import React from "react"
import {Fieldset} from 'primereact/fieldset';
import CSCOVerticalSection from "../../components/CSCOVerticalSection";
import CSControl from "../../controls/CSControl";
import { Button } from "primereact/button";
import CSUtil from "../../util/CSUtil";
import CSServiceUtil from "../../util/CSServiceUtil";
import { cfGetConstantValue} from "../../util/CSConstantUtil";

class CSCOReportCriteria extends React.Component
{
    constructor(props)
    {
       super(props);
       this.mCSServiceUtil = CSServiceUtil.cfGetInstance();
       this.state =
       {
            mReportCriteriaData : props.pReportCriteriaData,
            mReport             : props.pReport,
            mOnClick            : props.pOnClick,
            mNumSections        : 3,
            mSelectedData       : {},
            mEnableSearch       : false,
            mRequiredFieldMap   : null
       }

       this.cfGetCriteriaObject = this.cfGetCriteriaObject.bind(this);
       this.cfGetCriteriaSections = this.cfGetCriteriaSections.bind(this);
       this.cfGetCriteriaActions = this.cfGetCriteriaActions.bind(this);
       this.cfProcessSearch = this.cfProcessSearch.bind(this);
       this.cfProcessSelection = this.cfProcessSelection.bind(this);
       this.cfSetControlValue = this.cfSetControlValue.bind(this);
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if( (lPrevProps.pReportCriteriaData !== this.props.pReportCriteriaData) ||
            (lPrevProps.pReport !== this.props.pReport))
        {
            this.setState(
                {
                    mReportCriteriaData : this.props.pReportCriteriaData,
                    mReport : this.props.pReport,
                }
            );
        }
    }

    cfGetCriteriaObject(lReportCriteria, lNumSections, lIsRequired)
    {
        var ctl = null;
        if(lReportCriteria)
        {
            lReportCriteria.display_field_  = lReportCriteria.display_name_;
            lReportCriteria.data_field_     = lReportCriteria.criteria_field_;
            var lCriteriaDefinitionObject   = this.mCSServiceUtil.cfGetCriteriaDefinitionObject(lReportCriteria.criteria_name_);
            if(lCriteriaDefinitionObject)
            {
                lReportCriteria.data_provider_id_ = lCriteriaDefinitionObject.data_provider_id_;
            }

            ctl = 
            ( 
                <CSControl 
                    key                 = {lReportCriteria.id_}
                    process_change_     = {this.cfProcessSelection}
                    pFormLabelClassName = "form_criteria_label"
                    pLabelWidth         = "100px"
                    required_           = {lIsRequired}
                    pMaxWidth           = {(400 * 4/3) / lNumSections}
                    {...lReportCriteria}/>
            );
        }

        return ctl;
    }

    cfSetControlValue()
    {
        if((this.state !== null) && (this.state.mReportCriteriaData != null))
        {
            for(var ind=0; ind<this.state.mReportCriteriaData.length; ind++)
            {
                var criteria_item = this.state.mReportCriteriaData[ind];
                var criteria_data = this.state.mSelectedData;
                if(criteria_data)
                {   
                    criteria_item.mValue = criteria_data[criteria_item.data_field_];
                }
                else
                {
                    criteria_item.mValue = '';
                }
            }
        }
    }

    cfProcessSelection(lData)
    {
        if(lData)
        {
            if(this.state.mSelectedData !== null)
            {
                var lSelectedData = this.state.mSelectedData;
                lSelectedData[lData.data_field_] = lData.value_;
                this.setState ({mSelectedData : lSelectedData});
            }
        }
    }

    cfGetCriteriaSections(lCriteriaFields)
    {        
        var lHeight = 0;
        var lSections = [];
        var lRequiredCriteriaList = null;
        var lNumSections = this.state.mNumSections;
        var lCriteriaList = [];

        for(var lInd=0; lInd<lCriteriaFields.length; lInd++)
        {
            if(lCriteriaFields[lInd].sequence_ !== '0')
            {
                lCriteriaList.push(lCriteriaFields[lInd]);
            }
        }

        if(lCriteriaList.length < this.state.mNumSections)
        {
            lNumSections = lCriteriaList.length;
        }

        var lNumCriteriaSectionRows = Math.ceil(lCriteriaList.length/lNumSections);
        if(lNumCriteriaSectionRows > 0)
        {
            lHeight = 48 + (lNumCriteriaSectionRows - 1) * 50;
            
            var lSectionNumControlMap = {};
            lCriteriaList = CSUtil.cfSortInteger(lCriteriaList, 'sequence_');
            for(var ind=0; ind<lCriteriaList.length; ind++)
            {
                var lFormCriteria = lCriteriaList[ind];
                var lIsRequired = (lFormCriteria && lFormCriteria.value_ && (lFormCriteria.value_ === 'required')) ? '1' : '0';
                if(lIsRequired === '1')
                {
                    if(!lRequiredCriteriaList)
                    {
                        lRequiredCriteriaList = []
                    }
    
                    lRequiredCriteriaList.push(lFormCriteria);
                }
        
                var lCriteriaField = this.cfGetCriteriaObject(lFormCriteria, lNumSections, lIsRequired);
                var lIndex = Math.ceil(ind/lNumCriteriaSectionRows);
                var lSectionControlList = null;
                lSectionControlList = lSectionNumControlMap[lIndex];
                if(lSectionControlList == null)
                {
                    lSectionControlList = [];
                    lSectionNumControlMap[lIndex] = lSectionControlList;
                }

                lSectionControlList.push(lCriteriaField)
            }

            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FAFAFA";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }

                lSection = <CSCOVerticalSection
                    pBackgroundColor={lBkgColor} 
                    pId={lSectionNum} 
                    key={lSectionNum}
                    pNumSections={lNumSections}
                    pAlign="center"
                    pHeight={lHeight} 
                    pIsCriteriaSection = {true}
                    pChildren={lSectionNumControlMap[lSectionNum]}/>
                lSections.push(lSection);
            }

            var lCriteriaActionsList = [];
            var lActionItem = {};
            lActionItem.id_ = '1';
            lActionItem.label_ = 'Search';
            lActionItem.icon_name_ = 'PI_SEARCH';
            lCriteriaActionsList.push(lActionItem);
    
            var lActionSectionBackgroundColor = "#FFFFFF";
            if((lNumSections % 2) === 0)
            {
                lActionSectionBackgroundColor = "#FAFAFA";
            }

            var lActionSection = <CSCOVerticalSection  
                                    pBackgroundColor={lActionSectionBackgroundColor} 
                                    pChildren={this.cfGetCriteriaActions(lCriteriaActionsList, lRequiredCriteriaList)}
                                    pId={lSectionNum} 
                                    key={lSectionNum} 
                                    pHeight={lHeight} 
                                    pIsCriteriaSection = {true}
                                    pAlign="center"
                                    pWidth="150px"/>
            lSections.push(lActionSection);
        }

        return lSections;
    }

    cfGetCriteriaActions(lCriteriaActionsList, lRequiredCriteriaList)
    {
        var lActions = null;
        var lDisableSave = false;

        if(lRequiredCriteriaList)
        {
            for(var lInd=0; lInd<lRequiredCriteriaList.length; lInd++)
            {
                var lCriteriaObj = lRequiredCriteriaList[lInd];
                if(!lCriteriaObj.mValue)
                {
                    lDisableSave = true;
                    break;
                }
            }
        }

        lActions = 
            (lCriteriaActionsList) ?
                lCriteriaActionsList.map((lActionItem) => (
                    <div key = {lActionItem.id_} >
                        <Button
                            icon        = {CSUtil.mNameIconMap[lActionItem.icon_name_]} 
                            style       = {{fontSize : '13.2px', height : '30px'}}
                            key         = {lActionItem.id_}
                            id          = {lActionItem.id_}
                            label       = {lActionItem.label_}
                            disabled    = {lDisableSave}
                            onClick     = {(event) => this.cfProcessSearch(event, lActionItem)}
                            />
                    </div>
                )
            )
        : null

       return lActions;
    }

    cfProcessSearch(event, lActionItem)
    {
        if(event && lActionItem)
        {
            event.preventDefault();
            var lCriteria = {};
            if(this.state.mReportCriteriaData)
            {
                for(var lInd=0; lInd<this.state.mReportCriteriaData.length; lInd++)
                {
                    var lCriteriaObject = this.state.mReportCriteriaData[lInd];
                    if(lCriteriaObject.default_criteria_field_)
                    {
                        lCriteria[lCriteriaObject.default_criteria_field_] = cfGetConstantValue(lCriteriaObject.value_)
                    }
                }
            }

            if(this.state.mSelectedData !== null)
            {
                var lCriteriaNames = Object.keys(this.state.mSelectedData);
                var lCriteriaValues = Object.values(this.state.mSelectedData);
                for(var lInd_0=0; lInd_0<lCriteriaNames.length; lInd_0++)
                {
                    lCriteria[lCriteriaNames[lInd_0]] = lCriteriaValues[lInd_0]
                }
            }

            if(this.state.mOnClick && (this.state.mOnClick instanceof Function))
            {
                this.state.mOnClick(lCriteria, this.state.mReport);
            }
        }
    }

    render()
    {
        this.cfSetControlValue();
        var lCriteria = null;
        if(this.state.mReportCriteriaData && (this.state.mReportCriteriaData.length > 0))
        {
            lCriteria = 
                <Fieldset toggleable={true}>
                    <div className="report-criteria">
                        { this.cfGetCriteriaSections(this.state.mReportCriteriaData) }
                    </div>
                </Fieldset>
        }

        return(lCriteria);
    }
}

export default CSCOReportCriteria;