import React from "react"
import { TabMenu } from 'primereact/tabmenu';

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import CSRequestUtil from "../../util/CSRequestUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import { cfGetConstantValue} from "../../util/CSConstantUtil";

import CSCOGrid from "../../components/CSCOGrid"
import CSCODetailsHeader1 from "../../components/CSCODetailsHeader1";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar"
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"
import { fnGetForm } from "../../util/CSLayoutUtil"

class CSCODetailsForm19 extends React.Component
{
    mMinSectionItems 	= 1;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
       super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mFeatureRefId            : this.props.pFeatureRefId,
            mAppId                   : this.props.pAppId,
            mFormCode                : this.props.pFormCode,
            mSelectedMenuItem        : this.props.pSelectedMenuItem,
            mFormLayoutHeading       : null,
            mTabItems                : null,
 
            mHeader                  : null,
            mSection1                : null,
            mSection1FormCode        : null,
            mSection1SelectedData    : null,
            mSection1Summary         : {},
            mSection1DataField       : null,

            mSection2                : null,
            mSection2FormCode        : null,
            mSection2SelectedData    : null,
            mSection2Summary         : {},
            mSection2DataField       : null,

            mSection3                : null,
            mSection3FormCode        : null,
            mSection3DataField       : null,

            mParent                  : this.props.pParent,
            mFormData                : {},
            mDataCollection          : [],
            mTabList                 : [],
            mSelectionFlag           : 'section_1',

            mSection1DataCollection  : null, 
            mSection2DataCollection  : null,
            mSectionData             : {},
            mSelectedData            : null,
            mSelectedActionItem      : this.props.pSelectedActionItem,
            mFormItems               : this.props.pFormItems,
            mFormProperties          : this.props.pFormProperties,
            mFormValidation          : this.props.pFormValidation,
            mFormSelection           : this.props.pFormSelection,
            mFormActionProperties    : this.props.pFormActionProperties,
            mFormItemProperties      : this.props.pFormItemProperties,
            mFormItemComputation     : this.props.pFormItemComputation,
            mFormDetailsActions      : null,
            mFormItemsSearchActions  : null,
            mFormItemsDetailsActions : null,
            mSelectedTabActionItem   : this.props.pSelectedTabActionItem,
            mActionRefIdObjectMap    : {},

            mAddFlag                 : false,
            mDetailsFlag             : false,
            mDetailsMetaData         : null,
            mSelectedRecord          : null,
            mSectionFormItems        : null,
            mCollectionFormItem      : null,
            mIdFieldObjectMap        : {},
            mDataFieldObjectMap      : {},
            mSaveDataObject          : null,

            fnProcessChange          : this.props.fnProcessChange,
            fnProcessSubmit          : this.props.fnProcessSubmit,
            fnProcessClose           : this.props.fnProcessClose
        }

        this.cfInitializeForm        = this.cfInitializeForm.bind(this);
        this.cfInitializeData        = this.cfInitializeData.bind(this);
        this.cfGetFormHeading        = this.cfGetFormHeading.bind(this);
        this.cfGetFormItems          = this.cfGetFormItems.bind(this);
        this.cfSetControlValue       = this.cfSetControlValue.bind(this);
        this.cfAddToCollection       = this.cfAddToCollection.bind(this);
        this.cfUpdateCollection      = this.cfUpdateCollection.bind(this);
        this.cfUpdate                = this.cfUpdate.bind(this);
        this.cfGetSection            = this.cfGetSection.bind(this);
        this.cfGetDimensions         = this.cfGetDimensions.bind(this);
        this.cfGetRequestObject      = this.cfGetRequestObject.bind(this);
        this.cfProcessChange         = this.cfProcessChange.bind(this);
        this.cfSetSection1Selection  = this.cfSetSection1Selection.bind(this);
        this.cfSetSection2Selection  = this.cfSetSection2Selection.bind(this);
 
        this.process_add             = this.process_add.bind(this);
        this.process_modify          = this.process_modify.bind(this);
        this.process_delete          = this.process_delete.bind(this);
        this.process_close           = this.process_close.bind(this);
        this.process_change          = this.process_change.bind(this);
        this.process_save            = this.process_save.bind(this);
        this.onPopout                = this.onPopout.bind(this);
        this.cfResetCollection       = this.cfResetCollection.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            if(this.props.pSelectedData.hasOwnProperty('selected_data_'))
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData.selected_data_;
            }
            else
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData;
            }
        }

        if(this.props.pSelectedCriteria)
        {
            this.state.mFormData['selected_criteria_'] = this.props.pSelectedCriteria;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'initialize')
                {
                    if(lFormProperty.property_ === 'section_1_')
                    {
                        this.state.mSection1DataField = lFormProperty.value_;
                    }
                    else if(lFormProperty.property_ === 'section_2_')
                    {
                        this.state.mSection2DataField = lFormProperty.value_;
                    }
                    else if(lFormProperty.property_ === 'section_3_')
                    {
                        this.state.mSection3DataField = lFormProperty.value_;
                    }
                    else
                    {
                        if(this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                        {
                            if (lFormProperty.property_ === 'data_')
                            {
                                this.state.mFormData[lFormProperty.value_] = {}
                            }
                            else if(lFormProperty.property_ === 'collection_data_')
                            {
                                this.state.mFormData[lFormProperty.value_] = [];
                            }
                            else if(lFormProperty.property_ === 'criteria_field_')
                            {
                                if(!this.state.mFormData['selected_criteria_'])
                                {
                                    this.state.mFormData['selected_criteria_'] = {};
                                    if(this.props.pSelectedData)
                                    {
                                        this.state.mFormData.selected_criteria_[lFormProperty.value_] = this.props.pSelectedData[lFormProperty.value_];
                                    }
                                    else
                                    {
                                        this.state.mFormData.selected_criteria_[lFormProperty.value_] = {}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        if(this.props.pFormActions)
        {
            for(var lActionInd=0; lActionInd<this.props.pFormActions.length; lActionInd++)
            {
                var lActionObj = this.props.pFormActions[lActionInd];
                this.state.mActionRefIdObjectMap[lActionObj.id_] = lActionObj;
            }
        }
    }

    componentDidMount()
    {
        var lCriteria;
        if(this.state.mFormData && this.state.mFormData.hasOwnProperty('selected_criteria_'))
        {
            lCriteria = this.state.mFormData.selected_criteria_;
        }

        if(this.state.mSelectedTabActionItem)
        {        
            this.cfInitializeData(lCriteria, this.state.mSelectedTabActionItem.on_click_request_ref_id_, this.state.mSelectedTabActionItem.on_click_service_code_);
        }
    }

    cfInitializeData(lCriteria, lRequestRefId, lServiceCode)
    {
        var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lRequestRefId);
        if(lServiceCode || lRequestObject)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lRequestId = null;
                if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                {
                    lRequestId = lRequestObject.request_id_;
                }

                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            if(!lCriteria)
                            {
                                lCriteria = {}
                            }

                            lCriteria[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }

                if(lCriteria)
                {
                    lRequestUtil.cfSetRequestCriteria(lCriteria);
                    lRequestUtil.cfProcessRequest(
                        (lResponse)=>
                        {
                            if(lResponse)
                            {
                                this.cfSetFormData(lResponse);
                            }
                        }
                    );
                }
            }
        }
        else
        {
            this.cfInitializeForm();
        }
    }

    cfSetFormData(lData)
    {
        var lFormData = this.state.mFormData;
        if(lData)
        {
            if(this.state.mFormProperties)
            {
                for(var lind=0; lind<this.state.mFormProperties.length; lind++)
                {
                    var lfprop = this.state.mFormProperties[lind];
                    if(lfprop.type_ === 'initialize')
                    {
                        if (lfprop.property_ === 'data_')
                        {
                            if(lData.hasOwnProperty(lfprop.value_))
                            {
                                if(!lFormData)
                                {
                                    lFormData = {}
                                }

                                var lDataObj = lData[lfprop.value_];
                                if(Array.isArray(lDataObj) && (lDataObj.length > 0))
                                {
                                    lFormData[lfprop.value_] = lDataObj[0];
                                }
                            }
                        }
                        else if (lfprop.property_ === 'collection_data_')
                        {
                            if(!lFormData)
                            {
                                lFormData = {}
                            }

                            lFormData[lfprop.value_] = lData[lfprop.value_];
                        }
                    }
                }
            }
        }

        this.cfInitializeForm(lFormData);
        this.setState({mFormData : lFormData});
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.props.pFormActions.length; lInd++)
            {
                var lFormAction = this.props.pFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    cfGetFormItems(lActionId)
    {
        var lFormItems = [];
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.action_ref_id_ === lActionId)
                {
                    lFormItems.push(lFormItem);
                }
            }
        }
        
        return lFormItems;
    }

    cfAddToCollection(lDataObj, lCloseFlag, lSectionNo)
    {
        var lDataCollection = null;
        var lSection           = null;

        if(lSectionNo === '1')
        {
            lDataCollection = this.state.mSection1DataCollection;
        }
        else if(lSectionNo === '2')
        {
            lDataCollection = this.state.mSection2DataCollection;
        }

        if(!lDataCollection)
        {
            lDataCollection = [];
        }

        lDataCollection.push(lDataObj);

        if(lSectionNo === '1')
        {
            lSection = this.cfGetSection(
                    this.state.mSection1Columns, 
                    this.state.mSection1Heading, 
                    this.state.mSection1ActionBar, 
                    lDataCollection,
                    this.state.mSection1Summary,
                    this.cfSetSection1Selection,
                    (lData)=>{this.setState({mSection1Summary : lData})});

            this.setState(
                {
                    mSection1DataCollection : lDataCollection,
                    mSection1               : lSection,
                    mDetailsFlag            : !lCloseFlag
                }
            );
        }
        else if(lSectionNo === '2')
        {
            lSection = this.cfGetSection(
                    this.state.mSection2Columns, 
                    this.state.mSection2Heading, 
                    this.state.mSection2ActionBar, 
                    lDataCollection,
                    this.state.mSection2Summary,
                    this.cfSetSection2Selection,
                    (lData)=>{this.setState({mSection2Summary : lData})})

            this.setState(
                {
                    mSection2DataCollection : lDataCollection,
                    mSection2               : lSection,
                    mDetailsFlag            : !lCloseFlag
                }
            );
        }
    }

    cfUpdateCollection(lDataObj, lCloseFlag, lSectionNo)
    {
        var lDataCollection = null;
        var lSection           = null;

        if(lSectionNo === '1')
        {
            lDataCollection = this.state.mSection1DataCollection;
        }
        else if(lSectionNo === '2')
        {
            lDataCollection = this.state.mSection2DataCollection;
        }

        if(!lDataCollection)
        {
            lDataCollection = [];
        }

        if(lSectionNo === '1')
        {
            lSection = this.cfGetSection(
                    this.state.mSection1Columns, 
                    this.state.mSection1Heading, 
                    this.state.mSection1ActionBar, 
                    lDataCollection,
                    this.state.mSection1Summary,
                    this.cfSetSection1Selection,
                    (lData)=>{this.setState({mSection1Summary : lData})})

            this.setState(
                {
                    mSection1DataCollection : lDataCollection,
                    mSection1               : lSection,
                    mDetailsFlag            : !lCloseFlag
                }
            );
        }
        else if(lSectionNo === '2')
        {
            lSection = this.cfGetSection(
                    this.state.mSection2Columns, 
                    this.state.mSection2Heading, 
                    this.state.mSection2ActionBar, 
                    lDataCollection,
                    this.state.mSection2Summary,
                    this.cfSetSection2Selection,
                    (lData)=>{this.setState({mSection2Summary : lData})})

            this.setState(
                {
                    mSection2DataCollection : lDataCollection,
                    mSection2               : lSection,
                    mDetailsFlag            : !lCloseFlag
                }
            );
        }
    }

    cfResetCollection()
    {
        var lSection1Summary = {};
        var lSection2Summary = {};

        var lSection1 = this.cfGetSection(
            this.state.mSection1Columns, 
            this.state.mSection1Heading, 
            this.state.mSection1ActionBar, 
            null,
            lSection1Summary,
            this.cfSetSection1Selection,
            (lData)=>{this.setState({mSection1Summary : lData})});

         var lSection2 = this.cfGetSection(
            this.state.mSection2Columns, 
            this.state.mSection2Heading, 
            this.state.mSection2ActionBar, 
            null,
            lSection2Summary,
            this.cfSetSection2Selection,
            (lData)=>{this.setState({mSection2Summary : lData})});

        this.setState(
            {
                mSection1DataCollection : null,
                mSection1               : lSection1,
                mSection2DataCollection : null,
                mSection2               : lSection2
            }
        );
    }

    cfUpdate(lDataObj, lCloseFlag, lActionItem)
    {
        if(lActionItem && lActionItem._SECTION_)
        {
            var lAction = lActionItem.action_;
            var lSectionNo = lActionItem._SECTION_;
            if(lDataObj)
            {
                if(lAction === 'process_add')
                {
                    this.cfAddToCollection(lDataObj, lCloseFlag, lSectionNo)
                }
                else if((lAction === 'process_modify') || (lAction === 'process_update'))
                {
                    this.cfUpdateCollection(lDataObj, lCloseFlag, lSectionNo)
                }
            }
        }
    }

    process_add(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.props.pFormActions.length; lInd++)
            {
                var lAction = this.props.pFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ACTION') || (lAction.component_type_ === 'DETAILS_ITEM_ACTION'))
                {
                    if(lActionItem.id_ === lAction.parent_ref_id_)
                    {
                        lActions.push(lAction);
                    }
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pParent                 : this,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormDetails            : this.state.mFormDetails,
                pFormValidation         : this.state.mFormValidation,
                pSelectedActionItem     : lActionItem,
                pParentFlag             : true,
                pResponseFn             : this.cfUpdate
            };

            this.setState(
                {
                    mDetailsMetaData        : lDetailsMetaData, 
                    mDetailsFlag            : true,
                    mSelectedData           : null
                }
            );
        }
    }

    process_modify(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.props.pFormActions.length; lInd++)
            {
                var lAction = this.props.pFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ACTION') || (lAction.component_type_ === 'DETAILS_ITEM_ACTION'))
                {
                    if(lActionItem.id_ === lAction.parent_ref_id_)
                    {
                        lActions.push(lAction);
                    }
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pParent                 : this,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties         : this.state.mFormProperties,
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormDetails            : this.state.mFormDetails,
                pFormValidation         : this.state.mFormValidation,
                pSelectedActionItem     : lActionItem,
                pResponseFn             : this.cfUpdate
            };

            var lSelectedData = null;
            var lColumns = null;
            if(lActionItem._SECTION_ === '1')
            {
                lSelectedData = this.state.mSection1SelectedData;
                lColumns = this.state.mSection1Columns;
            }
            else if(lActionItem._SECTION_ === '2')
            {
                lSelectedData = this.state.mSection2SelectedData;
                lColumns = this.state.mSection2Columns;
            }

            if(lSelectedData && lColumns)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = lSelectedData;

                var lServiceCode = lActionItem.on_click_service_code_;
                var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
                var lCriteriaKeys = null;
                for(var lGInd=0; lGInd<lColumns.length; lGInd++)
                {
                    var lGridObject = lColumns[lGInd];
                    if(lGridObject.criteria_field_)
                    {
                        if(!lCriteriaKeys)
                        {
                            lCriteriaKeys = [];
                        }

                        lCriteriaKeys.push(lGridObject.criteria_field_);
                    }
                }

                if(lCriteriaKeys && (lServiceCode || lRequestObject))
                {
                    var lCriteria = {};
                    for(var lCInd=0; lCInd<lCriteriaKeys.length; lCInd++)
                    {
                        lCriteria[ lCriteriaKeys[lCInd] ] = lSelectedData[ lCriteriaKeys[lCInd] ];
                    }
                    
                    lDataObj.selected_criteria_ = lCriteria;
                 }
 
                 this.setState(
                    {
                        mDetailsMetaData    : lDetailsMetaData,
                        mDetailsFlag        : true,
                        mSelectedActionItem : lActionItem,
                        mSelectedData       : lDataObj
                    }
                );
            }
        }
    }

    process_delete(event, lParent)
    {
    }

    process_close(lEvent, lParent, lActionItem)
    {
        this.setState({mDetailsFlag : false, mDetailsMetaData : null});
    }

    process_change(lEvent, lFormItem)
    {
        if(lEvent && lFormItem)
        {
        }
    }
    
    cfProcessChange(lData)
    {
        if(lData)
        {
            if(lData.control_type_ === 'combo_box')
            {
                if(lData.id_)
                {
                    var lFormItem = this.state.mIdFieldObjectMap[lData.id_];
                    lFormItem.mClearSelection = false;
                    if(lFormItem && lFormItem.cs_form_item_properties_)
                    {
                        var lActionRefId = null;
                        for(var lInd=0; lInd<lFormItem.cs_form_item_properties_.length; lInd++)
                        {
                            var lfipObj = lFormItem.cs_form_item_properties_[lInd];
                            if(lfipObj.property_ === 'action_ref_id_')
                            {
                                lActionRefId = lfipObj.value_;
                            }
                        }

                        if(lActionRefId)
                        {
                            var lCriteria = {}
                            lCriteria[lData.data_field_] = lData.value_;
                            
                            var lActionItem = this.state.mActionRefIdObjectMap[lActionRefId];
                            this.cfInitializeData(lCriteria, lActionItem.on_click_request_ref_id_, lActionItem.on_click_service_code_);
                        }
                    }
                }
            }
            
            var lFormData = this.state.mFormData;
            if(!lFormData)
			{
                lFormData = {};
            }

            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem0 = this.state.mFormItems[ind];
                if(lFormItem0.control_type_ === 'constant')
                {
                    lFormData[lFormItem0.data_field_] = cfGetConstantValue(lFormItem0.constant_);
                }
            }

            if(lData.hasOwnProperty('data_field_') && lData.data_field_)
            {
                if(lData.hasOwnProperty('data_object_'))
                {
                    var lDataObj = lFormData[lData.data_object_];
                    if(!lDataObj)
                    {
                        lDataObj = {}
                        lFormData[lData.data_object_] = lDataObj;
                    }

                    lDataObj[lData.data_field_] = lData.value_;
                }
                else
                {
                    lFormData[lData.data_field_] = lData.value_;
                }
            }

            this.cfSetFormData(lFormData);
        }
    }

    onPopout(lEvent, lFormItem)
    {
        var lFormActions = this.state.mFormActions;
        if(lEvent && lFormItem)
        {
            var lActionItem;
            var lActionRefId;
            var lCriteria;
            var lAction;
            if(lFormItem.hasOwnProperty('cs_form_item_properties_') && lFormItem.cs_form_item_properties_)
            {
                var lFormItemProperties = lFormItem.cs_form_item_properties_;
                for(var lInd=0; lInd<lFormItemProperties.length; lInd++)
                {
                    var lProperty = lFormItemProperties[lInd];
                    if(lProperty.type_ === 'popup_action')
                    {
                        if(lProperty.property_ === 'action_ref_id_')
                        {
                            lActionItem = this.mFormUtil.cfGetActionItem(lFormActions, lProperty.value_)
                            lActionRefId = lProperty.value_;
                        }
                        else if(lProperty.property_ === 'action')
                        {
                            lAction = lProperty.value_;
                        }
                    }
                    else if(lProperty.type_ === 'criteria')
                    {
                        if(!lCriteria)
                        {
                            lCriteria = {}
                        }

                        var lData = lFormItem.mValue;
                        if(lData)
                        {
                            if(lData instanceof Object && lData.hasOwnProperty(lProperty.property_))
                            {
                                lCriteria[lProperty.property_] = lData[lProperty.property_];
                            }
                            else
                            {
                                lCriteria[lProperty.property_] = lData;
                            }
                        }
                    }
                }
            }

            if(lActionItem)
            {
                if(lCriteria 
                    && ((lActionItem.action_ === 'process_add') || (lActionItem.action_ === 'process_modify'))
                    && lActionItem.on_click_service_code_)
                {
                    var lDetailsMetaData = 
                    {
                        pParent                 : this,
                        pFormType               : lActionItem.navigate_to_form_component_code_,
                        pFormHeading            : lActionItem.title_,
                        pFormActions            : this.mFormUtil.cfGetChildActionItems(this.state.mFormActions, lActionRefId),
                        pFormProperties         : this.state.mFormProperties,
                        pFormItems              : this.mFormUtil.cfGetFormItems(this.state.mFormItems, lActionRefId, true),
                        pSelectedActionItem     : lActionItem,
                        pParentFlag             : true
                    };

                    var lServiceCode = lActionItem.on_click_service_code_;
                    var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        var lDataObj = {};
                        var lRequestId = null;
                        var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                        {
                            lRequestId = lRequestObject.request_id_;
                        }
                    
                        var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                        if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                            {
                                lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                            }
                        }
                    
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                            {
                                lDataObj.selected_criteria_ = lCriteria;
                                if(lResponse)
                                {
                                    if(lRequestObject)
                                    {
                                        var lKeys = Object.keys(lResponse);
                                        if(lKeys)
                                        {
                                            for(var lKInd=0; lKInd<lKeys.length; lKInd++)
                                            {
                                                lDataObj[lKeys[lKInd]] = lResponse[lKeys[lKInd]];
                                            }
                                        }
                                    }
                                    else
                                    {
                                        if(lResponse.hasOwnProperty('collection_') && (lResponse.collection_.length > 0))
                                        {
                                            var lRespData = lResponse.collection_[0];
                                            lDataObj = lRespData;
                                        }
                                    }
                                }
                    
                                lDetailsMetaData.pFormData = lDataObj;
                    
                                this.setState(
                                    {
                                        mDetailsMetaData    : lDetailsMetaData, 
                                        mPopoutFlag         : true
                                    }
                                );
                            }
                        );
                    }
                }
                else if(lActionItem.action_ === 'process_reset')
                {
                    this.cfResetCollection();
                }
            }
            else if(lAction && (lAction === 'process_reset'))
            {
                lFormItem.mValue = null;
                lFormItem.mClearSelection = true;
                this.cfSetFormData();
                this.cfResetCollection();
            }
        }
    }

    cfGetDimensions(lId)
    {
        var lHeight;
        if(lId)
        {
            var lElement = document.getElementById(lId);
            if(lElement)
            {
                var lRect = lElement.getBoundingClientRect();
                if(lRect)
                {
                    lHeight = lRect.height
                }
            }
        }

        return lHeight;
    }

    cfGetSection(lColumns, lHeading, lActionBar, lData, lSummaryData, fnOnSelection, fnOnSummaryChange)
    {
        var lHeadingHeight          = this.cfGetDimensions("ID_HEADING");
        var lTabMenuHeight          = this.cfGetDimensions("ID_TAB_MENU");
        var lDetailsActionsHeight   = this.cfGetDimensions("ID_DETAILS_ACTION_BAR");
        var lScrollHeight           = ((CSUtil.cfGetInstance()).mApplicationHeight - lTabMenuHeight -lHeadingHeight - lDetailsActionsHeight - 185);
        
        var lSection =  
            <div style={{ marginRight: 5, borderColor: '#FFFFFF', backgroundColor: '#FFFFFF' }}>
                <CSCOGrid
                    pColumns            = { lColumns }
                    pHeading            = { lHeading }
                    pSearchActionBar    = { lActionBar }
                    pData               = { lData }
                    pSummaryData        = { lSummaryData }
                    onSummaryChange     = { fnOnSummaryChange }
                    pEnableScroll       = { true }
                    onClick             = { fnOnSelection }
                    pScrollHeight       = { lScrollHeight }
                />
            </div>

        return lSection;
    }

    cfInitializeForm(lFormData = this.state.mFormData)
    {
        var lSaveActionItem;
        var lFormActions        = null;
        var lHeader             = null;

        var lSection1           = null;
        var lSection1FormCode   = null;
        var lSection1Heading    = null;
        var lSection1GridItems  = null;
        var lSection1Actions    = null;
        var lSection1Summary    = {};

        var lSection2           = null;
        var lSection2FormCode   = null;
        var lSection2Heading    = null;
        var lSection2GridItems  = null;
        var lSection2Actions    = null;
        var lSection2Summary    = {};

        var lSection3           = null;
        var lSection3FormCode   = null;
        var lSection3GridItems  = null;

        var lFormItemList = [];
        var lIdFieldObjectMap = {}
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                lIdFieldObjectMap[lFormItem.id_] = lFormItem;
                if((lFormItem.visible_ === '1') && this.state.mSelectedActionItem)
                {
                    if(lFormItem.action_ref_id_ === this.state.mSelectedActionItem.id_)
                    {
                        lFormItemList.push(lFormItem);
                    }
                }
            }
        }

        if(lFormItemList.length > 0)
        {
            lHeader =                     
                <CSCODetailsHeader1 
                    pFormItems          = {lFormItemList} 
                    pFormData           = {lFormData} 
                    pFormProperties     = {this.state.mFormProperties} 
                    pFormItemProperties = {this.state.mFormItemProperties}
                    pSaveActionItem     = {lSaveActionItem}
                    pProcessChange      = {this.cfProcessChange}
                    pOnPopout           = {this.onPopout}
                    pEnableSaveFn       = {this.cfEnableSave}
                    />
        }

        if(this.state.mFormProperties)
        {
            for(var lInd_4=0; lInd_4<this.state.mFormProperties.length; lInd_4++)
            {
                var lFormProperty = this.state.mFormProperties[lInd_4];
                if(lFormProperty.type_ === 'initialize')
                {
                    if (lFormProperty.property_ === 'sequence_')
                    {
                        if (lFormProperty.value_ === '1')
                        {
                            lSection1FormCode = lFormProperty.cs_form_code_;
                        }
                        else if (lFormProperty.value_ === '2')
                        {
                            lSection2FormCode = lFormProperty.cs_form_code_;
                        }
                        else if (lFormProperty.value_ === '3')
                        {
                            lSection3FormCode = lFormProperty.cs_form_code_;
                        }
                    }
                }
            }

            for(var lInd_0=0; lInd_0<this.state.mFormProperties.length; lInd_0++)
            {
                var lFormProperty_1 = this.state.mFormProperties[lInd_0];
                if(lFormProperty_1.type_ === 'initialize')
                {
                    if(lFormProperty_1.property_ === 'heading_')
                    {
                        if (lFormProperty_1.cs_form_code_ === lSection1FormCode)
                        {
                            lSection1Heading = lFormProperty_1.value_;
                        }
                        else if (lFormProperty_1.cs_form_code_ === lSection2FormCode)
                        {
                            lSection2Heading = lFormProperty_1.value_;
                        }
                    }
                }
            }
        }

        if(this.props.pFormGrid)
        {
            for(var lInd_1=0; lInd_1<this.props.pFormGrid.length; lInd_1++)
            {
                var lFormGrid = this.props.pFormGrid[lInd_1];
                lFormGrid.key = lInd_1 + 1;
                if(lFormGrid.cs_form_code_ === lSection1FormCode)
                {
                    if (!lSection1GridItems)
                    {
                        lSection1GridItems = [];
                    }

                    lSection1GridItems.push(lFormGrid);
                }
                else if(lFormGrid.cs_form_code_ === lSection2FormCode)
                {
                    if (!lSection2GridItems)
                    {
                        lSection2GridItems = [];
                    }

                    lSection2GridItems.push(lFormGrid);
                }
                else if(lFormGrid.cs_form_code_ === lSection3FormCode)
                {
                    if (!lSection3GridItems)
                    {
                        lSection3GridItems = [];
                    }

                    lSection3GridItems.push(lFormGrid);
                }
            }

            if(this.state.mFormItems)
            {
                var lFormItemList_1 = [];
                if(this.state.mFormItems && (this.state.mFormItems.length > 0))
                {
                    for(var lInd_3=0; lInd_3 < this.state.mFormItems.length; lInd_3++)
                    {
                        var lFormItem_1 = this.state.mFormItems[lInd_3];
                        
                        if((lFormItem_1.cs_form_code_ === lSection3FormCode) && (lFormItem_1.visible_ === '1'))
                        {
                            lFormItemList_1.push(lFormItem_1);
                        }

                        if(lFormItemList_1.length > 0)
                        {
                            lSection3 = 
                                <div className="form-item-1">
                                    { this.mFormUtil.cfGetSections(lFormItemList_1, 8, 1, null, null, 5) }
                                </div>
                        }
                    }
                }
            }

            if(this.props.pFormActions)
            {
                for(var lInd_2=0; lInd_2 < this.props.pFormActions.length; lInd_2++)
                {
                    var lFormAction = this.props.pFormActions[lInd_2];
                    lFormAction.mEnabled = (lFormAction.enabled_ === '1') ? true : false;
                    if(lFormAction.parent_form_code_ === lSection1FormCode)
                    {
                        if(!lSection1Actions)
                        {
                            lSection1Actions = [];
                        }

                        lFormAction._SECTION_ = '1';
                        
                        lSection1Actions.push(lFormAction);
                    }
                    else if(lFormAction.parent_form_code_ === lSection2FormCode)
                    {
                        if(!lSection2Actions)
                        {
                            lSection2Actions = [];
                        }

                        lFormAction._SECTION_ = '2';

                        lSection2Actions.push(lFormAction);
                    }
                    else if(lFormAction.parent_form_code_ === this.state.mFormCode)
                    {
                        if(!lFormActions)
                        {
                            lFormActions = [];
                        }

                        lFormAction._SECTION_ = '3';

                        lFormActions.push(lFormAction);
                    }
                }
            }

            if(lSection1FormCode)
            {
                var lSection1ActionBar = null;
                if(lSection1Actions)
                {
                    lSection1ActionBar = 
                        <CSCOBaseActionBar 
                            align       = "left"
                            pType       = 'SEARCH_ACTION_BAR'
                            parent      = {this}
                            actionList  = {lSection1Actions}
                        />    
                }

                var lSection1DataCollection = null;
                if(lFormData && lFormData.hasOwnProperty(this.state.mSection1DataField))
                {
                    lSection1DataCollection = lFormData[this.state.mSection1DataField];
                }

                lSection1 = this.cfGetSection(lSection1GridItems, lSection1Heading, lSection1ActionBar, lSection1DataCollection, lSection1Summary, this.cfSetSection1Selection,
                    (lData)=>{this.setState({mSection1Summary : lData})});
            }

            if(lSection2FormCode)
            {
                var lSection2ActionBar = null;
                if(lSection2Actions)
                {
                    lSection2ActionBar = 
                        <CSCOBaseActionBar 
                            align       = "left"
                            pType       = 'SEARCH_ACTION_BAR'
                            parent      = {this}
                            actionList  = {lSection2Actions}
                        />    
                }

                var lSection2DataCollection = null;
                if(lFormData && lFormData.hasOwnProperty(this.state.mSection2DataField))
                {
                    lSection2DataCollection = lFormData[this.state.mSection2DataField];
                }

                lSection2 = this.cfGetSection(lSection2GridItems, lSection2Heading, lSection2ActionBar, lSection2DataCollection, lSection2Summary, this.cfSetSection2Selection,
                    (lData)=>{this.setState({mSection2Summary : lData})});
            }
        }

        var lTabList = null;
        if(lSection1)
        {
            var lSection1Obj = {};
            lSection1Obj.label = lSection1Heading;
            lSection1Obj.command = ()=>this.setState({mSelectionFlag : 'section_1'});

            if(!lTabList)
            {
                lTabList = [];
            }

            lTabList.push(lSection1Obj)
        }

        if(lSection2)
        {
            var lSection2Obj = {};
            lSection2Obj.label = lSection2Heading;
            lSection2Obj.command = ()=>this.setState({mSelectionFlag : 'section_2'});

            if(!lTabList)
            {
                lTabList = [];
            }

            lTabList.push(lSection2Obj)
        }

        this.setState(
            {
                mFormDetailsActions : lFormActions,
                mHeader             : lHeader,
                mSection1FormCode   : lSection1FormCode,
                mSection1Columns    : lSection1GridItems,
                mSection1Heading    : lSection1Heading,
                mSection1ActionBar  : lSection1ActionBar,
                mSection1           : lSection1,
                mSection1Summary    : lSection1Summary,
                mSection2FormCode   : lSection2FormCode,
                mSection2Columns    : lSection2GridItems,
                mSection2           : lSection2,
                mSection2Heading    : lSection2Heading,
                mSection2ActionBar  : lSection2ActionBar,
                mSection2Summary    : lSection2Summary,
                mSection3FormCode   : lSection3FormCode,
                mSection3Columns    : lSection3GridItems,
                mSection3           : lSection3,
                mTabList            : lTabList,
                mIdFieldObjectMap   : lIdFieldObjectMap
            }
        );
    }

    cfSetSection1Selection(lSelectedData)
    {
        this.setState({mSection1SelectedData : lSelectedData});
    }

    cfSetSection2Selection(lSelectedData)
    {
        this.setState({mSection2SelectedData : lSelectedData});
    }


    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                var lFormData = null;
                if(!lFormItem.parent_ref_id_ && (lFormItem.control_type_ === 'reference'))
                {
                    lFormData = this.state.mFormData[lFormItem.data_field_];
                }

                 if(!lFormData)
                {
                    lFormData = {}
                }

                if(lFormItem.control_type_ === 'constant')
                {
                    lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    if(!lFormData[lFormItem.data_field_])
                    {
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
                else if(lFormItem.dependent_id_)
                {
                    this.mFormUtil.cfSetDependentFieldData(lFormItem, this.state.mIdFieldObjectMap);
                    if(lFormData[lFormItem.data_field_])
                    {
                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                    }
                }
                else if(lFormItem.control_type_ === 'check_box')
                {
                    if(lFormItem.constant_ && lFormData && ((lFormItem.constant_ === lFormData[lFormItem.data_field_])))
                    {
                        lFormItem.mValue = '1';
                    }
                    else
                    {
                        lFormItem.mValue = '0';
                    }
                }
                else if(lFormItem.control_type_ === 'text_input')
                {
                    if(lFormData[lFormItem.data_field_])
                    {
                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                    }
                    else if(lFormItem.constant_)
                    {
                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                    else
                    {
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
                else
                {
                    lFormItem.mValue = lFormData[lFormItem.data_field_];
                }                        
            }
        }
    }

    process_save(event, lParent, lActionItem)
    {
        if(lActionItem)
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            var lRequestData = null;
                            if(this.state.mFormItems)
                            {
                                for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
                                {
                                    var lFormItem = this.state.mFormItems[lInd];
                                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                    {
                                        var lRequestObj = this.cfGetRequestObject(lFormItem);
                                        if(lRequestObj)
                                        {
                                            if(!lRequestData)
                                            {
                                                lRequestData = {}
                                            }
                    
                                            lRequestData[lFormItem.data_field_] = lRequestObj;
                                        }
                                    }
                                }
                            }

                            if(!lRequestData)
                            {
                                lRequestData = this.state.mFormData;
                            }

                            this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                (lResponse) =>
                                {
                                    var lCode = null;
                                    var lMsg = null;
                                    var lCloseFlag = (lActionItem.new_state_ === "list")
                                    if(lResponse && lResponse.code)
                                    {
                                        if(lResponse.code instanceof Array)
                                        {
                                            lCode = lResponse.code[0];
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message[0];
                                            }
                                        }
                                        else
                                        {
                                            lCode = lResponse.code;
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message;
                                            }
                                        }
                                    }
                                    else
                                    {
                                        lCode = -1;
                                    }
    
                                    this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                    if(lCode === 1)
                                    {
                                        this.process_close(lCloseFlag, true);
                                    }
                                }
                            );
                        }
                    }
                }
            );
        }
    }

    cfGetRequestObject(lFormItem)
    {
        var lRetObj;
        var lFormItemProperties = lFormItem.cs_form_item_properties_;
        if(lFormItemProperties)
        {
            for(var lfipInd=0; lfipInd<lFormItemProperties.length; lfipInd++)
            {
                var lfipObj = lFormItemProperties[lfipInd];
                if(lfipObj.type_ === 'request_data')
                {
                    if(lfipObj.property_ === '#CONSTANT#')
                    {
                        if(lFormItem.control_type_ !== 'collection')
                        {
                            if(!lRetObj)
                            {
                                lRetObj = {}
                            }
        
                            lRetObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                        }
                        else
                        {
                            if(!lRetObj)
                            {
                                if(lfipObj.value_ === 'section_1_')
                                {
                                    lRetObj = this.state.mSection1DataCollection;
                                }
                                else if(lfipObj.value_ === 'section_2_')
                                {
                                    lRetObj = this.state.mSection2DataCollection;
                                }
                             }
        
                            if(lRetObj)
                            {
                                for(var lcInd=0; lcInd<lRetObj.length; lcInd++)
                                {
                                    var lcObj = lRetObj[lcInd];
                                    lcObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                                }
                            }
                        }
                    }
                    else if(lfipObj.value_ &&  (this.state.mFormData || this.state.mSectionData))
                    {
                        var lSelectedData = null;
                        if(lfipObj.value_ === 'section_data_')
                        {
                            lSelectedData = this.state.mSectionData;
                        }
                        else if(this.state.mFormData.hasOwnProperty(lfipObj.value_))
                        {
                            lSelectedData = this.state.mFormData[lfipObj.value_];
                        }

                        if(lFormItem.control_type_ === 'collection')
                        {
                            if(!lRetObj)
                            {
                                lRetObj = [];
                            }

                            var lCollection = null;
                            if(lfipObj.value_ === 'section_1_')
                            {
                                lCollection = this.state.mSection1DataCollection;
                            }
                            else if(lfipObj.value_ === 'section_2_')
                            {
                                lCollection = this.state.mSection2DataCollection;
                            }
        
                            if(lRetObj && lCollection)
                            {
                                for(var lcInd0=0; lcInd0<lCollection.length; lcInd0++)
                                {
                                    var lcObj0 = lCollection[lcInd0];
                                    var lObj0 = null;

                                    if(lfipInd === 0)
                                    {
                                        lObj0 = {}
                                        lRetObj.push(lObj0);
                                    }
                                    else
                                    {
                                        lObj0 = lRetObj[lcInd0];
                                    }

                                    if(lfipObj.property_ === 'collection')
                                    {
                                        lObj0[lfipObj.selection_property_] = lcObj0[lfipObj.selection_value_];
                                    }
                                    else if(lfipObj.property_ === 'form_data')
                                    {
                                        if(lSelectedData)
                                        {
                                            lObj0[lfipObj.selection_property_] = lSelectedData[lfipObj.selection_value_];
                                        }
                                        else
                                        {
                                            lObj0[lfipObj.selection_property_] = '';
                                        }
                                    }
                                    else if(lfipObj.property_ === '#CONSTANT#')
                                    {
                                        lObj0[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                                    }
                                }
                            }
                        }
                        else if(lFormItem.control_type_ === 'aggregation')
                        {
                            if(lfipObj.property_ === 'form_data')
                            {
                                if(lRetObj)
                                {
                                    if(lRetObj instanceof Array)
                                    {
                                        for(var lrInd=0; lrInd<lRetObj.length; lrInd++)
                                        {
                                            var lrSelObj = lRetObj[lrInd];
                                            if(lSelectedData)
                                            {
                                                lrSelObj[lfipObj.selection_property_] = lSelectedData[lfipObj.selection_value_];
                                            }
                                            else
                                            {
                                                lrSelObj[lfipObj.selection_property_] = '';
                                            }
                                        }
                                    }
                                }                
                            }                          
                            else
                            {
                                if(lSelectedData)
                                {
                                    var lSelection = lSelectedData[lfipObj.selection_value_];
                                    if(lSelection && (lSelection instanceof Array) && (lSelection.length > 0))
                                    {
                                        if(!lRetObj)
                                        {
                                            lRetObj = [];
                                        }
        
                                        for(var lsInd=0; lsInd<lSelection.length; lsInd++)
                                        {
                                            var lSelObj = lSelection[lsInd];
        
                                            var lObj = {}
                                            lObj[lfipObj.selection_property_] =  lSelObj[lfipObj.property_];
                                            lRetObj.push(lObj);
                                        }
                                    }
                                }
                            }
                        }
                        else
                        {
                            if(!lRetObj)
                            {
                                lRetObj = {}
                            }
        
                            if(lfipObj.property_ === 'collection')
                            {
                                var lSummaryData = null;
                                if(lfipObj.value_ === 'section_1_summary_')
                                {
                                    lSummaryData = this.state.mSection1Summary;
                                }
                                else if(lfipObj.value_ === 'section_2_summary_')
                                {
                                    lSummaryData = this.state.mSection2Summary;
                                }

                                if(lSummaryData && lSummaryData.hasOwnProperty(lfipObj.selection_value_))
                                {
                                    lRetObj[lfipObj.selection_property_] = lSummaryData[lfipObj.selection_value_];
                                }
                                else
                                {
                                    lRetObj[lfipObj.selection_property_] = '';
                                }
                            }
                            else
                            {
                                if(lSelectedData)
                                {
                                    lRetObj[lfipObj.selection_property_] = lSelectedData[lfipObj.selection_value_];    
                                }
                                else
                                {
                                    lRetObj[lfipObj.selection_property_] = '';
                                }
                            }
                        }                    
                    }
                }
            }
        }

        return lRetObj;
    }

    process_tab_change(lEvent)
    {
        console.log(lEvent);
    }

    render()
    {
        this.cfSetControlValue();

        var lDetailsForm = null;
        if(this.state.mDetailsFlag)
        {
            var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
            lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.process_close);
        }
        
        var lDetailsActionBar = 
            <div id="ID_DETAILS_ACTION_BAR">
                <CSCOHorizontalActionBar 
                    align       = "left"
                    pType       = 'DETAILS_ACTION_BAR'
                    parent      = { this }
                    actionList  = { this.state.mFormDetailsActions }
                />
            </div>

        var lHeader =  
            <div id="ID_HEADING">
                { this.state.mHeader }
            </div>

        var lTabMenu =
            <div id="ID_TAB_MENU">
                <TabMenu className='childMenu' model={ this.state.mTabList }/>
            </div>

        var lSection = null;
        if(this.state.mSelectionFlag === 'section_1')
        {
            lSection = 
                <div style={{visible: (this.state.mSelectionFlag === 'section_1'), marginBottom: '10px'}}>
                    { this.state.mSection1 }
                    { lDetailsActionBar }
                </div>
        }
        else if(this.state.mSelectionFlag === 'section_2')
        {
            lSection = 
                <div style={{marginBottom: '10px'}}>
                    { this.state.mSection2 }
                </div>
        }

        return(
            <>
                { lHeader }

                { lTabMenu }           

                { lSection }

                { lDetailsForm }
            </>
        );
    }
}

export default CSCODetailsForm19;