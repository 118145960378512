import React, { useState, useRef, useEffect } from 'react';
import QRCode from "qrcode.react"
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import CSConfigUtil from '../util/CSConfigUtil'; 
import CSServiceUtil from '../util/CSServiceUtil'; 
import CSRequestUtil from '../util/CSRequestUtil'; 
import CSUtil from "../util/CSUtil";

const mServiceUtil = CSServiceUtil.cfGetInstance();

const CSDeliveryChallanForm2 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const [ mCompanyDetails, setCompanyDetails ] = useState(
        {
            mCompanyName: CSConfigUtil.mCompanyName,
            mAddress1   : '',
            mAddress2   : '',
            mPhone1     : '',
            mPhone2     : '',
            mEmail      : '',
            mWebsite    : ''
        }, []
    );

    const mCompanyLogo = CSConfigUtil.mCompanyLogo;

    const [ mFormTitle, setFormTitle ] = useState('Delivery Challan');

    const [ mCustomerDetails, setCustomerDetails ] = useState(
        {
            mTitle          : 'Customer Details',
            mCustomerName   : '', 
            mCustomerAddress: '', 
            mDeliveryAddress: '', 
            mContactNumber  : '', 
            mPlaceOfSupply  : '', 
            mGstNo          : '',
            mPhoneNumber    : '',
            mEmail          : '',
        }, []
    );

    const [ mChallanDetails, setChallanDetails ] = useState(
        {
            mTitle          : 'Challan Details',
            mChallanNo      : '', 
            mShipmentDate   : '', 
            mEwayBillNo     : '', 
            mLogistics      : '', 
            mLrNo           : '', 
            mVehicleNo      : '',
            mDriverName     : '',
            mDriverContactNo: '',
            mQrCode         : 'Test'
        }, [lProps]
    );

    const [ mShipmentContents, setShipmentContents ] = useState(null);
    const [ mPackageContents, setPackageContents ] = useState(null);

    const [ mTermsTitle, setTermsTitle ] = useState('Terms & Conditions');
    const [ mTerms, setTerms ] = useState(null);
    const [ mConditions, setConditions ] = useState(null);

    const [ mServiceCode, setServiceCode] = useState(null);
    const [ mRequestId, setRequestId] = useState(null);

    const [ mFooter, setFooter ] = useState("Delivery Challan was created on a computer and is valid without the signature and seal.");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pCriteria && lProps.pFormType && lProps.pFormProperties)
                {
                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        if(lPropObj.type_ === 'initialize')
                        {
                            const lValue = lPropObj.value_;
                            if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'terms_title_')
                            {
                                setTermsTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'customer_section_title_')
                            {
                                setCustomerDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'challan_section_title_')
                            {
                                setChallanDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'request_id_')
                            {
                                setRequestId(lValue);
                            }
                            else if(lPropObj.property_ === 'service_code_')
                            {
                                setServiceCode(lValue);
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lValue);
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, [lProps, setChallanDetails, setCustomerDetails]
    );

    useEffect(() =>
        {
            const fnInitializeData = () =>
            {
                if(mServiceCode && lProps.pCriteria)
                {
                    var lServiceObject = mServiceUtil.cfGetServiceObject(mServiceCode);
                    if(lServiceObject)
                    {
                        var lCriteria = lProps.pCriteria;
                        lCriteria.office_code_ = CSConfigUtil.mOfficeCode;
                        var lRequestUtil = new CSRequestUtil('GET', mServiceCode, lServiceObject.type_, mRequestId, lServiceObject);
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse) =>
                            {
                                if(lResponse)
                                {
                                    if(lResponse.hasOwnProperty('challan_details_') && (lResponse.challan_details_.length > 0))
                                    {
                                        var lChallanDetails = lResponse.challan_details_[0];
                                        setChallanDetails(lPrevState => 
                                            { 
                                                return { 
                                                    ...lPrevState, 
                                                    mChallanNo      : lChallanDetails.goods_dispatch_no_, 
                                                    mShipmentDate   : lChallanDetails.shipment_date_, 
                                                    mEwayBillNo     : lChallanDetails.eway_bill_no_, 
                                                    mLogistics      : lChallanDetails.logistics_, 
                                                    mLrNo           : lChallanDetails.lrn_, 
                                                    mVehicleNo      : lChallanDetails.vehicle_no_,
                                                    mDriverName     : lChallanDetails.driver_name_,
                                                    mDriverContactNo: lChallanDetails.driver_contact_no_,
                                                    mQrCode         : lChallanDetails.qr_code_text_
                                                } 
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('company_details_') && (lResponse.company_details_.length > 0))
                                    {
                                        var lCompanyDetails = lResponse.company_details_[0];
                                        setCompanyDetails(lPrevState => 
                                            { 
                                                return { 
                                                    ...lPrevState, 
                                                    mAddress1   : lCompanyDetails.office_description_, 
                                                    mAddress2   : lCompanyDetails.office_address_,
                                                    mPhone1     : lCompanyDetails.office_phone_1_,
                                                    mPhone2     : lCompanyDetails.office_phone_2_,
                                                    mEmail      : lCompanyDetails.email_,
                                                    mWebsite    : lCompanyDetails.website_
                                                }
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('customer_details_') && (lResponse.customer_details_.length > 0))
                                    {
                                        var lCustomerDetails = lResponse.customer_details_[0];
                                        setCustomerDetails(lPrevState => 
                                            { 
                                                return { 
                                                    ...lPrevState, 
                                                    mCustomerAddress: lCustomerDetails.customer_address_, 
                                                    mCustomerName   : lCustomerDetails.customer_name_,
                                                    mDeliveryAddress: lCustomerDetails.delivery_address_,
                                                    mEmail          : lCustomerDetails.email_,
                                                    mGstNo          : lCustomerDetails.gst_no_,
                                                    mContactNumber  : lCustomerDetails.mobile_phone_,
                                                    mPhoneNumber    : lCustomerDetails.phone_no_,
                                                } 
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('shipment_content_') && (lResponse.shipment_content_.length > 0))
                                    {
                                        var lDataCollection = [];
                                        var lrcInd = 0;
                                        for(var ldInd=0; ldInd<lResponse.shipment_content_.length; ldInd++)
                                        {
                                            var lDataObj = lResponse.shipment_content_[ldInd];
                                            var lColDataList = [];

                                            lColDataList.push(<td key={lrcInd++}>{ldInd + 1}</td>);
                                            lColDataList.push(<td key={lrcInd++}>{lDataObj.package_id_}</td>);
                                            lColDataList.push(<td key={lrcInd++}> </td>);
                                            lColDataList.push(<td key={lrcInd++}>{lDataObj.quantity_}</td>);
                                            lColDataList.push(<td key={lrcInd++}>{lDataObj.content_qty_}</td>);
                    
                                            lDataCollection.push(<tr key={ldInd + 1}>{lColDataList}</tr>);
                                        }
                    
                                        setShipmentContents(lDataCollection);
                                    }

                                    if(lResponse.hasOwnProperty('package_contents_') && (lResponse.package_contents_.length > 0))
                                    {
                                        var lPackageContentList = [];
                                        var lpcInd = 0;
                                        for(var lpInd=0; lpInd<lResponse.package_contents_.length; lpInd++)
                                        {
                                            var lPackage = lResponse.package_contents_[lpInd];
                                            var lPackageContent = [];

                                            lPackageContent.push(<td key={lpcInd++}>{lPackage.package_id_}</td>);
                                            lPackageContent.push(<td key={lpcInd++}>{lPackage.unit_}</td>);
                                            lPackageContent.push(<td key={lpcInd++}>{lPackage.cut_piece_id_}</td>);
                                            lPackageContent.push(<td key={lpcInd++}>{lPackage.item_code_}</td>);
                                            lPackageContent.push(<td key={lpcInd++}>{lPackage.cut_piece_length_}</td>);
                                            lPackageContent.push(<td key={lpcInd++}>{lPackage.cut_piece_width_}</td>);
                    
                                            lPackageContentList.push(<tr key={lpInd + 1}>{lPackageContent}</tr>);
                                        }
                    
                                        setPackageContents(lPackageContentList);
                                    }

                                    if(lResponse.hasOwnProperty('terms_') && (lResponse.terms_.length > 0))
                                    {
                                        var lTerms = [];
                                        for(var ltInd=0; ltInd<lResponse.terms_.length; ltInd++)
                                        {
                                            var lTermObj = lResponse.terms_[ltInd];
                                            var lTerm = 
                                                <div key={ltInd}>
                                                    {lTermObj.sequence_} &nbsp;.&nbsp;&nbsp; {lTermObj.details_}
                                                </div>
                    
                                            lTerms.push(lTerm);
                                        }
                    
                                        setTerms(lTerms);
                                    }

                                    if(lResponse.hasOwnProperty('conditions_') && (lResponse.conditions_.length > 0))
                                    {
                                        var lConditions = [];
                                        for(var lcInd=0; lcInd<lResponse.conditions_.length; lcInd++)
                                        {
                                            var lConditionObj = lResponse.conditions_[lcInd];
                                            var lCondition = 
                                                <div key={lcInd}>
                                                    {lConditionObj.sequence_} &nbsp;.&nbsp;&nbsp; {lConditionObj.details_}
                                                </div>
                    
                                            lConditions.push(lCondition);
                                        }
                    
                                        setConditions(lConditions);
                                    }
                                }
                            }
                        );
                    }
                }
            }

            fnInitializeData();
        }, [mServiceCode, mRequestId, lProps.pCriteria]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => (lProps.fnEnablePrint) ? lProps.fnEnablePrint(false) : null }
        />
    );

    var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    const lForm = 
        <div id="dc" ref={mComponentRef}>
            <div className="dc overflow-auto">
                <div style={{minWidth: '600px'}}>
                    <header>
                        <div className="row">
                            <div className="company-details">
                                <h2 className="name">
                                    { mCompanyDetails.mCompanyName }
                                </h2>

                                <div>
                                    { mCompanyDetails.mAddress1 }
                                </div>

                                <div>
                                    { mCompanyDetails.mAddress2 }
                                </div>
                            </div>
                    
                            <div className="company-contact">
                                <div>Tel No. : { mCompanyDetails.mPhone1 }</div>
                                <div>Tel No. : { mCompanyDetails.mPhone2 }</div>
                                <div>Website : { mCompanyDetails.mWebsite }</div>
                                <div>EMail   : { mCompanyDetails.mEmail }</div>
                            </div>
                    
                            <div className="logo">
                                <img
                                    src={ mCompanyLogo } 
                                    width="100%"
                                    alt=""/>
                            </div>
                        </div>
                    </header>

                    <main>
                        <div className="title">
                            <h1>{ mFormTitle }</h1>
                        </div>

                        <div className="summary">
                            <div className="customer-details">
                                <h3 className="customer-name">
                                    { mCustomerDetails.mTitle }
                                </h3>

                                <div className="form-item-row"> 
                                    <div style={{width: '100px'}}>M/S :</div>
                                    <div style={{width: '100%'}}>{mCustomerDetails.mCustomerName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '100px'}}>Address :</div>
                                    <div style={{width: '100%'}}>{mCustomerDetails.mCustomerAddress}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '100px'}}>Delivery Address :</div>
                                    <div style={{width: '100%'}}>{mCustomerDetails.mDeliveryAddress}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '100px'}}>Phone No :</div>
                                    <div style={{width: '100%'}}>{mCustomerDetails.mContactNumber}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '100px'}}>Place :</div>
                                    <div style={{width: '100%'}}>Hyderabad</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '100px'}}>GSTIN :</div>
                                    <div style={{width: '100%'}}>{mCustomerDetails.mGstNo}</div>
                                </div>
                            </div>
                    
                            <div className="challan-details">
                                <h3 className="challan-name">
                                    { mChallanDetails.mTitle }
                                </h3>
                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>Challan No :</div>
                                    <div>{mChallanDetails.mChallanNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>Shipment Date :</div>
                                    <div>{mChallanDetails.mShipmentDate}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>E-Way Bill No :</div>
                                    <div>{mChallanDetails.mEwayBillNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>Logistics :</div>
                                    <div>{mChallanDetails.mLogistics}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>LRN :</div>
                                    <div>{mChallanDetails.mLrNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>Vehicle No :</div>
                                    <div>{mChallanDetails.mVehicleNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>Driver Name :</div>
                                    <div>{mChallanDetails.mDriverName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px'}}>Driver Contact :</div>
                                    <div>{mChallanDetails.mDriverContactNo}</div>
                                </div>
                            </div>
                    
                            <div className="qrcode">
                                <QRCode
                                    bgColor = "#FFFFFF"
                                    level   = "L"
                                    style   = {{ width: '72px', height: '72px' }}
                                    value   = {(mChallanDetails.mQrCode) ? mChallanDetails.mQrCode : ''}
                                />
                            </div>
                        </div>
                        
                        <div className="shipment-contents">
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Package Id</th>
                                        <th>HSN Number</th>
                                        <th>Quantity</th>
                                        <th>Content Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mShipmentContents}
                                </tbody>
                            </table>
                        </div>

                        <>
                            <h3 className="package-contents-title">
                                Package Contents
                            </h3>
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>Package Id</th>
                                        <th>Cabinet</th>
                                        <th>Cut Piece Id</th>
                                        <th>Material</th>
                                        <th>Length</th>
                                        <th>Width</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mPackageContents}
                                </tbody>
                            </table>
                        </>

                        <div className="terms-conditions">
                            <div className="terms">
                                <h3 className="terms-heading">
                                    { mTermsTitle }
                                </h3>

                                { mTerms }
                            </div>
                    
                            <div className="conditions">
                                { mConditions }
                            </div>
                        </div>
                    </main>
                    <footer>
                        { mFooter }
                    </footer>
                </div>

                <div></div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            header    = {lHeader}
            appendTo  = { document.body }
            onHide    = { () => (lProps.fnEnablePrint) ? lProps.fnEnablePrint(false) : null }>
                { lForm }
        </Dialog>
    );
}

export default CSDeliveryChallanForm2;