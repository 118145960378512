import React, { Component } from "react"
import {PickList} from 'primereact/picklist';

class CSCOPickList extends Component
{
    constructor(props)
    {
        super(props);
        this.state = 
            {
                mId                	: this.props.pId,
                mControlType       	: this.props.pControlType,
                mDataField         	: this.props.pDataField,
                mSourceList         : this.props.pSourceList,
                mSourceHeader       : (this.props.pSourceHeader) ? this.props.pSourceHeader : 'Available',
                mTargetList         : (this.props.pTargetList) ? this.props.pTargetList : [],
                mTargetHeader       : (this.props.pTargetHeader) ? this.props.pTargetHeader : 'Selected',
                mTargetControls     : (!this.props.pTargetControls) ? true : false,
                mTemplateItems      : this.props.pTemplateItems,
                mSaveActionItem     : this.props.pSaveActionItem,
                mOrigTargetList     : (this.props.pTargetList) ? this.props.pTargetList : []
            };

        this.cfListTemplate = this.cfListTemplate.bind(this);
        this.cfOnChange = this.cfOnChange.bind(this);
    }

    componentDidMount()
    {
        if(this.state.mSourceList)
        {
            this.state.mSourceList.map((lElement, index) => 
                {
                    lElement.key = index;

                    return {lElement};
                }
            );
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            let lSourceList = [];
            if(!this.state.mSourceList)
            {
                if(this.props.pSourceList)
                {
                    lSourceList = this.props.pSourceList.map((lElement, index) => 
                        {
                            lElement.key = index;
        
                            return lElement;
                        }
                    );
                }            
                
                let lTargetList = [];
                if(this.props.pTargetList)
                {
                    lTargetList = this.props.pTargetList.map((lElement, index) => 
                        {
                            lElement.key = index;

                            return lElement;
                        }
                    );
                }
            
                this.setState({mSourceList : lSourceList, mTargetList : lTargetList});
            }
        }
    }

    cfListTemplate(lTmplt) 
    {
        var lListTemplate = null;
        if(this.props.pTemplateItems)
        {
            var lListItem = null;
            for(var lInd=0; lInd<this.props.pTemplateItems.length; lInd++)
            {
                var lTmpltItem = this.props.pTemplateItems[lInd];
                if(!lListItem)
                {
                    lListItem = lTmplt[lTmpltItem];
                }
                else
                {
                    lListItem = lListItem + ' - ' + lTmplt[lTmpltItem];
                }
            }

            lListTemplate = 
                <div className="p-clearfix">
                    <div style={{ fontSize: '12px', float: 'left', margin: '5px 5px 0 0' }}>
                        { lListItem }
                    </div>
                </div>
        }

        return lListTemplate;
    }
    
    cfOnChange(event) 
    {
        var lData = event.target;
        if(this.props.pEnableSaveFn)
        {
            if(lData !== this.state.mOrigTargetList)
            {
                this.props.pEnableSaveFn(true, this.props.pSectionFlag);
            }
        }

        if(this.props.pProcessChange)
        {
            var lDataObj = {}
            lDataObj.control_type_  = 'pick_list';
            lDataObj.data_field_    = this.state.mDataField;
            lDataObj.value_         = lData;
            lDataObj.id_            = this.state.mId;

            this.props.pProcessChange(lDataObj);
        }

        this.setState(
            {
                mSourceList: event.source,
                mTargetList: event.target
            }
        );
    }

    render() 
    {
        return (
            <div style = {{ marginTop: '10px', marginBottom: '5px'}}>
                <PickList 
                    source              = { this.state.mSourceList } 
                    target              = { this.state.mTargetList } 
                    itemTemplate        = { this.cfListTemplate }
                    sourceHeader        = { this.state.mSourceHeader }
                    targetHeader        = { this.state.mTargetHeader }
                    responsive          = 'true'
                    showSourceControls  = 'false'
                    showTargetControls  = { this.state.mTargetControls }
                    sourceStyle         = { {minheight: '225px', maxHeight: '300px'} } 
                    targetStyle         = { {minheight: '225px', maxHeight: '300px'} }
                    onChange            = { this.cfOnChange }>
                </PickList>
            </div>
        );
    }
}

export default CSCOPickList;
