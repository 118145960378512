import React, { Component } from "react"

import CSCOVerticalSection from './CSCOVerticalSection'
import CSUtil from "../util/CSUtil";
import CSFormUtil from "../util/CSFormUtil"
import { cfGetConstantValue} from "../util/CSConstantUtil";

class CSCODetailsHeader1 extends Component
{
    mComponentCode      = 'CS_DETAILS_HEADING_SECTION_1';
    mNumSections 		= 3;
    mMaxSectionItems 	= 1;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mFormCode                   : null,
            mFormItems                  : null,
            mFormData                   : null,
            mWidth                      : this.props.pWidth,
            mHeight                     : this.props.pHeight,
            mFormProperties             : this.props.pFormProperties,
            mFormItemProperties         : this.props.pFormItemProperties,
            mParentFormItems            : [],
            mIdFieldObjectMap           : {},
            mParentFormItemChildrenMap  : {},
            mProcessChange              : this.props.process_change_,
            mSaveActionItem             : this.props.pSaveActionItem,
            mDefaultDataField           : 'selected_data_'
        }

        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfSetControlValue          = this.cfSetControlValue.bind(this);
        this.cfGetSections              = this.cfGetSections.bind(this);
        this.cfEnableSaveAction         = this.cfEnableSaveAction.bind(this);
        this.cfSetFormData              = this.cfSetFormData.bind(this);
        this.cfOnPopout                 = this.cfOnPopout.bind(this);

        if(this.props.pNumSections)
        {
            this.mNumSections = this.props.pNumSections;
        }

        if(this.props.pMaxSectionItems)
        {
            this.mMaxSectionItems = this.props.pMaxSectionItems;
        }

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            if(this.props.pSelectedData.hasOwnProperty(this.state.mDefaultDataField))
            {
                this.state.mFormData[this.state.mDefaultDataField] = this.props.pSelectedData[this.state.mDefaultDataField];
            }
            else
            {
                this.state.mFormData[this.state.mDefaultDataField] = this.props.pSelectedData;
            }
        }

        if(this.props.pFormItems)
        {
            var lFormItemList = [];
            CSUtil.cfSort(this.props.pFormItems, 'sequence_')
            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                if(lFormItem.cs_form_component_code_ === this.mComponentCode)
                {
                    lFormItem.mValue = null;
                    this.state.mIdFieldObjectMap[lFormItem.id_] = lFormItem;
                    lFormItemList.push(lFormItem);
                    if(lFormItem.parent_id_ !== '')
                    {
                        var lChildList = this.state.mParentFormItemChildrenMap[lFormItem.parent_id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                            this.state.mParentFormItemChildrenMap[lFormItem.parent_id_] = lChildList;
                        }

                        lChildList.push(lFormItem);
                    }
                    else
                    {
                        this.state.mParentFormItems.push(lFormItem);
                    }

                    if(!this.state.mFormCode)
                    {
                        this.state.mFormCode = lFormItem.cs_form_code_;
                    }
                }
            }

            this.state.mFormItems = lFormItemList;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if((lFormProperty.type_ === 'initialize') && (this.state.mFormCode === lFormProperty.cs_form_code_))
                {
                    if (lFormProperty.property_ === 'default_data_field_')
                    {
                        this.state.mDefaultDataField = lFormProperty.value_;
                    }
                }
            }
        }
    }

    componentDidMount()
    {
        this.cfSetFormData();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            var lFormData = this.state.mFormData;
            if(this.props.pFormProperties)
            {
                for(var lFipInd=0; lFipInd<this.props.pFormProperties.length; lFipInd++)
                {
                    var lFipObj = this.props.pFormProperties[lFipInd];
                    if(lFipObj.cs_form_code_ === this.state.mFormCode)
                    {
                        if((lFipObj.type_ === 'initialize') && ((lFipObj.property_ === 'data_') || (lFipObj.property_ === 'collection_data_')))
                        {
                            if(this.props.pFormData && this.props.pFormData.hasOwnProperty(lFipObj.value_))
                            {
                                var lDataObj = null;
                                var lData = this.props.pFormData[lFipObj.value_];
                                if(lData)
                                {
                                    if(lFipObj.property_ === 'data_')
                                    {
                                        if(lData instanceof Array && (lData.length > 0))
                                        {
                                            lDataObj = lData[0];
                                        }
                                        else
                                        {
                                            lDataObj = lData;
                                        }

                                        if(lDataObj)
                                        {
                                            lFormData[lFipObj.value_] = lDataObj;
                                        }
                                    }
                                    else
                                    {
                                        lFormData[lFipObj.value_] = lData;
                                    }
                                }
                                else
                                {
                                    if(lFipObj.property_ === 'data_')
                                    {
                                        lFormData[lFipObj.value_] = {};
                                    }
                                    else
                                    {
                                        lFormData[lFipObj.value_] = [];
                                    }
                                }
                            }
                            else
                            {
                                lFormData[lFipObj.value_] = {};
                            }
                        }
                    }
                }
            }

            this.setState({mFormData : lFormData});
        }
    }

    cfSetFormData()
    {
        var lFormData = this.state.mFormData;
        if(this.props.pFormProperties)
        {
            for(var lFipInd=0; lFipInd<this.props.pFormProperties.length; lFipInd++)
            {
                var lFipObj = this.props.pFormProperties[lFipInd];
                if(lFipObj.cs_form_code_ === this.state.mFormCode)
                {
                    var lDataObj = null;
                    if((lFipObj.type_ === 'initialize') && (lFipObj.property_ === 'data_'))
                    {
                        if(this.props.pFormData)
                        {
                            if(!lFormData)
                            {
                                lFormData = {}
                            }

                            if(this.props.pFormData.hasOwnProperty(lFipObj.value_))
                            {
                                var lData = this.props.pFormData[lFipObj.value_];
                                if(lData)
                                {
                                    if(lData instanceof Array && (lData.length > 0))
                                    {
                                        lDataObj = lData[0];
                                    }
                                    else
                                    {
                                        lDataObj = lData;
                                    }

                                    if(lDataObj)
                                    {
                                        lFormData[lFipObj.value_] = lDataObj;
                                    }
                                }
                                else
                                {
                                    lFormData[lFipObj.value_] = {};
                                }
                            }
                            else
                            {
                                lFormData[lFipObj.value_] = {};
                            }
                        }
                        else
                        {
                            if(!lFormData)
                            {
                                lFormData = {};
                            }

                            lFormData[lFipObj.value_] = {};
                        }
                    }
                }
            }

            if(lFormData)
            {
                this.setState({mFormData : CSUtil.cfGetObjectCopy(lFormData)});

                if(lFormData && this.props.pEnableSaveFn)
                {
                    this.props.pEnableSaveFn(this.cfEnableSaveAction(lFormData), 1);
                }
            }
        }
    }

    cfGetSections(lFormItemList)
    {
        var lSections = [];
        var lNumSections = 1;
        var lNumItemsPerSection = 0;
        if(lFormItemList)
        {
            var lSectionNumControlMap = {};
            var lMaxSectionItems = this.mMaxSectionItems;
            var lPicFormItem = null;
            CSUtil.cfSort(lFormItemList, 'sequence_')

            for(var ind_0=0; ind_0<lFormItemList.length; ind_0++)
            {
                var lPFormItem = lFormItemList[ind_0];
                if(lPFormItem.control_type_ === 'image')
                {
                    lPicFormItem = lPFormItem;
                    break;
                }
            }

            if(lPicFormItem)
            {
                if(lFormItemList.length === 1)
                {
                    if(this.mNumSections >= 1)
                    {
                        lNumSections = 1;
                    }
                }
                else if(lFormItemList.length <= this.mMaxSectionItems)
                {
                    lNumSections = 2;
                }
                else if ((lFormItemList.length / 2) <= this.mMaxSectionItems)
                {
                    lNumSections = 3;
                }
                else
                {
                    lNumSections = 4;
                }

                lNumItemsPerSection = lFormItemList.length/(lNumSections - 1);
            }
            else
            {
                if(lFormItemList.length <= this.mMaxSectionItems)
                {
                    lNumSections = 1;
                }
                else if ((lFormItemList.length / 2 ) <= this.mMaxSectionItems)
                {
                    lNumSections = 2;
                }
                else
                {
                    lNumSections = 3;
                }
             
                lNumItemsPerSection = lFormItemList.length/lNumSections;
            }

            for(var ind=0; ind<lFormItemList.length; ind++)
            {
                var lFormItem = lFormItemList[ind];
                if(lFormItem.control_type_ !== 'image')
                {
                    var lSequence = parseInt(lFormItem.sequence_, 10);
                    var lIndex = Math.ceil(lSequence/lNumItemsPerSection) - 1;
                    var lActIndex = lIndex;

                    if(ind === 0)
                    {
                        if(lPicFormItem)
                        {
                            var lPicSectionControlList = [];
                            lSectionNumControlMap[lActIndex] = lPicSectionControlList;

                            var lCtrl = this.mFormUtil.cfGetControl(lPicFormItem);
                            if(lCtrl)
                            {
                                lPicSectionControlList.push(lCtrl)
                            }
                            else
                            {
                                lPicFormItem = null;
                            }
                            
                        }
                    }

                    if(lPicFormItem)
                    {
                        lActIndex = lIndex + 1;
                    }

                    var lSectionControlList = null;
                    lSectionControlList = lSectionNumControlMap[lActIndex];
                    if(!lSectionControlList)
                    {
                        lSectionControlList = [];
                        lSectionNumControlMap[lActIndex] = lSectionControlList;
                    }
        
                    lSectionControlList.push(this.mFormUtil.cfGetControl(lFormItem, this.cfProcessChange, null, null, this.cfOnPopout))
                    lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList.length);
                }
            }

            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FFFFFF";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }

                var lImageSectionWidth;
                if((lSectionNum === 0) && (lPicFormItem))
                {
                    lImageSectionWidth = "130px"
                    lSection = <CSCOVerticalSection 
                        pBackgroundColor    = {lBkgColor} 
                        pId                 = {lSectionNum} 
                        key                 = {lSectionNum}
                        pWidth              = {lImageSectionWidth}
                        pNumSections        = {1}
                        pMaxSectionItems    = {lMaxSectionItems}
                        pIsCriteriaSection  = {false}
                        pChildren           = {lSectionNumControlMap[lSectionNum]}/>
                }
                else
                {
                    lSection = <CSCOVerticalSection 
                        pBackgroundColor    = {lBkgColor} 
                        pId                 = {lSectionNum} 
                        key                 = {lSectionNum}
                        pImageSectionWidth  = {lImageSectionWidth}
                        pNumSections        = {(lPicFormItem)? lNumSections - 1 : lNumSections}
                        pMaxSectionItems    = {lMaxSectionItems}
                        pIsCriteriaSection  = {false}
                        pChildren           = {lSectionNumControlMap[lSectionNum]}/>
                }

                lSections.push(lSection);
            }
        }

        return lSections;
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            if(lFormData)
            {
                var lSelectedData = lFormData[this.state.mDefaultDataField];
                if(lSelectedData)
                {
                    lSelectedData[lData.data_field_] = lData.value_;
                    lData.data_object_ = this.state.mDefaultDataField;
                }
            }
        }

        if(lFormData && this.props.pEnableSaveFn)
        {
            this.props.pEnableSaveFn(this.cfEnableSaveAction(lFormData), 1);
        }

        if(this.props.pProcessChange)
        {
            this.props.pProcessChange(lData);
        }
    }

    cfOnPopout(lEvent, lFormItem)
    {
        if(lEvent && lFormItem && this.props.pOnPopout)
        {
            this.props.pOnPopout(lEvent, lFormItem);
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            var lFormData = this.state.mFormData;
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                if(lFormData)
                {   
                    var lSelectedData = lFormData[this.state.mDefaultDataField];
                    if(lSelectedData && lSelectedData.hasOwnProperty(lFormItem.data_field_))
                    {
                        if(lFormItem.constant_ && ((lFormItem.control_type_ === 'constant') || (lFormItem.control_type_ === 'text_input')))
                        {
                            if(lSelectedData[lFormItem.data_field_])
                            {
                                lFormItem.mValue = lSelectedData[lFormItem.data_field_];
                            }
                            else
                            {
                                lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                                lSelectedData[lFormItem.data_field_] = lFormItem.mValue;
                            }
                        }
                        else 
                        {
                            if(lFormItem.dependent_id_ && this.mFormUtil)
                            {
                                this.mFormUtil.cfSetDependentFieldData(lFormItem, this.state.mIdFieldObjectMap);
                            }

                            if(lFormItem.hasOwnProperty('mClearSelection') && (lFormItem.mClearSelection === true))
                            {
                                lFormItem.mValue = null;
                            }
                            else
                            {
                                lFormItem.mValue = lSelectedData[lFormItem.data_field_];
                            }
                        }
                    }
                    else
                    {
//                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                        if(lFormItem.constant_ && ((lFormItem.control_type_ === 'constant') || (lFormItem.control_type_ === 'text_input')))
                        {
                            lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                        }
                        else
                        {
                            lFormItem.mValue = '';
                        }
                    }
                }
                else
                {
                    lFormItem.mValue = '';
                }
            }
        }
    }

    cfEnableSaveAction(lFormData)
    {
        var lEnabled = true;
        if(this.state.mFormItems && lFormData)
        {
            var lFlag = 1;
            for(var lInd=0; lInd<this.state.mParentFormItems.length; lInd++)
            {
                var lParentObj = this.state.mParentFormItems[lInd];
                var lChildList = this.state.mParentFormItemChildrenMap[lParentObj.id_];
                if(lChildList && (lChildList.length > 0))
                {
                    if(lFormData && lFormData.hasOwnProperty(lParentObj.data_field_))
                    {
                        if(lFlag === 1)
                        {
                            lFlag = 2;
                        }
                        else if(lFlag !== 2)
                        {
                            lFlag = 3;
                        }

                        var lParentFormData = lFormData[lParentObj.data_field_];
                        if(lParentFormData)
                        {
                            lEnabled = CSFormUtil.cfEnableAction(this.state.mSaveActionItem, lParentFormData, lChildList);
                            if(!lEnabled)
                            {
                                break;
                            }
                        }
                    }
                }
                else
                {
                    if(lFlag === 2)
                    {
                        lFlag = 3;
                    }
                    else if(lFlag === 1)
                    {
                        lFlag = 0;
                    }
                }
            }

            if(lEnabled)
            {
                if(lFlag !== 2)
                {
                    var lDataObj = null;
                    if(this.state.mFormData && this.state.mFormData.hasOwnProperty(this.state.mDefaultDataField))
                    {
                        lDataObj = this.state.mFormData[this.state.mDefaultDataField];
                    }
                    else
                    {
                        lDataObj = this.state.mFormData;
                    }
                    
                    lEnabled = CSFormUtil.cfEnableAction(this.state.mSaveActionItem, lDataObj, this.state.mParentFormItems);
                }
            }
        }

        return lEnabled;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormItemList = [];
        if(this.state.mFormItems && (this.state.mFormItems.length > 0))
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lSection = null;
        if(lFormItemList.length > 0)
        {
            lSection = 
                <div className="form-item-1">
                    { this.cfGetSections(lFormItemList) }
                </div>
        }

        return lSection;
    }
}

export default CSCODetailsHeader1;
