import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea';

const CSQuery = () =>
{
    const dt = useRef(null);
    const [m_query_, setQuery] = useState("");

    return (
        <div className="cs-query-group">
            <div className="cs-query">
                <div className="cs-query-form-item">
                    <label className="cs-query-section-label" >Data Source</label>
                    <AutoComplete id="ID_QUERY_DATA_SOURCE" inputClassName="cs-query-section-control" />
                </div>

                <InputTextarea id="ID_SERVICE_REMARKS" className="cs-query-script" value={m_query_} autoResize={false} onChange={(e)=>{setQuery(e.value)}}/>
            </div>
            
            <div className="cs-query-criteria">
                <DataTable ref={dt} style={{width : '100%'}} header="Criteria Details">
                    <Column field="criteria_"          style={{fontSize:'13px', width:'5em'}}   header="Criteria"/>
                    <Column field="alias_"             style={{fontSize:'13px', width:'5em'}}   header="Alias"/>
                    <Column field="type_"              style={{fontSize:'13px', width:'3em'}}   header="Type"/>
                    <Column field="is_constant_"       style={{fontSize:'13px', width:'3em'}}   header="Constant ?"/>
                    <Column field="default_value_"     style={{fontSize:'13px', width:'3em'}}   header="Default"/>
                </DataTable>
            </div>
        </div>
    );
}

export default CSQuery;
