import React from "react"

import CSUtil from "../../util/CSUtil";
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil"
import CSCOGrid from "../../components/CSCOGrid"
import { cfGetConstantValue} from "../../util/CSConstantUtil";

class CSCOStandardReport1 extends React.Component
{
    mUnmountFlag = false;
    constructor(props)
    {
       super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mAppId              : props.pAppId,
            mReportData         : props.pReportData,
            mReportCriteria     : props.pReportCriteria,
            mReportTableColumns : props.pReportTableColumns,
            mParent             : props.pParent,
            mDataCollection     : null
        }

        this.cfProcessResponse  = this.cfProcessResponse.bind(this);
        this.cfSetFormData      = this.cfSetFormData.bind(this);
        this.cfInitializeLayout = this.cfInitializeLayout.bind(this);
        this.cfSearch           = this.cfSearch.bind(this);
    }

    componentDidMount()
    {
        this.cfInitializeLayout();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeLayout();
        }
    }

    cfInitializeLayout()
    {
    }

    cfSetFormData(lCollection)
    {
    }

    cfSearch(lServiceCode, lCriteria)
    {
        if(lServiceCode !== null)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject !== null)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }
                
                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest(this.cfProcessResponse);
            }
        }
    }

    cfProcessResponse(response)
    {
        var lCollection = [];
        if((response != null) && response.hasOwnProperty("result") && response.result.hasOwnProperty("collection_") )
        {
            if( (response.result.collection_ !== undefined) || (response.result.collection_ !== null))
            {
                if(response.result.collection_ instanceof Array)
                {
                    lCollection = response.result.collection_;
                }
                else
                {
                    lCollection.push(response.result.collection_);
                }
            }
        }

        this.cfSetFormData(lCollection);
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render()
    {
        return(
            <>
                <CSCOGrid 
                    pData         = {this.state.mDataCollection}
                    pFeatureRefId = {this.state.mFeatureRefId}
                    pColumns      = {this.state.mParent.state.mReportTableColumns}
                    pageSize      = {15}
                    onClick       = {this.props.onDataSelection}
                />
            </>
        );
    }
}

export default CSCOStandardReport1;