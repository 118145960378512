import React, { Component } from "react"
import {Accordion, AccordionTab} from 'primereact/accordion';

import CSUtil from "../util/CSUtil";
import CSFormUtil from "../util/CSFormUtil"

class CSCOAccordion extends Component
{
    mComponentCode      = 'CS_ACCORDION_1';
    mMaxSections        = 1;
    mMinSectionItems 	= 6;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mOddActiveIndex             : 0,
            mEvenActiveIndex            : 0,
            mFormCode                   : null,
            mFormItems                  : null,
            mFormData                   : {},
            mWidth                      : this.props.pWidth,
            mHeight                     : this.props.pHeight,
            mFormProperties             : this.props.pFormProperties,
            mFormItemProperties         : this.props.pFormItemProperties,
            mParentFormItems            : [],
            mIdFieldObjectMap           : {},
            mDataFieldObjectMap         : {},
            mParentFormItemChildrenMap  : {},
            mSaveActionItem             : this.props.pSaveActionItem
        }

        this.cfProcessChange        = this.cfProcessChange.bind(this);
        this.cfSetControlValue      = this.cfSetControlValue.bind(this);
        this.cfEnableSaveAction     = this.cfEnableSaveAction.bind(this);

        if(this.props.pFormItems)
        {
            var lFormItemList = [];
            CSUtil.cfSort(this.props.pFormItems, 'parent_id_', 'sequence_')
            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                if(lFormItem.cs_form_component_code_ === this.mComponentCode)
                {
                    this.state.mIdFieldObjectMap[lFormItem.id_] = lFormItem;
                    this.state.mDataFieldObjectMap[lFormItem.data_field_] = lFormItem;
                    lFormItemList.push(lFormItem);
                    if(lFormItem.parent_id_)
                    {
                        var lChildList = this.state.mParentFormItemChildrenMap[lFormItem.parent_id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                            this.state.mParentFormItemChildrenMap[lFormItem.parent_id_] = lChildList;
                        }

                        lChildList.push(lFormItem);
                    }
                    else
                    {
                        this.state.mParentFormItems.push(lFormItem);
                    }
                }

                if(lInd === 0)
                {
                    this.state.mFormCode = lFormItem.cs_form_code_;
                }
            }

            this.state.mFormItems = lFormItemList;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'layout')
                {
                    if (lFormProperty.property_ === 'max_sections_')
                    {
                        this.mMaxSections = lFormProperty.value_;
                    }

                    if(lFormProperty.property_ === 'min_section_items_')
                    {
                        this.mMinSectionItems = lFormProperty.value_;
                    }
                }
                else if((lFormProperty.type_ === 'initialize') && ((lFormProperty.property_ === 'data_') || (lFormProperty.property_ === 'collection_data_')))
                {
                    if(!this.state.mFormData)
                    {
                        this.state.mFormData = {};
                    }

                    if(this.props.pFormData)
                    {
                        var lDataObj = null;
                        var lData = null;
                        if(lFormProperty.property_ === 'data_')
                        {
                            if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.props.pFormData[lFormProperty.value_];
                                if(lData)
                                {                                
                                    if(lData instanceof Array && (lData.length > 0))
                                    {
                                        lDataObj = lData[0];
                                    }
                                    else
                                    {
                                        lDataObj = lData;
                                    }

                                    if(lDataObj)
                                    {
                                        this.state.mFormData[lFormProperty.value_] = lDataObj;
                                    }
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = {};
                            }
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.props.pFormData[lFormProperty.value_];
                                if(lData instanceof Array)
                                {
                                    this.state.mFormData[lFormProperty.value_] = lData;
                                }
                                else
                                {
                                    this.state.mFormData[lFormProperty.value_] = [];
                                    this.state.mFormData[lFormProperty.value_].push(lData)    
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = [];
                            }
                        }
                    }
                    else
                    {
                        if(lFormProperty.property_ === 'data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = {};
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = [];
                        }
                    }
                }
            }
        }
        else
        {
            this.state.mFormData = this.props.pFormData;
        }

        if(this.state.mFormData && this.props.pEnableSaveFn)
        {
            this.props.pEnableSaveFn(this.cfEnableSaveAction(this.state.mFormData), 2);
        }        
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.pFormItems)
            {
                var lFormItemList = [];
                for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
                {
                    var lFormItem = this.props.pFormItems[lInd];
                    if(lFormItem.cs_form_component_code_ === this.mComponentCode)
                    {
                        lFormItemList.push(lFormItem);
                    }
                }
    
                CSUtil.cfSort(lFormItemList, 'sequence_')
                this.setState({mFormItems : lFormItemList});
            }
    
            if(this.props.pFormProperties)
            {
                for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
                {
                    var lFormProperty = this.props.pFormProperties[lInd_0];
                    if(lFormProperty.type_ === 'layout')
                    {
                        if (lFormProperty.property_ === 'max_sections_')
                        {
                            this.mMaxSections = lFormProperty.value_;
                        }
    
                        if(lFormProperty.property_ === 'min_section_items_')
                        {
                            this.mMinSectionItems = lFormProperty.value_;
                        }
                    }
                }
            }
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormItem = null;
            var lFormData = this.state.mFormData;
            if(this.state.mDataFieldObjectMap && lFormData)
            {
                lFormItem = this.state.mDataFieldObjectMap[lData.data_field_];
                if(lFormItem)
                {
                    var lParentObj = null;
                    if(lFormItem.parent_id_)
                    {
                        lParentObj = this.state.mIdFieldObjectMap[lFormItem.parent_id_];
                        if(lParentObj)
                        {
                            var lPFormData = lFormData[lParentObj.data_field_];
                            if(!lPFormData)
                            {
                                lPFormData = {};
                                lFormData[lParentObj.data_field_] = lPFormData;
                            }
                             
                            lPFormData[lData.data_field_] = lData.value_;
                        }
                    }
                    else
                    {
                        lParentObj = this.state.mIdFieldObjectMap[lFormItem.id_];
                        var lCFormData = lFormData[lParentObj.data_field_];
                        if(!lCFormData)
                        {
                            lCFormData = {};
                            lFormData[lParentObj.data_field_] = lCFormData;
                        }
                             
                        lCFormData[lData.data_field_] = lData.value_;
                    }
                }
            }

            if(lFormData && this.props.pEnableSaveFn)
            {
                this.props.pEnableSaveFn(this.cfEnableSaveAction(lFormData), 2);
            }

            this.setState ({mFormData : lFormData});        
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            var form_data = this.state.mFormData;
            var lFormItemChildMap = {};
            var lFormItemObjectMap = {};
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                lFormItemObjectMap[form_item.id_] = form_item;
                if(form_item.parent_id_)
                {
                    var lParentItemCollection = lFormItemChildMap[form_item.parent_id_];
                    if(!lParentItemCollection)
                    {
                        lParentItemCollection = [];
                        lFormItemChildMap[form_item.parent_id_] = lParentItemCollection;
                    }

                    lParentItemCollection.push(form_item);
                }

                if(form_data)
                {   
                    form_item.mValue = form_data[form_item.data_field_];
                }
                else
                {
                    form_item.mValue = '';
                }
            }

            var lKeys = Object.keys(lFormItemChildMap);
            var lValues = Object.values(lFormItemChildMap);
            for(var lInd=0; lInd<lKeys.length; lInd++)
            {
                var lParentObj = lFormItemObjectMap[lKeys[lInd]];
                if(lParentObj && form_data && form_data[lParentObj.data_field_])
                {
                    var lDataObj = form_data[lParentObj.data_field_];
                    if(lDataObj && (lValues[lInd].length > 0))
                    {
                        var lDItemObj = lValues[lInd];
                        for(var lDInd=0; lDInd<lDItemObj.length; lDInd++)
                        {
                            var lFormItemObject = lDItemObj[lDInd];
                            var lDataField = lFormItemObject.data_field_;
                            if(lDataField)
                            {
                                var lDataValue = lDataObj[lDataField];
                                lFormItemObject.mValue = lDataValue;
                            }
                        }
                    }
                }
            }
        }
    }

    cfEnableSaveAction(lFormData)
    {
        var lEnabled = true;
        if(this.state.mFormItems && lFormData)
        {
            var lFlag = 1;
            for(var lInd=0; lInd<this.state.mParentFormItems.length; lInd++)
            {
                var lParentObj = this.state.mParentFormItems[lInd];
                var lChildList = this.state.mParentFormItemChildrenMap[lParentObj.id_];
                if(lChildList && (lChildList.length > 0))
                {
                    if(lFormData && lFormData.hasOwnProperty(lParentObj.data_field_))
                    {
                        if(lFlag === 1)
                        {
                            lFlag = 2;
                        }
                        else if(lFlag !== 2)
                        {
                            lFlag = 3;
                        }

                        var lParentFormData = lFormData[lParentObj.data_field_];
                        if(lParentFormData)
                        {
                            lEnabled = CSFormUtil.cfEnableAction(this.state.mSaveActionItem, lParentFormData, lChildList);
                            if(!lEnabled)
                            {
                                break;
                            }
                        }
                    }
                }
                else
                {
                    if(lFlag === 2)
                    {
                        lFlag = 3;
                    }
                    else if(lFlag === 1)
                    {
                        lFlag = 0;
                    }
                }
            }

            if(lEnabled)
            {
                if(lFlag !== 2)
                {
                    lEnabled = CSFormUtil.cfEnableAction(this.state.mSaveActionItem, lFormData, this.state.mParentFormItems);
                }
            }
        }

        return lEnabled;
    }

    renderHeaderName(lItems, lDisplayLabel)
    {
        var lRetVal = lDisplayLabel;
        var lSummaryField = null;
        var lSummaryItem = lItems.filter(lItem => lItem.control_type_ === 'summary');
        if(lSummaryItem.length > 0)
        {
            lSummaryField = 
                <div style=
                    {
                        {
                            width           : "165px", 
                            alignItems      : 'flex-end',
                            display         : "flex", 
                            flexDirection   : "row", 
                            fontWeight      : 500,
                            fontSize        : '12.5px'
                        }
                    }>
                    { lSummaryItem[0].display_label_ }
                </div>
        }

        lRetVal = 
            <div style=
                {
                    {
                        width           : '100%',
                        display         : "flex", 
                        flexDirection   : "row", 
                    }
                }>
                <div style=
                    {
                        {
                            width       : "305px",
                            overflow    : "hidden",
                            fontWeight  : 500,
                            fontSize    : '12.5px'
                        }
                    }>
                    { lDisplayLabel }
                </div>

                { lSummaryField }
            </div>

        return lRetVal;
    }

    render() 
    {
        this.cfSetControlValue();
        var lOddItemsParentList = [];
        var lEvenItemsParentList = [];
        var lParentIdFormItems = {}
        var lGap = null;

        if(this.state.mFormItems && (this.state.mFormItems.length > 0))
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    var lChildList = null;
                    if(!lFormItem.parent_id_)
                    {
                        if(parseInt(parseInt(lFormItem.sequence_) % 2) === 0)
                        {
                            lEvenItemsParentList.push(lFormItem);
                        }
                        else
                        {
                            lOddItemsParentList.push(lFormItem);
                        }

                        lChildList = lParentIdFormItems[lFormItem.id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                            lParentIdFormItems[lFormItem.id_] = lChildList;
                        }
                        
                    }
                    else
                    {
                        lChildList = lParentIdFormItems[lFormItem.parent_id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                            lParentIdFormItems[lFormItem.parent_id_] = lChildList;
                        }
                        if(lFormItem.width_ !== '-1')
                        {
                            lFormItem.max_width_ = lFormItem.width_;
                        }
                       
                        lFormItem.label_width_ = '300px'
                    }

                    lChildList.push(lFormItem);
                }
            }
        }

        var lOddSection = null;
        var lEvenSection = null;
        if(lOddItemsParentList && (lOddItemsParentList.length > 0))
        {
            lOddSection = <Accordion activeIndex={this.state.mOddActiveIndex} onTabChange={(e) => this.setState({ mOddActiveIndex :  e.index })}>
            {
                lOddItemsParentList.map((lItem) =>                             
                    <AccordionTab 
                            header={this.renderHeaderName(lParentIdFormItems[lItem.id_], lItem.display_label_)} 
                            contentClassName="accordion-tab">
                            { this.mFormUtil.cfGetSections(lParentIdFormItems[lItem.id_], this.mMinSectionItems, this.mMaxSections, this.cfProcessChange) }
                    </AccordionTab>
                )
            }
            </Accordion>
        }

        if(lEvenItemsParentList && (lEvenItemsParentList.length > 0))
        {
            lEvenSection = <Accordion activeIndex={this.state.mEvenActiveIndex} onTabChange={(e) => this.setState({ mEvenActiveIndex: e.index })}>
            {
                lEvenItemsParentList.map((lItem) =>                          
                    <AccordionTab header={lItem.display_label_} contentClassName="accordion-tab" >
                        <div className="form-item-1">
                            { this.mFormUtil.cfGetSections(lParentIdFormItems[lItem.id_], this.mMinSectionItems, this.mMaxSections, this.cfProcessChange) }
                        </div>
                    </AccordionTab>
                )
            }
            </Accordion>
        }

        if(lEvenSection)
        {
            lGap = <div style={{width : '20px'}}/>
        }

        var lSection = 
            <div className="form-item-2" >
                { lOddSection }

                { lGap }        

                { lEvenSection }
            </div>

        return lSection;
    }
}

export default CSCOAccordion;
