import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import CSUtil from "../util/CSUtil";

function CSRecordSheet1(lProps) 
{
    const mComponentRef = useRef(null);

    const [ mStudentDetails, setStudentDetails ] = useState(
        {
            mAdmissionNo            : '',
            mStudentName            : '',
            mLocation               : '',
            mDateOfBirth            : '',
            mParentName             : '',
            mGuardianName           : '',
            mParentResidence        : '',
            mReligion               : '',
            mIssueDate              : '',
            mRecordSheetNo          : '',
            mBranchName             : '',
            mSchoolName             : '',
            mAdmissionDate          : '',
            mQuarter1Class          : '',
            mQuarter1WorkingDays    : '',
            mQuarter1PresentDays    : '',
            mQuarter2Class          : '',
            mQuarter2WorkingDays    : '',
            mQuarter2PresentDays    : '',
            mQuarter3Class          : '',
            mQuarter3WorkingDays    : '',
            mQuarter3PresentDays    : '',
            mQuarter4Class          : '',
            mQuarter4WorkingDays    : '',
            mQuarter4PresentDays    : '',
            mDateOfLeaving          : '',
            mProgress               : '',
            mConduct                : '',
        }
    );

    useEffect(() =>
        {
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pSelectedData)
                {
                    var lStudentDetails = 
                    {
                        mAdmissionNo              : lProps.pSelectedData.selected_data_.admission_no_,
                        mStudentName              : lProps.pSelectedData.selected_data_.student_name_,
                        mLocation                 : lProps.pSelectedData.selected_data_.place_,
                        mDateOfBirth              : lProps.pSelectedData.selected_data_.date_of_birth_,
                        mParentName               : lProps.pSelectedData.selected_data_.father_name_,
                        mGuardianName             : lProps.pSelectedData.selected_data_.guardian_name_,
                        mParentResidence          : lProps.pSelectedData.selected_data_.address_,
                        mReligion                 : lProps.pSelectedData.selected_data_.religion_,
                        mIssueDate                : lProps.pSelectedData.selected_data_.issue_date_,
                        mRecordSheetNo            : lProps.pSelectedData.selected_data_.record_sheet_no_,
                        mBranchName               : lProps.pSelectedData.selected_data_.branch_name_,
                        mSchoolName               : lProps.pSelectedData.selected_data_.school_name_,
                        mAdmissionDate            : lProps.pSelectedData.selected_data_.admission_date_,
                        mQuarter1Class            : lProps.pSelectedData.selected_data_.quarter1_class_,
                        mQuarter1WorkingDays      : lProps.pSelectedData.selected_data_.quarter1_working_days_,
                        mQuarter1PresentDays      : lProps.pSelectedData.selected_data_.quarter1_present_days_,
                        mQuarter2Class            : lProps.pSelectedData.selected_data_.quarter2_class_,
                        mQuarter2WorkingDays      : lProps.pSelectedData.selected_data_.quarter2_working_days_,
                        mQuarter2PresentDays      : lProps.pSelectedData.selected_data_.quarter2_present_days_,
                        mQuarter3Class            : lProps.pSelectedData.selected_data_.quarter3_class_,
                        mQuarter3WorkingDays      : lProps.pSelectedData.selected_data_.quarter3_working_days_,
                        mQuarter3PresentDays      : lProps.pSelectedData.selected_data_.quarter3_present_days_,
                        mQuarter4Class            : lProps.pSelectedData.selected_data_.quarter4_class_,
                        mQuarter4WorkingDays      : lProps.pSelectedData.selected_data_.quarter4_working_days_,
                        mQuarter4PresentDays      : lProps.pSelectedData.selected_data_.quarter4_present_days_,
                        mDateOfLeaving            : lProps.pSelectedData.selected_data_.date_of_leaving_,
                        mProgress                 : lProps.pSelectedData.selected_data_.progress_,
                        mConduct                  : lProps.pSelectedData.selected_data_.conduct_,
                    }

                    setStudentDetails(lStudentDetails);
                }
            }
            fnInitializeForm();

        }, [lProps]);

    
        var lHeaderActions = [];
        lHeaderActions.push(
            <ReactToPrint
                key = "1"
                trigger={() => 
                    <Button
                        style=
                            {
                                {
                                    fontSize        : '13.2px', 
                                    marginRight     : '.35em', 
                                    width           : '24px', 
                                    height          : '24px',
                                    verticalAlign   : 'middle'
                                }
                            } 
                            type="Button" 
                            icon={CSUtil.mNameIconMap['PI_PRINT']} 
                        />
                    }
                content={() => mComponentRef.current}
            />
        );
                
        lHeaderActions.push(
            <Button
                key = "2"
                style=
                    {
                        {
                            fontSize        : '13.2px', 
                            marginRight     : '.35em', 
                            width           : '24px', 
                            height          : '24px',
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => lProps.fnProcessClose(true, true) }
            />
        );

    var lHeader = 
    <>
        <div className="p-toolbar-group-left">
            <h2>{lProps.pFormHeading}</h2>
        </div>

        <div className="p-toolbar-group-right">
            { lHeaderActions }
        </div>
    </>

    const lForm =
        <div className='rs1' ref={mComponentRef} style={{backgroundImage: `url(./images/RS-GMS-1.jpeg)`, backgroundSize: 'cover'}}>
            {/* <div className='std-photo'>
                <img alt='' style = {{ width: 100, height: 100 }} src="" />
            </div> */}
            <p className='p0'>  { mStudentDetails.mAdmissionNo          }  </p>
            <p className='p1'>  { mStudentDetails.mStudentName          }  </p>
            <p className='p2'>  { mStudentDetails.mLocation             }  </p>
            <p className='p3'>  { mStudentDetails.mDateOfBirth          }  </p>
            <p className='p4'>  { mStudentDetails.mParentName           }  </p>
            <p className='p5'>  { mStudentDetails.mGuardianName         }  </p>
            <p className='p6'>  { mStudentDetails.mParentResidence      }  </p>
            <p className='p7'>  { mStudentDetails.mReligion             }  </p>
            <p className='p8'>  { mStudentDetails.mIssueDate            }  </p>
            <p className='p9'>  { mStudentDetails.mRecordSheetNo        }  </p>
            <p className='p10'> { mStudentDetails.mBranchName           }  </p>
            <p className='p11'> { mStudentDetails.mSchoolName           }  </p>
            <p className='p12'> { mStudentDetails.mAdmissionDate        }  </p>
            <p className='p13'> { mStudentDetails.mQuarter1Class        }  </p>
            <p className='p14'> { mStudentDetails.mQuarter1WorkingDays  }  </p>
            <p className='p15'> { mStudentDetails.mQuarter1PresentDays  }  </p>
            <p className='p16'> { mStudentDetails.mQuarter2Class        }  </p>
            <p className='p17'> { mStudentDetails.mQuarter2WorkingDays  }  </p>
            <p className='p18'> { mStudentDetails.mQuarter2PresentDays  }  </p>
            <p className='p19'> { mStudentDetails.mQuarter3Class        }  </p>
            <p className='p20'> { mStudentDetails.mQuarter3WorkingDays  }  </p>
            <p className='p21'> { mStudentDetails.mQuarter3PresentDays  }  </p>
            <p className='p22'> { mStudentDetails.mQuarter4Class        }  </p>
            <p className='p23'> { mStudentDetails.mQuarter4WorkingDays  }  </p>
            <p className='p24'> { mStudentDetails.mQuarter4PresentDays  }  </p>
            <p className='p25'> { mStudentDetails.mDateOfLeaving        }  </p>
            <p className='p26'> { mStudentDetails.mProgress             }  </p>
            <p className='p27'> { mStudentDetails.mConduct              }  </p>
        </div>

  return (
    <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            appendTo  = { document.body }
            header    = {lHeader}
            onHide    = { () => lProps.fnProcessClose(false, true) }>
                { lForm }
        </Dialog>
  )
}

export default CSRecordSheet1;