import React from "react"
import {InputText} from 'primereact/inputtext';
import CSServiceUtil from '../util/CSServiceUtil'
import CSBaseControl from './CSBaseControl'

class CSTextInput extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.mServiceUtil      = CSServiceUtil.cfGetInstance();
        this.cfGetControl      = this.cfGetControl.bind(this);
        this.cfHandleChange    = this.cfHandleChange.bind(this);
        this.cfValidateEmail   = this.cfValidateEmail.bind(this);
        this.cfValidateAadhar  = this.cfValidateAadhar.bind(this);
        this.cfValidateMobile  = this.cfValidateMobile.bind(this);
        this.cfValidateLength  = this.cfValidateLength.bind(this);
        this.cfValidatePincode = this.cfValidatePincode.bind(this);
        this.cfValidate        = this.cfValidate.bind(this);
    }
 
    cfValidateAadhar(lText)
    {
        var lRegexp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

        return lRegexp.test(lText);
    }
 
    cfValidateEmail(lText)
    {
        var lRegexp = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

        return lRegexp.test(lText);
    }
 
    cfValidateMobile(lText)
    {
        var lIsValid = false;
        if (lText.length === 10) 
        {
            lIsValid = true;
        }

        return lIsValid;
    }
 
    cfValidateLength(lText, lLength)
    {
        var lIsValid = false;
        if (lText.length === Number(lLength)) 
        {
            lIsValid = true;
        }

        return lIsValid;
    }
 
    cfValidatePincode(lText)
    {
        var lRegexp = /^\d{6}$/;

        return lRegexp.test(lText);
    }

    cfValidate(event)
    {
        this.cfHandleChange(event, true);
    }

    cfHandleChange(event, lEnable = false) {
        let lEnableBlur = false;
        let lAllowedCharactes = null;
        let lIsValid = true;
        let lStrictValidation = false;
        let lLength = null;
        let lProperties = this.props.cs_form_item_properties_;
        if(lProperties && (lProperties.length > 0)) {
            for(let lpInd = 0; lpInd < lProperties.length; lpInd++) {
                let lProperty = lProperties[lpInd];
                if(lProperty.type_ === 'validate') {
                    if(lProperty.property_ === 'enable_blur') {
                        if(lProperty.value_ === '1') {
                            lEnableBlur = true;
                        }
                    }

                    if(lProperty.property_ === 'strict_validation') {
                        if(lProperty.value_ === '1') {
                            lStrictValidation = true;
                        }
                    }

                    if(lProperty.property_ === 'allow') {
                        lAllowedCharactes = lProperty.value_;
                    }
                    else if(lProperty.property_ === 'email') {
                        lIsValid = this.cfValidateEmail(event.target.value);
                    }
                    else if(lProperty.property_ === 'aadhar') {
                        lIsValid = this.cfValidateAadhar(event.target.value);
                    }
                    else if(lProperty.property_ === 'mobile') {
                        lIsValid = this.cfValidateMobile(event.target.value);
                    }
                    else if(lProperty.property_ === 'length') {
                        lLength = lProperty.value_;
                        lIsValid = this.cfValidateLength(event.target.value, lProperty.value_);
                    }
                    else if(lProperty.property_ === 'pincode') {
                        lLength = lProperty.value_;
                        lIsValid = this.cfValidatePincode(event.target.value);
                    }
                }
            }
        }

        if(!lEnableBlur || (lEnableBlur && lEnable)) {
            if(!lIsValid) {
                this.setState({mBorder : this.state.mErrorBorder, mLength :lLength})
            }
            else {
                this.setState({mBorder : this.state.mStandardBorder, mLength :lLength})
            }

            let lAllow = true;
            let lValue = this.state.mValue;
            if(event.target.value) {
                if(lAllowedCharactes) {
                    if(!event.target.value.match(lAllowedCharactes)) {
                        lAllow = false;
                    }
                }

                if(lStrictValidation) {
                    lAllow = lIsValid;
                    lValue = event.target.value;
                }
            }

            if(lAllow && this.state.mHandleChange) {
                let lData           = {};
                lData.control_type_ = 'text_input';
                lData.data_field_   = this.state.mDataField;
                lData.value_        = event.target.value;
                lData.id_           = this.state.mId;
                lData.is_valid_     = lAllow;

                this.state.mHandleChange(lData);
            }
            else {
                this.setState({mValue : lValue});
            }
        }
        else {
            this.setState({mValue : event.target.value});
        }
    }

    cfGetControl()
    {
        var ctl = null;
        if ((this.state.mControlType) && ((this.state.mControlType === 'text_input') || (this.state.mControlType === 'state') || (this.state.mControlType === 'constant')))
        {
            ctl = (
                <div className="cs-control field grid">
                    { super.cfGetControlLabel() }

                    <InputText 
                        style = 
                        {
                            {
                                border : this.state.mBorder,
                                backgroundColor : (this.state.mEditable) ? '#FFFFFF' : 'var(--primary-color)'
                            }
                        }

                        className   = "cs-text-input"
                        id          = { this.state.mId } 
                        onBlur      = { this.cfValidate }
                        type        = { this.state.mType }
                        maxLength   = { this.state.mLength }
                        readOnly    = { !this.state.mEditable }
                        name        = { this.state.mDataField }
                        value       = { this.state.mValue || '' }
                        onChange    = { this.cfHandleChange }
                        required    = { this.state.mRequired } />
                </div>
            )
        }

        return ctl;
    }

    render()
    {
        return this.cfGetControl();
    }
}

export default CSTextInput;
