import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';
import {read, utils} from 'xlsx';

import CSCOGrid from "../components/CSCOGrid";
import CSFormUtil from "../util/CSFormUtil"
import CSCOHorizontalActionBar from "../components/CSCOHorizontalActionBar"

class CSImportExcel extends Component
{
    constructor(props) 
    {
        super(props);
        this.state =
            {
                mDisplay            : this.props.pShow,
                mFileName           : null,
                mFormHeading        : (this.props.pFormHeading) ? this.props.pFormHeading : "Excel Import - Column Map",
                mGridColumns        : this.props.pFormGrid,
                mFormActions        : this.props.pFormActions,
                mColumns            : [],
                mColumnMapData      : null,
                mExcelColumns       : [],
                mExcelData          : [],
                mDataCollection     : [],
                mHeaderRow          : null,
                mOrigExcelColumns   : null,
                mEditable           : true,
                mColObjMap          : null,
                mDataFieldColMap    : null,
                mDataFieldReqMap    : {},
                mDataFieldTypeMap   : {},

                fnProcessData       : this.props.fnProcessData,
                fnProcessClose      : this.props.fnProcessClose
            };

        this.cfBrowseFile           = this.cfBrowseFile.bind(this);
        this.cfExcelDateToJSDate    = this.cfExcelDateToJSDate.bind(this);
        this.cfSetGridColumns       = this.cfSetGridColumns.bind(this);
        this.cfCloseForm            = this.cfCloseForm.bind(this);
        this.cfOnItemChange         = this.cfOnItemChange.bind(this);
        this.process_close          = this.process_close.bind(this);
        this.process_cancel         = this.process_cancel.bind(this);
        this.process_update         = this.process_update.bind(this);

        var lColObjMap = null;
        if(this.props.pSelectedData && this.props.pSelectedData.hasOwnProperty('collection_'))
        {
            var lCollection = this.props.pSelectedData.collection_;
            if(lCollection.length > 0)
            {
                lColObjMap = {}
                this.state.mDataFieldColMap = {}
                this.state.mEditable = false;
                for(var lInd0=0; lInd0<lCollection.length; lInd0++)
                {
                    var lObj = lCollection[lInd0];
                    lColObjMap[lObj.column_name_] = lObj;
                    if(lObj.excel_column_)
                    {
                        this.state.mDataFieldColMap[lObj.excel_column_] = lObj.data_field_;
                    }
                }
            }
        }

        if(this.state.mGridColumns)
        {
            if(!this.state.mColumnMapData)
            {
                this.state.mColumnMapData = [];
            }

            for(var lInd=0; lInd<this.state.mGridColumns.length; lInd++)
            {
                var lColumn = this.state.mGridColumns[lInd];
                if(lColumn.column_name_)
                {
                    var lImportFlag = true;
                    if(lColumn.hasOwnProperty('cs_form_grid_item_properties_'))
                    {
                        for(var lfgInd=0; lfgInd<lColumn.cs_form_grid_item_properties_.length; lfgInd++)
                        {
                            var lProperty = lColumn.cs_form_grid_item_properties_[lfgInd];
                            if(lProperty.property_ === 'import_columns')
                            {
                                if(lProperty.param_ === 'import_flag_')
                                {
                                    if(lProperty.value_ === 'false')
                                    {
                                        lImportFlag = false;
                                    }
                                }
                            }
                        }
                    }

                    if(lImportFlag)
                    {
                        var lColObj = {};
                        lColObj.required_       = (lColumn.required_ === '1') ? 'Y' : 'N';
                        lColObj.grid_column_    = lColumn.column_name_;
                        lColObj.data_field_     = lColumn.data_field_;
                        lColObj.visible_        = lColumn.visible_;
                        lColObj.excel_column_   = '';
                        if(lColObjMap && lColumn.column_name_)
                        {
                            var lColObject = lColObjMap[lColumn.column_name_];
                            if(lColObject)
                            {
                                lColObj.excel_column_ = lColObject.excel_column_;
                            }
                        }

                        this.state.mDataFieldTypeMap[lColumn.data_field_] = lColumn.type_;
                        this.state.mDataFieldReqMap[lColObj.data_field_] = lColObj.required_;
                        this.state.mColumnMapData.push(lColObj);
                    }
                }
            }
        }
    }

    componentDidMount() 
    {
        this.cfSetGridColumns();
    }

    componentDidUpdate(lPrevProps, lPrevState) 
    {
        if(lPrevProps !== this.props)
        {
            this.cfSetGridColumns();
        }
    }

    cfSetGridColumns()
    {
        var lColumnList = [];
        var lColumn0 = {};
        lColumn0.column_name_   = 'Required ?';
        lColumn0.data_field_    = 'required_';
        lColumn0.visible_       = '1';
        lColumn0.width_         = "60px";
        lColumnList.push(lColumn0);

        var lColumn1 = {};
        lColumn1.column_name_   = 'Grid Column';
        lColumn1.data_field_    = 'grid_column_';
        lColumn1.visible_       = '1';
        lColumn1.width_         = "100px";
        lColumnList.push(lColumn1);

        var lColumn2 = {};
        lColumn2.column_name_   = 'Excel Column';
        lColumn2.data_field_    = 'excel_column_';
        lColumn2.visible_       = '1';
        lColumn2.width_         = "100px";

        if(this.state.mEditable)
        {
            lColumn2.editable_      = "1";
            lColumn2.item_editor_   = 'COMBO_BOX_EDITOR_1';
            lColumn2.data_provider_ = this.state.mExcelColumns;
        }

        lColumnList.push(lColumn2);

        return lColumnList;
    }

    componentWillUnmount()
    {
    }

    cfBrowseFile(event)
    {
        const lFiles = event.target.files;
        if(lFiles && (lFiles.length > 0))
        {
            const lSelectedFile = lFiles[0];
            const lReader = new FileReader();
            if(lSelectedFile)
            {
                lReader.onload = function(evt)
                {
                    var lData = evt.target.result;
                    if(lData)
                    {
                        var lWorkbook = read(lData, {type: 'binary'});
                        const lWorksheetName = lWorkbook.SheetNames[0];
                        const lWorksheet = lWorkbook.Sheets[lWorksheetName];
                        const lSheetData = utils.sheet_to_json(lWorksheet, {header:1});

                        if(lSheetData && (lSheetData.length > 0))
                        {
                            var lExcelData = [];
                            var lHeader = [];
                            var lHeaderRow = null;
                            for(var lSInd=0; lSInd<lSheetData.length; lSInd++)
                            {
                                var lRow = lSheetData[lSInd];
                                var lObj    = {}
                                var lIsBlankRow = true;
                                for(var lPInd=0; lPInd<lRow.length; lPInd++)
                                {
                                    if(lSInd === 0)
                                    {
                                        if(lRow)
                                        {
                                            if(lRow[lPInd])
                                            {
                                                lHeaderRow = lRow;
                                                var lHeaderObj = {};
                                                lHeaderObj.key    = lPInd;
                                                lHeaderObj.label  = lRow[lPInd];
                                                lHeaderObj.value  = lRow[lPInd];
                                                lHeader.push(lHeaderObj);
                                            }
                                        }
                                        else
                                        {
                                            break;
                                        }
                                    }
                                    else
                                    {
                                        if(lHeaderRow && lHeaderRow[lPInd] && lRow[lPInd])
                                        {
                                            lObj[ lHeaderRow[lPInd] ] = lRow[lPInd];
                                            lObj.key    = String(lSInd) + String(lPInd);
                                            if(lRow[lPInd])
                                            {
                                                lIsBlankRow = false;
                                            }
                                        }
                                    }
                                }

                                if(lSInd > 0)
                                {
                                    if(!lIsBlankRow)
                                    {
                                        lExcelData.push(lObj);
                                    }
                                }
                            }

                            this.setState(
                                {
                                    mExcelColumns       : lHeader, 
                                    mHeaderRow          : lHeaderRow, 
                                    mOrigExcelColumns   : lHeader, 
                                    mExcelData          : lExcelData
                                }
                            );

                            if(this.state.mDataFieldColMap && (lExcelData.length > 0))
                            {
                                var lDataCollection = [];
                                for(var lInd=0; lInd<lExcelData.length; lInd++)
                                {
                                    var lexObj = lExcelData[lInd];
                                    var lDataObj = {};
                                    var lObjKeys = Object.keys(lexObj);
                                    for(var lkeyInd=0; lkeyInd<lObjKeys.length; lkeyInd++)
                                    {
                                        var lDataColumn = this.state.mDataFieldColMap[lObjKeys[lkeyInd]];
                                        if(lDataColumn)
                                        {
                                            if(lexObj[lObjKeys[lkeyInd]])
                                            {
                                                lDataObj[lDataColumn] = lexObj[lObjKeys[lkeyInd]];
                                            }
                                            else
                                            {
                                                lDataObj[lDataColumn] = '';
                                            }
                                        }
                                    }
                    
                                    lDataCollection.push(lDataObj);
                                }
                    
                                this.setState({mDataCollection : lDataCollection});                                
                            }
                        }
                    }
                }.bind(this);

                lReader.readAsBinaryString(lSelectedFile);
            }
        }
    }

    cfExcelDateToJSDate = (lDate) => 
    {
        let lConvertedDate = new Date(Math.round((lDate - 25569) * 864e5));
        lConvertedDate = String(lConvertedDate).slice(4, 15)
        lDate = lConvertedDate.split(" ")
        let lDay = lDate[1];
        let lMonth = lDate[0];
        lMonth = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(lMonth) / 3 + 1
        if (lMonth.toString().length <= 1)
        {
            lMonth = '0' + lMonth
        }

        let lYear = lDate[2];

        return String(lYear + '-' + lMonth + '-' + lDay)
    }

    cfOnItemChange(lData)
    {
        if(lData && this.state.mHeaderRow && this.state.mExcelData)
        {
            var lDataCollection = [];
            var lDataFieldColumnMap = {};
            for(var lDInd=0; lDInd<lData.length; lDInd++)
            {
                if(lData[lDInd].data_field_ && lData[lDInd].excel_column_)
                {
                   lDataFieldColumnMap[lData[lDInd].data_field_] = lData[lDInd].excel_column_;
                }
            }

            for(var lInd=0; lInd<this.state.mExcelData.length; lInd++)
            {
                var lObj = this.state.mExcelData[lInd];
                var lDataObj = {};
                var lObjKeys = Object.keys(lDataFieldColumnMap);
                for(var lkeyInd=0; lkeyInd<lObjKeys.length; lkeyInd++)
                {
                    var lKey = lObjKeys[lkeyInd]; 
                    if(lObj[lDataFieldColumnMap[lKey]])
                    {
                        if(this.state.mDataFieldTypeMap[lKey] === 'DATE')
                        {
                            lDataObj[lKey] = this.cfExcelDateToJSDate(lObj[lDataFieldColumnMap[lKey]]);
                        }
                        else
                        {
                            lDataObj[lKey] = lObj[lDataFieldColumnMap[lKey]];
                        }
                    }
                    else
                    {
                        lDataObj[lKey] = '';
                    }
                }

                lDataCollection.push(lDataObj);
            }

            this.setState({mDataCollection : lDataCollection});
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }
    
    process_update()
    {
        if(this.state.fnProcessData)
        {
            this.state.fnProcessData(this.state.mDataCollection);
        }
    }

    cfCloseForm(lCloseFlag, lRefreshFlag)
    {
        this.state.fnProcessClose(lCloseFlag === false, lRefreshFlag);
    }

    render() 
    {
        var lChooseFileInput = this.state.mFileName;
        if(!lChooseFileInput)
        {
            lChooseFileInput = 
                <div style={{height : "35px", width : "100%"}}>
                    <input id="ID_FILE_INPUT" type="file" onChange={this.cfBrowseFile} accept=".csv, .xls, .xlsx"/>
                </div>
        }

        var lGrid = 
            <CSCOGrid
                pData           = { this.state.mColumnMapData }
                pColumns        = { this.cfSetGridColumns() }
                pColumnWidth    = "100px"
                pageSize        = { 15 }
                pScrollHeight   = { '100%' }
                pEnableScroll   = { true}
                pWidth          = "600px"
                OnItemChange    = {this.cfOnItemChange}
            />

        var lFormActions = null;
        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            var lFormActionList = this.state.mFormActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        if((lActionItem.action_ === 'process_update') && this.state.mDataFieldReqMap && this.state.mDataCollection)
                        {
                            var lEnable = true;
                            for(var ldInd=0; ldInd < this.state.mColumnMapData.length; ldInd++)
                            {
                                var lDataObj = this.state.mColumnMapData[ldInd];
                                if(this.state.mDataFieldReqMap[lDataObj.data_field_] === 'Y')
                                {
                                    if(!lDataObj.excel_column_)
                                    {
                                        lEnable = false;
                                        break;
                                    }
                                }
                            }

                            lActionItem.mEnabled = lEnable;
                        }
                        else
                        {
                            lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mDataCollection, this.state.mFormItems);
                        }
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormActions = 
                <CSCOHorizontalActionBar 
                    align       = "left"
                    pType       = 'SAVE_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormActionList}
                />;
        }

        var lForm = 
            <Dialog visible={this.state.mDisplay}
                modal       = {true}
                style=
                {
                    {
                        backgroundColor : "#D8D8D8"
                    }
                }
                maximizable = { false }                
                appendTo    = { document.body }
                padding     = '5px'
                header      = { this.state.mFormHeading || '.' }
                onHide      = { () => this.cfCloseForm(true) }
                footer      = { lFormActions }>

                <div style={{ height : '100%'}}>
                    { lChooseFileInput }
                    <div style={{width : '600px'}}>
                        { lGrid }
                    </div>                    
                </div>
            </Dialog>

        return lForm;
    }
}

export default CSImportExcel;
