import React, { Component } from "react"

import CSCOHorizontalActionBar from "./CSCOHorizontalActionBar"
import CSCOGrid from "./CSCOGrid";

import CSUtil from "../util/CSUtil";
import CSFormUtil from "../util/CSFormUtil"

class CSCODetailsSection2 extends Component 
{
    mFormCode           = 'CS_DETAILS_FORM_SECTION_2';
	mNumSections 		= 3;
    mMinSectionItems 	= 7;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);

        this.state = 
        {
            mDisplay        : props.pShow,
            mAppId          : props.pAppId,
            mFormHeading    : (props.pFormHeading) ? props.pFormHeading : "",
            mFormItems      : props.pFormItems,
            mFormGrid       : props.pFormGrid,
            mFormData       : (props.pFormData) ? props.pFormData : {},
            mFormOrigData   : CSUtil.cfGetObjectCopy(props.pFormData),
            mFormActions    : props.pFormActions,
            mFormProperties : props.pFormProperties,
            mControlType    : props.pControlType,
            mEditable       : props.pEditable,
            mWidth          : props.pWidth,
            mHeight         : props.pHeight,
            mValue          : props.pValue,

            fnProcessChange : props.fnProcessChange,
            fnProcessSubmit : props.fnProcessSubmit,
            fnProcessClose  : props.fnProcessClose
        }

        this.cfProcessChange        = this.cfProcessChange.bind(this);
        this.cfSetControlValue      = this.cfSetControlValue.bind(this);

        this.cfCloseForm    = this.cfCloseForm.bind(this);        
        this.process_save   = this.process_save.bind(this);
        this.process_cancel = this.process_cancel.bind(this);
        this.process_close  = this.process_close.bind(this);
        this.process_reset  = this.process_reset.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.setState({
                mDisplay        : this.props.pShow,
                mAppId          : this.props.pAppId,
                mFormHeading    : (this.props.pFormHeading) ? this.props.pFormHeading : "",
                mFormItems      : this.props.pFormItems,
                mFormData       : this.props.pFormData,
                mControlType    : this.props.pControlType,
                mEditable       : this.props.pEditable,
                mWidth          : this.props.pWidth,
                mHeight         : this.props.pHeight,
                mValue          : this.props.pValue,

                fnProcessChange : this.props.fnProcessChange,
                fnProcessSubmit : this.props.fnProcessSubmit,
                fnProcessClose  : this.props.fnProcessClose
            });

            this.mInitFlag = true;
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            if(this.state.mFormData !== null)
            {
                var lFormData = this.state.mFormData;
                lFormData[lData.data_field_] = lData.value_;
                this.setState ({mFormData : lFormData});
            }
        }
    }

    cfSetControlValue()
    {
        if((this.state !== null) && (this.state.mFormItems != null))
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mFormData;
                if(form_data)
                {   
                    form_item.mValue = form_data[form_item.data_field_];
                }
                else
                {
                    form_item.mValue = '';
                }
            }
        }
    }

    cfCloseForm()
    {
        this.setState({mDisplay : false});
    }

    process_save(event, lParent, lActionItem)
    {
        if(lActionItem && (lActionItem.on_click_service_code_ || lActionItem.on_click_request_ref_id_))
        {
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lForm =
            <>
				<CSCOHorizontalActionBar 
                    align           = "left"
                    pType           = 'SEARCH_ACTION_BAR'
                    parent          = {this}
                    actionList = {this.state.mFormActions}
                />
                
                <CSCOGrid
                    pData           = {this.state.mDataCollection}
                    pFeatureRefId   = {this.state.mFeatureRefId}
                    pColumns        = {this.state.mFormGrid}
                    pageSize        = {15}
                />

                <CSCOHorizontalActionBar 
                    align       = "left"
                    pType       = 'DETAILS_ACTION_BAR'
                    parent      = {this}
                    actionList  = {this.state.mFormDetailsActions}
                />            
            </>
          
        return lForm;
    }
}

export default CSCODetailsSection2;
