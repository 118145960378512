import React from "react"

import { fnGetForm } from "../../util/CSLayoutUtil"
import { fnGetFormHeader } from "../../util/CSLayoutUtil"

import CSCOGrid from "../../components/CSCOGrid";
import CSCOCriteria from "../../components/CSCOCriteria"
import CSCOBaseFormLayout from "./CSCOBaseFormLayout";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar";

import CSFormUtil from "../../util/CSFormUtil";
import CSUtil from "../../util/CSUtil";
import CSCOBackdrop from "../../components/CSCOBackdrop";

class CSCOFormLayout15 extends CSCOBaseFormLayout
{
    componentDidMount()
    {
        super.cfInitializeLayout();
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render()
    {
        var lCriteria = null;
        var lSearchActions = null;
        if(this.state.mFormCriteria && (this.state.mFormCriteria.length > 0))
        {
            lCriteria = 
                <div id="ID_CRITERIA">
                    <CSCOCriteria 
                        pCriteria       = {this.state.mFormCriteria}
                        pFormActions    = {this.state.mFormActions}
                        pFormProperties = {this.state.mFormProperties}
                        pParent         = {this}
                        />
                </div>
        }

        if(this.state.mSearchActionList && (this.state.mSearchActionList.length > 0))
        {
            var lSearchActionList = this.state.mSearchActionList.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
  
            lSearchActions = 
                <CSCOBaseActionBar
                    align       = "left"
                    pType       = 'SEARCH_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lSearchActionList}
                />
        }

        var lHeading = 
            <div id="ID_HEADING">
                {fnGetFormHeader(this.state.mFormLayoutHeading)}
            </div>

        var lHeadingHeight = this.cfGetDimensions("ID_HEADING");
        var lCriteriaHeight = this.cfGetDimensions("ID_CRITERIA");
        var lScrollHeight = ((CSUtil.cfGetInstance()).mApplicationHeight - lCriteriaHeight -lHeadingHeight - 50);
        if(this.state.mCriteriaHeight === 0)
        {
            lScrollHeight += 4;
        }

        var lDetailsForm = null;
        if(this.state.mDetailsFlag)
        {
            var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
            lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.cfCloseForm);
        }

        return( 
            <>
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>
                
                { lHeading }
    
                { lCriteria }

                <CSCOGrid
                    pData               = { this.state.mDataCollection }
                    pFeatureRefId       = { this.state.mFeatureRefId }
                    pColumns            = { this.state.mFormGrid }
                    rowIndex            = { this.state.mRowIndex } 
                    pageSize            = { 15 }
                    onClick             = { this.cfOnDataSelection }
                    pSearchActionBar    = { lSearchActions }
                    pEnableFilter       = { this.state.mEnableFilter }
                    pScrollHeight       = { lScrollHeight }
                    pEnableScroll       = { true}
                    pClearSelection     = { this.state.mClearSelection }
                />

                { lDetailsForm }
            </>
        )
    }
}

export default CSCOFormLayout15;
