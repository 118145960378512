import React, { useState, useRef, useEffect } from 'react';
import QRCode from "qrcode.react"
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import CSConfigUtil from '../util/CSConfigUtil'; 
import CSServiceUtil from '../util/CSServiceUtil'; 
import CSRequestUtil from '../util/CSRequestUtil'; 
import CSUtil from "../util/CSUtil";

const mServiceUtil = CSServiceUtil.cfGetInstance();

const CSDeliveryChallanForm1 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const [ mCompanyDetails, setCompanyDetails ] = useState(
        {
            mCompanyName: '99 Ventures',
            mAddress1   : 'D.NO;19-5-28/9/D, ',
            mAddress2   : 'Behind Old RTA Office(South Zone), Kishanbagh,Hyderabad-64',
            mPhone1     : '',
            mPhone2     : '',
            mEmail      : '',
            mWebsite    : ''
        }, []
    );

    const mCompanyLogo = CSConfigUtil.mCompanyLogo;

    const [ mFormTitle, setFormTitle ] = useState('Stock Transfer Voucher');

    const [ mCustomerDetails, setCustomerDetails ] = useState(
        {
            mTitle          : 'From :',
            mTransferFrom   : 'Central Stores', 
            mFromAddress    : 'D.NO;19-5-28/9/D, Behind Old RTA Office(South Zone), Kishanbagh,Hyderabad-64', 
            mDeliveryAddress: '', 
            mContactNumber  : '', 
            mPlaceOfSupply  : '', 
            mGstNo          : '',
            mPhoneNumber    : '',
            mEmail          : '',
            mDate           : '',
        }, []
    );

    const [ mChallanDetails, setChallanDetails ] = useState(
        {
            mTitle          : 'To :',
            mTransferTo     : 'Central Stores', 
            mToAddress      : '', 
            mChallanNo      : '', 
            mShipmentDate   : '', 
            mEwayBillNo     : '', 
            mLogistics      : '', 
            mLrNo           : '', 
            mVehicleNo      : '',
            mDriverName     : '',
            mDriverContactNo: '',
            mQrCode         : 'Test'
        }, [lProps]
    );

    const [ mStockTransferred, setStockTransferred ] = useState([]);
    const [ mKitsTransferred,  setKitsTransferred ]  = useState([]);
    const [ mPendingStock,     setPendingStock ]     = useState(null);

//    const [ mTermsTitle, setTermsTitle ] = useState('Terms & Conditions');
//    const [ mTerms, setTerms ] = useState(null);
//    const [ mConditions, setConditions ] = useState(null);

    const [ mServiceCode, setServiceCode] = useState(null);
    const [ mRequestId, setRequestId] = useState(null);

    const [ mFooter, setFooter ] = useState("Delivery Challan was created on a computer and is valid without the signature and seal.");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pCriteria && lProps.pFormType && lProps.pFormProperties)
                {
                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        if(lPropObj.type_ === 'initialize')
                        {
                            const lValue = lPropObj.value_;
                            if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'terms_title_')
                            {
//                                setTermsTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'customer_section_title_')
                            {
                                setCustomerDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'challan_section_title_')
                            {
                                setChallanDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'request_id_')
                            {
                                setRequestId(lValue);
                            }
                            else if(lPropObj.property_ === 'details_service_code_')
                            {
                                setServiceCode(lValue);
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lValue);
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, [lProps, setChallanDetails, setCustomerDetails]
    );

    useEffect(() =>
        {
            const fnInitializeData = () =>
            {
                if(mServiceCode && lProps.pCriteria)
                {
                    var lServiceObject = mServiceUtil.cfGetServiceObject(mServiceCode);
                    if(lServiceObject)
                    {
                        var lCriteria = lProps.pCriteria;
                        lCriteria.office_code_ = CSConfigUtil.mOfficeCode;
                        var lRequestUtil = new CSRequestUtil('GET', mServiceCode, lServiceObject.type_, mRequestId, lServiceObject);
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse) =>
                            {
                                if(lResponse)
                                {
                                    if(lResponse.hasOwnProperty('challan_details_') && (lResponse.challan_details_.length > 0))
                                    {
                                        var lChallanDetails = lResponse.challan_details_[0];
                                        setChallanDetails(lPrevState => 
                                            { 
                                                return { 
                                                    ...lPrevState, 
                                                    mChallanNo      : lChallanDetails.goods_dispatch_no_, 
                                                    mShipmentDate   : lChallanDetails.shipment_date_, 
                                                    mEwayBillNo     : lChallanDetails.eway_bill_no_, 
                                                    mTransferTo     : lChallanDetails.to_branch_name_, 
                                                    mToAddress      : lChallanDetails.to_branch_address_, 
                                                    mVehicleNo      : lChallanDetails.vehicle_no_,
                                                    mDriverName     : lChallanDetails.driver_name_,
                                                    mDriverContactNo: lChallanDetails.driver_contact_no_,
                                                    mQrCode         : lChallanDetails.qr_code_text_
                                                } 
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('company_details_') && (lResponse.company_details_.length > 0))
                                    {
                                        var lCompanyDetails = lResponse.company_details_[0];
                                        setCompanyDetails(lPrevState => 
                                            { 
                                                return { 
                                                    ...lPrevState, 
                                                    mAddress1   : lCompanyDetails.office_description_, 
                                                    mAddress2   : lCompanyDetails.office_address_,
                                                    mPhone1     : lCompanyDetails.office_phone_1_,
                                                    mPhone2     : lCompanyDetails.office_phone_2_,
                                                    mEmail      : lCompanyDetails.email_,
                                                    mWebsite    : lCompanyDetails.website_
                                                }
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('customer_details_') && (lResponse.customer_details_.length > 0))
                                    {
                                        var lCustomerDetails = lResponse.customer_details_[0];
                                        setCustomerDetails(lPrevState => 
                                            { 
                                                return { 
                                                    ...lPrevState, 
                                                    mFromAddress: lCustomerDetails.customer_address_, 
                                                    mTransferFrom   : lCustomerDetails.customer_name_,
                                                    mDeliveryAddress: lCustomerDetails.delivery_address_,
                                                    mEmail          : lCustomerDetails.email_,
                                                    mGstNo          : lCustomerDetails.gst_no_,
                                                    mContactNumber  : lCustomerDetails.mobile_phone_,
                                                    mPhoneNumber    : lCustomerDetails.phone_no_,
                                                } 
                                            }
                                        );
                                    }

                                    var lrcInd = 0;
                                    if(lResponse.hasOwnProperty('stock_transferred_') && (lResponse.stock_transferred_.length > 0))
                                    {
                                        var lStockTransferCollection = [];
                                        for(var ldInd=0; ldInd<lResponse.stock_transferred_.length; ldInd++)
                                        {
                                            var lStockObj = lResponse.stock_transferred_[ldInd];
                                            var lStockTransferList = [];

                                            lStockTransferList.push(<td key={lrcInd++}>{ldInd + 1}</td>);
                                            lStockTransferList.push(<td key={lrcInd++}>{lStockObj.kit_name_}</td>);
                                            lStockTransferList.push(<td key={lrcInd++}>{lStockObj.sku_code_}</td>);
                                            lStockTransferList.push(<td key={lrcInd++}>{lStockObj.item_name_}</td>);
                                            lStockTransferList.push(<td key={lrcInd++}>{lStockObj.hsn_code_}</td>);
                                            lStockTransferList.push(<td key={lrcInd++}>{lStockObj.items_per_kit_}</td>);
                                            lStockTransferList.push(<td key={lrcInd++}>{lStockObj.transferred_qty_}</td>);
                    
                                            lStockTransferCollection.push(<tr key={ldInd + 1}>{lStockTransferList}</tr>);
                                        }
                    
                                        setStockTransferred(lStockTransferCollection);
                                    }

                                    if(lResponse.hasOwnProperty('kits_transferred_') && (lResponse.kits_transferred_.length > 0))
                                    {
                                        var lKitTransferCollection = [];
                                        for(var lkInd=0; lkInd<lResponse.kits_transferred_.length; lkInd++)
                                        {
                                            var lKitObj = lResponse.kits_transferred_[lkInd];
                                            var lKitTransferList = [];

                                            lKitTransferList.push(<td key={lrcInd++}>{lkInd + 1}</td>);
                                            lKitTransferList.push(<td key={lrcInd++}>{lKitObj.kit_name_}</td>);
                                            lKitTransferList.push(<td key={lrcInd++}>{lKitObj.transferred_qty_}</td>);
                    
                                            lKitTransferCollection.push(<tr key={lrcInd + 1}>{lKitTransferList}</tr>);
                                        }
                    
                                        setKitsTransferred(lKitTransferCollection);
                                    }

                                    if(lResponse.hasOwnProperty('pending_stock_') && (lResponse.pending_stock_.length > 0))
                                    {
                                        var lPendingStockCollection = [];
                                        for(var lpsInd=0; lpsInd<lResponse.pending_stock_.length; lpsInd++)
                                        {
                                            var lPendingStockObj = lResponse.pending_stock_[lpsInd];
                                            var lPendingStockList = [];

                                            lPendingStockList.push(<td key={lrcInd++}>{lpsInd + 1}</td>);
                                            lPendingStockList.push(<td key={lrcInd++}>{lPendingStockObj.kit_name_}</td>);
                                            lPendingStockList.push(<td key={lrcInd++}>{lPendingStockObj.sku_code_}</td>);
                                            lPendingStockList.push(<td key={lrcInd++}>{lPendingStockObj.item_name_}</td>);
                                            lPendingStockList.push(<td key={lrcInd++}>{lPendingStockObj.hsn_code_}</td>);
                                            lPendingStockList.push(<td key={lrcInd++}>{lPendingStockObj.items_per_kit_}</td>);
                                            lPendingStockList.push(<td key={lrcInd++}>{lPendingStockObj.pending_stock_qty_}</td>);
                    
                                            lPendingStockCollection.push(<tr key={lpsInd + 1}>{lPendingStockList}</tr>);
                                        }
                    
                                        setPendingStock(lPendingStockCollection);
                                    }
/*
                                    if(lResponse.hasOwnProperty('terms_') && (lResponse.terms_.length > 0))
                                    {
                                        var lTerms = [];
                                        for(var ltInd=0; ltInd<lResponse.terms_.length; ltInd++)
                                        {
                                            var lTermObj = lResponse.terms_[ltInd];
                                            var lTerm = 
                                                <div key={ltInd}>
                                                    {lTermObj.sequence_} &nbsp;.&nbsp;&nbsp; {lTermObj.details_}
                                                </div>
                    
                                            lTerms.push(lTerm);
                                        }
                    
                                        setTerms(lTerms);
                                    }

                                    if(lResponse.hasOwnProperty('conditions_') && (lResponse.conditions_.length > 0))
                                    {
                                        var lConditions = [];
                                        for(var lcInd=0; lcInd<lResponse.conditions_.length; lcInd++)
                                        {
                                            var lConditionObj = lResponse.conditions_[lcInd];
                                            var lCondition = 
                                                <div key={lcInd}>
                                                    {lConditionObj.sequence_} &nbsp;.&nbsp;&nbsp; {lConditionObj.details_}
                                                </div>
                    
                                            lConditions.push(lCondition);
                                        }
                    
                                        setConditions(lConditions);
                                    }
*/
                                }
                            }
                        );
                    }
                }
            }

            fnInitializeData();
        }, [mServiceCode, mRequestId, lProps.pCriteria]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => (lProps.fnEnablePrint) ? lProps.fnEnablePrint(false) : null }
        />
    );

    var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    const lForm = 
        <div id="dc" ref={mComponentRef}>
            <div className="dc overflow-auto">
                <div style={{minWidth: '600px'}}>
                    <header>
                        <div className="row">
                            <div className="logo">
                                <img
                                    src={ mCompanyLogo } 
                                    width="100%"
                                    alt=""/>
                            </div>

                            <div className="company-details">
                                <h2 className="name">
                                    { mCompanyDetails.mCompanyName }
                                </h2>

                                <div>
                                    { mCompanyDetails.mAddress1 }
                                </div>

                                <div>
                                    { mCompanyDetails.mAddress2 }
                                </div>
                            </div>
                        </div>
                    </header>

                    <main>
                        <div className="title">
                            <h1>{ mFormTitle }</h1>
                        </div>

                        <div className="summary">
                            <div className="customer-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '130px', fontSize: '12.5px', fontWeight: 'bold'}}>Shipment Date</div>
                                    <div style={{width: '25px', fontSize: '12.5px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%', fontSize: '12px'}}>{ mChallanDetails.mShipmentDate }</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '130px', fontSize: '12.5px', fontWeight: 'bold'}}>From</div>
                                    <div style={{width: '25px', fontSize: '12.5px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%', fontSize: '12px'}}>{ mCustomerDetails.mTransferFrom }</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '130px', fontSize: '12.5px', fontWeight: 'bold'}}>Address</div>
                                    <div style={{width: '25px', fontSize: '12.5px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%', fontSize: '12px'}}>{ mCustomerDetails.mFromAddress }</div>
                                </div>
                            </div>
                    
                            <div className="challan-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '100px', fontSize: '12.5px', fontWeight: 'bold'}}>Challan No</div>
                                    <div style={{width: '25px', fontSize: '12.5px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%', fontSize: '12px'}}>{ mChallanDetails.mChallanNo }</div>
                                </div>

                                <div className="form-item-row">
                                    <div style={{width: '100px', fontSize: '12.5px', fontWeight: 'bold'}}>To</div>
                                    <div style={{width: '25px', fontSize: '12.5px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%', fontSize: '12px'}}>{mChallanDetails.mTransferTo}</div>
                                </div>

                                <div className="form-item-row">
                                    <div style={{width: '100px', fontSize: '12.5px', fontWeight: 'bold'}}>Address</div>
                                    <div style={{width: '25px', fontSize: '12.5px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%', fontSize: '12px'}}>{ mChallanDetails.mToAddress }</div>
                                </div>
                           </div>
                    
                            <div className="qrcode">
                                <QRCode
                                    bgColor = "#FFFFFF"
                                    level   = "L"
                                    style   = {{ width: '72px', height: '72px' }}
                                    value   = {(mChallanDetails.mQrCode) ? mChallanDetails.mQrCode : ''}
                                />
                            </div>
                        </div>
                        
                        <>
{/*                             <h3 className="package-contents-title">
                            Stock Transfer Voucher - Item Wise
                            </h3>
 */}                            <div className="shipment-contents">
                                <table className="items">
                                    <thead>
                                        <td colSpan={7} style={{border : 'none', textAlign : 'center'}}>
                                            <h3 className="package-contents-title">
                                                Stock Transfer Voucher - Item Wise
                                            </h3>
                                            <h4 className="package-challan-no-title">
                                                Challan No : { mChallanDetails.mChallanNo }
                                            </h4>
                                        </td>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Kit Name</th>
                                            <th>SKU Code</th>
                                            <th>Item Name</th>
                                            <th>HSN Code</th>
                                            <th>Items Per Kit</th>
                                            <th>Transferred Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mStockTransferred}
                                    </tbody>
                                </table>
                            </div>
                        </>
                        <div className="page-break"/>
                        <>
{/*                             <h3 className="package-contents-title">
                                Stock Transfer Voucher - Kit Wise
                            </h3> */}
                            <div className="shipment-contents">
                                <table className="items">
                                    <thead>
                                        <td colSpan={3} style={{border : 'none', textAlign : 'center'}}>
                                            <h3 className="package-contents-title">
                                                Stock Transfer Voucher - Kit Wise
                                            </h3>
                                            <h4 className="package-challan-no-title">
                                                Challan No : { mChallanDetails.mChallanNo }
                                            </h4>
                                        </td>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Kit Name</th>
                                            <th>Transferred Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { mKitsTransferred }
                                    </tbody>
                                </table>
                            </div>
                        </>
                        <div className="page-break"/>
                        <>
                            {/* <h3 className="package-contents-title">
                                Stock Transfer Voucher - Pending Items
                            </h3> */}
                            <div className="shipment-contents">
                                <table className="items">
                                    <thead>
                                        <td colSpan={7} style={{border : 'none', textAlign : 'center'}}>
                                            <h3 className="package-contents-title">
                                                Stock Transfer Voucher - Pending Items
                                            </h3>
                                            <h4 className="package-challan-no-title">
                                                Challan No : { mChallanDetails.mChallanNo }
                                            </h4>
                                        </td>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Kit Name</th>
                                            <th>SKU Code</th>
                                            <th>Item Name</th>
                                            <th>HSN Code</th>
                                            <th>Items Per Kit</th>
                                            <th>Pending Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mPendingStock}
                                    </tbody>
                                </table>
                            </div>
                        </>

                        <div style={{display: 'flex', flexDirection: 'row', height: '100px', alignItems: 'center'}}>
                            <div style={{width: '50%', fontSize: '12.5px', fontWeight: 'bold', textAlign: 'left'}}>Stores Incharge</div>
                            <div style={{width: '50%', fontSize: '12.5px', fontWeight: 'bold', textAlign: 'right'}}>Branch Incharge</div>
                        </div>
                    </main>
                    <footer>
                        { mFooter }
                    </footer>
                </div>

                <div></div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            header    = {lHeader}
            appendTo  = { document.body }
            onHide    = { () => (lProps.fnEnablePrint) ? lProps.fnEnablePrint(false) : null }>
                { lForm }
        </Dialog>
    );
}

export default CSDeliveryChallanForm1;