import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import CSUtil from "../util/CSUtil";
import CSConfigUtil from "../util/CSConfigUtil";
import { fnConvertNumberToWords, fnNumberIndiaFormat } from '../util/CSMathUtil';
import { fnGetDateToString, fnGetStringToDate } from '../util/CSDateTimeUtil';
import CSRequestUtil from '../util/CSRequestUtil';
import { cfGetConstantValue } from '../util/CSConstantUtil';
import CSServiceUtil from '../util/CSServiceUtil';

var CryptoJS = require("crypto-js");

const CSReceiptForm4 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const mParentCopyHeading = 'PARENT COPY';
    
    const [ mInstituteHeading, setInstituteHeading ] = useState('')
    const [ mStudentDetails, setStudentDetails ] = useState(
        {
            mStudentName          : '',
            mParentName           : '',
            mClass                : '',
            mSection              : '',
            mSectionLabel         : '',
            mRegistrationNo       : '',
            mRegistrationNoLabel  : ''
        }, []
    );

//    const [ mFormTitle, setFormTitle ] = useState(CSConfigUtil.mReceiptHeading);
//    const [ mFormSubTitle1, setFormSubTitle1 ] = useState(CSConfigUtil.mReceiptSubHeading1);
//    const [ mFormSubTitle2, setFormSubTitle2 ] = useState(CSConfigUtil.mReceiptSubHeading2);

    const [ mFormTitle, setFormTitle ] = useState(CSConfigUtil.mReceiptHeading[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle1, setFormSubTitle1 ] = useState(CSConfigUtil.mReceiptSubHeading1[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle2, setFormSubTitle2 ] = useState(CSConfigUtil.mReceiptSubHeading2[CSConfigUtil.mBranchCode]);

    const [ mReceiptDetails, setReceiptDetails ] = useState(
        {
            mTransactionId     : '', 
            mReceiptNo         : '', 
            mReceiptDate       : '', 
            mBranchName        : '', 
            mAdmissionNo       : '',
            mInquiryNo         : '',
            mQrCode            : '',
            mEncryptedQrCode   : '',
            mTimestamp         : '',
            mPaymentInWords    : '',
            mIdName            : ''      
        }, [lProps]
    );

    const [mChequeNoTitle, setChequeNoTitle] = useState(null);
    const [mChequeDateTitle, setChequeDateTitle] = useState(null);
    const [mDraweeBankTitle, setDraweeBankTitle] = useState(null);

    const [ mTaxInvoiceTotalPayment, setTaxInvoiceTotalPayment ] = useState(0);
    const [ mTaxInvoicePaymentInWords, setTaxInvoicePaymentInWords ] = useState('');

    const [ mBosTotalPayment, setBosTotalPayment ] = useState(0);
    const [ mBosPaymentInWords, setBosPaymentInWords ] = useState('');

//    const [ mPaymentDetails, setPaymentDetails ] = useState([]);
    const [ mTaxInvoiceReceiptNo, setTaxInvoiceReceiptNo ] = useState([]);
    const [ mBillOfSupplyReceiptNo, setBillOfSupplyReceiptNo ] = useState([]);
    const [ mItemDetails, setItemDetails ] = useState([]);
    const [ mBosItemDetails, setBosItemDetails ] = useState([]);
    const [ mPaymentInfo, setPaymentInfo ] = useState(
        {
            mPaymentMode : '',
            mChequeNo    : '',
            mChequeDate  : '',
            mBank        : '',
            mAccountant  : '   '
        }
    );

    const [ mFooter, setFooter ] = useState("");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pFormProperties)
                {
                    var lDetailsServiceCode = null;
                    var lReceiptServiceCode = null;
                    var lReceiptDetails = null;
                    var lReceiptDetailsName = null;
                    var lDefaultDataField = 'selected_data_';
                    var lCriteriaField = '';
                    
                    var lTotalPmtField = 'total_payment_amount_';
                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        if(lPropObj.type_ === 'initialize')
                        {
                            const lValue = lPropObj.value_;
                            if(lPropObj.property_ === 'details_service_code_')
                            {
                                lDetailsServiceCode = lValue;
                            }
                            if(lPropObj.property_ === 'receipt_service_code_')
                            {
                                lReceiptServiceCode = lValue;
                            }
                            else if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_1_')
                            {
                                setFormSubTitle1(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_2_')
                            {
                                setFormSubTitle2(lValue);
                            }
                            else if(lPropObj.property_ === 'receipt_details_')
                            {
                                lReceiptDetailsName = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'default_data_field_')
                            {
                                lDefaultDataField = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'criteria_field_')
                            {
                                lCriteriaField = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lValue);
                            }
                            else if(lPropObj.property_ === 'total_payment_field_')
                            {
                                lTotalPmtField = lPropObj.value_;
                            }
                        }
                    }
                    
                    if(lReceiptDetailsName)
                    {
                        if(lProps.hasOwnProperty(lReceiptDetailsName))
                        {
                            var lRcptData = lProps[lReceiptDetailsName];
                            if(lDefaultDataField && lRcptData.hasOwnProperty(lDefaultDataField))
                            {
                                lReceiptDetails = lRcptData[lDefaultDataField];
                            }
                            else
                            {
                                lReceiptDetails = lRcptData;
                            }

                            var lRctData1 = [];
                            lRctData1.push(<td key={1}>Total Payment</td>);
                            lRctData1.push(<td key={2}>Rupees { fnNumberIndiaFormat(lReceiptDetails[lTotalPmtField]) }</td>);
                            
                            var lPmtDetails = [];
                            lPmtDetails.push(<tr key={1}>{lRctData1}</tr>);
//                            setPaymentDetails(lPmtDetails);
                        }
                    }
                }

                if(lReceiptDetails)
                {
                    var lRecData = {}
                    var lStdData = {}
                    var lPmtInfo = {}
                    for(var lInd1=0; lInd1<lProps.pFormProperties.length;lInd1++)
                    {
                        var lPropObj0 = lProps.pFormProperties[lInd1];
                        if(lPropObj0.type_ === 'initialize')
                        {
                            const lValue = lPropObj0.value_;
                            if(lPropObj0.property_ === 'receipt_no_')
                            {
                                lRecData.mReceiptNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'institute_heading_')
                            {
                                setInstituteHeading(lValue);
                            }
                            else if(lPropObj0.property_ === 'admission_no_')
                            {
                                lRecData.mAdmissionNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'inquiry_no_')
                            {
                                lRecData.mInquiryNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'id_name_')
                            {
                                lRecData.mIdName = lValue;
                            }
                            else if(lPropObj0.property_ === 'branch_name_')
                            {
                                lRecData.mBranchName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'date_')
                            {
                                var lDate1 = fnGetStringToDate(lReceiptDetails[lValue]);
                                if(lDate1)
                                {
                                    var lDate1Str = fnGetDateToString(lDate1, 'display' )
                                    lRecData.mReceiptDate = lDate1Str;
                                }
                            }
                            else if(lPropObj0.property_ === 'time_')
                            {
                                lRecData.mReceiptTime = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'total_payment_')
                            {
                                if(lReceiptDetails[lValue])
                                {
                                    var lRcptVal = (Math.round(lReceiptDetails[lValue])).toFixed(0);
                                    lRecData.mPaymentInWords = fnConvertNumberToWords(lRcptVal);
                                }

                                lRecData.mTotalPayment = fnNumberIndiaFormat(lReceiptDetails[lValue]);
                            }
                            else if(lPropObj0.property_ === 'student_name_')
                            {
                                lStdData.mStudentName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'parent_name_')
                            {
                                lStdData.mParentName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'class_')
                            {
                                lStdData.mClass = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'section_')
                            {
                                lStdData.mSection = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'section_label_')
                            {
                                lStdData.mSectionLabel = lValue;
                            }
                            else if(lPropObj0.property_ === 'registration_no_')
                            {
                                lStdData.mRegistrationNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'registration_no_label_')
                            {
                                lStdData.mRegistrationNoLabel = lValue;
                            }
                            else if(lPropObj0.property_ === 'purchased_for_class_')
                            {
                                lStdData.mPurchasedFor = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'payment_mode_')
                            {
                                lPmtInfo.mPaymentMode = lReceiptDetails[lValue];
                                var lChequeNoTitle = '';
                                var lChequeDateTitle  = '';
                                var lDraweeBankTitle  = '';

                                if(lPmtInfo.mPaymentMode === 'CASH')
                                {
                                    lChequeNoTitle = '';
                                    lChequeDateTitle  = '';
                                    lDraweeBankTitle  = '';
                                }
                                else if(lPmtInfo.mPaymentMode === 'POS')
                                {
                                    lChequeNoTitle = 'Batch No/Appr Code';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.batch_no_ + "/" + lReceiptDetails.approval_code_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'BANK_TXFR')
                                {
                                    lChequeNoTitle = 'UTR No';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.utr_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'UPI')
                                {
                                    lChequeNoTitle = 'UPI No';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.upi_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'BBPS')
                                {
                                    lChequeNoTitle = 'UPI No';
                                    lChequeDateTitle  = '';
                                    lDraweeBankTitle  = '';

                                    lPmtInfo.mChequeNo = lReceiptDetails.upi_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'CHEQUE')
                                {
                                    lChequeNoTitle = 'Cheque No';
                                    lChequeDateTitle  = 'Cheque Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.cheque_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }

                                setChequeDateTitle(lChequeDateTitle);
                                setChequeNoTitle(lChequeNoTitle);
                                setDraweeBankTitle(lDraweeBankTitle);
                            }
                        }
                    }

                    if(lRecData.mReceiptDate && lRecData.mReceiptTime)
                    {
                        lRecData.mTimestamp = lRecData.mReceiptDate + lRecData.mReceiptTime; 
                        lRecData.mQrCode  =    "Receipt Date : "    + lRecData.mReceiptDate;
                        lRecData.mQrCode += "\n Receipt Time : "    + lRecData.mReceiptTime;
                        lRecData.mQrCode += "\n Student Name : "    + lStdData.mStudentName;
                        lRecData.mQrCode += "\n Parent Name : "     + lStdData.mParentName;
                        lRecData.mQrCode += "\n Class : "           + lStdData.mClass;
                        lRecData.mQrCode += "\n Section : "         + lStdData.mSection;
                        lRecData.mQrCode += "\n Admission No : "    + lRecData.mAdmissionNo;
                        lRecData.mQrCode += "\n Inquiry No : "      + lRecData.mInquiryNo;
                        lRecData.mQrCode += "\n Registration No : " + lRecData.mRegistrationNo;
                        lRecData.mQrCode += "\n Payment : "         + lRecData.mTotalPayment;

                        var lTmpdata = CSUtil.cfGetObjectCopy(lRecData.mQrCode);
                        lTmpdata = "Transaction Id : " + lRecData.id_ + "\n " + lTmpdata;
                        lRecData.mEncryptedQrCode = CryptoJS.AES.encrypt(JSON.stringify(lTmpdata), 'cs_app').toString();
                    }

                    setReceiptDetails(lRecData);
                    setStudentDetails(lStdData);
                    setPaymentInfo(lPmtInfo);
                    
                    if(lReceiptDetails.hasOwnProperty(lCriteriaField))
                    {
                        var lCriteriaObj = {};
                        lCriteriaObj.receipt_no_ = lReceiptDetails.receipt_no_;
                        var lServiceUtil = CSServiceUtil.cfGetInstance();
                        if(lReceiptServiceCode)  
                        {
                            var lReceiptServiceObject = lServiceUtil.cfGetServiceObject(lReceiptServiceCode);
                            if(lReceiptServiceObject)
                            {
                                var lRequestUtil0 = new CSRequestUtil('GET', lReceiptServiceCode, lReceiptServiceObject.type_, null, lReceiptServiceObject);

                                lRequestUtil0.cfSetRequestCriteria(lCriteriaObj);
                                lRequestUtil0.cfProcessRequest((lResponse0) =>
                                    {
                                        if((lResponse0) && lResponse0.hasOwnProperty("collection_"))
                                        {
                                            if(lResponse0.collection_ && (lResponse0.collection_.length > 0))
                                            {
                                                var lRcptObj = lResponse0.collection_[0];
                                                setTaxInvoiceReceiptNo(lRcptObj.tax_invoice_receipt_no_);
                                                setBillOfSupplyReceiptNo(lRcptObj.bill_of_supply_receipt_no_);
                                            }
                                        }
                                    }
                                );
                            }
                        }

                        if(lDetailsServiceCode)
                        {
                            var lServiceObject = lServiceUtil.cfGetServiceObject(lDetailsServiceCode);
                            if(lServiceObject)
                            {
                                var lRequestUtil = new CSRequestUtil('GET', lDetailsServiceCode, lServiceObject.type_, null, lServiceObject);
                                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                                {
                                    var lServiceProperties = lServiceObject.cs_service_properties_;
                                    if(lServiceProperties !== null)
                                    {
                                        for(var lspInd=0; lspInd<lServiceProperties.length; lspInd++)
                                        {
                                            lCriteriaObj[lServiceProperties[lspInd].property_] = cfGetConstantValue(lServiceProperties[lspInd].value_);
                                        }
                                    }
                                }

                                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                                lRequestUtil.cfProcessRequest((lResponse) =>
                                    {
                                        var lCollection = [];
                                        if((lResponse) && lResponse.hasOwnProperty("collection_"))
                                        {
                                            var lItemCollection = [];
                                            var lBosCollection = [];
                                            lCollection = lResponse.collection_;
                                            var lcgstValue = 0;
                                            var lsgstValue = 0;
                                            var lgstValue = 0;
                                            var lKeyInd = 1000
                                            var lTaxInvoiceTotalPayment = 0;
                                            var lTaxInvoiceNetPayment = 0;
                                            var lBosTotalPayment = 0;

                                            for(var lpInd=0; lpInd<lCollection.length; lpInd++)
                                            {
                                                var lData = lCollection[lpInd];
                                                var lItemData = [];

                                                if(Number(lData.gst_value_) > 0)
                                                {
                                                    lItemData.push( <td key={lKeyInd + 101} > {lItemCollection.length + 1} </td> );
                                                    lItemData.push( <td key={lKeyInd + 102} > {lData.item_name_}        </td> );
                                                    lItemData.push( <td key={lKeyInd + 103} > {lData.hsn_code_}         </td> );
                                                    lItemData.push( <td key={lKeyInd + 104} > {lData.gst_percentage_}   </td> );
                                                    lItemData.push( <td key={lKeyInd + 105} > {lData.quantity_}         </td> );
                                                    lItemData.push( <td key={lKeyInd + 107} > {lData.sale_price_}       </td> );
                                                    lItemCollection.push(<tr key={lKeyInd + 108}>{lItemData}</tr>);
                                                
                                                    lcgstValue = Number(lcgstValue) + Number(lData.cgst_value_);
                                                    lsgstValue = Number(lsgstValue) + Number(lData.sgst_value_);
                                                    lgstValue  = Number(lcgstValue) + Number(lsgstValue);

                                                    lTaxInvoiceTotalPayment += Number(lData.item_amount_);
                                                    lTaxInvoiceNetPayment += Number(lData.sale_price_);
                                                }
                                                else
                                                {
                                                    lItemData.push( <td key={lKeyInd + 101} > {lBosCollection.length + 1} </td> );
                                                    lItemData.push( <td key={lKeyInd + 102} > {lData.item_name_}        </td> );
                                                    lItemData.push( <td key={lKeyInd + 103} > {lData.hsn_code_}         </td> );
                                                    lItemData.push( <td key={lKeyInd + 104} > {lData.gst_percentage_}   </td> );
                                                    lItemData.push( <td key={lKeyInd + 105} > {lData.quantity_}         </td> );
                                                    lItemData.push( <td key={lKeyInd + 107} > {lData.sale_price_}      </td> );
                                                    lBosCollection.push(<tr key={lKeyInd + 108}>{lItemData}</tr>);

                                                    lBosTotalPayment += Number(lData.item_amount_);
                                                }

                                                if(lpInd === lCollection.length - 1)
                                                {
                                                    var lnettData = [];
                                                    lnettData.push( <td key={lKeyInd + 109}/> );
                                                    lnettData.push( <td key={lKeyInd + 110}/> );
                                                    lnettData.push( <td key={lKeyInd + 111}/> );
                                                    lnettData.push( <td key={lKeyInd + 112}/> );
                                                    lnettData.push( <td key={lKeyInd + 113}> Net Amt. </td> );
                                                    lnettData.push( <td key={lKeyInd + 114}> {Math.round(lTaxInvoiceNetPayment).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 115}>{lnettData}</tr>);

                                                    var lcgstData = [];
                                                    lcgstData.push( <td key={lKeyInd + 116}/> );
                                                    lcgstData.push( <td key={lKeyInd + 117}/> );
                                                    lcgstData.push( <td key={lKeyInd + 118}/> );
                                                    lcgstData.push( <td key={lKeyInd + 119}/> );
                                                    lcgstData.push( <td key={lKeyInd + 120}> CGST Amt. </td> );
                                                    lcgstData.push( <td key={lKeyInd + 121}> {Math.round(lcgstValue).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 122}>{lcgstData}</tr>);

                                                    var lsgstData = [];
                                                    lsgstData.push( <td key={lKeyInd + 123}/> );
                                                    lsgstData.push( <td key={lKeyInd + 124}/> );
                                                    lsgstData.push( <td key={lKeyInd + 125}/> );
                                                    lsgstData.push( <td key={lKeyInd + 126}/> );
                                                    lsgstData.push( <td key={lKeyInd + 127}> SGST Amt. </td> );
                                                    lsgstData.push( <td key={lKeyInd + 128}> {Math.round(lsgstValue).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 129}>{lsgstData}</tr>);

                                                    var lgstData = [];
                                                    lgstData.push( <td key={lKeyInd + 130}/> );
                                                    lgstData.push( <td key={lKeyInd + 131}/> );
                                                    lgstData.push( <td key={lKeyInd + 132}/> );
                                                    lgstData.push( <td key={lKeyInd + 133}/> );
                                                    lgstData.push( <td key={lKeyInd + 134}> Total GST. </td> );
                                                    lgstData.push( <td key={lKeyInd + 135}> {Math.round(lgstValue).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 136}>{lgstData}</tr>);

                                                    var ltotData = [];
                                                    ltotData.push( <td key={lKeyInd + 137}/> );
                                                    ltotData.push( <td key={lKeyInd + 138}/> );
                                                    ltotData.push( <td key={lKeyInd + 139}/> );
                                                    ltotData.push( <td key={lKeyInd + 140}/> );
                                                    ltotData.push( <td key={lKeyInd + 141}> Total Amt. </td> );
                                                    ltotData.push( <td key={lKeyInd + 142}> {Math.round(lTaxInvoiceTotalPayment).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 143}>{ltotData}</tr>);

                                                    var lnettData1 = [];
                                                    lnettData1.push( <td key={lKeyInd + 144}/> );
                                                    lnettData1.push( <td key={lKeyInd + 145}/> );
                                                    lnettData1.push( <td key={lKeyInd + 146}/> );
                                                    lnettData1.push( <td key={lKeyInd + 147}/> );
                                                    lnettData1.push( <td key={lKeyInd + 148}> Net Amt. </td> );
                                                    lnettData1.push( <td key={lKeyInd + 149}> {Math.round(lBosTotalPayment).toFixed(2)} </td> );

                                                    lBosCollection.push(<tr key={lKeyInd + 150}>{lnettData1}</tr>);

                                                    var lbosCgstData = [];
                                                    lbosCgstData.push( <td key={lKeyInd + 151}/> );
                                                    lbosCgstData.push( <td key={lKeyInd + 152}/> );
                                                    lbosCgstData.push( <td key={lKeyInd + 153}/> );
                                                    lbosCgstData.push( <td key={lKeyInd + 154}/> );
                                                    lbosCgstData.push( <td key={lKeyInd + 155}> CGST Amt. </td> );
                                                    lbosCgstData.push( <td key={lKeyInd + 156}> 0 </td> );

                                                    lBosCollection.push(<tr key={lKeyInd + 157}>{lbosCgstData}</tr>);

                                                    var lbosSgstData = [];
                                                    lbosSgstData.push( <td key={lKeyInd + 158}/> );
                                                    lbosSgstData.push( <td key={lKeyInd + 159}/> );
                                                    lbosSgstData.push( <td key={lKeyInd + 160}/> );
                                                    lbosSgstData.push( <td key={lKeyInd + 161}/> );
                                                    lbosSgstData.push( <td key={lKeyInd + 162}> SGST Amt. </td> );
                                                    lbosSgstData.push( <td key={lKeyInd + 163}> 0 </td> );

                                                    lBosCollection.push(<tr key={lKeyInd + 164}>{lbosSgstData}</tr>);

                                                    var lbosGstData = [];
                                                    lbosGstData.push( <td key={lKeyInd + 165}/> );
                                                    lbosGstData.push( <td key={lKeyInd + 166}/> );
                                                    lbosGstData.push( <td key={lKeyInd + 167}/> );
                                                    lbosGstData.push( <td key={lKeyInd + 168}/> );
                                                    lbosGstData.push( <td key={lKeyInd + 169}> Total GST. </td> );
                                                    lbosGstData.push( <td key={lKeyInd + 170}> 0 </td> );

                                                    lBosCollection.push(<tr key={lKeyInd + 171}>{lbosGstData}</tr>);

                                                    var ltottData1 = [];
                                                    ltottData1.push( <td key={lKeyInd + 172}/> );
                                                    ltottData1.push( <td key={lKeyInd + 173}/> );
                                                    ltottData1.push( <td key={lKeyInd + 174}/> );
                                                    ltottData1.push( <td key={lKeyInd + 175}/> );
                                                    ltottData1.push( <td key={lKeyInd + 176}> Total Amt. </td> );
                                                    ltottData1.push( <td key={lKeyInd + 177}> {Math.round(lBosTotalPayment).toFixed(2)} </td> );

                                                    lBosCollection.push(<tr key={lKeyInd + 178}>{ltottData1}</tr>);
                                                }
/*
                                                var ltotData = [];
                                                ltotData.push( <td key={lKeyInd + 179}/> );
                                                ltotData.push( <td key={lKeyInd + 180}/> );
                                                ltotData.push( <td key={lKeyInd + 181}/> );
                                                ltotData.push( <td key={lKeyInd + 182}/> );
                                                ltotData.push( <td key={lKeyInd + 183}> Total Amt. </td> );
                                                ltotData.push( <td key={lKeyInd + 184}> {Math.round(lTaxInvoiceTotalPayment).toFixed(2)} </td> );
*/
                                                lKeyInd += 16;
                                            }

                                            var lRcptVal1 = (Math.round(lTaxInvoiceTotalPayment)).toFixed(0);
                                            var lRcptVal2 = (Math.round(lBosTotalPayment)).toFixed(0);

                                            setTaxInvoiceTotalPayment(fnNumberIndiaFormat(lRcptVal1));
                                            setTaxInvoicePaymentInWords(fnConvertNumberToWords(lRcptVal1));
                                            setBosTotalPayment(fnNumberIndiaFormat(lRcptVal2));
                                            setBosPaymentInWords(fnConvertNumberToWords(lRcptVal2));

                                            setItemDetails(lItemCollection);
                                            setBosItemDetails(lBosCollection);
                                        }                                
                                    }
                                );
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, [lProps, setStudentDetails, setReceiptDetails, setPaymentInfo]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => lProps.fnProcessClose(true, true) }
        />
    );

    var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    const lForm = 
        <div id="rf_1" ref={mComponentRef}>
            <div className="rf1 overflow-auto">
                <div style={{minWidth: '750px'}}>
                    <main>
                        <div className="top">
                            <div className="heading-copy">
                                { mParentCopyHeading }
                            </div>
                        </div>

                        <div className="title-summary">
                            <div className='tax-info'>
                                    <div className="form-item-row"> 
                                        <div style={{width: '80px', fontWeight: 'bold'}}>GSTIN</div>
                                        <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                        <div style={{width: '80%'}}>36AARFG5671Q1ZE</div>
                                    </div>
                                    <div className="form-item-row"> 
                                        <div style={{width: '80px', fontWeight: 'bold'}}>Place</div>
                                        <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                        <div style={{width: '80%'}}>Hyderabad - 36</div>
                                    </div>
                            </div>

                            <div className="title">
                                <h1>{ mInstituteHeading }</h1>

                                <div className='sub-title'>
                                    <h2>{ mFormSubTitle1 }</h2>
                                    <h2>{ mFormSubTitle2 }</h2>
                                </div>
                            </div>
                        </div>

                        <div className='form-title'>
                            <h2>TAX INVOICE</h2>
                        </div>

                        <div className="summary">
                            <div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Customer Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mStudentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Class</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mClass}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Section</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mSection}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Purchased For</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mPurchasedFor}</div>
                                </div>
                            </div>
                    
                            <div className="receipt-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Receipt No</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mTaxInvoiceReceiptNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Location</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mBranchName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>{ mReceiptDetails.mIdName }</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{ (mReceiptDetails.mIdName === 'Admission No') ? (mReceiptDetails.mAdmissionNo) : (mReceiptDetails.mInquiryNo) }</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mReceiptDate}</div>
                                </div>
                            </div>
                        </div>
                         
                        <div className="payment-details">
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Product/Item Name</th>
                                        <th>HSN Code</th>
                                        <th>GST(%)</th>
                                        <th>Qty</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mItemDetails}
                                </tbody>
                            </table>
                        </div>

                        <div className="pmt-amt"> 
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment Amount</div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>{mTaxInvoiceTotalPayment}</div>
                            </div>
                                
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment in Words </div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>Rupees {mTaxInvoicePaymentInWords}</div>
                            </div>
                        </div>
                        
                        <div className="payment-info">
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Payment Mode" }
                                </div>

                                { mPaymentInfo.mPaymentMode }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeNoTitle }
                                </div>

                                { mPaymentInfo.mChequeNo }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeDateTitle }
                                </div>

                                { mPaymentInfo.mChequeDate }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mDraweeBankTitle }
                                </div>

                                { mPaymentInfo.mBank }
                            </div>
                            <div className="pi-item-accountant">
                                { mPaymentInfo.mAccountant }

                                <div className="pi-item-heading">
                                    { "Principal/Accountant" }
                                </div>
                            </div>
                        </div>
                        <div className="cheque-realization"> 
                            { mFooter }
                        </div>
                    </main>

                    <div className="page-break"/>

                    <main>
                        <div className="top-1">
                            <div className="heading-copy">
                                { mParentCopyHeading }
                            </div>
                        </div>

                        <div className="title-summary">
                            <div className='tax-info'>
                                    <div className="form-item-row"> 
                                        <div style={{width: '80px', fontWeight: 'bold'}}>GSTIN</div>
                                        <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                        <div style={{width: '80%'}}>36AARFG5671Q1ZE</div>
                                    </div>
                                    <div className="form-item-row"> 
                                        <div style={{width: '80px', fontWeight: 'bold'}}>Place</div>
                                        <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                        <div style={{width: '80%'}}>Hyderabad - 36</div>
                                    </div>
                            </div>

                            <div className="title">
                                <h1>{ mInstituteHeading }</h1>

                                <div className='sub-title'>
                                    <h2>{ mFormSubTitle1 }</h2>
                                    <h2>{ mFormSubTitle2 }</h2>
                                </div>
                            </div>
                            <div className='tax-info'>
                            </div>
                        </div>

                        <div className='form-title'>
                            <h2>BILL OF SUPPLY</h2>
                        </div>

                        <div className="summary">
                            <div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Customer Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mStudentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Class</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mClass}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Section</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mSection}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Purchased For</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mPurchasedFor}</div>
                                </div>
                            </div>
                    
                            <div className="receipt-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Receipt No</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mBillOfSupplyReceiptNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Location</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mBranchName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>{ mReceiptDetails.mIdName }</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{ (mReceiptDetails.mIdName === 'Admission No') ? (mReceiptDetails.mAdmissionNo) : (mReceiptDetails.mInquiryNo) }</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mReceiptDate}</div>
                                </div>
                            </div>
                        </div>
                         
                        <div className="payment-details">
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Product/Item Name</th>
                                        <th>HSN Code</th>
                                        <th>GST(%)</th>
                                        <th>Qty</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mBosItemDetails}
                                </tbody>
                            </table>
                        </div>

                        <div className="pmt-amt"> 
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment Amount</div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>{mBosTotalPayment}</div>
                            </div>
                                
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment in Words </div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>Rupees {mBosPaymentInWords}</div>
                            </div>
                        </div>
                        
                        <div className="payment-info">
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Payment Mode" }
                                </div>

                                { mPaymentInfo.mPaymentMode }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeNoTitle }
                                </div>

                                { mPaymentInfo.mChequeNo }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeDateTitle }
                                </div>

                                { mPaymentInfo.mChequeDate }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mDraweeBankTitle }
                                </div>

                                { mPaymentInfo.mBank }
                            </div>
                            <div className="pi-item-accountant">
                                { mPaymentInfo.mAccountant }

                                <div className="pi-item-heading">
                                    { "Principal/Accountant" }
                                </div>
                            </div>
                        </div>
                        <div className="cheque-realization"> 
                            { mFooter }
                        </div>
                    </main>
                </div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            appendTo  = { document.body }
            header    = {lHeader}
            onHide    = { () => lProps.fnProcessClose(true, true) }>
                { lForm }
        </Dialog>
    );
}

export default CSReceiptForm4;