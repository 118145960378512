import React from "react"

import CSCOBaseFormLayout from "../form/CSCOBaseFormLayout";
import CSCOBasicAnalysisLayout from "./CSCOBasicAnalysisLayout";
import {Dropdown} from 'primereact/dropdown';
import CSUtil from "../../util/CSUtil";
import {Panel} from 'primereact/panel';

class CSCODashboardReport extends CSCOBaseFormLayout 
{

    constructor(props) 
    {
        super(props);
        this.state = 
        {
            selectedReport          : null,
            mFeatureRefId           : this.props.pFeatureRefId,
            mAppId                  : this.props.pAppId,
            mLayoutCode             : this.props.pLayoutCode,
            mLayoutType             : this.props.pLayoutType,
            mSelectedMenuItem       : null,
            mFormLayoutHeading      : null,
            mDataCollection         : null,
            mParent                 : this.props.pParent,
            mSearchActionList       : null,
            mFormActions            : this.props.pFormActions,
            //mFormGrid             : (this.props.pFormGrid) ? CSUtil.cfSort(this.props.pFormGrid, 'sequence_') : [],
            mFormItems              : this.props.pFormItems,
            mFormCriteria           : this.props.pFormCriteria,
            mFormProperties         : this.props.pFormProperties,
            mFormValidation         : this.props.pFormValidation,
            mFormSelection          : this.props.pFormSelection,
            mFormDetails            : this.props.pFormDetails,
            mFormActionProperties   : this.props.pFormActionProperties,
            mFormCriteriaProperties : this.props.pFormCriteriaProperties,
            mFormGridProperties     : this.props.pFormGridProperties,
            mFormGridItemProperties : this.props.pFormGridItemProperties,
            mFormItemProperties     : this.props.pFormItemProperties,
            mFormItemComputation    : this.props.pFormItemComputation,
            mFormData               : (this.props.pFormData !== null) ? this.props.pFormData : {},
            mSelectedCriteria       : null,
            mSelectedRecord         : null,
            mCriteria               : null,
            mActionItem             : null,
            mEnableFilter           : true,
            mAddFlag                : false,
            mDetailsFlag            : false,
            mFormFlag               : 0,
            mSelectedData           : null,
            mDetailsMetaData        : null,
            mSelectedActionItem     : null,
            mDetailsForm1           : null,
            mReportList             : this.props.pReportList            
        }

        this.onReportChange = this.onReportChange.bind(this);
    }
    
    componentDidMount() 
    {
        super.cfInitializeLayout();
    }

    componentWillUnmount() 
    {
        this.props = null;
    }

    onReportChange(e) 
    {
        this.setState({selectedReport: e.value});
    }

    render() 
    {
        const lProps = 
        {
            pAppId              : (this.state.selectedReport) ? (this.state.selectedReport.app_id_) : null,
            pReportHeading      : (this.state.selectedReport) ? (this.state.selectedReport.heading_) : null,
            pLayoutCode         : (this.state.selectedReport) ? (this.state.selectedReport.layout_code_) : null,
            pReportName         : (this.state.selectedReport) ? (this.state.selectedReport.report_name_) : null,
            pSelectedReport     : this.state.selectedReport,
            pIsDashboardReport  : true
        }

        let lLayout = null;
        if(CSUtil.mDashboard)
        {
            const lReportList = 
                <>
                    <Dropdown 
                        value       = { this.state.selectedReport }
                        options     = { this.state.mReportList }
                        onChange    = { this.onReportChange }
                        placeholder = "Select a Report"
                        optionLabel = "report_name_"
                        style       = {{ width: '300px' }}/>
                </>

            lLayout =
                <Panel header = {lReportList} toggleable className="div-2">
                    <div style={{height:(CSUtil.cfGetInstance()).mApplicationHeight - 256, marginLeft: -8, marginRight: -8, marginTop: -8}}>
                        <CSCOBasicAnalysisLayout {...lProps}/>
                    </div>
                </Panel>
        }

        return lLayout;
    }
}

export default CSCODashboardReport;
