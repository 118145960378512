import React, { useEffect, useState } from "react"

const CSCOPrintReportTemplate = (lProps) =>
{
    const [mColumns, setColumns]= useState(null);
//    const [mHeader, setHeader]  = useState(lProps.pHeader);
//    const [mFooter, setFooter]  = useState(lProps.pFooter);
    const [mData, setData]      = useState(null);

    useEffect(() =>
        {
            const fnInitialize = () =>
            {
                if(lProps.pColumns && (lProps.pColumns.length > 0))
                {
                    var lColumns = [];
                    for(var lcInd=0; lcInd<lProps.pColumns.length; lcInd++)
                    {
                        lColumns.push(<th key={lcInd + 1}>{lProps.pColumns[lcInd].name_}</th>);
                    }

                    setColumns(lColumns);
                }

                if(lProps.pDataCollection && (lProps.pDataCollection.length > 0) && lProps.pColumns)
                {
                    var lDataCollection = [];
                    for(var ldInd=0; ldInd<lProps.pDataCollection.length; ldInd++)
                    {
                        var lDataObj = lProps.pDataCollection[ldInd];
                        var lColDataList = null; 
                        for(var lrcInd=0; lrcInd<lProps.pColumns.length; lrcInd++)
                        {
                            if(!lColDataList)
                            {
                                lColDataList = [];
                            }

                            lColDataList.push(<td key={lrcInd + 1}>{lDataObj[lProps.pColumns[lrcInd].data_field_]}</td>);
                        }

                        lDataCollection.push(<tr key={ldInd + 1}>{lColDataList}</tr>);
                    }

                    setData(lDataCollection);
                }
            }

            fnInitialize();
        }, [lProps.pDataCollection, lProps.pColumns, lProps.pReportName]
    );

    var lTable = null;
    if(mColumns)
    {
        lTable = 
            <div id="prt" ref={lProps.pReference}>
                <h3 className="report-title">
                    { lProps.pReportName }
                </h3>
                <table className="report-table">
                    <thead>
                        <tr>{mColumns}</tr>
                    </thead>
                    
                    <tbody>
                        {mData}
                    </tbody>
                </table>
            </div>
    }

    return lTable;
}

export default CSCOPrintReportTemplate;
