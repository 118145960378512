import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import './VNLayout.css';
import ReactToPrint from 'react-to-print';
import { Button } from 'primereact/button';
import CSUtil from '../util/CSUtil';
import CSServiceUtil from '../util/CSServiceUtil';
import { fnGetCurrentDate } from '../util/CSDateTimeUtil';
import CSRequestUtil from '../util/CSRequestUtil';

const mServiceUtil = CSServiceUtil.cfGetInstance();

function VNLayout(lProps) {
    const [mAvailableMap, setAvailableMap] = useState(
        {
            VNF001: 'default', VNF041: 'default', VNF081: 'default', VNE121: 'default', VND161: 'default', VND201: 'default',
            VNF002: 'default', VNF042: 'default', VNF082: 'default', VNE122: 'default', VND162: 'default', VND202: 'default',
            VNF003: 'default', VNF043: 'default', VNF083: 'default', VNE123: 'default', VND163: 'default', VND203: 'default',
            VNF004: 'default', VNF044: 'default', VNF084: 'default', VNE124: 'default', VND164: 'default', VND204: 'default',
            VNF005: 'default', VNF045: 'default', VNF085: 'default', VNE125: 'default', VND165: 'default', VND205: 'default',
            VNF006: 'default', VNF046: 'default', VNF086: 'default', VNE126: 'default', VND166: 'default', VND206: 'default',
            VNF007: 'default', VNF047: 'default', VNF087: 'default', VNE127: 'default', VND167: 'default', VNC207: 'default',
            VNF008: 'default', VNF048: 'default', VNF088: 'default', VNE128: 'default', VND168: 'default', VNC208: 'default',
            VNF009: 'default', VNF049: 'default', VNF089: 'default', VNE129: 'default', VND169: 'default', VNC209: 'default',
            VNF010: 'default', VNF050: 'default', VNF090: 'default', VNE130: 'default', VND170: 'default', VNC210: 'default',
            VNF011: 'default', VNF051: 'default', VNF091: 'default', VNE131: 'default', VND171: 'default', VNC211: 'default',
            VNF012: 'default', VNF052: 'default', VNF092: 'default', VNE132: 'default', VND172: 'default', VNC212: 'default',
            VNF013: 'default', VNF053: 'default', VNF093: 'default', VNE133: 'default', VND173: 'default', VNC213: 'default',
            VNF014: 'default', VNF054: 'default', VNF094: 'default', VNE134: 'default', VND174: 'default', VNC214: 'default',
            VNF015: 'default', VNF055: 'default', VNF095: 'default', VNE135: 'default', VND175: 'default', VNC215: 'default',
            VNF016: 'default', VNF056: 'default', VNF096: 'default', VNE136: 'default', VND176: 'default', VNC216: 'default',
            VNF017: 'default', VNF057: 'default', VNF097: 'default', VNE137: 'default', VND177: 'default', VNC217: 'default',
            VNF018: 'default', VNF058: 'default', VNF098: 'default', VNE138: 'default', VND178: 'default', VNC218: 'default',
            VNF019: 'default', VNF059: 'default', VNE099: 'default', VNE139: 'default', VND179: 'default', VNC219: 'default',
            VNF020: 'default', VNF060: 'default', VNE100: 'default', VNE140: 'default', VND180: 'default', VNC220: 'default',
            VNF021: 'default', VNF061: 'default', VNE101: 'default', VNE141: 'default', VND181: 'default', VNC221: 'default',
            VNF022: 'default', VNF062: 'default', VNE102: 'default', VNE142: 'default', VND182: 'default', VNC222: 'default',
            VNF023: 'default', VNF063: 'default', VNE103: 'default', VNE143: 'default', VND183: 'default', VNC223: 'default',
            VNF024: 'default', VNF064: 'default', VNE104: 'default', VNE144: 'default', VND184: 'default', VNC224: 'default',
            VNF025: 'default', VNF065: 'default', VNE105: 'default', VNE145: 'default', VND185: 'default', VNC225: 'default',
            VNF026: 'default', VNF066: 'default', VNE106: 'default', VNE146: 'default', VND186: 'default', VNC226: 'default',
            VNF027: 'default', VNF067: 'default', VNE107: 'default', VNE147: 'default', VND187: 'default', VNC227: 'default',
            VNF028: 'default', VNF068: 'default', VNE108: 'default', VNE148: 'default', VND188: 'default', VNC228: 'default',
            VNF029: 'default', VNF069: 'default', VNE109: 'default', VNE149: 'default', VND189: 'default', VNC229: 'default',
            VNF030: 'default', VNF070: 'default', VNE110: 'default', VNE150: 'default', VND190: 'default', VNC230: 'default',
            VNF031: 'default', VNF071: 'default', VNE111: 'default', VNE151: 'default', VND191: 'default', VNC231: 'default',
            VNF032: 'default', VNF072: 'default', VNE112: 'default', VNE152: 'default', VND192: 'default',
            VNF033: 'default', VNF073: 'default', VNE113: 'default', VNE153: 'default', VND193: 'default',
            VNF034: 'default', VNF074: 'default', VNE114: 'default', VNE154: 'default', VND194: 'default',
            VNF035: 'default', VNF075: 'default', VNE115: 'default', VNE155: 'default', VND195: 'default',
            VNF036: 'default', VNF076: 'default', VNE116: 'default', VNE156: 'default', VND196: 'default',
            VNF037: 'default', VNF077: 'default', VNE117: 'default', VNE157: 'default', VND197: 'default',
            VNF038: 'default', VNF078: 'default', VNE118: 'default', VNE158: 'default', VND198: 'default',
            VNF039: 'default', VNF079: 'default', VNE119: 'default', VND159: 'default', VND199: 'default',
            VNF040: 'default', VNF080: 'default', VNE120: 'default', VND160: 'default', VND200: 'default'
        }, []
    );

    const mStatusMap = {
        'BOOKED'    : 'booked',
        'MORTGAGE'  : 'mortgage',
        'AVAILABLE' : 'available',
        'SALE'      : 'available',
        'SOLD_OUT'  : 'soldout'
    };

    const [mDisplay, setDisplay] = useState(lProps.pShow);
    const mComponentRef = useRef(null);

    useEffect(() => {
            const fnInitializeForm = () => {
                setDisplay(true);
                if (lProps && lProps.pFormProperties) {
                    let lServiceCode = null;

                    for (let lInd = 0; lInd < lProps.pFormProperties.length; lInd++) {
                        let lPropObj = lProps.pFormProperties[lInd];
                        if (lPropObj.property_ === 'details_service_code_') {
                            lServiceCode = lPropObj.value_;
                        }
                    }
                    
                    if (lServiceCode) {
                        let lCriteria = null;
                        let lServiceObject = mServiceUtil.cfGetServiceObject(lServiceCode);
                        if (lServiceObject) {
                            let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                            lRequestUtil.cfSetRequestCriteria(lCriteria);
                            lRequestUtil.cfProcessRequest((lResponse) => {
                                    if (lResponse && lResponse.hasOwnProperty('collection_') && (lResponse.collection_.length > 0)) {
                                        let lAvailableMap = {};
                                        for (let lInd = 0; lInd < lResponse.collection_.length; lInd++) {
                                            let lUnitObj = lResponse.collection_[lInd];
                                            if (mAvailableMap[lUnitObj.unit_code_]) {
                                                lAvailableMap[lUnitObj.unit_code_] = mStatusMap[lUnitObj.status_];
                                            }
                                        }

                                        setAvailableMap(lAvailableMap);
                                    }
                                }
                            );
                        }                
                    }
                }
            }

            fnInitializeForm();
        }, []
    );

    let lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key="1"
            trigger={() =>
                <Button
                   
                    type="Button"
                    icon={CSUtil.mNameIconMap['PI_PRINT']}
                />
            }
            content={() => mComponentRef.current}
        />
    );

    lHeaderActions.push(
        <Button
            key="2"
            
            type="Button"
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick={() => lProps.fnProcessClose()}
        />
    );

    const lHeader =
        <div className='flex justify-content-between '>
            <div >
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div >
                {lHeaderActions}
            </div>
        </div>

    return (
        <Dialog
            visible={mDisplay}
            modal={true}
            appendTo={document.body}
            closable={false}
            header={lHeader}
        >
            <div className=" bg_new" ref={mComponentRef}>
                <div className="first">
                    <div className="plots"></div>
                    <div className="plots">
                        <div className="new B11C2">
                            <div className={`box F1 ${mAvailableMap.VNF001}`}>F<br />01</div>
                            <div className={`box F2 ${mAvailableMap.VNF002}`}>F<br />02</div>
                            <div className={`box F3 ${mAvailableMap.VNF003}`}>F<br />03</div>
                            <div className={`box F4 ${mAvailableMap.VNF004}`}>F<br />04</div>
                            <div className={`box F5 ${mAvailableMap.VNF005}`}>F<br />05</div>
                            <div className={`box F6 ${mAvailableMap.VNF006}`}>F<br />06</div>
                            <div className={`box F7 ${mAvailableMap.VNF007}`}>F<br />07</div>
                            <div className={`box F8 ${mAvailableMap.VNF008}`}>F<br />08</div>
                            <div className={`box F9 ${mAvailableMap.VNF009}`}>F<br />09</div>
                            <div className={`box F10 ${mAvailableMap.VNF010}`}>F<br />10</div>
                            <div className={`box F11 ${mAvailableMap.VNF011}`}>F<br />11</div>
                        </div>
                    </div>

                    <div className="plots"></div>
                </div>

                <div className="first">
                    <div className="plots"></div>

                    <div className="plots">
                        <div className="new B12C2">
                            <div className={`box F22 ${mAvailableMap.VNF022}`}>F<br />22</div>
                            <div className={`box F21 ${mAvailableMap.VNF021}`}>F<br />21</div>
                            <div className={`box F20 ${mAvailableMap.VNF020}`}>F<br />20</div>
                            <div className={`box F19 ${mAvailableMap.VNF019}`}>F<br />19</div>
                            <div className={`box F18 ${mAvailableMap.VNF018}`}>F<br />18</div>
                            <div className={`box F17 ${mAvailableMap.VNF017}`}>F<br />17</div>
                            <div className={`box F16 ${mAvailableMap.VNF016}`}>F<br />16</div>
                            <div className={`box F15 ${mAvailableMap.VNF015}`}>F<br />15</div>
                            <div className={`box F14 ${mAvailableMap.VNF014}`}>F<br />14</div>
                            <div className={`box F13 ${mAvailableMap.VNF013}`}>F<br />13</div>
                            <div className={`box F12 ${mAvailableMap.VNF012}`}>F<br />12</div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B21C1">
                            <div className={`box F23 ${mAvailableMap.VNF023}`}>F<br />23</div>
                            <div className={`box F24 ${mAvailableMap.VNF024}`}>F<br />24</div>
                            <div className={`box F25 ${mAvailableMap.VNF025}`}>F<br />25</div>
                            <div className={`box F26 ${mAvailableMap.VNF026}`}>F<br />26</div>
                            <div className={`box F27 ${mAvailableMap.VNF027}`}>F<br />27</div>
                            <div className={`box F28 ${mAvailableMap.VNF028}`}>F<br />28</div>
                            <div className={`box F29 ${mAvailableMap.VNF029}`}>F<br />29</div>
                            <div className={`box F30 ${mAvailableMap.VNF030}`}>F<br />30</div>
                        </div>
                    </div>

                    <div className=" new B21C2">
                        <div className={`box F31 ${mAvailableMap.VNF031}`}>F<br />31</div>
                        <div className={`box F32 ${mAvailableMap.VNF032}`}>F<br />32</div>
                        <div className={`box F33 ${mAvailableMap.VNF033}`}>F<br />33</div>
                        <div className={`box F34 ${mAvailableMap.VNF034}`}>F<br />34</div>
                        <div className={`box F35 ${mAvailableMap.VNF035}`}>F<br />35</div>
                        <div className={`box F36 ${mAvailableMap.VNF036}`}>F<br />36</div>
                        <div className={`box F37 ${mAvailableMap.VNF037}`}>F<br />37</div>
                        <div className={`box F38 ${mAvailableMap.VNF038}`}>F<br />38</div>
                        <div className={`box F39 ${mAvailableMap.VNF039}`}>F<br />39</div>
                        <div className={`box F40 ${mAvailableMap.VNF040}`}>F<br />40</div>
                        <div className={`box F41 ${mAvailableMap.VNF041}`}>F<br />41</div>
                    </div>

                    <div className="plots"></div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B22C1">
                            <div className={`box F60 ${mAvailableMap.VNF060}`}>F<br />60</div>
                            <div className={`box F59 ${mAvailableMap.VNF059}`}>F<br />59</div>
                            <div className={`box F58 ${mAvailableMap.VNF058}`}>F<br />58</div>
                            <div className={`box F57 ${mAvailableMap.VNF057}`}>F<br />57</div>
                            <div className={`box F56 ${mAvailableMap.VNF056}`}>F<br />56</div>
                            <div className={`box F55 ${mAvailableMap.VNF055}`}>F<br />55</div>
                            <div className={`box F54 ${mAvailableMap.VNF054}`}>F<br />54</div>
                            <div className={`box F53 ${mAvailableMap.VNF053}`}>F<br />53</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B22C2">
                            <div className={`box F52 ${mAvailableMap.VNF052}`}>F<br />52</div>
                            <div className={`box F51 ${mAvailableMap.VNF051}`}>F<br />51</div>
                            <div className={`box F50 ${mAvailableMap.VNF050}`}>F<br />50</div>
                            <div className={`box F49 ${mAvailableMap.VNF049}`}>F<br />49</div>
                            <div className={`box F48 ${mAvailableMap.VNF048}`}>F<br />48</div>
                            <div className={`box F47 ${mAvailableMap.VNF047}`}>F<br />47</div>
                            <div className={`box F46 ${mAvailableMap.VNF046}`}>F<br />46</div>
                            <div className={`box F45 ${mAvailableMap.VNF045}`}>F<br />45</div>
                            <div className={`box F44 ${mAvailableMap.VNF044}`}>F<br />44</div>
                            <div className={`box F43 ${mAvailableMap.VNF043}`}>F<br />43</div>
                            <div className={`box F42 ${mAvailableMap.VNF042}`}>F<br />42</div>
                        </div>
                    </div>

                    <div className="plots"></div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B31C1">
                            <div className={`box F61 ${mAvailableMap.VNF061}`}>F<br />61</div>
                            <div className={`box F62 ${mAvailableMap.VNF062}`}>F<br />62</div>
                            <div className={`box F63 ${mAvailableMap.VNF063}`}>F<br />63</div>
                            <div className={`box F64 ${mAvailableMap.VNF064}`}>F<br />64</div>
                            <div className={`box F65 ${mAvailableMap.VNF065}`}>F<br />65</div>
                            <div className={`box F66 ${mAvailableMap.VNF066}`}>F<br />66</div>
                            <div className={`box F67 ${mAvailableMap.VNF067}`}>F<br />67</div>
                            <div className={`box F68 ${mAvailableMap.VNF068}`}>F<br />68</div>
                        </div>
                    </div>

                    <div className="plots ">
                        <div className="new B31C2">
                            <div className={`box F69 ${mAvailableMap.VNF069}`}>F<br />69</div>
                            <div className={`box F70 ${mAvailableMap.VNF070}`}>F<br />70</div>
                            <div className={`box F71 ${mAvailableMap.VNF071}`}>F<br />71</div>
                            <div className={`box F72 ${mAvailableMap.VNF072}`}>F<br />72</div>
                            <div className={`box F73 ${mAvailableMap.VNF073}`}>F<br />73</div>
                            <div className={`box F74 ${mAvailableMap.VNF074}`}>F<br />74</div>
                            <div className={`box F75 ${mAvailableMap.VNF075}`}>F<br />75</div>
                            <div className={`box F76 ${mAvailableMap.VNF076}`}>F<br />76</div>
                            <div className={`box F77 ${mAvailableMap.VNF077}`}>F<br />77</div>
                            <div className={`box F78 ${mAvailableMap.VNF078}`}>F<br />78</div>
                            <div className={`box F79 ${mAvailableMap.VNF079}`}>F<br />79</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B31C3">
                            <div className='legend available'><p>Available</p></div>
                            <div className='legend booked'><p>Booked</p></div>
                            <div className='legend mortgage'><p>Mortgaged</p></div>
                            <div className='legend soldout'><p>Soldout</p></div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B32C1">
                            <div className={`box F98 ${mAvailableMap.VNF098}`}>F<br />98</div>
                            <div className={`box F97 ${mAvailableMap.VNF097}`}>F<br />97</div>
                            <div className={`box F95 ${mAvailableMap.VNF096}`}>F<br />96</div>
                            <div className={`box F95 ${mAvailableMap.VNF095}`}>F<br />95</div>
                            <div className={`box F94 ${mAvailableMap.VNF094}`}>F<br />94</div>
                            <div className={`box F93 ${mAvailableMap.VNF093}`}>F<br />93</div>
                            <div className={`box F92 ${mAvailableMap.VNF092}`}>F<br />92</div>
                            <div className={`box F91 ${mAvailableMap.VNF091}`}>F<br />91</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B32C2">
                            <div className={`box F90 ${mAvailableMap.VNF090}`}>F<br />90</div>
                            <div className={`box F89 ${mAvailableMap.VNF089}`}>F<br />89</div>
                            <div className={`box F88 ${mAvailableMap.VNF088}`}>F<br />88</div>
                            <div className={`box F87 ${mAvailableMap.VNF087}`}>F<br />87</div>
                            <div className={`box F86 ${mAvailableMap.VNF086}`}>F<br />86</div>
                            <div className={`box F85 ${mAvailableMap.VNF085}`}>F<br />85</div>
                            <div className={`box F84 ${mAvailableMap.VNF084}`}>F<br />84</div>
                            <div className={`box F83 ${mAvailableMap.VNF083}`}>F<br />83</div>
                            <div className={`box F82 ${mAvailableMap.VNF082}`}>F<br />82</div>
                            <div className={`box F81 ${mAvailableMap.VNF081}`}>F<br />81</div>
                            <div className={`box F80 ${mAvailableMap.VNF080}`}>F<br />80</div>
                        </div>
                    </div>

                    <div className="plots"></div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B41C1">
                            <div className={`box b4 E99 ${mAvailableMap.VNE099}`}>E<br />99</div>
                            <div className={`box b4 E100 ${mAvailableMap.VNE100}`}>E<br />100</div>
                            <div className={`box b4 E101 ${mAvailableMap.VNE101}`}>E<br />101</div>
                            <div className={`box b4 E102 ${mAvailableMap.VNE102}`}>E<br />102</div>
                            <div className={`box b4 E103 ${mAvailableMap.VNE103}`}>E<br />103</div>
                        </div>
                    </div>

                    <div className="plots ">
                        <div className="new B41C2">
                            <div className={`box b4 E104 ${mAvailableMap.VNE104}`}>E<br />104</div>
                            <div className={`box b4 E105 ${mAvailableMap.VNE105}`}>E<br />105</div>
                            <div className={`box b4 E105 ${mAvailableMap.VNE106}`}>E<br />106</div>
                            <div className={`box E107 ${mAvailableMap.VNE107}`}>E<br />107</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B41C3">
                            <div className={`box E108 ${mAvailableMap.VNE108}`}>E<br />108 </div>
                            <div className={`box E109 ${mAvailableMap.VNE109}`}>E<br />109</div>
                            <div className={`box E110 ${mAvailableMap.VNE110}`}>E<br />110</div>
                            <div className={`box E111 ${mAvailableMap.VNE111}`}>E<br />111</div>
                            <div className={`box E112 ${mAvailableMap.VNE112}`}>E<br />112</div>
                            <div className={`box E113 ${mAvailableMap.VNE113}`}>E<br />113</div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B42C1">
                            <div className={`box b4 E124 ${mAvailableMap.VNE128}`} x>E<br />128</div>
                            <div className={`box b4 E125 ${mAvailableMap.VNE127}`}>E<br />127</div>
                            <div className={`box b4 E126 ${mAvailableMap.VNE126}`}>E<br />126</div>
                            <div className={`box b4 E127 ${mAvailableMap.VNE125}`}>E<br />125</div>
                            <div className={`box b4 E124 ${mAvailableMap.VNE124}`}>E<br />124</div>
                        </div>
                    </div>

                    <div className="plots ">
                        <div className="new B42C2">
                            <div className={`box b4 E123 ${mAvailableMap.VNE123}`}>E<br />123</div>
                            <div className={`box b4 E122 ${mAvailableMap.VNE122}`}>E<br />122</div>
                            <div className={`box b4 E121 ${mAvailableMap.VNE121}`}>E<br />121</div>
                            <div className={`box E107 E120 ${mAvailableMap.VNE120}`}>E<br />120</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B42C3">
                            <div className={`box E108 E119 ${mAvailableMap.VNE119}`}>E<br />119 </div>
                            <div className={`box E108 E118 ${mAvailableMap.VNE118}`}>E<br />118</div>
                            <div className={`box E108 E117 ${mAvailableMap.VNE117}`}>E<br />117</div>
                            <div className={`box E108 E116 ${mAvailableMap.VNE116}`}>E<br />116</div>
                            <div className={`box E108 E115 ${mAvailableMap.VNE115}`}>E<br />115</div>
                            <div className={`box E113 E114 ${mAvailableMap.VNE114}`}>E<br />114</div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B51C1">
                            <div className={`box b4 E129 ${mAvailableMap.VNE129}`}>E<br />129</div>
                            <div className={`box b4 E130 ${mAvailableMap.VNE130}`}>E<br />130</div>
                            <div className={`box b4 E131 ${mAvailableMap.VNE131}`}>E<br />131</div>
                            <div className={`box b4 E132 ${mAvailableMap.VNE132}`}>E<br />132</div>
                            <div className={`box b4 E133 ${mAvailableMap.VNE133}`}>E<br />133</div>
                        </div>
                    </div>

                    <div className="plots ">
                        <div className="new B51C2">
                            <div className={`box b4 ${mAvailableMap.VNE134}`}>E<br />134</div>
                            <div className={`box b4 ${mAvailableMap.VNE135}`}>E<br />135</div>
                            <div className={`box b4 ${mAvailableMap.VNE136}`}>E<br />136</div>
                            <div className={`box E107 ${mAvailableMap.VNE137}`}>E<br />137</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B51C3">
                            <div className={`box E108 ${mAvailableMap.VNE138}`}>E<br />138 </div>
                            <div className={`box E109 ${mAvailableMap.VNE139}`}>E<br />139</div>
                            <div className={`box E110 ${mAvailableMap.VNE140}`}>E<br />140</div>
                            <div className={`box E111 ${mAvailableMap.VNE141}`}>E<br />141</div>
                            <div className={`box E112 ${mAvailableMap.VNE142}`}>E<br />142</div>
                            <div className={`box E113 ${mAvailableMap.VNE143}`}>E<br />143</div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B52C1">
                            <div className={`box b4 ${mAvailableMap.VNE158}`}>E<br />158</div>
                            <div className={`box b4 ${mAvailableMap.VNE157}`}>E<br />157</div>
                            <div className={`box b4 ${mAvailableMap.VNE156}`}>E<br />156</div>
                            <div className={`box b4 ${mAvailableMap.VNE155}`}>E<br />155</div>
                            <div className={`box b4 ${mAvailableMap.VNE154}`}>E<br />154</div>
                        </div>
                    </div>

                    <div className="plots ">
                        <div className="new B52C2">
                            <div className={`box b4 ${mAvailableMap.VNE153}`}>E<br />153 </div>
                            <div className={`box b4 ${mAvailableMap.VNE152}`}>E<br />152</div>
                            <div className={`box b4 ${mAvailableMap.VNE151}`}>E<br />151</div>
                            <div className={`box E107 ${mAvailableMap.VNE150}`}>E<br />150</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B52C3">
                            <div className={`box E108 ${mAvailableMap.VNE149}`}>E<br />149 </div>
                            <div className={`box E109 ${mAvailableMap.VNE148}`}>E<br />148</div>
                            <div className={`box E110 ${mAvailableMap.VNE147}`}>E<br />147</div>
                            <div className={`box E111 ${mAvailableMap.VNE146}`}>E<br />146</div>
                            <div className={`box E112 ${mAvailableMap.VNE145}`}>E<br />145</div>
                            <div className={`box E113 ${mAvailableMap.VNE144}`}>E<br />144</div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B61C1">
                            <div className={`box dblock ${mAvailableMap.VND159}`}>D<br />159</div>
                            <div className={`box dblock ${mAvailableMap.VND160}`}>D<br />160</div>
                            <div className={`box dblock ${mAvailableMap.VND161}`}>D<br />161</div>
                            <div className={`box dblock ${mAvailableMap.VND162}`}>D<br />162</div>
                            <div className={`box dblock ${mAvailableMap.VND163}`}>D<br />163</div>
                            <div className={`box dblock ${mAvailableMap.VND164}`}>D<br />164</div>
                            <div className={`box dblock ${mAvailableMap.VND165}`}>D<br />165</div>
                            <div className={`box dblock ${mAvailableMap.VND166}`}>D<br />166</div>
                            <div className={`box dblock ${mAvailableMap.VND167}`}>D<br />167</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B61C2">
                            <div className={`box dblock ${mAvailableMap.VND168}`}>D<br />168</div>
                            <div className={`box dblock ${mAvailableMap.VND169}`}>D<br />169</div>
                            <div className={`box dblock ${mAvailableMap.VND170}`}>D<br />170</div>
                            <div className={`box dblock ${mAvailableMap.VND171}`}>D<br />171</div>
                            <div className={`box dblock ${mAvailableMap.VND172}`}>D<br />172</div>
                            <div className={`box dblock ${mAvailableMap.VND173}`}>D<br />173</div>
                            <div className={`box dblock ${mAvailableMap.VND174}`}>D<br />174</div>
                            <div className={`box dblock ${mAvailableMap.VND175}`}>D<br />175</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B61C3">
                            <div className={`box d1block ${mAvailableMap.VND176}`}>D<br />176</div>
                            <div className={`box d1block ${mAvailableMap.VND177}`}>D<br />177</div>
                            <div className={`box d1block ${mAvailableMap.VND178}`}>D<br />178</div>
                            <div className={`box d1block ${mAvailableMap.VND179}`}>D<br />179</div>
                            <div className={`box d1block ${mAvailableMap.VND180}`}>D<br />180</div>
                            <div className={`box d1block ${mAvailableMap.VND181}`}>D<br />181</div>
                            <div className={`box d1block ${mAvailableMap.VND182}`}>D<br />182</div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B62C1">
                            <div className={`box dblock ${mAvailableMap.VND206}`}>D<br />206</div>
                            <div className={`box dblock ${mAvailableMap.VND205}`}>D<br />205</div>
                            <div className={`box dblock ${mAvailableMap.VND204}`}>D<br />204</div>
                            <div className={`box dblock ${mAvailableMap.VND203}`}>D<br />203</div>
                            <div className={`box dblock ${mAvailableMap.VND202}`}>D<br />202</div>
                            <div className={`box dblock ${mAvailableMap.VND201}`}>D<br />201</div>
                            <div className={`box dblock ${mAvailableMap.VND200}`}>D<br />200</div>
                            <div className={`box dblock ${mAvailableMap.VND199}`}>D<br />199</div>
                            <div className={`box dblock ${mAvailableMap.VND198}`}>D<br />198</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B62C2">
                            <div className={`box dblock ${mAvailableMap.VND197}`}>D<br />197</div>
                            <div className={`box dblock ${mAvailableMap.VND196}`}>D<br />196</div>
                            <div className={`box dblock ${mAvailableMap.VND195}`}>D<br />195</div>
                            <div className={`box dblock ${mAvailableMap.VND194}`}>D<br />194</div>
                            <div className={`box dblock ${mAvailableMap.VND193}`}>D<br />193</div>
                            <div className={`box dblock ${mAvailableMap.VND192}`}>D<br />192</div>
                            <div className={`box dblock ${mAvailableMap.VND191}`}>D<br />191</div>
                            <div className={`box dblock ${mAvailableMap.VND190}`}>D<br />190</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B62C3">
                            <div className={`box d1block ${mAvailableMap.VND189}`}>D<br />189</div>
                            <div className={`box d1block ${mAvailableMap.VND188}`}>D<br />188</div>
                            <div className={`box d1block ${mAvailableMap.VND187}`}>D<br />187</div>
                            <div className={`box d1block ${mAvailableMap.VND186}`}>D<br />186</div>
                            <div className={`box d1block ${mAvailableMap.VND185}`}>D<br />185</div>
                            <div className={`box d1block ${mAvailableMap.VND184}`}>D<br />184</div>
                            <div className={`box d1block ${mAvailableMap.VND183}`}>D<br />183</div>
                        </div>
                    </div>
                </div>

                <div className="first">
                    <div className="plots">
                        <div className="new B71C1">
                            <div className={`box F208 ${mAvailableMap.VNC207}`}>C<br />207</div>
                            <div className={`box F208 ${mAvailableMap.VNC208}`}>C<br />208</div>
                            <div className={`box F208 ${mAvailableMap.VNC209}`}>C<br />209</div>
                            <div className={`box F208 ${mAvailableMap.VNC210}`}>C<br />210</div>
                            <div className={`box F208 ${mAvailableMap.VNC211}`}>C<br />211</div>
                            <div className={`box F208 ${mAvailableMap.VNC212}`}>C<br />212</div>
                            <div className={`box F208 ${mAvailableMap.VNC213}`}>C<br />213</div>
                            <div className={`box F208 ${mAvailableMap.VNC214}`}>C<br />214</div>
                            <div className={`box F208 ${mAvailableMap.VNC215}`}>C<br />215</div>
                            <div className={`box F208 ${mAvailableMap.VNC2}`}></div>
                            <div className={`box F208 ${mAvailableMap.VNC216}`}>C<br />216</div>
                            <div className={`box F208 ${mAvailableMap.VNC217}`}>C<br />217</div>
                            <div className={`box F208 ${mAvailableMap.VNC218}`}>C<br />218</div>
                            <div className={`box F208 ${mAvailableMap.VNC219}`}>C<br />219</div>
                            <div className={`box F208 ${mAvailableMap.VNC220}`}>C<br />220</div>
                            <div className={`box F208 ${mAvailableMap.VNC221}`}>C<br />221</div>
                            <div className={`box F208 ${mAvailableMap.VNC222}`}>C<br />222</div>
                            <div className={`box F208 ${mAvailableMap.VNC223}`}>C<br />223</div>
                            <div className={`box F208 ${mAvailableMap.VNC224}`}>C<br />224</div>
                        </div>
                    </div>

                    <div className="plots">
                        <div className="new B71C3">
                            <div className={`box F225 ${mAvailableMap.VNC225}`}>D<br />225</div>
                            <div className={`box F226 ${mAvailableMap.VNC226}`}>D<br />226</div>
                            <div className={`box F226 ${mAvailableMap.VNC227}`}>D<br />227</div>
                            <div className={`box F226 ${mAvailableMap.VNC228}`}>D<br />228</div>
                            <div className={`box F226 ${mAvailableMap.VNC229}`}>D<br />229</div>
                            <div className={`box F226 ${mAvailableMap.VNC230}`}>D<br />230</div>
                            <div className={`box F226 ${mAvailableMap.VNC231}`}>D<br />231</div>
                        </div>
                    </div>
                </div>
                <h5 className="availability-report-name">Availabilty List as on {fnGetCurrentDate('display')}</h5>
            </div>
        </Dialog>
    )
}

export default VNLayout