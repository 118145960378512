import React from "react"
import {MultiSelect} from 'primereact/multiselect';
import CSServiceUtil from '../util/CSServiceUtil'
import CSBaseControl from './CSBaseControl'

class CSMultiselect extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.mServiceUtil       = CSServiceUtil.cfGetInstance();
        this.cfGetControl       = this.cfGetControl.bind(this);
        this.cfHandleChange     = this.cfHandleChange.bind(this);
        this.cfHandleResponse   = this.cfHandleResponse.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);
        this.cfTemplate         = this.cfTemplate.bind(this);
        this.cfOnChange         = this.cfOnChange.bind(this);
    }

    componentDidMount()
    {
        if(this.props.data_provider_source_)
        {
            this.mServiceUtil.cfGetDataFromDataProvider (this.props.data_provider_source_, this.cfHandleResponse);
        }
        else
        {
            if(this.props.mValue)
            {
                if(this.props.mValue instanceof Array)
                {
                    this.setState({mValue : this.props.mValue});   
                }
                else
                {
                    var lList = this.props.mValue.split(",");
                    if(lList && (lList.length > 0) && this.state.mDataProvider && this.state.mDataProviderIdObjectMap)
                    {
                        var lSelection = []
                        for(var lInd=0; lInd<lList.length; lInd++)
                        {
                            if(this.state.mDataProviderIdObjectMap[lList[lInd]])
                            {
                                lSelection.push(this.state.mDataProviderIdObjectMap[lList[lInd]])
                            }
                        }

                        this.cfOnChange(lSelection);
                    }
                }
            }
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.mValue)
            {
                var lSelection = []

                if(this.props.mValue instanceof Array)
                {
                    this.setState({mValue : this.props.mValue});   
                }
                else
                {
                    var lList = this.props.mValue.split(",");
                    if(lList && (lList.length > 0) && this.state.mDataProvider && (this.state.mDataProvider.length > 0) && this.state.mDataProviderIdObjectMap)
                    {
                        for(var lInd=0; lInd<lList.length; lInd++)
                        {
                            if(this.state.mDataProviderIdObjectMap[lList[lInd]])
                            {
                                lSelection.push(this.state.mDataProviderIdObjectMap[lList[lInd]])
                            }
                        }

                        if(lSelection.length > 0)
                        {
                            this.cfOnChange(lSelection);
                        }
                    }
                }
            }

            if(this.props.data_provider_source_)
            {
                this.mServiceUtil.cfGetDataFromDataProvider (this.props.data_provider_source_, 
                    (lDataProviderId, lCollection) =>
                    {
                        if(this.props && (lDataProviderId === this.props.data_provider_source_))
                        {
                            let lDataProvider = null;
                            let lValue = this.props.mValue;
                            let lDependentValObj = null;
                            let lDependencyFlag = false;
                
                            if(this.props.dependent_field_)
                            {
                                if(lCollection && this.props.dependent_value_)
                                {
                                    for(var ldpInd=0; ldpInd<lCollection.length; ldpInd++)
                                    {
                                        var ldpObj = lCollection[ldpInd];
                                        var lDependentField = this.props.dependent_field_;
                                        if(lDependentField)
                                        {
                                            if(ldpObj[lDependentField] && (ldpObj[lDependentField] === this.props.dependent_value_))
                                            {
                                                if(!lDataProvider)
                                                {
                                                    lDataProvider = [];
                                                }
                
                                                lDataProvider.push(ldpObj);
                                            }
                                        }
                                    }
                                }
                            }
                            else if(lCollection && lDependentValObj)
                            {
                                for(var ldpInd1=0; ldpInd1<lCollection.length; ldpInd1++)
                                {
                                    var ldpObj1 = lCollection[ldpInd1];
                                    var lKeys = Object.keys(lDependentValObj);
                                    var lIncludeFlag = true;
                                    for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                                    {
                                        var lKey = lKeys[lKeyInd];
                                        if(ldpObj1.hasOwnProperty(lKey))
                                        {
                                            if(ldpObj1[lKey] !== lDependentValObj[lKey])
                                            {
                                                lIncludeFlag = false;
                                                break;
                                            }
                                        }
                                    }
                                    
                                    if(lIncludeFlag)
                                    {
                                        if(!lDataProvider)
                                        {
                                            lDataProvider = [];
                                        }
                
                                        lDataProvider.push(ldpObj1);
                                    }
                                }                
                            }
                            else
                            {
                                if(!lDependencyFlag)
                                {
                                    lDataProvider = lCollection;
                                    if(this.props.hasOwnProperty('pFormItem') && this.props.pFormItem && this.props.pFormItem.constant_ && !this.props.pValue)
                                    {
                                        lValue = this.props.pFormItem.constant_;
                                    }
                                }
                            }

                            var lDPIdObjMap = {}
                            let lDataCollection = null;
                            if(lDataProvider)
                            {
                                lDataCollection = lDataProvider.map((lItem) =>  
                                    {
                                        lDPIdObjMap[lItem[this.state.mDataProviderId]] = lItem;
                                        lItem.label = lItem[this.state.mDataProviderDisplayField];
                                        return lItem;
                                    }
                                );

                                this.setState(
                                    {
                                        mDataProvider : lDataCollection,
                                        mDataProviderIdObjectMap : lDPIdObjMap
                                    }
                                );
                            }                    
                        }                    
                    }
                );
            }
        }
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    cfHandleResponse(lDataProviderId, lCollection)
    {
        if(this.props && (lDataProviderId === this.props.data_provider_source_))
        {
            let lDataProvider = null;
            let lValue = this.props.mValue;
            let lDependentValObj = null;
            let lDependencyFlag = false;

            if(this.props.dependent_field_)
            {
                if(lCollection && this.props.dependent_value_)
                {
                    for(var ldpInd=0; ldpInd<lCollection.length; ldpInd++)
                    {
                        var ldpObj = lCollection[ldpInd];
                        var lDependentField = this.props.dependent_field_;
                        if(lDependentField)
                        {
                            if(ldpObj[lDependentField] && (ldpObj[lDependentField] === this.props.dependent_value_))
                            {
                                if(!lDataProvider)
                                {
                                    lDataProvider = [];
                                }

                                lDataProvider.push(ldpObj);
                            }
                        }
                    }
                }
            }
            else if(lCollection && lDependentValObj)
            {
                for(var ldpInd1=0; ldpInd1<lCollection.length; ldpInd1++)
                {
                    var ldpObj1 = lCollection[ldpInd1];
                    var lKeys = Object.keys(lDependentValObj);
                    var lIncludeFlag = true;
                    for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                    {
                        var lKey = lKeys[lKeyInd];
                        if(ldpObj1.hasOwnProperty(lKey))
                        {
                            if(ldpObj1[lKey] !== lDependentValObj[lKey])
                            {
                                lIncludeFlag = false;
                                break;
                            }
                        }
                    }
                    
                    if(lIncludeFlag)
                    {
                        if(!lDataProvider)
                        {
                            lDataProvider = [];
                        }

                        lDataProvider.push(ldpObj1);
                    }
                }                
            }
            else
            {
                if(!lDependencyFlag)
                {
                    lDataProvider = lCollection;
                    if(this.props.hasOwnProperty('pFormItem') && this.props.pFormItem && this.props.pFormItem.constant_ && !this.props.pValue)
                    {
                        lValue = this.props.pFormItem.constant_;
                    }
                }
            }

            var lDPIdObjMap = {}
            var lDataCollection = null;
            if(lDataProvider)
            {
                lDataCollection = lDataProvider.map((lItem) =>
                    {
                        lDPIdObjMap[lItem[this.state.mDataProviderId]] = lItem;
                        lItem.label = lItem[this.state.mDataProviderDisplayField];
                        return lItem;
                    }
                );
            }

            var lSelection = [];
            if(this.props.pData && this.props.pSelectionKey && lDataCollection)
            {
                const lKeys = this.props.pData.split(",");
                if(lKeys)
                {
                    for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                    {
                        for(var ldInd=0; ldInd<lDataCollection.length; ldInd++)
                        {
                            var lDataObj = lDataCollection[ldInd];
                            if(lDataObj.hasOwnProperty(this.props.pSelectionKey) && (lDataObj[this.props.pSelectionKey] === lKeys[lKeyInd]))
                            {
                                lSelection.push(lDataObj);
                            }
                        }
                    }
                }
            }
            else if(this.props.mValue && lDataCollection && lDPIdObjMap)
            {
                if(this.props.mValue instanceof Array)
                {
                    this.setState({mValue : this.props.mValue});   
                }
                else
                {
                    var lList = this.props.mValue.split(",");
                    if(lList && (lList.length > 0) && lDataCollection && (lDataCollection.length > 0) && lDPIdObjMap)
                    {
                        for(var lInd=0; lInd<lList.length; lInd++)
                        {
                            if(lDPIdObjMap[lList[lInd]])
                            {
                                lSelection.push(lDPIdObjMap[lList[lInd]])
                            }
                        }
                    }
                }
            }

            this.setState(
                {
                    mDataProvider : lDataCollection,
                    mDataProviderIdObjectMap : lDPIdObjMap,
                    mValue : lSelection
                }
            );

//            var lData = {};
//            lData.control_type_ = 'multi_select_combo_box';
//            lData.data_field_   = this.state.mDataField;
//            lData.id_           = this.state.mId;
//            lData.value_        = lSelection;
            this.cfOnChange(lSelection);
        }
    }

    cfTemplate(lOption)
    {
        var lRetValue = null;
        if(lOption && lOption.label)
        {
            lRetValue = 
                <div className="p-clearfix">
                    <span style={{fontSize:'12px', float:'right', marginTop:'4px'}}>{lOption.label}</span>
                </div>
        }
        
        return lRetValue;
    }

    cfSetControlValue(lCollection, lDataProviderId, lValue)
    {
        var lRetValue = null;
        if(lCollection && lValue)
        {
            if(lDataProviderId)
            {
                for(var ind=0; ind<lCollection.length; ind++)
                {
                    var lData = lCollection[ind];
                    if(lData && (lData[lDataProviderId] === lValue))
                    {
                        lRetValue = lData;
                        break;
                    }
                }
            }
            else
            {
                lRetValue = lValue;
            }
        }

        return lRetValue;
    }

    cfHandleChange(event)
    {
        event.preventDefault();
        if(event.value)
        {
            this.cfOnChange(event.value);
        }
    }

    cfOnChange(lSelectedData)
    {
        if(this.state.mHandleChange !== undefined)
        {
            var lData = {};
            lData.control_type_ = 'multi_select_combo_box';
            lData.data_field_   = this.state.mDataField;
            lData.id_           = this.state.mId;
            if(lSelectedData)
            {
                if(lData.data_field_ && lSelectedData.hasOwnProperty(this.state.mDataProviderId))
                {
                    lData.selected_data_ = lSelectedData;
                    lData.value_ = lSelectedData[this.state.mDataProviderId];
                }
                else
                {
                    lData.value_ = lSelectedData;
                }
            }

            this.state.mHandleChange(lData);
        }
    }

    cfGetControl()
    {
        var ctl = null;
        if(this.state.mControlType && (this.state.mControlType === 'multi_select_combo_box'))
        {
            let lValue = null;
            if(this.state.mValue instanceof Array)
            {
                lValue = this.state.mValue;
            }

            ctl = (
                <div className="cs-control field grid">
                    { super.cfGetControlLabel() }

                    <MultiSelect
                        className   = "cs-multi-select"
                        id          = {this.state.mId}
                        key         = {this.state.mId}
                        display     = "chip"
                        filter      = {true}
                        name        = {this.state.mDataField}
                        appendTo    = {document.body}
                        onChange    = {this.cfHandleChange}
                        options     = {this.state.mDataProvider}
                        value       = {lValue}
                        optionLabel = {this.state.mDataProviderDisplayField}
                        autoResize  = {true}/>
                </div>
            )
        }

        return ctl;
    }

    render()
    {
        var lControl = null;

        try
        {
            lControl = this.cfGetControl();
        }
        catch(error)
        {
            console.log(error)
        }

        return lControl;
    }
}

export default CSMultiselect;
