import React from "react"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil";
import { fnGetForm } from "../../util/CSLayoutUtil"
import { fnGetFormHeader } from "../../util/CSLayoutUtil"
import { cfGetConstantValue } from "../../util/CSConstantUtil";

import CSCOGrid from "../../components/CSCOGrid"
import CSCOBaseFormLayout from "./CSCOBaseFormLayout";
import CSCOBackdrop from "../../components/CSCOBackdrop";
import CSCOCriteria from "../../components/CSCOCriteria"
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar"
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

const cfGetDimensions = (lId) =>
{
    var lHeight;
    if(lId)
    {
        var lElement = document.getElementById(lId);
        if(lElement)
        {
            var lRect = lElement.getBoundingClientRect();
            if(lRect)
            {
                lHeight = lRect.height;
            }
        }
    }

    return lHeight;
}

class CSCOFormLayout21 extends CSCOBaseFormLayout
{
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.process_save = this.process_save.bind(this);        
        this.cfGetActionItemState = this.cfGetActionItemState.bind(this);        
        this.cfAddToCollection    = this.cfAddToCollection.bind(this);
        this.cfUpdateCollection   = this.cfUpdateCollection.bind(this);
        this.cfGetRequestObject   = this.cfGetRequestObject.bind(this);
        this.cfGetRequestCriteria = this.cfGetRequestCriteria.bind(this);
    }

    componentDidMount()
    {
        super.cfInitializeLayout();
        if(this.props.pDataCollection)
        {
            this.cfSetFormData(this.props.pDataCollection);
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(lPrevProps.pAppId !== this.props.pAppId)
            {
                super.cfInitializeLayout();
                if(this.state.mDataCollection)
                {
                    this.state.mDataCollection.splice(0, this.state.mDataCollection.length);
                }
            }
            else if(this.state.mDataCollection)
            {
                this.cfSetFormData(this.state.mDataCollection);
            }
            else if(this.props.pDataCollection)
            {
                this.cfSetFormData(this.props.pDataCollection);
            }
        }
    }

    cfProcessData(lDataCollection)
    {
        if(lDataCollection && (lDataCollection.length > 0))
        {
            var lSelectedData = null;
            var lImportType = 'REPLACE';
            var lKeyField = null;
            var lExcelField = null;
            if(this.state.mFormData)
            {
                if(this.state.mFormData.hasOwnProperty('selected_data_'))
                {
                    lSelectedData = this.state.mFormData.selected_data_;
                }
                else
                {
                    lSelectedData = this.state.mFormData;
                }
            }

            if(this.state.mFormProperties)
            {
                for(var lInd=0; lInd<this.state.mFormProperties.length; lInd++)
                {
                    var lFpObj = this.state.mFormProperties[lInd];
                    if(lFpObj.type_ === 'save_data')
                    {
                        if((lFpObj.property_ === 'form_data_') && lFpObj.value_ && lSelectedData)
                        {
                            for(var lDInd=0; lDInd<lDataCollection.length; lDInd++)
                            {
                                var lDObj = lDataCollection[lDInd];
                                if(lSelectedData.hasOwnProperty(lFpObj.value_))
                                {
                                    lDObj[lFpObj.value_] = lSelectedData[lFpObj.value_];
                                }
                            }            
                        }
                    }
                                                
                    if(lFpObj.type_ === 'import_data')
                    {
                        if((lFpObj.property_ === 'data_') && lFpObj.value_)
                        {
                            lImportType = lFpObj.value_;
                        }

                        if((lFpObj.property_ === 'key_field_') && lFpObj.value_)
                        {
                            lKeyField = lFpObj.value_;
                        }

                        if((lFpObj.property_ === 'excel_field_') && lFpObj.value_)
                        {
                            lExcelField = lFpObj.value_;
                        }
                    }
                }
            }

            if(lImportType === 'REPLACE')
            {
                this.setState(
                    {
                        mDataCollection : lDataCollection, 
                        mSelectedRecord : lDataCollection,
                        mImportFlag     : false,
                        mDetailsFlag    : false
                    }
                );
            }
            else if(lImportType === 'APPEND')
            {
                var lCol1 = this.state.mDataCollection;
                if(!lCol1)
                {
                    lCol1 = [];
                }

                lCol1.push.apply(lCol1, lDataCollection);
                this.setState({mDataCollection : lCol1, mSelectedRecord : lCol1});
            }
            else if(lImportType === 'COLLATE')
            {
                var lCol2 = this.state.mDataCollection;
                if(!lCol2)
                {
                    lCol2 = [];
                }

                if(lKeyField && lExcelField)
                {
                    var lKeyFieldObjMap = {}
                    for(var lDInd0=0; lDInd0<lCol2.length; lDInd0++)
                    {
                        var lDObj0 = lCol2[lDInd0];
                        if(lDObj0.hasOwnProperty(lKeyField))
                        {
                            lKeyFieldObjMap[lDObj0[lKeyField]] = lDObj0;
                        }
                    }

                    var lMissingData = []
                    for(var lDInd1=0; lDInd1<lDataCollection.length; lDInd1++)
                    {
                        var lDObj1 = lDataCollection[lDInd1];
                        if(lDObj1.hasOwnProperty(lExcelField))
                        {
                            var lExcelKeyData = lDObj1[lExcelField];
                            var lMapObj = lKeyFieldObjMap[lExcelKeyData];
                            if(lMapObj)
                            {
                                var lExcelKeys = Object.keys(lDObj1);
                                for(var lkInd=0; lkInd<lExcelKeys.length; lkInd++)
                                {
                                    var lKey = lExcelKeys[lkInd];
                                    lMapObj[lKey] = lDObj1[lKey];
                                }
                            }
                            else
                            {
                                lMissingData.push(lDObj1);
                            }
                        }
                    }

                    if(lMissingData.length > 0)
                    {
                        lCol2.push.apply(lCol2, lMissingData);
                    }
                }
                else
                {
                    lCol2.push.apply(lCol2, lDataCollection);
                }

                for(var lcdInd=0; lcdInd<lCol2.length; lcdInd++)
                {
                    var lRowData = CSFormUtil.cfGetGridComputeFieldsData(lCol2[lcdInd], this.state.mFormGrid, this.state.mFormGridItemProperties);
                    lCol2[lcdInd] = lRowData;
                }
                
                this.setState({mDataCollection : lCol2, mSelectedRecord : lCol2});
            }
        }
    }
    
    cfAddToCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            var lCollection = this.state.mDataCollection;
            if(!lCollection)
            {
                lCollection = [];
            }

            lCollection.push(lRecord);
            if((lCollection.length > 0) && this.state.mFormProperties)
            {
                for(var lInd=0; lInd<this.state.mFormProperties.length; lInd++)
                {
                    var lFpObj = this.state.mFormProperties[lInd];
                    if(lFpObj.type_ === 'save_data')
                    {
                        if((lFpObj.property_ === 'form_data_') && lFpObj.value_ && this.state.mFormData)
                        {
                            for(var lDInd=0; lDInd<lCollection.length; lDInd++)
                            {
                                var lDObj = lCollection[lDInd];
                                if(this.state.mFormData.hasOwnProperty(lFpObj.value_))
                                {
                                    lDObj[lFpObj.value_] = this.state.mFormData[lFpObj.value_];
                                }
                            }            
                        }
                    }
                }
            }

            this.setState(
                {
                    mDataCollection : lCollection
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }
    
    cfUpdateCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            this.setState(
                {
                    mSelectedRecord : lRecord
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }

    process_save(lEvent, lParent, lActionItem)
    {
        this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
            {
                if(lResult && lResult.value)
                {
                    var lRequestData = this.cfGetRequestObject();
                    var lCriteria = this.cfGetRequestCriteria();
                    this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                        (lResponse) =>
                        {
                            var lCode = null;
                            var lMsg = null;
                            var lCloseFlag = (lActionItem.new_state_ === "list")

                            if(lResponse && lResponse.code)
                            {
                                if(lResponse.code instanceof Array)
                                {
                                    lCode = lResponse.code[0];
                                    if(lResponse.message)
                                    {
                                        lMsg = lResponse.message[0];
                                    }
                                }
                                else
                                {
                                    lCode = lResponse.code;
                                    if(lResponse.message)
                                    {
                                        lMsg = lResponse.message;
                                    }
                                }
                            }
                            else
                            {
                                lCode = -1;
                            }

                            this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                            if((lCode === 1) || (lCode === '1'))
                            {
                                this.cfCloseForm(!lCloseFlag, false);
                            }
                        }, lCriteria
                    );
                }            
            }
        );
    }

    cfGetRequestCriteria()
    {
        var lCriteria;
        var lFormGridProperties = this.state.mFormGridProperties;
        if(lFormGridProperties)
        {
            for(var lfipInd=0; lfipInd<lFormGridProperties.length; lfipInd++)
            {
                var lfipObj = lFormGridProperties[lfipInd];
                if(lfipObj.property_ === 'save_criteria')
                {
                    if(lfipObj.type_ && (lfipObj.type_ !== '#CONSTANT#'))
                    {
                        if(this.state.mFormData.hasOwnProperty(lfipObj.type_))
                        {
                            var lDataObj = this.state.mFormData[lfipObj.type_];

                            if(!lCriteria)
                            {
                                lCriteria = {};
                            }
        
                            lCriteria[lfipObj.name_] = lDataObj[lfipObj.value_];
                        }                        
                    }
                    else
                    {
                        if(!lCriteria)
                        {
                            lCriteria = {}; 
                        }
    
                        lCriteria[lfipObj.name_] = cfGetConstantValue(lfipObj.value_);
                    }
                }
            }
         }

         return lCriteria;
    }

    cfGetRequestObject()
    {
        var lRetObj;
        var lCollection = null;
        if(this.state.mSelectedRecord && (this.state.mSelectedRecord instanceof Array))
        {
            lCollection = this.state.mSelectedRecord;
        }
        else
        {
            lCollection = this.state.mDataCollection;   
        }
        
        if(lCollection)
        {
            for(var lfgInd=0; lfgInd<this.props.pFormGrid.length; lfgInd++)
            {
                var lfgObj = this.props.pFormGrid[lfgInd];
                if(lfgObj.constant_)
                {
                    var lConstantVal = cfGetConstantValue(lfgObj.constant_);
                    for(var lInd=0; lInd<lCollection.length; lInd++)
                    {
                        var lDataObj = lCollection[lInd];
                        lDataObj[lfgObj.data_field_] = lConstantVal;
                    }
                }
            }

            var lFormGridProperties = this.state.mFormGridProperties;
            if(lFormGridProperties)
            {
                for(var lfipInd=0; lfipInd<lFormGridProperties.length; lfipInd++)
                {
                    var lfipObj = lFormGridProperties[lfipInd];
                    if(lfipObj.property_ === 'save_data')
                    {
                        if(lfipObj.type_=== 'collection')
                        {
                            if((lfipObj.name_ === 'data_object_') && lfipObj.value_)
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = {}
                                }

                                lRetObj[lfipObj.value_] = lCollection;
                            }
                        }
                    }
                }
            }

            if(!lRetObj)
            {
                lRetObj = lCollection;
            }
        }

        return lRetObj;
    }

    cfGetActionItemState(lActionItem)
    {
        var lEnable = true;
        if(lActionItem.hasOwnProperty('cs_form_action_properties_') && lActionItem.cs_form_action_properties_)
        {
            var lfaProperties = lActionItem.cs_form_action_properties_;
            for(var lInd=0; lInd<lfaProperties.length; lInd++)
            {
                var lformProp = lfaProperties[lInd];
                if(lformProp.type_ === 'form_initialize')
                {
                    if(lformProp.property_ === 'enabled')
                    {
                        if(lformProp.selection_value_ === '#NOT_NULL#')   
                        {
                            if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                            {
                                var lDataObj = this.state.mDataCollection[0];
                                if(lDataObj.hasOwnProperty(lformProp.selection_property_))
                                {
                                    if(lformProp.value_ === 'false')
                                    {
                                        lEnable = false;
                                    }
                                    else
                                    {
                                        lEnable = true;
                                    }
                                    }
                            }
                        }
                        else if(lformProp.selection_value_ === '#NULL#')   
                        {
                            if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                            {
                                var lDataObj1 = this.state.mDataCollection[0];
                                if(!lDataObj1.hasOwnProperty(lformProp.selection_property_))
                                {
                                    if(lformProp.value_ === 'false')
                                    {
                                        lEnable = false;
                                    }
                                    else
                                    {
                                        lEnable = true;
                                    }
                                }
                            }
                            else
                            {
                                if(lformProp.value_ === 'false')
                                {
                                    lEnable = false;
                                }
                                else
                                {
                                    lEnable = true;
                                }
                            }
                        }
                    }
                }

                if(!lEnable)
                {
                    break;
                }
            }
        }

        return lEnable;
    }

    render()
    {
        var lCriteria = null;
        var lSearchActions = null;
        if(this.state.mFormCriteria && (this.state.mFormCriteria.length > 0))
        {
            lCriteria = 
                <div id="ID_CRITERIA" ref={cfGetDimensions}>
                    <CSCOCriteria 
                        pCriteria       = {this.state.mFormCriteria}
                        pFormActions    = {this.state.mFormActions}
                        pFormProperties = {this.state.mFormProperties}
                        pParent         = {this}
                    />
                </div>
        }

        if(this.state.mSearchActionList && (this.state.mSearchActionList.length > 0))
        {
            var lSearchActionList = this.state.mSearchActionList.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        if(this.cfGetActionItemState(lActionItem))
                        {
                            lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                        }
                        else
                        {
                            lActionItem.mEnabled = false;
                        }
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lSearchActions = 
                <CSCOBaseActionBar 
                    align       = "left"
                    pType       = 'SEARCH_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lSearchActionList}
                />;
        }

        var lFormActions = null;
        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            var lFormActionList = [];
            for(var lFInd=0; lFInd<this.state.mFormActions.length; lFInd++)
            {
                var lActionItem = this.state.mFormActions[lFInd];
                if (((lActionItem.component_type_ === 'SAVE_MULTIPLE_ACTION') || (lActionItem.component_type_ === 'SEARCH_ACTION')) && (!lActionItem.parent_ref_id_ || (lActionItem.parent_ref_id_ === lActionItem.id_)))
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        var lData = this.state.mFormData;
                        if(lData && lData.hasOwnProperty('selected_data_'))
                        {
                            lData = lData.selected_data_;
                        }

                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, lData);

                        if(lActionItem.component_type_ !== 'SEARCH_ACTION')
                        {
                            var lEnableDetailsSaveAction = false;
                            if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                            {
                                lEnableDetailsSaveAction = true;
                                lActionItem.mEnabled = true;
                            }

                            if(!lEnableDetailsSaveAction)
                            {
                                lActionItem.mEnabled = false;
                            }
                        }
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    if(lActionItem.component_type_ === 'SAVE_MULTIPLE_ACTION')
                    {
                        lFormActionList.push(lActionItem);
                    }
                }
            }

            if(lFormActionList)
            {
                lFormActions = 
                    <CSCOHorizontalActionBar 
                        align               = "left"
                        pType               = 'SAVE_ACTION_BAR'
                        parent              = {this}
                        actionList          = {lFormActionList}
                        pPadding            = '0px'
                        pMargin             = '0px'
                        pMarginBottom       = '0px'
                        pBackgroundColor    = '#FFFFFF' />;
            }
        }

        var lDetailsForm = null;
        if(this.state.mDetailsFlag)
        {
            var lDetailsData = null;
            if(this.state.mImportFlag || !this.state.mAddFlag)
            {
                lDetailsData = this.state.mSelectedData;
            }

            lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.cfCloseForm);
        }

        var lHeadingHeight = cfGetDimensions("ID_HEADING");
        var lCriteriaHeight = cfGetDimensions("ID_CRITERIA");
        var lScrollHeight = (CSUtil.cfGetInstance()).mApplicationHeight - 80;
    
        if(lHeadingHeight)
        {
            lScrollHeight = lScrollHeight - lHeadingHeight;
        }

        if(lCriteriaHeight)
        {
            lScrollHeight = lScrollHeight - lCriteriaHeight;
        }

        return( 
            <>
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>
                
                <div id="ID_HEADING">
                    { fnGetFormHeader(this.state.mFormLayoutHeading) }
                </div>

                { lCriteria }
 
                <CSCOGrid 
                    pData               = { this.state.mDataCollection }
                    pFeatureRefId       = { this.state.mFeatureRefId }
                    pColumns            = { this.state.mFormGrid }
                    pHeaderColumns      = { this.state.mHeaderColumns }
                    rowIndex            = { this.state.mRowIndex } 
                    pageSize            = { 15 }
                    onClick             = { this.cfOnDataSelection }
                    pSearchActionBar    = { lSearchActions }
                    pEnableFilter       = { this.state.mEnableFilter }
                    pScrollHeight       = { lScrollHeight }
                    pFooter             = { lFormActions }
                    pEnableScroll       = { true}
                    pClearSelection     = { this.state.mClearSelection }
                    pSelectedData       = { this.state.mSelectedRecord }
                    />

                { lDetailsForm }
            </>
            );
    }
}

export default CSCOFormLayout21;
