import CSRequestUtil from "./CSRequestUtil"
import CSFormData from "../common/CSFormData"

const gAppIdMetadataMap = {};
const gActionIdObjMap   = {};

function fnGetFormMetaData(lAppObj, lCallBackFunction)
{
    if(lAppObj && lCallBackFunction)
    {
/*        
        var lMetaData = gAppIdMetadataMap[lAppObj.app_id_];
        if(!lMetaData)
        {
*/          const lRequestUtil = new CSRequestUtil(
                'GET', 
                'CS_REQ_GET_CS_SERVICE_DETAILS', 
                'cs_service', 
                'CS_REQ_GET_CS_SERVICE_DETAILS');
    
            lAppObj.call_back_function_ = lCallBackFunction;
            lRequestUtil.cfSetRequestCriteria(lAppObj);
            lRequestUtil.cfProcessRequest((lData, lAppObj) =>
                {
                    if(lData)
                    {
                        let lFormData = new CSFormData();
                        lFormData.cfSetAppData(lAppObj);
                        lFormData.cfSetFormData(lData, gActionIdObjMap);

                        lCallBackFunction(lFormData, lAppObj);
                        gAppIdMetadataMap[lAppObj.app_id_] = lFormData;
                    }
                }
            );
/*        }
        else
        {
            lCallBackFunction(lMetaData, lAppObj);
        } */
    }
}

export { fnGetFormMetaData };