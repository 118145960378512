import CSUtil from "../util/CSUtil";

class CSFormData
{
    constructor()
    {
        this.mCSUtil    = CSUtil.cfGetInstance();

        this.pAppCode                       = '';
        this.pAppId                         = '';
        this.pFeatureRefId                  = '';
        this.pLayoutCode                    = '';
        this.pLayoutType                    = '';
        
        this.pServices                      = [];
        this.pFormCriteria                  = [];
        this.pFormGrid                      = [];
        this.pFormItems                     = [];
        this.pFormActions                   = [];
        this.pFormDetails                   = null;
        this.pFormProperties                = null;
        this.pFormValidation                = null;
        this.pFormSelection                 = null;
        this.pFormActionProperties          = null;
        this.pFormCriteriaProperties        = null;
        this.pFormGridProperties            = null;
        this.pFormGridItemProperties        = null;
        this.pGroupFields                   = null;
        this.pGroupFieldGridItemObjectMap   = null;
        this.pFormItemProperties            = null;
        this.pFormItemComputation           = null;
        this.mActionRefIdFormItemObjectMap  = {};
        this.mFormItemRefIdObjectMap        = {};
        this.mControlIdFormItemObjectMap    = {};
        this.pFormMetaData                  = this;
    }

    cfSetAppData(lAppObj)
    {
        if(lAppObj)
        {
            this.pAppId         = lAppObj.app_id_;
            this.pAppCode       = lAppObj.app_code_;
            this.pFeatureRefId  = lAppObj.feature_ref_id_;
            this.pLayoutCode    = lAppObj.layout_code_;
            this.pLayoutType    = lAppObj.layout_type_;
        }
    }

    cfSetFormData(lData, lActionIdObjectMap)
    {
        if(lData)
        {
            if(lData.hasOwnProperty('cs_service_'))
            {
                if(lData.cs_service_ instanceof Array)
                {
                    this.pServices = lData.cs_service_;
                }
                else
                {
                    this.pServices.push(lData.cs_service_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_criteria_'))
            {
                if(lData.cs_form_criteria_ instanceof Array)
                {
                    this.pFormCriteria = lData.cs_form_criteria_;
                }
                else
                {
                    this.pFormCriteria.push(lData.cs_form_criteria_);
                }
            }
                        
            if(lData.hasOwnProperty('cs_form_items_'))
            {
                if(lData.cs_form_items_ instanceof Array)
                {
                    this.pFormItems = lData.cs_form_items_;
                }
                else
                {
                    this.pFormItems.push(lData.cs_form_items_);
                }
            }
                        
            if(lData.hasOwnProperty('cs_form_details_'))
            {
                if(lData.cs_form_details_ instanceof Array)
                {
                    this.pFormDetails = lData.cs_form_details_;
                }
                else
                {
                    this.pFormDetails = [];
                    this.pFormDetails.push(lData.cs_form_details_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_actions_'))
            {
                if(lData.cs_form_actions_ instanceof Array)
                {
                    this.pFormActions = lData.cs_form_actions_;
                }
                else
                {
                    this.pFormActions.push(lData.cs_form_actions_);
                }

                if(this.pFormActions)
                {
                    for(var lInd=0; lInd<this.pFormActions.length; lInd++)
                    {
                        var lFormAction = this.pFormActions[lInd];
                        lFormAction.component_type_ = this.mCSUtil.cfGetComponentType(lFormAction.component_code_);
                        if(lFormAction.navigate_to_form_component_code_)
                        {
                            lFormAction.navigate_to_form_component_type_ = this.mCSUtil.cfGetComponentType(lFormAction.navigate_to_form_component_code_);
                        }
                    }
                }
            }
            
            if(lData.hasOwnProperty('cs_form_properties_'))
            {
                if(lData.cs_form_properties_ instanceof Array)
                {
                    this.pFormProperties = lData.cs_form_properties_;
                }
                else
                {
                    this.pFormProperties = [];
                    this.pFormProperties.push(lData.cs_form_properties_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_validation_'))
            {
                if(lData.cs_form_validation_ instanceof Array)
                {
                    this.pFormValidation = lData.cs_form_validation_;
                }
                else
                {
                    this.pFormValidation = [];
                    this.pFormValidation.push(lData.cs_form_validation_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_selection_'))
            {
                if(lData.cs_form_selection_ instanceof Array)
                {
                    this.pFormSelection = lData.cs_form_selection_;
                }
                else
                {
                    this.pFormSelection = [];
                    this.pFormSelection.push(lData.cs_form_selection_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_action_properties_'))
            {
                if(lData.cs_form_action_properties_ instanceof Array)
                {
                    this.pFormActionProperties = lData.cs_form_action_properties_;
                }
                else
                {
                    this.pFormActionProperties = [];
                    this.pFormActionProperties.push(lData.cs_form_action_properties_);
                }
            }

            for(let lFaInd=0; lFaInd<this.pFormActions.length; lFaInd++)
            {
                const lFormActionObj = this.pFormActions[lFaInd];
                if(lFormActionObj && lFormActionObj.id_)
                {
                    lActionIdObjectMap[lFormActionObj.id_] = lFormActionObj;
                    if(this.pFormActionProperties)
                    {
                        for(let lFapInd=0; lFapInd<this.pFormActionProperties.length; lFapInd++)
                        {
                            const lFapObj = this.pFormActionProperties[lFapInd];
                            if(lFormActionObj.id_ === lFapObj.cs_form_action_ref_id_)
                            {
                                let lFapCol = lFormActionObj.cs_form_action_properties_;
                                if(!lFapCol)
                                {
                                    lFapCol = [];
                                    lFormActionObj.cs_form_action_properties_ = lFapCol;
                                }
                                
                                lFapCol.push(lFapObj);
                            }
                        }
                    }

                    if(this.pFormValidation)
                    {
                        for(let lFvInd=0; lFvInd<this.pFormValidation.length; lFvInd++)
                        {
                            const lFvObj = this.pFormValidation[lFvInd];
                            let lFvCol = null;
                            if(lFvObj.action_ && (!lFvObj.cs_form_action_ref_id_) && (!lFvObj.cs_form_item_ref_id_))
                            {
                                lFvCol = lFormActionObj.cs_form_validation_;
                                if(!lFvCol)
                                {
                                    lFvCol = [];
                                    lFormActionObj.cs_form_validation_ = lFvCol;
                                }
                                
                                lFvCol.push(lFvObj);
                            }
                            else if(lFormActionObj.id_ === lFvObj.cs_form_action_ref_id_)
                            {
                                lFvCol = lFormActionObj.cs_form_validation_;
                                if(!lFvCol)
                                {
                                    lFvCol = [];
                                    lFormActionObj.cs_form_validation_ = lFvCol;
                                }
                                
                                lFvCol.push(lFvObj);
                            }
                        }
                    }
                }
            }

            if(this.pFormCriteria && lData.hasOwnProperty('cs_form_criteria_properties_'))
            {
                if(lData.cs_form_criteria_properties_ instanceof Array)
                {
                    this.pFormCriteriaProperties = lData.cs_form_criteria_properties_;
                }
                else
                {
                    this.pFormCriteriaProperties = [];
                    this.pFormCriteriaProperties.push(lData.cs_form_criteria_properties_);
                }

                if(this.pFormCriteriaProperties.length > 0)
                {
                    for(let lFcInd=0; lFcInd<this.pFormCriteria.length; lFcInd++)
                    {
                        const lFormCriteriaObj = this.pFormCriteria[lFcInd];
                        for(let lFcpInd=0; lFcpInd<this.pFormCriteriaProperties.length; lFcpInd++)
                        {
                            const lFcpObj = this.pFormCriteriaProperties[lFcpInd];
                            if(lFormCriteriaObj.id_ === lFcpObj.cs_form_criteria_ref_id_)
                            {
                                let lFcpCol = lFormCriteriaObj.cs_form_criteria_properties_;
                                if(!lFcpCol)
                                {
                                    lFcpCol = [];
                                    lFormCriteriaObj.cs_form_criteria_properties_ = lFcpCol;
                                }
                                
                                lFcpCol.push(lFcpObj);
                            }
                        }
                    }
                }
            }
            
            
            if(lData.hasOwnProperty('cs_form_grid_'))
            {
                if(lData.cs_form_grid_ instanceof Array)
                {
                    this.pFormGrid = lData.cs_form_grid_;
                }
                else
                {
                    this.pFormGrid.push(lData.cs_form_grid_);
                }

                if(lData.hasOwnProperty('cs_form_grid_properties_'))
                {
                    if(lData.cs_form_grid_properties_ instanceof Array)
                    {
                        this.pFormGridProperties = lData.cs_form_grid_properties_;
                    }
                    else
                    {
                        this.pFormGridProperties = [];
                        this.pFormGridProperties.push(lData.cs_form_grid_properties_);
                    }
                }
                
                if(lData.hasOwnProperty('cs_form_grid_item_properties_'))
                {
                    if(lData.cs_form_grid_item_properties_ instanceof Array)
                    {
                        this.pFormGridItemProperties = lData.cs_form_grid_item_properties_;
                    }
                    else
                    {
                        this.pFormGridItemProperties = [];
                        this.pFormGridItemProperties.push(lData.cs_form_grid_item_properties_);
                    }
                }
        
                if(this.pFormGrid && (this.pFormGrid.length > 0))
                {
                    var lFormGridObjectMap = {};
                    var lGroupFieldGridItemObjectMap = {};
                    var lGroupFields = [];
                    for(var lFgiInd=0; lFgiInd<this.pFormGrid.length; lFgiInd++)
                    {
                        var lGridObject = this.pFormGrid[lFgiInd];
                        lFormGridObjectMap[lGridObject.id_] = lGridObject;
        
                        if(lGridObject.grouping_field_ === '1')
                        {
                            lGroupFields.push(lGridObject.data_field_);
                            lGroupFieldGridItemObjectMap[lGridObject.data_field_] = lGridObject;
                        }
                    }
        
                    if(this.pFormGridItemProperties && (this.pFormGridItemProperties.length > 0))
                    {
                        for(var lFgipInd=0; lFgipInd<this.pFormGridItemProperties.length; lFgipInd++)
                        {
                            const lGridItemPropObject = this.pFormGridItemProperties[lFgipInd];
                            var lGridObject0 = lFormGridObjectMap[lGridItemPropObject.cs_form_grid_ref_id_];
                            if(lGridObject0)
                            {
                                if(!lGridObject0.hasOwnProperty('cs_form_grid_item_properties_'))
                                {
                                    lGridObject0.cs_form_grid_item_properties_ = [];
                                }
        
                                lGridObject0.cs_form_grid_item_properties_.push(lGridItemPropObject);
                            }
                        }
                    }
        
                    if((lGroupFields.length > 0) && (this.pFormGridProperties))
                    {
                        for(var lInd_0=0; lInd_0<this.pFormGridProperties.length; lInd_0++)
                        {
                            var lGridProperties = this.pFormGridProperties[lInd_0];
                            if((lGridProperties.type_ === 'GROUP') && (lGridProperties.name_ === 'key'))
                            {
        //                        lKeyField = lGridProperties.value_;
                            }
                        }
                    }

                    this.pGroupFields = lGroupFields;
                    this.pGroupFieldGridItemObjectMap = lGroupFieldGridItemObjectMap;
                }
            }
            
            if(lData.hasOwnProperty('cs_form_item_properties_'))
            {
                if(lData.cs_form_item_properties_ instanceof Array)
                {
                    this.pFormItemProperties = lData.cs_form_item_properties_;
                }
                else
                {
                    this.pFormItemProperties = [];
                    this.pFormItemProperties.push(lData.cs_form_item_properties_);
                }
            }
            
            if(lData.hasOwnProperty('cs_form_item_computation_'))
            {
                let lComputeList = null;
                if(lData.cs_form_item_computation_ instanceof Array)
                {
                    lComputeList = lData.cs_form_item_computation_;
                }
                else
                {
                    lComputeList = [];
                    lComputeList.push(lData.cs_form_item_computation_);
                }


                CSUtil.cfSort(lComputeList, 'sequence_');
                this.pFormItemComputation = lComputeList;
            }

            for(let lFiInd=0; lFiInd<this.pFormItems.length; lFiInd++)
            {
                const lFormItemObj = this.pFormItems[lFiInd];
                this.mFormItemRefIdObjectMap[lFormItemObj.id_] = lFormItemObj;
                this.mControlIdFormItemObjectMap[lFormItemObj.control_id_] = lFormItemObj;

                let lFormItemList = this.mActionRefIdFormItemObjectMap[lFormItemObj.action_ref_id_];
                if(!lFormItemList)
                {
                    lFormItemList = [];
                    this.mActionRefIdFormItemObjectMap[lFormItemObj.action_ref_id_] = lFormItemList;
                }

                let lContains = false;
                for(let lFiInd_0=0; lFiInd_0<lFormItemList.length; lFiInd_0++)
                {
                    if (lFormItemList[lFiInd_0].id_ === lFormItemObj.id_)
                    {
                        lContains = true;
                        break;
                    }
                }

                if(!lContains)
                {
                    this.mActionRefIdFormItemObjectMap[lFormItemObj.action_ref_id_].push(lFormItemObj);
                }

                if(this.pFormItemProperties)
                {
                    for(let lFipInd=0; lFipInd<this.pFormItemProperties.length; lFipInd++)
                    {
                        const lFipObj = this.pFormItemProperties[lFipInd];
                        if(lFormItemObj.id_ === lFipObj.cs_form_item_ref_id_)
                        {
                            let lFipCol = lFormItemObj.cs_form_item_properties_;
                            if(!lFipCol)
                            {
                                lFipCol = [];
                                lFormItemObj.cs_form_item_properties_ = lFipCol;
                            }
                            
                            lFipCol.push(lFipObj);
                        }
                    }
                }

                if(this.pFormItemComputation)
                {
                    for(let lFicInd=0; lFicInd<this.pFormItemComputation.length; lFicInd++)
                    {
                        const lFicObj = this.pFormItemComputation[lFicInd];
                        if(lFormItemObj.id_ === lFicObj.cs_form_item_ref_id_)
                        {
                            let lFicCol = lFormItemObj.cs_form_item_computation_;
                            if(!lFicCol)
                            {
                                lFicCol = [];
                                lFormItemObj.cs_form_item_computation_ = lFicCol;
                            }
                            
                            lFicCol.push(lFicObj);
                        }
                    }
                }

                if(this.pFormValidation)
                {
                    for(let lFvInd_0=0; lFvInd_0<this.pFormValidation.length; lFvInd_0++)
                    {
                        const lFvObj_0 = this.pFormValidation[lFvInd_0];
                        if(lFvObj_0 && (lFormItemObj.id_ === lFvObj_0.cs_form_item_ref_id_))
                        {
                            let lFvCol_0 = lFormItemObj.cs_form_validation_;
                            if(!lFvCol_0)
                            {
                                lFvCol_0 = [];
                                lFormItemObj.cs_form_validation_ = lFvCol_0;
                            }
                            
                            lFvCol_0.push(lFvObj_0);
                        }
                    }
                }
            }
        }
    }

    cfGetFormData()
    {
    }
}

export default CSFormData;
