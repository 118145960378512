import { useEffect, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';

import GSTIcon from '../../assets/images/GST.svg';
import PFIcon from '../../assets/images/pficon.svg';
import TDSIcon from '../../assets/images/TDSicon.svg';
import IncomeTax from '../../assets/images/IncomeTaxicon.svg';

import CSDropDown from '../../controls/CSDropDown';
import CSConfigUtil from '../../util/CSConfigUtil';
import CSRequestUtil from '../../util/CSRequestUtil';
import CSServiceUtil from '../../util/CSServiceUtil';
import CSCOScroller from './CSCOScroller'
import CSCOBackdrop from "../../components/CSCOBackdrop";

const Compliance = (lProps) => {
    const [mSelectedFYCode, setSelectedFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mSelectedFYObj,  setSelectedFYObj]   = useState(CSConfigUtil.mActiveFinancialYearObj);

    const [mSelectedCompanyCode, setSelectedCompanyCode] = useState(CSConfigUtil.mCompanyCode);
    const [mSelectedCompanyObj, setSelectedCompanyObj]   = useState(CSConfigUtil.mCompanyObj);

    const [mShowBackdrop, setShowBackdrop] = useState(false);

    const [mcomplianceData, setComplianceData] = useState(null);
    const [mRequestId, setRequestId] = useState(null);
    const [mChartIdObjectMap, setChartIdObjectMap] = useState(null);
    const [mComplianceCodeObjectMap, setComplianceCodeObjectMap] = useState(null);

    const mIconMap = {
        'PFIcon' : PFIcon,
        'GSTIcon' : GSTIcon,
        'TDSIcon' : TDSIcon,
        'IncomeTax' : IncomeTax
    };

    const [mComplianceCodeDueDateMap, setComplianceCodeDueDateMap] = useState({
            'PF'    : '',
            'GST'   : '',
            'TDS'   : '',
            'IT'    : ''
        }
    );

    useEffect(() => {
            let lChartIdObjMap = {};
            let lChartRefIdObjMap = {};
            let lComplianceCodeObjectMap = {};

            if(lProps.pReportChartProperties)
            {
                for(let lInd=0; lInd<lProps.pReportChartProperties.length; lInd++)
                {
                    let propertyObj = lProps.pReportChartProperties[lInd];
                    if(propertyObj.property_name_ === 'request_id_')
                    {
                        setRequestId(propertyObj.value_);
                    }
                    else if(propertyObj.property_name_ === 'chart_id_')
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.value_];
                        if(!lcpObj)
                        {
                            lcpObj = {};
                            lChartRefIdObjMap[propertyObj.id_] = lcpObj;
                        }

                        lcpObj[propertyObj.property_name_] = propertyObj.value_;
                        lChartIdObjMap[propertyObj.value_] = lcpObj;
                    }
                    else if(propertyObj.parent_ref_id_)
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.parent_ref_id_];
                        if(lcpObj)
                        {
                            if((propertyObj.property_name_ === 'chart_data_field_') || (propertyObj.property_name_ === 'chart_data_label_') || (propertyObj.property_name_ === 'compliance_code_'))
                            {
                                if(propertyObj.property_name_ === 'chart_data_field_')
                                {
                                    let lChartDataFieldList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataFieldList)
                                    {
                                        lChartDataFieldList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataFieldList;
                                    }

                                    lChartDataFieldList.push(propertyObj.value_);
                                }
                                else if(propertyObj.property_name_ === 'compliance_code_')
                                {
                                    lcpObj[propertyObj.property_name_] = propertyObj.value_;
                                    lComplianceCodeObjectMap[lcpObj[propertyObj.property_name_]] = lcpObj;
                                }
                                else
                                {
                                    let lChartDataLabelList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataLabelList)
                                    {
                                        lChartDataLabelList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataLabelList;
                                    }

                                    lChartDataLabelList.push(propertyObj.value_);
                                }
                            }
                            else
                            {
                                lcpObj[propertyObj.property_name_] = propertyObj.value_; 
                            }
                        }
                    }
                }

                setChartIdObjectMap(lChartIdObjMap);
                setComplianceCodeObjectMap(lComplianceCodeObjectMap);
            }
        }, []
    );

    useEffect(() => {
            fnGetReportData(CSConfigUtil.mCurrentFYCode, CSConfigUtil.mCompanyCode);
        }, [mChartIdObjectMap]
    );

    function fnGetReportData(lFyCode, lCompanyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && (lFyCode || lCompanyCode))
            {
                setShowBackdrop(true);
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;
                lCriteria.company_code_ = lCompanyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lObj = mChartIdObjectMap['ID_PF'];
                            let lDataField = null;
                            if(lObj && lResponse.hasOwnProperty(lObj.data_field_)) {
                                lDataField = lObj.data_field_;
                            }

                            if(lDataField) {
                                let lComplianceDataList = []
                                let lDataList = lResponse[lDataField];
                                let lComplianceCodeDueDateMap = mComplianceCodeDueDateMap;
                                for (let lInd=0; lInd<lDataList.length; lInd++) {
                                    let lDataObj = lDataList[lInd];
                                    if(lDataObj['compliance_code_']) {
                                        let lObj1 = mComplianceCodeObjectMap[lDataObj['compliance_code_']];

                                        let lObj0 = {};
                                        lObj0['chart_id_']    = lObj1['chart_id_'];
                                        lObj0['chart_title_'] = lObj1['chart_title_'];
                                        lObj0['description_'] = lObj1['chart_title_'];
                                        if(lDataObj.hasOwnProperty(lObj1['heading_1_'])) {
                                            lObj0['heading_1_']   = lDataObj[lObj1['heading_1_']];
                                        }

                                        if(lDataObj.hasOwnProperty(lObj1['heading_2_'])) {
                                            lObj0['heading_2_']   = lDataObj[lObj1['heading_2_']];
                                            lComplianceCodeDueDateMap[lDataObj['compliance_code_']] = lDataObj[lObj1['heading_2_']];
                                        }

                                        if(lDataObj.hasOwnProperty(lObj1['heading_3_'])) {
                                            lObj0['heading_3_']   = lDataObj[lObj1['heading_3_']];
                                        }

                                        if(lDataObj.hasOwnProperty(lObj1['heading_4_'])) {
                                            lObj0['heading_4_']   = lDataObj[lObj1['heading_4_']];
                                        }

                                        if(lDataObj.hasOwnProperty(lObj1['heading_5_'])) {
                                            lObj0['heading_5_']   = lDataObj[lObj1['heading_5_']];
                                        }
                                        if(mIconMap[lObj1['icon_']]) {
                                            lObj0['icon_']        = mIconMap[lObj1['icon_']];
                                        }

                                        lComplianceDataList.push(lObj0);
                                    }
                                }
                                
                                setComplianceData(lComplianceDataList);
                                setComplianceCodeDueDateMap(lComplianceCodeDueDateMap);
                            }
                        }
                        
                        setShowBackdrop(false);
                    }
                );
            }
        }
    }

    function fnProcessChange(lData)
    {
        if(lData)
        {
            let lFyCode = mSelectedFYCode;
            let lCompanyCode = mSelectedCompanyCode;

            if(lData.id_ === 'ID_FY_CODE')
            {
                setSelectedFYObj(lData);
                setSelectedFYCode(lData.value_);
                lFyCode = lData.value_;
            }
            else if(lData.id_ === 'ID_COMPANY')
            {
                setSelectedCompanyObj(lData);
                setSelectedCompanyCode(lData.value_);
                lCompanyCode = lData.value_;
            }

            fnGetReportData(lFyCode, lCompanyCode);
        }
    }

    function ComplianceCard() {
        const fncomplianceCardData = (lCompliance) => {
            return (
                <div className="w-5 m-2 shadow-2 surface-card border-round p-4 flex-1 text-center mb-3 md:mb-0">
                    <div className="mb-3">
                        <img src={lCompliance.icon_} width="54" height="54" className="-mt-9 bg-white shadow-1 border-round" alt={lCompliance.chart_title_} />
                        
                        <span className="block text-900 mb-1 text-xl font-bold"> {lCompliance.chart_title_} </span>

                        <div className="flex justify-content-center gap-1 px-2">
                            <div className='text-column p-2'>
                                <div className="text-base text-primary mb-2 p-2">Frequency :
                                    <div className="text-base text-black-alpha-90 mb-4 p-2">{lCompliance.heading_1_}</div>
                                </div>

                                <div className="text-base text-primary mb-2"> Current Cycle Due Date :
                                    <div className="text-base text-black-alpha-90 mb-2 p-2">{lCompliance.heading_2_}</div>
                                </div>

                                <div className="text-base"> Status : <div><Tag className="mr-2 mt-2 w-7rem h-2rem" value={lCompliance.heading_3_}  icon={lCompliance.heading_4_} severity={lCompliance.heading_5_}></Tag> </div> </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div className='complianceCardData'>
                <DataView value={mcomplianceData} itemTemplate={fncomplianceCardData} />
            </div>
        )
    }
    
    let lDashboardCriteria =
        <div className='col-12 flex justify-content-center gap-6' >
            <div className="w-14rem h-5rem ">
                <CSDropDown
                    display_field_                = 'Financial Year'
                    data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                    pDataProviderId               = 'code_'
                    pDataField                    = 'code_'
                    pPlaceHolder                  = 'Select a Financial Year'
                    pRequired                     = { true }
                    pId                           = 'ID_FY_CODE'
                    data_provider_display_field_  = 'name_'
                    pControlType                  = "combo_box"
                    pValue                        = { mSelectedFYObj }
                    mValue                        = { mSelectedFYCode }
                    pProcessChange                = { fnProcessChange } 
                />
            </div>

            <div className="w-14rem h-5rem ">
                <CSDropDown
                    display_field_                = 'Company'
                    data_provider_source_         = 'USER_COMPANY_LIST'
                    pDataProviderId               = 'company_code_'
                    pDataField                    = 'company_code_'
                    pPlaceHolder                  = 'Select a Company'
                    pRequired                     = { true }
                    pId                           = 'ID_COMPANY'
                    data_provider_display_field_  = 'company_name_'
                    pControlType                  = "combo_box"
                    pValue                        = { mSelectedCompanyObj }
                    mValue                        = { mSelectedCompanyCode }
                    pProcessChange                = { fnProcessChange } 
                />
            </div>
        </div>

    const lForm =
        <>
            <CSCOBackdrop pShow = {mShowBackdrop}/>
            <div className='grid justify-content-center'>
                <CSCOScroller mComplianceCodeDueDateMap={mComplianceCodeDueDateMap}/>
                
                { lDashboardCriteria }

                <div className="w-full px-4">
                    <div className="flex flex-column  pt-4">
                        <ComplianceCard />
                    </div>
                </div>
            </div>
        </>

    return lForm;
}

export default Compliance;
