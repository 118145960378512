import {Button} from 'primereact/button';
import React, { Component } from "react"
import Dropzone from 'react-dropzone';

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil";
import CSRequestUtil from "../../util/CSRequestUtil";
import CSServiceUtil from "../../util/CSServiceUtil";
import { cfGetConstantValue} from "../../util/CSConstantUtil";

import { Dialog } from "primereact/dialog";
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar";

const styleDrag = 
{
    flex            : 1,
    display         : 'flex',
    justifyContent  : 'center',
    flexDirection   : 'column',
    alignItems      : 'center',
    padding         : '20px',
    borderWidth     : 2,
    borderRadius    : 2,
    borderColor     : '#eeeeee',
    backgroundColor : '#fafafa',
    color           : '#bdbdbd',
    outline         : 'none',
    minheight       : '300px',
    transition      : 'border .24s ease-in-out',
}

class CSCODetailsForm17 extends Component 
{
    mNumSections        = 1;
    mMinSectionItems    = 6;
    mFormUtil           = null;
    mCSServiceUtil      = null;
   
    constructor(props)
    {
        super(props);
        this.mFormUtil          = CSFormUtil.cfGetInstance();
        this.mCSServiceUtil     = CSServiceUtil.cfGetInstance();
        this.state = 
        {
            mDisplay                : this.props.pShow,
            mAppId                  : this.props.pAppId,
            mFormHeading            : (this.props.pFormHeading !== null) ? this.props.pFormHeading : "",
            mFormCriteria           : this.props.pFormCriteria,
            mFormGrid               : this.props.pFormGrid,
            mFormItems              : this.props.pFormItems,
            mFormHeaderItems        : null,
            mFormSectionItems       : null,
            mFormActions            : this.props.pFormActions,
            mFormProperties         : this.props.pFormProperties,
            mMaximizable            : this.props.pMaximizable,
            mFormData               : (this.props.pFormData && this.props.pFormData.selected_data_) ? this.props.pFormData.selected_data_ : {},
            mFormOrigData           : CSUtil.cfGetObjectCopy(this.props.pFormData),
            mControlType            : this.props.pControlType,
            mEditable               : this.props.pEditable,
            mWidth                  : this.props.pWidth,
            mHeight                 : this.props.pHeight,
            mValue                  : this.props.pValue,
            mSelectedTabActionItem  : this.props.pSelectedTabActionItem,
            mDragDropDataField      : null,
            mSelectedImage          : null,
            mImageFileName          : null,
            mSaveActionItem         : null,
            mDetailsActionList      : null,

            fnProcessChange         : this.props.fnProcessChange,
            fnProcessSubmit         : this.props.fnProcessSubmit,
            fnProcessClose          : this.props.fnProcessClose,
        }

        this.cfProcessChange        = this.cfProcessChange.bind(this);
        this.cfSetControlValue      = this.cfSetControlValue.bind(this);
        this.cfImageResize          = this.cfImageResize.bind(this);
        this.cfProcessResponse      = this.cfProcessResponse.bind(this);
        this.cfInitializeForm       = this.cfInitializeForm.bind(this);
        this.cfInitializeFormData   = this.cfInitializeFormData.bind(this);
        this.cfProcessDragDrop      = this.cfProcessDragDrop.bind(this);
 
        this.cfCloseForm            = this.cfCloseForm.bind(this);        
        this.process_save           = this.process_save.bind(this);
        this.process_cancel         = this.process_cancel.bind(this);
        this.process_close          = this.process_close.bind(this);
        this.process_reset          = this.process_reset.bind(this);

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if((lFormProperty.type_ === 'layout') && (lFormProperty.property_ === 'min_section_items_'))
                {
                    this.mMinSectionItems = lFormProperty.value_;
                    break;
                }
            }
        }
    }

    componentDidMount()
    {
        this.cfInitializeForm();
        this.cfInitializeFormData();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.pFormProperties)
            {
                for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
                {
                    var lFormProperty = this.props.pFormProperties[lInd_0];
                    if((lFormProperty.TYPE === 'layout') && (lFormProperty.property_ === 'min_section_items_'))
                    {
                        this.mMinSectionItems = lFormProperty.value_;
                        break;
                    }
                }
            }

            this.cfInitializeForm();
        }
    }

    cfInitializeFormData()
    {
        if(this.state.mSelectedTabActionItem)
        {
            if(this.state.mSelectedTabActionItem.on_click_service_code_)
            {
                var lServiceCode = this.state.mSelectedTabActionItem.on_click_service_code_;
                var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                if(lServiceObject)
                {
                    var lRequestId = null;
                    var lTabCriteria = null;
                    if(this.state.mFormData && this.state.mFormData.hasOwnProperty('selected_criteria_'))
                    {
                        lTabCriteria = this.state.mFormData.selected_criteria_;
                    }
        
                    if(this.state.mSelectedTabActionItem.request_id_)
                    {
                        lRequestId = this.state.mSelectedTabActionItem.request_id_;
                    }
        
                    var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                    if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                    {
                        var lServiceProperties = lServiceObject.cs_service_properties_;
                        for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                        {
                            lTabCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                        }
                    }
        
                    lRequestUtil.cfSetRequestCriteria(lTabCriteria);
                    lRequestUtil.cfProcessRequest((lTabResponse, lTabCriteria)=>
                        {
                            if(this.state.mSelectedTabActionItem.request_id_)
                            {
                                if(lTabResponse.result && (lTabResponse.result.length > 0))
                                {
                                    var lResult = lTabResponse.result[0];
                                    if(lResult.code === '1')
                                    {
                                        if(lTabResponse.hasOwnProperty('collection_'))
                                        {
                                            this.setState({ mLightBoxData : lTabResponse.collection_ });
                                        }
                                    }
                                }
                            }
                            else
                            {
                                if(lTabResponse.status)
                                {
                                    var lStatus = null;
                                    if(lTabResponse.status instanceof Array)
                                    {
                                        lStatus = lTabResponse.status[0];
                                    }
                                    else
                                    {
                                        lStatus = lTabResponse.status;
                                    }
        
                                    if(lStatus.code === 1)
                                    {
                                        if(lTabResponse.hasOwnProperty('collection_'))
                                        {
                                            this.setState({ mLightBoxData : lTabResponse.collection_ });
                                        }
                                    }
                                }
                            }
                        }
                    );
                }
            }
        }
    }

    cfInitializeForm()
    {
        var lDragDropDataField = null;
        var lFileNameDataField = null;
        var lFormHeaderItems = [];
        var lFormSectionItems = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_HEADING_SECTION_1')
                    {
                        lFormHeaderItems.push(lFormItem);
                    }
                    else if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_SECTION_1')
                    {
                        lFormSectionItems.push(lFormItem);
                    }
                    else if(lFormItem.cs_form_component_code_ === 'CS_DRAG_DROP_1')
                    {
                        lDragDropDataField = lFormItem.data_field_;
                        if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                        {
                            for(var lFipInd=0; lFipInd<lFormItem.cs_form_item_properties_.length; lFipInd++)
                            {
                                var lFipObj = lFormItem.cs_form_item_properties_[lFipInd];
                                if((lFipObj.property_ === 'data_field') && (lFipObj.selection_property_ === 'file_name'))
                                {
                                    lFileNameDataField = lFipObj.selection_value_;
                                }
                            }
                        }
                    }
                }
            }
        }

        var lSaveActionItem = null;
        var lDetailsActionList = null;

        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            for(var lDaInd=0; lDaInd<this.state.mFormActions.length; lDaInd++)
            {
                var lDetailsActionItem = this.state.mFormActions[lDaInd]; 
                if(lDetailsActionItem.component_type_ === 'DETAILS_ACTION')
                {
                    if(!lDetailsActionList)
                    {
                        lDetailsActionList = [];
                    }
            
                    if(lDetailsActionItem.action_type_ === '1')
                    {
                        if(lDetailsActionItem.component_type_ === 'DETAILS_ACTION')
                        {
                            if (lDetailsActionItem.action_ === 'process_save')
                            {
                                lSaveActionItem = lDetailsActionItem;
                            }

                            lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                        }
                    }
                    else
                    {
                        lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                    }
                    
                    lDetailsActionList.push(lDetailsActionItem);
                }
            }
        }

        this.setState(
            {
                mDisplay            : this.props.pShow,
                mAppId              : this.props.pAppId,
                mFormHeading        : (this.props.pFormHeading !== null) ? this.props.pFormHeading : "",
                mFormCriteria       : this.props.pFormCriteria,
                mFormGrid           : this.props.pFormGrid,
                mFormItems          : this.props.pFormItems,
                mFormActions        : this.props.pFormActions,
                mFormProperties     : this.props.pFormProperties,
                mFormData           : (this.props.pFormData && this.props.pFormData.selected_data_) ? this.props.pFormData.selected_data_ : {},
                mControlType        : this.props.pControlType,
                mEditable           : this.props.pEditable,
                mWidth              : this.props.pWidth,
                mHeight             : this.props.pHeight,
                mValue              : this.props.pValue,
                mSaveActionItem     : lSaveActionItem,
                mDetailsActionList  : lDetailsActionList,
                mFormHeaderItems    : lFormHeaderItems, 
                mFormSectionItems   : lFormSectionItems,
                mDragDropDataField  : lDragDropDataField,
                mImageFileName      : lFileNameDataField,

                fnProcessChange     : this.props.fnProcessChange,
                fnProcessSubmit     : this.props.fnProcessSubmit,
                fnProcessClose      : this.props.fnProcessClose
            }
        );
    }

    cfProcessDragDrop(lSelectedFiles)
    {
        if(lSelectedFiles && (lSelectedFiles.length > 0))
        {
            var lFileToLoad     = lSelectedFiles[0];
            var lDetailsForm    = this;
    
            var lFormData = this.state.mFormData;
            lFormData[this.state.mImageFileName] = lFileToLoad.name;

            /**
             * FileReader function for read the file.
             */
            var lFileReader = new FileReader();
            lFileReader.onload = function(lEvent) 
            {
                if(lEvent.target.result && lDetailsForm)
                {
                    lFormData[lDetailsForm.state.mDragDropDataField] = lEvent.target.result
                    lDetailsForm.setState(
                        {
                            mSelectedImage  : lEvent.target.result,
                            mFormData       : lFormData
                        }
                    )
                }
            };
        
            /**
             * Convert to base 64
             */ 
            lFileReader.readAsDataURL(lFileToLoad);
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            if(this.state.mFormData)
            {
                var lFormData = this.state.mFormData;
                lFormData[lData.data_field_] = lData.value_;
                this.setState ({mFormData : lFormData});
            }
        }
    }

    cfImageResize(lBase64Image, lWidth, lHeight)
    {
        var lDataUrl = '';
        if (lBase64Image) 
        {
            var lTempImg                    = new Image();
            lTempImg.src                    = lBase64Image;

            var lCanvas                     = document.createElement('canvas');
            lCanvas.width                   = (lWidth) ? lWidth : 105;
            lCanvas.height                  = (lHeight) ? lHeight : 105;

            var lContext                    = lCanvas.getContext('2d');
            lContext.imageSmoothingEnabled  = true;
            lContext.imageSmoothingQuality  = 'high';
            lContext.fillStyle              = 'rgba(255,255,255,0)';

            lContext.drawImage(lTempImg, 0, 0, 100, 100);

            var lExt = lBase64Image.toString().indexOf('image/png') >= 0 ? 'png' : 'jpeg';

            if (lExt === 'png') 
            {
                lDataUrl = lCanvas.toDataURL('image/png');
            }
            else 
            {
                lDataUrl = lCanvas.toDataURL('image/jpeg');
            }

        }

        return lDataUrl;
    }

    cfSetControlValue()
    {
        if((this.state) && (this.state.mFormItems))
        {
            var lFormData = this.state.mFormData;
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                if(lFormData)
                {   
                    if(lFormItem.control_type_ === 'light_box')
                    {
                        if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                        {
                            var lLightBoxDataMap = {};
                            for(var lFipInd=0; lFipInd<lFormItem.cs_form_item_properties_.length; lFipInd++)
                            {
                                var lFipObj = lFormItem.cs_form_item_properties_[lFipInd];
                                if(lFipObj.property_ === 'data_field')
                                {
                                    lLightBoxDataMap[lFipObj.selection_property_] = lFipObj.selection_value_;
                                }
                            }

                            var lLightBoxDataList = this.state.mLightBoxData;
                            if(lLightBoxDataList)
                            {
                                var llbobjKeys = Object.keys(lLightBoxDataMap);
                                if(llbobjKeys)
                                {
                                    for(var lLbInd=0; lLbInd<lLightBoxDataList.length; lLbInd++)
                                    {
                                        var lLbobj = lLightBoxDataList[lLbInd];
                                        for(var lLbkInd=0; lLbkInd<llbobjKeys.length; lLbkInd++)
                                        {
                                            var lKey = llbobjKeys[lLbkInd];
                                            if(lKey === 'source')
                                            {
                                                lLbobj[lKey] = lLbobj[lLightBoxDataMap[lKey]];
                                                lLbobj['thumbnail'] = this.cfImageResize(lLbobj[lKey])
                                            }
                                            else if (lKey === 'title') 
                                            {
                                                lLbobj['title'] = lLbobj[lLightBoxDataMap[lKey]];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else
                    {
                        if(lFormItem.control_type_ === 'text_input')
                        {
                            if(lFormData[lFormItem.data_field_])
                            {
                                lFormItem.mValue = lFormData[lFormItem.data_field_];        
                            }
                            else if(lFormItem.constant_)
                            {
                                lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                            }
                        }
                        else
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                    }
                }
                else
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    }
                    else
                    {
                        lFormItem.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm(lCloseFlag, lRefreshFlag)
    {
        this.state.fnProcessClose(lCloseFlag === false, lRefreshFlag);
    }
    
    process_save(lEvent)
    {
        if(lEvent && this.state.mSaveActionItem)
        {
            const data      = this.state.mFormData
            data.unit_id_   = '999999';
            this.mFormUtil.cfProcessSave(this.state.mSaveActionItem, data, this.cfProcessResponse);
        }
    }
       
    cfProcessResponse(lResponse)
    {
        if(lResponse && lResponse.code)
        {
            var lCode = null;
            if(lResponse.code instanceof Array)
            {
                lCode = lResponse.code[0];
            }
            else
            {
                lCode = lResponse.code;
            }
            
            if(lCode === 1)
            {

                this.cfInitializeFormData();
            }
        }
    }

    process_add()
    {

    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    myUploader = (event) =>
    {
        const data = this.state.mFormData
        data.append('file', event[0]);
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lDetailsSection = null;
        if(this.state.mFormSectionItems)
        {
            lDetailsSection = 
                <div 
                    style=
                    {
                        {
                            marginTop       : '0px', 
                            marginLeft      : '6px', 
                            marginRight     : '2px', 
                            marginBottom    : '1px', 
                            height          : 'auto',
                        }
                    }>
                    
                    { this.mFormUtil.cfGetDetailsSections(this.state.mFormSectionItems, this.mMinSectionItems, this.cfProcessChange) }
                </div>
        }

        let lHeaderActions = 
        <div style={{verticalAlign: 'middle'}}>            

            <Button
                key = "21"
                style=
                    {
                        {
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => this.process_close(false, true) }
            />
        </div>

        let lHeader = 
            <div className='flex justify-content-between'>
                <div className="p-toolbar-group-left">
                    <h2>{ this.state.mFormHeading || '.' }</h2>
                </div>

                <div className="p-toolbar-group-right">
                    { lHeaderActions }
                </div>
            </div>

        var lFormActions = 
            <CSCOHorizontalActionBar 
                align       = "left"
                pType       = 'DETAILS_ACTION_BAR'
                parent      = { this }
                actionList  = { this.state.mDetailsActionList } />

        return(
            <Dialog visible={this.state.mDisplay}
                    modal={true}
                    style=
                    {
                        {
                            minWidth        : '350px', 
                            minheight       : 'auto', 
                            paddingBottom   : -40,
                        }
                    }
                    minheight   = 'auto'
                    appendTo    = { document.body }
                    maximizable = { this.state.mMaximizable }
                    header      = {lHeader}
                    closable    = { false }
                    padding     = '5px'
                    onHide      = { () => this.cfCloseForm(true) }
                    footer      = { lFormActions }
                >
                <div className="form-item-1">
                    { lDetailsSection }

                    <Dropzone onDrop={(lSelectedFiles) => this.cfProcessDragDrop(lSelectedFiles)}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                                <div style={styleDrag} {...getRootProps({ className: 'dropzone' })} >
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' Drop image here, or click to select an image</p>
                                </div>                     
                            )} 
                    </Dropzone>
                </div>
             </Dialog>
        )
    }
}

export default CSCODetailsForm17;