import React from "react"
import CSBaseControl from "./CSBaseControl";
import { InputTextarea } from "primereact/inputtextarea";
class CSFileUpload extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            mFileList : null
        }

        this.cfFileUpload   = this.cfFileUpload.bind(this);
        this.cfHandleChange = this.cfHandleChange.bind(this);
    }

    cfHandleChange(lEvent)
    {
        if(lEvent.target.files)
        {
            let lFiles = '';
            let lTimestamp = null;
            for(var lfInd=0; lfInd<lEvent.target.files.length; lfInd++)
            {
                var lFile = lEvent.target.files[lfInd];
                if(lfInd > 0)
                {
                    lFiles += ",\n"
                }

                lTimestamp = lFile.lastModified;
                lFiles += lTimestamp +lFile.name;
            }
    
            this.setState( { mFileList: lEvent.target.files, mValue : lFiles } );

            var lData           = {};
            lData.control_type_ = 'file';
            lData.data_field_   = this.state.mDataField;
            lData.value_        = lFiles;
            lData.file_list_    = lEvent.target.files;
            lData.time_stamp_   = lTimestamp;
            lData.id_           = this.state.mId;

            this.state.mHandleChange(lData);
        }
    }

    cfFileUpload(lEvent)
    {
        if(lEvent)
        {
            this.growl.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
        }
    }

    render() 
    {
/*        var lctl = 
            <div>
                <FileUpload 
                    mode        = "basic" 
                    name        = "file[]" 
                    url         = "./files" 
                    accept      = "image/*, .pdf, .docx" 
                    maxFileSize = {1000000} 
                    onUpload    = {this.cfUploadFile} />

                <Growl ref={(el) => { this.growl = el; }}></Growl>
            </div>

        return lctl;
*/
        var lctl =
            <>
                <InputTextarea 
                    style    = {{ width : '100%', minheight : '65px' }}
                    readOnly = { true } 
                    value    = { this.state.mValue || '' } />

                <input 
                        style = 
                        {
                            {
                                borderWeight    : '1px',
                                borderColor     : '#DEDEDE',
                                backgroundColor : '#F5F5F5',
                                height          : '35px !important',
                                width           : '100%',
                                fontSize        : '13px'
                            }
                        }

                        type="file" 
                        onChange={this.cfHandleChange} />
            </> 

        return lctl;
    }
}

export default CSFileUpload;
