import React from "react"

import { fnGetForm } from "../../util/CSLayoutUtil"
import { fnGetFormHeader } from "../../util/CSLayoutUtil"
import CSCOCriteria from "../../components/CSCOCriteria"
import CSCOGrid from "../../components/CSCOGrid"
import CSCOBaseFormLayout from "./CSCOBaseFormLayout";
import CSFormUtil from "../../util/CSFormUtil";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar"
import CSCOBackdrop from "../../components/CSCOBackdrop";

class CSCOFormLayout14 extends CSCOBaseFormLayout
{
    componentDidMount()
    {
        super.cfInitializeLayout();
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render()
    {
        var lCriteria = null;
        var lSearchActions = null;
        if(this.state.mFormCriteria && (this.state.mFormCriteria.length > 0))
        {
            lCriteria = 
                <CSCOCriteria 
                    pCriteria       = {this.state.mFormCriteria}
                    pFormActions    = {this.state.mFormActions}
                    pFormProperties = {this.state.mFormProperties}
                    pParent         = {this}
                />;
        }

        if(this.state.mSearchActionList && (this.state.mSearchActionList.length > 0))
        {
            var lSearchActionList = this.state.mSearchActionList.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lSearchActions = 
                <CSCOBaseActionBar 
                    align       = "left"
                    pType       = 'SEARCH_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lSearchActionList}
                />;
        }

        var lDetailsForm = null;
        if(this.state.mDetailsFlag)
        {
            var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
            lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.cfCloseForm);
        }

        return( 
            <div id="ID_BASIC_FORM_LAYOUT">
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>
                
                { fnGetFormHeader(this.state.mFormLayoutHeading) }
    
                { lCriteria }

                <CSCOGrid
                    pData               = { this.state.mDataCollection }
                    pFeatureRefId       = { this.state.mFeatureRefId }
                    pColumns            = { this.state.mFormGrid }
                    rowIndex            = { this.state.mRowIndex } 
                    pageSize            = { 15 }
                    onClick             = { this.cfOnDataSelection }
                    pSearchActionBar    = { lSearchActions }
                    pEnableFilter       = { this.state.mEnableFilter }
                    pClearSelection     = { this.state.mClearSelection }
                />
                { lDetailsForm }
            </div>
            );
    }
}

export default CSCOFormLayout14;
