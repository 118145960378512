import React, { useState, useEffect } from 'react';
import { DataView } from 'primereact/dataview';
import { RadioButton } from 'primereact/radiobutton';
import CSUtil from '../util/CSUtil';

export default function CSCOCard(mProps) {
    const [mData, setData] = useState([]);
    const [mColumnMap, setColumnMap] = useState([]);
    const [mSelectedData, setSelectedData] = useState('');

    useEffect(() => {
        if (mProps.pColumns instanceof Array) {
            CSUtil.cfSort(mProps.pColumns, 'sequence_');

            let lColumnMap = {}
            for (let lInd = 0; lInd < mProps.pColumns.length; lInd++) {
                let lColumn = mProps.pColumns[lInd];
                if (lColumn.visible_ === '1') {
                    lColumnMap[lColumn.sequence_] = lColumn;
                }
            }

            setColumnMap(lColumnMap);
        }
    }, [mProps.pColumns]);

    useEffect(() => {
        if (mProps.pData instanceof Array) {
            setData(mProps.pData);
        }
    }, [mProps.pData]);

    const fnOnSelection = (lItem) => {
        setSelectedData(lItem);
        if(mProps.onClick) {
            mProps.onClick(lItem);
        }
    }

    const fnSetItem = (lItem) => {
        let lLogo = './user.jpg';
        if (lItem.hasOwnProperty('logo_')) {
            lLogo = lItem.logo_;
        }

        let lText1 = '';
        let lText2 = '';
        let lText3 = '';

        if (mColumnMap) {
            let lKeys = Object.keys(mColumnMap);
            for(let lkInd=0; lkInd<lKeys.length; lkInd++){
                let lColumn = mColumnMap[lKeys[lkInd]];
                if(lItem.hasOwnProperty(lColumn.data_field_))
                {
                    if(lkInd === 0)
                    {
                        lText1 = lItem[lColumn.data_field_];
                    }
                    else if(lkInd === 1)
                    {
                        lText2 = lItem[lColumn.data_field_];
                    }
                    else if(lkInd === 2)
                    {
                        lText3 = lItem[lColumn.data_field_];
                    }
                }
            }
        }

        return (
            <div className="col-12 sm:col-6 lg:col-12 xl:col-3 p-2">
                <div className="p-1 border-1 surface-border surface-card border-round flex  gap-2 p-2">
                    <img className="w-3 m-0 gap-2 bg-center shadow-1 border-round" src={lLogo} alt={lText1} />
                    <div className='m-1 flex '>
                        <div className='justify-content-center w-full'>
                            <div className="text-sm"> {lText1} </div>
                            <div className="text-xs"> {lText2} </div>
                            <div className="text-xs"> {lText3} </div>
                        </div>
                    </div>

                    <div className="flex align-items-center justify-content-between">
                        <RadioButton
                            inputId  = "ID_SELECTED_ITEM"
                            name     = "Selected Item"
                            value    = {mSelectedData}
                            onChange = {(lEvent) => (lEvent.checked) ? fnOnSelection(lItem) : null} />
                    </div>
                </div>
            </div>
        );
    }; 

    const fnItemTemplate = (lItem) => {
        if (lItem) {
            return fnSetItem(lItem);
        }
    };

    return (
        <div className="card">
            
            <DataView
                value                 = {mData}
                itemTemplate          = {fnItemTemplate}
                layout                = 'grid'
                paginator rows        = {20}
                paginatorPosition     = "top"
                overflow-y-visible    = 'true' />
        </div>
    )
}
