import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import CSUtil from '../util/CSUtil';
import CSConfigUtil from "../util/CSConfigUtil"
import CSServiceUtil from '../util/CSServiceUtil'
import { Dropdown } from 'primereact/dropdown';

var gServiceUtil = CSServiceUtil.cfGetInstance();

const AyChange = (lProps) =>
{
    const [mAcademicYear, setAcademicYear]         = useState(CSUtil.mSelectedAYObj);
    const [mAcademicYearList, setAcademicYearList] = useState(null);

    useEffect(() =>
        {
            gServiceUtil.cfGetDataFromDataProvider ('AY_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'AY_LIST')
                    {
                        setAcademicYearList(lCollection);
                    }
                }
            );
        }, []
    );

    const cfSetAcademicYear = () =>
    {
        if(mAcademicYear)
        {
            CSConfigUtil.mCurrentAYCode = mAcademicYear.academic_year_code_;
            CSConfigUtil.mCurrentAYName = mAcademicYear.academic_year_name_;
            gServiceUtil.cfResetDataproviderList();
        }

        lProps.fnProcessClose(true);
    }

    var lFooter =
        <div className="cs-dialog-footer">
            <Button label="Save" icon={CSUtil.mNameIconMap['PI_CHECK']} disabled = {!mAcademicYear} onClick = { () => cfSetAcademicYear() }/>
            <Button label="Back" icon={CSUtil.mNameIconMap['PI_CARET_LEFT']} onClick = { () => lProps.fnProcessClose() }/>
        </div>

    var lForm =
        <Dialog 
            className='cs-dialog-1'
            visible = {lProps.pShow} 
            header = "Academic Year Change" 
            footer = {lFooter} 
            modal = {true} 
            onHide = { () => lProps.fnProcessClose() }>
            <div className="cs-details-section-1" align="center">
                <div className  = "p-field">
                    <label htmlFor="ID_ACADEMIC_YEAR" className="cs-details-section-label">Academic Year</label>
                        <Dropdown 
                            id          = "ID_ACADEMIC_YEAR" 
                            className   = "cs-details-section-control" 
                            type        = "text" 
                            onChange    = {(lItem) => setAcademicYear(lItem.value)}
                            appendTo    = {document.body}                            
                            optionLabel = 'academic_year_name_'
                            options     = {mAcademicYearList}
                            value       = {mAcademicYear}/>
                </div>
            </div>
        </Dialog>
    return lForm;
}

export default AyChange;
