import React, { Component, createRef } from "react"
import ReactToPrint from "react-to-print";
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import CSUtil from "../util/CSUtil";

class CSCOPrint extends Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mComponent  : this.props.pComponent,
            mCloseForm  : this.props.pCloseForm,
            mHeading    : this.props.pHeading,
            mDisplay    : this.props.pDisplay
        }

        this.mComponentRef = createRef();
        this.cfCloseForm = this.cfCloseForm.bind(this);
    }

    cfCloseForm()
    {
        if(this.state.mCloseForm)
        {
            this.state.mCloseForm();
        }
    }

    render()
    {
        return (
            <Dialog
                visible     = {this.state.mDisplay}
                modal       = {true}
                closable    = {true}
                style = 
                {
                    {
                        width           : '870px',
                        backgroundColor : "#D8D8D8"
                    }
                }
                minheight   = 'auto'
                header      = {this.state.mHeading}
                appendTo    = { document.body }
                onHide      = {() => this.cfCloseForm()}>
                <div style={{ minheight : '350px', maxHeight : '575px', overflowY : 'auto', marginRight : -10}}>
                    <ReactToPrint
                        trigger = 
                            {() => 
                                <Button
                                    style=
                                        {
                                            {
                                                fontSize        : '13.2px', 
                                                marginRight     : '.35em', 
                                                width           : '32px', 
                                                height          : '32px',
                                                verticalAlign   : 'middle'
                                            }
                                        } 
                                        type="Button" 
                                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                                />
                            }
                        content={() => this.mComponentRef.current}
                    />

                    <div ref = {this.mComponentRef} >
                        { this.state.mComponent }
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default CSCOPrint;
