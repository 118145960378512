import React from "react";

import CSUtil from "../util/CSUtil";
import CSServiceUtil from "../util/CSServiceUtil";
import CSConfigUtil from "../util/CSConfigUtil"
import { fnGetFormMetaData } from "../util/CSMiscUtil"
import { fnGetLayout } from "../util/CSLayoutUtil";
import CSCOBackdrop from "../components/CSCOBackdrop";

class CSCOFormLayoutManager extends React.Component
{
    mCSUtil                         = null;
    mCSServiceUtil                  = null;
    mFormItemRefIdObjectMap         = {};
    mControlIdFormItemObjectMap     = {};
    mActionRefIdFormItemObjectMap   = {};
    mParentRefIdActionIdMap         = {};

    constructor(props)
    {
        super(props);
        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();
        this.state = 
        {   
            mFeatureRefId           : this.props.pFeatureRefId,
            mLayoutType             : this.props.pLayoutType,
            mLayoutCode             : this.props.pLayoutCode,
            mAppId                  : this.props.pAppId,
            mRowIndex               : -1,
            mShowBackdrop           : true,
            mDataCollection         : [],
            mSelectedData           : null,
            mFormServices           : null,
            mFormActions            : null,
            mFormGrid               : null,
            mFormItems              : null,
            mFormDetails            : null,
            mFormCriteria           : null,
            mFormProperties         : null,
            mFormValidation         : null,
            mFormSelection          : null,
            mFormActionProperties   : null,
            mFormcriteriaProperties : null,
            mFormGridProperties     : null,
            mFormGridItemProperties : null,
            mFormItemProperties     : null,
            mFormItemComputation    : null,
            mFormMetaData           : null,
            mRefresh                : false
        };

        this.cfOnDataSelection = this.cfOnDataSelection.bind(this);
        this.cfProcessFormDetails = this.cfProcessFormDetails.bind(this);
        this.cfGetFormData = this.cfGetFormData.bind(this);
    }

    componentDidMount()
    {
        this.cfGetFormData();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if((lPrevProps.pFeatureRefId !== this.props.pFeatureRefId) || this.props.pRefresh)
        {
            if(!this.state.mRefresh)
            {
                this.setState({mRefresh : this.props.pRefresh});
                this.cfGetFormData();
            }
        }
    }

    cfGetFormData()
    {
        var lAppObj = {};
        lAppObj.user_id_    = CSConfigUtil.mUserId;
        this.setState({mShowBackdrop : true});

        if(this.props.pFeatureRefId)
        {
            var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
            if(lMenuItem != null)
            {
                lAppObj.app_code_       = CSConfigUtil.mAppCode;
                lAppObj.app_id_         = lMenuItem.layout_item_code_;
                lAppObj.feature_ref_id_ = this.props.pFeatureRefId;
                lAppObj.layout_code_    = this.props.pLayoutCode;
                lAppObj.layout_type_    = this.props.pLayoutType;
            }    
        }
        else if(this.props.pAppId)
        {
            lAppObj.app_code_       = CSConfigUtil.mAppCode;
            lAppObj.app_id_         = this.props.pAppId;
            lAppObj.feature_ref_id_ = null;
            lAppObj.layout_code_    = this.props.pLayoutCode;
            lAppObj.layout_type_    = this.props.pLayoutType;
        }

        fnGetFormMetaData(lAppObj, this.cfProcessFormDetails);
    }

    cfProcessFormDetails(lMetaData, lCriteria)
    {
        if(lMetaData)
        {
            var lAppId;
            if(this.props && this.props.pFeatureRefId)
            {
                var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
                if(lMenuItem != null)
                {
                    lAppId = lMenuItem.layout_item_code_;
                }
            }
    
            this.mActionRefIdFormItemObjectMap  = lMetaData.mActionRefIdFormItemObjectMap;
            this.mFormItemRefIdObjectMap        = lMetaData.mFormItemRefIdObjectMap;
            this.mControlIdFormItemObjectMap    = lMetaData.mControlIdFormItemObjectMap;

            this.setState(
                { 
                    mShowBackdrop      : false,
                    mAppId                  : lAppId,
                    mFeatureRefId           : this.props.pFeatureRefId ,
                    mLayoutType             : this.props.pLayoutType,
                    mLayoutCode             : this.props.pLayoutCode,
                    mFormCriteria           : lMetaData.pFormCriteria,
                    mFormServices           : lMetaData.pServices,
                    mFormActions            : lMetaData.pFormActions,
                    mFormItems              : lMetaData.pFormItems,
                    mFormDetails            : lMetaData.pFormDetails,
                    mFormGrid               : lMetaData.pFormGrid,
                    mFormProperties         : lMetaData.pFormProperties,
                    mFormValidation         : lMetaData.pFormValidation,
                    mFormSelection          : lMetaData.pFormSelection,
                    mFormActionProperties   : lMetaData.pFormActionProperties,
                    mFormcriteriaProperties : lMetaData.pFormCriteriaProperties,
                    mFormGridProperties     : lMetaData.pFormGridProperties,
                    mFormGridItemProperties : lMetaData.pFormGridItemProperties,
                    mFormItemProperties     : lMetaData.pFormItemProperties,
                    mFormItemComputation    : lMetaData.pFormItemComputation,
                    mFormMetaData           : lMetaData
                }
            );
        }
        else
        {
            this.setState(
                { 
                    mShowBackdrop  : false
                }
            );
        }
    }

    cfOnDataSelection(sel_data)
    {
        if(sel_data != null)
        {
            this.setState({mSelectedData : sel_data});
        }
    }

    render()
    {
        var lProps = 
        {
            pAppId                  : this.state.mAppId,
            pFeatureRefId           : this.state.mFeatureRefId,
            pLayoutType             : this.state.mLayoutType,
            pLayoutCode             : this.state.mLayoutCode,
            pDataCollection         : this.state.mDataCollection,
            pRowIndex               : this.state.mRowIndex,
            
            pFormCriteria           : this.state.mFormCriteria,
            pFormActions            : this.state.mFormActions,
            pFormGrid               : this.state.mFormGrid,
            pFormItems              : this.state.mFormItems,
            pFormDetails            : this.state.mFormDetails,
            pFormProperties         : this.state.mFormProperties,
            pFormValidation         : this.state.mFormValidation,
            pFormSelection          : this.state.mFormSelection,
            pFormActionProperties   : this.state.mFormActionProperties,
            pFormCriteriaProperties : this.state.mFormcriteriaProperties,
            pFormGridProperties     : this.state.mFormGridProperties,
            pFormGridItemProperties : this.state.mFormGridItemProperties,
            pFormItemProperties     : this.state.mFormItemProperties,
            pFormItemComputation    : this.state.mFormItemComputation,
            pFormMetaData           : this.state.mFormMetaData,

            pParent                 : this,

            onDataSelection         : this.cfOnDataSelection,
            process_close           : this.cfProcessClose
        }

        var lLayout = 
            <>
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>
                { fnGetLayout(this.state.mLayoutCode, lProps) }
            </>

        /**
         * Return respective layout
         */
        return lLayout;
    }
}

export default CSCOFormLayoutManager;
