import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';

import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";
import CSCOPickList from "../../components/CSCOPickList";
import CSCODetailsHeader from "../../components/CSCODetailsHeader";
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil";
import CSCOBackdrop from "../../components/CSCOBackdrop";
import { Buffer } from "buffer";

class CSCODetailsForm12 extends Component 
{
	mComponentCode     = 'CS_DETAILS_FORM_LAYOUT_12';
	mNumSections 	   = 3;
    mMinSectionItems   = 7;
    mFormUtil          = CSFormUtil.cfGetInstance();
    mServiceUtil       = CSServiceUtil.cfGetInstance();

    mDataFieldFormItemMap = {};
    
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mDisplay		: props.pShow,
            mAppId          : props.pAppId,
            mFormHeading    : (props.pFormHeading) ? props.pFormHeading : "",
            mFormItems      : props.pFormItems,
            mFormData       : props.pFormData,
            mSelectedData   : props.pSelectedData,
            mFormOrigData   : CSUtil.cfGetObjectCopy(props.pFormData),
            mFormActions    : props.pFormActions,
            mFormProperties : props.pFormProperties,
            mControlType    : props.pControlType,
            mEditable       : props.pEditable,
            mWidth          : props.pWidth,
            mHeight         : props.pHeight,
            mValue          : props.pValue,
            mHeaderFlag     : false,
            mSectionFlag    : false,
            mShowBackdrop   : false,

            fnProcessChange : props.fnProcessChange,
            fnProcessSubmit : props.fnProcessSubmit,
            fnProcessClose  : props.fnProcessClose,

            mSelectedActionItem : null
        }

        this.cfProcessChange    = this.cfProcessChange.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);
        this.cfCloseForm        = this.cfCloseForm.bind(this);
        this.cfProcessResponse  = this.cfProcessResponse.bind(this);
        this.cfEnableSave       = this.cfEnableSave.bind(this);
        this.cfGetRequestObject = this.cfGetRequestObject.bind(this);

        this.process_save   = this.process_save.bind(this);
        this.process_cancel = this.process_cancel.bind(this);
        this.process_close  = this.process_close.bind(this);
        this.process_reset  = this.process_reset.bind(this);

        if(this.props.pFormItems)
        {
            for(var lfiInd=0; lfiInd<this.props.pFormItems.length; lfiInd++)
            {
                var lfItem = this.props.pFormItems[lfiInd];
                if(lfItem.data_field_)
                {
                    this.mDataFieldFormItemMap[lfItem.data_field_] = lfItem;
                }
            }
        }

        if(this.props.pFormData)
        {
            let lFormData = this.props.pFormData;
            if(lFormData.hasOwnProperty('selected_data_'))
            {
                var lSelectedData = lFormData.selected_data_;
                if(lSelectedData && (lSelectedData instanceof Array) && (lSelectedData.length > 0))
                {
                    this.state.mSelectedData = lSelectedData[0];    
                }
                else
                {
                    this.state.mSelectedData = lSelectedData;    
                }
            }
        }
    }

    componentDidMount()
    {
        let lProperties = this.props;
        let lServiceCode;
        let lRequestObject;
        if(lProperties.pSelectedCriteria)
        {
            let lCriteria = lProperties.pSelectedCriteria;

            if(lProperties.pSelectedActionItem)
            {
                let lRequestRefId = lProperties.pSelectedActionItem.on_click_request_ref_id_;
                if(lRequestRefId)
                {
                    lRequestObject = this.mServiceUtil.cfGetRequestObject(lRequestRefId);
                }

                lServiceCode = lProperties.pSelectedActionItem.on_click_service_code_;
                if((lServiceCode || lRequestObject) && lCriteria)
                {
                    let lServiceObject = this.mServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        let lRequestId = null;
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                        {
                            lRequestId = lRequestObject.request_id_;
                        }
                        
                        let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                        if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            if(lServiceProperties !== null)
                            {
                                for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                                {
                                    if(lServiceProperties[lInd].type_ === 'criteria')
                                    {
                                        if(!lCriteria)
                                        {
                                            lCriteria = {}
                                        }

                                        lCriteria[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                                    }
                                }
                            }
                        }

                        this.setState({mShowBackdrop : true });
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse) =>
                            {
                                if(lResponse)
                                {
                                    let lFormData = this.state.mFormData;
                                    var lKeys = Object.keys(lResponse);
                                    if(!lFormData)
                                    {
                                        lFormData = {}
                                    }

                                    for (let lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                                    {
                                        let lKey = lKeys[lKeyInd];
                                        if((lKey !== 'status') && (lKey !== 'collection_') && (lKey !== 'result'))
                                        {
                                            lFormData[lKey] = lResponse[lKey];
                                        }
                                    }

                                    this.setState({mFormData : lFormData });
                                }
                                
                                this.setState({mShowBackdrop : false });
                            }
                        );
                    }
                }
            }
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.setState({
                mAppId          : this.props.pAppId,
                mFormHeading    : (this.props.pFormHeading) ? this.props.pFormHeading : "",
                mFormItems      : this.props.pFormItems,
                mFormData       : (this.props.pFormData) ? this.props.pFormData : this.props.pSelectedData,
                mControlType    : this.props.pControlType,
                mEditable       : this.props.pEditable,
                mWidth          : this.props.pWidth,
                mHeight         : this.props.pHeight,
                mValue          : this.props.pValue,

                fnProcessChange : this.props.fnProcessChange,
                fnProcessSubmit : this.props.fnProcessSubmit,
                fnProcessClose  : this.props.fnProcessClose
            });
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            var lSelectedData;
            if(lData.control_type_ !== 'pick_list')
            {
                if(!lFormData)
                {
                    lFormData = {};
                }
                else
                {
                    lSelectedData = this.state.mSelectedData;
                }

                if(!lSelectedData && this.state.mFormProperties)
                {
                    for(var lfpInd=0; lfpInd < this.state.mFormProperties.length; lfpInd++)
                    {
                        var lfpObj = this.state.mFormProperties[lfpInd];
                        if(lfpObj.property_ === 'data_')
                        {
                            lFormData[lfpObj.value_] = {};
                            lSelectedData = lFormData[lfpObj.value_];
                            if(!lSelectedData)
                            {
                                lSelectedData = {}
                                lFormData[lfpObj.value_] = lSelectedData;
                            }
                        }
                    }

                    if(!lSelectedData)
                    {
                        lSelectedData = {};
                    }
                }

                for(var ind=0; ind<this.state.mFormItems.length; ind++)
                {
                    var lFormItem = this.state.mFormItems[ind];
                    if((lFormItem.control_type_ === 'constant') && (!lSelectedData[lFormItem.data_field_]))
                    {
                        lSelectedData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                }

                if(lData.hasOwnProperty('data_field_') && lData.data_field_)
                {
                    lSelectedData[lData.data_field_] = lData.value_;
                }    
            }
            else
            {
                if(!lFormData)
                {
                    lFormData = {};
                }

                if(lData.hasOwnProperty('data_field_') && lData.data_field_)
                {
                    lFormData[lData.data_field_] = lData.value_;
                }
            }

            if(lSelectedData)
            {
                this.setState({mFormData : lFormData, mSelectedData : lSelectedData});
            }
            else
            {
                this.setState({mFormData : lFormData});
            }
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mSelectedData;
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm(lCloseFlag)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(lCloseFlag);
        }
    }

    process_close(lCloseFlag, lRefreshFlag)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(lCloseFlag, lRefreshFlag);
        }
    }

    cfProcessResponse(lResponse)
    {
        if(lResponse && lResponse.code)
        {
            var lCode = null;
            if(lResponse.code instanceof Array)
            {
                lCode = lResponse.code[0];
            }
            else
            {
                lCode = lResponse.code;
            }
            
            if(lCode === 1)
            {
                var lCloseFlag = false;
                if(this.state.mSelectedActionItem)
                {
                    lCloseFlag = (this.state.mSelectedActionItem.new_state_ === "list")
                }

                this.cfCloseForm(lCloseFlag);
            }
        }
    }

    process_save(lEvent, lParent, lActionItem)
    {
        this.setState({mSelectedActionItem : lActionItem});
        if(lActionItem)
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            var lRequestData = null;
                            if(this.state.mFormItems)
                            {
                                for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
                                {
                                    var lFormItem = this.state.mFormItems[lInd];
                                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                    {
                                        var lRequestObj = this.cfGetRequestObject(lFormItem);
                                        if(lRequestObj)
                                        {
                                            if(!lRequestData)
                                            {
                                                lRequestData = {}
                                            }
                    
                                            lRequestData[lFormItem.data_field_] = lRequestObj;
                                        }
                                    }
                                }
                            }
                    
                            if(!lRequestData)
                            {
                                lRequestData = this.state.mFormData;
                            }
                    
                            this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                (lResponse) =>
                                {
                                    var lCode = null;
                                    var lMsg = null;
                                    var lCloseFlag = (lActionItem.new_state_ === "list")
    
                                    if(lResponse && lResponse.code)
                                    {
                                        if(lResponse.code instanceof Array)
                                        {
                                            lCode = lResponse.code[0];
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message[0];
                                            }
                                        }
                                        else
                                        {
                                            lCode = lResponse.code;
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message;
                                            }
                                        }
                                    }
                                    else
                                    {
                                        lCode = -1;
                                    }
    
                                    this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                    this.process_close(lCloseFlag, true);
                                }
                            );
                        }
                    }
                }
            );
        }
    }

    cfGetRequestObject(lFormItem)
    {
        var lRetObj;
        var lFormItemProperties = lFormItem.cs_form_item_properties_;
        if(lFormItemProperties)
        {
            for(var lfipInd=0; lfipInd<lFormItemProperties.length; lfipInd++)
            {
                var lfipObj = lFormItemProperties[lfipInd];
                if(lfipObj.type_ === 'request_data')
                {
                    if(lfipObj.property_ === '#CONSTANT#')
                    {
                        if(lFormItem.control_type_ !== 'collection')
                        {
                            if(!lRetObj)
                            {
                                lRetObj = {}
                            }
        
                            lRetObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                        }
                        else
                        {
                            if(!lRetObj)
                            {
                                var lDataObj;
                                if(lfipObj.property_ === 'form_data')
                                {
                                    lDataObj = this.state.mFormData;
                                }
                                else
                                {
                                    lDataObj = this.state.mDataCollection;
                                }

                                if(lfipObj.value_)
                                {
                                    lRetObj = lDataObj[lfipObj.value_];
                                }
                                else
                                {
                                    lRetObj = lDataObj;
                                }
                            }
        
                            if(lRetObj)
                            {
                                for(var lcInd=0; lcInd<lRetObj.length; lcInd++)
                                {
                                    var lcObj = lRetObj[lcInd];
                                    lcObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                                }
                            }
                        }
                    }
                    else if((lfipObj.property_ === 'form_data') && (this.state.mFormData))
                    {
                        var lFormData;
                        if(!lfipObj.value_)
                        {
                            lFormData = this.state.mFormData;
                        }
                        else if(lfipObj.value_ === 'selected_data_')
                        {
                            lFormData = this.state.mSelectedData;
                        }
                        else
                        {
                            lFormData = this.state.mFormData[lfipObj.value_];
                        }

                        if(lFormItem.control_type_ !== 'collection')
                        {
                            if(!lRetObj)
                            {
                                lRetObj = {}
                            }
        
                            if(lFormData && lFormData.hasOwnProperty(lfipObj.selection_value_))
                            {
                                var lfiObj = this.mDataFieldFormItemMap[lfipObj.selection_property_];
                                if(lfiObj && (lfiObj.is_filter_ === 'Y') && (lfiObj.filter_value_ === 'base64_encode'))
                                {
                                    lRetObj[lfipObj.selection_property_] = Buffer.from(lFormData[lfipObj.selection_value_]).toString('base64');
                                }
                                else
                                {
                                    lRetObj[lfipObj.selection_property_] = lFormData[lfipObj.selection_value_];
                                }
                            }
                            else
                            {
                                lRetObj[lfipObj.selection_property_] = '';
                            }
                        }
                        else
                        {
                            if(!lRetObj)
                            {
                                lRetObj = [];
                            }
        
                            if(lFormData)
                            {
                                if(lFormData instanceof Array)
                                {
                                    for(var lcInd0=0; lcInd0<lFormData.length; lcInd0++)
                                    {
                                        var lObj = {}
                                        lRetObj.push(lObj);
    
                                        var lcObj0 = lFormData[lcInd0];
                                        lObj[lfipObj.selection_property_] = lcObj0[lfipObj.selection_value_];
                                    }
                                }
                                else
                                {
                                    for(var lcInd1=0; lcInd1<lRetObj.length; lcInd1++)
                                    {
                                        var lcObj1 = lRetObj[lcInd1];
                                        lcObj1[lfipObj.selection_property_] = lFormData[lfipObj.selection_value_];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return lRetObj;
    }

    cfEnableSave(lValue, lHeaderFlag)
    {
        if(lHeaderFlag === 1)
        {
            this.setState({mHeaderFlag : lValue});
        }
        else
        {
            this.setState({mSectionFlag : lValue});
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormItemList = [];
        var lPickListSource;
        var lPickListTarget;
        var lPickListDataField;
        var lId;
        var lPickListSourceHeader;
        var lPickListTargetHeader;
        var lTemplateItems = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }

                if(lFormItem.control_type_ === 'pick_list')
                {
                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                    {
                        for(var lFipInd=0; lFipInd<lFormItem.cs_form_item_properties_.length; lFipInd++)
                        {
                            var lFipObj = lFormItem.cs_form_item_properties_[lFipInd];
                            if(lFipObj.value_)
                            {
                                if(lFipObj.property_ === 'pick_list_source')
                                {
                                    if(this.state.mFormData && this.state.mFormData.hasOwnProperty(lFipObj.value_))
                                    {
                                        lPickListSource = this.state.mFormData[lFipObj.value_];
                                    }
                                }
                                else if(lFipObj.property_ === 'pick_list_target')
                                {
                                    lPickListDataField = lFipObj.value_;
                                    lId = lFormItem.id_;
                                    if(this.state.mFormData && this.state.mFormData.hasOwnProperty(lFipObj.value_))
                                    {
                                        lPickListTarget = this.state.mFormData[lFipObj.value_];
                                    }
                                }
                                else if(lFipObj.property_ === 'pick_list_source_header')
                                {
                                    lPickListSourceHeader = lFipObj.value_;
                                }
                                else if(lFipObj.property_ === 'pick_list_target_header')
                                {
                                    lPickListTargetHeader = lFipObj.value_;
                                }
                                else if(lFipObj.property_ === 'pick_list_template_items')
                                {
                                    lTemplateItems.push(lFipObj.value_);
                                }
                            }
                        }
                    }
                }
            }
        }

        var lDetailsActionList = null;
        var lSaveActionItem = null;
        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            for(var lDaInd=0; lDaInd<this.state.mFormActions.length; lDaInd++)
            {
                var lDetailsActionItem = this.state.mFormActions[lDaInd]; 
                if(lDetailsActionItem.component_type_ === 'DETAILS_ACTION')
                {
                    if(!lDetailsActionList)
                    {
                        lDetailsActionList = [];
                    }

                    if(lDetailsActionItem.action_type_ === '1')
                    {
                        if (lDetailsActionItem.action_ === 'process_save')
                        {
                            lSaveActionItem = lDetailsActionItem;
                        }

                        var lHeaderFlag = CSFormUtil.cfEnableAction(lSaveActionItem, this.state.mSelectedData, lFormItemList);
                        var lSectionFlag = (lPickListTarget && (lPickListTarget.length > 0)) ? true : false;
                        lDetailsActionItem.mEnabled = lHeaderFlag && lSectionFlag;
                    }
                    else
                    {
                        lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                    }

                    lDetailsActionList.push(lDetailsActionItem);
                }
            }

            CSUtil.cfSort(lDetailsActionList, 'sequence_');
        }

        
        var lHeader =                     
            <CSCODetailsHeader
                pFormItems          = {lFormItemList} 
                pFormData           = {this.state.mFormData} 
                pFormProperties     = {this.props.pFormProperties} 
                pFormItemProperties = {this.props.pFormItemProperties}
                pSaveActionItem     = {lSaveActionItem}
                pProcessChange      = {this.cfProcessChange}
                pEnableSaveFn       = {this.cfEnableSave}
                pNumSections        = {2}
                pMaxSectionItems    = {2}   
                />

        var lPickList = 
            <CSCOPickList 
                pId                 = {lId}
                pSourceList         = {lPickListSource} 
                pTargetList         = {lPickListTarget} 
                pDataField          = {lPickListDataField}
                pSourceHeader       = {lPickListSourceHeader}
                pTargetHeader       = {lPickListTargetHeader}
                pTemplateItems      = {lTemplateItems}
                pSaveActionItem     = {lSaveActionItem}
                pEnableSaveFn       = {this.cfEnableSave}
                pProcessChange      = {this.cfProcessChange}
                pSectionFlag        = {2}
                />

        var lForm =
            <Dialog visible     = {this.state.mDisplay}
                    modal       = {true}
                    style       = {{ minWidth : '350px', minheight : 'auto', paddingBottom: -40 }}
                    minheight   = 'auto'
                    header      = {this.state.mFormHeading || '.'}
                    padding     = '10px'
                    appendTo    = { document.body }
                    onHide      = {() => this.cfCloseForm(true)}
                    footer      = { <CSCOHorizontalActionBar align = "left" parent = {this} actionList = { lDetailsActionList } /> }>                    
                <>
                    <CSCOBackdrop pShow={this.state.mShowBackdrop} />
                    { lHeader }
                    { lPickList }
                </>
            </Dialog>
          
        return lForm;
    }
}

export default CSCODetailsForm12;
