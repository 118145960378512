import React, {Component} from "react"
import { PanelMenu } from 'primereact/panelmenu';
import CSUtil from "../util/CSUtil";

class CSCOMenu extends Component 
{    
    mCSUtil = null;
    constructor(props)
    {
        super(props);

        this.mCSUtil = CSUtil.cfGetInstance();

        this.state = 
        {
            mSelectedMenuItemId: 0,
            mSelectedMenuItem: null
        }
    }

        
    render() 
    {
        this.mCSUtil.cfSetMenuCommand(this.props.cfOnMenuItemClick);
        let lMenu = this.mCSUtil.cfGetMenuItems();
        if(lMenu)
        {
            return ( <PanelMenu model = {lMenu} /> );
        }
        else
        {
            return null;
        }
    }
}

export default CSCOMenu; 
