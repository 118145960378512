import React from "react"
import CSCOStandardReport1 from "./CSCOStandardReport1";
import CSCOGrid from "../../components/CSCOGrid";
import CSUtil from "../../util/CSUtil";
import { fnGetDateToString, fnGetDaysInMonth, fnGetMonthFromIndex } from "../../util/CSDateTimeUtil";
import CSRequestUtil from "../../util/CSRequestUtil";
import { cfGetConstantValue } from "../../util/CSConstantUtil";
import CSServiceUtil from "../../util/CSServiceUtil";

class CSCOReportTable extends React.Component
{
    constructor(props)
    {
        super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();
                
        this.state = 
        {
            mReportData          : this.props.pReportData,
            mColumns             : this.props.pColumns,
            mHeaderColumns       : null,
            mDataCollection      : this.props.pDataCollection,
            mReport              : null,
            mPrintFlag           : this.props.pPrintFlag,
            fnClosePrintForm     : this.props.pClosePrintForm,
            mColumnChooser       : this.props.pColumnChooser,
            mHeight              : (this.props.pIsDashboardReport) ? '370' : (CSUtil.cfGetInstance()).mApplicationHeight - 130
        }
        
        this.cfInitializeReport    = this.cfInitializeReport.bind(this);
        this.cfClosePrintForm      = this.cfClosePrintForm.bind(this);
        this.cfCloseColumnChooser  = this.cfCloseColumnChooser.bind(this);
    }

    componentDidMount()
    {
        this.cfInitializeReport();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeReport();
        }
    }

    cfInitializeReport()
    {
        if(this.props.pColumns)
        {
            let lColumns = null;
            let lHeaderColumns = null;
            let lnameColumnMap = {}
            let lDynamicColumnProperties = null;

            for(let lcInd=0; lcInd<this.props.pColumns.length; lcInd++)
            {                
                let lDynamicColumnYear = null;
                let lDynamicColumnMonth = null;
                let lColumn = this.props.pColumns[lcInd];
                if(lColumn.hasOwnProperty('report_table_column_properties_'))
                {
                    for(let lpInd=0; lpInd< lColumn.report_table_column_properties_.length; lpInd++)
                    {
                        let lProperty = lColumn.report_table_column_properties_[lpInd];
                        if(lProperty.property_ === 'summary_column')
                        {
                            if(!lColumns)
                            {
                                lColumns = []
                            }

                            if(!lHeaderColumns)
                            {
                                lHeaderColumns = []
                            }

                            if(lProperty.type_ === 'header')
                            {
                                if(lProperty.param_ === 'name')
                                {
                                    lColumn.property_ = lProperty.value_;
                                    let lnColumnList = lnameColumnMap[lProperty.value_]
                                    if(!lnColumnList)
                                    {
                                        lnColumnList = []
                                        lnameColumnMap[lProperty.value_] = lnColumnList;
                                    }

                                    lnColumnList.push(lColumn);
                                }

                                lColumn.property_type_ = lProperty.type_;
                                lHeaderColumns.push(lColumn);
                                lColumns.push(lColumn);
                            }
                            else if((lProperty.type_ === 'dynamic_column') && (lProperty.selection_property_ === '#MONTH_DATES#') && this.props.pCriteria)
                            {
                                if(lProperty.param_ === '#YEAR#')
                                {
                                    if(this.props.pCriteria.hasOwnProperty(lProperty.value_))
                                    {
                                        lDynamicColumnYear = this.props.pCriteria[lProperty.value_];
                                    }
                                }

                                if(lProperty.param_ === '#MONTH#')
                                {
                                    if(this.props.pCriteria.hasOwnProperty(lProperty.value_))
                                    {
                                        lDynamicColumnMonth = this.props.pCriteria[lProperty.value_];
                                    }
                                }

                                if(lProperty.param_ === '#MONTH_INDEX#')
                                {
                                    if(this.props.pCriteria.hasOwnProperty(lProperty.value_))
                                    {
                                        lDynamicColumnMonth = fnGetMonthFromIndex(this.props.pCriteria[lProperty.value_]);
                                    }
                                }

                                if(lDynamicColumnYear && lDynamicColumnMonth)
                                {
                                    let lDynamicColumns = fnGetDaysInMonth(lDynamicColumnMonth, lDynamicColumnYear);
                                    if(lDynamicColumns)
                                    {
                                        for(let ldcInd=0; ldcInd<lDynamicColumns.length; ldcInd++)
                                        {
                                            let ldCol = {}
                                            ldCol.visible_          = '1';
                                            ldCol.width_            = '-1';
                                            ldCol.sequence_         = Number(lColumn.sequence_) + ldcInd;
                                            ldCol.data_field_       = lDynamicColumns[ldcInd] + '_' + lColumn.data_field_;
                                            ldCol.name_             = lColumn.name_;
                                            ldCol.type_             = 'dynamic_column';
                                            ldCol.property_         = '#MONTH_DATES#';
                                            ldCol.column_prefix_    = lDynamicColumns[ldcInd];

                                            ldCol.property_ = lDynamicColumns[ldcInd];
                                            let lnColumnList = lnameColumnMap[lDynamicColumns[ldcInd]]
                                            if(!lnColumnList)
                                            {
                                                lnColumnList = []
                                                lnameColumnMap[lDynamicColumns[ldcInd]] = lnColumnList;
                                            }

                                            lnColumnList.push(ldCol);
                                            lColumns.push(ldCol);

                                            ldCol.property_type_ = 'header';
                                            lHeaderColumns.push(ldCol);
                                        }
                                    }
                                }
                            }
                        }
                        else if((lProperty.type_ === 'dynamic_column') && (lProperty.property_ === '#MONTH_DATES#') && this.props.pCriteria)
                        {
                            if(!lDynamicColumnProperties)
                            {
                                lDynamicColumnProperties = [];
                            }

                            lDynamicColumnProperties.push(lColumn);
                            if(lProperty.param_ === '#YEAR#')
                            {
                                if(this.props.pCriteria.hasOwnProperty(lProperty.value_))
                                {
                                    lDynamicColumnYear = this.props.pCriteria[lProperty.value_];
                                }
                            }

                            if(lProperty.param_ === '#MONTH#')
                            {
                                if(this.props.pCriteria.hasOwnProperty(lProperty.value_))
                                {
                                    lDynamicColumnMonth = this.props.pCriteria[lProperty.value_];
                                }
                            }

                            if(lProperty.param_ === '#MONTH_INDEX#')
                            {
                                if(this.props.pCriteria.hasOwnProperty(lProperty.value_))
                                {
                                    lDynamicColumnMonth = this.props.pCriteria[lProperty.value_];
                                }
                            }

                            if(lDynamicColumnYear && lDynamicColumnMonth)
                            {
                                let lDynamicColumns = fnGetDaysInMonth(lDynamicColumnMonth, lDynamicColumnYear);
                                if(lDynamicColumns)
                                {
                                    for(let ldcInd=0; ldcInd<lDynamicColumns.length; ldcInd++)
                                    {
                                        let ldCol = {}
                                        ldCol.visible_     = '1';
                                        ldCol.width_       = '-1';
                                        ldCol.sequence_    = Number(lColumn.sequence_) + ldcInd + 1;
                                        ldCol.data_field_  = lDynamicColumns[ldcInd];
                                        ldCol.name_        = lDynamicColumns[ldcInd];
                                        lColumns.push(ldCol);
                                    }
                                }
                            }
                        }
                    }
                }
                else
                {
                    if(!lColumns)
                    {
                        lColumns = []
                    }

                    lColumns.push(lColumn);
                }
            }

            let lKeys = Object.keys(lnameColumnMap);
            for(let lkInd=0; lkInd<lKeys.length; lkInd++)
            {
                let lKey = lKeys[lkInd];
                let lCList = lnameColumnMap[lKey];
                for(let lspInd=0; lspInd<lCList.length; lspInd++)
                {
                    let lColumn0 = lCList[lspInd];
                    lColumn0.colSpan = lCList.length;
                }
            }

            let lReportData = this.props.pReportData;
            let lReportLayout = 'STANDARD_REPORT_LAYOUT_1';

            if(lReportData.layout !== '')
            {
                lReportLayout = lReportData.layout;
            }

            let lReport = null;
            switch(lReportLayout)
            {
                case 'STANDARD_REPORT_LAYOUT_2':
                    lReport = <CSCOStandardReport1 pReportData={lReportData}/>
                break;

                case 'SUMMARY_REPORT_LAYOUT_1':
                break;

                case 'SUMMARY_REPORT_LAYOUT_2':
                break;

                default:
                break;
            }
               
            this.setState( 
                {
                    mReportData     : this.props.pReportData,
                    mColumns        : lColumns,
                    mPrintFlag      : this.props.pPrintFlag,
                    mColumnChooser  : this.props.pColumnChooser,
                    mDataCollection : this.props.pDataCollection,
                    mReport         : lReport,
                    mHeaderColumns  : lHeaderColumns
                });
        }
    }

    cfClosePrintForm()
    {
        if(this.state.fnClosePrintForm)
        {
            this.setState({ mPrintFlag : false });
            this.state.fnClosePrintForm();
        }
    }
 
    cfCloseColumnChooser(lColumns)
    {
        this.setState({ mColumnChooser : false, mColumns : lColumns});
        this.props.pCloseColumnChooser(lColumns);
    }

    cfExportCSV = () =>
    {
        this.props.fnResetCsvFlag();
        var lSelectedReport = this.props.pReportData;
        if(lSelectedReport && (lSelectedReport.service_code_))
        {
            var lReportName  = lSelectedReport.report_name_ + "_" + fnGetDateToString(new Date());
            var lCriteria = this.props.pCriteria;
            var lServiceCode = lSelectedReport.service_code_;
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd0=0; lInd0<lServiceProperties.length; lInd0++)
                        {
                            lCriteriaObj[lServiceProperties[lInd0].property_] = cfGetConstantValue(lServiceProperties[lInd0].value_);
                        }
                    }
                }
                
                lReportName = lReportName.replace(/ /g,"_");
                lReportName = lReportName + ".csv";
                lCriteriaObj['file_name_'] = lReportName;
                let lHeader = [];
                let lColumnDataFields = [];
                if(this.state.mColumns)
                {
                    CSUtil.cfSort(this.state.mColumns, 'sequence_');
                    for(var lInd=0; lInd<this.state.mColumns.length; lInd++)
                    {
                        let lColumn = this.state.mColumns[lInd];
                        lColumnDataFields.push(lColumn.data_field_);
                        if(lColumn.type_ === 'dynamic_column')
                        {
                            if(lColumn.column_prefix_)
                            {
                                lHeader.push(lColumn.column_prefix_ + ' - ' + lColumn.name_);
                            }
                            else
                            {
                                lHeader.push(lColumn.name_);
                            }
                        }
                        else
                        {
                            lHeader.push(lColumn.name_);
                        }
                    }
                }
                
                lCriteriaObj['report_header_'] = lHeader;
                lCriteriaObj['report_data_fields_'] = lColumnDataFields;
                lCriteriaObj['limit_'] = '1000000';
                this.setState({mShowBdExport : true});

                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfExportCsvFile(() =>
                    {
                        const lLink = document.createElement('a');
                        lLink.href = CSRequestUtil.mServerUrl + "/data/" + lReportName;
                        lLink.setAttribute('download', lReportName);
                        document.body.appendChild(lLink)
                        lLink.click();
                        lLink.parentNode.removeChild(lLink);
                        this.setState({mShowBdExport : false});
                    }
                );
            }
        }
    }

    render()
    {
        let lReport = null;
        let lColumnList = [];
        if (this.state.mColumns) 
        {
            CSUtil.cfSort(this.state.mColumns, 'sequence_');

            this.state.mColumns.map((lColumn, index) => 
                {
                    let lCol = CSUtil.cfGetObjectCopy(lColumn);
                    lCol.column_name_ = lCol.name_;
                    lColumnList.push(lCol);
                    if(lCol.summary_function_ && (lCol.summary_function_ === 'GROUP'))
                    {
                        lCol.grouping_field_ = '1';
                    }

                    return lCol;
                }
            );

            if(this.props.pExportCsv)
            {
                this.cfExportCSV();
            }

            lReport =
                <>
                    <CSCOGrid
                        pData                = { this.props.pDataCollection }
                        pFeatureRefId        = { this.state.mFeatureRefId }
                        pColumns             = { lColumnList }
                        pHeaderColumns       = { this.state.mHeaderColumns }
                        pPageSize             = { 500 }
                        pSortableColumns     = { false }
                        pColumnWidth         = { '120px' }
                        onClick              = { this.props.onDataSelection }
                        pClosePrintForm      = { this.cfClosePrintForm }
                        pPrintFlag           = { this.state.mPrintFlag }
                        pScrollHeight        = { this.state.mHeight }
                        pEnableScroll        = { true}
                        pColumnChooser       = { this.state.mColumnChooser }
                        pCloseColumnChooser  = { this.cfCloseColumnChooser }
                    />
                </>;
        }

        return lReport;
    }
}

export default CSCOReportTable;
