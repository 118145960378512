import React from "react"

import { fnGetForm } from "../../util/CSLayoutUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil"

import CSCOGrid from "../../components/CSCOGrid"
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue } from "../../util/CSConstantUtil";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar";
import {Dialog} from 'primereact/dialog';
import { isArray } from "lodash";
import CSCOBackdrop from "../../components/CSCOBackdrop";
import CSTheme from "../../components/CSTheme";
import {Button} from 'primereact/button';

class CSCODetailsForm20 extends React.Component
{
    mMinSectionItems     = 1;
    mNumSections        = 3;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
       super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mFeatureRefId               : props.pFeatureRefId,
            mAppId                      : props.pAppId,

            mSelectedMenuItem           : this.props.pSelectedMenuItem,
            mFormLayoutHeading          : null,
            mParent                     : this.props.pParent,
            mFormData                   : (this.props.pFormData) ? this.props.pFormData : {},
            mDataCollection             : [],
            mSectionData                : {},
            mSelectedData               : null,
            mSelectedActionItem         : null,
            mFormGrid                   : null,
            mFormActions                : this.props.pFormActions,
            mFormItems                  : this.props.pFormItems,
            mFormProperties             : this.props.pFormProperties,
            mFormValidation             : this.props.pFormValidation,
            mFormSelection              : this.props.pFormSelection,
            mFormActionProperties       : this.props.pFormActionProperties,
            mFormItemProperties         : this.props.pFormItemProperties,
            mFormItemComputation        : this.props.pFormItemComputation,
            mFormDetailsActions         : null,
            mFormItemsSearchActions     : null,
            mFormItemsDetailsActions    : null,
            mGridHeading                : null,
            mAddFlag                    : false,
            mDetailsFlag                : false,
            mDetailsMetaData            : null,
            mSelectedRecord             : null,
            mSectionFormItems           : null,
            mCollectionFormItem         : null,
            mIdFormItemMap              : {},
            mDataFieldFormItemMap       : {},
            mEditableGrid               : true,
            mSaveDataObject             : null,
            mDataFieldObjectMap         : {},
            mChangeFlag                 : false,
            mDisplay                    : this.props.pShow,
            mShowBackdrop               : false,
            mEnableNotification         : false,
            mNotificationParams         : {},

            fnProcessChange             : this.props.fnProcessChange,
            fnProcessSubmit             : this.props.fnProcessSubmit,
            fnProcessClose              : this.props.fnProcessClose
        }

        this.process_search             = this.process_search.bind(this);
        this.process_add                = this.process_add.bind(this);
        this.process_modify             = this.process_modify.bind(this);
        this.process_delete             = this.process_delete.bind(this);
        this.process_details            = this.process_details.bind(this);
        this.process_close              = this.process_close.bind(this);
        this.process_cancel             = this.process_cancel.bind(this);
        this.process_save               = this.process_save.bind(this);

        this.cfGetFormHeading           = this.cfGetFormHeading.bind(this);
        this.cfGetFormItems             = this.cfGetFormItems.bind(this);
        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfCloseForm                = this.cfCloseForm.bind(this);

        this.cfProcessResponse          = this.cfProcessResponse.bind(this);
        this.cfSetFormData              = this.cfSetFormData.bind(this);
        this.cfInitializeLayout         = this.cfInitializeLayout.bind(this);
        this.cfGetFormData              = this.cfGetFormData.bind(this);
        this.cfSearch                   = this.cfSearch.bind(this);
        this.cfOnDataSelection          = this.cfOnDataSelection.bind(this);
        this.cfSetControlValue          = this.cfSetControlValue.bind(this);
        this.cfAddToCollection          = this.cfAddToCollection.bind(this);
        this.cfUpdateCollection         = this.cfUpdateCollection.bind(this);
        this.cfGetRequestObject         = this.cfGetRequestObject.bind(this); 
        this.cfSetDependentFieldData    = this.cfSetDependentFieldData.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            if(this.props.pSelectedData.hasOwnProperty('selected_data_'))
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData.selected_data_;
            }
            else
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData;
            }
        }
        
        if(this.props.pFormProperties)
        {
            var lFormData = this.state.mFormData;
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'initialize')
                {
                    if((lFormProperty.property_ === 'notification_') && lFormProperty.value_)
                    {
                        this.state.mEnableNotification = (lFormProperty.value_ === '1');
                    }
                    else if(((lFormProperty.property_ === 'param_1_') 
                        || (lFormProperty.property_ === 'param_2_') 
                        || (lFormProperty.property_ === 'param_3_')
                        || (lFormProperty.property_ === 'param_4_')) && lFormProperty.value_)
                    {
                        this.state.mNotificationParams[lFormProperty.property_] = lFormProperty.value_;
                    }

                    if(lFormData)
                    {
                        if (lFormProperty.property_ === 'data_')
                        {
                            if(this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                this.state.mSectionData = this.state.mFormData[lFormProperty.value_];
                            }
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            if(this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                this.state.mDataCollection = this.state.mFormData[lFormProperty.value_];
                            }
                            else if(this.props.hasOwnProperty('pCollectionData'))
                            {
                                this.state.mDataCollection = this.props.pCollectionData; 
                                this.state.mFormData[lFormProperty.value_] = this.props.pCollectionData;
                            }
                        }
                    }
                }
                else if (lFormProperty.type_ === 'save_data')
                {
                    if(lFormProperty.property_ === 'data_')
                    {
                        this.state.mSaveDataObject = lFormProperty.value_;
                    }
                }
    	        else if (lFormProperty.type_ === 'layout')
    	        {
    	            if(lFormProperty.property_ === 'max_sections_')
                    {
                        this.mNumSections = lFormProperty.value_;
                    }
    	        }
            }
        }
    }

    componentDidMount()
    {
        this.cfInitializeLayout();
        this.cfGetFormData();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeLayout();
            this.cfGetFormData();
        }
    }

    cfGetDimensions(lId)
    {
        var lHeight;
        if(lId)
        {
            var lElement = document.getElementById(lId);
            if(lElement)
            {
                var lRect = lElement.getBoundingClientRect();
                if(lRect)
                {
                    lHeight = lRect.height
                }
            }
        }

        return lHeight;
    }

    cfGetFormData()
    {
        var lActionItem = this.props.pSelectedActionItem;
        var lCriteria = this.props.pSelectedCriteria;
        if(!lCriteria && (this.props.pSelectedData && this.props.pSelectedData.hasOwnProperty('selected_criteria_')))
        {
            lCriteria = this.props.pSelectedData.selected_criteria_;
        }

        if(this.props.pFormProperties && this.state.mFormData)
        {
            for(var lpInd=0; lpInd<this.props.pFormProperties.length; lpInd++)
            {
                var lProperty = this.props.pFormProperties[lpInd];
                if(lProperty.type_ === 'criteria')
                {
                    if(this.state.mFormData.hasOwnProperty(lProperty.property_) && lProperty.value_)
                    {
                        var lSelData = this.state.mFormData[lProperty.property_]
                        if(lSelData.hasOwnProperty(lProperty.value_))
                        {
                            if(!lCriteria)
                            {
                                lCriteria = {}
                            }
    
                            lCriteria[lProperty.value_] = lSelData[lProperty.value_];
                        }
                    }
                }
            }
        }

        if(lActionItem && lCriteria)
        {
            var lServiceCode = lActionItem.on_click_service_code_;
            var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
            if (lServiceCode || lRequestObject)
            {
                var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                if(lServiceObject)
                {
                    var lRequestId = null;
                    if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                    {
                        lRequestId = lRequestObject.request_id_;
                    }

                    var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                    if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                    {
                        var lServiceProperties = lServiceObject.cs_service_properties_;
                        for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                        {
                            lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                        }
                    }
                    
                    this.setState({mShowBackdrop : true});
                    lRequestUtil.cfSetRequestCriteria(lCriteria);
                    lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                        {
                            if(lResponse)
                            {
                                var lDataObj = this.state.mFormData;
                                if(lRequestObject)
                                {
                                    var lKeys = Object.keys(lResponse);
                                    if(lKeys)
                                    {
                                        for(var lKInd=0; lKInd<lKeys.length; lKInd++)
                                        {
                                            var lKey = lKeys[lKInd];
                                            if((lKey !== 'collection_') && (lKey !== 'result'))
                                            {
                                                lDataObj[lKey] = lResponse[lKey];
                                            }
                                        }
                                    }
                                }
                                else
                                {
                                    if(lResponse.hasOwnProperty('collection_') && (lResponse.collection_.length > 0))
                                    {
                                        var lRespData = lResponse.collection_[0];
                                        lDataObj.collection_ = lResponse.collection_;
                                        var lRespKeys = Object.keys(lRespData);
                                        if(lRespKeys)
                                        {
                                            for(var lRKInd=0; lRKInd<lRespKeys.length; lRKInd++)
                                            {
                                                lDataObj[lRespKeys[lRKInd]] = lRespData[lRespKeys[lRKInd]];
                                            }
                                        }
                                    }
                                }
        
                                if(this.props.pFormProperties && lDataObj)
                                {
                                    var lSectionData = this.state.mSectionData;
                                    var lDataCollection = this.state.mDataCollection;
                                    var lSaveDataObject = this.state.mSaveDataObject;
                                    for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
                                    {
                                        var lFormProperty = this.props.pFormProperties[lInd_0];
                                        if(lFormProperty.type_ === 'initialize')
                                        {
                                            if (lFormProperty.property_ === 'data_')
                                            {
                                                if(lDataObj.hasOwnProperty(lFormProperty.value_))
                                                {
                                                    lSectionData = lDataObj[lFormProperty.value_];
                                                }
                                            }
                                            else if(lFormProperty.property_ === 'collection_data_')
                                            {
                                                if(lDataObj.hasOwnProperty(lFormProperty.value_))
                                                {
                                                    lDataCollection = lDataObj[lFormProperty.value_];
                                                }
                                            }
                                        }
                                        else if (lFormProperty.type_ === 'save_data')
                                        {
                                            if(lFormProperty.property_ === 'data_')
                                            {
                                                lSaveDataObject = lFormProperty.value_;
                                            }
                                        }
                                    }
                                }
                        
                                this.setState(
                                    {
                                        mSectionData    : lSectionData, 
                                        mDataCollection : lDataCollection, 
                                        mSaveDataObject : lSaveDataObject,
                                        mFormData       : lDataObj,
                                        mShowBackdrop   : false
                                    }
                                );
                            }
                        }
                    );
                }
            }
        }
    }

    cfInitializeLayout()
    {
        var lFormTitle = null;
        var lFormGrid = [];
        var lFormItemsSearchActions = [];
        var lFormItemsDetailsActions = [];
        var lFormDetailsActions = [];
        var lGridHeading = null;
        var lDataCollection = null;

        var lSelectedActionItem = this.props.pSelectedActionItem;
        if(lSelectedActionItem)
        {
            lFormTitle = lSelectedActionItem.title_;
        }

        var lFormItemCollection = this.props.pFormItems;
        var lSectionFormItems   = [];
        var lCollectionFormItem = null;
        var lIdFormItemMap   = {};
        var lDataFieldFormItemMap = {};
        var lEditableGrid       = this.state.mEditableGrid;
        var lParentChildrenMap  = {}
        var lParentList = []
        var lFormItemsWithoutParent = []
        var lDataFieldObjectMap = this.state.mDataFieldObjectMap;
        if(!lDataFieldObjectMap)
        {
            lDataFieldObjectMap = {}
        }

        var lFormData = this.state.mFormData;
        if(!lFormData)
        {
            lFormData = {}
        }

        if(lFormItemCollection)
        {
            for (var lItemInd=0; lItemInd<lFormItemCollection.length; lItemInd++)
            {
                var lFormItem = lFormItemCollection[lItemInd];
                if(lFormItem.mValue)
                {
                    lFormItem.mValue = null;
                }

                lIdFormItemMap[lFormItem.id_] = lFormItem;

                lDataFieldFormItemMap[lFormItem.data_field_] = lFormItem;
                if(lFormItem.parent_id_)
                {
                    if((lParentList.indexOf(lFormItem.parent_id_) === -1))
                    {
                        lParentList.push(lFormItem.parent_id_);
                    }
                                    
                    if(!lParentChildrenMap)
                    {
                        lParentChildrenMap = {}
                    }

                    var lChildren = lParentChildrenMap[lFormItem.parent_id_];
                    if(!lChildren)
                    {
                        lChildren = [];
                        lParentChildrenMap[lFormItem.parent_id_] = lChildren;
                    }

                    lChildren.push(lFormItem);
                }
                else
                {
                    if((lFormItem.control_type_ === 'collection') || (lFormItem.control_type_ === 'cs_grid'))
                    {
                        lSectionFormItems.push(lFormItem);
                    }

                    if(!lFormItemsWithoutParent)
                    {
                        lFormItemsWithoutParent = [];
                    }

                    lFormItemsWithoutParent.push(lFormItem);
                }

                if(lFormItem.grid_display_ === '1')
                {
                    lFormItem.column_name_ = lFormItem.display_label_;
                    if(lFormItem.group_ && (lFormItem.group_ === '1'))
                    {
                        lFormItem.grouping_field_ = '1';
                    }

                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                    {
                        for (var lfipInd=0; lfipInd<lFormItem.cs_form_item_properties_.length; lfipInd++)
                        {
                            var lfipObj = lFormItem.cs_form_item_properties_[lfipInd];
                            lFormItem[lfipObj.property_] = lfipObj.value_;
                        }
                    }

                    lFormItem.key = lFormItem.id_;
                    lFormGrid.push(lFormItem);
                }

                if((lFormItem.control_type_ === 'collection') || (lFormItem.control_type_ === 'cs_grid'))
                {
                    lCollectionFormItem = lFormItem;
                    if(lFormItem.display_label_)
                    {
                        lGridHeading = lFormItem.display_label_;
                    }

                    if(this.props.hasOwnProperty('pSelectedData') && this.props.pSelectedData && this.props.pSelectedData.hasOwnProperty('collection_'))
                    {
                        lDataCollection = this.props.pSelectedData.collection_;
                    }
                    else if(lFormData.hasOwnProperty(lFormItem.data_field_))
                    {
                        lDataCollection = lFormData[lFormItem.data_field_];
                    }
                    else if(this.props.hasOwnProperty('pCollectionData') && this.props.pCollectionData)
                    {
                        lDataCollection = this.props.pCollectionData;
                        lFormData[lFormItem.data_field_] = lDataCollection;
                    }
                }
            }

            for(var lpfiIndex=0; lpfiIndex<lParentList.length; lpfiIndex++)
            {
                var lParentFormItem = lIdFormItemMap[lParentList[lpfiIndex]];
                if(!lFormData.hasOwnProperty(lParentFormItem.data_field_))
                {
                    if((lParentFormItem.control_type_ === 'collection') || (lParentFormItem.control_type_ === 'cs_grid'))
                    {
                        lFormData[lParentFormItem.data_field_] = []
                    }
                    else
                    {
                        lFormData[lParentFormItem.data_field_] = {}
                        var lDataObj = lFormData[lParentFormItem.data_field_];

                        var lChildrenList = lParentChildrenMap[lParentList[lpfiIndex]];
                        if(lChildrenList)
                        {
                            for(var lcInd=0; lcInd<lChildrenList.length; lcInd++)
                            {
                                var lChFormItem = lChildrenList[lcInd];
                                lDataFieldObjectMap[lChFormItem.data_field_] = lDataObj;
                                lDataObj[lChFormItem.data_field_] = null;
                            }
                        }
                    }
                }
            }

            for(var lcwpIndex=0; lcwpIndex<lFormItemsWithoutParent.length; lcwpIndex++)
            {
                var lcwpFormItem = lFormItemsWithoutParent[lcwpIndex];
                if(lParentList.indexOf(lcwpFormItem.id_) === -1)
                {
                    lDataFieldObjectMap[lcwpFormItem.data_field_] = this.state.mSectionData;
                }
            }

            for(var lfgIndex=0; lfgIndex<lFormGrid.length; lfgIndex++)
            {
                if(lFormGrid[lfgIndex]['editable_'] === '1')
                {
                    lEditableGrid = true;
                    break;
                }
            }
        }

        var lFormActions = this.props.pFormActions;
        if(lFormActions)
        { 
            for (var lActionInd=0; lActionInd<lFormActions.length; lActionInd++)
            {
                var lFormAction = lFormActions[lActionInd];
                if(lFormAction.component_type_ === 'DETAILS_ACTION')
                {
                    if(lFormAction.parent_ref_id_ && (this.props.pSelectedActionItem) && (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormDetailsActions.push(lFormAction);
                    }
                }
                else if(lFormAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                {
                    if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormItemsSearchActions.push(lFormAction);
                    }
                }
                else if(lFormAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION')
                {
                    if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormItemsDetailsActions.push(lFormAction);
                    }
                }
            }
        }

        if(lFormGrid)
        {
            CSUtil.cfSort(lFormGrid, 'sequence_')            
        }

        if(lFormItemsSearchActions)
        {
            CSUtil.cfSort(lFormItemsSearchActions, 'sequence_')            
        }

        if(lFormDetailsActions)
        {
            CSUtil.cfSort(lFormDetailsActions, 'sequence_')            
        }

        this.setState(
            {
                mFormLayoutHeading          : lFormTitle,
                mFormGrid                   : lFormGrid,
                mFormItemsSearchActions     : lFormItemsSearchActions,
                mFormItemsDetailsActions    : lFormItemsDetailsActions,
                mFormDetailsActions         : lFormDetailsActions,
                mGridHeading                : lGridHeading,
                mSectionFormItems           : lSectionFormItems,
                mCollectionFormItem         : lCollectionFormItem,
                mDataFieldFormItemMap       : lDataFieldFormItemMap,
                mIdFormItemMap              : lIdFormItemMap,
                mEditableGrid               : lEditableGrid,
                mDataCollection             : lDataCollection,
                mFormData                   : lFormData,
                mParentChildrenMap          : lParentChildrenMap,
                mFormItemsWithoutParent     : lFormItemsWithoutParent,
                mDataFieldObjectMap         : lDataFieldObjectMap,
                mDisplay                    : true
            }
        );
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormActions.length; lInd++)
            {
                var lFormAction = this.state.mFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    cfSetFormData(lCollection)
    {
        if(this.props !== null)
        {
            var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
            if(lMenuItem != null)
            {
                var lSearchActionsList = [];
                var lFormActions = this.props.pFormActions;
                if((lFormActions !== undefined) && (lFormActions !== null))
                {
                    for(var ind=0; ind<lFormActions.length; ind++)
                    {
                        if(lFormActions[ind].component_type_ === 'SEARCH_ACTION')
                        {
                            lSearchActionsList.push(lFormActions[ind]);
                        }
                    }
                }

                this.setState(
                    {
                        mFeatureRefId           : this.props.pFeatureRefId,
                        mSelectedMenuItem       : lMenuItem,
                        mSelectedActionItem     : null,
                        mParent                 : this.props.pParent,
                        mFormActions            : this.props.pFormActions,
                        mFormItems              : this.props.pFormItems,
                        mFormGrid               : this.props.pFormGrid,
                        mFormProperties         : this.props.pFormProperties,
                        mFormValidation         : this.props.pFormValidation,
                        mFormSelection          : this.props.pFormSelection,
                        mFormActionProperties   : this.props.pFormActionProperties,
                        mFormGridProperties     : this.props.pFormGridProperties,
                        mFormGridItemProperties : this.props.pFormGridItemProperties,
                        mFormItemProperties     : this.props.pFormItemProperties,
                        mFormItemComputation    : this.props.pFormItemComputation,
                        mSearchActionList       : lSearchActionsList,
                        mDataCollection         : lCollection
                    }
                );
            }
        }
    }

    process_search(lCriteria, lActionItem)
    {
        this.setState({mCriteria : lCriteria, mActionItem : lActionItem});
        if((lActionItem !== undefined) && (lActionItem !== null) && (lActionItem.on_click_service_code_ !== null))
        {
            this.cfSearch(lActionItem.on_click_service_code_, lCriteria);
        }
    }

    cfSearch(lServiceCode, lCriteria)
    {
        if(lServiceCode !== null)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject !== null)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }
                
                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest(this.cfProcessResponse);
            }
        }
    }

    process_add(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pResponseFn             : this.cfAddToCollection,
                pSelectedActionItem     : lActionItem,
                pParentActionItem       : this.props.pSelectedActionItem
            };

            this.setState(
                {
                    mAddFlag             : true,
                    mDetailsMetaData     : lDetailsMetaData,
                    mDetailsFlag         : true,
                    mSelectedActionItem  : lActionItem
                }
            );
        }
    }
    
    cfAddToCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            var lCollection = this.state.mDataCollection;
            if(!lCollection)
            {
                lCollection = [];
            }

            lCollection.push(lRecord);

            this.setState(
                {
                    mDataCollection : lCollection
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }

    process_modify(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }
            
            let lSelectedActionItem = null;
            if(this.state.mFormDetailsActions && event.currentTarget.id)
            {
                for(let laInd=0; laInd<this.state.mFormDetailsActions.length; laInd++)
                {
                    let laAction = this.state.mFormDetailsActions[laInd];
                    if(laAction.id_ === event.currentTarget.id)
                    {
                        lSelectedActionItem = laAction;
                        break;
                    }
                }
            }

            if(!lSelectedActionItem)
            {
                lSelectedActionItem = this.props.pSelectedActionItem;
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pResponseFn             : this.cfUpdateCollection,
                pSelectedActionItem     : lActionItem,
                pParentActionItem       : lSelectedActionItem
            };

            if(this.state.mSelectedRecord || this.state.mSectionData)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = (this.state.mSelectedRecord) ? this.state.mSelectedRecord : this.state.mSectionData;
                this.setState(
                    {
                        mAddFlag             : false,
                        mDetailsMetaData     : lDetailsMetaData,
                        mDetailsFlag         : true,
                        mSelectedData        : lDataObj,
                        mSelectedActionItem  : lActionItem
                    }
                );
            }
        }
    }
    
    cfUpdateCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            this.setState(
                {
                    mSelectedRecord : lRecord
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }
    
    process_details(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = this.state.mSelectedRecord;
                this.setState(
                    {
                        mAddFlag            : false,
                        mDetailsMetaData    : lDetailsMetaData,
                        mDetailsFlag        : true,
                        mSelectedData       : lDataObj,
                        mSelectedActionItem : lActionItem
                    }
                );
            }
        }
    }

    process_delete(event, lParent)
    {
        if(this.state.mParent && this.state.mParent.hasOwnProperty('process_delete'))
        {
            this.state.mParent.process_delete(event, lParent);
        }
    }

    cfProcessResponse(response)
    {
        var lCollection = [];
        if((response != null) && response.hasOwnProperty("result") && response.result.hasOwnProperty("collection_") )
        {
            if( (response.result.collection_ !== undefined) || (response.result.collection_ !== null))
            {
                if(response.result.collection_ instanceof Array)
                {
                    lCollection = response.result.collection_;
                }
                else
                {
                    lCollection.push(response.result.collection_);
                }
            }
        }

        this.cfSetFormData(lCollection);
    }

    cfCloseForm()
    {
        this.setState({ mDetailsFlag : false });
        this.process_close(true, true);
    }

    process_cancel()
    {
        this.setState({ mDetailsFlag : false, mDisplay : false });
    }

    process_close(lCloseFlag, lRefreshFlag)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(lCloseFlag, lRefreshFlag);
        }
    }

    cfOnDataSelection(sel_data)
    {
        if(sel_data)
        {
            this.setState({mSelectedRecord : sel_data});
        }
    }

    cfProcessChange(lData, lRowData, lRowIndex, lValue, lColumn)
    {
        if(lData && this.state.mIdFormItemMap)
        {
            var lChangedFormItem = null;
            var lCompData = null;
            var lFormData = null;
            if(lColumn && lRowData)
            {
                lChangedFormItem = this.state.mIdFormItemMap[lColumn.id_]
                if(lChangedFormItem)
                {
                    lFormData = CSFormUtil.cfSetDependentFieldsData(this.props.pFormItems, lData, lChangedFormItem, lRowData, this.state.mIdFormItemMap);
                    
                    lCompData = CSFormUtil.cfGetComputedFieldsData(lRowData, this.props.pFormItems, this.state.mIdFormItemMap);
                    if(lCompData)
                    {
                        Object.entries(lCompData).map(([lKey, lValue]) => 
                            {
                                if(lCompData.hasOwnProperty(lKey))
                                {
                                    lRowData[lKey] = lValue;
                                }

                                return lRowData;
                            }
                        );
                    }

                    if(lData.control_type_ === 'file')
                    {
                        lChangedFormItem.mValue = lData.value_;
                        lChangedFormItem.mFileList = lData.file_list_;
                    }

                    lFormData = CSFormUtil.cfSetDependentFieldsData(this.props.pFormItems, lData, lChangedFormItem, lRowData, this.state.mIdFormItemMap);
                }
            }
            else
            {
                lChangedFormItem = this.state.mIdFormItemMap[lData.id_];
                if(lChangedFormItem)
                {
                    lFormData = this.state.mDataFieldObjectMap[lChangedFormItem.data_field_];
                    if(lFormData)
                    {
                        lFormData[lData.data_field_] = lData.value_;
                        
                        var lSelectedData = null;
                        if(lData.hasOwnProperty('selected_data_'))
                        {
                            lSelectedData = lData.selected_data_;
                            lChangedFormItem.selected_data_ = lSelectedData;
                        }
                        else
                        {
                            lChangedFormItem.mValue = lData.value_;
                        }

                        if(lData.control_type_ === 'file')
                        {
                            lChangedFormItem.mValue = lData.value_;
                            lChangedFormItem.mFileList = lData.file_list_;
                        }
    
                        lFormData = CSFormUtil.cfSetDependentFieldsData(this.props.pFormItems, lFormData, lChangedFormItem, lSelectedData, this.state.mIdFormItemMap);
                        lCompData = CSFormUtil.cfGetComputedFieldsData(lFormData, this.props.pFormItems, this.state.mIdFormItemMap, this.state.mFormData);
                        if(lCompData)
                        {
                            var lKeys = Object.keys(lCompData);
                            for(var lKind=0; lKind<lKeys.length; lKind++)
                            {
                                var lKey = lKeys[lKind];
                                lFormData[lKey] = lCompData[lKey];
                            }
                        }
                    }
                }    
            }

            this.setState({mChangeFlag : true});
        }
    }
    
    cfSetDependentFieldData(lFormItem)
    {
        if(lFormItem && lFormItem.dependent_id_)
        {
            var lDependentItem = this.state.mIdFormItemMap[lFormItem.dependent_id_];
            if(lDependentItem)
            {
                var lDependentField = (lFormItem.reference_field_) ? lFormItem.reference_field_ : lFormItem.dependent_field_;
                if(lDependentField)
                {
                    var lDataObj = null;
                    lFormItem.dependent_field_ = lDependentField;

                    var lDependentData = null;
                    if(lDependentItem.control_type_ === 'combo_box')
                    {
                        lDependentData = lDependentItem.selected_data_;
                        if( lDependentData && (lDependentData instanceof Object))
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData[lDependentField];
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData[lDependentField];
                                if(!lFormItem.mValue)
                                {
                                    lFormItem.mValue = '0';
                                }

                                lDataObj = this.state.mDataFieldObjectMap[lFormItem.data_field_];
                                if(!lDataObj)
                                {
                                    lDataObj = {}
                                }

                                if(lDataObj.hasOwnProperty(lFormItem.data_field_))
                                {
                                    lDataObj[lFormItem.data_field_] = lFormItem.mValue;
                                }
                            }
                        }
                        else
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData;
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData;
                                if(!lFormItem.mValue)
                                {
                                    lFormItem.mValue = '0';
                                }

                                lDataObj = this.state.mDataFieldObjectMap[lFormItem.data_field_];
                                if(!lDataObj)
                                {
                                    lDataObj = {}
                                }
                                
                                if(lDataObj.hasOwnProperty(lFormItem.data_field_))
                                {
                                    lDataObj[lFormItem.data_field_] = lFormItem.mValue;
                                }
                            }                            
                        }
                    }
                    else
                    {
                        if(lDependentItem.parent_id_)
                        {
                            var lpDataObj = null;
                            var lDependentParentFormItem = this.state.mIdFormItemMap[lDependentItem.parent_id_]
                            if(lDependentParentFormItem)
                            {
                                lpDataObj = this.state.mFormData[lDependentParentFormItem.data_field_];
                                if(lpDataObj && (lpDataObj instanceof Array))
                                {
                                    if (lpDataObj.length > 0)
                                    {
                                        lDependentData = lpDataObj[0][lDependentItem.data_field_];
                                    }
                                }
                                else
                                {
                                    lDependentData = lpDataObj[lDependentItem.data_field_];
                                }
                            }
                            else
                            {
                                lDependentData = this.state.mFormData[lDependentItem.data_field_];
                            }
                        }
                        else
                        {
                            lDependentData = this.state.mFormData[lDependentItem.data_field_];
                        }

                        if(!lDependentData)
                        {
                            lDependentData = lDependentItem.mValue;
                        }

                        if( lDependentData && (lDependentData instanceof Object))
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData[lDependentField];
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData[lDependentField];
                                if(!lFormItem.mValue)
                                {
                                    lFormItem.mValue = '0';
                                }
                                
                                lDataObj = this.state.mDataFieldObjectMap[lFormItem.data_field_];
                                if(!lDataObj)
                                {
                                    lDataObj = {}
                                }
                                
                                if(lDataObj.hasOwnProperty(lFormItem.data_field_))
                                {
                                    lDataObj[lFormItem.data_field_] = lFormItem.mValue;
                                }
                            }
                        }
                        else
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData;
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData;
                                if(!lFormItem.mValue)
                                {
                                    lFormItem.mValue = '0';
                                }

                                lDataObj = this.state.mDataFieldObjectMap[lFormItem.data_field_];
                                if(!lDataObj)
                                {
                                    lDataObj = {}
                                }
                                
                                if(lDataObj.hasOwnProperty(lFormItem.data_field_))
                                {
                                    lDataObj[lFormItem.data_field_] = lFormItem.mValue;
                                }
                            }
                        }
                    }
                }
                else
                {
                    lFormItem.mValue = lDependentData;
                    if(!lFormItem.mValue)
                    {
                        lFormItem.mValue = '0';
                    }

                    lDataObj = this.state.mDataFieldObjectMap[lFormItem.data_field_];
                    if(!lDataObj)
                    {
                        lDataObj = {}
                    }
                    
                    if(lDataObj.hasOwnProperty(lFormItem.data_field_))
                    {
                        lDataObj[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
            }
        }
    }

    cfGetFormItems(lActionId)
    {
        var lAllFormItems = (this.props.pAllFormItems) ? this.props.pAllFormItems : this.props.pFormItems;
        var lFormItems = [];
        if(lActionId)
        {
            /**
             * Check if the Action is Details Action
             */
            let lIsDetailsAction = false;
            if(this.state.mFormDetailsActions)
            {
                for(let laInd=0; laInd<this.state.mFormDetailsActions.length; laInd++)
                {
                    let laAction = this.state.mFormDetailsActions[laInd];
                    if(laAction.id_ === lActionId)
                    {
                        lIsDetailsAction = true;
                        break;
                    }
                }
            }

            if(lIsDetailsAction)
            {
                lFormItems = this.mFormUtil.cfGetFormItems(lAllFormItems, lActionId);
            }
            else
            {
                for(var lInd=0; lInd < lAllFormItems.length; lInd++)
                {
                    var lFormItem = lAllFormItems[lInd];
                    if(lFormItem.parent_id_)
                    {
                        if(lFormItem.dependent_id_)
                        {
                            var lDependentItem = this.state.mIdFormItemMap[lFormItem.dependent_id_];
                            if(!lDependentItem.parent_id_)
                            {
                                lDependentItem.is_dependent_item_ = true;
                                lFormItems.push(lDependentItem);
                            }
                        }

                        lFormItems.push(lFormItem);
                    }
                }
            }
        }
        
        return lFormItems;
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    cfSetControlValue()
    {
        if(this.state && this.props.pFormItems)
        {
            CSUtil.cfSort(this.props.pFormItems, 'parent_id_', 'sequence_');
            for(var ind=0; ind<this.props.pFormItems.length; ind++)
            {
                var lFormItem = this.props.pFormItems[ind];
                var lFormData = this.state.mDataFieldObjectMap[lFormItem.data_field_];
                if(lFormItem.parent_id_)
                {
                    var lParentFormItem = this.state.mIdFormItemMap[lFormItem.parent_id_];
                    if(lParentFormItem)
                    {
                        lFormData = this.state.mFormData[lParentFormItem.data_field_]
                    }
                    else
                    {
                        lFormData = this.state.mSectionData;    
                    }
                }
                else
                {
                    lFormData = this.state.mSectionData;
                }

                if(lFormData && lFormItem.hasOwnProperty('cs_form_item_properties_'))
                {
                    var lProperties = lFormItem.cs_form_item_properties_;
                    var lSequence = '0';
                    var lRequired = '0';
                    for(var lPInd=0; lPInd<lProperties.length; lPInd++)
                    {
                        var lProperty = lProperties[lPInd];
                        if(lProperty.type_ === 'form_item')
                        {
                            if(lProperty.property_ === 'sequence')
                            {
                                if(lFormData.hasOwnProperty(lProperty.selection_property_))
                                {
                                    if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                    {
                                        lFormItem.sequence_ = lProperty.value_;
                                        lSequence = lProperty.value_;
                                    }
                                    else
                                    {
                                        lFormItem.sequence_ = lSequence;
                                    }
                                }
                                else
                                {
                                    lFormItem.sequence_ = lSequence;
                                }
                            }
                            if(lProperty.property_ === 'required')
                            {
                                if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                {
                                    lFormItem.required_ = lProperty.value_;
                                    lRequired = lProperty.value_;
                                }
                                else
                                {
                                    lFormItem.required_ = lRequired;
                                }
                            }
                        }
                    }
                }

                if(lFormItem.control_type_ === 'constant')
                {
                    lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    if(lFormData)
                    {
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
                else if(lFormItem.dependent_id_)
                {
                    this.cfSetDependentFieldData(lFormItem);
                    if(lFormData)
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                    }
                }
                else if(lFormItem.control_type_ === 'check_box')
                {
                    if(lFormItem.constant_ && lFormData && ((lFormItem.constant_ === lFormData[lFormItem.data_field_])))
                    {
                        lFormItem.mValue = '1';
                    }
                    else
                    {
                        lFormItem.mValue = '0';
                    }
                }
                else if(lFormData)
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                        else if(lFormItem.constant_)
                        {
                            lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                        }
                    }
                    else
                    {
                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                    }
                }
                else
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    }
                    else
                    {
                        lFormItem.mValue = '';
                    }
                }
               
                var lCompData = CSFormUtil.cfGetComputedFieldsData(lFormData, this.props.pFormItems, this.state.mIdFormItemMap, this.state.mFormData);
                if(lCompData)
                {
                    var lKeys = Object.keys(lCompData);
                    for(var lKind=0; lKind<lKeys.length; lKind++)
                    {
                        var lKey = lKeys[lKind];
                        if(lFormData.hasOwnProperty(lKey))
                        {
                            lFormData[lKey] = lCompData[lKey];
                        }
                    }
                }                
            }
        }
    }

    process_save(lEvent, lParent, lActionItem)
    {
        this.setState({mSelectedActionItem : lActionItem});
        if(lActionItem)
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            let lFileUploadRetVal = true;
                            var lRequestData = null;
                            if(this.props.pFormItems)
                            {
                                for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
                                {
                                    var lFormItem = this.props.pFormItems[lInd];
                                    if(lFormItem.control_type_ === 'file')
                                    {
                                        if(lFormItem.hasOwnProperty('mFileList') && lFormItem.mFileList)
                                        {
                                            var lRequestUtil = new CSRequestUtil();
                                            lRequestUtil.cfUploadFile(lFormItem.mFileList, lFormItem.constant_, (lResponseData) =>
                                                {
                                                    if(lResponseData instanceof Object)
                                                    {

                                                    }
                                                    else if(lResponseData === -1)
                                                    {
                                                        lFileUploadRetVal = false;
                                                    }
                                                }
                                            )
                                        }
                                    }

                                    if(lFileUploadRetVal)
                                    {
                                        if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                        {
                                            var lRequestObj = this.mFormUtil.fnGetRequestObject(lFormItem, this.state.mDataCollection, this.state.mFormData, lRequestData, this.state.mIdFormItemMap, lActionItem);
                                            if(lRequestObj)
                                            {
                                                if(!lRequestData)
                                                {
                                                    lRequestData = {}
                                                }

                                                if(lFormItem.control_type_ === 'state')
                                                {
                                                    lRequestObj[lFormItem.data_field_] = lActionItem.action_code_;
                                                }
                        
                                                lRequestData[lFormItem.data_field_] = lRequestObj;
                                            }
                                        }
                                    }
                                }
                            }
                    
                            if(lFileUploadRetVal)
                            {
                                if(!lRequestData)
                                {
                                    if(this.state.mSaveDataObject)
                                    {
                                        if(this.state.mFormData.hasOwnProperty(this.state.mSaveDataObject))
                                        {
                                            lRequestData = this.state.mFormData[this.state.mSaveDataObject];
                                        }
                                        else
                                        {
                                            lRequestData = this.state.mFormData;
                                        }
                                    }
                                    else
                                    {
                                        lRequestData = this.state.mFormData;
                                    }

                                    for(var lfInd=0; lfInd<this.props.pFormItems.length; lfInd++)
                                    {
                                        var lfItem = this.props.pFormItems[lfInd];
                                        if(!lRequestData.hasOwnProperty(lfItem.data_field_))
                                        {
                                            if(lfItem.control_type_ === 'state')
                                            {
                                                lRequestData[lfItem.data_field_] = lActionItem.action_code_;
                                            }
                                            else
                                            {
                                                lRequestData[lfItem.data_field_] = lfItem.mValue;
                                            }
                                        }
                                    }
                                }
                        
                                this.setState({mShowBackdrop : true});
                                this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                    (lResponse, lResponseData) =>
                                    {
                                        var lCode = null;
                                        var lMsg = null;
                                        var lCloseFlag = (lActionItem.new_state_ === "list")
        
                                        if(lResponse && lResponse.code)
                                        {
                                            if(lResponse.code instanceof Array)
                                            {
                                                lCode = lResponse.code[0];
                                                if(lResponse.message)
                                                {
                                                    lMsg = lResponse.message[0];
                                                }
                                            }
                                            else
                                            {
                                                lCode = lResponse.code;
                                                if(lResponse.message)
                                                {
                                                    lMsg = lResponse.message;
                                                }
                                            }
    /*
                                            if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
                                            {
                                                lIsEnabled = false;
                                                var lLength = lActionItem.cs_form_action_properties_.length
                                                for(var lFapInd=0; lFapInd<lLength; lFapInd++)
                                                {
                                                    var lFapObj = lActionItem.cs_form_action_properties_[lFapInd];
                                                    if((lFapObj.type_ === 'form_initialize') && (lFapObj.property_ === 'enable_sms') && (lFapObj.value_ === '1'))
                                                    {
                                                        if((lFapObj.selection_property_ === 'TEMPLATE') && (lFapObj.selection_value_ === 'FEE_PMT_SMS'))
                                                        {
                                                        }
                                                    }
                                                }
                                            }
    */                                  }
                                        else
                                        {
                                            lCode = -1;
                                        }
            
                                        var lPostSaveData = this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                        if((lCode === 1) || (lCode === '1'))
                                        {
                                            var lSelectedData = null;
                                            if(this.state.mEnableNotification && this.state.mFormData)
                                            {
                                                lSelectedData = this.state.mFormData['selected_data_'];
                                                var lParam1 = null;
                                                var lParam2 = null;
                                                var lParam3 = null;
                                                var lParam4 = null;
                                                if(lSelectedData)
                                                {
                                                    if(this.state.mNotificationParams['param_1_'] && lSelectedData.hasOwnProperty(this.state.mNotificationParams['param_1_']))
                                                    {
                                                        lParam1 = lSelectedData[this.state.mNotificationParams['param_1_']];
                                                    }
                                                    
                                                    if(this.state.mNotificationParams['param_2_'] && lSelectedData.hasOwnProperty(this.state.mNotificationParams['param_2_']))
                                                    {
                                                        lParam2 = lSelectedData[this.state.mNotificationParams['param_2_']];
                                                    }
                                                    
                                                    if(this.state.mNotificationParams['param_3_'] && lSelectedData.hasOwnProperty(this.state.mNotificationParams['param_3_']))
                                                    {
                                                        lParam3 = lSelectedData[this.state.mNotificationParams['param_3_']];
                                                    }
                                                    
                                                    if(this.state.mNotificationParams['param_4_'] && lSelectedData.hasOwnProperty(this.state.mNotificationParams['param_4_']))
                                                    {
                                                        lParam4 = lSelectedData[this.state.mNotificationParams['param_4_']];
                                                    }
                                                }

                                                if(lParam1 && lParam2 && lParam3 && lParam4)
                                                {                                                    
                                                    new CSRequestUtil().cfSmsNotification(lParam1, lParam2, lParam3, lParam4, (lResult)=>
                                                        {
                                                        }
                                                    )
                                                }                       
                                            }

                                            var lNavigateToForm = lActionItem.navigate_to_form_;
                                            if( lNavigateToForm && lPostSaveData && this.state.mFormData)
                                            {
                                                if(!lSelectedData)
                                                {
                                                    lSelectedData = this.state.mFormData['selected_data_'];
                                                }

                                                var lSelectedCriteria = null;
                                                var lDetailsMetaData = 
                                                {
                                                    pParent                 : this,
                                                    pFormType               : lActionItem.navigate_to_form_component_code_,
                                                    pFormHeading            : lActionItem.title_,
                                                    pFormActions            : this.mFormUtil.cfGetChildActionItems(this.props.pFormActions, lActionItem.id_),
                                                    pFormItems              : this.mFormUtil.cfGetFormItems(this.props.pFormItems, lActionItem.id_),
                                                    pFormProperties         : this.mFormUtil.cfGetFormProperties(this.props.pFormProperties, lActionItem.navigate_to_form_),
                                                    pSelectedActionItem     : lActionItem,
                                                    pSelectedData           : lSelectedData,
                                                    pSelectedCriteria       : lSelectedCriteria,
                                                    pCriteria               : lSelectedCriteria,
                                                    fnProcessClose          : this.process_close,
                                                    pParentFlag             : true
                                                };

                                                for(var lFv_0=0; lFv_0<lPostSaveData.length; lFv_0++)
                                                {
                                                    var lP1Obj = lPostSaveData[lFv_0];
                                                    if(lP1Obj.data_field_1_type_ && lP1Obj.data_field_1_)
                                                    {
                                                        if(lP1Obj.data_field_1_type_ === 'data')
                                                        {
                                                            if(lP1Obj.data_object_ && this.state.mFormData.hasOwnProperty(lP1Obj.data_object_))
                                                            {
                                                                lDetailsMetaData[lP1Obj.data_field_1_] = this.state.mFormData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                        else if(lP1Obj.data_field_1_type_ === 'collection')
                                                        {
                                                            if(lP1Obj.data_object_ && this.state.mFormData.hasOwnProperty(lP1Obj.data_object_))
                                                            {
                                                                lDetailsMetaData[lP1Obj.data_field_1_] = this.state.mFormData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                        else if(lP1Obj.data_field_1_type_ === 'response')
                                                        {
                                                            if(lP1Obj.data_object_ && lP1Obj.data_field_2_ && lResponseData.hasOwnProperty(lP1Obj.data_object_))
                                                            {                                                           
                                                                if(lDetailsMetaData.hasOwnProperty(lP1Obj.data_field_1_))
                                                                {
                                                                    var lSelData1 = lDetailsMetaData[lP1Obj.data_field_1_];
                                                                    var lRespVal = lResponseData[lP1Obj.data_object_];
                                                                    if(lRespVal && lRespVal.hasOwnProperty(lP1Obj.data_field_2_) && isArray(lRespVal[lP1Obj.data_field_2_]))
                                                                    {
                                                                        lSelData1[lP1Obj.data_field_2_] = lRespVal[lP1Obj.data_field_2_][0];
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                if(lSelectedData)
                                                {
                                                    for(var lFv_1=0; lFv_1<lPostSaveData.length; lFv_1++)
                                                    {    
                                                        var lP2Obj = lPostSaveData[lFv_1];
                                                        if(lP2Obj.data_field_1_type_ === 'criteria')
                                                        {
                                                            if(lP2Obj.data_field_1_ && lSelectedData.hasOwnProperty(lP2Obj.data_field_1_))
                                                            {
                                                                if(!lSelectedCriteria)
                                                                {
                                                                    lSelectedCriteria = {}
                                                                }

                                                                lSelectedCriteria[lP2Obj.data_field_1_] = lSelectedData[lP2Obj.data_field_1_]
                                                            }
                                                        }
                                                    }
                                                }
                            
                                                this.setState({mDetailsMetaData : lDetailsMetaData, mDetailsFlag : 'DETAILS'});
                                            }
                                            else
                                            {
                                                this.process_close(lCloseFlag, true);
                                            }
                                        }

                                        this.setState({mShowBackdrop : false});
                                    }
                                );
                            }
                        }
                    }
                }
            );
        }
    }

    cfGetRequestObject(lFormItem, lActionItem)
    {
        var lRetObj;
        var lFormItemProperties = lFormItem.cs_form_item_properties_;
        if(lFormItemProperties)
        {
            if(lFormItem.control_type_ !== 'collection')
            {
                for(var lfipInd=0; lfipInd<lFormItemProperties.length; lfipInd++)
                {
                    var lfipObj = lFormItemProperties[lfipInd];
                    if(lfipObj.type_ === 'request_data')
                    {
                        if(lfipObj.property_ === '#CONSTANT#')
                        {
                            if(lFormItem.control_type_ !== 'collection')
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = {}
                                }
            
                                lRetObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                            }
                            else
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = this.state.mDataCollection;
                                }
            
                                if(lRetObj)
                                {
                                    for(var lcInd=0; lcInd<lRetObj.length; lcInd++)
                                    {
                                        var lcObj = lRetObj[lcInd];
                                        lcObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                                    }
                                }
                            }
                        }
                        else if(lfipObj.property_ === 'form_data')
                        {
                            var lDataObj;
                            if((lfipObj.value_ === 'section_data_') && this.state.mSectionData)
                            {
                                lDataObj = this.state.mSectionData;
                            }
                            else if((lfipObj.value_ === 'selected_data_') && this.state.mSelectedData)
                            {
                                lDataObj = this.state.mSelectedData;
                            }

                            if(lDataObj)
                            {
                                if(lFormItem.control_type_ !== 'collection')
                                {
                                    if(!lRetObj)
                                    {
                                        lRetObj = {}
                                    }

                                    lRetObj[lfipObj.selection_property_] = lDataObj[lfipObj.selection_value_];
                                }
                                else
                                {
                                    if(!lRetObj)
                                    {
                                        lRetObj = this.state.mDataCollection;
                                    }
                
                                    if(lRetObj)
                                    {
                                        for(var lcInd0=0; lcInd0<lRetObj.length; lcInd0++)
                                        {
                                            var lcObj0 = lRetObj[lcInd0];
                                            lcObj0[lfipObj.selection_property_] = lDataObj[lfipObj.selection_value_];
                                        }
                                    }
                                }
                            }
                        }
                        else if(lfipObj.property_ === 'state')
                        {
                            var lStateValue = lActionItem.action_code_;
                            if(lFormItem.control_type_ !== 'collection')
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = {}
                                }
            
                                lRetObj[lfipObj.selection_property_] = lStateValue;
                            }
                            else
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = this.state.mDataCollection;
                                }
            
                                if(lRetObj)
                                {
                                    for(var lcInd1=0; lcInd1<lRetObj.length; lcInd1++)
                                    {
                                        var lcObj1 = lRetObj[lcInd1];
                                        lcObj1[lfipObj.selection_property_] = lStateValue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else
            {
                if(!lRetObj)
                {
                    lRetObj = [];
                    if(this.state.mDataCollection)
                    {
                        for(var ldInd=0; ldInd<this.state.mDataCollection.length; ldInd++)
                        {
                            var lnObj = null;
                            var ldObj = this.state.mDataCollection[ldInd];
                            var lDObj = null;
                            for(var lfipInd1=0; lfipInd1<lFormItemProperties.length; lfipInd1++)
                            {
                                var lfipObj1 = lFormItemProperties[lfipInd1];
                                if(!lfipObj1.value_)
                                {
                                    lDObj = ldObj;
                                }
                                else if((lfipObj1.value_ === 'section_data_') && this.state.mSectionData)
                                {
                                    lDObj = this.state.mSectionData;
                                }
                                else if((lfipObj1.value_ === 'selected_data_') && this.state.mSelectedData)
                                {
                                    lDObj = this.state.mSelectedData;
                                }

                                if(!lnObj)
                                {
                                    lnObj = {}
                                }

                                if(lDObj && lDObj[lfipObj1.selection_value_])
                                {
                                    lnObj[lfipObj1.selection_property_] = lDObj[lfipObj1.selection_value_];
                                }
                                else
                                {
                                    lnObj[lfipObj1.selection_property_] = '';
                                }
                            }

                            if(lnObj)
                            {
                                lRetObj.push(lnObj);
                            }
                        }
                    }                        
                }
            }
        }

        return lRetObj;
    }

    render()
    {
        this.cfSetControlValue();

        var lHeaderSection = null;
        var lSummaryRightSection = null;
        var lSummaryBottomSection = null;
        var lFormDetailsItemSearchActions = null;
        var lFormDetailsItemActionsList = null;
        if(this.props.pFormItems && (this.props.pFormItems.length > 0))
        {
            var lHeaderFormItems = [];
            var lSummaryRightFormItems = [];
            var lSummaryBottomFormItems = [];

            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                if(lFormItem.cs_form_component_code_)
                {
                    if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_HEADING_SECTION_1')
                    {
                        lHeaderFormItems.push(lFormItem);
                    }
                    else if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_SUMMARY_2')
                    {
                        lSummaryRightFormItems.push(lFormItem);
                    }
                    else if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_SUMMARY_1')
                    {
                        if(lFormItem.visible_ === '1')
                        {
                            lSummaryBottomFormItems.push(lFormItem);
                        }
                    }
                }
            }

            /**
             * Header Items
             */
            if(lHeaderFormItems.length > 0)
            {
                CSUtil.cfSort(lHeaderFormItems, 'sequence_');
                lHeaderSection = this.mFormUtil.cfGetDetailsSections(lHeaderFormItems, this.mMinSectionItems, this.cfProcessChange, "form_criteria_label", null, this.mNumSections);
            }

            /**
             * Summary Right Items
             */
            if(lSummaryRightFormItems.length > 0)
            {
                CSUtil.cfSort(lSummaryRightFormItems, 'sequence_');
                lSummaryRightSection = 
                        <div className="form-item-6">
                            { this.mFormUtil.cfGetDetailsSections(lSummaryRightFormItems, this.mMinSectionItems, this.cfProcessChange, "form_criteria_label", null, 1) }
                        </div>
            }

            /**
             * Summary Bottom Items
             */
            if(lSummaryBottomFormItems.length > 0)
            {
                CSUtil.cfSort(lSummaryBottomFormItems, 'sequence_');
                lSummaryBottomSection = 
                    <div className="form-item-4">
                        { this.mFormUtil.cfGetDetailsSections(lSummaryBottomFormItems, this.mMinSectionItems, this.cfProcessChange, "form_criteria_label", null, 3) }
                    </div>
            }
        }

        var lDetailsForm = null;
        if(this.state.mDetailsFlag)
        {
            var lDetailsData = (this.state.mAddFlag) ? null : (this.state.mSelectedData ? this.state.mSelectedData : this.state.mSectionData);
            lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.process_close);
        }

        if(this.props.pFormItemsSearchActions && (this.props.pFormItemsSearchActions.length > 0))
        {
            var lFormDetailsItemSearchActionList = this.props.pFormItemsSearchActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormDetailsItemSearchActions = 
                <CSCOBaseActionBar
                    align       = "left"
                    pType       = 'SEARCH_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormDetailsItemSearchActionList}
                />;
        }

        if(this.state.mFormDetailsActions && (this.state.mFormDetailsActions.length > 0))
        {
            for(var lDAItemInd=0; lDAItemInd<this.state.mFormDetailsActions.length; lDAItemInd++)
            {
                var lDetailsActionItem = this.state.mFormDetailsActions[lDAItemInd];
                if(CSFormUtil.cfIncludeAction(lDetailsActionItem, this.state.mSectionData))
                {
                    if(lDetailsActionItem.action_type_ === '1')
                    {
                        var lEnable = true;
                        for(var lfiIndex=0; lfiIndex<this.props.pFormItems.length; lfiIndex++)
                        {
                            var lfItem = this.props.pFormItems[lfiIndex];
                            if(lfItem.required_ === '1')
                            {
                                var lDataObj = this.state.mDataFieldObjectMap[lfItem.data_field_];
                                if(lDataObj && lDataObj[lfItem.data_field_])
                                {
                                    lEnable = true;
                                }
                                else
                                {
                                    if(lfItem.constant_)
                                    {
                                        lDataObj[lfItem.data_field_] = cfGetConstantValue(lfItem.constant_);
                                        lEnable = true;
                                    }
                                    else
                                    {
                                        lEnable = false;
                                        break;
                                    }
                                }
                            }
                        }

                        lDetailsActionItem.mEnabled = lEnable;
                        if(lEnable)
                        {
                            if(this.props.pFormProperties)
                            {
                                var lEnableAction = true;
                                for(var lfpInd=0; lfpInd < this.props.pFormProperties.length; lfpInd++)
                                {
                                    var lfProperty = this.props.pFormProperties[lfpInd];
                                    if((lfProperty.type_ === 'save_data') && (lfProperty.property_ === 'VALIDATE') && lfProperty.value_)
                                    {
                                        var ldObj = this.state.mFormData[lfProperty.value_];
                                        if(ldObj)
                                        {
                                            lEnableAction = CSFormUtil.cfEnableAction(lDetailsActionItem, ldObj, this.props.pFormItems);
                                            if(!lEnableAction)
                                            {
                                                break;
                                            }                                            
                                        }
                                    }
                                }

                                lDetailsActionItem.mEnabled = lEnableAction;
                            }
                        }

//                        lDetailsActionItem.mEnabled = CSFormUtil.cfEnableAction(lDetailsActionItem, this.state.mFormData, this.props.pFormItems);
//                        lDetailsActionItem.mEnabled = lEnable;
                        if(lDetailsActionItem.mEnabled)
                        {
                            if(this.state.mCollectionFormItem && (this.state.mCollectionFormItem.required_ === '1'))
                            {
                                lDetailsActionItem.mEnabled = (this.state.mDataCollection && (this.state.mDataCollection.length > 0));
                            }
                        }
                    }
                    else
                    {
                        lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                    }

                    if(!lFormDetailsItemActionsList)
                    {
                        lFormDetailsItemActionsList = [];
                    }
                    
                    lFormDetailsItemActionsList.push(lDetailsActionItem);
                }
            }
        }

        var lHeaderHeight = this.cfGetDimensions("ID_HEADER");
        var lActionBarHeight = this.cfGetDimensions("ID_DETAILS_ACTIONS");
        var lScrollHeight = (CSUtil.cfGetInstance()).mApplicationHeight - 50;
    
        if(lActionBarHeight)
        {
            lScrollHeight = lScrollHeight - lActionBarHeight;
        }
        else 
        {
            lScrollHeight = lScrollHeight - 60;
        }

        if(lHeaderHeight)
        {
            lScrollHeight = lScrollHeight - lHeaderHeight;
        }
        else
        {
            lScrollHeight += 4;
        }

        var  lPreviewForm = null;
        if(this.props.pEnablePrint && this.props.pPrintForm)
        {
            var lCriteria = null;
            if(this.props.pSelectedCriteria)
            {
                lCriteria = this.props.pSelectedCriteria;
            }
            else if(this.props.pFormData && this.props.pFormData.hasOwnProperty('selected_criteria_'))
            {
                lCriteria = this.props.pFormData.selected_criteria_;
            }
            else if(this.props.pSelectedData && this.props.pSelectedData.hasOwnProperty('selected_criteria_'))
            {
                lCriteria = this.props.pSelectedData.selected_criteria_;
            }

            var lFormProperties = null;
            if(this.props.pFormProperties)
            {
                for(var lfpInd0=0; lfpInd0<this.props.pFormProperties.length; lfpInd0++)
                {
                    var lfpObj = this.props.pFormProperties[lfpInd0];
                    if(lfpObj.cs_form_code_ === this.props.pPrintForm)
                    {
                        if(!lFormProperties)
                        {
                            lFormProperties = [];
                        }

                        lFormProperties.push(lfpObj);
                    }
                }
            }

            var lMetaData = 
            {
                pFormProperties : lFormProperties,
                pFormType       : this.props.pPrintForm,
                fnEnablePrint   : this.props.fnEnablePrint,
                pCriteria       : lCriteria
            }

            lPreviewForm = fnGetForm(lMetaData);
        }

        var lDetailsActions = null;
        if(lFormDetailsItemActionsList)
        {
            lDetailsActions = 
                <CSCOHorizontalActionBar 
                    id          = 'ID_DETAILS_ACTIONS'
                    align       = "left"
                    pType       = 'DETAILS_ACTION_BAR'
                    parent      = { this }
                    actionList  = { lFormDetailsItemActionsList }
                />
        }

        let lGrid = null;
        if(lSummaryRightSection)
        {
            lGrid = 
                <div className="form-item-5">
                    <CSCOGrid  
                        pData               = { this.state.mDataCollection }
                        pFeatureRefId       = { this.state.mFeatureRefId }
                        pColumns            = { this.state.mFormGrid }
                        rowIndex            = { this.state.mRowIndex } 
                        pHeading            = { this.state.mGridHeading }
                        OnItemChange        = { this.cfProcessChange }
                        pAlignHeading       = "left"
                        pageSize            = { 15 }
                        pEditableGrid       = { this.state.mEditableGrid }
                        onClick             = { this.cfOnDataSelection }
                        pSearchActionBar    = { lFormDetailsItemSearchActions }
                        pSortableColumns    = { false }
                        pScrollHeight       = { lScrollHeight }
                        pEnableScroll       = { true }
                        pHeight             = "100%"
                        pMinHeight          = "250px"
                    />

                    {lSummaryRightSection}
                </div>
        }
        else
        {
            lGrid = 
                <CSCOGrid  
                        pData               = { this.state.mDataCollection }
                        pFeatureRefId       = { this.state.mFeatureRefId }
                        pColumns            = { this.state.mFormGrid }
                        rowIndex            = { this.state.mRowIndex } 
                        pHeading            = { this.state.mGridHeading }
                        OnItemChange        = { this.cfProcessChange }
                        pAlignHeading       = "left"
                        pageSize            = { 15 }
                        pEditableGrid       = { this.state.mEditableGrid }
                        onClick             = { this.cfOnDataSelection }
                        pSearchActionBar    = { lFormDetailsItemSearchActions }
                        pSortableColumns    = { false }
                        pScrollHeight       = { lScrollHeight }
                        pEnableScroll       = { true }
                        pHeight             = "100%"
                        pMinHeight          = "250px"
                    />
        }
        
        let lHeaderActions = 
            <div style={{verticalAlign: 'middle'}}>            


                <Button
                    key = "21"
                    style=
                        {
                            {
                                verticalAlign   : 'middle'
                            }
                        } 
                    type="Button" 
                    icon={CSUtil.mNameIconMap['PI_TIMES']}
                    onClick= { () => this.process_close(false, true) }
                />
            </div>

        let lHeader = 
            <div className='flex justify-content-between'>
                <div className="p-toolbar-group-left">
                    <h2>{ this.state.mFormLayoutHeading || '.' }</h2>
                </div>

                <div className="p-toolbar-group-right">
                    { lHeaderActions }
                </div>
            </div>


        return(
            <Dialog visible={this.state.mDisplay}
                modal={true}
                style=
                {
                    {
                        minWidth       : 'auto',
                        maxWidth       : '1200px',
                        minheight      : 'auto', 
                        paddingBottom  : -40,
                    }
                }
                minheight   = 'auto'
                appendTo    = { document.body }
                header      = {lHeader}
                closable    = { false }
                onHide      = {() => this.process_close(false, true)}
                footer      = { lDetailsActions }>
                    
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>
                    
                <div className="form-item-3" id='ID_HEADER'>
                    {lHeaderSection}
                </div>

                { lGrid }

                { lSummaryBottomSection }

                { lDetailsForm }

                { lPreviewForm }
            </Dialog>
        );
    }
}

export default CSCODetailsForm20;
