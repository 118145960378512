import React from 'react';

import CSCOVerticalSection from './CSCOVerticalSection'
import CSUtil from "../util/CSUtil";
import CSFormUtil from "../util/CSFormUtil"

const mFormUtil         = CSFormUtil.cfGetInstance();
const mMaxSectionItems  = 1;
const mNumSections 		= 3;

const CSCODetailsHeader = (lProps) =>
{
    const cfGetSections = (lFormItemList) =>
    {
        let lSections = [];
        let lNumSections = 1;
        let lMaxSections = 3;
        let lNumItemsPerSection = 0;
        if(lFormItemList)
        {
            let lSectionNumControlMap = {};
            let lMaxSectionItems = mMaxSectionItems;
            let lPicFormItem = null;
            CSUtil.cfSort(lFormItemList, 'sequence_')
    
            for(var ind_0=0; ind_0<lFormItemList.length; ind_0++)
            {
                var lPFormItem = lFormItemList[ind_0];
                if(lPFormItem.control_type_ === 'image')
                {
                    lPicFormItem = lPFormItem;
                    lPicFormItem.pEnableBrowse       = true;
                    lPicFormItem.pEnableCamera       = true;
                    break;
                }
            }

            if(lProps.pFormProperties)
            {
                for(let pInd=0; pInd<lProps.pFormProperties.length; pInd++)
                {
                    let lProperty = lProps.pFormProperties[pInd];
                    if(lProperty && (lProperty.property_ === 'max_sections_'))
                    {
                        lMaxSections = Number(lProperty.value_);
                    }
                }
            }

            if(lPicFormItem)
            {
                if(lFormItemList.length === 1)
                {
                    if(mNumSections >= 1)
                    {
                        lNumSections = 1;
                    }
                }
                else if(lFormItemList.length <= mMaxSectionItems)
                {
                    lNumSections = 2;
                }
                else if ((lFormItemList.length / 2) <= mMaxSectionItems)
                {
                    lNumSections = 3;
                }
                else
                {
                    lNumSections = 4;
                }
    
                lNumItemsPerSection = lFormItemList.length/(lNumSections - 1);
            }
            else
            {
                if(lFormItemList.length <= mMaxSectionItems)
                {
                    lNumSections = 1;
                }
                else if ((lFormItemList.length / 2 ) <= mMaxSectionItems)
                {
                    lNumSections = 2;
                }
                else if ((lFormItemList.length / 3 ) <= mMaxSectionItems)
                {
                    lNumSections = 3;
                }
                else
                {
                    lNumSections = lMaxSections;
                }
             
                lNumItemsPerSection = lFormItemList.length/lNumSections;
            }
    
            for(var ind=0; ind<lFormItemList.length; ind++)
            {
                var lFormItem = lFormItemList[ind];
                if(lFormItem.control_type_ !== 'image')
                {
                    var lSequence = parseInt(lFormItem.sequence_, 10);
                    var lIndex = Math.ceil(lSequence/lNumItemsPerSection) - 1;
                    var lActIndex = lIndex;
    
                    if(ind === 0)
                    {
                        if(lPicFormItem)
                        {
                            var lPicSectionControlList = [];
                            lSectionNumControlMap[lActIndex] = lPicSectionControlList;
    
                            var lCtrl = mFormUtil.cfGetControl(lPicFormItem, lProps.pProcessChange);
                            if(lCtrl)
                            {
                                lPicSectionControlList.push(lCtrl)
                            }
                            else
                            {
                                lPicFormItem = null;
                            }                        
                        }
                    }
    
                    if(lPicFormItem)
                    {
                        lActIndex = lIndex + 1;
                    }
    
                    var lSectionControlList = null;
                    lSectionControlList = lSectionNumControlMap[lActIndex];
                    if(!lSectionControlList)
                    {
                        lSectionControlList = [];
                        lSectionNumControlMap[lActIndex] = lSectionControlList;
                    }
        
                    lSectionControlList.push(mFormUtil.cfGetControl(lFormItem, lProps.pProcessChange, null, null, null))
                    lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList.length);
                }
            }
    
            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FFFFFF";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }
    
                var lImageSectionWidth = null;
                if(lPicFormItem)
                {
                    lImageSectionWidth = "130px";
                }

                lSection = <CSCOVerticalSection 
                    pBackgroundColor    = {lBkgColor} 
                    pId                 = {lSectionNum} 
                    key                 = {lSectionNum}
                    pImageSection       = {false}
                    pNumSections        = {lNumSections}
                    pMaxSectionItems    = {lMaxSectionItems}
                    pImageSectionWidth = {lImageSectionWidth}
                    pIsCriteriaSection  = {false}
                    pChildren           = {lSectionNumControlMap[lSectionNum]}/>
    
                lSections.push(lSection);
            }
        }
    
        return lSections;
    }

    let lForm = null;
    if(lProps.pFormItems && (lProps.pFormItems.length > 0))
    {
        lForm = 
            <div className="fluid flex">
                { cfGetSections(lProps.pFormItems) }
            </div>
    }
        
    return lForm;
}

export default CSCODetailsHeader;
