import React from "react"
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import CSBaseControl from '../controls/CSBaseControl'

const google = window.google;

export default class CSCOGoogleMap extends CSBaseControl
{
    constructor(props) 
    {
        super(props);

        this.state = 
        {
            mOverlays         : null,
            mSelectedPosition : null
        };

        this.onMapClick     = this.onMapClick.bind(this);
        this.onMapReady     = this.onMapReady.bind(this);
    }

    componentDidMount(){}

    componentDidUpdate(lPrevProps, lPrevState){
        if(lPrevProps !== this.props)
        {
        }
    }

    onMapClick(lEvent) 
    {
        this.setState(
            {
                mSelectedPosition: lEvent.latLng
            }
        );
    }
    
    onMapReady(lEvent) 
    {
        if(this.props && this.props.pLattitude && this.props.pLongitude && this.props.pTitle && google)
        {
            this.setState({
                mOverlays: [
                    new window.google.maps.Marker({ position: { lat : this.props.pLattitude, lng : this.props.pLongitude }, title : this.props.pTitle }),
                ]
            })
        }
    }

    render()
    {
        const lOptions = 
        {
            center: {lat : this.props.pLattitude, lng : this.props.pLongitude},
            zoom: 12
        }

        var lMap = null;
        if(google)
        {
            lMap = 
                <GoogleMap 
                    overlays          = { this.state.mOverlays} 
                    options           = { lOptions}
                    style             = {{ width: '100%', minheight: '320px' }} 
                    onMapReady        = { this.onMapReady}
                    onMapClick        = { this.onMapClick} />
        }
        
        return lMap;
    }
};
