import CSDropDown from '../../controls/CSDropDown';
import CSCOBarChart from "../../layout/analysis/CSCOBarChart";
import { useEffect, useState } from 'react';
import CSServiceUtil from '../../util/CSServiceUtil';
import CSRequestUtil from '../../util/CSRequestUtil';
import CSConfigUtil from '../../util/CSConfigUtil';
import CSCOBackdrop from "../../components/CSCOBackdrop";

const MarginMetrics = (lProps) => {
    const [mSelectedFYCode, setSelectedFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mSelectedFYObj, setSelectedFYObj] = useState(CSConfigUtil.mActiveFinancialYearObj);

    const [mSelectedCompanyCode, setSelectedCompanyCode] = useState(CSConfigUtil.mCompanyCode);
    const [mSelectedCompanyObj, setSelectedCompanyObj] = useState(CSConfigUtil.mCompanyObj);

    const [mRequestId, setRequestId] = useState(null);
    const [mChartIdObjectMap, setChartIdObjectMap] = useState(null);

    const [mselectedFrequencyCode, setselectedFrequencyCode] = useState(null);
    const [mselectedFrequencyObj, setSelectedFrequencyObj]   = useState(null);

    const [mMarginData, setMarginData] = useState(null);
    const [mShowBackdrop, setShowBackdrop] = useState(false);

    useEffect(() => {
            let lChartIdObjMap = {};
            let lChartRefIdObjMap = {};

            if(lProps.pReportChartProperties)
            {
                for(let lInd=0; lInd<lProps.pReportChartProperties.length; lInd++)
                {
                    let propertyObj = lProps.pReportChartProperties[lInd];
                    if(propertyObj.property_name_ === 'request_id_')
                    {
                        setRequestId(propertyObj.value_);
                    }
                    else if(propertyObj.property_name_ === 'chart_id_')
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.id_];
                        if(!lcpObj)
                        {
                            lcpObj = {};
                            lChartRefIdObjMap[propertyObj.id_] = lcpObj;
                        }

                        lcpObj[propertyObj.property_name_] = propertyObj.value_;
                        lChartIdObjMap[propertyObj.value_] = lcpObj;
                    }
                    else if(propertyObj.parent_ref_id_)
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.parent_ref_id_];
                        if(lcpObj)
                        {
                            if((propertyObj.property_name_ === 'chart_data_field_') || (propertyObj.property_name_ === 'chart_data_label_'))
                            {
                                if(propertyObj.property_name_ === 'chart_data_field_')
                                {
                                    let lChartDataFieldList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataFieldList)
                                    {
                                        lChartDataFieldList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataFieldList;
                                    }

                                    lChartDataFieldList.push(propertyObj.value_);
                                }
                                else
                                {
                                    let lChartDataLabelList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataLabelList)
                                    {
                                        lChartDataLabelList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataLabelList;
                                    }

                                    lChartDataLabelList.push(propertyObj.value_);
                                }
                            }
                            else
                            {
                                lcpObj[propertyObj.property_name_] = propertyObj.value_; 
                            }
                        }
                    }
                }

                setChartIdObjectMap(lChartIdObjMap);
            }
        }, []
    );

    function fnGetReportData(lFyCode, lCompanyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && (lFyCode || lCompanyCode))
            {
                setShowBackdrop(true);
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;
                lCriteria.company_code_ = lCompanyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lObj = mChartIdObjectMap['ID_MONTHLY_MARGIN'];
                            let lDataObj = null;
                            if(lObj && lResponse.hasOwnProperty(lObj.data_field_))
                            {
                                lDataObj = lResponse[lObj.data_field_];
                            }
                        
                            setMarginData(lDataObj);
                            setShowBackdrop(false);
                        }
                    }
                );
            }
        }
    }

    useEffect(() => {
            fnGetReportData(CSConfigUtil.mCurrentFYCode, CSConfigUtil.mCompanyCode);
        }, [mChartIdObjectMap]
    );

    function fnProcessChange(lData)
    {
        if(lData)
        {
            let lFyCode = mSelectedFYCode;
            let lCompanyCode = mSelectedCompanyCode;

            if(lData.id_ === 'ID_FY_CODE')
            {
                setSelectedFYObj(lData);
                setSelectedFYCode(lData.value_);
                lFyCode = lData.value_;
            }
            else if(lData.id_ === 'ID_COMPANY')
            {
                setSelectedCompanyObj(lData);
                setSelectedCompanyCode(lData.value_);
                lCompanyCode = lData.value_;
            }
            else if(lData.id_ === 'ID_FREQUENCY')
            {
                setSelectedFrequencyObj(lData);
                setselectedFrequencyCode(lData.value_);
            }

            fnGetReportData(lFyCode, lCompanyCode);
        }
    }

    const lForm =
        <>
            <CSCOBackdrop pShow = {mShowBackdrop}/>
            <div className="grid flex flex-wrap justify-content-center">
                <div className="p-6 inline-flex h-5rem align-items-center">
                    <CSDropDown
                        display_field_                = 'Financial Year'
                        data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                        pDataProviderId               = 'code_'
                        pDataField                    = 'code_'
                        pPlaceHolder                  = 'Select a Financial Year'
                        pRequired                     = { true }
                        pId                           = 'ID_FY_CODE'
                        data_provider_display_field_  = 'name_'
                        pControlType                  = "combo_box"
                        pValue                        = { mSelectedFYObj }
                        mValue                        = { mSelectedFYCode }
                        pProcessChange                = { fnProcessChange } 
                    />

                    <CSDropDown
                        display_field_                = 'Company'
                        data_provider_source_         = 'USER_COMPANY_LIST'
                        pDataProviderId               = 'company_code_'
                        pDataField                    = 'company_code_'
                        pPlaceHolder                  = 'Select a Company'
                        pRequired                     = { true }
                        pId                           = 'ID_COMPANY'
                        data_provider_display_field_  = 'company_name_'
                        pControlType                  = "combo_box"
                        pValue                        = { mSelectedCompanyObj }
                        mValue                        = { mSelectedCompanyCode }
                        pProcessChange                = { fnProcessChange } 
                    />
                </div>
                
                <div className="col-10">
                    <CSCOBarChart
                        id         = "ID_MONTHLY_MARGIN" 
                        pData      = {mMarginData}
                        pChartData = {(mChartIdObjectMap) ? mChartIdObjectMap["ID_MONTHLY_MARGIN"] : null}/>
                </div>
            </div>
        </>

    return lForm;
}

export default MarginMetrics;
