import React from "react"

import { fnGetFormHeader } from "../../util/CSLayoutUtil"
import CSRequestUtil from "../../util/CSRequestUtil"

import CSCOGrid from "../../components/CSCOGrid"
import CSCOCriteria from "../../components/CSCOCriteria"
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue } from "../../util/CSConstantUtil";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar";
import CSServiceUtil from "../../util/CSServiceUtil"
import CSCOBackdrop from "../../components/CSCOBackdrop"

class CSCOFormLayout8 extends React.Component
{
    mMinSectionItems 	= 1;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mAddFlag                   : false,
            mDetailsFlag               : false,
            mDetailsMetaData           : null,
            mSelectedData              : null,
            mFormDetailsActions        : null,
            mFormItemsSearchActions    : null,
            mFormItemsDetailsActions   : null,
            mGridHeading               : null,
            mEditableGrid              : false,
            mShowBackdrop              : false,
            mSelectedRecord            : null,
            mFormHeading               : null,
            mFormTitle                 : null,
            mFormData                  : null,
            mIdFormItemMap             : null,
            mMaxHeaderSections         : 3,
            mMaxFooterSections         : 4,
            mDownloadFileName          : '',
            mComponentCodeDataFieldMap : null
        }
        var lFormTitle = null;
        var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
        if(lMenuItem)
        {
            if(lMenuItem.title_)
            {
                lFormTitle = lMenuItem.title_;
            }
            else
            {
                lFormTitle = lMenuItem.layout_item_name_;
            }
        }

        this.state.mFormHeading = fnGetFormHeader(lFormTitle);
        this.state.mFormTitle = lFormTitle;

        this.cfSearch           = this.cfSearch.bind(this);
        this.cfCloseForm        = this.cfCloseForm.bind(this);
        this.cfProcessChange    = this.cfProcessChange.bind(this);
        this.cfProcessResponse  = this.cfProcessResponse.bind(this);
        this.cfGetFormHeading   = this.cfGetFormHeading.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);
        this.cfInitializeLayout = this.cfInitializeLayout.bind(this);
        this.cfOnDataSelection  = this.cfOnDataSelection.bind(this);

        this.process_search     = this.process_search.bind(this);
        this.process_save       = this.process_save.bind(this);
        this.process_close      = this.process_close.bind(this);
        this.process_export     = this.process_export.bind(this);
   }

    cfGetDimensions(lId)
    {
        var lHeight;
        if(lId)
        {
            var lElement = document.getElementById(lId);
            if(lElement)
            {
                var lRect = lElement.getBoundingClientRect();
                if(lRect)
                {
                    lHeight = lRect.height
                }
            }
        }

        return lHeight;
    }

    componentDidMount()
    {
        this.cfInitializeLayout();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeLayout();
        }
    }

    cfInitializeLayout()
    {
        var lDetailsActionList = [];
        var lFormCodeItemsMap  = null;
        var lMaxHeaderSections = this.state.mMaxHeaderSections;
        var lMaxFooterSections = this.state.mMaxFooterSections;
        var lDownloadFileName  = '';
        var lIdFormItemMap = {}
        if(this.props.pFormActions)
        {
            for(var lInd=0; lInd < this.props.pFormActions.length; lInd++)
            {
                var lFormAction = this.props.pFormActions[lInd];
                if(lFormAction.component_type_ === 'SAVE_MULTIPLE_ACTION')
                {
                    lDetailsActionList.push(lFormAction);
                }
                else if(lFormAction.component_type_ === 'CRITERIA_ACTION')
                {
                    lFormAction.fnProcessClick = this.process_search;
                }
            }
        }

        if(this.props.pFormItems)
        {
            for(var lInd1=0; lInd1 < this.props.pFormItems.length; lInd1++)
            {
                var lFormItem = this.props.pFormItems[lInd1];
                lIdFormItemMap[lFormItem.id_] = lFormItem;

                lFormItem.mValue = null;

                if(!lFormCodeItemsMap)
                {
                    lFormCodeItemsMap = {}
                }

                var lColl = lFormCodeItemsMap[lFormItem.cs_form_code_];
                if(!lColl)
                {
                    lColl = []
                    lFormCodeItemsMap[lFormItem.cs_form_code_] = lColl;
                }

                lColl.push(lFormItem);
            }
        }

        var lMap = {}
        if(this.props.pFormProperties)
        {
            var lFormCodeDataObjectMap = {}
            var lFormData = this.state.mFormData;
            if(!lFormData)
            {
                lFormData = {}
            }

            for(var lInd0=0; lInd0< this.props.pFormProperties.length; lInd0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd0];
                if(lFormProperty.property_ === 'data_')
                {
                    lFormCodeDataObjectMap[lFormProperty.cs_form_code_] = lFormProperty.value_;
                    if(!lFormData.hasOwnProperty(lFormProperty.value_))
                    {
                        lFormData[lFormProperty.value_] = {}
                    }
                }
                else if(lFormProperty.property_ === 'collection_data_')
                {
                    if(!lFormData.hasOwnProperty(lFormProperty.value_))
                    {
                        lFormData[lFormProperty.value_] = []
                    }
                }
                else if(lFormProperty.property_ === 'max_header_sections_')
                {
                    lMaxHeaderSections = lFormProperty.value_;
                }
                else if(lFormProperty.property_ === 'max_footer_sections_')
                {
                    lMaxFooterSections = lFormProperty.value_;
                }
                else if(lFormProperty.property_ === 'download_file_')
                {
                    if(!lDownloadFileName)
                    {
                        lDownloadFileName = lFormProperty.value_;
                    }
                    else if(lFormProperty.value_ === '#BRANCH_CODE#')
                    {
                        lDownloadFileName += "_";
                        lDownloadFileName += cfGetConstantValue(lFormProperty.value_);
                    }
                    else if(lFormProperty.value_ === '#TODAY#')
                    {
                        lDownloadFileName += "_";
                        lDownloadFileName += cfGetConstantValue(lFormProperty.value_);
                    }
                }
                else
                {
                    if(!lMap)
                    {
                        lMap = {}
                    }

                    lMap[lFormProperty.property_] = lFormProperty.value_;
                }
            }
        }

        this.setState(
            {
                mFormDetailsActions        : lDetailsActionList, 
                mFormData                  : lFormData, 
                mFormCodeDataObjectMap     : lFormCodeDataObjectMap,
                mFormCodeItemsMap          : lFormCodeItemsMap,
                mIdFormItemMap             : lIdFormItemMap,
                mMaxHeaderSections         : lMaxHeaderSections,
                mMaxFooterSections         : lMaxFooterSections,
                mDownloadFileName          : lDownloadFileName,
                mComponentCodeDataFieldMap : lMap
            }
        );
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormActions.length; lInd++)
            {
                var lFormAction = this.state.mFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    process_search(lCriteria, lActionItem)
    {
        this.setState({mCriteria : lCriteria, mActionItem : lActionItem});
        if(lActionItem && lActionItem.on_click_service_code_ && this.state.mFormData)
        {
            this.cfSearch(lActionItem, lCriteria);
        }
    }

    process_save(event, lParent, lActionItem)
    {
        if(lActionItem && (lActionItem.on_click_service_code_ || lActionItem.on_click_request_ref_id_))
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            var lRequestData = null;
                            var lStatus = null;
                            var lStatusDataField = null;
                            if(this.props.pFormItems)
                            {
                                for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
                                {
                                    var lFormItem = this.props.pFormItems[lInd];
                                    if(lFormItem.action_ref_id_ === lActionItem.parent_ref_id_)
                                    {
                                        if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                        {
                                            var lRequestObj = this.mFormUtil.fnGetRequestObject(lFormItem, this.state.mDataCollection, this.state.mFormData, lRequestData, this.state.mIdFormItemMap, lActionItem);
                                            if(lRequestObj)
                                            {
                                                if(!lRequestData)
                                                {
                                                    lRequestData = {}
                                                }
                        
                                                lRequestData[lFormItem.data_field_] = lRequestObj;
                                            }
                                        }
                                        else if(lFormItem.control_type_ === 'state')
                                        {
                                            lStatus = lActionItem.action_code_;
                                            lStatusDataField = lFormItem.data_field_;
                                        }
                                    }
                                }
                            }

                            if(!lRequestData)
                            {
                                if(this.state.mFormData.hasOwnProperty('selected_data_') && this.state.mFormData['selected_data_'])
                                {
                                    lRequestData = this.state.mFormData['selected_data_'];
                                }
                                else
                                {
                                    lRequestData = this.state.mFormData;
                                }

                                if(lStatus)
                                {
                                    lRequestData[lStatusDataField] = lStatus;
                                }
                            }
    
                            this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                (lResponse) =>
                                {
                                    var lCode = null;
                                    var lMsg = null;
                                    if(lResponse && lResponse.code)
                                    {
                                        if(lResponse.code instanceof Array)
                                        {
                                            lCode = lResponse.code[0];
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message[0];
                                            }
                                        }
                                        else
                                        {
                                            lCode = lResponse.code;
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message;
                                            }
                                        }
                                    }
                                    else
                                    {
                                        lCode = -1;
                                    }
                                    this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                    if((lCode === 1) || (lCode === '1'))
                                    {
                                        this.cfCloseForm(true);
                                    }
                                }
                            )
                        }
                   }
                }
            )
        }
    }

    cfSearch(lActionItem, lCriteria)
    {
        this.setState({mShowBackdrop : true});
        var lServiceCode = lActionItem.on_click_service_code_;
        var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
        if (lServiceCode || lRequestObject)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lRequestId = null;
                if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                {
                    lRequestId = lRequestObject.request_id_;
                }

                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);    
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }
                
                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest(this.cfProcessResponse);
            }
        }
    }

    cfProcessResponse(lResponse)
    {
        if(lResponse  && lResponse.hasOwnProperty("result"))
        {
            var lFormData = this.state.mFormData;
            var lDataKeys = Object.keys(lFormData);
            var lDataCollection = null;
            for(var lInd=0; lInd<lDataKeys.length; lInd++)
            {
                var lKey = lDataKeys[lInd];
                var lDataObj = lFormData[lKey];
                if (lResponse.hasOwnProperty(lKey))
                {
                    if(Array.isArray(lDataObj))
                    {
                        lDataObj = lResponse[lKey];
                        lFormData[lKey] = lDataObj;
                        lDataCollection = lFormData[lKey];
                    }
                    else
                    {
                        if(Array.isArray(lResponse[lKey]))
                        {
                            lDataObj = lResponse[lKey][0];
                            lFormData[lKey] = lDataObj;
                        }
                    }
                }
            }

            this.setState({mFormData : lFormData, mDataCollection : lDataCollection, mShowBackdrop : false});
        }
    }

    cfCloseForm(lCloseFlag)
    {
        if(lCloseFlag)
        {
            this.process_search(this.state.mCriteria, this.state.mActionItem);
        }
    }

    process_close(lCloseFlag, lRefreshFlag)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(lCloseFlag === false, lRefreshFlag);
        }
    }

    cfSetControlValue()
    {
        if(this.props.pFormItems && this.state.mFormData)
        {
            for(var ind=0; ind<this.props.pFormItems.length; ind++)
            {
                var lFormItem = this.props.pFormItems[ind];
                var lDataFieldName = this.state.mFormCodeDataObjectMap[lFormItem.cs_form_code_];
                
                var lFormData = (lDataFieldName) ? this.state.mFormData[lDataFieldName] : null;
                if(lFormItem.control_type_ === 'constant')
                {
                    lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    if(lFormData)
                    {
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
                else if(lFormItem.dependent_id_)
                {
                    this.cfSetDependentFieldData(lFormItem);
                    if(lFormData)
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                    }
                }
                else if(lFormItem.control_type_ === 'check_box')
                {
                    if(lFormItem.constant_ && lFormData && ((lFormItem.constant_ === lFormData[lFormItem.data_field_])))
                    {
                        lFormItem.mValue = '1';
                    }
                    else
                    {
                        lFormItem.mValue = '0';
                    }
                }
                else if(lFormData)
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                        else if(lFormItem.constant_)
                        {
                            lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                        }
                    }
                    else
                    {
                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                    }
                }
                else
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    }
                    else
                    {
                        lFormItem.mValue = '';
                    }
                }
            }
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            for(var ind=0; ind<this.props.pFormItems.length; ind++)
            {
                var lFormItem = this.props.pFormItems[ind];
                var lFormData = null
                var lDataField = this.state.mFormCodeDataObjectMap[lFormItem.cs_form_code_]
                if(lDataField)
                {
                    lFormData = this.state.mFormData[lDataField];
                }
            
                if(lFormData && (lDataField !== lFormItem.data_field_))
                {
                    if(!lFormData.hasOwnProperty(lFormItem.data_field_))
                    {
                        if(lFormItem.control_type_ === 'check_box')
                        {
                            lFormData[lFormItem.data_field_] = '0';
                        }
                        else
                        {
                            lFormData[lFormItem.data_field_] = '';
                        }
                    }

                    if(lFormItem.control_type_ === 'constant')
                    {
                        lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                    else if(lFormItem.control_type_ === 'check_box')
                    {
                        if(lData.control_type_ === 'check_box')
                        {
                            lFormData[lData.data_field_] = (lData.value_ === '1') ? lFormItem.constant_ : '';
                        }        
                    }
                    else if(lFormItem.control_type_ === 'text_input')
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormData[lData.data_field_] = lData.value_;
                        }
                        else if(lFormItem.constant_)
                        {
                            lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                        }
                    }
                    else
                    {
                        lFormData[lData.data_field_] = lData.value_;
                    }
                }
            }
                
            if(this.state.mDataFieldObjectMap)
            {
                var lChangedFormItem = this.state.mDataFieldObjectMap[lData.data_field_];
                if(lChangedFormItem)
                {
                    var lSelectedData = null;
                    if(lData.hasOwnProperty('selected_data_'))
                    {
                        lSelectedData = lData.selected_data_;
                    }

                    lChangedFormItem.selected_data_ = lSelectedData;

                    lFormData = CSFormUtil.cfSetDependentFieldsData(this.state.mFormItems, lFormData, lChangedFormItem, lSelectedData, this.state.mIdFieldObjectMap);
                    var lCompData = CSFormUtil.cfGetComputedFieldsData(lFormData, this.state.mFormItems, this.state.mIdFieldObjectMap);
                    if(lCompData)
                    {
                        var lKeys = Object.keys(lCompData);
                        for(var lKind=0; lKind<lKeys.length; lKind++)
                        {
                            var lKey = lKeys[lKind];
                            if(lFormData.hasOwnProperty(lKey))
                            {
                                lFormData[lKey] = lCompData[lKey];
                            }
                        }
                    }
                }
            }
        }

        this.setState({mFormData : this.state.mFormData});
    }

    cfOnDataSelection(sel_data)
    {
        if(sel_data)
        {
            this.setState({mSelectedRecord : sel_data});
        }
    }

    process_export(event, lParent, lActionItem)
    {
        if(this.state.mDownloadFileName)
        {
            if(lActionItem && (lActionItem.on_click_service_code_ || lActionItem.on_click_request_ref_id_))
            {
                var lServiceCode = lActionItem.on_click_service_code_;
                var lRequestId = lActionItem.request_id_;
                var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                var lCriteriaObj = this.state.mCriteria;
                var lDownloadFileName = this.state.mDownloadFileName + ".csv";
                if(lServiceObject && lCriteriaObj)
                {
                    lCriteriaObj['file_name_'] = lDownloadFileName;

                    var lHeaderItems = []
                    var lGridItems = []
                    var lSummaryItems = []
//                    var lFormItems = CSUtil.cfSort(this.props.pFormItems, 'sequence_');
                    var lFormItems = this.props.pFormItems;
                    for(var lInd=0; lInd<lFormItems.length; lInd++)
                    {
                        var lFormItem = lFormItems[lInd];
                        if(lFormItem.cs_form_component_code_)
                        {
                            if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_HEADING_SECTION_1')
                            {
                                if(lFormItem.visible_ === '1')
                                {
                                    lHeaderItems.push(
                                        {
                                            label : lFormItem.display_label_, 
                                            data_field : lFormItem.data_field_, 
                                            data_object : this.state.mComponentCodeDataFieldMap[lFormItem.cs_form_component_code_]
                                        }
                                    );
                                }
                            }
                            else if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_SUMMARY_1')
                            {
                                if(lFormItem.visible_ === '1')
                                {
                                    lSummaryItems.push(
                                        {
                                            label : lFormItem.display_label_, 
                                            data_field: lFormItem.data_field_, 
                                            data_object : this.state.mComponentCodeDataFieldMap[lFormItem.cs_form_component_code_]
                                        }
                                    );
                                }
                            }
                        }
                    }

                    for(var lgInd=0; lgInd<this.props.pFormGrid.length; lgInd++)
                    {
                        var lgObj = this.props.pFormGrid[lgInd]
                        if(lgObj.visible_ === '1')
                        {
                            lGridItems.push(
                                {
                                    label : lgObj.column_name_, 
                                    data_field: lgObj.data_field_,
                                    data_object : this.state.mComponentCodeDataFieldMap['CS_SEARCH_FORM_1']
                                }
                            );
                        }
                    }

                    lCriteriaObj['heading_'] = this.state.mFormTitle;
                    lCriteriaObj['header_items_'] = lHeaderItems;
                    lCriteriaObj['grid_items_'] = lGridItems;
                    lCriteriaObj['summary_items_'] = lSummaryItems;

                    var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                    if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                    {
                        var lServiceProperties = lServiceObject.cs_service_properties_;
                        if(lServiceProperties !== null)
                        {
                            for(var lInd0=0; lInd0<lServiceProperties.length; lInd0++)
                            {
                                lCriteriaObj[lServiceProperties[lInd0].property_] = cfGetConstantValue(lServiceProperties[lInd0].value_);
                            }
                        }
                    }

                    lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                    lRequestUtil.cfDownload(() =>
                        {
                            const lLink = document.createElement('a');
                            lLink.href = CSRequestUtil.mServerUrl + "/data/" + lDownloadFileName;
                            lLink.setAttribute('download', lDownloadFileName);
                            document.body.appendChild(lLink)
                            lLink.click();
                            lLink.parentNode.removeChild(lLink);
                        }
                    );
                }
            }
        }
    }
    
    render()
    {
        this.cfSetControlValue();

        var lHeaderSection = null;
        var lSummaryBottomSection = null;
        var lFormDetailsItemSearchActions = null;
        if(this.props.hasOwnProperty('pFormItems') && this.props.pFormItems && (this.props.pFormItems.length > 0))
        {
            var lHeaderFormItems = [];
            var lSummaryRightFormItems = [];
            var lSummaryBottomFormItems = [];
            
            var lFormItems = [];
            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                if((!lFormItem.parent_id_) && (lFormItem.visible_ === '1') && (lFormItem.control_type_ !== 'collection'))
                {
                    lFormItems.push(lFormItem);
                }
                if(lFormItem.cs_form_component_code_)
                {
                    if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_HEADING_SECTION_1')
                    {
                        lFormItem.max_width_ = '200px';
                        lFormItem.label_width_ = '100px';
                        lHeaderFormItems.push(lFormItem);
                    }
                    else if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_SUMMARY_2')
                    {
                        lSummaryRightFormItems.push(lFormItem);
                    }
                    else if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_FORM_SUMMARY_1')
                    {
                        if(lFormItem.visible_ === '1')
                        {
                            lFormItem.max_width_ = '150px';
                            lFormItem.label_width_ = '80px';
                            lSummaryBottomFormItems.push(lFormItem);
                        }
                    }
                }
            }

            /**
             * Header Items
             */
            if(lHeaderFormItems.length > 0)
            {
                var lHeaderNumSections = this.state.mMaxHeaderSections;
                var lNumHeaderItems = 0;
                for(var lhInd=0; lhInd<lHeaderFormItems.length; lhInd++)
                {
                    if(lHeaderFormItems[lhInd].visible_ === '1')
                    {
                        lNumHeaderItems++;
                    }
                }

                if(lNumHeaderItems < lHeaderNumSections)
                {
                   lHeaderNumSections = lNumHeaderItems;
                }

                CSUtil.cfSort(lHeaderFormItems, 'sequence_');
                lHeaderSection = this.mFormUtil.cfGetDetailsSections(lHeaderFormItems, this.mMinSectionItems, this.cfProcessChange, "form_criteria_label", null, lHeaderNumSections);
            }
 
             /**
              * Summary Right Items
              */
            if(lSummaryRightFormItems.length > 0)
            {
                CSUtil.cfSort(lSummaryRightFormItems, 'sequence_');
            }
 
             /**
              * Summary Bottom Items
              */
             if(lSummaryBottomFormItems.length > 0)
             {
                var lBottomNumSections = this.state.mMaxFooterSections;
                var lNumBottomItems = 0;
                for(var lbInd=0; lbInd<lSummaryBottomFormItems.length; lbInd++)
                {
                    if(lSummaryBottomFormItems[lbInd].visible_ === '1')
                    {
                        lNumBottomItems++;
                    }
                }

                if(lNumBottomItems < lBottomNumSections)
                {
                    lBottomNumSections = lNumBottomItems;
                }

                CSUtil.cfSort(lSummaryBottomFormItems, 'sequence_');
              
                 lSummaryBottomSection = this.mFormUtil.cfGetDetailsSections(lSummaryBottomFormItems, this.mMinSectionItems, this.cfProcessChange, "form_criteria_label", null, lBottomNumSections);
             }
        }

        if(this.state.mFormItemsSearchActions && (this.state.mFormItemsSearchActions.length > 0))
        {
            var lFormDetailsItemSearchActionList = this.state.mFormItemsSearchActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormDetailsItemSearchActions = 
                <CSCOBaseActionBar
                    align       = "left"
                    pType       = 'SEARCH_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormDetailsItemSearchActionList}
                />;
        }

        if(this.state.mFormCodeDataObjectMap && this.state.mFormDetailsActions && (this.state.mFormDetailsActions.length > 0))
        {
            var lKeys = Object.keys(this.state.mFormCodeDataObjectMap);
            for(var lDAItemInd=0; lDAItemInd<this.state.mFormDetailsActions.length; lDAItemInd++)
            {
                var lDetailsActionItem = this.state.mFormDetailsActions[lDAItemInd];
                if(lDetailsActionItem.action_type_ === '1')
                {
                    for(var lacInd=0; lacInd<lKeys.length; lacInd++)
                    {
                        var lFormCode = lKeys[lacInd];
                        var lDataField = this.state.mFormCodeDataObjectMap[lFormCode]
                        if(lDataField)
                        {
                            var lData = this.state.mFormData[lDataField];
                            var lFormItems1 = this.state.mFormCodeItemsMap[lFormCode];
                            lDetailsActionItem.mEnabled = CSFormUtil.cfEnableAction(lDetailsActionItem, lData, lFormItems1);
                        }
                    }
                }
                else
                {
                    lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                }
            }
        }

        var lFormHeading =  
            <div id="ID_HEADING">
                { this.state.mFormHeading }
            </div>

        var lFormCriteria = null;
        if(this.props.pFormCriteria && (this.props.pFormCriteria.length > 0))
        {
            lFormCriteria = 
                <div id="ID_CRITERIA" ref={this.cfGetDimensions}>
                    <CSCOCriteria 
                        id              = "ID_CRITERIA"
                        pCriteria       = {this.props.pFormCriteria}
                        pFormActions    = {this.props.pFormActions}
                        pFormProperties = {this.props.pFormProperties}
                        pParent         = {this}
                        />
                </div>
        }

        var lDetailsActionBar = null;
        if(this.state.mFormDetailsActions && (this.state.mFormDetailsActions.length > 0))
        {
            lDetailsActionBar = 
                <CSCOHorizontalActionBar 
                    id          = 'ID_DETAILS_ACTIONS'
                    align       = "left"
                    pType       = 'DETAILS_ACTION_BAR'
                    parent      = { this }
                    actionList  = { this.state.mFormDetailsActions }
                />
        }

        return(
            <>
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>

                { lFormHeading }                

                { lFormCriteria }

                <div className="form-item-8" id='ID_HEADER'>
                    {lHeaderSection}
                </div>

                <CSCOGrid  
                    pData               = { this.state.mDataCollection }
                    pFeatureRefId       = { this.props.pFeatureRefId }
                    pColumns            = { this.props.pFormGrid }
                    pHeading            = { this.state.mGridHeading }
                    pAlignHeading       = "left"
                    pageSize            = { 15 }
                    pEditableGrid       = { this.state.mEditableGrid }
                    onClick             = { this.cfOnDataSelection }
                    pSearchActionBar    = { lFormDetailsItemSearchActions }
                    pScrollHeight       = { '175px' }
                    pEnableScroll       = { true }
                    pHeight             = { '175px' }
                />
                
                <div className="form-item-8">
                    { lSummaryBottomSection }
                </div>

                { lDetailsActionBar }
            </>
        );
    }
}

export default CSCOFormLayout8;
