import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';

import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"
import CSFormUtil from "../../util/CSFormUtil"
import CSUtil from "../../util/CSUtil";
import CSRequestUtil from "../../util/CSRequestUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import { cfGetConstantValue} from "../../util/CSConstantUtil";

import CSCODetailsHeader1 from "../../components/CSCODetailsHeader1";
import CSCODetailsSection1 from "../../components/CSCODetailsSection1";
import { fnGetForm } from "../../util/CSLayoutUtil"
import {Button} from 'primereact/button';


class CSCODetailsForm10 extends Component
{
    mComponentCode      = 'CS_DETAILS_FORM_LAYOUT_10';
    mComponentList      = ['CS_DETAILS_FORM_LAYOUT_10', 'CS_DETAILS_HEADING_SECTION_1', 'CS_DETAILS_FORM_SECTION_1'];
    mMaxSections        = 3;
    mMinSectionItems 	= 6;

    mFormUtil = CSFormUtil.cfGetInstance();
    mCSServiceUtil = CSServiceUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mDisplay        	  : this.props.pShow,
            mAppId          	  : this.props.pAppId,
            mFormHeading    	  : (this.props.pFormHeading) ? this.props.pFormHeading : "",
            mFormCode       	  : this.props.pFormCode,
            mFormData       	  : null,
            mFormOrigData   	  : null,
            mIdFieldObjectMap     : {},
            mDataFieldFormItemMap : {},
            mDataFieldObjectMap   : {},
            mDefaultDataField     : 'selected_data_',
            mFormActions    	  : this.props.pFormActions,
            mFormProperties 	  : this.props.pFormProperties,
            mFormItemProperties	  : this.props.pFormItemProperties,
            mFormItems         	  : this.props.pFormItems,
            mSelectedActionItem   : this.props.pSelectedActionItem,
            mSelectedData         : this.props.pSelectedData,
            mDetailsMetaData      : null,
            mWidth             	  : this.props.pWidth,
            mHeight            	  : this.props.pHeight,
            fnProcessClose     	  : props.fnProcessClose,
            mHeaderFlag           : false,
            mSectionFlag          : false,
            mPopoutFlag           : false
        }

        this.process_save               = this.process_save.bind(this);
        this.process_close              = this.process_close.bind(this);
        this.process_cancel             = this.process_cancel.bind(this);
        this.onPopout                   = this.onPopout.bind(this);
        this.cfClosePopout              = this.cfClosePopout.bind(this);
        this.cfSetControlValue          = this.cfSetControlValue.bind(this);
        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfEnableSave               = this.cfEnableSave.bind(this);
        this.cfResetForm                = this.cfResetForm.bind(this);
        this.cfSetDependentFieldData    = this.cfSetDependentFieldData.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            if(this.props.pSelectedData.hasOwnProperty('selected_data_'))
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData['selected_data_'];
                this.state.mSelectedData = this.props.pSelectedData['selected_data_'];
            }
            else
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData;
                this.state.mSelectedData = this.props.pSelectedData;
            }
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'layout')
                {
                    if (lFormProperty.property_ === 'max_sections_')
                    {
                        this.mMaxSections = lFormProperty.value_;
                    }

                    if(lFormProperty.property_ === 'min_section_items_')
                    {
                        this.mMinSectionItems = lFormProperty.value_;
                    }
                }
                else if((lFormProperty.type_ === 'initialize') && ((lFormProperty.property_ === 'data_') || (lFormProperty.property_ === 'collection_data_')))
                {
                    if(this.state.mFormData)
                    {
                        var lDataObj = null;
                        var lData = null;
                        if(lFormProperty.property_ === 'data_')
                        {
                            if(this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.state.mFormData[lFormProperty.value_];
                                if(lData)
                                {
                                    if(lData instanceof Array && (lData.length > 0))
                                    {
                                        lDataObj = lData[0];
                                    }
                                    else
                                    {
                                        lDataObj = lData;
                                    }

                                    if(lDataObj)
                                    {
                                        this.state.mFormData[lFormProperty.value_] = lDataObj;
                                    }
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = {};
                            }
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            if(this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.state.mFormData[lFormProperty.value_];
                                if(lData instanceof Array)
                                {
                                    this.state.mFormData[lFormProperty.value_] = lData;
                                }
                                else
                                {
                                    this.state.mFormData[lFormProperty.value_] = [];
                                    this.state.mFormData[lFormProperty.value_].push(lData)    
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = [];
                            }
                        }
                    }
                    else
                    {
                        if(lFormProperty.property_ === 'data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = {};
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = [];
                        }
                    }
                }
                else if((lFormProperty.type_ === 'initialize') && (this.state.mFormCode === lFormProperty.cs_form_code_))
                {
                    if (lFormProperty.property_ === 'default_data_field_')
                    {
                        this.state.mDefaultDataField = lFormProperty.value_;
                    }
                }
            }
        }
        else
        {
            this.state.mFormData = this.props.pFormData;
        }

        if(this.props.pFormItems)
        {
            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                lFormItem.mValue = null;
                if(lFormItem.hasOwnProperty('selected_data_'))
                {
                    lFormItem.selected_data_ = null;
                }

                this.state.mIdFieldObjectMap[lFormItem.id_] = lFormItem;
                this.state.mDataFieldFormItemMap[lFormItem.data_field_] = lFormItem;
            }
        }
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(lCloseFlag, lRefreshFlag)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(lCloseFlag === false, lRefreshFlag);
        }
    }

    onPopout(lEvent, lFormItem)
    {
        var lFormActions = this.state.mFormActions;
        if(lEvent && lFormItem)
        {
            var lActionItem;
            var lActionRefId;
            var lCriteria;
            if(lFormItem.hasOwnProperty('cs_form_item_properties_') && lFormItem.cs_form_item_properties_)
            {
                var lFormItemProperties = lFormItem.cs_form_item_properties_;
                for(var lInd=0; lInd<lFormItemProperties.length; lInd++)
                {
                    var lProperty = lFormItemProperties[lInd];
                    if(lProperty.type_ === 'popup_action')
                    {
                        if(lProperty.property_ === 'action_ref_id_')
                        {
                            lActionItem = this.mFormUtil.cfGetActionItem(lFormActions, lProperty.value_)
                            lActionRefId = lProperty.value_;
                        }
                    }
                    else if(lProperty.type_ === 'criteria')
                    {
                        if(!lCriteria)
                        {
                            lCriteria = {}
                        }

                        var lData = lFormItem.mValue;
                        if(lData)
                        {
                            if(lData instanceof Object && lData.hasOwnProperty(lProperty.property_))
                            {
                                lCriteria[lProperty.property_] = lData[lProperty.property_];
                            }
                            else
                            {
                                lCriteria[lProperty.property_] = lData;
                            }
                        }
                    }
                }
            }

            if(lActionItem)
            {
                if(lCriteria 
                    && ((lActionItem.action_ === 'process_add') || (lActionItem.action_ === 'process_modify'))
                    && lActionItem.on_click_service_code_)
                {
                    var lDetailsMetaData = 
                    {
                        pParent                 : this,
                        pFormType               : lActionItem.navigate_to_form_component_code_,
                        pFormHeading            : lActionItem.title_,
                        pFormActions            : this.mFormUtil.cfGetChildActionItems(this.state.mFormActions, lActionRefId),
                        pFormProperties         : this.state.mFormProperties,
                        pFormItems              : this.mFormUtil.cfGetFormItems(this.state.mFormItems, lActionRefId, true),
                        pSelectedActionItem     : lActionItem,
                        pParentFlag             : true
                    };

                    var lServiceCode = lActionItem.on_click_service_code_;
                    var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        var lDataObj = {};
                        var lRequestId = null;
                        var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                        {
                            lRequestId = lRequestObject.request_id_;
                        }
                    
                        var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                        if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                            {
                                lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                            }
                        }
                    
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                            {
                                lDataObj.selected_criteria_ = lCriteria;
                                if(lResponse)
                                {
                                    if(lRequestObject)
                                    {
                                        var lKeys = Object.keys(lResponse);
                                        if(lKeys)
                                        {
                                            for(var lKInd=0; lKInd<lKeys.length; lKInd++)
                                            {
                                                lDataObj[lKeys[lKInd]] = lResponse[lKeys[lKInd]];
                                            }
                                        }
                    
                                        lDetailsMetaData.pFormData = lDataObj;
                                    }
                                    else
                                    {
                                        if(lResponse.hasOwnProperty('collection_') && (lResponse.collection_.length > 0))
                                        {
                                            var lRespData = lResponse.collection_[0];
                                            lDataObj = lRespData;
                                            lDetailsMetaData.pSelectedData = lDataObj;
                                        }
                                    }
                                }
                    
                                this.setState(
                                    {
                                        mDetailsMetaData    : lDetailsMetaData, 
                                        mPopoutFlag         : true
                                    }
                                );
                            }
                        );
                    }
                }
                else if(lActionItem.action_ === 'process_reset')
                {
                    this.cfResetForm();
                }
            }
        }
    }

    cfClosePopout(lCloseFlag)
    {
        this.setState({mPopoutFlag : lCloseFlag});
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;

            if(!lFormData)
            {
                lFormData = {};
            }

            var lDataObject = null;
            if(lData.hasOwnProperty('data_object_'))
            {
                if(!lFormData.hasOwnProperty(lData.data_object_))
                {
                    lDataObject = {};
                    lFormData[lData.data_object_] = lDataObject;
                }

                lDataObject = lFormData[lData.data_object_];
            }

            if(lDataObject)
            {
                for(var ind=0; ind<this.state.mFormItems.length; ind++)
                {
                    var lFormItem = this.state.mFormItems[ind];
                    if(!lDataObject.hasOwnProperty(lFormItem.data_field_))
                    {
                        if(lFormItem.control_type_ === 'check_box')
                        {
                            lDataObject[lFormItem.data_field_] = '0';
                        }
                        else
                        {
                            lDataObject[lFormItem.data_field_] = '';
                        }
                    }

                    if(lFormItem.control_type_ === 'constant')
                    {
                        lDataObject[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                    else if(lFormItem.control_type_ === 'check_box')
                    {
                        if(lData.control_type_ === 'check_box')
                        {
                            lDataObject[lData.data_field_] = (lData.value_ === '1') ? lFormItem.constant_ : '';
                        }        
                    }
                    else if(lFormItem.control_type_ === 'text_input')
                    {
                        if(lDataObject[lFormItem.data_field_])
                        {
                            lDataObject[lData.data_field_] = lData.value_;
                        }
                        else if(lFormItem.constant_)
                        {
                            lDataObject[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                        }
                    }
                    else
                    {
                        lDataObject[lData.data_field_] = lData.value_;
                    }
                }
                            
                if(this.state.mDataFieldFormItemMap)
                {
                    var lChangedFormItem = this.state.mDataFieldFormItemMap[lData.data_field_];
                    if(lChangedFormItem)
                    {
                        var lSelectedData = null;
                        if(lData.hasOwnProperty('selected_data_'))
                        {
                            lSelectedData = lData.selected_data_;
                        }

                        lChangedFormItem.selected_data_ = lSelectedData;

                        lDataObject = CSFormUtil.cfSetDependentFieldsData(this.state.mFormItems, lDataObject, lChangedFormItem, lSelectedData, this.state.mIdFieldObjectMap);
                        var lCompData = CSFormUtil.cfGetComputedFieldsData(lDataObject, this.state.mFormItems, this.state.mIdFieldObjectMap);
                        if(lCompData)
                        {
                            var lKeys = Object.keys(lCompData);
                            for(var lKind=0; lKind<lKeys.length; lKind++)
                            {
                                var lKey = lKeys[lKind];
                                if(lDataObject.hasOwnProperty(lKey))
                                {
                                    lDataObject[lKey] = lCompData[lKey];
                                }
                            }
                        }
                    }
                }
            }

            this.setState ({mFormData : lFormData});
        }
    }
    
    cfSetDependentFieldData(lFormItem)
    {
        if(lFormItem && lFormItem.dependent_id_)
        {
            var lDependentItem = this.state.mIdFieldObjectMap[lFormItem.dependent_id_];
            if(lDependentItem)
            {
                var lDependentField = (lFormItem.reference_field_) ? lFormItem.reference_field_ : lFormItem.dependent_field_;
                if(lDependentField)
                {
                    lFormItem.dependent_field_ = lDependentField;

                    var lDependentData = null;
                    if(lDependentItem.control_type_ === 'combo_box')
                    {
                        lDependentData = lDependentItem.selected_data_;
                        if( lDependentData && (lDependentData instanceof Object))
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData[lDependentField];
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData[lDependentField];
                            }
                        }
                        else
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData;
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData;
                            }                            
                        }
                    }
                    else
                    {
                        lDependentData = lDependentItem.mValue;
                        if( lDependentData && (lDependentData instanceof Object))
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData[lDependentField];
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData[lDependentField];
                            }
                        }
                        else
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData;
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData;
                            }
                        }
                    }
                }
                else
                {
                    lFormItem.mValue = lDependentData;
                }
            }
        }
    }
/*
    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            if(!lFormData)
			{
                lFormData = {};
			}

            var lDataObject = null;
            if(lData.hasOwnProperty('data_object_'))
            {
                if(!lFormData.hasOwnProperty(lData.data_object_))
                {
                    lDataObject = {};
                    lFormData[lData.data_object_] = lDataObject;
                }

                lDataObject = lFormData[lData.data_object_];
            }

            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                if(lFormItem.control_type_ === 'constant')
                {
                    if(lDataObject)
                    {
                        lDataObject[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                    else
                    {
                        lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                }
            }

            if(lData.hasOwnProperty('data_field_') && lData.data_field_)
            {
                if(lDataObject)
                {
                    lDataObject[lData.data_field_] = lData.value_;
                }
                else
                {
                    lFormData[lData.data_field_] = lData.value_;
                }
            }

            this.setState ({mFormData : lFormData});
        }
    }
*/

    cfResetForm()
    {
        
    }
/*    
    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mFormData;
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }
*/

    cfSetControlValue()
    {
        if((this.state) && (this.state.mFormItems))
        {
            var lFormData = this.state.mFormData;
            var lSortedFormItems = CSUtil.cfSort(this.state.mFormItems, 'sequence_');
            for(var lInd=0; lInd < lSortedFormItems.length; lInd++)
            {
                var lFormItem = lSortedFormItems[lInd];
                if(lFormItem.control_type_ === 'constant')
                {
                    lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    if(lFormData)
                    {
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
                else if(lFormItem.control_type_ === 'check_box')
                {
                    if(lFormItem.constant_ && lFormData && ((lFormItem.constant_ === lFormData[lFormItem.data_field_])))
                    {
                        lFormItem.mValue = '1';
                    }
                    else
                    {
                        lFormItem.mValue = '0';
                    }
                }
                else if(lFormData)
                {
                    if(lFormItem.dependent_id_)
                    { 
                        if(lFormData)
                        {
                            if(lFormData[lFormItem.data_field_])
                            {
                                lFormItem.mValue = lFormData[lFormItem.data_field_];
                            }
                            else
                            {
                                this.cfSetDependentFieldData(lFormItem, lFormData);
                                lFormData[lFormItem.data_field_] = lFormItem.mValue;
                            }
                        }
                    }
                    else if(lFormItem.control_type_ === 'text_input')
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];        
                        }
                        else if(lFormItem.constant_)
                        {
                            lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                        }
                        else
                        {
                            lFormItem.mValue = '';
                        }
                    }
                    else
                    {
                        if(lFormItem.cs_form_item_properties_)
                        {
                            for(var lfipInd=0; lfipInd<lFormItem.cs_form_item_properties_.length; lfipInd++)
                            {
                                var lfipObj = lFormItem.cs_form_item_properties_[lfipInd];
                                if(lfipObj.type_ === 'data_selection')
                                {
                                    var lfiObj = lFormData[lfipObj.value_];
                                    if(lfiObj)
                                    {
                                        if(lfipObj.property_ === 'selection_key_')
                                        {
                                            lFormItem.pData = lfiObj[lfipObj.selection_property_];
                                            lFormItem.pSelectionKey = lfipObj.selection_value_;
                                        }
                                    }
                                }
                            }
                        }
                        else
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                    }
                }
                else
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    }
                    else
                    {
                        lFormItem.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm(lCloseFlag)
    {
        this.state.fnProcessClose(lCloseFlag === false);
    }

    cfEnableSave(lValue, lHeaderFlag)
    {
        if(lHeaderFlag === 1)
        {
            this.setState({mHeaderFlag : lValue});
        }
        else
        {
            this.setState({mSectionFlag : lValue});
        }
    }

    process_save(lEvent, lParent, lActionItem)
    {
        if(lActionItem)
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            var lRequestData = null;
                            if(this.state.mFormItems)
                            {
                                for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
                                {
                                    var lFormItem = this.state.mFormItems[lInd];
                                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                    {
                                        var lRequestObj = this.mFormUtil.fnGetRequestObject(lFormItem, this.state.mDataCollection, this.state.mFormData, lRequestData, this.state.mIdFieldObjectMap);
                                        if(lRequestObj)
                                        {
                                            if(!lRequestData)
                                            {
                                                lRequestData = {}
                                            }
                    
                                            lRequestData[lFormItem.data_field_] = lRequestObj;
                                        }
                                    }
                                }
                            }
                    
                            if(!lRequestData)
                            {
                                if(this.state.mFormData.hasOwnProperty(this.state.mDefaultDataField))
                                {
                                    lRequestData = this.state.mFormData[this.state.mDefaultDataField];
                                }
                                else
                                {
                                    lRequestData = this.state.mFormData;
                                }
                            }
                    
                            this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                (lResponse) =>
                                {
                                    var lCode = null;
                                    var lMsg = null;
                                    var lCloseFlag = (lActionItem.new_state_ === "list")
    
                                    if(lResponse && lResponse.code)
                                    {
                                        if(lResponse.code instanceof Array)
                                        {
                                            lCode = lResponse.code[0];
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message[0];
                                            }
                                        }
                                        else
                                        {
                                            lCode = lResponse.code;
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message;
                                            }
                                        }

                                        if(lCode === '1')
                                        {
                                            this.process_close(lCloseFlag, true);
                                        }
                                    }
                                    else
                                    {
                                        lCode = -1;
                                    }
    
                                    this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                }
                            );
                        }
                    }
                }
            );
        }
    }

    render() 
    {
        this.cfSetControlValue();
     
        var lFormActions = null;
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lSection = null;
        if(lFormItemList.length > 0)
        {
            var lSaveActionItem = null;
            if(this.state.mFormActions && (this.state.mFormActions.length > 0))
            {
                var lFormActionList = null;
                for(var lAInd=0; lAInd<this.state.mFormActions.length; lAInd++)
                {
                    var lActionItem = this.state.mFormActions[lAInd];
                    if(lActionItem.component_type_ === 'DETAILS_ACTION')
                    {
                        if((lActionItem.parent_ref_id_ === '') || (lActionItem.parent_ref_id_ === this.state.mSelectedActionItem.id_))
                        {
                            if(lActionItem.action_type_ === '1')
                            {
                                if (lActionItem.action_ === 'process_save')
                                {
                                    lSaveActionItem = lActionItem;
                                }

                                lActionItem.mEnabled = this.state.mHeaderFlag && this.state.mSectionFlag;
                            }
                            else
                            {
                                lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                            }

                            if(!lFormActionList)
                            {
                                lFormActionList = [];
                            }
    
                            lFormActionList.push(lActionItem);
                        }
                    }
                }
     
                lFormActions = 
                    <CSCOHorizontalActionBar 
                        align       = "left"
                        pType       = 'SAVE_ACTION_BAR'
                        parent      = {this}
                        actionList  = {lFormActionList}
                    />;
            }
            
            let lHeaderActions = 
                <div style={{verticalAlign: 'middle'}}>            


                    <Button
                        key = "21"
                        style=
                            {
                                {
                                    verticalAlign   : 'middle'
                                }
                            } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_TIMES']}
                        onClick= { () => this.process_close(false, true) }
                    />
                </div>

            let lHeader = 
                <div className='flex justify-content-between'>
                    <div className="p-toolbar-group-left">
                        <h2>{ this.state.mFormHeading || '.' }</h2>
                    </div>

                    <div className="p-toolbar-group-right">
                        { lHeaderActions }
                    </div>
                </div>

            var lDetailsForm = null;
            if(this.state.mPopoutFlag)
            {
                var lSelectedData = (this.state.mDetailsMetaData.hasOwnProperty('pSelectedData')) ? this.state.mDetailsMetaData.pSelectedData : null; 
                lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lSelectedData, this.cfClosePopout);
            }

            lSection = 
                <Dialog visible={this.state.mDisplay}
                        modal={true}
                        style=
                        {
                            {
                                minWidth : 'auto',
                                minheight : 'auto', 
                                paddingBottom: -40,
                            }
                        }
                        minheight   = 'auto'
                        appendTo    = { document.body }
                        header      = {lHeader}
                        closable    = { false }
                        padding     = '10px'
                        onHide      = {() => this.cfCloseForm()}
                        footer      = { lFormActions }
                        >

                    <div className="background">
                        <CSCODetailsHeader1 pFormItems          = {lFormItemList} 
                                            pFormData           = {this.state.mFormData} 
                                            pFormProperties     = {this.state.mFormProperties} 
                                            pEnableSaveFn       = {this.cfEnableSave}
                                            pProcessChange      = {this.cfProcessChange}
                                            pSaveActionItem     = {lSaveActionItem}
                                            pFormItemProperties = {this.state.mFormItemProperties}
                                            pSelectedData       = {this.state.mSelectedData}
                                            pOnPopout           = {this.onPopout}
                                            />

                        <CSCODetailsSection1 pFormItems         = {lFormItemList} 
                                            pFormData           = {this.state.mFormData} 
                                            pFormProperties     = {this.state.mFormProperties} 
                                            pEnableSaveFn       = {this.cfEnableSave}
                                            pProcessChange      = {this.cfProcessChange}
                                            pOnPopout           = {this.onPopout}
                                            pSaveActionItem     = {lSaveActionItem}
                                            pSelectedData       = {this.state.mSelectedData}
                                            pFormItemProperties = {this.state.mFormItemProperties}/>

                        { lDetailsForm }
                    </div>
                </Dialog>
        }

        return lSection;
    }
}

export default CSCODetailsForm10;
