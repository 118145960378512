import React from "react"
import {Password} from 'primereact/password';
import CSServiceUtil from '../util/CSServiceUtil'
import CSBaseControl from './CSBaseControl'

class CSPassword extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.mServiceUtil   = CSServiceUtil.cfGetInstance();
        this.cfGetControl   = this.cfGetControl.bind(this);
        this.cfHandleChange = this.cfHandleChange.bind(this);
    }
 
    cfHandleChange(event)
    {
        if(this.state.mHandleChange !== undefined)
        {
            var lData = {};
            lData.control_type_ = 'text_input';
            lData.data_field_ = this.state.mDataField;
            lData.value_ = event.target.value;
            this.state.mHandleChange(lData);
        }
    }

    cfGetControl()
    {
        var ctl = null;
        if (this.state.mControlType && (this.state.mControlType === 'password'))
        {
            ctl = (
                <div className="cs-control field grid">
                    {super.cfGetControlLabel()}
                    <Password
                        className   = "cs-password"
                        id          = {this.state.mId} 
                        name        = {this.state.mDataField}
                        value       = {this.state.mValue || ''}
                        onChange    = {this.cfHandleChange}
                        />
                </div>
            )
        }

        return ctl;
    }

    render()
    {
        return this.cfGetControl();
    }
}

export default CSPassword;
