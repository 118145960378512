import React from "react";

import CSUtil from "../../util/CSUtil";
import CSConfigUtil from "../../util/CSConfigUtil";
import CSRequestUtil from "../../util/CSRequestUtil"
import CSCOAnalysisLayout1 from "./CSCOAnalysisLayout1";
import CSCOBasicAnalysisLayout from "./CSCOBasicAnalysisLayout";

class CSCOAnalysisLayoutManager extends React.Component
{
    mCSUtil = null;
    constructor(props)
    {
        super(props);
        this.mCSUtil = CSUtil.cfGetInstance();
        this.state = 
        {   
            mFeatureRefId : this.props.pFeatureRefId,
            mLayoutType : this.props.pLayoutType,
            mLayoutCode : this.props.pLayoutCode,
            mReportList : null,
            mFormLayoutHeading  : null,
            mAppId : null,
        };

        this.cfGetFormData = this.cfGetFormData.bind(this);
        this.cfProcessReportList = this.cfProcessReportList.bind(this);
        this.cfGetFormHeading = this.cfGetFormHeading.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(lPrevProps.pFeatureRefId !== this.props.pFeatureRefId)
            {
                this.cfGetFormData();
            }
        }
    }

    cfGetFormData()
    {
        var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
        if(lMenuItem !== null)
        {
            var lRequestUtil = new CSRequestUtil(
                'GET', 
                'SYS_SEARCH_REPORT', 
                'cs_report');

            var lCriteria = {};
            lCriteria.app_code_ = CSConfigUtil.mAppCode;
            lCriteria.app_id_   = lMenuItem.layout_item_code_;
            lCriteria.app_name_ = lMenuItem.layout_item_code_;
            lCriteria.user_id_  = CSConfigUtil.mUserId;
            
            lRequestUtil.cfSetRequestCriteria(lCriteria);
            lRequestUtil.cfProcessRequest(this.cfProcessReportList);
        }
    }

    cfProcessReportList(lResponse)
    {
        var lData = [];
        var lFormLayoutHeading;
        var lAppId;

        if(this.props && this.props.pFeatureRefId)
        {
            var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
            if(lMenuItem !== null)
            {
                lAppId = lMenuItem.layout_item_code_;
                lFormLayoutHeading = lMenuItem.title_;
            }
        }

        if(lResponse && lResponse.hasOwnProperty('collection_'))
        {
            if(lResponse.collection_ instanceof Array)
            {
                lData = lResponse.collection_;
            }
            else
            {
                lData.push(lResponse.collection_);
            }

            this.setState(
                { 
                    mAppId              : lAppId,
                    mFormLayoutHeading  : lFormLayoutHeading,
                    mFeatureRefId       : this.props.pFeatureRefId ,
                    mLayoutType         : this.props.pLayoutType,
                    mLayoutCode         : this.props.pLayoutCode,
                    mReportList         : lData
                }
            );
        }
        else
        {
            this.setState(
                { 
                    mFormLayoutHeading  : lFormLayoutHeading,
                    mReportList         : lData 
                }
            );
        }
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormActions.length; lInd++)
            {
                var lFormAction = this.state.mFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    render()
    {
        var lProps = 
        {
            pAppId                  : this.state.mAppId,
            pFormLayoutHeading      : this.state.mFormLayoutHeading,
            pFeatureRefId           : this.state.mFeatureRefId,
            pLayoutType             : this.state.mLayoutType,
            pLayoutCode             : this.state.mLayoutCode,
            pParent                 : this,
            pReportList             : this.state.mReportList
        }

        var lLayout = null;
        if(this.state.mLayoutCode === 'CS_ANALYSIS_LAYOUT_5' || this.state.mLayoutCode === 'CS_DASHBOARD_LAYOUT_1')
        {
            lLayout = <CSCOBasicAnalysisLayout {...lProps}/>;
        }
        else if(this.state.mLayoutCode === 'CS_ANALYSIS_LAYOUT_6')
        {
            lLayout = <CSCOAnalysisLayout1 {...lProps}/>;
        }

        /**
         * Return respective layout
         */
        return lLayout;
    }
}

export default CSCOAnalysisLayoutManager;
