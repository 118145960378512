import CSCODoughnutChart from '../../layout/analysis/CSCODoughnutChart';
import CSCOBarChart from "../../layout/analysis/CSCOBarChart";
import CSDropDown from '../../controls/CSDropDown';
import { useEffect, useState } from 'react';
import CSServiceUtil from '../../util/CSServiceUtil';
import CSRequestUtil from '../../util/CSRequestUtil';
import CSConfigUtil from '../../util/CSConfigUtil';
import CSCOBackdrop from "../../components/CSCOBackdrop";

const RevenueMetrics = (lProps) => {
    const [mRequestId, setRequestId] = useState(null);
    const [mChartIdObjectMap, setChartIdObjectMap] = useState(null);
    const [mShowBackdrop, setShowBackdrop] = useState(false);

    const [mSelectedFYCode, setselectedFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mSelectedFYObj, setselectedFYObj] = useState(CSConfigUtil.mCurrentFYName);
    const [mMonthlyRevenue, setMonthlyRevenue] = useState(null);

    const [mSelectedProductServiceFYCode, setselectedProductServiceFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mSelectedProductServiceFYObj, setselectedProductServiceFYObj] = useState(CSConfigUtil.mActiveFinancialYearObj);
    const [mYtdProductServiceRevenue, setYtdProductServiceRevenue] = useState(null);

    const [mSelectedYtdFYCode, setselectedYtdFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mSelectedYtdFYObj, setselectedYtdFYObj] = useState(CSConfigUtil.mActiveFinancialYearObj);
    const [mSelectedCompanyCode, setSelectedCompanyCode] = useState(CSConfigUtil.mCompanyCode);
    const [mSelectedCompanyObj, setSelectedCompanyObj] = useState(CSConfigUtil.mCompanyObj);
    const [mYtdRevenue, setYtdRevenue] = useState(null);

    useEffect(() => {
            let lChartIdObjMap = {};
            let lChartRefIdObjMap = {};

            if(lProps.pReportChartProperties)
            {
                for(let lInd=0; lInd<lProps.pReportChartProperties.length; lInd++)
                {
                    let propertyObj = lProps.pReportChartProperties[lInd];
                    if(propertyObj.property_name_ === 'request_id_')
                    {
                        setRequestId(propertyObj.value_);
                    }
                    else if(propertyObj.property_name_ === 'chart_id_')
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.id_];
                        if(!lcpObj)
                        {
                            lcpObj = {};
                            lChartRefIdObjMap[propertyObj.id_] = lcpObj;
                        }

                        lcpObj[propertyObj.property_name_] = propertyObj.value_;
                        lChartIdObjMap[propertyObj.value_] = lcpObj;
                    }
                    else if(propertyObj.parent_ref_id_)
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.parent_ref_id_];
                        if(lcpObj)
                        {
                            if((propertyObj.property_name_ === 'chart_data_field_') || (propertyObj.property_name_ === 'chart_data_label_'))
                            {
                                if(propertyObj.property_name_ === 'chart_data_field_')
                                {
                                    let lChartDataFieldList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataFieldList)
                                    {
                                        lChartDataFieldList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataFieldList;
                                    }

                                    lChartDataFieldList.push(propertyObj.value_);
                                }
                                else
                                {
                                    let lChartDataLabelList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataLabelList)
                                    {
                                        lChartDataLabelList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataLabelList;
                                    }

                                    lChartDataLabelList.push(propertyObj.value_);
                                }
                            }
                            else
                            {
                                lcpObj[propertyObj.property_name_] = propertyObj.value_; 
                            }
                        }
                    }
                }

                setChartIdObjectMap(lChartIdObjMap);
            }
        }, []
    );

    useEffect(() => {
            fnGetYtdReportData(CSConfigUtil.mCurrentFYCode);
            fnGetReportData(CSConfigUtil.mCurrentFYCode, CSConfigUtil.mCompanyCode);
            fnGetProductServiceReportData(CSConfigUtil.mCurrentFYCode, CSConfigUtil.mCompanyCode);
        }, [mChartIdObjectMap]
    );

    function fnGetReportData(lFyCode, lCompanyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && (lFyCode || lCompanyCode))
            {
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;
                lCriteria.company_code_ = lCompanyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lObj0 = mChartIdObjectMap['ID_MONTHLY_REVENUE'];
                            if(lObj0 && lResponse.hasOwnProperty(lObj0.data_field_))
                            {
                                let lDataObj = lResponse[lObj0.data_field_];
                                setMonthlyRevenue(lDataObj);
                            }
                            else
                            {
                                setMonthlyRevenue(null);
                            }
                        }
                    }
                );
            }
        }
    }

    function fnProcessChange(lData)
    {
        if(lData)
        {
            let lFyCode = mSelectedFYCode;
            let lCompanyCode = mSelectedCompanyCode;

            if(lData.id_ === 'ID_FY_CODE')
            {
                setselectedFYObj(lData);
                setselectedFYCode(lData.value_);
                lFyCode = lData.value_;
            }

            fnGetReportData(lFyCode, lCompanyCode);
        }
    }

    /**
     * Year To Day Report 
     */
    function fnProcessYtdChange(lData)
    {
        if(lData)
        {
            let lFyCode = mSelectedYtdFYCode;
            if(lData.id_ === 'ID_FY_CODE')
            {
                setselectedYtdFYObj(lData);
                setselectedYtdFYCode(lData.value_);
                lFyCode = lData.value_;
            }

            fnGetYtdReportData(lFyCode);
        }
    }

    function fnGetYtdReportData(lFyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);            
            if(lServiceObject && lFyCode)
            {
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lObj1 = mChartIdObjectMap['ID_YTD_REVENUE'];
                            if(lObj1 && lResponse.hasOwnProperty(lObj1.data_field_))
                            {
                                let lDataObj = lResponse[lObj1.data_field_];
                                setYtdRevenue(lDataObj);
                            }
                            else
                            {
                                setYtdRevenue(null);
                            }
                        }
                    }
                );
            }
        }
    }
    
    /**
     * Product Service Change Report 
     */
    function fnProcessProductServiceChange(lData)
    {
        if(lData && lData.hasOwnProperty('selected_data_') && lData.selected_data_)
        {
            let lFyCode = mSelectedProductServiceFYCode;
            let lCompanyCode = mSelectedCompanyCode;
            if(lData.id_ === 'ID_FY_CODE')
            {
                setselectedProductServiceFYObj(lData.selected_data_);
                setselectedProductServiceFYCode(lData.value_);
                lFyCode = lData.value_;
            }
            else if(lData.id_ === 'ID_COMPANY')
            {
                setSelectedCompanyObj(lData);
                setSelectedCompanyCode(lData.value_);
                lCompanyCode =lData.value_;
            }

            fnGetProductServiceReportData(lFyCode, lCompanyCode);
        }
    }

    function fnGetProductServiceReportData(lFyCode, lCompanyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && (lFyCode || lCompanyCode))
            {
                setShowBackdrop(true);
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;
                lCriteria.company_code_ = lCompanyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lObj2 = mChartIdObjectMap['ID_YTD_REVENUE_PRODUCT_SERVICE'];
                            if(lObj2 && lResponse.hasOwnProperty(lObj2.data_field_))
                            {
                                let lDataObj = lResponse[lObj2.data_field_];
                                setYtdProductServiceRevenue(lDataObj);
                            }
                            else
                            {
                                setYtdProductServiceRevenue(null);
                            }
                        }
                        
                        setShowBackdrop(false);
                    }
                );
            }
        }
    }

    const lForm =
        <>
            <CSCOBackdrop pShow = {mShowBackdrop}/>
            <div className="grid p-2 md:h-22rem lg:h-full inline-contents justify-content-center">
                <div className="col-5 md:col-4 shadow-2 mr-4" >
                    <div className="mb-4 h-5rem ">
                        <div className='flex justify-content-center'>
                            <div className="mb-2 w-7">
                                <CSDropDown
                                    display_field_                = 'Financial Year'
                                    data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                                    pDataProviderId               = 'code_'
                                    pDataField                    = 'code_'
                                    pPlaceHolder                  = 'Select a Financial Year'
                                    pRequired                     = { true }
                                    pId                           = 'ID_FY_CODE'
                                    data_provider_display_field_  = 'name_'
                                    pControlType                  = "combo_box"
                                    pValue                        = { mSelectedFYObj }
                                    mValue                        = { mSelectedFYCode }
                                    pProcessChange                = { fnProcessChange } 
                                />
                            </div>
                        </div>
                        
                        <div className='text-base text-center font-medium text-900 '>Total Actual Revenue from Each Business - {mSelectedFYCode} - Monthly (INR Cr.)</div>

                        <CSCOBarChart
                                id         = "ID_MONTHLY_REVENUE" 
                                pData      = {mMonthlyRevenue}
                                pChartData = {(mChartIdObjectMap) ? mChartIdObjectMap["ID_MONTHLY_REVENUE"] : null}/>
                    </div>
                </div>

                <div className='col-7 flex p-0 gap-2'>
                    <div className="col-6 shadow-2 mr-3">
                        <div className='flex justify-content-center mb-2'>
                            <div className="w-7">
                                <CSDropDown
                                    display_field_                = 'Financial Year'
                                    data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                                    pDataProviderId               = 'code_'
                                    pDataField                    = 'code_'
                                    pPlaceHolder                  = 'Select a Financial Year'
                                    pRequired                     = { true }
                                    pId                           = 'ID_FY_CODE'
                                    data_provider_display_field_  = 'name_'
                                    pControlType                  = "combo_box"
                                    pValue                        = { mSelectedYtdFYObj }
                                    mValue                        = { mSelectedYtdFYCode }
                                    pProcessChange                = { fnProcessYtdChange } 
                                />
                            </div>
                        </div>

                        <div className='text-base text-center font-medium text-900  mb-5'> Total Actual Revenue YTD of different Companies - {mSelectedYtdFYCode} (INR Cr.)</div>

                        <div className='h-full pb-7'>
                            <CSCODoughnutChart
                                id         = "ID_YTD_REVENUE" 
                                pData      = {mYtdRevenue}
                                pChartData = {(mChartIdObjectMap) ? mChartIdObjectMap["ID_YTD_REVENUE"] : null}/>
                        </div>
                    </div>
                    
                    <div className="col-6 shadow-2">
                        <div className="mb-2 flex justify-content-center">
                            <div className="pr-3 w-5">
                                <CSDropDown
                                    display_field_                = 'Fin Year'
                                    data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                                    pDataProviderId               = 'code_'
                                    pDataField                    = 'code_'
                                    pPlaceHolder                  = 'Select Year'
                                    pRequired                     = { true }
                                    pId                           = 'ID_FY_CODE'
                                    data_provider_display_field_  = 'name_'
                                    pControlType                  = "combo_box"
                                    pValue                        = { mSelectedProductServiceFYObj }
                                    mValue                        = { mSelectedProductServiceFYCode }
                                    pProcessChange                = { fnProcessProductServiceChange } 
                                />
                            </div>
    
                            <div className="w-7">
                                <CSDropDown
                                    display_field_                = 'Company'
                                    data_provider_source_         = 'USER_COMPANY_LIST'
                                    pDataProviderId               = 'company_code_'
                                    pDataField                    = 'company_code_'
                                    pPlaceHolder                  = 'Select a Company'
                                    pRequired                     = { true }
                                    pId                           = 'ID_COMPANY'
                                    data_provider_display_field_  = 'company_name_'
                                    pControlType                  = "combo_box"
                                    pValue                        = { mSelectedCompanyObj }
                                    mValue                        = { mSelectedCompanyCode }
                                    pProcessChange                = { fnProcessProductServiceChange } 
                                />
                            </div>
                        </div>

                        <div className='text-base text-center font-medium text-900 mb-5 '> Actual Revenue from different Products/Services - {mSelectedProductServiceFYCode} (INR Cr.)</div>

                        <div className='h-full pb-7'>
                            <CSCODoughnutChart
                                id         = "ID_YTD_REVENUE_PRODUCT_SERVICE" 
                                pData      = {mYtdProductServiceRevenue}
                                pChartData = {(mChartIdObjectMap) ? mChartIdObjectMap["ID_YTD_REVENUE_PRODUCT_SERVICE"] : null}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    return lForm;
}

export default RevenueMetrics;
