import CSCOBarChart from "../../layout/analysis/CSCOBarChart";
import CSDropDown from '../../controls/CSDropDown';
import { useEffect, useState } from 'react';
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil";
import CSConfigUtil from "../../util/CSConfigUtil";
import CSCOBackdrop from "../../components/CSCOBackdrop";

const BudgetPerformance = (lProps) => {
    const [mSelectedFYCode, setselectedFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mSelectedFYObj, setselectedFYObj] = useState(CSConfigUtil.mActiveFinancialYearObj);

    const [mSelectedCompanyCode, setSelectedCompanyCode] = useState(CSConfigUtil.mCompanyCode);
    const [mSelectedCompanyObj, setSelectedCompanyObj] = useState(CSConfigUtil.mCompanyObj);

    const [mRequestId, setRequestId] = useState(null);
    const [mChartIdObjectMap, setChartIdObjectMap] = useState(null);

    const [mSelectedDeptWiseFYCode, setselectedDeptWiseFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mSelectedDeptWiseFYObj, setselectedDeptWiseFYObj] = useState(CSConfigUtil.mActiveFinancialYearObj);
    const [mBudgetSpent, setBudgetSpent] = useState(null);
    const [mBudgetSpentDeptWise, setBudgetSpentDeptWise] = useState(null);
    const [mShowBackdrop, setShowBackdrop] = useState(false);

    useEffect(() => {
            let lChartIdObjMap = {};
            let lChartRefIdObjMap = {};

            if(lProps.pReportChartProperties)
            {
                for(let lInd=0; lInd<lProps.pReportChartProperties.length; lInd++)
                {
                    let propertyObj = lProps.pReportChartProperties[lInd];
                    if(propertyObj.property_name_ === 'request_id_')
                    {
                        setRequestId(propertyObj.value_);
                    }
                    else if(propertyObj.property_name_ === 'chart_id_')
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.id_];
                        if(!lcpObj)
                        {
                            lcpObj = {};
                            lChartRefIdObjMap[propertyObj.id_] = lcpObj;
                        }

                        lcpObj[propertyObj.property_name_] = propertyObj.value_;
                        lChartIdObjMap[propertyObj.value_] = lcpObj;
                    }
                    else if(propertyObj.parent_ref_id_)
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.parent_ref_id_];
                        if(lcpObj)
                        {
                            if((propertyObj.property_name_ === 'chart_data_field_') || (propertyObj.property_name_ === 'chart_data_label_'))
                            {
                                if(propertyObj.property_name_ === 'chart_data_field_')
                                {
                                    let lChartDataFieldList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataFieldList)
                                    {
                                        lChartDataFieldList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataFieldList;
                                    }

                                    lChartDataFieldList.push(propertyObj.value_);
                                }
                                else
                                {
                                    let lChartDataLabelList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataLabelList)
                                    {
                                        lChartDataLabelList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataLabelList;
                                    }

                                    lChartDataLabelList.push(propertyObj.value_);
                                }
                            }
                            else
                            {
                                lcpObj[propertyObj.property_name_] = propertyObj.value_; 
                            }
                        }
                    }
                }

                setChartIdObjectMap(lChartIdObjMap);
            }
        }, []
    );

    useEffect(() => {
            fnGetReportData(CSConfigUtil.mCurrentFYCode, CSConfigUtil.mCompanyCode);
            fnGetDeptWiseReportData(CSConfigUtil.mCurrentFYCode, CSConfigUtil.mCompanyCode);
        }, [mChartIdObjectMap]
    );

    function fnProcessChange(lData)
    {
        if(lData)
        {
            let lFyCode = mSelectedFYCode;
            let lCompanyCode = mSelectedCompanyCode;

            if(lData.id_ === 'ID_FY_CODE')
            {
                setselectedFYObj(lData);
                setselectedFYCode(lData.value_);
                lFyCode = lData.value_;
            }

            fnGetReportData(lFyCode);
        }
    }

    function fnGetReportData(lFyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && lFyCode)
            {
                setShowBackdrop(true);
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lObj = mChartIdObjectMap['ID_BUDGET_SPENT'];
                            if(lObj && lResponse.hasOwnProperty(lObj.data_field_))
                            {
                                setBudgetSpent(lResponse[lObj.data_field_]);
                            }
                            else
                            {
                                setBudgetSpent(null);
                            }
                        }
                        
                        setShowBackdrop(false);
                    }
                );
            }
        }
    }

    function fnProcessDeptWiseChange(lData)
    {
        if(lData)
        {
            let lFyCode = mSelectedDeptWiseFYCode;
            let lCompanyCode = mSelectedCompanyCode;

            if(lData.id_ === 'ID_FY_CODE')
            {
                setselectedDeptWiseFYObj(lData);
                setselectedDeptWiseFYCode(lData.value_);
                lFyCode = lData.value_;
            }
            else if(lData.id_ === 'ID_COMPANY')
            {
                setSelectedCompanyObj(lData);
                setSelectedCompanyCode(lData.value_);
                lCompanyCode =lData.value_;
            }

            fnGetDeptWiseReportData(lFyCode, lCompanyCode);
        }
    }

    function fnGetDeptWiseReportData(lFyCode, lCompanyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && (lFyCode || lCompanyCode))
            {
                setShowBackdrop(true);
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;
                lCriteria.company_code_ = lCompanyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lObj1 = mChartIdObjectMap['ID_BUDGET_SPENT_DEPT_WISE'];
                            if(lObj1 && lResponse.hasOwnProperty(lObj1.data_field_))
                            {
                                setBudgetSpentDeptWise(lResponse[lObj1.data_field_]);
                            }
                            else
                            {
                                setBudgetSpentDeptWise(null);
                            }
                        }
                        
                        setShowBackdrop(false);
                    }
                );
            }
        }
    }

    const lForm =
        <>
            <CSCOBackdrop pShow = {mShowBackdrop}/>
            <div className="grid justify-content-center w-full gap-4 md:h-5rem lg:h-full pt-2">
                <div className="col-12 lg:col-5 inline-block border-round borderpx shadow-2 justify-content-center ">
                    <div className="flex align-items-center justify-content-center">
                        <div className="w-6">
                            <CSDropDown
                                display_field_                = 'Financial Year'
                                data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                                pDataProviderId               = 'code_'
                                pDataField                    = 'code_'
                                pPlaceHolder                  = 'Select a Financial Year'
                                pRequired                     = { true }
                                pId                           = 'ID_FY_CODE'
                                data_provider_display_field_  = 'name_'
                                pControlType                  = "combo_box"
                                pValue                        = { mSelectedFYObj }
                                mValue                        = { mSelectedFYCode }
                                pProcessChange                = { fnProcessChange } 
                            />
                        </div>
                    </div>
                    
                    <div className='text-base text-center font-medium text-900 my-2'>Budget Spending (INR Cr.) - All Companies </div>
                    
                    <CSCOBarChart
                        id         = "ID_BUDGET_SPENT" 
                        pData      = {mBudgetSpent}
                        pChartData = {(mChartIdObjectMap) ? mChartIdObjectMap["ID_BUDGET_SPENT"] : null}/>
                </div>

                <div className="col-12 lg:col-5 inline-block shadow-2 justify-content-center">
                    <div className="flex align-items-center justify-content-center">
                        <div className="pr-3 w-6">
                            <CSDropDown
                                    display_field_                = 'Financial Year'
                                    data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                                    pDataProviderId               = 'code_'
                                    pDataField                    = 'code_'
                                    pPlaceHolder                  = 'Select Financial Year'
                                    pRequired                     = { true }
                                    pId                           = 'ID_FY_CODE'
                                    data_provider_display_field_  = 'name_'
                                    pControlType                  = "combo_box"
                                    pValue                        = { mSelectedDeptWiseFYObj }
                                    mValue                        = { mSelectedDeptWiseFYCode }
                                    pProcessChange                = { fnProcessDeptWiseChange } 
                            />
                        </div>

                        <div className="w-5">
                            <CSDropDown
                                display_field_                = 'Company'
                                data_provider_source_         = 'USER_COMPANY_LIST'
                                pDataProviderId               = 'company_code_'
                                pDataField                    = 'company_code_'
                                pPlaceHolder                  = 'Select a Company'
                                pRequired                     = { true }
                                pId                           = 'ID_COMPANY'
                                data_provider_display_field_  = 'company_name_'
                                pControlType                  = "combo_box"
                                pValue                        = { mSelectedCompanyObj }
                                mValue                        = { mSelectedCompanyCode }
                                pProcessChange                = { fnProcessDeptWiseChange } 
                            />
                        </div>
                    </div>
                    
                    <div className='text-base text-center font-medium text-900 my-2'>Budget Spending (INR Cr.)</div>
                    
                    <CSCOBarChart
                        id         = "ID_BUDGET_SPENT_DEPT_WISE" 
                        pData      = {mBudgetSpentDeptWise}
                        pChartData = {(mChartIdObjectMap) ? mChartIdObjectMap["ID_BUDGET_SPENT_DEPT_WISE"] : null}/>
                </div>
            </div>
        </>
    return lForm;
}

export default BudgetPerformance;
