import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';
import XMLParser from 'react-xml-parser';

import CSCOGrid from "../components/CSCOGrid";
import CSCOHorizontalActionBar from "../components/CSCOHorizontalActionBar"

class CSImportXml extends Component
{
    constructor(props) 
    {
        super(props);
        this.mIdDataObjectMap = {};
        this.state =
            {
                mDisplay            : this.props.pShow,
                mFileName           : null,
                mFormHeading        : "Xml Import - Column Map",
                mGridColumns        : this.props.pFormGrid,
                mFormActions        : this.props.pFormActions,
                mColumns            : [],
                mColumnMapData      : null,
                mXmlMapData         : (this.props.pFormData && this.props.pFormData.collection_) ? this.props.pFormData.collection_ : null,
                mXmlColumns         : [],
                mXmlColumnMap       : [],
                mXmlData            : [],
                mDataCollection     : [],
                mHeaderRow          : null,
                mOrigXmlColumns     : null,
                mDataNodes          : [],
                mDependentNodes     : [],
                mEnableAction       : false,

                fnProcessData       : this.props.fnProcessData,
                fnProcessClose      : this.props.fnProcessClose
            };

        this.cfBrowseFile           = this.cfBrowseFile.bind(this);
        this.cfSetGridColumns       = this.cfSetGridColumns.bind(this);
        this.cfCloseForm            = this.cfCloseForm.bind(this);
        this.process_close          = this.process_close.bind(this);
        this.process_cancel         = this.process_cancel.bind(this);
        this.process_update         = this.process_update.bind(this);
        this.cfProcessXmlData       = this.cfProcessXmlData.bind(this);
        this.cfGetObject            = this.cfGetObject.bind(this);
        this.cfGetData              = this.cfGetData.bind(this);
        this.cfGetId                = this.cfGetId.bind(this);
        this.cfSetData              = this.cfSetData.bind(this);

        if(this.state.mGridColumns)
        {
            if(!this.state.mColumnMapData)
            {
                this.state.mColumnMapData = [];
            }

            var lColumnObjMap = {}
            for(var lInd=0; lInd<this.state.mGridColumns.length; lInd++)
            {
                var lColumn = this.state.mGridColumns[lInd];
                if(lColumn.column_name_)
                {
                    var lColObj = {};
                    lColObj.required_       = (lColumn.required_ === '1') ? 'Y' : 'N';
                    lColObj.grid_column_    = lColumn.column_name_;

                    lColumnObjMap[lColumn.column_name_] = lColObj;

                }
            }
        }

        var lCollection = null;
        if(this.props.pFormData && this.props.pFormData.collection_)
        {
            lCollection = this.props.pFormData.collection_;
        }
        else if(this.props.pSelectedData && this.props.pSelectedData.collection_)
        {
            lCollection = this.props.pSelectedData.collection_;
        }

        if(lCollection)
        {
            this.state.mXmlColumnMap = lCollection;
            for(var lCInd=0; lCInd<lCollection.length; lCInd++)
            {
                var lCObj = lCollection[lCInd];
                var lColumnObject = lColumnObjMap[lCObj.column_name_];
                if(lColumnObject)
                {
                    lColumnObject['type_']          = lCObj['type_'];
                    lColumnObject['level_']         = lCObj['level_'];
                    lColumnObject['xml_node_']      = lCObj['node_'];
                    lColumnObject['xml_attribute_'] = lCObj['attribute_'];
                    lColumnObject['column_name_']   = lCObj['column_name_'];
                    lColumnObject['data_field_']    = lCObj['data_field_'];

                    this.state.mColumnMapData.push(lColumnObject);
                }
            }
        }

        if(this.props.pFormProperties)
        {
            for(var lfpInd=0; lfpInd<this.props.pFormProperties.length; lfpInd++)
            {
                var lfpObj = this.props.pFormProperties[lfpInd];
                if(lfpObj.property_ === 'data_node_')
                {
                    this.state.mDataNodes.push(lfpObj.value_);
                }
                else if(lfpObj.property_ === 'dependent_node_')
                {
                    this.state.mDependentNodes.push(lfpObj.value_);
                }
            }
        }
    }

    componentDidMount() 
    {
        this.cfSetGridColumns();
    }

    componentDidUpdate(lPrevProps, lPrevState) 
    {
        if(lPrevProps !== this.props)
        {
            this.cfSetGridColumns();
        }
    }

    cfSetGridColumns()
    {
        var lColumnList = [];
        var lColumn0 = {};
        lColumn0.column_name_   = 'Required ?';
        lColumn0.data_field_    = 'required_';
        lColumn0.visible_       = '1';
        lColumn0.width_         = "120px";
        lColumnList.push(lColumn0);

        var lColumn1 = {};
        lColumn1.column_name_   = 'Grid Column';
        lColumn1.data_field_    = 'grid_column_';
        lColumn1.visible_       = '1';
        lColumn1.width_         = "200px";
        lColumnList.push(lColumn1);

        var lColumn3 = {};
        lColumn3.column_name_   = 'XML Node';
        lColumn3.data_field_    = 'xml_node_';
        lColumn3.visible_       = '1';
        lColumn3.width_         = "200px";
        lColumnList.push(lColumn3);

        var lColumn2 = {};
        lColumn2.column_name_   = 'XML Attribute';
        lColumn2.data_field_    = 'xml_attribute_';
        lColumn2.visible_       = '1';
        lColumn2.width_         = "200px";
        lColumnList.push(lColumn2);

        return lColumnList;
    }

    componentWillUnmount()
    {
    }

    cfBrowseFile(event)
    {
        const lFiles = event.target.files;
        if(lFiles && (lFiles.length > 0))
        {
            const lSelectedFile = lFiles[0];
            const lReader = new FileReader();
            if(lSelectedFile)
            {
                lReader.onload = function(evt)
                {
                    var lData = evt.target.result;
                    if(lData)
                    {
                        var xml = new XMLParser().parseFromString(lData);
                        this.cfProcessXmlData(xml);
                    }
                }.bind(this);

                lReader.readAsBinaryString(lSelectedFile);

                this.setState({mEnableAction : true});
            }
        }
    }
    
    cfProcessXmlData(lData)
    {
        if(lData)
        {
            if(this.state.mColumnMapData)
            {
                var lNodeObjectsMap = {}
                for(var lxInd=0; lxInd<this.state.mXmlColumnMap.length; lxInd++)
                {
                    var lxObj = this.state.mXmlColumnMap[lxInd];
                    if(lxObj.node_)
                    {
                        var lCollection = lNodeObjectsMap[lxObj.node_];
                        if(!lCollection)
                        {
                            lCollection = [];
                            lNodeObjectsMap[lxObj.node_] = lCollection;
                        }

                        lCollection.push(lxObj);
                    }
                }

                var lDataCollection = this.cfGetFlatArray(lNodeObjectsMap, lData);
                if(this.state.mDependentNodes && lData && lData.children)
                {
                    for (var ldpInd=0; ldpInd<this.state.mDependentNodes.length; ldpInd++)
                    {
                        var lDependentNode = this.state.mDependentNodes[ldpInd];
                        if(lDependentNode)
                        {
                            for(var lDInd=0; lDInd<lData.children.length; lDInd++)
                            {
                                var lDObj = lData.children[lDInd];
                                if((lDObj.name === lDependentNode) && lDObj.children)
                                {
                                    const lDepNode = lDependentNode;
                                    const lDataTmplt = {}
                                    lDObj.children.forEach(lItem =>
                                        {
                                            var lObjectArray = lNodeObjectsMap[lDepNode];
                                            if(lObjectArray)
                                            {
                                                for(var lobInd=0; lobInd<lObjectArray.length; lobInd++)
                                                {
                                                    if(lObjectArray[lobInd].attribute_ === lItem.name)
                                                    {
                                                        var lDataField = lObjectArray[lobInd].data_field_;
                                                        if(lDataField)
                                                        {
                                                            lDataTmplt[lDataField] = lItem.value;
                                                            break;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    );

                                    var lKeys = Object.keys(lDataTmplt);
                                    for(var lkInd=0; lkInd<lKeys.length; lkInd++)
                                    {
                                        for(var ldcInd=0; ldcInd<lDataCollection.length; ldcInd++)
                                        {
                                            var ldcObj = lDataCollection[ldcInd];
                                            if(ldcObj.hasOwnProperty(lKeys[lkInd]))
                                            {
                                                ldcObj[lKeys[lkInd]] = lDataTmplt[lKeys[lkInd]];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                this.setState({mDataCollection : lDataCollection});
            }
        }
    }

    cfGetFlatArray(lNodeObjectsMap, lData, lKey)
    {
        var lCollection = [];
        if(lNodeObjectsMap && lData)
        {
            if(lData.name === 'Root')
            {
                lData.id_ = this.cfGetId();
                lData.children.forEach(lItem =>
                {
                    lItem.id_ = this.cfGetId();
                    this.mIdDataObjectMap[lItem.id_] = lItem;
                    lItem.parent_ = lData.id_;

                    var lRetVal = this.cfGetData(lNodeObjectsMap, lItem, lData.name);
                    if(lRetVal && (lRetVal.length > 0))
                    {
                        lCollection = lCollection.concat(lRetVal);
                    }
                });
            }
        }

        return lCollection;
    }

    cfGetData(lNodeObjectsMap, lItem, lNode, lDataTmplt)
    {
        var lRetVal = [];
        if(lItem && lNode)
        {
            if(!lDataTmplt)
            {
                if(this.state.mDataNodes.includes(lNode))
                {
                    lDataTmplt = this.cfGetObject();
                }
            }

            lItem.children.forEach(lDataItem =>
                {
                    if(!lDataTmplt)
                    {
                        if(this.state.mDataNodes.includes(lItem.name))
                        {
                            lDataTmplt = this.cfGetObject();
                        }
                    }
                    
                    lDataItem.id_ = this.cfGetId();
                    this.mIdDataObjectMap[lDataItem.id_] = lDataItem;
                    lDataItem.parent_ = lItem.id_;
                    if(lDataItem.children && lDataItem.children.length > 0)
                    {
                        var lcol = this.cfGetData(lNodeObjectsMap, lDataItem, lItem.name, lDataTmplt);
                        if(lcol && (lcol.length > 0))
                        {
                            lRetVal = lRetVal.concat(lcol);
                        }
                    }
                    else
                    {
                        if(lDataTmplt)
                        {
                            var lObjectArray = lNodeObjectsMap[lItem.name];
                            if(lObjectArray)
                            {
                                lDataTmplt['_type_'] = lItem.name;
                                lDataTmplt['_ref_id_'] = lDataItem.id_;
                                lDataTmplt['_parent_ref_id_'] = lDataItem.parent_;
                                for(var lobInd=0; lobInd<lObjectArray.length; lobInd++)
                                {
                                    if(lObjectArray[lobInd].attribute_ === lDataItem.name)
                                    {
                                        var lDataField = lObjectArray[lobInd].data_field_;
                                        if(lDataField)
                                        {
                                            lDataTmplt[lDataField] = lDataItem.value;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            );

            if(lDataTmplt)
            {
                lDataTmplt = this.cfSetData(lDataTmplt, lItem.parent_, lNodeObjectsMap);
                lRetVal.push(lDataTmplt);
            }
        }

        return lRetVal;
    }

    cfSetData(lDataTmplt, lId, lNodeObjectsMap)
    {
        if(lDataTmplt && lId)
        {
            var lParent = this.mIdDataObjectMap[lId];
            if(lParent)
            {
                lParent.children.forEach(lDataItem =>
                    {                
                        var lObjectArray = lNodeObjectsMap[lParent.name];
                        if(lObjectArray)
                        {
                            for(var lobInd=0; lobInd<lObjectArray.length; lobInd++)
                            {
                                if(lObjectArray[lobInd].attribute_ === lDataItem.name)
                                {
                                    var lDataField = lObjectArray[lobInd].data_field_;
                                    if(lDataField)
                                    {
                                        lDataTmplt[lDataField] = lDataItem.value;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                );

                lDataTmplt = this.cfSetData(lDataTmplt, lParent.parent_, lNodeObjectsMap);
            }
        }

        return lDataTmplt;
    }

    cfGetObject()
    {
        var lDataTmplt = {};
        if(this.state.mColumnMapData)
        {
            for(var lCInd=0; lCInd < this.state.mColumnMapData.length; lCInd++)
            {
                var lColObj = this.state.mColumnMapData[lCInd];
                lDataTmplt[lColObj.data_field_] = '';
            }
        }

        return lDataTmplt;
    }

    cfGetId()
    {
        if( !this.cfGetId.counter ) 
        {
            this.cfGetId.counter = 10000;
        }
        
        return this.cfGetId.counter++;
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }
    
    process_update()
    {
        if(this.state.fnProcessData)
        {
            this.state.fnProcessData(this.state.mDataCollection);
            this.cfCloseForm();
        }
    }

    cfCloseForm(lCloseFlag, lRefreshFlag)
    {
        this.state.fnProcessClose(lCloseFlag === false, lRefreshFlag);
    }

    render() 
    {
        var lChooseFileInput = this.state.mFileName;
        if(!lChooseFileInput)
        {
            lChooseFileInput = 
                <div style={{height : "35px", width : "100%"}}>
                    <input id="ID_FILE_INPUT" type="file" onChange={this.cfBrowseFile} accept=".xml"/>
                </div>
        }

        var lGrid = 
            <CSCOGrid
                pData           = { this.state.mColumnMapData }
                pColumns        = { this.cfSetGridColumns() }
                pColumnWidth    = "150px"
                pScrollHeight   = "300px"
                pEnableScroll   = { true}
                pageSize        = { 15 }
                OnItemChange    = {this.cfOnItemChange}
            />

        var lFormActions = null;
        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            var lFormActionList = this.state.mFormActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = this.state.mEnableAction;
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormActions = 
                <CSCOHorizontalActionBar 
                    align       = "left"
                    pType       = 'SAVE_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormActionList}
                />;
        }

        var lForm = 
            <Dialog 
                style=
                {
                    {
                        backgroundColor : "#D8D8D8",
                        paddingBottom   : -40
                    }
                }

                padding     = '5px'
                header      = { this.state.mFormHeading || '.' }
                visible     = {this.state.mDisplay}
                modal       = {true}
                onHide      = { () => this.cfCloseForm(true) }
                appendTo    = { document.body }
                footer      = { lFormActions }>

                <div style={{ height : '100%'}}>
                    { lChooseFileInput }
                    <div style={{width : '800px'}}>
                        { lGrid }
                    </div>
                </div>
            </Dialog>

        return lForm;
    }
}

export default CSImportXml;
