import React, { Component } from "react"

class CSCOLabel1 extends Component
{
    mKey = 100000;
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            mLabelHeading1      : null,
            mLabelHeading2      : null,
            mLabelLogo          : null,

            mLeftOuterText      : null,
            mLeftInnerText      : null,
            mLeftInlineText     : null,
            
            mRightOuterText     : null,
            mRightInnerText     : null,
            mRightInlineText    : null,
            
            mTopOuterText       : null,
            mTopInnerText       : null,
            mTopInlineText      : null,
            
            mBottomOuterText    : null,
            mBottomInnerText    : null,
            mBottomInlineText   : null,

            mQRCodeText         : '',

            mLabelWidth         : '100%',
            mLabelHeight        : '100%',

            mChildren           : null,
            mForegroundColor    : '#000000'
        };

        this.cfSetData = this.cfSetData.bind(this);
    }

    componentDidMount() 
    {
        this.cfSetData();
    }

    cfSetData()
    {
        var lLabelHeading1 = 'GOWTHAM MODEL SCHOOLS';
        if(this.props.pLabelHeading1)
        {
            lLabelHeading1 = this.props.pLabelHeading1;
        }

        var lLabelHeading2 = 'HALL TICKET';
        if(this.props.pLabelHeading2)
        {
            lLabelHeading2 = this.props.pLabelHeading2;
        }

        var lLabelLogo = './images/gms.png';
        if(this.props.pLabelLogo)
        {
            lLabelLogo = this.props.pLabelLogo;
        }

        var lTopInlineText = null;
        if(this.props.pTopInlineText)
        {
            lTopInlineText = 
                <p className="label-top-inline-text">
                    {this.props.pTopInlineText}
                </p>
        }

        var lBottomInlineText = null;
        if(this.props.pBottomInlineText)
        {
            lBottomInlineText = 
                <p className="label-bottom-inline-text">
                    {this.props.pBottomInlineText}
                </p>
        }

        var lLeftInlineText = null;
        if(this.props.pLeftInlineText)
        {
            lLeftInlineText = 
                <p className="label-left-inline-text">
                    {this.props.pLeftInlineText}
                </p>
        }

        var lRightInlineText = null;
        if(this.props.pRightInlineText)
        {
            lRightInlineText = 
                <p className="label-right-inline-text">
                    {this.props.pRightInlineText}
                </p>
        }

        var lTopOuterText = null;
        if(this.props.pTopOuterText)
        {
            lTopOuterText = 
                <p className="label-top-outer-text">
                    {this.props.pTopOuterText}
                </p>
        }

        var lBottomOuterText = null;
        if(this.props.pBottomOuterText)
        {
            lBottomOuterText = 
                <p className="label-bottom-outer-text">
                    {this.props.pBottomOuterText}
                </p>
        }

        var lLeftOuterText = null;
        if(this.props.pLeftOuterText)
        {
            lLeftOuterText = 
                <p className="label-left-outer-text">
                    {this.props.pLeftOuterText}
                </p>
        }

        var lRightOuterText = null;
        if(this.props.pRightOuterText)
        {
            lRightOuterText = 
                <p className="label-right-outer-text">
                    {this.props.pRightOuterText}
                </p>
        }

        var lTopInnerText = null;
        if(this.props.pTopInnerText)
        {
            lTopInnerText = 
                <p className="label-top-inner-text">
                    {this.props.pTopInnerText}
                </p>
        }

        var lBottomInnerText = null;
        if(this.props.pBottomInnerText)
        {
            lBottomInnerText = 
                <p className="label-bottom-inner-text">
                    {this.props.pBottomInnerText}
                </p>
        }

        var lLeftInnerText = null;
        if(this.props.pLeftInnerText)
        {
            lLeftInnerText = 
                <p className="label-left-inner-text">
                    {this.props.pLeftInnerText}
                </p>
        }

        var lRightInnerText = null;
        if(this.props.pRightInnerText)
        {
            lRightInnerText = 
                <p className="label-right-inner-text">
                    {this.props.pRightInnerText}
                </p>
        }

        var lChildren = this.state.mChildren;
        if(this.props.pSection1Text1)
        {
            if(!lChildren)
            {
                lChildren = [];
            }

            var lSection1Text1 = <label key={this.mKey++} className="label-section-1">{this.props.pSection1Text1}</label>
            lChildren.push(lSection1Text1);

            if(this.props.pSection1Text2)
            {
                var lSection1Text2 = <label key={this.mKey++} className="label-section-2">{this.props.pSection1Text2}</label>
                lChildren.push(lSection1Text2);
            }

            if(this.props.pSection1Text3)
            {
                var lSection1Text3 = <label key={this.mKey++} className="label-section-3">{this.props.pSection1Text3}</label>
                lChildren.push(lSection1Text3);
            }

            if(this.props.pSection1Text4)
            {
                var lSection1Text4 = <label key={this.mKey++} className="label-section-4">{this.props.pSection1Text4}</label>
                lChildren.push(lSection1Text4);
            }

            if(this.props.pSection1Text5)
            {
                var lSection1Text5 = <label key={this.mKey++} className="label-section-5">{this.props.pSection1Text5}</label>
                lChildren.push(lSection1Text5);
            }

            if(this.props.pSection1Text6)
            {
                var lSection1Text6 = <label key={this.mKey++} className="label-section-6">{this.props.pSection1Text6}</label>
                lChildren.push(lSection1Text6);
            }
        }

        var lQRCodeText = '';
        if(this.props.pQRCodeText)
        {
            lQRCodeText = this.props.pQRCodeText;
        }

        var lColor = '#000000';
        if(this.props.pColor)
        {
            lColor = this.props.pColor;
        }

        this.setState(
            {
                mLabelHeading1      : lLabelHeading1,
                mLabelHeading2      : lLabelHeading2,
                mLabelLogo          : lLabelLogo,

                mTopInlineText      : lTopInlineText,
                mBottomInlineText   : lBottomInlineText,
                mLeftInlineText     : lLeftInlineText,
                mRightInlineText    : lRightInlineText,
                
                mTopOuterText       : lTopOuterText,
                mBottomOuterText    : lBottomOuterText,
                mLeftOuterText      : lLeftOuterText,
                mRightOuterText     : lRightOuterText,

                mTopInnerText       : lTopInnerText,
                mBottomInnerText    : lBottomInnerText,
                mLeftInnerText      : lLeftInnerText,
                mRightInnerText     : lRightInnerText,
                mQRCodeText         : lQRCodeText,

                mChildren           : lChildren,
                mForegroundColor    : lColor
            }
        );
    }

    render() 
    {
        var lForm = 
            <div className="label-1" key={ this.mKey++ }>
                <div className="header-1">
                    <img alt='Logo' className="logo" src={this.state.mLabelLogo}/>
                    
                    <div className="heading"> 
                        <h1>{this.state.mLabelHeading1}</h1>
                        <h2>{this.state.mLabelHeading2}</h2>
                        <h2>{this.props.pSection1Text5}</h2>
                    </div>

                    <img alt='Seal' className="seal" src="./images/circle-stamp-png-5.png"/>
                </div>

                <div className="form-item-row"> 
                    <div style={{width: '90px', fontWeight: 'bold', textAlign: "start"}}>Student Name</div>
                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                    <div style={{textAlign: "start"}}>{this.props.pSection1Text1}</div>
                </div>

                <div className="form-item-row"> 
                    <div style={{width: '90px', fontWeight: 'bold', textAlign: "start"}}>Father Name</div>
                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                    <div style={{textAlign: "start"}}>{this.props.pSection1Text2}</div>
                </div>

                <div className="form-item-row"> 
                    <div style={{width: '90px', fontWeight: 'bold', textAlign: "start"}}>Admission No</div>
                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                    <div style={{textAlign: "start"}}>{this.props.pSection1Text3}</div>
                    
                    <div style={{width: '25px', fontWeight: 'bold'}}>&nbsp;&nbsp;</div>

                    <div style={{fontWeight: 'bold', textAlign: "start"}}>Class</div>
                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                    <div style={{textAlign: "start"}}>{this.props.pSection1Text4}</div>
                </div>

                <div className="footer-1"> 
                    <div style={{width: '50%', fontWeight: 'bold', textAlign: "start"}}>{this.props.pSection1Text7}</div>
                    <div style={{width: '50%', fontWeight: 'bold', textAlign: "end"}}>Signature of the Principal</div>
                </div>
            </div>

        return lForm;
    }
}

export default CSCOLabel1;
