import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import ReactToPrint from "react-to-print";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import header from '../../assets/images/header.png';
import renewal_header from '../../assets/images/header-renewal.png';
import CSUtil from '../../util/CSUtil';
import CSServiceUtil from '../../util/CSServiceUtil';
import CSRequestUtil from '../../util/CSRequestUtil';
import CSConfigUtil from '../../util/CSConfigUtil';
import { InputTextarea } from 'primereact/inputtextarea';
import CSCOBackdrop from '../../components/CSCOBackdrop';

const ApplicationForm = (lProps) => {
    const mComponentRef = useRef(null);

    const [mAdmissionType,  setAdmissionType]  = useState('JOIN');
    const [mBranchAddress,  setBranchAddress]  = useState('');
    const [mShowBackdrop, setBackdrop]         = useState(false);
    const [mStudentDetails, setStudentDetails] = useState(
        {
            admission_date_      : '',
            reference_name_      : '',
            reference_code_      : '',
            inquiry_no_          : '',
            registration_no_     : '',
            admission_no_        : '',
            class_name_          : '',
            language_1_          : '',
            student_name_        : '',
            gender_              : '',
            religion_            : '',
            caste_               : '',
            date_of_birth_       : '',
            uid_                 : '',
            father_name_         : '',
            father_mobile_       : '',
            father_aadhaar_      : '',
            mother_name_         : '',
            mother_mobile_       : '',
            mother_aadhaar_      : '',
            address_             : '',
            email_id_            : '',
            staff_child_         : '',
            staff_employee_id_   : ''
        }
    );
    
    const [mSiblingDetails, setSiblingDetails] = useState(
        {
            sno_1_            : '',
            name_1_           : '',
            branch_1_         : '',
            class_1_          : '',
            sno_2_            : '',
            name_2_           : '',
            branch_2_         : '',
            class_2_          : '',
            sno_3_            : '',
            name_3_           : '',
            branch_3_         : '',
            class_3_          : ''
        }
    );

    const [mFeeDetails, setFeeDetails] = useState(
        {
            registration_fee_     : '',
            admission_fee_        : '',
            old_dues_             : '',
            net_tuition_fee_      : '',
            instalment_1_         : '',
            instalment_2_         : '',
            instalment_3_         : ''
        }
    );

    useEffect(() => {
            const fnInitializeForm = () => {
                if(lProps.pSelectedActionItem) {
                    let lServiceCode = lProps.pSelectedActionItem.on_click_service_code_;
                    let lRequestId = lProps.pSelectedActionItem.request_id_;
                    let lServiceUtil = CSServiceUtil.cfGetInstance();
                    if(lServiceCode) {
                        let lServiceObject = lServiceUtil.cfGetServiceObject(lServiceCode);
                        if(lServiceObject)
                        {
                            let lCriteriaObj = null;
                            if(lProps.hasOwnProperty('pSelectedData') && lProps.hasOwnProperty('pSelectedCriteria') && lProps.pSelectedData && lProps.pSelectedCriteria) {
                                lCriteriaObj = lProps.pSelectedCriteria;

                                let lBranchCode = null;
                                if(lCriteriaObj.hasOwnProperty('branch_code_') && lCriteriaObj.branch_code_) {
                                    lBranchCode = lCriteriaObj.branch_code_;
                                }
                                else {
                                    lBranchCode = CSConfigUtil.mBranchCode;
                                }
                                
                                let lFormSubTitle1 = CSConfigUtil.mReceiptSubHeading1[lBranchCode];
                                let lFormSubTitle2 = CSConfigUtil.mReceiptSubHeading2[lBranchCode];

                                let lBranchAddress = lFormSubTitle1 + "\n" + lFormSubTitle2;
                                setBranchAddress(lBranchAddress);
                
                                setBackdrop(true);
                                let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                                lRequestUtil.cfProcessRequest((lResponse) =>
                                    {
                                        setBackdrop(false);
                                        if((lResponse) && lResponse.hasOwnProperty("student_details_")) {
                                            let lStudentDetails = lResponse.student_details_[0];
                                            fnSetStudentDetails(lStudentDetails);
                                        }

                                        if((lResponse) && lResponse.hasOwnProperty("sibling_details_")) {
                                            fnSetSiblingDetails(lResponse.sibling_details_);
                                        }

                                        if((lResponse) && lResponse.hasOwnProperty("fee_details_")) {
                                            let lFeeDetails = lResponse.fee_details_[0];
                                            fnSetFeeDetails(lFeeDetails);
                                        }
                                    }
                                );
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, []
    );

    const fnSetStudentDetails = (lDetails) => {
        let lStudentDetails = null;
        if(lDetails) {
            lStudentDetails = {}
            if(lDetails.hasOwnProperty('admission_type_') && lDetails.admission_type_) {
                setAdmissionType(lDetails.admission_type_);
            }
            else {
                lStudentDetails.admission_type_ = 'JOIN';
            }

            if(lDetails.hasOwnProperty('admission_date_') && lDetails.admission_date_) {
                lStudentDetails.admission_date_ = lDetails.admission_date_;
            }
            else {
                lStudentDetails.admission_date_ = '';
            }

            if(lDetails.hasOwnProperty('reference_name_') && lDetails.reference_name_) {
                lStudentDetails.reference_name_ = lDetails.reference_name_;
            }
            else {
                lStudentDetails.reference_name_ = '';
            }

            if(lDetails.hasOwnProperty('reference_code_') && lDetails.reference_code_) {
                lStudentDetails.reference_code_ = lDetails.reference_code_;
            }
            else {
                lStudentDetails.reference_code_ = '';
            }

            if(lDetails.hasOwnProperty('inquiry_no_') && lDetails.inquiry_no_) {
                lStudentDetails.inquiry_no_ = lDetails.inquiry_no_;
            }
            else {
                lStudentDetails.inquiry_no_ = '';
            }

            if(lDetails.hasOwnProperty('registration_no_') && lDetails.registration_no_) {
                lStudentDetails.registration_no_ = lDetails.registration_no_;
            }
            else {
                lStudentDetails.registration_no_ = '';
            }

            if(lDetails.hasOwnProperty('admission_no_') && lDetails.admission_no_) {
                lStudentDetails.admission_no_ = lDetails.admission_no_;
            }
            else {
                lStudentDetails.admission_no_ = '';
            }

            if(lDetails.hasOwnProperty('class_name_') && lDetails.class_name_) {
                lStudentDetails.class_name_ = lDetails.class_name_;
            }
            else {
                lStudentDetails.class_name_ = '';
            }

            if(lDetails.hasOwnProperty('language_1_') && lDetails.language_1_) {
                lStudentDetails.language_1_ = lDetails.language_1_;
            }
            else {
                lStudentDetails.language_1_ = '';
            }

            if(lDetails.hasOwnProperty('student_name_') && lDetails.student_name_) {
                lStudentDetails.student_name_ = lDetails.student_name_;
            }
            else {
                lStudentDetails.student_name_ = '';
            }

            if(lDetails.hasOwnProperty('gender_') && lDetails.gender_) {
                lStudentDetails.gender_ = lDetails.gender_;
            }
            else {
                lStudentDetails.gender_ = '';
            }

            if(lDetails.hasOwnProperty('religion_') && lDetails.religion_) {
                lStudentDetails.religion_ = lDetails.religion_;
            }
            else {
                lStudentDetails.religion_ = '';
            }

            if(lDetails.hasOwnProperty('caste_') && lDetails.caste_) {
                lStudentDetails.caste_ = lDetails.caste_;
            }
            else {
                lStudentDetails.caste_ = '';
            }

            if(lDetails.hasOwnProperty('date_of_birth_') && lDetails.date_of_birth_) {
                lStudentDetails.date_of_birth_ = lDetails.date_of_birth_;
            }
            else {
                lStudentDetails.date_of_birth_ = '';
            }

            if(lDetails.hasOwnProperty('uid_') && lDetails.uid_) {
                lStudentDetails.uid_ = lDetails.uid_;
            }
            else {
                lStudentDetails.uid_ = '';
            }

            if(lDetails.hasOwnProperty('father_name_') && lDetails.father_name_) {
                lStudentDetails.father_name_ = lDetails.father_name_;
            }
            else {
                lStudentDetails.father_name_ = '';
            }

            if(lDetails.hasOwnProperty('father_mobile_') && lDetails.father_mobile_) {
                lStudentDetails.father_mobile_ = lDetails.father_mobile_;
            }
            else {
                lStudentDetails.father_mobile_ = '';
            }

            if(lDetails.hasOwnProperty('father_aadhaar_') && lDetails.father_aadhaar_) {
                lStudentDetails.father_aadhaar_ = lDetails.father_aadhaar_;
            }
            else {
                lStudentDetails.father_aadhaar_ = '';
            }

            if(lDetails.hasOwnProperty('mother_name_') && lDetails.mother_name_) {
                lStudentDetails.mother_name_ = lDetails.mother_name_;
            }
            else {
                lStudentDetails.mother_name_ = '';
            }

            if(lDetails.hasOwnProperty('mother_mobile_') && lDetails.mother_mobile_) {
                lStudentDetails.mother_mobile_ = lDetails.mother_mobile_;
            }
            else {
                lStudentDetails.mother_mobile_ = '';
            }

            if(lDetails.hasOwnProperty('mother_aadhaar_') && lDetails.mother_aadhaar_) {
                lStudentDetails.mother_aadhaar_ = lDetails.mother_aadhaar_;
            }
            else {
                lStudentDetails.mother_aadhaar_ = '';
            }

            if(lDetails.hasOwnProperty('address_') && lDetails.address_) {
                lStudentDetails.address_ = lDetails.address_;
            }
            else {
                lStudentDetails.address_ = '';
            }

            if(lDetails.hasOwnProperty('email_id_') && lDetails.email_id_) {
                lStudentDetails.email_id_ = lDetails.email_id_;
            }
            else {
                lStudentDetails.email_id_ = '';
            }

            if(lDetails.hasOwnProperty('staff_child_') && lDetails.staff_child_) {
                lStudentDetails.staff_child_ = lDetails.staff_child_;
            }
            else {
                lStudentDetails.staff_child_ = '';
            }

            if(lDetails.hasOwnProperty('staff_employee_id_') && lDetails.staff_employee_id_) {
                lStudentDetails.staff_employee_id_ = lDetails.staff_employee_id_;
            }
            else {
                lStudentDetails.staff_employee_id_ = '';
            }
        }
        else {
            lStudentDetails = mStudentDetails;
        }

        setStudentDetails(lStudentDetails);
    }

    const fnSetSiblingDetails = (lDetails) => {
        let lSiblingDetails = {};
        if(lDetails && (lDetails.length > 0)) {
            for (let lInd=0; lInd<lDetails.length; lInd++) {
                let lObj = lDetails[lInd];
                if (lInd === 0){
                    lSiblingDetails.sno_1_ = 1;
                    lSiblingDetails.name_1_ = lObj.name_;
                    lSiblingDetails.branch_1_ = lObj.branch_;
                    lSiblingDetails.class_1_ = lObj.class_;
                }
                else if (lInd === 1){
                    lSiblingDetails.sno_2_ = 2;
                    lSiblingDetails.name_2_ = lObj.name_;
                    lSiblingDetails.branch_2_ = lObj.branch_;
                    lSiblingDetails.class_2_ = lObj.class_;
                }
                else if (lInd === 2){
                    lSiblingDetails.sno_3_ = 3;
                    lSiblingDetails.name_3_ = lObj.name_;
                    lSiblingDetails.branch_3_ = lObj.branch_;
                    lSiblingDetails.class_3_ = lObj.class_;
                }
            }
        }

        setSiblingDetails(lSiblingDetails);
    }

    const fnSetFeeDetails = (lDetails) => {
        let lFeeDetails = null;
        if(lDetails) {
            lFeeDetails = {}
            if(lDetails.hasOwnProperty('registration_fee_') && lDetails.registration_fee_) {
                lFeeDetails.registration_fee_ = lDetails.registration_fee_;
            }

            if(lDetails.hasOwnProperty('admission_fee_') && lDetails.admission_fee_) {
                lFeeDetails.admission_fee_ = lDetails.admission_fee_;
            }

            if(lDetails.hasOwnProperty('old_dues_') && lDetails.old_dues_) {
                lFeeDetails.old_dues_ = lDetails.old_dues_;
            }

            if(lDetails.hasOwnProperty('net_tuition_fee_') && lDetails.net_tuition_fee_) {
                lFeeDetails.net_tuition_fee_ = lDetails.net_tuition_fee_;
            }

            if(lDetails.hasOwnProperty('instalment_1_') && lDetails.instalment_1_) {
                lFeeDetails.instalment_1_ = lDetails.instalment_1_;
            }

            if(lDetails.hasOwnProperty('instalment_2_') && lDetails.instalment_2_) {
                lFeeDetails.instalment_2_ = lDetails.instalment_2_;
            }

            if(lDetails.hasOwnProperty('instalment_3_') && lDetails.instalment_3_) {
                lFeeDetails.instalment_3_ = lDetails.instalment_3_;
            }
        }

        setFeeDetails(lFeeDetails);
    }

    const fnProcessClose = (lEvent) => {
        lProps.fnProcessClose();
    }

    let lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key="1"
            trigger={() =>
                <Button
                    style=
                    {
                        {
                            fontSize: '13.2px',
                            marginRight: '.35em',
                            width: '24px',
                            height: '24px',
                            verticalAlign: 'middle'
                        }
                    }
                    type="Button"
                    icon={CSUtil.mNameIconMap['PI_PRINT']}
                />
            }
            content={() => mComponentRef.current}
        />
    );

    lHeaderActions.push(
        <Button
            key="2"
            style=
            {
                {
                    fontSize: '13.2px',
                    marginRight: '.35em',
                    width: '24px',
                    height: '24px',
                    verticalAlign: 'middle'
                }
            }
            type="Button"
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick={fnProcessClose}
        />
    );

    let lHeader =
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                {lHeaderActions}
            </div>
        </>

    return (
        <Dialog visible={ (lProps.pShow) ? lProps.pShow : false } minHeight='auto' closable={false} appendTo={document.body} header={lHeader} onHide={fnProcessClose}>
            <div id="application" ref={mComponentRef} className="af" >
                <CSCOBackdrop pShow = {mShowBackdrop}/>
                <div className="page" style={{ fontSize: '11px' }}>
                    <div className="subpage">
                        <div className='col-12 flex justify-content-center flex-wrap mb-4 af_pad'>
                            <img src={(mAdmissionType === 'JOIN') ? header : renewal_header} alt="" width='100%' />
                            <p style={{ marginTop: '-38px', width: '380Px', textAlign: 'center', }}>{ mBranchAddress }</p>
                        </div>

                        <div className="grid mb-2 pb-2 border-100 bdashed  ">
                            <div className="col-4 md:col-4 af_pad">
                                <span className="pr-2"> Date</span>
                                <InputText readOnly style={{ padding: '0.1rem' }} className='w-6rem' type="text" value={ mStudentDetails.admission_date_ }/>
                            </div>

                            <div className=" col-4 md:col-4 af_pad">
                                <span className="pr-2">Reference Name</span>
                                <InputText readOnly style={{ padding: '0.1rem' }} className='w-8rem' type="text" value={ mStudentDetails.reference_name_ }/>
                            </div>

                            <div className=" col-4 md:col-4 af_pad">
                                <span className="pr-2 pl-4">Reference Code</span>
                                <InputText readOnly style={{ padding: '0.1rem' }} className='w-7rem' type="text" value={ mStudentDetails.reference_code_ }/>
                            </div>
                        </div>

                        <div className="grid flex align-items-center flex-wrap mb-2 pb-2 mt-1 border-100 border-bottom-100">
                            <div className='col-10 af_pad'>
                                <div className='col-12 flex align-items-center gap-1 af_pad '>
                                    <div className="col-4 md:col-4 af_pad" style={{ display : (mAdmissionType === 'JOIN') ? 'block' : 'none' }}>
                                        <span style={{ paddingRight: '22px' }}> Enquiry No.</span>
                                        <InputText readOnly style={{ padding: '0.1rem' }} className='w-6rem' type="text" value={ mStudentDetails.inquiry_no_ }/>
                                    </div>

                                    <div className="col-2 md:col-2 ml-5 af_pad"></div>

                                    <div className="col-6 md:col-6 af_pad" style={{ display : (mAdmissionType === 'JOIN') ? 'block' : 'none' }}>
                                        <span style={{ paddingRight: '12px' }}> Registration No.</span>
                                        <InputText readOnly style={{ padding: '0.1rem' }} className='w-9rem' type="text" value={ mStudentDetails.registration_no_ }/>
                                    </div>
                                </div>

                                <div className="col-12 flex gap-1 af_pad ">
                                    <div className="col-4 md:col-4 af_pad">
                                        <span style={{ paddingRight: '8px' }}> Student ID No.</span>
                                        <InputText readOnly style={{ padding: '0.1rem' }} className='w-6rem' type="text" value={ mStudentDetails.admission_no_ }/>
                                    </div>

                                    <div className="col-3 md:col-3 af_pad">
                                        <span className="pr-2">Class</span>
                                        <InputText readOnly style={{ padding: '0.1rem' }} className='w-6rem' type="text" value={ mStudentDetails.class_name_ }/>
                                    </div>

                                    <div className=" col-5 md:col-5 af_pad">
                                        <span className="pr-2">1st Language</span>
                                        <InputText readOnly style={{ padding: '0.1rem' }} className='w-9rem' type="text" value={ mStudentDetails.language_1_ }/>
                                    </div>
                                </div>

                                <div className='col-12 flex gap-1 af_pad'>
                                    <div className="col-12 md:col-12 af_pad">
                                        <span style={{ paddingRight: '9px' }}> Student Name</span>
                                        <InputText readOnly style={{ width: '31rem', padding: '0.1rem' }} type="text" value={ mStudentDetails.student_name_ }/>
                                    </div>
                                </div>

                                <div className='col-12 flex gap-1 af_pad'>
                                    <div className="col-4 md:col-4 af_pad">
                                        <span style={{ paddingRight: '45px' }}> Gender</span>
                                        <InputText readOnly className='w-6rem' style={{ padding: '0.1rem' }} type="text" value={ mStudentDetails.gender_ }/>
                                    </div>

                                    <div className="col-4 md:col-4 af_pad">
                                        <span className="pr-2"> Religion</span>
                                        <InputText readOnly className='w-6rem' style={{ padding: '0.1rem' }} type="text" value={ mStudentDetails.religion_ }/>
                                    </div>

                                    <div className="col-4 md:col-4 af_pad">
                                        <span className="pr-2"> Caste</span>
                                        <InputText readOnly style={{ width:'132px', padding: '0.1rem' }} type="text" value={ mStudentDetails.caste_ }/>
                                    </div>
                                </div>

                                <div className="col-12 flex gap-4 af_pad ">
                                    <div className="col-6 md:col-6 af_pad">
                                        <span style={{ paddingRight: '19px' }}> Date of Birth</span>
                                        <InputText readOnly className='w-6rem' style={{ padding: '0.1rem' }} type="text" value={ mStudentDetails.date_of_birth_ }/>
                                    </div>

                                    <div className="col-6 md:col-6 af_pad">
                                        <span className="pr-2">Aadhar Number</span>
                                        <InputText readOnly style={{ padding: '0.1rem', width: '163px', textAlign: 'right' }} type="text" value={ mStudentDetails.uid_ }/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-2 border-1 border-round-sm h-10rem align-self-center text-center" style={{ borderColor: '#a6a6a6' }}>Photo</div>
                        </div>

                        <div className="grid flex align-items-center flex-wrap mb-2 pb-2">
                            <div className="col-12 md:col-12 af_pad  ">
                                <span className="pr-2"> Father's Name/ Guardian's Name</span>
                                <InputText readOnly className='w-35rem' style={{ padding: '0.1rem', width: '529px' }} type="text" value={ mStudentDetails.father_name_ }/>
                            </div>

                            <div className="col-12 md:col-12 af_pad">
                                <span className="pr-2" > Mother's Name</span>
                                <InputText readOnly style={{ width: '621px', padding: '0.1rem', }} type="text" value={ mStudentDetails.mother_name_ }/>
                            </div>

                            <div className='col-12 flex gap-2 af_pad'>
                                <div className="col-6 md:col-6 af_pad">
                                    <span className='pr-2'>Father’s Mobile</span>
                                    <InputText readOnly className='w-15rem' style={{ padding: '0.1rem' }} type="text" value={ mStudentDetails.father_mobile_ }/>
                                </div>
                                <div className="col-6 md:col-6 af_pad">
                                    <span className='pr-2'>Father’s Aadhaar</span>
                                    <InputText readOnly style={{ width: '15rem', padding: '0.1rem', textAlign: 'right' }} type="text" value={ mStudentDetails.father_aadhaar_ }/>
                                </div>
                            </div>

                            <div className='col-12 flex gap-1 af_pad '>
                                <div className="col-6 md:col-6 af_pad">
                                    <span className='pr-1'>Mother's Mobile</span>
                                    <InputText readOnly className='w-15rem' style={{ padding: '0.1rem' }} type="text" value={ mStudentDetails.mother_mobile_ }/>
                                </div>

                                <div className="col-6 md:col-6 af_pad">
                                    <span style={{ paddingRight: '12px' }}>Mother's Aadhar</span>
                                    <InputText readOnly style={{ width: '15rem', padding: '0.1rem', textAlign: 'right' }} type="text" value={ mStudentDetails.mother_aadhaar_ }/>
                                </div>
                            </div>

                            <div className="col-12 md:col-12 af_pad row align-items-center">
                                <label htmlFor="buttondisplay" className="pr-1" style={{width:'90px'}}>Communication Address</label>
                                <InputTextarea readOnly className='h-5rem' autoResize='false' style={{ padding: '0.1rem', width : '618px' }} type="text" value={ mStudentDetails.address_ }/>
                            </div>

                            <div className='col-12 flex gap-1 af_pad'>
                                <div className="col-4 md:col-4 af_pad">
                                    <span style={{paddingRight:'12px'}}>E-Mail ID</span>
                                    <InputText readOnly style={{ padding: '0.1rem', width:'177px' }} type="text" value={ mStudentDetails.email_id_ }/>
                                </div>

                                <div className="col-4 md:col-4 af_pad">
                                    <span style={{ paddingRight: '12px', paddingLeft:'10px' }}>Is Parent employee of GMS?</span>
                                    <InputText readOnly style={{ width: '60px', padding: '0.1rem' }} type="text" value={ mStudentDetails.staff_child_ }/>
                                </div>

                                <div className="col-4 md:col-4 af_pad">
                                    <span style={{ paddingRight: '12px', paddingLeft:'9px' }}>If yes, EMP ID</span>
                                    <InputText readOnly style={{ width: '123px', padding: '0.1rem' }} type="text" value={ mStudentDetails.staff_employee_id_ }/>
                                </div>
                            </div>

                            <div className=" col-12 md:col-12 bdashed mb-1 af_pad">
                                <label htmlFor="buttondisplay" className="block mb-1">Details of Siblings:</label>
                                <table className='w-42rem table mb-1'>
                                    <thead>
                                        <tr>
                                            <th className='w-2rem' >S.No. </th>
                                            <th className='w-20rem'>Name  </th>
                                            <th className='w-10rem'>Branch</th>
                                            <th className='w-5rem' >Class </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{ mSiblingDetails.sno_1_ }</td>
                                            <td>{ mSiblingDetails.name_1_ }</td>
                                            <td>{ mSiblingDetails.branch_1_ }</td>
                                            <td>{ mSiblingDetails.class_1_ }</td>
                                        </tr>

                                        <tr>
                                            <td>{ mSiblingDetails.sno_2_ }</td>
                                            <td>{ mSiblingDetails.name_2_ }</td>
                                            <td>{ mSiblingDetails.branch_2_ }</td>
                                            <td>{ mSiblingDetails.class_2_ }</td>
                                        </tr>

                                        <tr>
                                            <td>{ mSiblingDetails.sno_3_ }</td>
                                            <td>{ mSiblingDetails.name_3_ }</td>
                                            <td>{ mSiblingDetails.branch_3_ }</td>
                                            <td>{ mSiblingDetails.class_3_ }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='col-12 flex gap-1'>
                                <div className="col-8 md:col-8 af_pad" style={{display : (mAdmissionType === 'JOIN') ? 'block' : 'none'}}>
                                    <span style={{paddingRight:'15px' }}>Registration Fee : Rs</span>
                                    <InputText readOnly style={{ padding: '0.1rem', width:'104px', textAlign: 'right' }} type="text" value={ mFeeDetails.registration_fee_ }/>
                                </div>

                                <div className="col-8 md:col-8 af_pad" style={{display : (mAdmissionType === 'JOIN') ? 'none' : 'block'}}>
                                    <span style={{paddingRight:'15px'}}>Old Dues : Rs</span>
                                    <InputText readOnly style={{ padding: '0.1rem', width:'104px', textAlign: 'right' }} type="text" value={ mFeeDetails.old_dues_ }/>
                                </div>

                                <div className="col-4 md:col-4 af_pad" style={{display : (mAdmissionType === 'JOIN') ? 'block' : 'none'}}>
                                    <span style={{ paddingRight: '15px', paddingLeft:'10px'}}>Admission Fee : Rs</span>
                                    <InputText readOnly style={{ width: '104px', padding: '0.1rem', textAlign: 'right' }} type="text" value={ mFeeDetails.admission_fee_ }/>
                                </div>
                            </div>

                            <div className='col-12 flex block mb-2 af_pad' style={{ justifyContent : 'space-between' }}>
                                <p className='pt-2 ml-2 font-medium'> Net Tution Fee</p>

                                <div className="flex flex-column" style={{paddingLeft:'10px', paddingRight: '10px'}}>
                                    <span style={{ textAlign : 'center' }}>Total</span>
                                    <InputText readOnly className='af_netfee-width' style={{ padding: '0.1rem', textAlign: 'right' }} type="text"  value={ mFeeDetails.net_tuition_fee_ }/>
                                </div>

                                <div className="flex flex-column" style={{paddingLeft:'10px', paddingRight: '10px'}}>
                                    <span style={{ textAlign : 'center' }}>1st Term</span>
                                    <InputText readOnly className='af_netfee-width' style={{ padding: '0.1rem', textAlign: 'right' }} type="text"  value={ mFeeDetails.instalment_1_ }/>
                                    <small id="I-Term-Fee-help" style={{ textAlign : 'center' }}>(15th June)</small>
                                </div>

                                <div className="flex flex-column " style={{paddingLeft:'10px', paddingRight: '10px'}}>
                                    <span style={{ textAlign : 'center' }}>2nd Term</span>
                                    <InputText readOnly className='af_netfee-width' style={{ padding: '0.1rem', textAlign: 'right' }} type="text"  value={ mFeeDetails.instalment_2_ }/>
                                    <small id="I-Term-Fee-help" style={{ textAlign : 'center' }}>(15th Sep)</small>
                                </div>

                                <div className="flex flex-column" style={{paddingLeft:'10px', paddingRight: '12px'}}>
                                    <span style={{ textAlign : 'center' }}>3rd Term</span>
                                    <InputText readOnly className='af_netfee-width' style={{ padding: '0.1rem', textAlign: 'right' }} type="text"  value={ mFeeDetails.instalment_3_ }/>
                                    <small id="I-Term-Fee-help" style={{ textAlign : 'center' }}>(15th Nov)</small>
                                </div>
                            </div>

                            <div className="col-12 mt-1 mb-2 text-center flex justify-content-between flex-wrap bdashed af_pad ">
                                <div className="col-3 md:col-4 font-medium">Principal Signature</div>
                                <div className="col-5 md:col-4 font-medium">Accountant Signature</div>
                                <div className="col-4 md:col-4 font-medium">Parent/Guardian Signature</div>
                            </div>

                            <div className='grid'>
                                <div className='text-center w-full font-semibold p-1'>DECLARATION OF THE PARENT / GUARDIAN</div>
                                <ol style={{ fontSize: 10, padding: '0rem 2rem 0rem 2rem', gap: '2rem' }}>
                                    <li>I, Mr./Mrs.  <InputText readOnly className='w-16rem' style={{border:'none', borderBottom: '1px solid', borderRadius:'0px', textAlign: 'center'}} value={ mStudentDetails.father_name_ }/> 
                                        Parent/Guardian of <InputText readOnly className='w-16rem' style={{border:'none', borderBottom: '1px solid', borderRadius:'0px', textAlign: 'center'}} value={ mStudentDetails.student_name_ }/> 
                                        declare that the information given above is true to the best of my knowledge. </li>
                                    <li>D.O.B. .of  the student given above is correct and DOB certificate will be submitted and no alteration  will be demanded in future.</li>
                                    <li>     I Promise to pay the fee promptly and I will not default on/delay the payment of fee at any time. In case of default / delay in payment of fee, I agree to abide by the action taken by school. </li>
                                    <li>    I will withdraw my ward from the school if the Principal feels that the student's presence in the school is detrimental to the interests of the school or his / her progress in studies does not come up to the minimum qualifying marks/ grades of promotion. </li>
                                    <li>     I shall abide by rules and regulations formulated by GOWTHAM MODEL SCHOOL from time to time and I agree that any action taken by the school on any matter concerning my word will be binding on me.</li>
                                    <li>    Availing Transport is optional and depending upon availability </li>
                                    <li>  Any Fee, once paid, will NOT be refunded</li>
                                </ol>
                            </div>

                            <div className="col-12 text-center flex justify-content-between flex-wrap af_pad">
                                <div className="col-3 md:col-4 font-medium">Principal Signature</div>
                                <div className="col-5 md:col-4 font-medium">Accountant Signature</div>
                                <div className="col-4 md:col-4 font-medium">Parent/Guardian Signature</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Dialog>
    );
}

export default ApplicationForm;