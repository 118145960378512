import React, { Component } from "react"

import CSCODetailsHeader1 from "../../components/CSCODetailsHeader1";
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";
import CSCOTreeSelection1 from "../../components/CSCOTreeSelection1";

class CSCODetailsForm14 extends Component 
{
	mNumSections 		= 3;
    mMinSectionItems 	= 7;
    mFormUtil = CSFormUtil.cfGetInstance();
    
    constructor(props)
    {
        super(props);
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mDisplay                : this.props.pShow,
            mAppId                  : this.props.pAppId,
            mFormHeading            : (this.props.pFormHeading !== null) ? this.props.pFormHeading : "",
            mHeaderFormItems        : null,
            mSectionFormItems       : null,
            mFormData               : this.props.pFormData,
            mHeaderData             : null,
            mSectionData            : null,
            mFormOrigData           : CSUtil.cfGetObjectCopy(this.props.pFormData),
            mFormProperties         : this.props.pFormProperties,
            mFormActions            : this.props.pFormActions,
            mFormActionProperties   : this.props.pFormProperties,
            mFormCriteria           : this.props.pFormCriteria,
            mFormCriteriaProperties : this.props.pFormCriteriaProperties,
            mFormItems              : this.props.pFormItems,
            mFormItemProperties	    : this.props.pFormItemProperties,
            mControlType            : this.props.pControlType,
            mEditable               : this.props.pEditable,
            mWidth                  : this.props.pWidth,
            mHeight                 : this.props.pHeight,
            mValue                  : this.props.pValue,
            mTargetData             : null,
            mCollectionFormItems    : null,
            mHeaderFlag             : false,
            mSectionFlag            : false,
            mSelectedTabActionItem  : this.props.pSelectedTabActionItem,
            mSelectedCriteria       : this.props.pSelectedCriteria,

            fnProcessChange         : this.props.fnProcessChange,
            fnProcessSubmit         : this.props.fnProcessSubmit,
            fnProcessClose          : this.props.fnProcessClose
        }

        this.cfProcessChange    = this.cfProcessChange.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);
        this.cfEnableSave       = this.cfEnableSave.bind(this);
        this.cfCloseForm        = this.cfCloseForm.bind(this);
        this.cfSetFormData      = this.cfSetFormData.bind(this);

        this.process_save       = this.process_save.bind(this);
        this.process_cancel     = this.process_cancel.bind(this);
        this.process_close      = this.process_close.bind(this);
        this.process_reset      = this.process_reset.bind(this);
        this.cfInitializeForm   = this.cfInitializeForm.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            if(this.props.pSelectedData.hasOwnProperty('selected_data_'))
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData.selected_data_;
            }
            else
            {
                this.state.mFormData['selected_data_'] = this.props.pSelectedData;
            }
        }

        if(this.props.pSelectedCriteria)
        {
            this.state.mFormData['selected_criteria_'] = this.props.pSelectedCriteria;
        }

        if(this.props.pFormItems && this.props.pSelectedActionItem)
        {
            var lFormItemList = [];
            for(var lfiInd=0; lfiInd<this.props.pFormItems.length; lfiInd++)
            {
                var lFormItem = this.props.pFormItems[lfiInd];
                if(lFormItem.action_ref_id_ === this.props.pSelectedActionItem.id_)
                {
                    lFormItemList.push(lFormItem);
                    
                    if(lFormItem.control_type_ === 'collection')
                    {
                        if(!this.state.mCollectionFormItems)
                        {
                            this.state.mCollectionFormItems = [];
                        }

                        this.state.mCollectionFormItems.push(lFormItem);
                    }                
                }
            }

            this.state.mFormItems = lFormItemList;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'initialize')
                {
                    if (lFormProperty.property_ === 'default_data_field_')
                    {
                        this.state.mDefaultDataField = lFormProperty.value_;
                    }

                    if (lFormProperty.property_ === 'data_')
                    {
                        if(!this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                        {
                            this.state.mFormData[lFormProperty.value_] = {}
                        }
                    }
                    else if(lFormProperty.property_ === 'collection_data_')
                    {
                        this.state.mFormData[lFormProperty.value_] = []
                    }
                    else if(lFormProperty.property_ === 'criteria_field_')
                    {
                        if(!this.state.mFormData['selected_criteria_'])
                        {
                            this.state.mFormData['selected_criteria_'] = {};
                            if(this.props.pSelectedData)
                            {
                                this.state.mFormData.selected_criteria_[lFormProperty.value_] = this.props.pSelectedData[lFormProperty.value_];
                            }
                            else
                            {
                                this.state.mFormData.selected_criteria_[lFormProperty.value_] = {}
                            }
                        }
                    }
                }
            }
        }
    }

    componentDidMount()
    {
        this.cfInitializeForm();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
        }
    }

    cfInitializeForm()
    {
        var lRequestId;
        var lServiceCode;
        var lCriteria;

        if(this.state.mSelectedTabActionItem)
        {
            lRequestId = this.state.mSelectedTabActionItem.request_id_;
            lServiceCode = this.state.mSelectedTabActionItem.on_click_service_code_;
        }

        if(this.state.mFormData && this.state.mFormData.hasOwnProperty('selected_criteria_'))
        {
            lCriteria = this.state.mFormData.selected_criteria_;
        }

        if(lServiceCode)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                    {
                        lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                    }
                }

                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse)=>
                    {
                        this.cfSetFormData(lResponse);
                    }
                );
            }
        }
    }

    cfSetFormData(lData)
    {
        if(lData)
        {
            var lTargetData = null;
            if(this.state.mCollectionFormItems)
            {
                for(var lind=0; lind<this.state.mCollectionFormItems.length; lind++)
                {
                    var lFormItem = this.state.mCollectionFormItems[lind];
                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                    {
                        var lFormItemProperties = lFormItem.cs_form_item_properties_;
                        for(var lFpInd=0; lFpInd<lFormItemProperties.length; lFpInd++)
                        {
                            var lfprop = lFormItemProperties[lFpInd];
                            if((lfprop.type_ === 'property') && lfprop.property_ && lfprop.value_)
                            {
                                if(lfprop.property_ === 'target_collection')
                                {
                                    if(lData.hasOwnProperty(lFormItem.data_field_))
                                    {
                                        lTargetData = lData[lFormItem.data_field_];
                                    }
                                }
                            }
                        }
                    }
                }

                this.setState({mTargetData : lTargetData});
            }
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            if(!lFormData)
			{
                lFormData = {};
                for(var ind=0; ind<this.state.mFormItems.length; ind++)
                {
                    var lFormItem = this.state.mFormItems[ind];
                    lFormData[lFormItem.data_field_] = '';
                    if(lFormItem.control_type_ === 'constant')
                    {
                        lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                }
			}

            if(lData.hasOwnProperty('data_field_') && lData.data_field_)
            {
                lFormData[lData.data_field_] = lData.value_;
            }

            this.setState ({mFormData : lFormData});

            var lTargetData = null;
            if(lData.hasOwnProperty('target_data_') && lData.target_data_)
            {
                lTargetData = lData.target_data_;
                this.setState ({mTargetData : lTargetData});
            }
        }
    }

    cfEnableSave(lValue, lHeaderFlag)
    {
        if(lHeaderFlag === 1)
        {
            this.setState({mHeaderFlag : lValue});
        }
        else
        {
            this.setState({mSectionFlag : lValue});
        }
    }

    cfSetControlValue()
    {
        if((this.state) && (this.state.mFormItems))
        {
            var form_data = this.state.mFormData;
            var lFormItemChildMap = {};
            var lFormItemObjectMap = {};
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                lFormItemObjectMap[form_item.id_] = form_item;
                if(form_item.parent_id_)
                {
                    var lParentItemCollection = lFormItemChildMap[form_item.parent_id_];
                    if(!lParentItemCollection)
                    {
                        lParentItemCollection = [];
                        lFormItemChildMap[form_item.parent_id_] = lParentItemCollection;
                    }

                    lParentItemCollection.push(form_item);
                }

                if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if((form_item.control_type_ === 'text_input') || (form_item.control_type_ === 'constant'))
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }

            var lKeys = Object.keys(lFormItemChildMap);
            var lValues = Object.values(lFormItemChildMap);
            for(var lInd=0; lInd<lKeys.length; lInd++)
            {
                var lParentObj = lFormItemObjectMap[lKeys[lInd]];
                if(lParentObj && form_data[lParentObj.data_field_])
                {
                    var lDataObj = form_data[lParentObj.data_field_];
                    var lParentDataObj = lDataObj[0];
                    if(lValues[lInd].length > 0)
                    {
                        var lDItemObj = lValues[lInd];
                        for(var lDInd=0; lDInd<lDItemObj.length; lDInd++)
                        {
                            var lFormItemObject = lDItemObj[lDInd];
                            var lDataField = lFormItemObject.data_field_;
                            if(lDataField)
                            {
                                var lDataValue = lParentDataObj[lDataField];
                                lFormItemObject.mValue = lDataValue;
                            }
                        }
                    }
                }
            }
        }
    }

    cfCloseForm()
    {
        this.setState({mDisplay : false});
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose();
        }
    }

    process_save(event, lParent, lActionItem)
    {
        if(lActionItem && (lActionItem.on_click_service_code_ || lActionItem.on_click_request_ref_id_))
        {
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lDetailsActionList = null;
        var lSaveActionItem = null;
        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            for(var lDaInd=0; lDaInd<this.state.mFormActions.length; lDaInd++)
            {
                var lDetailsActionItem = this.state.mFormActions[lDaInd]; 
                if(lDetailsActionItem.component_type_ === 'DETAILS_ACTION')
                {
                    if(!lDetailsActionList)
                    {
                        lDetailsActionList = [];
                    }
                    if(lDetailsActionItem.action_type_ === '1')
                    {
                        if(lDetailsActionItem.component_type_ === 'DETAILS_ACTION')
                        {
                            if (lDetailsActionItem.action_ === 'process_save')
                            {
                                lSaveActionItem = lDetailsActionItem;
                            }

                            lDetailsActionItem.mEnabled = this.state.mHeaderFlag && this.state.mSectionFlag;
                        }
                    }
                    else
                    {
                        lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                    }
                    
                    lDetailsActionList.push(lDetailsActionItem);
                }
            }
        }

        var lForm =
            <>
                <CSCODetailsHeader1 pFormItems          = { lFormItemList }
                                    pFormData           = { this.state.mFormData }
                                    pFormProperties     = { this.state.mFormProperties }
                                    pEnableSaveFn       = { this.cfEnableSave }
                                    pSaveActionItem     = { lSaveActionItem }
                                    pFormItemProperties = { this.state.mFormItemProperties }/>

                <CSCOTreeSelection1 pEnableSaveFn               = { this.cfEnableSave }
                                    pFormData                   = { this.state.mFormData }
                                    pTargetData                 = { this.state.mTargetData }
                                    pFormProperties             = { this.state.mFormProperties }
                                    pSaveActionItem             = { lSaveActionItem }
                                    pFormCriteria               = { this.state.mFormCriteria }
                                    pFormCriteriaProperties     = { this.state.mFormCriteriaProperties }
                                    pFormActions                = { this.state.mFormActions }
                                    pFormActionProperties       = { this.state.mFormActionProperties }
                                    pFormItems                  = { lFormItemList }
                                    fnProcessChange             = { this.cfProcessChange }
                                    pFormItemProperties         = { this.state.mFormItemProperties }/>

                <CSCOHorizontalActionBar
                    align       = "left"
                    pType       = 'DETAILS_ACTION_BAR'
                    parent      = { this }
                    actionList  = { lDetailsActionList }/>
            </>
        return lForm;
    }
}

export default CSCODetailsForm14;
