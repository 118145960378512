import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import CSUtil from '../util/CSUtil';
import CSConfigUtil from "../util/CSConfigUtil"
import CSServiceUtil from '../util/CSServiceUtil'
import { Dropdown } from 'primereact/dropdown';

var gServiceUtil = CSServiceUtil.cfGetInstance();

const BranchChange = (lProps) =>
{
    const [mBranch, setBranch]                     = useState(CSUtil.mSelectedInstituteObj);
    const [mBranchList, setBranchList]             = useState(null);
 
    useEffect(() =>
        {
            gServiceUtil.cfGetDataFromDataProvider ('USER_BRANCH_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'USER_BRANCH_LIST')
                    {
                        setBranchList(lCollection);
                    }
                }
            );
        }, []
    );

    const cfSetBranch = () =>
    {
        if(mBranch)
        {
            CSConfigUtil.mBranchCode = mBranch.branch_code_;
            CSConfigUtil.mBranchName = mBranch.branch_name_;
            CSConfigUtil.mBranchType = mBranch.branch_type_;
            CSConfigUtil.mBranchObj  = mBranch;
            CSConfigUtil.mBoardCode  = mBranch.board_code_;
            CSConfigUtil.mBoardName  = mBranch.board_name_;
            gServiceUtil.cfResetDataproviderList();
        }

        lProps.fnProcessClose(true);
    }

    var lFooter =
        <div className="cs-dialog-footer">
            <Button label="Save" icon={CSUtil.mNameIconMap['PI_CHECK']} disabled = {!mBranch} onClick = { () => cfSetBranch() }/>
            <Button label="Back" icon={CSUtil.mNameIconMap['PI_CARET_LEFT']} onClick = { () => lProps.fnProcessClose() }/>
        </div>

    var lForm =
        <Dialog 
            className='cs-dialog-1'
            minheight   = '150px'
            visible = {lProps.pShow} 
            header = "Branch Change" 
            footer = {lFooter} 
            modal = {true} 
            onHide = { () => lProps.fnProcessClose() }>
            <div className="cs-details-section-1" align="center">
                <div className  =   "p-field">
                    <label htmlFor="ID_BRANCH" className="cs-details-section-label" >Branch</label>
                    <Dropdown 
                        id          = "ID_BRANCH" 
                        className   = "cs-details-section-control-1" 
                        type        = "text" 
                        onChange    = {(lItem) => setBranch(lItem.value)}
                        appendTo    = {document.body}                            
                        optionLabel = 'branch_name_'
                        options     = {mBranchList}
                        value       = {mBranch}/>
                </div>
            </div>
        </Dialog>
    return lForm;
}

export default BranchChange;
