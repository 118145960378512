import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import CSUtil from '../util/CSUtil';
import CSConfigUtil from "../util/CSConfigUtil"
import CSServiceUtil from '../util/CSServiceUtil'
import { Dropdown } from 'primereact/dropdown';

var gServiceUtil = CSServiceUtil.cfGetInstance();

const FyChange = (lProps) =>
{
    const [mFinancialYear, setFinancialYear]         = useState(CSUtil.mSelectedFYObj);
    const [mFinancialYearList, setFinancialYearList] = useState(null);

    useEffect(() =>
        {
            gServiceUtil.cfGetDataFromDataProvider ('FINANCIAL_YEAR_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'FINANCIAL_YEAR_LIST')
                    {
                        setFinancialYearList(lCollection);
                    }
                }
            );
        }, []
    );

    const cfSetFinancialYear = () =>
    {
        if(mFinancialYear)
        {
            CSConfigUtil.mCurrentFYCode = mFinancialYear.financial_year_code_;
            CSConfigUtil.mCurrentFYName = mFinancialYear.financial_year_name_;
            gServiceUtil.cfResetDataproviderList();
        }

        lProps.fnProcessClose(true);
    }

    var lFooter =
        <div className="cs-dialog-footer">
            <Button label="Save" icon={CSUtil.mNameIconMap['PI_CHECK']} disabled = {!mFinancialYear} onClick = { () => cfSetFinancialYear() }/>
            <Button label="Back" icon={CSUtil.mNameIconMap['PI_CARET_LEFT']} onClick = { () => lProps.fnProcessClose() }/>
        </div>

    var lForm =
        <Dialog 
            className='cs-dialog-1'
            visible = {lProps.pShow} 
            header = "Financial Year Change" 
            footer = {lFooter} 
            modal = {true} 
            onHide = { () => lProps.fnProcessClose() }>
            <div className="cs-details-section-1" align="center">
                <div className  = "p-field">
                    <label htmlFor="ID_FINANCIAL_YEAR" className="cs-details-section-label">Financial Year</label>
                        <Dropdown 
                            id          = "ID_FINANCIAL_YEAR" 
                            className   = "cs-details-section-control" 
                            type        = "text" 
                            onChange    = {(lItem) => setFinancialYear(lItem.value)}
                            appendTo    = {document.body}                            
                            optionLabel = 'financial_year_name_'
                            options     = {mFinancialYearList}
                            value       = {mFinancialYear}/>
                </div>
            </div>
        </Dialog>
    return lForm;
}

export default FyChange;
