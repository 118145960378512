import React, { createRef } from "react";
import ReactToPrint from "react-to-print";
import {PanelMenu} from 'primereact/panelmenu';
import {Button} from 'primereact/button';
import ReactExport from "react-data-export";
import jsPDF from "jspdf";
import "jspdf-autotable";

import CSUtil from "../../util/CSUtil";
import CSConfigUtil from "../../util/CSConfigUtil";
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil";
import { fnGetFormHeader } from "../../util/CSLayoutUtil";
import { cfGetConstantValue } from "../../util/CSConstantUtil";

import CSCOReportTable from "./CSCOReportTable";
import CSCOReportChart from "./CSCOReportChart";

import CSCOPrintReportTemplate from "../../components/CSCOPrintReportTemplate";
import CSCOReportCriteria1 from "./CSCOReportCriteria1";
import { Dialog } from "primereact/dialog";
import CSCOBackdrop from "../../components/CSCOBackdrop";

//import * as FileSaver from "file-saver";
//import * as XLSX from "xlsx";
import { fnGetDateToString } from "../../util/CSDateTimeUtil";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class CSCOAnalysisLayout1 extends React.Component
{
    mUnmountFlag = false;
    constructor(props)
    {
       super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mFeatureRefId        : this.props.pFeatureRefId,
            mAppId               : this.props.pAppId,
            mFormLayoutHeading   : this.props.pFormLayoutHeading,
            mLayoutType          : this.props.pLayoutType,
            mLayoutCode          : this.props.pLayoutCode,
            mParent              : this.props.pParent,
            mReportList          : this.props.pReportList,
            mMenuList            : null,
            mReportName          : this.props.pReportName,
            mDataCollection      : null,
            mSelectedReport      : this.props.pSelectedReport,
            mReportCriteriaData  : null,
            mCriteriaData        : null,
            mColumnChooser       : false,
            mEnableColumnChooser : false,
            mCsvDownload         : false,
            mPrintFlag           : false,
            mDisplay             : false,
            mShowBackdrop        : false,
            mShowBdExport        : false,
            mIsDashboardReport   : (this.props.pIsDashboardReport) ? this.props.pIsDashboardReport : false
        }
        
        this.cfInitializeLayout     = this.cfInitializeLayout.bind(this);
        this.cfGetReport            = this.cfGetReport.bind(this);
        this.cfGetReportCriteria    = this.cfGetReportCriteria.bind(this);
        this.cfGetReportMenu        = this.cfGetReportMenu.bind(this);
        this.cfProcessMenuClick     = this.cfProcessMenuClick.bind(this);
        this.cfProcessReportTable   = this.cfProcessReportTable.bind(this);
        this.cfProcessReportChart   = this.cfProcessReportChart.bind(this);
        this.cfProcessSearch        = this.cfProcessSearch.bind(this);
        this.cfProcessResponse      = this.cfProcessResponse.bind(this);
        this.cfGetReportData        = this.cfGetReportData.bind(this);
        this.cfProcessPrint         = this.cfProcessPrint.bind(this);
        this.cfColumnChooser        = this.cfColumnChooser.bind(this);
        this.cfCloseColumnChooser   = this.cfCloseColumnChooser.bind(this);
        this.cfClosePrintForm       = this.cfClosePrintForm.bind(this);
        this.cfHide                 = this.cfHide.bind(this);

        this.mComponentRef = createRef();
        this.state.mMenuList = this.cfGetReportMenu();
    }

    componentDidMount()
    {
        this.cfInitializeLayout();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if((this.props.pFeatureRefId !== lPrevProps.pFeatureRefId)
                || (this.props.pSelectedReport !== lPrevProps.pSelectedReport))
            {
                this.setState({mMenuList : this.cfGetReportMenu()});
                this.cfInitializeLayout();
            }
        }
    }

    cfInitializeLayout()
    {
        this.setState(
            {
                mFormLayoutHeading  : this.props.pFormLayoutHeading,
                mFeatureRefId       : this.props.pFeatureRefId,
                mAppId              : this.props.pAppId,
                mLayoutType         : this.props.pLayoutType,
                mLayoutCode         : this.props.pLayoutCode,
                mParent             : this.props.pParent,
                mReportList         : this.props.pReportList,
                mReportName         : this.props.pReportName,
                mSelectedReport     : this.props.pSelectedReport,
                mIsDashboardReport  : (this.props.pIsDashboardReport) ? this.props.pIsDashboardReport : false
            }
        );

        if(this.props.pIsDashboardReport)
        {
            this.cfGetReportData(this.props.pSelectedReport);
        }
    }

    componentWillUnmount()
    {
    }

    cfGetReportMenu()
    {
        var lReportMenu = null;
        CSUtil.cfSort(this.props.pReportList, 'parent_report_', 'sequence_');        
        if(this.props.pReportList && (this.props.pReportList.length > 0))
        {
            var lReportNameChildCollectionMap = {};
            for(var lInd=0; lInd<this.props.pReportList.length; lInd++)
            {
                var lReport = this.props.pReportList[lInd];
                if(!lReport.parent_report_)
                {
                    var lParentMenuItem = lReportNameChildCollectionMap[lReport.report_name_];
                    if(!lParentMenuItem)
                    {
                        lParentMenuItem = {};
                        lParentMenuItem.label = lReport.report_name_;
                        lParentMenuItem.icon = lReport.icon_;
                        lParentMenuItem.sequence_ = lReport.sequence_;
                        lParentMenuItem.command = this.cfProcessMenuClick;
                        lParentMenuItem.data = lReport;
                        lReportNameChildCollectionMap[lReport.report_name_] = lParentMenuItem;
                    }
                    else
                    {
                        lParentMenuItem.icon = lReport.icon_;
                        lParentMenuItem.sequence_ = lReport.sequence_;
                    }
                }
                else
                {
                    var lParentItem = lReportNameChildCollectionMap[lReport.parent_report_];
                    if(!lParentItem)
                    {
                        lParentItem = {};
                        lParentItem.label = lReport.parent_report_;
                        lReportNameChildCollectionMap[lReport.parent_report_] = lParentItem;
                    }

                    if(!lParentItem.items)
                    {
                        lParentItem.items = [];
                    }

                    if(lReport.visible_ === '1')
                    {
                        var lMenuItem = {};
                        lMenuItem.label = lReport.report_name_;
                        lMenuItem.icon = lReport.icon_;
                        lMenuItem.command = this.cfProcessMenuClick;
                        lMenuItem.data = lReport;

                        lParentItem.items.push(lMenuItem)
                    }
                }
            }

            lReportMenu = Object.values(lReportNameChildCollectionMap);
        }

        return lReportMenu;
    }

    cfGetReportData(lReportData)
    {
        if(lReportData)
        {
            this.setState({mFormLayoutHeading : lReportData.heading_});
            var lRequestUtil = null;
            var lCriteria = {};
            lCriteria.app_code_ = CSConfigUtil.mAppCode;
            lCriteria.report_name_ = lReportData.report_name_;
            lCriteria.selected_report_ = lReportData;

            if(lReportData.type_ === 'Table')
            {
                lRequestUtil = new CSRequestUtil(
                        'GET',
                        'REQ_GET_REPORT_DATA', 
                        'cs_report',
                        'REQ_GET_REPORT_DATA');

                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest(this.cfProcessReportTable);
            }
            else if(lReportData.type_ === 'Chart')
            {
                lRequestUtil = new CSRequestUtil(
                    'GET',
                    'REQ_GET_CHART_DATA', 
                    'cs_report',
                    'REQ_GET_CHART_DATA');

                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest(this.cfProcessReportChart);
            }
        }
    }

    cfProcessMenuClick(event)
    {
        this.setState(
            {
                mReportCriteriaData : null,
                mSelectedReport     : null,
                mDataCollection     : null,
                mReportColumns      : null
            }
        );

        if(event && event.hasOwnProperty('item'))
        {
            if(event.item.hasOwnProperty('data'))
            {
                var lReportData = event.item.data;
                this.setState({ mFormLayoutHeading : lReportData.heading_ });
                this.cfGetReportData(lReportData);
            }
            else if(event.item.label)
            {
                this.setState({ mFormLayoutHeading : event.item.label });
            }
        }
        else
        {
            this.setState(
                { 
                    mFormLayoutHeading    : this.props.pFormLayoutHeading,
                    mReportCriteriaData   : null,
                    mSelectedReport       : null,
                    mReportColumns        : null,
                    mEnableColumnChooser  : false
                }
            );
        }
    }

    cfProcessReportTable(lResponse, lRequestCriteria)
    {
        var lSelectedReport = null;
        if(lRequestCriteria)
        {
            lSelectedReport = lRequestCriteria.selected_report_;
        }

        if(lResponse)
        {
            var lReportCriteriaData     = null;
            var lEnableColumnChooser    = false;
            var lReportColumns          = null;
    
            if(lResponse.hasOwnProperty('report_criteria_'))
            {
                lReportCriteriaData = lResponse.report_criteria_;
            }

            if(lResponse.hasOwnProperty('report_table_column_'))
            {
                lReportColumns = lResponse.report_table_column_;
                if(lResponse.hasOwnProperty('report_table_column_properties_'))
                {
                    for (var lRtcInd=0; lRtcInd<lResponse.report_table_column_.length; lRtcInd++)
                    {
                        var lColumn = lResponse.report_table_column_[lRtcInd];
                        for (var lRtcpInd=0; lRtcpInd<lResponse.report_table_column_properties_.length; lRtcpInd++)
                        {
                            var lProperty = lResponse.report_table_column_properties_[lRtcpInd];
                            if(lColumn.id_ === lProperty.cs_report_table_column_ref_id_)
                            {
                                var lPropertiesList = lColumn.report_table_column_properties_;
                                if(!lPropertiesList)
                                {
                                    lPropertiesList = [];
                                    lColumn.report_table_column_properties_ = lPropertiesList;
                                }

                                lPropertiesList.push(lProperty);
                            }
                        }
                    }    
                }

                lEnableColumnChooser = true;
            }

            this.setState(
                {
                    mReportCriteriaData   : lReportCriteriaData,
                    mSelectedReport       : lSelectedReport,
                    mReportName           : (lSelectedReport && lSelectedReport.hasOwnProperty('report_name_')) ? lSelectedReport.report_name_ : '',
                    mReportColumns        : lReportColumns,
                    mEnableColumnChooser  : lEnableColumnChooser
                }
            );
        }
        else
        {
            this.setState(
                {
                    mReportCriteriaData : null,
                    mSelectedReport     : lSelectedReport,
                    mReportColumns      : null
                }
            );
        }
    }

    cfProcessReportChart(lResponse, lRequestCriteria)
    {
        var lSelectedReport = null;
        if(lRequestCriteria)
        {
            lSelectedReport = lRequestCriteria.selected_report_;
        }

        if(lResponse)
        {
            var lReportCriteriaData = null;
            var lReportChart = null;
            var lReportChartProperties = null;
    
            if(lResponse.hasOwnProperty('report_criteria_'))
            {
                lReportCriteriaData = lResponse.report_criteria_;
            }

            if(lResponse.hasOwnProperty('report_chart_'))
            {
                lReportChart = lResponse.report_chart_;
            }

            if(lResponse.hasOwnProperty('report_chart_property_'))
            {
                lReportChartProperties = lResponse.report_chart_property_;
            }

            this.setState(
                {
                    mReportCriteriaData     : lReportCriteriaData,
                    mSelectedReport         : lSelectedReport,
                    mReportChart            : lReportChart,
                    mReportChartProperties  : lReportChartProperties
                }
            );

            if(!lReportCriteriaData)
            {
                this.cfProcessSearch(lReportCriteriaData, lSelectedReport);
            }
        }
        else
        {
            this.setState(
                {
                    mReportCriteriaData     : null,
                    mSelectedReport         : lSelectedReport,
                    mReportChart            : null,
                    mReportChartProperties  : null
                }
            );
        }
    }

    cfGetReportCriteria()
    {
        var lCriteria = null;
        if(this.state.mSelectedReport)
        {
            if(this.state.mReportCriteriaData)
            {
                lCriteria =
                    <CSCOReportCriteria1
                        pReportCriteriaData = {this.state.mReportCriteriaData} 
                        pReport             = {this.state.mSelectedReport} 
                        pOnClick            = {this.cfProcessSearch}/>
            }
        }

        return lCriteria;
    }

    cfGetReport(lReportCriteria)
    {
        var lReport = null;
        if(this.state.mSelectedReport)
        {
            if(this.state.mSelectedReport.type_ === 'Table')
            {
                if(this.state.mReportColumns)
                {
                    lReport =
                        <CSCOReportTable 
                            pReference          = { this.mComponentRef }
                            pReportData         = { this.state.mSelectedReport } 
                            pColumns            = { this.state.mReportColumns } 
                            pCriteria           = { this.state.mCriteriaData}
                            pParent             = { this }
                            pIsDashboardReport  = { this.state.mIsDashboardReport } 
                            pDataCollection     = { this.state.mDataCollection }
                            pColumnChooser      = { this.state.mColumnChooser }
                            pCloseColumnChooser = { this.cfCloseColumnChooser }
                            pExportCsv          = { this.state.mCsvDownload }
                            fnResetCsvFlag      = {() => {this.setState({mCsvDownload : false})} }
                            pScrollHeight       = { '500px'} />
                }
            }
            else if(this.state.mSelectedReport.type_ === 'Chart')
            {
                lReport = 
                    <CSCOReportChart 
                        pReportData             = {this.state.mSelectedReport} 
                        pReportCriteria         = {lReportCriteria} 
                        pReportChart            = {this.state.mReportChart} 
                        pReportChartProperties  = {this.state.mReportChartProperties} 
                        pParent                 = {this} 
                        pIsDashboardReport      = {this.state.mIsDashboardReport}
                        pCloseColumnChooser     = { this.fnEnableColumnChooser }
                        pDataCollection         = {this.state.mDataCollection}
                        pHeight                 = {(lReportCriteria) ? `calc(100%) - 250` : `calc(100%)`}
                    />
            }
        }

        return lReport;
    }

    cfColumnChooser = (lFlag) =>
    {
        this.setState({mColumnChooser : lFlag});
    }

    cfCloseColumnChooser(lColumns)
    {
        this.setState( { mReportColumns : lColumns, mColumnChooser : false } );
    }

    cfExportPDF = () => 
    {
        if(this.state.mDataCollection)
        {
            var lColumnList = [];
			var lDataFieldList = []
            for(var lInd=0; lInd<this.state.mReportColumns.length; lInd++)
            {
                var lColumn = this.state.mReportColumns[lInd];
                lColumnList.push(lColumn.name_);
				lDataFieldList.push(lColumn.data_field_);
            }

            var lDataList = [];
            for(var ldIndex=0; ldIndex<this.state.mDataCollection.length; ldIndex++)
            {
                var ldObj = this.state.mDataCollection[ldIndex];
                var lDataObject = []
                for(var lkInd=0; lkInd<lDataFieldList.length; lkInd++)
                {
                    var lKey = lDataFieldList[lkInd];
                    lDataObject.push(ldObj[lKey]);
                }

                lDataList.push(lDataObject);
            }

            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
            const totalPagesExp = '{total_pages_count_string}'
            doc.autoTable(
                {
                    startY: 50,
                    head: [ lColumnList ],
                    body: lDataList,
                    didDrawPage: data => 
                    {                
                        /*
                         * Header
                         */
                        doc.setFontSize(20)
                        doc.setTextColor(40)
                        doc.setFontStyle('normal')
                        doc.text('Report', marginLeft, 40 )

                        /*
                         * Footer
                         */
                        var str = 'Page ' + doc.internal.getNumberOfPages()
                        if (typeof doc.putTotalPages === 'function') 
                        {
                            str = str + ' of ' + totalPagesExp
                        }

                        doc.setFontSize(10)
                        var pageSize = doc.internal.pageSize
                        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                        doc.text(str, data.settings.margin.left, pageHeight - 10)
                    },

                    margin: { top: 30 },
                }
            )
        
            if (typeof doc.putTotalPages === 'function') 
            {
                doc.putTotalPages(totalPagesExp)
            }

            doc.save("report.pdf")
        }
    }

    cfExportCSV = () =>
    {
        var lSelectedReport = this.state.mSelectedReport;
        if(lSelectedReport && (lSelectedReport.service_code_))
        {
            var lReportName  = lSelectedReport.report_name_ + "_" + fnGetDateToString(new Date());
            var lCriteria = this.state.mCriteriaData;
            var lServiceCode = lSelectedReport.service_code_;
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd0=0; lInd0<lServiceProperties.length; lInd0++)
                        {
                            lCriteriaObj[lServiceProperties[lInd0].property_] = cfGetConstantValue(lServiceProperties[lInd0].value_);
                        }
                    }
                }
                
                lReportName = lReportName.replace(/ /g,"_");
                lReportName = lReportName + ".csv";
                lCriteriaObj['file_name_'] = lReportName;
                var lHeader = [];
                var lColumnDataFields = [];
                if(this.state.mReportColumns)
                {
                    CSUtil.cfSort(this.state.mReportColumns, 'sequence_');
                    for(var lInd=0; lInd<this.state.mReportColumns.length; lInd++)
                    {
                        var lColumn = this.state.mReportColumns[lInd];
                        lColumnDataFields.push(lColumn.data_field_);
                        lHeader.push(lColumn.name_);
                    }
                }
                
                lCriteriaObj['report_header_'] = lHeader;
                lCriteriaObj['report_data_fields_'] = lColumnDataFields;
                lCriteriaObj['limit_'] = '1000000';
                this.setState({mShowBdExport : true});

                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfExportCsvFile(() =>
                    {
                        const lLink = document.createElement('a');
                        lLink.href = CSRequestUtil.mServerUrl + "/data/" + lReportName;
                        lLink.setAttribute('download', lReportName);
                        document.body.appendChild(lLink)
                        lLink.click();
                        lLink.parentNode.removeChild(lLink);
                        this.setState({mShowBdExport : false});
                    }
                );
            }
        }
    }

    cfExceldDownload = () =>
    { 
        var data=this.state.mDataCollection;

        var lExcel = null;
        var lExcelSheet = null;
        var lExcelColumnList = []
        if(this.state.mReportColumns)
        {
            for(var lInd=0; lInd<this.state.mReportColumns.length; lInd++)
            {
                var lColumn = this.state.mReportColumns[lInd];
                var lExcelColumn = <ExcelColumn label={lColumn.name_} value={lColumn.data_field_} key={lInd} />
                lExcelColumnList.push(lExcelColumn);
            }

            if(lExcelColumnList.length > 0)
            {
                var lSelectedReport = this.state.mSelectedReport;
                if(lSelectedReport)
                {
                    lExcelSheet = 
                        <ExcelSheet data={this.state.mDataCollection} name={lSelectedReport.report_name_}>
                            { lExcelColumnList }
                        </ExcelSheet>
                }
            }
        }

        lExcel = 
            <ExcelFile 
                element = 
                    {
                        <Button 
                            disabled = { !data }
                            icon     = { CSUtil.mNameIconMap['PI_FILE_EXCEL'] } 
                            style    = {{ marginRight:'.5em', width: '24px', height: '24px', verticalAlign : 'middle' }} 
                        />
                    } >
                        
                { lExcelSheet }

            </ExcelFile>
            
        return lExcel;
   }

    cfProcessSearch(lCriteria, lSelectedReport)
    {
        if(lSelectedReport && (lSelectedReport.service_code_))
        {
            var lServiceCode = lSelectedReport.service_code_;
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject !== null)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }
                
                this.setState({mShowBackdrop : true, mCriteriaData : lCriteriaObj});        
        
                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest(this.cfProcessResponse);
            }
        }
    }

    cfProcessResponse(response)
    {
        var lCollection = [];
        if(response && response.hasOwnProperty("collection_") && response.collection_)
        {
            if(response.collection_ instanceof Array)
            {
                lCollection = response.collection_;
            }
            else
            {
                lCollection.push(response.collection_);
            }
        }

        this.setState({mDataCollection : lCollection, mDisplay : true, mShowBackdrop : false});
    }

    cfProcessPrint()
    {
        this.setState({ mPrintFlag : true });
    }

    cfClosePrintForm()
    {
        this.setState({ mPrintFlag : false });
    }

    cfHide()
    {
        if(this.state.mDataCollection)
        {
            delete this.state.mDataCollection;
        }
/*        
        caches.keys().then((lNames) => 
            {
                lNames.forEach((lName) => 
                    {
                        caches.delete(lName);
                    }
                );
            }
        );
*/
        this.setState({ mDisplay : false, mDataCollection : null })
    }

    render()
    {
        var lForm = null;
        var lMenuList = this.state.mMenuList;
        if(!lMenuList)
        {
            lMenuList = [];
        }

        var lHeaderActions = 
            <div style={{width: '160px', marginTop: '4px'}}>
                <Button icon     = { CSUtil.mNameIconMap['PI_INFO' ]} 
                        disabled = { !this.state.mEnableColumnChooser }
                        onClick  = { () => this.cfColumnChooser(true) }
                        style    = {{ marginRight:'.5em', width: '24px', height: '24px', verticalAlign : 'middle' }}
                    />
                
                <ReactToPrint
                    trigger={() => 
                        <Button
                            style=
                                {
                                    {
                                        fontSize        : '13.2px', 
                                        marginRight     : '.5em', 
                                        width           : '24px', 
                                        height          : '24px',
                                        verticalAlign   : 'middle'
                                    }
                                } 
                                type="Button" 
                                icon={CSUtil.mNameIconMap['PI_PRINT']} 
                            />
                        }
                    content={() => this.mComponentRef.current}
                />
                
                {
                    /*
                        <Button icon={CSUtil.mNameIconMap['PI_FILE_PDF']} 
                            onClick={() => this.cfExportPDF()}
                            style={{ marginRight:'.5em', width: '24px', height: '24px', verticalAlign : 'middle' }}
                        />
                    */
                }
        
                <Button
                    key = "20"
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                    type="Button" 
                    icon={CSUtil.mNameIconMap['PI_FILE_EXCEL']}
                    onClick= { () => this.setState({mCsvDownload : true}) }
                />
        
                <Button
                    key = "21"
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                    type="Button" 
                    icon={CSUtil.mNameIconMap['PI_TIMES']}
                    onClick= { () => this.setState({mDisplay : false}) }
                />
            </div>

        var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{ this.state.mReportName }</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>
    
        var lMenu = null;
        if(lMenuList.length > 0)
        {
            CSUtil.cfSort(lMenuList, 'sequence_');
            lMenu = <PanelMenu model={ lMenuList } style={{width: '100%', marginTop: '4px'}}/>
        }

        var lReportCriteria = this.cfGetReportCriteria();
        var lReport = this.cfGetReport(lReportCriteria);
        var lReportComponent = null;
        if(lReport)
        {
            lReportComponent = 
                <Dialog visible={this.state.mDisplay}
                    style=
                    {
                        {
                            maxWidth  : '90%',
                            minheight : '650px'
                        }
                    }
                    appendTo    = {document.body}
                    header      = { lHeader }
                    modal       = { true }
                    maximizable = { false }
                    closable    = { false }
                    onHide      = { () => this.cfHide() }>
                    <div style={{ minheight : '590px', height: '100%', width : '100%'}}>
                        <CSCOBackdrop pShow = {this.state.mShowBdExport}/>
                        { lReport }
                    </div>
                </Dialog>
        }
        
        var lHeading = (!this.state.mIsDashboardReport) ? <> { fnGetFormHeader(this.state.mFormLayoutHeading) } </> : null;

        var lReportGroup = (this.state.mIsDashboardReport) 
            ? 
                <div style={{paddingTop: '2px', overflowY: "auto"}}>
                    { lReportCriteria } 
                </div>
            :
                <div style={{height:'100%', width : '100%', paddingTop: '5px'}}>
                    { lReportCriteria }
                </div>

        lForm = 
            <div style={{width: '100%', height: '100%'}}>
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>

                { lHeading }

                <div className="grid">
                    <div className="col-4">{lMenu}</div>
                    <div className="col-8">{lReportGroup}</div>
                </div>

                { lReportComponent }

                <div style={{display : 'none'}}>
                    <CSCOPrintReportTemplate 
                        pReference          = { this.mComponentRef } 
                        pColumns            = { this.state.mReportColumns } 
                        pDataCollection     = { this.state.mDataCollection } 
                        pReportName         = { (this.state.mSelectedReport) ? this.state.mSelectedReport.report_name_ : null }
                        /> 
                </div>
         </div>
        return lForm;
    }
}

export default CSCOAnalysisLayout1;
