import React, { createRef } from "react"
import CSUtil from "../util/CSUtil";
import CSBaseControl from "./CSBaseControl";
import Webcam from "react-webcam";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
class CSImage extends CSBaseControl
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mEnableWebcam : false,
            mBrowseFlag   : false,
            mValue        : this.props.mValue,
            mLoading      : false
        }

        this.mWebcamRef = createRef();
        this.mBrowseRef = createRef();

        this.cfCapture         = this.cfCapture.bind(this);
        this.cfUploadFile      = this.cfUploadFile.bind(this);
        this.cfHandleUserMedia = this.cfHandleUserMedia.bind(this);
        this.cfHandleChange    = this.cfHandleChange.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.setState({mValue : this.props.mValue});
        }
    }

    cfCapture()
    {
        if(this.mWebcamRef.current)
        {
            var lImageSrc = this.mWebcamRef.current.getScreenshot();
            if(this.props.pProcessChange)
            {
                var lData           = {};
                lData.control_type_ = 'image';
                lData.data_field_   = this.props.data_field_;
                lData.value_        = lImageSrc;
                lData.id_           = this.props.id_;
    
                this.props.pProcessChange(lData);
            }
            
            this.setState({mEnableWebcam : false})
        }
    }

    cfHandleUserMedia()
    {
        this.setState({mLoading : false});
    }

    cfUploadFile(lEvent)
    {
        if(lEvent)
        {

        }
    }

    cfHandleChange(lEvent)
    {
        if(lEvent)
        {
            var lImage = URL.createObjectURL(lEvent.target.files[0]);
            if(this.props.pProcessChange)
            {
                var lData           = {};
                lData.control_type_ = 'image';
                lData.data_field_   = this.props.data_field_;
                lData.value_        = lImage;
                lData.id_           = this.props.id_;
    
                this.props.pProcessChange(lData);
                this.setState({mBrowseFlag : false}) 
            }
        } 
    }

    render() 
    {
        const lDisplay = this.state.mLoading ? 'none' : 'block';
        const lVideoConstraints = 
        {
            width     : 220,
            height    : 200,
            facingMode: "user"
        };

        var lImageWidth  = (this.props.width_) ? this.props.width_ : 150;
        var lImageHeight = (this.props.height_) ? this.props.height_ : 150;

        var lFooter = <Button style={{width : '120px', height : '30px'}} icon={CSUtil.mNameIconMap['PI_UPLOAD']} onClick={this.cfCapture} label="Capture" />
        var lWebcam = null;
        if(this.state.mEnableWebcam)
        {
            lWebcam = 
                <Dialog visible={this.state.mEnableWebcam} modal={true} onHide = { () => this.setState({mEnableWebcam : false}) } footer={lFooter}>
                    <Webcam
                        ref                       = {this.mWebcamRef}
                        onUserMedia               = {this.cfHandleUserMedia}
                        screenshotFormat          = "image/jpeg"
                        forceScreenshotSourceSize = "true"
                        videoConstraints          = {lVideoConstraints}
                        style                     = {{lDisplay}}
                        />
                </Dialog>
        }

        var lBrowseObj = null;
        if(this.state.mBrowseFlag)
        {
            lBrowseObj = 
                <Dialog visible={this.state.mBrowseFlag} modal={true} onHide = { () => this.setState({mBrowseFlag : false}) } style={{width : '400px', height : '250px'}}>
                    <input type='file' id='file' onChange={this.cfHandleChange}/>
                </Dialog>
        }

        var lCameraBtn = null;
        var lBrowseBtn = null;
        var lLabel = null;
        var lButtonBar = null;

        if(this.props.pDisplayLabel)
        {
            lLabel = super.cfGetControlLabel();
        }

        if(this.props.pEnableCamera || this.props.pEnableBrowse)
        {
            if(this.props.pEnableCamera)
            {
                lCameraBtn = 
                    <Button
                        icon={CSUtil.mNameIconMap['PI_CAMERA']} 
                        className="header-photo-1"
                        key = '316'
                        id = 'ID_CAMERA'
                        onClick = 
                        {
                            (event) => 
                            {
                                this.setState({mEnableWebcam : true})
                            }
                        }
                    />
            }

            if(this.props.pEnableBrowse)
            {
                lBrowseBtn = 
                    <Button
                        icon={CSUtil.mNameIconMap['PI_FOLDER']} 
                        className="header-photo-2"
                        key = '416'
                        id = 'ID_BROWSE'
                        onClick = 
                        {
                            (event) => 
                            {
                                this.setState({mBrowseFlag : true})
                            }
                        }
                    />
            }

            lButtonBar = 
                <div className="cs-image-buttonbar">
                    { lCameraBtn }

                    { lBrowseBtn }
                </div>
        }


        var lctl = 
            <div className="cs-image-container">
                <div className="cs-image-box">
                    { lLabel }

                    <Zoom>
                        <img
                            width  = { lImageWidth }
                            height = { lImageHeight }
                            src    = { this.state.mValue }
                            alt    = ""/>
                    </Zoom>
                      
                    { lButtonBar }
                </div>

                { lWebcam }

                { lBrowseObj }
            </div>

        return lctl;
    }
}

export default CSImage;
