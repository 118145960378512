import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import CSUtil from '../util/CSUtil';

const CSDao = () =>
{
    const dt = useRef(null);
    const [mDaoList, setDaoList]                       = useState(null);
    const [mDatasourceList, setDatasourceList]         = useState(null);
    const [mTableList, setTableList]                   = useState(null);
    const [mSelectedDao, setSelectedDao]               = useState(null);
    const [mSelectedDataSource, setSelectedDataSource] = useState(null);
    const [mSelectedTable, setSelecteTable]            = useState(null);

    return (
        <>
            <div className="cs-dao-form-item-group" align="center">
                <div className="cs-dao-form-item">
                    <label htmlFor="ID_SERVICE_DAO" className="cs-dao-section-label" >Dao Name</label>
                    <Dropdown id="ID_SERVICE_DAO"  className="cs-dao-section-control" options={mDaoList} optionLabel='dao_name_' onChange={(lItem) => setSelectedDao(lItem.value)}/>
                </div>

                <div className="cs-dao-form-item">
                    <label htmlFor="ID_SOLUTION_DATA_SOURCE" className="cs-dao-section-label" >Data Source</label>
                    <Dropdown id="ID_SOLUTION_DATA_SOURCE" className="cs-dao-section-control" options={mDatasourceList} optionLabel='name_' onChange={(lItem) => setSelectedDataSource(lItem.value)}/>
                </div>

                <div className="cs-dao-form-item">
                    <label htmlFor="ID_TABLE_NAME" className="cs-dao-section-label" >Table Name</label>
                    <Dropdown id="ID_TABLE_NAME" className="cs-dao-section-control" options={mTableList} optionLabel='name_' onChange={(lItem) => setSelecteTable(lItem.value)}/>
                </div>

                <Button icon={CSUtil.mNameIconMap['PI_SEARCH']}  />
            </div>

            <DataTable ref={dt} style={{width : '100%'}}>
                <Column selectionMode="multiple"   style={{width:'2em'}}/>
                <Column field="column_name_"       style={{fontSize:'13px', width:'7em'}}   header="Column Name"    sortable/>
                <Column field="type_"              style={{fontSize:'13px', width:'3em'}}   header="Type"/>
                <Column field="alias_"             style={{fontSize:'13px', width:'3em'}}   header="Alias"/>
                <Column field="ai_name_"           style={{fontSize:'13px', width:'4em'}}   header="AI Name"/>
                <Column field="unique_flag_"       style={{fontSize:'13px', width:'4em'}}   header="Unique ?"/>
                <Column field="criteria_flag_"     style={{fontSize:'13px', width:'4em'}}   header="Criteria ?"/>
                <Column field="generation_flag_"   style={{fontSize:'13px', width:'5em'}}   header="Generation ?"/>
                <Column field="nullable_flag_"     style={{fontSize:'13px', width:'4em'}}   header="Nullable?"/>
            </DataTable>
        </>
    );
}

export default CSDao;
