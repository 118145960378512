import React, { Component, createRef, useRef } from "react";
import PropTypes from "prop-types";
import CSUtil from "../util/CSUtil";
import CSConfigUtil from "../util/CSConfigUtil";
import { Menu } from "primereact/menu";


export default class CSCONavBar extends Component {
    mUtil = CSUtil.cfGetInstance();
    menuRight = createRef(null);

    mItems = [
        {
            label: "Branch ",
            icon: "pi pi-building",
            command: (lEvent) => { this.props.cfBranchChange(lEvent) }
        },
        {
            label: "Academic Year",
            icon: "pi pi-calendar",
            command: (lEvent) => { this.props.cfAyChange(lEvent) }
        },
        {
            label: "Company ",
            icon: "pi pi-building",
            command: (lEvent) => { this.props.cfCompanyChange(lEvent) }
        },
        {
            label: "Financial Year",
            icon: "pi pi-calendar",
            command: (lEvent) => { this.props.cfFyChange(lEvent) }
        },
        {
            label: "Theme",
            icon: "pi pi-circle",
            command: (lEvent) => { this.props.cfThemeChange(lEvent) }
        }
    ];

    constructor(lProps) {
        super(lProps);
        this.state = 
        {
            items : []
        }


        this.cfSetSidebar = this.cfSetSidebar.bind(this);
        this.cfDisplayNotification = this.cfDisplayNotification.bind(this);
    }

    componentDidUpdate(lPrevProps, lPrevState) {
        if(lPrevProps !== this.props)
        {
            let lItems = [];
            if(CSConfigUtil.mCompanyCode) {
                lItems.push(this.mItems[2]);
                lItems.push(this.mItems[3]);
                lItems.push(this.mItems[4]);
            }
            else {
                lItems.push(this.mItems[0]);
                lItems.push(this.mItems[1]);
                lItems.push(this.mItems[4]);
            }

            this.setState({items : lItems});
        }
    }

    cfSetSidebar() {
        this.props.cfSetSidebar();
    }

    cfDisplayNotification() {
        let lFeatureRefId = this.mUtil.cfGetFeatureRefId('COM_EMG_SYSTEM');
        this.props.fnDisplayFeature(lFeatureRefId);
    }

    static gDefaultProps = {
        cfOnToggleMenu: null,
        cfLogout: null,
    };

    static gPropTypes = {
        cfOnToggleMenu: PropTypes.func.isRequired,
        cfLogout: PropTypes.func.isRequired,
    };

    render() {
        let lCalendar = null;
        if (!this.mUtil.cfIsAccessible(CSConfigUtil.gAppId_Calendar)) {
            lCalendar = (
                <button className="p-link">
                    <span className="layout-topbar-item-text">Calendar</span>
                    <span className="layout-topbar-icon pi pi-calendar" />
                </button>
            );
        }

        let lNotifications = null;
        if (this.mUtil.cfIsAccessible(CSConfigUtil.gAppId_Notifications)) {
            lNotifications = (
                <button className="p-link" onClick={this.props.cfAyChange}>
                    <span className="layout-topbar-item-text">Alerts</span>
                    <span className="layout-topbar-icon pi pi-bell" />
                </button>
            );
        }

        let lSettings = null;
        if (this.mUtil.cfIsAccessible(CSConfigUtil.gAppId_Settings)) {
            lSettings = (
                <button className="p-link">
                    <span className="layout-topbar-item-text">Settings</span>
                    <span className="layout-topbar-icon pi pi-cog" />
                </button>
            );
        }

        let lPhoto = CSConfigUtil.mProductLogo;
        if (!lPhoto) {
            lPhoto = <i className="pi pi-user"></i>;
        }

        let lAyName = (CSConfigUtil.mCurrentAYName) ? CSConfigUtil.mCurrentAYName : null;
        let lAyComponent = null;
        if (CSConfigUtil.mCurrentAYName) {
            lAyComponent = (
                <button className="p-link" onClick={this.props.cfAyChange}>
                    <span className="layout-login-user">{lAyName}</span>
                </button>
            );
        }

        let lFyName = (CSConfigUtil.mCurrentFYName) ? CSConfigUtil.mCurrentFYName : null;
        let lFyComponent = null;
        if (CSConfigUtil.mCurrentFYName) {
            lFyComponent = (
                <button className="p-link" onClick={this.props.cfFyChange}>
                    <span className="layout-login-user">{lFyName}</span>
                </button>
            );
        }

        let lBranchComponent = null;
        if (CSConfigUtil.mBranchName) {
            lBranchComponent = (
                <button className="p-link" onClick={this.props.cfBranchChange}>
                    <span className="layout-login-user">{CSConfigUtil.mBranchName}</span>
                </button>
            );
        }

        let lCompanyComponent = null;
        if (CSConfigUtil.mCompanyName) {
            lCompanyComponent = (
                <button 
                    className = "p-link" 
                    onClick   = {this.props.cfCompanyChange}>
                    <span className="layout-login-user">{CSConfigUtil.mCompanyName}</span>
                </button>
            );
        }

        let lNavBar = (
            <div className="layout-topbar">
                <button
                    className="p-link layout-menu-button"
                    onClick={this.props.cfOnToggleMenu}>
                    <span className="pi pi-bars" />
                </button>

                <div className="layout-navigation">
                    <img src={lPhoto} height="48" alt="Logo" />

                    <span className="layout-topbar-item-text">
                        {CSConfigUtil.mProductTitle}
                    </span>
                </div>

                <div className="layout-topbar-icons">
                    {lAyComponent}

                    {lFyComponent}

                    <button
                        type          = "button"
                        className     = "p-link layout-topbar-button"
                        onClick       = {(event) => this.menuRight.current.toggle(event)}
                        aria-controls = "popup_menu_right"
                        aria-haspopup>
                        <i className  = "pi pi-cog"></i>
                    </button>

                    <Menu
                        model = {this.state.items}
                        popup
                        ref   = {this.menuRight}
                        id    = "popup_menu_right"
                        popupAlignment = "center"/>
                    
                    <button type="button" className="p-link layout-topbar-button" onClick={ this.cfDisplayNotification }>
                        <i className="pi pi-bell"></i>
                    </button>
                    
                    <button type="button" className="p-link layout-topbar-button" onClick={ this.cfSetSidebar }>
                        <i className="pi pi-th-large"></i>
                    </button>

                    <button
                        type         = "button"
                        onClick      = {this.props.cfLogout}
                        className    = "p-link layout-topbar-button">
                        <i className = "pi pi-sign-out"></i>
                    </button>
                </div>
            </div>
        );

        return lNavBar;
    }
}
