import React from "react"

import { fnGetForm } from "../../util/CSLayoutUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil"

import CSCOGrid from "../../components/CSCOGrid"
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue } from "../../util/CSConstantUtil";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar";
import CSCOBackdrop from "../../components/CSCOBackdrop";
import { isArray } from "lodash";

class CSCODetailsForm3 extends React.Component
{
    mMinSectionItems 	= 1;
    mDataVariable       = null;
    mCollectionVariable = null;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
       super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mFeatureRefId               : props.pFeatureRefId,
            mAppId                      : props.pAppId,
            mNumSections                : 3,

            mSelectedMenuItem           : this.props.pSelectedMenuItem,
            mFormLayoutHeading          : null,
            mParent                     : this.props.pParent,
            mFormData                   : (this.props.pFormData) ? this.props.pFormData : {},
            mDataCollection             : [],
            mSectionData                : {},
            mSelectedData               : null,
            mSelectedActionItem         : null,
            mFormGrid                   : null,
            mDynamicColumns             : null,
            mFormActions                : this.props.pFormActions,
            mFormItems                  : this.props.pFormItems,
            mFormProperties             : this.props.pFormProperties,
            mFormValidation             : this.props.pFormValidation,
            mFormSelection              : this.props.pFormSelection,
            mFormActionProperties       : this.props.pFormActionProperties,
            mFormItemProperties         : this.props.pFormItemProperties,
            mFormItemComputation        : this.props.pFormItemComputation,
            mFormDetailsActions         : null,
            mFormItemsSearchActions     : null,
            mFormItemsDetailsActions    : null,
            mGridHeading                : null,
            mAddFlag                    : false,
            mDetailsFlag                : false,
            mShowBackdrop               : false,
            mDetailsMetaData            : null,
            mSelectedRecord             : null,
            mSelectedRecords            : null,
            mSectionFormItems           : null,
            mCollectionFormItem         : null,
            mIdFieldObjectMap           : {},
            mDataFieldObjectMap         : {},
            mEditableGrid               : true,
            mEnableFilter               : false,
            mSaveDataObject             : null,
            mChangeFlag                 : false,
            mPrintForm                  : null,
            mEnablePrint                : false,

            fnProcessChange             : this.props.fnProcessChange,
            fnProcessSubmit             : this.props.fnProcessSubmit,
            fnProcessClose              : this.props.fnProcessClose
        }

        this.process_search             = this.process_search.bind(this);
        this.process_add                = this.process_add.bind(this);
        this.process_modify             = this.process_modify.bind(this);
        this.process_delete             = this.process_delete.bind(this);
        this.process_details            = this.process_details.bind(this);
        this.process_close              = this.process_close.bind(this);
        this.process_save               = this.process_save.bind(this);

        this.cfGetFormHeading           = this.cfGetFormHeading.bind(this);
        this.cfGetFormItems             = this.cfGetFormItems.bind(this);
        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfCloseForm                = this.cfCloseForm.bind(this);

        this.cfProcessResponse          = this.cfProcessResponse.bind(this);
        this.cfSetFormData              = this.cfSetFormData.bind(this);
        this.cfInitializeLayout         = this.cfInitializeLayout.bind(this);
        this.cfSearch                   = this.cfSearch.bind(this);
        this.cfOnDataSelection          = this.cfOnDataSelection.bind(this);
        this.cfSetControlValue          = this.cfSetControlValue.bind(this);
        this.cfAddToCollection          = this.cfAddToCollection.bind(this);
        this.cfUpdateCollection         = this.cfUpdateCollection.bind(this);
        this.cfGetRequestObject         = this.cfGetRequestObject.bind(this); 
        this.cfSetDependentFieldData    = this.cfSetDependentFieldData.bind(this);
        this.cfOnGridItemChange         = this.cfOnGridItemChange.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData)
        {
            if(this.props.pSelectedData.hasOwnProperty('selected_data_'))
            {
                if(Array.isArray(this.props.pSelectedData.selected_data_))
                {
                    this.state.mFormData['selected_data_'] = this.props.pSelectedData.selected_data_[0];
                }
                else
                {
                    this.state.mFormData['selected_data_'] = this.props.pSelectedData.selected_data_;
                }
            }
            else
            {
                if(Array.isArray(this.props.pSelectedData))
                {
                    this.state.mFormData['selected_data_'] = this.props.pSelectedData[0];
                }
                else
                {
                    this.state.mFormData['selected_data_'] = this.props.pSelectedData;
                }
            }
        }
        
        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'initialize')
                {
                    if(this.state.mFormData)
                    {
                        if (lFormProperty.property_ === 'data_')
                        {
                            this.mDataVariable = lFormProperty.value_;
                            if(this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                this.state.mSectionData = this.state.mFormData[lFormProperty.value_];
                            }
                            else
                            {
                                this.state.mSectionData = {}
                                this.state.mFormData[lFormProperty.value_] = this.state.mSectionData;
                            }
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            this.mCollectionVariable = lFormProperty.value_;
                            if(this.state.mFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                this.state.mDataCollection = this.state.mFormData[lFormProperty.value_];
                            }
                        }
                    }
                }
                else if (lFormProperty.type_ === 'save_data')
                {
                    if(lFormProperty.property_ === 'data_')
                    {
                        this.state.mSaveDataObject = lFormProperty.value_;
                    }
                }
                else if (lFormProperty.type_ === 'layout')
                {
                    if(lFormProperty.property_ === 'max_sections_')
                    {
                        this.state.mNumSections = lFormProperty.value_;
                    }
                }
            }
        }
    }

    cfGetDimensions(lId)
    {
        var lHeight;
        if(lId)
        {
            var lElement = document.getElementById(lId);
            if(lElement)
            {
                var lRect = lElement.getBoundingClientRect();
                if(lRect)
                {
                    lHeight = lRect.height
                }
            }
        }

        return lHeight;
    }

    componentDidMount()
    {
        this.cfInitializeLayout(true);
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeLayout();
        }
    }

    cfInitializeLayout(lResetFlag = false)
    {
        let lFormTitle = null;
        let lFormGrid = [];
        let lFormItemsSearchActions = [];
        let lFormItemsDetailsActions = [];
        let lFormDetailsActions = [];
        let lGridHeading = null;
        let lEnableFilter = false;
        let lDataCollection = (this.props.pDataCollection ) ? this.props.pDataCollection : this.state.mDataCollection ;

        let lSelectedActionItem = this.props.pSelectedActionItem;
        if(lSelectedActionItem)
        {
            lFormTitle = lSelectedActionItem.title_;
        }

        let lFormItemCollection = this.props.pFormItems;
        let lSectionFormItems   = [];
        let lSelectionItem      = null;
        let lCollectionFormItem = null;
        let lIdFieldObjectMap   = {};
        let lDataFieldObjectMap = {};
        let lEditableGrid       = this.state.mEditableGrid;
        let lSectionData        = this.state.mSectionData;
        let lFormData           = this.state.mFormData;

        if(this.mDataVariable && this.props.pSelectedData) {
            if(this.props.pSelectedData.hasOwnProperty('selected_data_')){
                if(Array.isArray(this.props.pSelectedData.selected_data_))
                {
                    lFormData['selected_data_'] = this.props.pSelectedData.selected_data_[0];
                }
                else
                {
                    lFormData['selected_data_'] = this.props.pSelectedData.selected_data_;
                }
            }
            else
            {
                if(Array.isArray(this.props.pSelectedData))
                {
                    lFormData['selected_data_'] = this.props.pSelectedData[0];
                }
                else
                {
                    lFormData['selected_data_'] = this.props.pSelectedData;
                }
            }
		
            if(lFormData.hasOwnProperty(this.mDataVariable))
            {
                lSectionData = lFormData[this.mDataVariable];
            }
            else
            {
                lSectionData = {}
                lFormData[this.mDataVariable] = lSectionData;
            }
        }

        if(lFormItemCollection)
        {
            for (var lItemInd=0; lItemInd<lFormItemCollection.length; lItemInd++)
            {
                var lFormItem = lFormItemCollection[lItemInd];
                if(lResetFlag)
                {
                    lFormItem.mValue = null;
                }

                lIdFieldObjectMap[lFormItem.id_] = lFormItem;
                lDataFieldObjectMap[lFormItem.data_field_] = lFormItem;

                if(!lFormItem.parent_id_ &&  (lFormItem.control_type_ !== 'collection'))
                {
                    lSectionFormItems.push(lFormItem);
                }

                if(lFormItem.grid_display_ === '1')
                {
                    let lItemEditor    = null;
                    let lItemRenderer  = null;
                    let lAlign         = 'center';
                    let lColumn = CSUtil.cfGetObjectCopy(lFormItem);
                    if(lColumn.hasOwnProperty('cs_form_item_properties_'))
                    {
                        var lProperties = lColumn.cs_form_item_properties_;
                        for(var lInd=0; lInd<lProperties.length; lInd++)
                        {
                            var lProperty = lProperties[lInd];
                            if(lProperty.property_ === 'item_editor')
                            {
                                lItemEditor = lProperty.value_;
                            }

                            if(lProperty.property_ === 'item_renderer')
                            {
                                lItemRenderer = lProperty.value_;
                            }

                            if(lProperty.property_ === 'alignment')
                            {
                                lAlign = lProperty.value_;
                            }

                            if(lProperty.property_ === 'grouping_field')
                            {
                                lColumn.grouping_field_ = '1';
                            }
                        }
                    }

                    lColumn.column_name_ = lColumn.display_label_;
                    if(lItemEditor)
                    {
                        lColumn.item_editor_ = lItemEditor;
                        if(lColumn.control_type_ === 'combo_box')
                        {
                            if(lColumn.data_provider_source_)
                            {
                                this.mCSServiceUtil.cfGetDataFromDataProvider (lColumn.data_provider_source_, 
                                    (lDataProviderId, lCollection) => 
                                    {
                                        if( lDataProviderId === lColumn.data_provider_source_)
                                        {
                                            lColumn.data_provider_ = lCollection;
                                        }
                                    }
                                );
                            }
                        }
                    }
                    else if(lItemRenderer)
                    {
                        lColumn.item_renderer_ = lItemRenderer;
                    }
                    else if(lColumn.control_type_ === 'check_box')
                    {
                        lColumn.item_renderer_ = 'MULTI_SELECT_RENDERER_1';
                        lSelectionItem = lColumn;
                    }

                    if(!lColumn.item_editor_)
                    {
                        lColumn.editable_ = '0';
                    }

                    lColumn.alignment_ = lAlign;
                    lFormGrid.push(lColumn);
                }
                else if(lSectionData && lSectionData.hasOwnProperty(lFormItem.data_field_))
                {
                    lFormItem.mValue = lSectionData[lFormItem.data_field_]
                }

                if(lFormItem.control_type_ === 'collection')
                {
                    lCollectionFormItem = lFormItem;
                    if(lFormItem.display_label_)
                    {
                        lGridHeading = lFormItem.display_label_;
                    }

                    if(lFormItem.editable_ === '1')
                    {
                        lEditableGrid = true;
                    }
                    else
                    {
                        lEditableGrid = false;
                    }

                    if(!lDataCollection)
                    {
                        if(this.props.hasOwnProperty('pFormData') && this.props.pFormData )
                        {
                            if(this.props.pFormData.hasOwnProperty(lFormItem.data_field_))
                            {
                                lDataCollection = this.props.pFormData[lFormItem.data_field_];
                            }
                        }
                    }

                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                    {
                        var lfiProperties = lFormItem.cs_form_item_properties_;
                        for(var lfiInd=0; lfiInd<lfiProperties.length; lfiInd++)
                        {
                            var lfiProperty = lfiProperties[lfiInd];
                            if((lfiProperty.type_ === 'filter_data') && (lfiProperty.property_ === 'advanced_grid'))
                            {
                                lEnableFilter = (lfiProperty.value_ === '1');
                            }
                        }
                    }
                }
            }
        }

        var lSelectedRecords  = null;
        if(lDataCollection && lSelectionItem)
        {
            let lCompareConstant = (lSelectionItem.constant_) ? lSelectionItem.constant_ : '1';
            for(var lcInd = 0; lcInd<lDataCollection.length; lcInd++)
            {
                var lcObj = lDataCollection[lcInd];
                if(lcObj.hasOwnProperty(lSelectionItem.data_field_))
                {
                    if (lcObj[lSelectionItem.data_field_] === lCompareConstant)
                    {
                        if(!lSelectedRecords)
                        {
                            lSelectedRecords = []
                        }

                        lSelectedRecords.push(lcObj);
                    }
                }
            }
        }

        var lFormActions = this.props.pFormActions;
        if(lFormActions)
        { 
            for (var lActionInd=0; lActionInd<lFormActions.length; lActionInd++)
            {
                var lFormAction = lFormActions[lActionInd];
                if(lFormAction.component_type_ === 'DETAILS_ACTION')
                {
                    if(lFormAction.parent_ref_id_ && (this.props.pSelectedActionItem) && (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormDetailsActions.push(lFormAction);
                    }
                }
                else if(lFormAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                {
                    if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormItemsSearchActions.push(lFormAction);
                    }
                }
                else if(lFormAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION')
                {
                    if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormItemsDetailsActions.push(lFormAction);
                    }
                }
            }
        }

        if(lFormGrid)
        {
            if(this.state.mDynamicColumns)
            {
                lFormGrid.push(...this.state.mDynamicColumns);
            }

            CSUtil.cfSort(lFormGrid, 'sequence_')            
        }

        if(lFormItemsSearchActions)
        {
            CSUtil.cfSort(lFormItemsSearchActions, 'sequence_')            
        }

        if(lFormDetailsActions)
        {
            CSUtil.cfSort(lFormDetailsActions, 'sequence_')            
        }

        this.setState(
            {
                mFormLayoutHeading          : lFormTitle,
                mFormGrid                   : lFormGrid,
                mFormItemsSearchActions     : lFormItemsSearchActions,
                mFormItemsDetailsActions    : lFormItemsDetailsActions,
                mFormDetailsActions         : lFormDetailsActions,
                mGridHeading                : lGridHeading,
                mSectionFormItems           : lSectionFormItems,
                mCollectionFormItem         : lCollectionFormItem,
                mDataFieldObjectMap         : lDataFieldObjectMap,
                mIdFieldObjectMap           : lIdFieldObjectMap,
                mEditableGrid               : lEditableGrid,
                mEnableFilter               : lEnableFilter,
                mFormData                   : lFormData,
                mSectionData                : lSectionData,
                mDataCollection             : lDataCollection,
                mSelectedRecords            : lSelectedRecords
            }
        );
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormActions.length; lInd++)
            {
                var lFormAction = this.state.mFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    cfSetFormData(lCollection)
    {
        if(this.props !== null)
        {
            var lFeatureRefId = null;
            var lMenuItem = null;
            if(this.props.pFeatureRefId)
            {
                lFeatureRefId = this.props.pFeatureRefId;
            }
            else if(this.props.hasOwnProperty('pParent') && this.props.pParent.hasOwnProperty('pFeatureRefId'))
            {
                lFeatureRefId = this.props.pParent.pFeatureRefId;
            }

            if(lFeatureRefId)
            {
                lMenuItem = this.mCSUtil.cfGetMenuItem(lFeatureRefId);
            }

            var lSearchActionsList = [];
            var lFormActions = this.props.pFormActions;
            if((lFormActions !== undefined) && (lFormActions !== null))
            {
                for(var ind=0; ind<lFormActions.length; ind++)
                {
                    if(lFormActions[ind].component_type_ === 'SEARCH_ACTION')
                    {
                        lSearchActionsList.push(lFormActions[ind]);
                    }
                }
            }

            this.setState(
                {
                    mFeatureRefId           : this.props.pFeatureRefId,
                    mSelectedMenuItem       : lMenuItem,
                    mSelectedActionItem     : null,
                    mParent                 : this.props.pParent,
                    mFormActions            : this.props.pFormActions,
                    mFormItems              : this.props.pFormItems,
                    mFormGrid               : this.props.pFormGrid,
                    mFormProperties         : this.props.pFormProperties,
                    mFormValidation         : this.props.pFormValidation,
                    mFormSelection          : this.props.pFormSelection,
                    mFormActionProperties   : this.props.pFormActionProperties,
                    mFormGridProperties     : this.props.pFormGridProperties,
                    mFormGridItemProperties : this.props.pFormGridItemProperties,
                    mFormItemProperties     : this.props.pFormItemProperties,
                    mFormItemComputation    : this.props.pFormItemComputation,
                    mSearchActionList       : lSearchActionsList,
                    mDataCollection         : lCollection
                }
            );
        }
    }

    process_search(lCriteria, lActionItem)
    {
        this.setState({mCriteria : lCriteria, mActionItem : lActionItem});
        if(lActionItem && lActionItem.on_click_service_code_)
        {
            this.cfSearch(lActionItem.on_click_service_code_, lCriteria);
        }
    }

    cfSearch(lServiceCode, lCriteria)
    {
        if(lServiceCode !== null)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject !== null)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }
                
                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest(this.cfProcessResponse);
            }
        }
    }

    process_add(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : CSUtil.cfGetObjectCopy(this.cfGetFormItems(event.currentTarget.id)),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pResponseFn             : this.cfAddToCollection,
                pSelectedActionItem     : lActionItem,
                pParentActionItem       : this.props.pSelectedActionItem,
                pDataFieldObjectMap     : this.state.mDataFieldObjectMap
            };

            this.setState(
                {
                    mAddFlag             : true,
                    mDetailsMetaData     : lDetailsMetaData,
                    mDetailsFlag         : true,
                    mSelectedActionItem  : lActionItem
                }
            );
        }
    }
    
    cfAddToCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            var lCollection = this.state.mDataCollection;
            if(!lCollection)
            {
                lCollection = [];
            }

            lCollection.push(lRecord);

            this.setState(
                {
                    mDataCollection : lCollection
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }

    process_modify(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }
            
            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : CSUtil.cfGetObjectCopy(this.cfGetFormItems(event.currentTarget.id)),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pResponseFn             : this.cfUpdateCollection,
                pSelectedActionItem     : lActionItem,
                pDataFieldObjectMap     : this.state.mDataFieldObjectMap,
                pParentActionItem       : this.props.pSelectedActionItem
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = this.state.mSelectedRecord;
                this.setState(
                    {
                        mAddFlag             : false,
                        mDetailsMetaData     : lDetailsMetaData,
                        mDetailsFlag         : true,
                        mSelectedData        : lDataObj,
                        mSelectedActionItem  : lActionItem
                    }
                );
            }
        }
    }
    
    cfUpdateCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            this.setState(
                {
                    mSelectedRecord : lRecord
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }
    
    process_details(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pDataFieldObjectMap     : this.state.mDataFieldObjectMap,
                pFormValidation         : this.state.mFormValidation
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = this.state.mSelectedRecord;
                this.setState(
                    {
                        mAddFlag            : false,
                        mDetailsMetaData    : lDetailsMetaData,
                        mDetailsFlag        : true,
                        mSelectedData       : lDataObj,
                        mSelectedActionItem : lActionItem
                    }
                );
            }
        }
    }

    process_delete(event, lParent)
    {
        var lSelectedData = this.state.mSelectedRecord;
        if(lSelectedData)
        {
            var lDataCollection = this.state.mDataCollection;
            const lItemIndex = lDataCollection.findIndex(lItem => 
                {
                    var lFlag = true;
                    var lKeys = Object.keys(lItem);
                    for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                    {
                        var lKey = lKeys[lKeyInd];
                        if(lSelectedData[lKey] !== lItem[lKey])
                        {
                            lFlag = false;
                            break;
                        }
                    }

                    return lFlag;
                }
            );

            lItemIndex !== -1 && lDataCollection.splice(lItemIndex , 1);

            this.setState({mDataCollection : lDataCollection});
        }
    }

    cfProcessResponse(lResponse)
    {
        var lCollection = [];
        if(lResponse)
        {
            if (lResponse.hasOwnProperty("result") && lResponse.result.hasOwnProperty("collection_"))
            {
                if(lResponse.result.collection_ instanceof Array)
                {
                    lCollection = lResponse.result.collection_;
                }
                else
                {
                    lCollection.push(lResponse.result.collection_);
                }
            }
            else if(lResponse.collection_)
            {
                if(lResponse.collection_ instanceof Array)
                {
                    lCollection = lResponse.collection_;
                }
                else
                {
                    lCollection.push(lResponse.collection_);
                }
            }
        }

        this.cfSetFormData(lCollection);
    }

    cfCloseForm()
    {
        this.setState({ mDetailsFlag : false });
    }

    process_close(lCloseFlag, lRefreshFlag)
    {
        if(lCloseFlag && this.state.fnProcessClose)
        {
            let lParent = null;
            if(this.props.hasOwnProperty('pParent')) {
                lParent = this.props.pParent;
            }

            this.state.fnProcessClose(lCloseFlag === false, lRefreshFlag, lParent);
        }
    }

    cfOnGridItemChange(lData, lRowData, lRowIndex, lValue, lColumn)
    {
        if(lData && this.state.mIdFieldObjectMap)
        {
            var lChangedFormItem = null;
            var lCompData = null;
            if(lColumn && lRowData)
            {
                lChangedFormItem = this.state.mIdFieldObjectMap[lColumn.id_]
                if(lChangedFormItem)
                {
                    lCompData = CSFormUtil.cfGetComputedFieldsData(lRowData, this.props.pFormItems, this.state.mIdFieldObjectMap);
                    if(lCompData)
                    {
                        Object.entries(lCompData).map(([lKey, lValue]) => 
                            {
                                if(lCompData.hasOwnProperty(lKey))
                                {
                                    lRowData[lKey] = lValue;
                                }

                                return lRowData;
                            }
                        );
                    }

                    if(lData.control_type_ === 'file')
                    {
                        lChangedFormItem.mValue = lData.value_;
                        lChangedFormItem.mFileList = lData.file_list_;
                    }

                    CSFormUtil.cfSetDependentFieldsData(this.props.pFormItems, lData, lChangedFormItem, lRowData, this.state.mIdFieldObjectMap);
                }
            }

            this.setState({mChangeFlag : true});
        }
    }

    cfOnDataSelection(sel_data)
    {
        if(sel_data)
        {
            var lCompareField = '';
            var lCompareValue = '';
            if(this.state.mFormGrid)
            {
                for(var lcInd=0; lcInd<this.state.mFormGrid.length; lcInd++)
                {
                    var lColumn = this.state.mFormGrid[lcInd];
                    if(lColumn.control_type_ === 'check_box')
                    {
                        lCompareField = lColumn.data_field_;
                        lCompareValue = lColumn.constant_;
                    }
                }
            }

            if(lCompareField && lCompareValue)
            {
                for(var lvInd=0; lvInd<this.state.mDataCollection.length; lvInd++)
                {
                    var lDataObj0 = this.state.mDataCollection[lvInd];
                    if(lDataObj0)
                    {
                        lDataObj0[lCompareField] = '';
                    }
                }

                for(var lsInd=0; lsInd<sel_data.length; lsInd++)
                {
                    var lsObj = sel_data[lsInd];
                    lsObj[lCompareField] = lCompareValue;
                }
            }

            var lFormData = this.state.mFormData;
            lFormData.selected_records_ = sel_data;
            var lCompData = CSFormUtil.cfGetComputedFieldsData(sel_data, this.state.mFormItems, this.state.mIdFieldObjectMap, this.state.mFormData);
            if(lCompData)
            {
                var lKeys = Object.keys(lCompData);
                for(var lKind=0; lKind<lKeys.length; lKind++)
                {
                    var lKey = lKeys[lKind];
                    var lFormItem = this.state.mDataFieldObjectMap[lKey];
                    if(lFormItem)
                    {
                        var lDataObj = null;
                        if(!lFormItem.parent_id_)
                        {
                            lDataObj = this.state.mFormData['selected_data_'];
                            if(lDataObj)
                            {
                                lDataObj[lKey] = lCompData[lKey];
                            }
                        }
                        else
                        {
                            var lParentFormItem = this.state.mIdFieldObjectMap[lFormItem.parent_id_];
                            if(lParentFormItem)
                            {
                                lDataObj = this.state.mFormData[lParentFormItem.data_field_];
                                if(lDataObj)
                                {
                                    lDataObj[lKey] = lCompData[lKey];
                                }
                            }
                        }
                    }
                }
            }

            this.setState({mSelectedRecord : sel_data});
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormGrid = this.state.mFormGrid;
            var lDColumns = null;
            var lFormData = this.state.mSectionData;
            if(!lFormData)
            {
                lFormData = {}
            }

            lFormData[lData.data_field_] = lData.value_;

            if(this.state.mIdFieldObjectMap)
            {
                var lChangedFormItem = this.state.mIdFieldObjectMap[lData.id_];
                if(lChangedFormItem)
                {
                    var lSelectedData = null;
                    if(lData.hasOwnProperty('selected_data_'))
                    {
                        lSelectedData = lData.selected_data_;
                        lChangedFormItem.selected_data_ = lSelectedData;

                        if(lChangedFormItem.control_type_ === 'constant')
                        {
                            lSelectedData[lChangedFormItem.data_field_] = cfGetConstantValue(lChangedFormItem.constant_);
                            lChangedFormItem.mValue = cfGetConstantValue(lChangedFormItem.constant_);
                        }
                        else if(lChangedFormItem.control_type_ === 'check_box')
                        {
                            if(lData.control_type_ === 'check_box')
                            {
                                lSelectedData[lData.data_field_] = (lData.value_ === '1') ? lChangedFormItem.constant_ : '';
                                lChangedFormItem.mValue = lData.value_;
                            }        
                        }
                    }
                    else
                    {
                        lChangedFormItem.mValue = lData.value_;
                    }

                    if(lData.control_type_ === 'file')
                    {
                        lChangedFormItem.mValue = lData.value_;
                        lChangedFormItem.mFileList = lData.file_list_;
                    }

                    lFormData = CSFormUtil.cfSetDependentFieldsData(this.state.mFormItems, lFormData, lChangedFormItem, lSelectedData, this.state.mIdFieldObjectMap);                  
//                    var lFormItems = this.state.mFormItems.filter(lFormItem => (!lFormItem.parent_id_));
                    var lCompData = CSFormUtil.cfGetComputedFieldsData(lFormData, this.props.pFormItems, this.state.mIdFieldObjectMap, this.state.mFormData);
                    if(lCompData)
                    {
                        var lKeys = Object.keys(lCompData);
                        for(var lKind=0; lKind<lKeys.length; lKind++)
                        {
                            var lKey = lKeys[lKind];
                            lFormData[lKey] = lCompData[lKey];
                        }
                    }

                    if(lChangedFormItem.hasOwnProperty('cs_form_item_properties_'))
                    {
                        var lServiceCode = null;
                        var lCriteria =  null;
                        var lProperties = lChangedFormItem.cs_form_item_properties_;
                        var lDynamicColumns = null;
                        for(var lpInd=0; lpInd<lProperties.length; lpInd++)
                        {
                            var lProperty = lProperties[lpInd];
                            if(lProperty.type_ === 'filter_collection')
                            {
                                if(lProperty.property_ && (lProperty.property_ === 'service_code_'))
                                {
                                    lServiceCode = lProperty.value_;
                                }
                            }
                            else if(lProperty.type_ === 'dynamic_column')
                            {
                                if(lProperty.property_ && lProperty.value_)
                                {
                                    if(!lDynamicColumns)
                                    {
                                        lDynamicColumns = {}
                                    }

                                    var lDynamicColumnObj = lDynamicColumns[lProperty.cs_form_item_ref_id_];
                                    if(!lDynamicColumnObj)
                                    {
                                        lDynamicColumnObj = {}
                                        lDynamicColumns[lProperty.cs_form_item_ref_id_] = lDynamicColumnObj;
                                    }

                                    lDynamicColumnObj[lProperty.property_] = lProperty.value_;
                                }
                            }
                            else if(lProperty.type_ === 'criteria')
                            {
                                if(lProperty.property_ && lProperty.selection_property_ && lProperty.selection_value_)
                                {
                                    if(!lCriteria)
                                    {
                                        lCriteria = {}
                                    }

                                    if(lProperty.selection_property_)
                                    {
                                        if(lProperty.property_ === 'control')
                                        {
                                            lCriteria[lProperty.selection_property_] = lData.value_;
                                        }
                                        else if(lProperty.property_ === 'constant')
                                        {
                                            lCriteria[lProperty.selection_property_] = cfGetConstantValue(lProperty.selection_value_);
                                        }
                                        else if(lFormData && (lProperty.property_ === 'form_data'))
                                        {
                                            lCriteria[lProperty.selection_property_] = lFormData[lProperty.selection_value_];
                                        }
                                        else if(lFormData && (lProperty.property_ === 'concat_data') && lProperty.dependent_item_ref_id_)
                                        {
                                            let lDepDataField = null;
                                            let lDependentFormItem = this.state.mIdFieldObjectMap[lProperty.dependent_item_ref_id_];
                                            if(lDependentFormItem)
                                            {
                                                lDepDataField = lDependentFormItem.reference_field_;
                                                if(lDepDataField)
                                                {
                                                    let lFiData = lFormData[lProperty.selection_value_];
                                                    if(lFiData instanceof Array)
                                                    {
                                                        let lFidataVal = null;
                                                        for(var lfidIn=0; lfidIn<lFiData.length; lfidIn++)
                                                        {
                                                            if(!lFidataVal)
                                                            {
                                                                lFidataVal = "'" + lFiData[lfidIn][lDepDataField] + "'";
                                                            }
                                                            else
                                                            {
                                                                lFidataVal = lFidataVal + ", '" + lFiData[lfidIn][lDepDataField] + "'";
                                                            }
                                                        }
        
                                                        lCriteria[lProperty.selection_property_] = lFidataVal;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if(lDynamicColumns && lFormData && lFormGrid)
                        {
                            var lKeyList = Object.keys(lDynamicColumns);
                            if(lKeyList)
                            {
                                var lGridColumn0 = lFormGrid[0];
                                for(var lkInd=0; lkInd<lKeyList.length; lkInd++)
                                {
                                    let lDynColObj = lDynamicColumns[lKeyList[lkInd]];
                                    let lFItem = this.state.mIdFieldObjectMap[lKeyList[lkInd]];
                                    if(lFItem && lDynColObj)
                                    {
                                        let lFiData = lFormData[lFItem.data_field_];
                                        if(lFiData instanceof Array)
                                        {
                                            for(let lfidInd=0; lfidInd<lFiData.length; lfidInd++)
                                            {
                                                let lFiObj = lFiData[lfidInd];
                                                let lGridColumn = {}
                                                lGridColumn.id_ = Date.now();
                                                lGridColumn.action_ref_id_          = lGridColumn0.action_ref_id_;
                                                lGridColumn.app_code_               = lGridColumn0.app_code_;
                                                lGridColumn.app_id_                 = lGridColumn0.app_id_;
                                                lGridColumn.cs_form_code_           = lGridColumn0.cs_form_code_;
                                                lGridColumn.type_                   = lGridColumn0.type_;
                                                lGridColumn.cs_form_component_code_ = lGridColumn0.cs_form_component_code_;
                                                lGridColumn.column_name_            = lFiObj[lDynColObj.heading];
                                                lGridColumn.data_field_             = lDynColObj.data_field;
                                                lGridColumn.visible_                = '1';
                                                lGridColumn.editable_               = '1';
                                                lGridColumn.width_                  = '-1';
                                                lGridColumn.height_                 = '-1';
                                                if(!lDColumns)
                                                {
                                                    lDColumns = [];
                                                }

                                                lGridColumn.sequence_ = lFormGrid.length + 1;
                                                lDColumns.push(lGridColumn);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if(lServiceCode && lCriteria)
                        {
                            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                            if(lServiceObject)
                            {                    
                                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);     
                                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                                {
                                    var lServiceProperties = lServiceObject.cs_service_properties_;
                                    if(lServiceProperties !== null)
                                    {
                                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                                        {
                                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                                        }
                                    }
                                }
                                    
                                this.setState({mShowBackdrop : true});
                                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                                lRequestUtil.cfProcessRequest((lResponse)=>
                                    {
                                        var lCollection = [];
                                        if(lResponse)
                                        {
                                            if (lResponse.hasOwnProperty("result") && lResponse.result.hasOwnProperty("collection_"))
                                            {
                                                if(lResponse.result.collection_ instanceof Array)
                                                {
                                                    lCollection = lResponse.result.collection_;
                                                }
                                                else
                                                {
                                                    lCollection.push(lResponse.result.collection_);
                                                }
                                            }
                                            else if(lResponse.collection_)
                                            {
                                                if(lResponse.collection_ instanceof Array)
                                                {
                                                    lCollection = lResponse.collection_;
                                                }
                                                else
                                                {
                                                    lCollection.push(lResponse.collection_);
                                                }
                                            }
                                        }
                                        
                                        var lParentObj = null;
                                        if(lCollection && this.state.mFormGrid)
                                        {
                                            for(var lcInd=0 ; lcInd<lCollection.length; lcInd++)
                                            {
                                                var lcObj = lCollection[lcInd];
                                                for(var lgInd=0; lgInd<this.state.mFormGrid.length; lgInd++)
                                                {
                                                    var lColumn = this.state.mFormGrid[lgInd];
                                                    if(lColumn.data_field_)
                                                    {
                                                        if(!lParentObj && lColumn.parent_id_)
                                                        {
                                                            lParentObj = this.state.mIdFieldObjectMap[lColumn.parent_id_];
                                                        }

                                                        if(!lcObj.hasOwnProperty(lColumn.data_field_))
                                                        {
                                                            lcObj[lColumn.data_field_] = '';
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        var lFormData = this.state.mFormData;
                                        if(lParentObj && lFormData)
                                        {
                                            lFormData[lParentObj.data_field_] = lCollection;
                                        }

                                        this.setState({mDataCollection : lCollection, mFormData : lFormData})
                                    }
                                );
                                
                                this.setState({mShowBackdrop : false});
                            }                    
                        }
                    }
                }
            }
            
            this.setState ({mSectionData : lFormData, mFormGrid : lFormGrid, mDynamicColumns : lDColumns});
        }
    }
    
    cfSetDependentFieldData(lFormItem)
    {
        if(lFormItem && lFormItem.dependent_id_ && (lFormItem.grid_display_ !== '1'))
        {
            var lDependentItem = this.state.mIdFieldObjectMap[lFormItem.dependent_id_];
            if(lDependentItem)
            {
                var lDependentField = (lFormItem.reference_field_) ? lFormItem.reference_field_ : lFormItem.dependent_field_;
                if(lDependentField)
                {
                    lFormItem.dependent_field_ = lDependentField;

                    var lDependentData = null;
                    if(lDependentItem.control_type_ === 'combo_box')
                    {
                        lDependentData = lDependentItem.selected_data_;
                        if( lDependentData && (lDependentData instanceof Object))
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData[lDependentField];
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData[lDependentField];
                            }
                        }
                        else
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData;
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData;
                            }                            
                        }
                    }
                    else
                    {
                        lDependentData = lDependentItem.mValue;
                        if( lDependentData && (lDependentData instanceof Object))
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData[lDependentField];
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData[lDependentField];
                            }
                        }
                        else
                        {
                            if(lFormItem.control_type_ === 'combo_box')
                            {
                                lFormItem.dependent_value_ = lDependentData;
                            }
                            else
                            {
                                lFormItem.mValue = lDependentData;
                            }
                        }
                    }
                }
                else
                {
                    lFormItem.mValue = lDependentData;
                }
            }
        }
    }

    cfGetFormItems(lActionId)
    {
        var lFormItems = [];
        if(lActionId)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.parent_id_)
                {
                    if(lFormItem.dependent_id_)
                    {
                        var lDependentItem = this.state.mIdFieldObjectMap[lFormItem.dependent_id_];
                        if(!lDependentItem.parent_id_)
                        {
                            lDependentItem.is_dependent_item_ = true;
                            lFormItems.push(lDependentItem);
                        }
                    }

                    lFormItems.push(lFormItem);
                }
            }
        }
        
        return lFormItems;
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                var lFormData = this.state.mSectionData;
                if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                {
                    var lProperties = lFormItem.cs_form_item_properties_;
                    var lSequence = '0';
                    var lRequired = '0';
                    for(var lPInd=0; lPInd<lProperties.length; lPInd++)
                    {
                        var lProperty = lProperties[lPInd];
                        if(lProperty.type_ === 'form_item')
                        {
                            if(lProperty.property_ === 'sequence')
                            {
                                if(lFormData.hasOwnProperty(lProperty.selection_property_))
                                {
                                    if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                    {
                                        lFormItem.sequence_ = lProperty.value_;
                                        lSequence = lProperty.value_;
                                    }
                                    else
                                    {
                                        lFormItem.sequence_ = lSequence;
                                    }
                                }
                            }
                            if(lProperty.property_ === 'required')
                            {
                                if(lFormData[lProperty.selection_property_] === cfGetConstantValue(lProperty.selection_value_))
                                {
                                    lFormItem.required_ = lProperty.value_;
                                    lRequired = lProperty.value_;
                                }
                                else
                                {
                                    lFormItem.required_ = lRequired;
                                }
                            }
                        }
                    }
                }

                if(lFormItem.control_type_ === 'constant')
                {
                    lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    if(lFormData)
                    {
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
                else if(lFormItem.dependent_id_)
                {
                    this.mFormUtil.cfSetDependentFieldData(lFormItem, this.state.mIdFieldObjectMap);
                    
//                    this.cfSetDependentFieldData(lFormItem);
                    if(lFormData)
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                    }
                }
                else if(lFormItem.control_type_ === 'check_box')
                {
                    if(lFormItem.constant_ && lFormData && ((lFormItem.constant_ === lFormData[lFormItem.data_field_])))
                    {
                        lFormItem.mValue = '1';
                    }
                    else
                    {
                        lFormItem.mValue = '0';
                    }
                }
                else if(lFormData)
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        if(lFormData[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                        else if(lFormItem.constant_)
                        {
                            lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                            lFormData[lFormItem.data_field_] = lFormItem.mValue;
                        }
                    }
                    else
                    {
                        lFormItem.mValue = lFormData[lFormItem.data_field_];
                    }
                }
                else
                {
                    if(lFormItem.control_type_ === 'text_input')
                    {
                        lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    }
                    else
                    {
                        lFormItem.mValue = '';
                    }
                }            
            }
        }
    }

    process_save(lEvent, lParent, lActionItem)
    {
        this.setState({mSelectedActionItem : lActionItem});
        if(lActionItem)
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            let lFileUploadRetVal = true;
                            var lRequestData = null;
                            if(this.state.mFormItems)
                            {
                                for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
                                {
                                    var lFormItem = this.state.mFormItems[lInd];
                                    if(lFormItem.control_type_ === 'file')
                                    {
                                        if(lFormItem.hasOwnProperty('mFileList') && lFormItem.mFileList)
                                        {
                                            var lRequestUtil = new CSRequestUtil();
                                            lRequestUtil.cfUploadFile(lFormItem.mFileList, lFormItem.constant_, async (lResponseData) =>
                                                {
                                                    if(lResponseData instanceof Object)
                                                    {

                                                    }
                                                    else if(lResponseData === -1)
                                                    {
                                                        lFileUploadRetVal = false;
                                                    }
                                                }
                                            )
                                        }
                                    }

                                    if(lFileUploadRetVal)
                                    {
                                        if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                        {
                                            if(!this.state.mFormData.hasOwnProperty('section_data_'))
                                            {
                                                this.state.mFormData.section_data_ = this.state.mSectionData;
                                            }

                                            if(!this.state.mFormData.hasOwnProperty('selected_data_'))
                                            {
                                                this.state.mFormData.selected_data_ = this.state.mSelectedData;
                                            }

                                            var lDataCollection = null;
                                            if((lFormItem.required_ === '1') && (lFormItem.control_type_ === 'collection'))
                                            {
                                                for(var ldInd=0; ldInd<this.state.mDataCollection.length; ldInd++)
                                                {
                                                    var lIncFlag = true;
                                                    var ldObj = this.state.mDataCollection[ldInd];
                                                    for(var lgInd=0; lgInd<this.state.mFormGrid.length; lgInd++)
                                                    {
                                                        var lgridObj = this.state.mFormGrid[lgInd];
                                                        if(lgridObj.required_ === '1')
                                                        {
                                                            if(ldObj[lgridObj.data_field_] === '')
                                                            {
                                                                lIncFlag = false;
                                                                break;
                                                            }
                                                        }
                                                    }

                                                    if(lIncFlag)
                                                    {
                                                        if(!lDataCollection)
                                                        {
                                                            lDataCollection = [];
                                                        }

                                                        lDataCollection.push(ldObj);
                                                    }
                                                }
                                            }
                                            else
                                            {
                                                lDataCollection = this.state.mDataCollection;
                                            }

                                            var lRequestObj = this.mFormUtil.fnGetRequestObject(lFormItem, lDataCollection, this.state.mFormData, lRequestData, this.state.mIdFieldObjectMap, lActionItem);
                                            if(lRequestObj)
                                            {
                                                if(!lRequestData)
                                                {
                                                    lRequestData = {}
                                                }

                                                if(lFormItem.control_type_ === 'state')
                                                {
                                                    lRequestObj[lFormItem.data_field_] = lActionItem.action_code_;
                                                }
                        
                                                lRequestData[lFormItem.data_field_] = lRequestObj;
                                            }
                                        }
                                    }
                                }
                            }
                    
                            if(!lRequestData)
                            {
                                if(this.state.mSaveDataObject)
                                {
                                    if(this.state.mFormData.hasOwnProperty(this.state.mSaveDataObject))
                                    {
                                        lRequestData = this.state.mFormData[this.state.mSaveDataObject];
                                    }
                                    else
                                    {
                                        lRequestData = this.state.mFormData;
                                    }
                                }
                                else
                                {
                                    lRequestData = this.state.mFormData;
                                }

                                for(var lfInd=0; lfInd<this.state.mFormItems.length; lfInd++)
                                {
                                    var lfItem = this.state.mFormItems[lfInd];
                                    if(!lRequestData.hasOwnProperty(lfItem.data_field_))
                                    {
                                        if(lfItem.control_type_ === 'state')
                                        {
                                            lRequestData[lfItem.data_field_] = lActionItem.action_code_;
                                        }
                                        else
                                        {
                                            lRequestData[lfItem.data_field_] = lfItem.mValue;
                                        }
                                    }
                                }
                            }

                            if(lFileUploadRetVal)
                            {
                                this.setState({ mShowBackdrop : true });
                                this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                    (lResponse, lResponseData, lReqData) =>
                                    {
                                        this.setState({ mShowBackdrop : false });
                                        var lCode = null;
                                        var lMsg = null;
                                        var lCloseFlag = (lActionItem.new_state_ === "list")
        
                                        if(lResponse && lResponse.code)
                                        {
                                            if(lResponse.code instanceof Array)
                                            {
                                                lCode = lResponse.code[0];
                                                if(lResponse.message)
                                                {
                                                    lMsg = lResponse.message[0];
                                                }
                                            }
                                            else
                                            {
                                                lCode = lResponse.code;
                                                if(lResponse.message)
                                                {
                                                    lMsg = lResponse.message;
                                                }

                                                if(lCode === 0)
                                                {
                                                    lCode = 1;
                                                }
                                            }
                                        }
                                        else
                                        {
                                            if(lResponse.code === 0)
                                            {
                                                lCode = 1;
                                            }
                                            else
                                            {
                                                lCode = -1;
                                            }
                                        }
        
                                        let lPostSaveData = this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse);
                                        if((lCode === 1) || (lCode === '1'))
                                        {
                                            let lPrintForm = null;
                                            let lEnablePrint = false;
                                            if(this.props.pFormProperties)
                                            {
                                                for(var lfpInd=0; lfpInd<this.props.pFormProperties.length; lfpInd++)
                                                {
                                                    var lfpObj = this.props.pFormProperties[lfpInd];

                                                    if (lfpObj.property_ === 'print_form')
                                                    {
                                                        lPrintForm = lfpObj.value_;
                                                        lEnablePrint = true;
                                                        break;
                                                    }
                                                }
                                            }

                                            if(lPostSaveData)
                                            {
                                                let lSelectedData = null;
                                                if(this.state.mFormData && this.state.mFormData.hasOwnProperty('selected_data_'))
                                                {
                                                    lSelectedData = this.state.mFormData['selected_data_'];
                                                }

                                                let lSelectedCriteria = null;
                                                let lRespData = {}
                                                for(let lFv_0=0; lFv_0<lPostSaveData.length; lFv_0++)
                                                {
                                                    let lP1Obj = lPostSaveData[lFv_0];
                                                    if(lP1Obj.data_field_1_type_)
                                                    {
                                                        if(lP1Obj.data_field_1_type_ === 'data')
                                                        {
                                                            if(lP1Obj.data_object_ && this.state.mFormData.hasOwnProperty(lP1Obj.data_object_))
                                                            {
                                                                lSelectedData = this.state.mFormData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                        else if(lP1Obj.data_field_1_type_ === 'criteria')
                                                        {
                                                            if(lP1Obj.data_object_ && this.state.mFormData.hasOwnProperty(lP1Obj.data_object_))
                                                            {
                                                                lSelectedCriteria = this.state.mFormData[lP1Obj.data_object_]
                                                            }
                                                        }
                                                        else if(lP1Obj.data_field_1_type_ === 'response')
                                                        {
                                                            if(lP1Obj.data_object_ && lP1Obj.data_field_2_ && lResponseData.hasOwnProperty(lP1Obj.data_object_))
                                                            {
                                                                let lRespVal = lResponseData[lP1Obj.data_object_];
                                                                if(lRespVal && lRespVal.hasOwnProperty(lP1Obj.data_field_2_) && isArray(lRespVal[lP1Obj.data_field_2_]))
                                                                {
                                                                    lRespData[lP1Obj.data_field_2_] = lRespVal[lP1Obj.data_field_2_][0];
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                if(lRespData)
                                                {
                                                    for(var lFv_1=0; lFv_1<lPostSaveData.length; lFv_1++)
                                                    {    
                                                        var lP2Obj = lPostSaveData[lFv_1];
                                                        if(lP2Obj.data_field_1_type_ === 'criteria')
                                                        {
                                                            if(lP2Obj.data_field_1_ && lRespData.hasOwnProperty(lP2Obj.data_field_1_))
                                                            {
                                                                if(!lSelectedCriteria)
                                                                {
                                                                    lSelectedCriteria = {}
                                                                }

                                                                lSelectedCriteria[lP2Obj.data_field_1_] = lRespData[lP2Obj.data_field_1_]
                                                            }
                                                        }
                                                    }

                                                    this.setState({mSelectedCriteria : lSelectedCriteria, mEnablePrint : lEnablePrint, mPrintForm : lPrintForm});
                                                }
                                            }
                                            
                                            if(!lPrintForm)
                                            {
                                                this.process_close(lCloseFlag, true);
                                            }
                                        }
                                    }
                                );
                            }
                        }
                    }
                }
            );
        }
    }

    cfGetRequestObject(lFormItem, lActionItem)
    {
        var lRetObj;
        var lFormItemProperties = lFormItem.cs_form_item_properties_;
        if(lFormItemProperties)
        {
            if(lFormItem.control_type_ !== 'collection')
            {
                for(var lfipInd=0; lfipInd<lFormItemProperties.length; lfipInd++)
                {
                    var lfipObj = lFormItemProperties[lfipInd];
                    if(lfipObj.type_ === 'request_data')
                    {
                        if(lfipObj.property_ === '#CONSTANT#')
                        {
                            if(lFormItem.control_type_ !== 'collection')
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = {}
                                }
            
                                lRetObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                            }
                            else
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = this.state.mDataCollection;
                                }
            
                                if(lRetObj)
                                {
                                    for(var lcInd=0; lcInd<lRetObj.length; lcInd++)
                                    {
                                        var lcObj = lRetObj[lcInd];
                                        lcObj[lfipObj.selection_property_] = cfGetConstantValue(lfipObj.selection_value_);
                                    }
                                }
                            }
                        }
                        else if(lfipObj.property_ === 'form_data')
                        {
                            var lDataObj;
                            if((lfipObj.value_ === 'section_data_') && this.state.mSectionData)
                            {
                                lDataObj = this.state.mSectionData;
                            }
                            else if((lfipObj.value_ === 'selected_data_') && this.state.mSelectedData)
                            {
                                lDataObj = this.state.mSelectedData;
                            }

                            if(lDataObj)
                            {
                                if(lFormItem.control_type_ !== 'collection')
                                {
                                    if(!lRetObj)
                                    {
                                        lRetObj = {}
                                    }

                                    lRetObj[lfipObj.selection_property_] = lDataObj[lfipObj.selection_value_];
                                }
                                else
                                {
                                    if(!lRetObj)
                                    {
                                        lRetObj = this.state.mDataCollection;
                                    }
                
                                    if(lRetObj)
                                    {
                                        for(var lcInd0=0; lcInd0<lRetObj.length; lcInd0++)
                                        {
                                            var lcObj0 = lRetObj[lcInd0];
                                            lcObj0[lfipObj.selection_property_] = lDataObj[lfipObj.selection_value_];
                                        }
                                    }
                                }
                            }
                        }
                        else if(lfipObj.property_ === 'state')
                        {
                            var lStateValue = lActionItem.action_code_;
                            if(lFormItem.control_type_ !== 'collection')
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = {}
                                }
            
                                lRetObj[lfipObj.selection_property_] = lStateValue;
                            }
                            else
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = this.state.mDataCollection;
                                }
            
                                if(lRetObj)
                                {
                                    for(var lcInd1=0; lcInd1<lRetObj.length; lcInd1++)
                                    {
                                        var lcObj1 = lRetObj[lcInd1];
                                        lcObj1[lfipObj.selection_property_] = lStateValue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else
            {
                if(!lRetObj)
                {
                    lRetObj = [];
                    if(this.state.mDataCollection)
                    {
                        for(var ldInd=0; ldInd<this.state.mDataCollection.length; ldInd++)
                        {
                            var lnObj = null;
                            var ldObj = this.state.mDataCollection[ldInd];
                            var lDObj = null;
                            var lAddFlag = true;
                            for(var lfipInd1=0; lfipInd1<lFormItemProperties.length; lfipInd1++)
                            {
                                var lfipObj1 = lFormItemProperties[lfipInd1];
                                if(!lfipObj1.value_)
                                {
                                    lDObj = ldObj;
                                }
                                else if((lfipObj1.value_ === 'section_data_') && this.state.mSectionData)
                                {
                                    lDObj = this.state.mSectionData;
                                }
                                else if((lfipObj1.value_ === 'selected_data_') && this.state.mSelectedData)
                                {
                                    lDObj = this.state.mSelectedData;
                                }

                                
                                if(!lnObj)
                                {
                                    lnObj = {}
                                }

                                if(lDObj && lDObj[lfipObj1.selection_value_])
                                {
                                    if(lFormItem.required_ && !lDObj[lfipObj1.selection_value_])
                                    {
                                        lAddFlag = false;
                                        break;
                                    }

                                    lnObj[lfipObj1.selection_property_] = lDObj[lfipObj1.selection_value_];
                                }
                                else
                                {
                                    lnObj[lfipObj1.selection_property_] = '';
                                }
                            }

                            if(lAddFlag && lnObj)
                            {
                                lRetObj.push(lnObj);
                            }
                        }
                    }                        
                }
            }
        }

        return lRetObj;
    }

    render()
    {
        this.cfSetControlValue();

        var lFormItemSection = null;
        var lFormDetailsItemSearchActions = null;
        var lFormDetailsItemActionsList = null;
        if(this.state.mFormItems && (this.state.mFormItems.length > 0))
        {
            var lFormItems = [];
            for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if((!lFormItem.parent_id_) && (lFormItem.visible_ === '1') && (lFormItem.control_type_ !== 'collection'))
                {
                    lFormItems.push(lFormItem);
                }
            }

            if(lFormItems.length > 0)
            {
                CSUtil.cfSort(lFormItems, 'sequence_');
                lFormItemSection = this.mFormUtil.cfGetDetailsSections(lFormItems, this.mMinSectionItems, this.cfProcessChange, "form_criteria_label", null, Number(this.state.mNumSections));
            }
        }

        var lDetailsForm = null;
        if(this.state.mDetailsFlag)
        {
            var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
            lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.cfCloseForm);
        }

        if(this.state.mFormItemsSearchActions && (this.state.mFormItemsSearchActions.length > 0))
        {
            var lFormDetailsItemSearchActionList = this.state.mFormItemsSearchActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormDetailsItemSearchActions = 
                <CSCOBaseActionBar
                    align       = "left"
                    pType       = 'SEARCH_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormDetailsItemSearchActionList}
                />;
        }

        if(this.state.mFormDetailsActions && (this.state.mFormDetailsActions.length > 0))
        {
            for(var lDAItemInd=0; lDAItemInd<this.state.mFormDetailsActions.length; lDAItemInd++)
            {
                var lDetailsActionItem = this.state.mFormDetailsActions[lDAItemInd];
                if(CSFormUtil.cfIncludeAction(lDetailsActionItem, this.state.mSectionData))
                {
                    if(lDetailsActionItem.action_type_ === '1')
                    {
                        let lRequiredAll = true;
                        if(lDetailsActionItem.hasOwnProperty('cs_form_action_properties_'))
                        {
                            var lProperties = lDetailsActionItem.cs_form_action_properties_;
                            for(var lpInd=0; lpInd<lProperties.length; lpInd++)
                            {
                                var lProperty = lProperties[lpInd];
                                if(lProperty.selection_property_ === '#REQUIRED#')
                                {
                                    if(lProperty.selection_value_ === 'ANY')
                                    {
                                        lRequiredAll = false;
                                        break;
                                    }
                                }
                            }
                        }

                        lDetailsActionItem.mEnabled = true;
                        lDetailsActionItem.enabled_ = "1";
                        for(var lgInd=0; lgInd<this.state.mFormGrid.length; lgInd++)
                        {
                            var lColumn = this.state.mFormGrid[lgInd];
                            if(lColumn.required_ === "1")
                            {
                                if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                                {
                                    for(var ldInd=0; ldInd<this.state.mDataCollection.length; ldInd++)
                                    {
                                        var ldObj = this.state.mDataCollection[ldInd];
                                        if(!ldObj[lColumn.data_field_])
                                        {
                                            lDetailsActionItem.mEnabled = false;
                                            lDetailsActionItem.enabled_ = "0";

                                            if(lRequiredAll)
                                            {
                                                break;
                                            }
                                        }
                                        else
                                        {
                                            if(!lRequiredAll)
                                            {
                                                lDetailsActionItem.mEnabled = true;
                                                lDetailsActionItem.enabled_ = "1";
                                                break;
                                            }
                                        }
                                    }
                                }
                                else
                                {
                                    lDetailsActionItem.mEnabled = false;
                                    lDetailsActionItem.enabled_ = "0";
                                    break;
                                }
                            }
                        }

                        if(lDetailsActionItem.mEnabled)
                        {
                            lDetailsActionItem.mEnabled = CSFormUtil.cfEnableAction(lDetailsActionItem, this.state.mSectionData, this.state.mSectionFormItems);
                        }
                    }
                    else
                    {
                        lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                    }

                    if(!lFormDetailsItemActionsList)
                    {
                        lFormDetailsItemActionsList = [];
                    }
                    
                    lFormDetailsItemActionsList.push(lDetailsActionItem);
                }
            }
        }

        var lHeaderHeight = this.cfGetDimensions("ID_HEADER");
        var lActionBarHeight = this.cfGetDimensions("ID_DETAILS_ACTIONS");
        var lScrollHeight = (CSUtil.cfGetInstance()).mApplicationHeight - 65;
    
        if(lActionBarHeight)
        {
            lScrollHeight = lScrollHeight - lActionBarHeight;
        }
        else 
        {
            lScrollHeight = lScrollHeight - 60;
        }

        if(lHeaderHeight)
        {
            lScrollHeight = lScrollHeight - lHeaderHeight;
        }
        else
        {
            lScrollHeight += 4;
        }

        var  lPreviewForm = null;
        if((this.props.pEnablePrint && this.props.pPrintForm) || (this.state.mEnablePrint && this.state.mPrintForm))
        {
            var lCriteria = null;
/*            if(this.props.pSelectedCriteria)
            {
                lCriteria = this.props.pSelectedCriteria;
            }
            else if(this.props.pFormData && this.props.pFormData.hasOwnProperty('selected_criteria_'))
            {
                lCriteria = this.props.pFormData.selected_criteria_;
            }
            else if(this.props.pSelectedData && this.props.pSelectedData.hasOwnProperty('selected_criteria_'))
            {
                lCriteria = this.props.pSelectedData.selected_criteria_;
            }
*/
            if(this.state.mSelectedCriteria)
            {
                lCriteria = this.state.mSelectedCriteria;
            }
            else if(this.state.mFormData && this.state.mFormData.hasOwnProperty('selected_criteria_'))
            {
                lCriteria = this.state.mFormData.selected_criteria_;
            }
            else if(this.state.mSelectedData && this.state.mSelectedData.hasOwnProperty('selected_criteria_'))
            {
                lCriteria = this.state.mSelectedData.selected_criteria_;
            }

            var lFormProperties = null;
            var lFormType = (this.props.pPrintForm) ? this.props.pPrintForm : this.state.mPrintForm;
            if(this.props.pFormProperties)
            {
                for(var lfpInd=0; lfpInd<this.props.pFormProperties.length; lfpInd++)
                {
                    var lfpObj = this.props.pFormProperties[lfpInd];
                    if(lfpObj.property_ === 'form_type')
                    {
                        lFormType = lfpObj.value_; 
                    }

                    if(lfpObj.cs_form_code_ === (this.props.pPrintForm || this.state.mPrintForm))
                    {
                        if(!lFormProperties)
                        {
                            lFormProperties = [];
                        }

                        lFormProperties.push(lfpObj);
                    }
                }
            }

            var lMetaData = 
            {
                pFormProperties   : lFormProperties,
                pFormType         : lFormType,
                fnEnablePrint     : this.props.fnEnablePrint,
                pCriteria         : lCriteria,
                pSelectedCriteria : lCriteria
            }

            lPreviewForm = fnGetForm(lMetaData, null, this.process_close);
        }

        return(
            <>
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>

                <div className="form-item" id='ID_HEADER'>
                    {lFormItemSection}
                </div>

                <CSCOGrid  
                    pData               = { this.state.mDataCollection }
                    pFeatureRefId       = { this.state.mFeatureRefId }
                    pColumns            = { this.state.mFormGrid }
                    rowIndex            = { this.state.mRowIndex } 
                    pHeading            = { this.state.mGridHeading }
                    pSelectedData       = { this.state.mSelectedRecords }
                    pAlignHeading       = "center"
                    pageSize            = { 100 }
                    pEditableGrid       = { this.state.mEditableGrid }
                    pEnableFilter       = { this.state.mEnableFilter }
                    onClick             = { this.cfOnDataSelection }
                    pSearchActionBar    = { lFormDetailsItemSearchActions }
                    pScrollHeight       = { lScrollHeight }
                    OnItemChange        = { this.cfOnGridItemChange }
                    pEnableScroll       = { true }
                />

                <CSCOHorizontalActionBar 
                    id          = 'ID_DETAILS_ACTIONS'
                    align       = "left"
                    pType       = 'DETAILS_ACTION_BAR'
                    parent      = { this }
                    actionList  = { lFormDetailsItemActionsList }
                />            

                { lDetailsForm }

                { lPreviewForm }
            </>
        );
    }
}

export default CSCODetailsForm3;
