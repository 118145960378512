import React, { Component, createRef } from "react"
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import CSServiceUtil from '../../util/CSServiceUtil';
import CSRequestUtil from "../../util/CSRequestUtil";
import { cfGetConstantValue} from "../../util/CSConstantUtil";

import CSCOLabel from "../../components/CSCOLabel";
import CSCOVerticalSection from "../../components/CSCOVerticalSection";
import CSControl from "../../controls/CSControl";
import ReactToPrint from 'react-to-print';

class CSCODetailsForm18 extends Component 
{
	mComponentCode      = 'CS_DETAILS_FORM_LAYOUT_18';
    mNumSections 		= 2;
    mKey                = 100000;

    mFormUtil       = CSFormUtil.cfGetInstance();
    mCSServiceUtil  = CSServiceUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mDisplay		        : this.props.pShow,
            mAppId                  : this.props.pAppId,
            mFormHeading            : (this.props.pFormHeading) ? this.props.pFormHeading : "",
            mFormItems              : this.props.pFormItems,
            mFormData               : this.props.pFormData,
            mSelectedData           : null,
            mDataCollection         : null,
            mFormOrigData           : CSUtil.cfGetObjectCopy(this.props.pFormData),
            mFormActions            : this.props.pFormActions,
            mFormProperties         : this.props.pFormProperties,
            mControlType            : this.props.pControlType,
            mEditable               : this.props.pEditable,
            mWidth                  : this.props.pWidth,
            mHeight                 : this.props.pHeight,
            mValue                  : this.props.pValue,
            mDetailsActions         : null,
            mDataFieldReferenceMap  : null,
            mCriteria               : null,
            mCriteriaActions        : null,
            mCriteriaSection        : null,
            mCriteriaList           : null,
            mActionItem             : null,
            mSelectedActionItem     : this.props.pSelectedActionItem,
            mSelectedCriteria       : null,

            mHeader                 : null,
            mFirstRecord            : 0,   
            mNumRecords             : 100,
            mPageLabels             : null,
            mPrintFlag              : this.props.pPrintFlag,
            mPageWidth              : 21.0,
            mPageHeight             : 29.7,
            mPageBackground         : '#FAFAFA',
            mPageType               : 'A4',
            mPagePadding            : 0,
            mPageMarginTop          : 0,
            mPageMarginBottom       : 0,
            mPageMarginLeft         : 0,
            mPageMarginRight        : 0,

            mLabelWidth             : 7.5,
            mLabelHeight            : 7,
            mLabelBackground        : '#A4D4E4',
            mNumColumns             : 3,
            mNumRows                : -1,
            mLabelVerticalSpacing   : 0.15,
            mLabelHorizontalSpacing : 0.15,
            mLabelMargin            : 0.15,
            mLabelMarginTop         : 0.15,
            mLabelMarginBottom      : 0.15,
            mLabelMarginLeft        : 0.15,
            mLabelMarginRight       : 0.15,

            fnProcessChange         : this.props.fnProcessChange,
            fnProcessSubmit         : this.props.fnProcessSubmit,
            fnProcessClose          : this.props.fnProcessClose,
            fnClosePrintForm        : this.props.pClosePrintForm,
        }

        this.cfProcessChange        = this.cfProcessChange.bind(this);
        this.cfOnCriteriaChange     = this.cfOnCriteriaChange.bind(this);
        this.cfSetControlValue      = this.cfSetControlValue.bind(this);
        this.cfCloseForm            = this.cfCloseForm.bind(this);
        this.cfClosePrintForm       = this.cfClosePrintForm.bind(this);
        this.cfSearch               = this.cfSearch.bind(this);
        this.cfSetFormData          = this.cfSetFormData.bind(this);
        this.cfGetLabels            = this.cfGetLabels.bind(this);
        this.cfGetCriteriaObject    = this.cfGetCriteriaObject.bind(this);
        this.cfGetCriteriaSections  = this.cfGetCriteriaSections.bind(this);
        this.cfGetCriteriaActions   = this.cfGetCriteriaActions.bind(this);
        this.cfOnPageChange         = this.cfOnPageChange.bind(this);
        this.cfSetLabelData         = this.cfSetLabelData.bind(this);

        this.process_search         = this.process_search.bind(this);
        this.process_save           = this.process_save.bind(this);
        this.process_cancel         = this.process_cancel.bind(this);
        this.process_close          = this.process_close.bind(this);
        this.process_reset          = this.process_reset.bind(this);
        this.process_print          = this.process_print.bind(this);
        this.process_pdf            = this.process_pdf.bind(this);

        if(this.props.pFormData)
        {
            var lFormData = this.props.pFormData;
            if(lFormData)
            {
                if(lFormData.hasOwnProperty('selected_data_'))
                {
                    var lSelectedData = lFormData.selected_data_;
                    if(lSelectedData && (lSelectedData instanceof Array) && (lSelectedData.length > 0))
                    {
                        this.state.mSelectedData = lSelectedData[0];   
                    }
                    else
                    {
                        this.state.mSelectedData = lSelectedData;
                    }
                }

                if(lFormData.hasOwnProperty('selected_criteria_'))
                {
                    this.state.mSelectedCriteria = lFormData.selected_criteria_;
                }

                if(lFormData.hasOwnProperty('collection_'))
                {
                    this.state.mDataCollection = lFormData.collection_;
                }

                if(lFormData.hasOwnProperty('label_settings_') && lFormData.label_settings_)
                {
                    var lSettings = lFormData.label_settings_[0];
                    if(lSettings)
                    {
                        this.state.mPageType                = lSettings.page_type_;
                        this.state.mPageWidth               = Number(lSettings.page_width_);
                        this.state.mPageHeight              = Number(lSettings.page_height_);
                        this.state.mPagePadding             = Number(lSettings.page_padding_);
                        this.state.mPageMarginTop           = Number(lSettings.page_padding_top_);
                        this.state.mPageMarginBottom        = Number(lSettings.page_padding_bottom_);
                        this.state.mPageMarginLeft          = Number(lSettings.page_padding_left_);
                        this.state.mPageMarginRight         = Number(lSettings.page_padding_right_);
                        this.state.mPageBackground          = lSettings.page_background_;
                        this.state.mLabelWidth              = Number(lSettings.label_width_);
                        this.state.mLabelHeight             = Number(lSettings.label_height_);
                        this.state.mLabelVerticalSpacing    = Number(lSettings.label_vertical_spacing_);
                        this.state.mLabelHorizontalSpacing  = Number(lSettings.label_horizontal_spacing_);
                        this.state.mLabelMargin             = Number(lSettings.label_margin_);
                        this.state.mLabelMarginTop          = Number(lSettings.label_margin_top_);
                        this.state.mLabelMarginBottom       = Number(lSettings.label_margin_bottom_);
                        this.state.mLabelMarginLeft         = Number(lSettings.label_margin_left_);
                        this.state.mLabelMarginRight        = Number(lSettings.label_margin_right_);
                        this.state.mLabelBackground         = lSettings.label_background_;
                        this.state.mNumColumns              = Number(lSettings.num_columns_);
                        this.state.mNumRows                 = Number(lSettings.num_rows_);
                    }
                }
            }
        }
        
        this.state.mNumRows = Math.floor(this.state.mPageHeight / this.state.mLabelHeight);

        this.mComponentRef = createRef();
    }

    componentDidMount()
    {
        this.cfSetFormData();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            var lDataCollection = null;
            if(this.props.pFormData)
            {
                var lSelectedData = null;
                var lFormData = this.props.pFormData;
                if(lFormData)
                {
                    if(lFormData.hasOwnProperty('selected_data_'))
                    {
                        var lSData = lFormData.selected_data_;
                        if(lSData && (lSData instanceof Array) && (lSData.length > 0))
                        {
                            lSelectedData = lSData[0];
                        }
                        else
                        {
                            lSelectedData = lSData;    
                        }
                    }
    
                    if(lFormData.hasOwnProperty('collection_'))
                    {
                        lDataCollection = lFormData.collection_;
                    }
                }
            }

            this.setState({
                mAppId          : this.props.pAppId,
                mFormHeading    : (this.props.pFormHeading) ? this.props.pFormHeading : "",
                mFormItems      : this.props.pFormItems,
                mFormData       : null,
                mControlType    : this.props.pControlType,
                mEditable       : this.props.pEditable,
                mWidth          : this.props.pWidth,
                mHeight         : this.props.pHeight,
                mValue          : this.props.pValue,
                mDataCollection : lDataCollection,
                mSelectedData   : lSelectedData,

                fnProcessChange : this.props.fnProcessChange,
                fnProcessSubmit : this.props.fnProcessSubmit,
                fnProcessClose  : this.props.fnProcessClose
            });
        }
    }

    cfSetFormData()
    {
        var lHeader                 = null;
        var lHeaderActionList       = null;
        var lDetailsActionList      = null;
        var lCriteriaActionsList    = [];

        var lDataFieldReferenceMap = {}
        var lCriteriaList = [];
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            var lLabelDesign = null;
            var lFormData = this.props.pFormData;
            if(lFormData && lFormData.hasOwnProperty('label_design_') && (lFormData.label_design_.length > 0))
            {
                lLabelDesign = lFormData.label_design_[0];
                lDataFieldReferenceMap = lLabelDesign;
            }

            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.cs_form_component_code_ === 'CS_DETAILS_CRITERIA_FORM_1')
                {
                    if(lFormItem.visible_ === '1')
                    {
                        lCriteriaList.push(lFormItem);
                    }
                }
                else
                {
                    if(!lLabelDesign)
                    {
                        if((lFormItem.control_type_ === 'reference') && lFormItem.reference_field_ && lFormItem.data_field_)
                        {
                            lDataFieldReferenceMap[lFormItem.reference_field_] = lFormItem.data_field_;
                        }
                    }
                    else 
                    {
                        if((lFormItem.control_type_ === 'reference') && lFormItem.reference_field_ && (lFormItem.reference_field_ === 'color_code_'))
                        {
                            lDataFieldReferenceMap[lFormItem.reference_field_] = lFormItem.data_field_;
                        }
                    }

                    lFormItemList.push(lFormItem);
                }
            }
        }

        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            for(var lActInd=0; lActInd<this.state.mFormActions.length; lActInd++)
            {
                var lActionItem = this.state.mFormActions[lActInd];
                if(lActionItem.component_type_ === 'DIALOG_HEADER_ACTION')
                {
                    if(!lHeaderActionList)
                    {
                        lHeaderActionList = [];
                    }

                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedData, this.state.mFormItems);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    lHeaderActionList.push(lActionItem);
                }
                else if(lActionItem.component_type_ === 'DETAILS_ACTION')
                {
                    if(!lDetailsActionList)
                    {
                        lDetailsActionList = [];
                    }

                    lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;                    
                    lDetailsActionList.push(lActionItem);
                }
                else if(lActionItem.component_type_ === 'DETAILS_CRITERIA_ACTION')
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedData, lCriteriaList);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    lCriteriaActionsList.push(lActionItem);
                }
            }

            CSUtil.cfSort(lDetailsActionList, 'sequence_');
        }

        var lCriteriaSection =
            <div className="form-criteria" id="ID_CRITERIA_SECTION" key={this.mKey}>
                { this.cfGetCriteriaSections(lCriteriaList) }
            </div>

        var lHeaderActions = [];
        if(lHeaderActionList)
        {
            for(var lhaInd=0; lhaInd<lHeaderActionList.length; lhaInd++)
            {
                var lhAction = lHeaderActionList[lhaInd];
                if(lhAction.action_ === 'process_print')
                {
                    lHeaderActions.push(
                        <ReactToPrint
                            trigger={() => 
                                <Button
                                    style=
                                        {
                                            {
                                                marginRight     : '.35em',
                                                verticalAlign   : 'middle'
                                            }
                                        } 
                                        type="Button" 
                                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                                        key={this.mKey++}
                                    />
                                }
                            content={() => this.mComponentRef.current}
                        />
                    );
                }
                else
                {
                    lHeaderActions.push(
                        CSFormUtil.cfGetButton(lhAction, 'SEARCH_ACTION_BAR', 
                            (event) => 
                                {
                                    if(this.hasOwnProperty(lActionItem.action_))
                                    {
                                        this[lActionItem.action_](event, this, lActionItem);
                                    }
                                }
                            )
                        )
                }
            }
        }

        lHeader = 
            <>
                <div className="p-toolbar-group-left" key={this.mKey++} >
                    <h2 key={this.mKey++} >{this.state.mFormHeading}</h2>
                </div>

                <div className="p-toolbar-group-right" key={this.mKey++} >
                    { lHeaderActions }
                </div>
            </>

        var lLabelList = null;
        if(this.state.mDataCollection)
        {
            var lDataList = this.state.mDataCollection.slice(this.state.mFirstRecord, this.state.mFirstRecord + this.state.mNumRecords);
            lLabelList = this.cfGetLabels(lDataList, lDataFieldReferenceMap);        
        }

        this.setState(
            {
                mHeader                 : lHeader,
                mDataFieldReferenceMap  : lDataFieldReferenceMap,
                mDetailsActions         : lDetailsActionList,
                mCriteriaActions        : lCriteriaActionsList,
                mCriteriaSection        : lCriteriaSection,
                mCriteriaList           : lCriteriaList,
                mDetailsFormItems       : lFormItemList,
                mPageLabels             : lLabelList
            }
        );
    }
    
    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            if(!lFormData)
			{
                lFormData = {};
                for(var ind=0; ind<this.state.mFormItems.length; ind++)
                {
                    var lFormItem = this.state.mFormItems[ind];
                    lFormData[lFormItem.data_field_] = '';
                    if(lFormItem.control_type_ === 'constant')
                    {
                        lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                }
			}

            if(lData.hasOwnProperty('data_field_') && lData.data_field_)
            {
                lFormData[lData.data_field_] = lData.value_;
            }

            this.setState ({mFormData : lFormData});
        }
    }

    cfOnCriteriaChange(lData)
    {
        if(lData)
        {
            var lCriteriaData = this.state.mSelectedData;
            if(!lCriteriaData)
			{
                lCriteriaData = {};
                for(var ind=0; ind<this.state.mFormItems.length; ind++)
                {
                    var lFormItem = this.state.mFormItems[ind];
                    lCriteriaData[lFormItem.data_field_] = '';
                    if(lFormItem.control_type_ === 'constant')
                    {
                        lCriteriaData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                }
			}

            if(lData.hasOwnProperty('data_field_') && lData.data_field_)
            {
                lCriteriaData[lData.data_field_] = lData.value_;
            }

            this.setState ({mSelectedData : lCriteriaData});
        }
    }

    cfGetCriteriaObject(lFormCriteria, lNumSections)
    {
        var ctl = null;
        if(lFormCriteria)
        {
            var lRequired = false;
            lFormCriteria.required_ = '0';
            lFormCriteria.display_field_ = lFormCriteria.display_label_;
            if(lFormCriteria.hasOwnProperty('cs_form_criteria_properties_') &&  lFormCriteria.cs_form_criteria_properties_)
            {
                for(var lInd=0; lInd<lFormCriteria.cs_form_criteria_properties_.length; lInd++)
                {
                    var lProperty = lFormCriteria.cs_form_criteria_properties_[lInd];
                    if(lProperty.cs_form_criteria_ref_id_ === lFormCriteria.id_)
                    {
                        if((lProperty.type_ === 'other') && (lProperty.property_ === 'required'))
                        {
                            lRequired = (lProperty.value_ === '1') ? true : false;
                            lFormCriteria.required_ = lProperty.value_;
                            break;
                        }
                    }
                }
            }

            ctl = 
            (
                <CSControl
                    key                 = { lFormCriteria.id_ }
                    process_change_     = { this.cfOnCriteriaChange }
                    pFormLabelClassName = "form_criteria_label"
                    editable_           = '1'
                    pLabelWidth         = "100px"
                    pMaxWidth           = { (450 * 4/3) / lNumSections }
                    pRequired           = { lRequired }
                    {...lFormCriteria}/>
            );
        }

        return ctl;
    }

    cfGetCriteriaSections(lCriteriaList)
    {        
        var lHeight = 0;
        var lSections = [];
        var lNumSections = this.mNumSections;
        if(lCriteriaList.length < lNumSections)
        {
            lNumSections = lCriteriaList.length;
        }

        var lNumCriteriaSectionRows = Math.ceil(lCriteriaList.length/lNumSections);
        if(lNumCriteriaSectionRows > 0)
        {
            lHeight = 48 + (lNumCriteriaSectionRows - 1) * 40;
            
            var lSectionNumControlMap = {};
            CSUtil.cfSort(lCriteriaList, 'sequence_')
            for(var ind=0; ind<lCriteriaList.length; ind++)
            {
                var lFormCriteria = lCriteriaList[ind];
                var lSequence = parseInt(lFormCriteria.sequence_, 10);
                if( lSequence > 0)
                {
                    var lCriteriaField = this.cfGetCriteriaObject(lFormCriteria, lNumSections);
                    var lIndex = Math.ceil(lSequence/lNumCriteriaSectionRows) - 1;
                    var lSectionControlList = null;
                    lSectionControlList = lSectionNumControlMap[lIndex];
                    if(lSectionControlList == null)
                    {
                        lSectionControlList = [];
                        lSectionNumControlMap[lIndex] = lSectionControlList;
                    }

                    lSectionControlList.push(lCriteriaField)
                }
            }

            var lCriteriaActionsList = [];
            var lFormActions = this.props.pFormActions;
            var lLabelFlag = false;
            if(lFormActions)
            {
                for(var lInd_0=0; lInd_0<lFormActions.length; lInd_0++)
                {
                    var lActionItem = lFormActions[lInd_0];
                    if(lActionItem.component_type_ === 'DETAILS_CRITERIA_ACTION')
                    {
                        if(lActionItem.label_)
                        {
                            lLabelFlag = true;
                        }

                        if(lActionItem.action_type_ === '1')
                        {
                            lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mFormData, lCriteriaList);
                        }
                        else
                        {
                            lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                        }

                        lCriteriaActionsList.push(lActionItem);
                    }
                }
            }

            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FAFAFA";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }

                lSection = <CSCOVerticalSection 
                    pBackgroundColor    = {lBkgColor} 
                    pId                 = {lSectionNum} 
                    key                 = {lSectionNum}
                    pNumSections        = {lNumSections}
                    pHeight             = {lHeight} 
                    pIsCriteriaSection  = {(lCriteriaActionsList > 0)}
                    pIsCriteriaLabel    = {true}
                    pAlign              = "center"
                    pChildren           = {lSectionNumControlMap[lSectionNum]}/>

                lSections.push(lSection);
            }

            if(lCriteriaActionsList.length > 0)
            {
                var lActionSectionBackgroundColor = "#FFFFFF";
                if((lNumSections % 2) === 0)
                {
                    lActionSectionBackgroundColor = "#FAFAFA";
                }
    
                var lActionSection = <CSCOVerticalSection  
                                        pBackgroundColor    = {lActionSectionBackgroundColor} 
                                        pChildren           = {this.cfGetCriteriaActions(lCriteriaActionsList)}
                                        pId                 = {lSectionNum} 
                                        key                 = {lSectionNum} 
                                        pHeight             = {lHeight} 
                                        pIsCriteriaSection  = {true}
                                        pIsCriteriaLabel    = {lLabelFlag}
                                        pAlign              = "center"
                                        pWidth              = {(lLabelFlag) ? "150px" : "60px"}/>
                lSections.push(lActionSection);
            }
        }

        return lSections;
    }

    cfGetCriteriaActions(lCriteriaActionsList)
    {
        var lActions = null;
        lActions = 
            (lCriteriaActionsList) ?
                lCriteriaActionsList.map((lActionItem) => (
                    <div key            = {lActionItem.id_} >
                        <Button
                            icon        = {CSUtil.mNameIconMap[lActionItem.icon_name_]} 
                            // style       = {{fontSize : '13.2px', height : '30px'}}
                            key         = {lActionItem.id_}
                            id          = {lActionItem.id_}
                            label       = {lActionItem.label_}
                            disabled    = { !lActionItem.mEnabled }
                            onClick     = {(event) => this.process_search(event, lActionItem)}
                            />
                    </div>
                )
            )
        : null

       return lActions;
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            var form_data = this.state.mSelectedData;
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm(lCloseFlag)
    {
        this.state.fnProcessClose(lCloseFlag === false);
    }

    process_save(lEvent, lParent, lActionItem)
    {
        this.setState({mSelectedActionItem : lActionItem});
        this.mFormUtil.cfProcessSave(lActionItem, this.state.mFormData, this.cfProcessResponse);
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    process_search(lEvent, lActionItem)
    {
        if(lActionItem && lActionItem.on_click_service_code_ && this.state.mFormItems)
        {
            var lData = null;
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                if(lFormItem.mValue)
                {
                    if(!lData)
                    {
                        lData = {}
                    }

                    lData[lFormItem.data_field_] = lFormItem.mValue;
                }
            }

            if(lData)
            {
                this.setState({mCriteria : lData, mActionItem : lActionItem});
                this.cfSearch(lActionItem.on_click_service_code_, lActionItem.on_click_request_ref_id_, lData);
            }
        }
    }

    cfSearch(lServiceCode, lRequestRefId, lData)
    {
        if(lServiceCode)
        {
            var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lRequestRefId);
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lRequestId = null;
                if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                {
                    lRequestId = lRequestObject.request_id_;
                }
    
                var lDataObj = (lData) ? lData : {};
                var lRequestUtil = new CSRequestUtil('SAVE', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lDataObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }

                lRequestUtil.cfSetRequestData(lDataObj);
                lRequestUtil.cfSetRequestCriteria(lDataObj);
                lRequestUtil.cfProcessRequest((lResponse)=>
                    {
                        this.cfSetLabelData();
                    }
                );
            }
        }
    }

    cfSetLabelData()
    {
        if(this.state.mSelectedActionItem && this.state.mSelectedCriteria)
        {
            var lCriteria = this.state.mSelectedCriteria;
            var lActionItem = this.state.mSelectedActionItem;
            var lServiceCode = lActionItem.on_click_service_code_;
            var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
            if(lCriteria && (lServiceCode || lRequestObject))
            {
                var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                if(lServiceObject)
                {
                    var lRequestId = null;
                    if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                    {
                        lRequestId = lRequestObject.request_id_;
                    }

                    var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                    if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                    {
                        var lServiceProperties = lServiceObject.cs_service_properties_;
                        for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                        {
                            lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                        }
                    }

                    lRequestUtil.cfSetRequestCriteria(lCriteria);
                    lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                        {
                            var lCollection = [];
                            if((lResponse) && lResponse.hasOwnProperty("collection_"))
                            {
                                lCollection = lResponse.collection_;
                                var lLabelList = null;
                                if(lCollection)
                                {
                                    var lDataList = lCollection.slice(this.state.mFirstRecord, this.state.mFirstRecord + this.state.mNumRecords);
                                    lLabelList = this.cfGetLabels(lDataList, this.state.mDataFieldReferenceMap);        
                                }
                        
                                this.setState(
                                    {
                                        mDataCollection : lCollection,
                                        mPageLabels     : lLabelList
                                    }
                                );
                            }
                        }
                    );
                }
            }
        }
    }

    process_print()
    {
        this.setState({ mPrintFlag : true });
    }

    cfClosePrintForm()
    {
        this.setState({ mPrintFlag : false });
    }

    process_pdf()
    {
    }

    cfGetLabels(lDataCollection, lDataFieldReferenceMap)
    {
        var lLabelList = null;
        if(lDataCollection && lDataFieldReferenceMap)
        {
            for(var lDInd=0; lDInd<lDataCollection.length; lDInd++)
            {
                var lDObj = lDataCollection[lDInd];
                var lLeftInlineText     = (lDataFieldReferenceMap['left_inline_']) ?    lDObj[lDataFieldReferenceMap['left_inline_']]	: null;
                var lRightInlineText    = (lDataFieldReferenceMap['right_inline_']) ?   lDObj[lDataFieldReferenceMap['right_inline_']]  : null;
                var lTopInlineText      = (lDataFieldReferenceMap['top_inline_']) ?     lDObj[lDataFieldReferenceMap['top_inline_']]    : null;
                var lBottomInlineText   = (lDataFieldReferenceMap['bottom_inline_']) ?  lDObj[lDataFieldReferenceMap['bottom_inline_']] : null;

                var lLeftInnerText      = (lDataFieldReferenceMap['left_inner_']) ?     lDObj[lDataFieldReferenceMap['left_inner_']]    : null;
                var lRightInnerText     = (lDataFieldReferenceMap['right_inner_']) ?    lDObj[lDataFieldReferenceMap['right_inner_']]   : null;
                var lTopInnerText       = (lDataFieldReferenceMap['top_inner_']) ?      lDObj[lDataFieldReferenceMap['top_inner_']]     : null;
                var lBottomInnerText    = (lDataFieldReferenceMap['bottom_inner_']) ?   lDObj[lDataFieldReferenceMap['bottom_inner_']]  : null;
        
                var lLeftOuterText      = (lDataFieldReferenceMap['left_outer_']) ?     lDObj[lDataFieldReferenceMap['left_outer_']]    : null;
                var lRightOuterText     = (lDataFieldReferenceMap['right_outer_']) ?    lDObj[lDataFieldReferenceMap['right_outer_']]   : null;
                var lTopOuterText       = (lDataFieldReferenceMap['top_outer_']) ?      lDObj[lDataFieldReferenceMap['top_outer_']]     : null;
                var lBottomOuterText    = (lDataFieldReferenceMap['bottom_outer_']) ?   lDObj[lDataFieldReferenceMap['bottom_outer_']]  : null;
                
                var lSection1Text1      = (lDataFieldReferenceMap['text_1_']) ?         lDObj[lDataFieldReferenceMap['text_1_']]        : null;
                var lSection1Text2      = (lDataFieldReferenceMap['text_2_']) ?         lDObj[lDataFieldReferenceMap['text_2_']]        : null;
                var lSection1Text3      = (lDataFieldReferenceMap['text_3_']) ?         lDObj[lDataFieldReferenceMap['text_3_']]        : null;
                var lSection1Text4      = (lDataFieldReferenceMap['text_4_']) ?         lDObj[lDataFieldReferenceMap['text_4_']]        : null;
                var lSection1Text5      = (lDataFieldReferenceMap['text_5_']) ?         lDObj[lDataFieldReferenceMap['text_5_']]        : null;
                var lSection1Text6      = (lDataFieldReferenceMap['text_6_']) ?         lDObj[lDataFieldReferenceMap['text_6_']]        : null;
                
                var lQRCodeText      	= (lDataFieldReferenceMap['qr_code_text_']) ?   lDObj[lDataFieldReferenceMap['qr_code_text_']]  : lDObj['qr_code_text_'];
                var lColor      	    = (lDataFieldReferenceMap['color_code_']) ?     lDObj[lDataFieldReferenceMap['color_code_']]    : '';
                var lLabel = 
                        <CSCOLabel
                            key                 = { this.mKey++ }
                            pLabelWidth         = { (this.state.mLabelWidth - this.state.mLabelMargin) + 'cm' }
                            pLabelHeight        = { (this.state.mLabelHeight - this.state.mLabelMargin) + 'cm' }
                            pLeftInlineText     = { lLeftInlineText }
                            pRightInlineText    = { lRightInlineText }
                            pTopInlineText      = { lTopInlineText }
                            pBottomInlineText   = { lBottomInlineText }
        
                            pLeftInnerText      = { lLeftInnerText }
                            pRightInnerText     = { lRightInnerText }
                            pTopInnerText       = { lTopInnerText }
                            pBottomInnerText    = { lBottomInnerText }
        
                            pLeftOuterText      = { lLeftOuterText }
                            pRightOuterText     = { lRightOuterText }
                            pTopOuterText       = { lTopOuterText }
                            pBottomOuterText    = { lBottomOuterText }
        
                            pSection1Text1      = { lSection1Text1 }
                            pSection1Text2      = { lSection1Text2 }
                            pSection1Text3      = { lSection1Text3 }
                            pSection1Text4      = { lSection1Text4 }
                            pSection1Text5      = { lSection1Text5 }
                            pSection1Text6      = { lSection1Text6 }

                            pQRCodeText      	= { lQRCodeText }
                            pColor              = { lColor }
                            />
            
                if(!lLabelList)
                {
                    lLabelList = [];
                }
                
                lLabelList.push(lLabel);
            }
        }

        return lLabelList;
    }

    cfOnPageChange(lEvent)
    {
        var lLabelList = null;
        if(this.state.mDataCollection)
        {
            var lDataList = this.state.mDataCollection.slice(lEvent.first, lEvent.first + lEvent.rows);            
            lLabelList = this.cfGetLabels(lDataList, this.state.mDataFieldReferenceMap);
        }

        this.setState(
            {
                mFirstRecord    : lEvent.first,
                mNumRecords     : lEvent.rows,
                mPageLabels     : lLabelList
            }
        );
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lColCount = 0;
        var lRows = [];
        var lDisplayLabels = [];
    
        if(this.state.mPageLabels && this.state.mPageLabels.length > 0)
        {
            for(var lInd=0; lInd<this.state.mPageLabels.length; lInd++)
            {
                lColCount++;
                if(lRows.length === 0)
                {
                    lRows.push([]);
                }

                var lItem = 
                        <div className="flex" key={lInd + 11} 
                            style={{ 
                                display         : 'flex', 
                                flexDirection   : 'row',
                                justifyContent  : 'center', 
                                alignItems      : 'center', 
                                textAlign       : 'center', 
                                backgroundColor : this.state.mLabelBackground, 
                                height          : (this.state.mLabelHeight - this.state.mLabelMarginTop - this.state.mLabelMarginBottom) + 'cm', 
                                width           : (this.state.mLabelWidth - this.state.mLabelMarginLeft - this.state.mLabelMarginRight) + 'cm', 
                                marginTop       : this.state.mLabelMarginTop + 'cm',
                                marginBottom    : this.state.mLabelMarginBottom + 'cm',
                                marginLeft      : this.state.mLabelMarginLeft + 'cm',
                                marginRight     : this.state.mLabelMarginRight + 'cm'}}>

                            {this.state.mPageLabels[lInd]}

                        </div>

                lRows[lRows.length - 1].push(lItem);
                if(lColCount === this.state.mNumColumns)
                {
                    lColCount = 0;
                    lRows.push([]);
                }
            }
        }

        var lPageBreakFlag = false;
        for(var lRInd=0; lRInd<lRows.length; lRInd++)
        {
            var lTopRowMargin = 0;
            if(lPageBreakFlag)
            {
                lTopRowMargin = this.state.mPageMarginTop + 'cm';
                lPageBreakFlag = false;
            }

            var lRow = 
                <div key={1000 + lRInd} 
                    style={{display:"flex", flexDirection : 'row', marginTop : lTopRowMargin}}>

                    {lRows[lRInd]}
                </div>

            lDisplayLabels.push(lRow);

            if((lRInd + 1) === this.state.mNumRows)
            {
                lDisplayLabels.push(<div key={10000 + lRInd} className="page-break" />)
                lPageBreakFlag = true;
            }
            else if(((lRInd + 1) > this.state.mNumRows) && ((lRInd + 1) % this.state.mNumRows === 0))
            {
                lDisplayLabels.push(<div key={10000 + lRInd} className="page-break" />)
                lPageBreakFlag = true;
            }
        }

        var lCriteriaSection = null;
        if(this.state.mCriteriaList)
        {
            lCriteriaSection = 
                <div key={this.mKey++} className="form-criteria" style={{width : '100%'}} id="ID_CRITERIA_SECTION">
                    { this.cfGetCriteriaSections(this.state.mCriteriaList) }
                </div>
        }
        let lHeaderActions = 
        <div style={{verticalAlign: 'middle'}}>            


            <Button
                key = "21"
                style=
                    {
                        {
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => this.process_close(false, true) }
            />
        </div>

        let lHeader = 
            <div className='flex justify-content-between'>
                <div className="p-toolbar-group-left">
                    <h2>{ this.state.mHeader || '.' }</h2>
                </div>

                <div className="p-toolbar-group-right">
                    { lHeaderActions }
                </div>
            </div>

        var lForm =
            <Dialog visible     = {this.state.mDisplay && !this.state.mPrintFlag}
                    modal       = {true}
                    style = 
                    {
                        {
                            width           : '75%',
                            minheight       : '600px',
                            maxHeight       : '660px',
                        }
                    }
                    minheight   = 'auto'
                    appendTo    = { document.body }
                    header      = {lHeader}
                    closable    = { false }
                    key         = {this.mKey++}
                    onHide      = {() => this.cfCloseForm(true)}>

                <div style={{margin : '-10px'}} key={this.mKey++} >
                    { lCriteriaSection }

                    <Paginator 
                        first        = {this.state.mFirstRecord} 
                        rows         = {this.state.mNumRecords} 
                        totalRecords = {(this.state.mDataCollection) ? this.state.mDataCollection.length : 0}
                        onPageChange = {this.cfOnPageChange}>
                    </Paginator>

                    <div  key={this.mKey++} 
                        style = 
                            {
                                {
                                    width           : '100%',
                                    height          : '100%',
                                    maxHeight       : '450px',
                                    overflowY       : 'auto'
                                }
                            }>
                        <div
                            key   = {this.mKey++}
                            ref   = {this.mComponentRef}
                            style = 
                            {
                                {
                                    width           : '100%',
                                    height          : '100%',
                                    margin          : 'auto',
                                    backgroundColor : this.state.mPageBackground, 
                                    paddingTop      : this.state.mPageMarginTop + 'cm',
                                    paddingBottom   : this.state.mPageMarginBottom + 'cm',
                                    paddingLeft     : this.state.mPageMarginLeft + 'cm',
                                    paddingRight    : this.state.mPageMarginRight + 'cm'
                                }
                            }>
                            {lDisplayLabels}
                        </div>
                    </div>
                </div>
            </Dialog>
          
        return lForm;
    }
}

export default CSCODetailsForm18;
