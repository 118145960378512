import React, { useState, useRef, useEffect } from 'react';
import QRCode from "qrcode.react"
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import CSConfigUtil from '../util/CSConfigUtil'; 
import CSServiceUtil from '../util/CSServiceUtil'; 
import CSRequestUtil from '../util/CSRequestUtil'; 
import CSUtil from "../util/CSUtil";

const mServiceUtil = CSServiceUtil.cfGetInstance();

const CSPurchaseOrder1 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const [ mFormTitle, setFormTitle ] = useState('Purchase Order');

    const [ mCompanyDetails, setCompanyDetails ] = useState(
        {
            mCompanyName: '',
            mAddress1   : '',
            mAddress2   : '',
            mPhone1     : '',
            mPhone2     : '',
            mEmail      : '',
            mWebsite    : '',
            mLogo       : ''
        }, []
    );

    const [ mVendorDetails, setVendorDetails ] = useState(
        {
            mTitle          : 'Consignor',
            mVendorName     : '', 
            mVendorAddress  : '', 
            mContactPerson  : '', 
            mContactNumber  : '', 
            mGstNo          : '',
            mPhoneNumber    : '',
            mEmail          : '',
        }, []
    );

    const [ mConsigneeDetails, setConsigneeDetails ] = useState(
        {
            mTitle             : 'Consignee',
            mConsigneeName     : '',
            mAddress           : '', 
            mDeliveryAddress   : '', 
            mContactPerson     : '', 
            mContactNumber     : '', 
            mPhoneNumber       : '', 
            mGstNo             : '',
            mEmail             : ''
        }, [lProps]
    );

    const [ mPurchaseOrder, setPurchaseOrder ] = useState(
        {
            mPurchaseOrderNo   : '',
            mPurchaseOrderDate : '',
            mQuotationNo       : '',
            mQuotationDate     : '',
            mPOTitle           : 'Purchase Order Items'
        }
    );

    const [ mPurchaseOrderItems, setPurchaseOrderItems ] = useState(null);

    const [ mTermsTitle, setTermsTitle ] = useState('Terms & Conditions');
    const [ mTerms, setTerms ] = useState(null);

    const [ mServiceCode, setServiceCode] = useState(null);
    const [ mRequestId, setRequestId] = useState(null);

    const [ mFooter, setFooter ] = useState("Purchase Order is a computer generated document.");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pCriteria && lProps.pFormType && lProps.pFormProperties)
                {
                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        if(lPropObj.type_ === 'initialize')
                        {
                            const lValue = lPropObj.value_;
                            if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'terms_title_')
                            {
                                setTermsTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'vendor_section_title_')
                            {
                                setVendorDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'consignee_section_title_')
                            {
                                setConsigneeDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'request_id_')
                            {
                                setRequestId(lValue);
                            }
                            else if(lPropObj.property_ === 'service_code_')
                            {
                                setServiceCode(lValue);
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lValue);
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, [lProps, setConsigneeDetails, setVendorDetails]
    );

    useEffect(() =>
        {
            const fnInitializeData = () =>
            {
                if(mServiceCode && lProps.pCriteria)
                {
                    var lServiceObject = mServiceUtil.cfGetServiceObject(mServiceCode);
                    if(lServiceObject)
                    {
                        var lCriteria = lProps.pCriteria;
                        lCriteria.office_code_ = CSConfigUtil.mOfficeCode;
                        var lRequestUtil = new CSRequestUtil('GET', mServiceCode, lServiceObject.type_, mRequestId, lServiceObject);
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse) =>
                            {
                                if(lResponse)
                                {
                                    if(lResponse.hasOwnProperty('consignee_details_') && (lResponse.consignee_details_.length > 0))
                                    {
                                        var lConsigneeDetails = lResponse.consignee_details_[0];
                                        setConsigneeDetails(lPrvState => 
                                            { 
                                                return { 
                                                    ...lPrvState,
                                                    mConsigneeName     : lConsigneeDetails.consignee_name_, 
                                                    mAddress           : lConsigneeDetails.address_, 
                                                    mDeliveryAddress   : lConsigneeDetails.delivery_address_, 
                                                    mContactPerson     : lConsigneeDetails.contact_person_, 
                                                    mContactNumber     : lConsigneeDetails.contact_number_, 
                                                    mPhoneNumber       : lConsigneeDetails.phone_no_, 
                                                    mGstNo             : lConsigneeDetails.gst_no_,
                                                    mEmail             : lConsigneeDetails.email_
                                                } 
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('company_details_') && (lResponse.company_details_.length > 0))
                                    {
                                        let lCompanyInfo = lResponse.company_details_[0];
                                        let lCompanyDetails = {};

                                        lCompanyDetails.mCompanyName = lCompanyInfo.office_name_;
                                        lCompanyDetails.mAddress1    = lCompanyInfo.address_1_;
                                        lCompanyDetails.mAddress2    = lCompanyInfo.address_2_;
                                        lCompanyDetails.mPhone1      = lCompanyInfo.office_phone_1_;
                                        lCompanyDetails.mPhone2      = lCompanyInfo.office_phone_2_;
                                        lCompanyDetails.mEmail       = lCompanyInfo.email_;
                                        lCompanyDetails.mWebsite     = lCompanyInfo.website_;
                                        lCompanyDetails.mLogo        = lCompanyInfo.logo_;

                                        setCompanyDetails(lCompanyDetails);
                                    }

                                    if(lResponse.hasOwnProperty('vendor_details_') && (lResponse.vendor_details_.length > 0))
                                    {
                                        var lVendorDetails = lResponse.vendor_details_[0];
                                        setVendorDetails(lPrevState => 
                                            { 
                                                return { 
                                                    ...lPrevState,
                                                    mVendorName        : lVendorDetails.vendor_name_, 
                                                    mVendorAddress     : lVendorDetails.vendor_address_, 
                                                    mContactPerson     : lVendorDetails.contact_person_, 
                                                    mContactNumber     : lVendorDetails.contact_number_, 
                                                    mPhoneNumber       : lVendorDetails.phone_no_, 
                                                    mGstNo             : lVendorDetails.gst_no_,
                                                    mEmail             : lVendorDetails.email_
                                                }
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('purchase_order_') && (lResponse.purchase_order_.length > 0))
                                    {
                                        var lPurchaseOrder = lResponse.purchase_order_[0];
                                        setPurchaseOrder(lPrevState =>
                                            {
                                                return {
                                                    ...lPrevState,
                                                    mPurchaseOrderNo   : lPurchaseOrder.po_num_,
                                                    mPurchaseOrderDate : lPurchaseOrder.po_date_,
                                                    mQuotationNo       : lPurchaseOrder.quotation_no_,
                                                    mQuotationDate     : lPurchaseOrder.quotation_date_,
                                                    mPOTitle           : lPurchaseOrder.po_title_
                                                }
                                            }
                                        );
                                    }

                                    if(lResponse.hasOwnProperty('purchase_order_items_') && (lResponse.purchase_order_items_.length > 0))
                                    {
                                        var lDataCollection = [];
                                        var lrcInd     = 0;
                                        var lTotalCost = 0;
                                        var lTotalGst  = 0;
                                        for(var ldInd=0; ldInd<lResponse.purchase_order_items_.length; ldInd++)
                                        {
                                            var lDataObj = lResponse.purchase_order_items_[ldInd];
                                            var lColDataList = [];

                                            lColDataList.push(<td key={lrcInd++}>               {ldInd + 1}                     </td>);
                                            lColDataList.push(<td key={lrcInd++}>               {lDataObj.class_name_}          </td>);
                                            lColDataList.push(<td key={lrcInd++}>               {lDataObj.item_name_}           </td>);
                                            lColDataList.push(<td key={lrcInd++}>               {lDataObj.item_description_}    </td>);
                                            lColDataList.push(<td align="right" key={lrcInd++}> {lDataObj.max_retail_price_}    </td>);
                                            lColDataList.push(<td align="right" key={lrcInd++}> {lDataObj.discount_percentage_} </td>);
                                            lColDataList.push(<td align="right" key={lrcInd++}> {lDataObj.cost_price_}          </td>);
                                            lColDataList.push(<td align="right" key={lrcInd++}> {lDataObj.order_qty_}           </td>);
                                            lColDataList.push(<td align="right" key={lrcInd++}> {lDataObj.total_cost_}          </td>);
                    
                                            lTotalCost += Number(lDataObj.total_cost_);
                                            lTotalGst  += Number(lDataObj.gst_);
                                            lDataCollection.push(<tr key={ldInd + 1}>{lColDataList}</tr>);
                                        }

                                        var lEmptyRow = []
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                                        lEmptyRow.push(<td key={lrcInd++}/> );
                
                                        lDataCollection.push(<tr key={lDataCollection.length + 1}>{lEmptyRow}</tr>);

                                        var lGstRow = []
                                        lGstRow.push(<td key={lrcInd++} colSpan="8" align='right'>GST </td> );
                                        lGstRow.push(<td key={lrcInd++} align='right'>{lTotalGst}</td> );
                
                                        lDataCollection.push(<tr key={lDataCollection.length + 1}>{lGstRow}</tr>);

                                        var lTotalRow = []
                                        lTotalRow.push(<td key={lrcInd++} colSpan="8" align='right'> Total </td> );
                                        lTotalRow.push(<td key={lrcInd++} align='right'>{lTotalCost}</td> );
                
                                        lDataCollection.push(<tr key={lDataCollection.length + 1}>{lTotalRow}</tr>);
                
                                        setPurchaseOrderItems(lDataCollection);
                                    }

                                    if(lResponse.hasOwnProperty('purchase_order_terms_') && (lResponse.purchase_order_terms_.length > 0))
                                    {
                                        var lTermsCollection = [];
                                        var lpdInd = 0;
                                        for(let ldtInd=0; ldtInd<lResponse.purchase_order_terms_.length; ldtInd++)
                                        {
                                            var lTermsObj = lResponse.purchase_order_terms_[ldtInd];
                                            var lTermsList = [];

                                            lTermsList.push(<td key={lpdInd++} className='terms-seq'>{lTermsObj.sequence_}  </td>);
                                            lTermsList.push(<td key={lpdInd++} className='terms-det'>{lTermsObj.details_}   </td>);
                    
                                            lTermsCollection.push(<tr key={ldtInd + 1}>{lTermsList}</tr>);
                                        }
                    
                                        setTerms(lTermsCollection);
                                    }
                                }
                            }
                        );
                    }
                }
            }

            fnInitializeData();
        }, [mServiceCode, mRequestId, lProps.pCriteria]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => (lProps.fnEnablePrint) ? lProps.fnEnablePrint(false) : null }
        />
    );

    var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    let lTerms = null;
    if(mTerms)
    {
        lTerms = 
            <div className="terms">
                <h3 className="terms-heading">
                    { mTermsTitle }
                </h3>

                { mTerms }
            </div>
    }

    const lForm = 
        <div id="po1" ref={mComponentRef} className="po1">
            <div style={{minWidth: '600px'}}>
                <header>
                    <div className="row">
                        <div className="logo">
                            <img
                                src={ mCompanyDetails.mLogo } 
                                width="100%"
                                alt=""/>
                        </div>

                        <div className="company-details">
                            <h2 className="name">
                                { mCompanyDetails.mCompanyName }
                            </h2>

                            <div>
                                { mCompanyDetails.mAddress1 }
                            </div>

                            <div>
                                { mCompanyDetails.mAddress2 }
                            </div>
                        </div>
                
                        <div className="company-contact">
                            <div>Tel No. : { mCompanyDetails.mPhone1 }</div>
                            <div>Tel No. : { mCompanyDetails.mPhone2 }</div>
                            <div>Website : { mCompanyDetails.mWebsite }</div>
                            <div>EMail   : { mCompanyDetails.mEmail }</div>
                        </div>
                    </div>
                </header>

                <main>
                    <div className="title">
                        <h1>{ mFormTitle }</h1>
                    </div>

                    <div className="sum-1">
                        <div className="summary-1">
                            <h3 className="summary-title">
                                { mConsigneeDetails.mTitle }
                            </h3>
                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Name</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mConsigneeDetails.mConsigneeName}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Address</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mConsigneeDetails.mAddress}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}/>
                                <div style={{width: '25px', fontWeight: 'bold'}}/>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Contact Person</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mConsigneeDetails.mContactPerson}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>EMail</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mConsigneeDetails.mEmail}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>GSTIN</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mConsigneeDetails.mGstNo}</div>
                            </div>
                        </div>

                        <div className="summary-2">
                            <h3 className="summary-title">
                                { mVendorDetails.mTitle }
                            </h3>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>M/S</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mVendorDetails.mVendorName}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Address</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mVendorDetails.mVendorAddress}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}/>
                                <div style={{width: '100%'}} />
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Contact Person</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mVendorDetails.mContactPerson}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Phone No</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mVendorDetails.mContactNumber}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>GSTIN</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mVendorDetails.mGstNo}</div>
                            </div>
                        </div>
                    </div>

                    <div className="sum-1">
                        <div className="summary-1">
                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>PO Number</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mPurchaseOrder.mPurchaseOrderNo}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>PO Date</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mPurchaseOrder.mPurchaseOrderDate}</div>
                            </div>
                        </div>
                                
                        <div className="summary-2">
                        <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Quotation No</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mPurchaseOrder.mQuotationNo}</div>
                            </div>

                            <div className="form-item-row"> 
                                <div style={{width: '125px'}}>Quotation Date</div>
                                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                <div style={{width: '100%'}}>{mPurchaseOrder.mQuotationDate}</div>
                            </div>
                        </div>
                    </div>

                    <>
                        <h3 className="order-details-title">
                            { mPurchaseOrder.mPOTitle }
                        </h3>

                        <div className="order-details">
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>SNo              </th>
                                        <th>Class            </th>
                                        <th>Item Name        </th>
                                        <th>Item Description </th>
                                        <th>MRP              </th>
                                        <th>Discount(%)      </th>
                                        <th>Net Price        </th>
                                        <th>Quantity         </th>
                                        <th>Amount           </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { mPurchaseOrderItems }
                                </tbody>
                            </table>
                        </div>
                    </>

                    { lTerms }
                </main>
                
                <footer>
                    { mFooter }
                </footer>
            </div>

            <div></div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            header    = {lHeader}
            appendTo  = { document.body }
            onHide    = { () => (lProps.fnEnablePrint) ? lProps.fnEnablePrint(false) : null }>
                { lForm }
        </Dialog>
    );
}

export default CSPurchaseOrder1;