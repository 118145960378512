import React from "react"

import CSCOBaseFormLayout from "../form/CSCOBaseFormLayout";
import CSFormUtil from "../../util/CSFormUtil";
import CSUtil from "../../util/CSUtil";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar";
import CSCarousel from "../../controls/CSCarousel";
import CSCODashboardReport from "./CSCODashboardReport";

class CSCODashboardLayout1 extends CSCOBaseFormLayout 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            mFeatureRefId           : props.pFeatureRefId,
            mAppId                  : props.pAppId,
            mLayoutCode             : props.pLayoutCode,
            mLayoutType             : props.pLayoutType,
            mSelectedMenuItem       : null,
            mFormLayoutHeading      : null,
            mDataCollection         : null,
            mParent                 : props.pParent,
            mSearchActionList       : null,
            mFormActions            : props.pFormActions,
            mFormItems              : props.pFormItems,
            mFormCriteria           : props.pFormCriteria,
            mFormProperties         : props.pFormProperties,
            mFormValidation         : props.pFormValidation,
            mFormSelection          : props.pFormSelection,
            mFormDetails            : props.pFormDetails,
            mFormActionProperties   : props.pFormActionProperties,
            mFormCriteriaProperties : props.pFormCriteriaProperties,
            mFormGridProperties     : props.pFormGridProperties,
            mFormGridItemProperties : props.pFormGridItemProperties,
            mFormItemProperties     : props.pFormItemProperties,
            mFormItemComputation    : props.pFormItemComputation,
            mFormData               : (this.props.pFormData !== null) ? this.props.pFormData : {},
            mSelectedCriteria       : null,
            mSelectedRecord         : null,
            mCriteria               : null,
            mActionItem             : null,
            mEnableFilter           : true,

            mAddFlag                : false,
            mDetailsFlag            : false,
            mFormFlag               : 0,
            mSelectedData           : null,
            mDetailsMetaData        : null,
            mSelectedActionItem     : null,
            mDetailsForm1           : null,
            mDashboardReportList    : null,
            mWidgetList             : null
        }

        const lDashboardReportList = [];
        const lWidgetList = [];
        for(let ldbrInd=0; ldbrInd<CSUtil.mDashboard.length; ldbrInd++)
        {
            const lDashboardReport = CSUtil.mDashboard[ldbrInd];
            if(lDashboardReport.hasOwnProperty('report_name_'))
            {
                if((lDashboardReport.type_ === 'Table') || lDashboardReport.type_ === 'Chart')
                {
                    lDashboardReportList.push(CSUtil.mDashboard[ldbrInd]);
                }
                else if(lDashboardReport.type_ === 'Widget')
                {
                    lWidgetList.push(lDashboardReport);
                }
            }
        }
        
        this.state.mDashboardReportList = lDashboardReportList;
        this.state.mWidgetList = lWidgetList;
    }

    componentDidMount() 
    {
        super.cfInitializeLayout();
    }

    componentWillUnmount() 
    {
        this.props = null;
    }

    render() 
    {
        let lSearchActions = null;
        if (this.state.mSearchActionList && (this.state.mSearchActionList.length > 0)) 
        {
            const lSearchActionList = this.state.mSearchActionList.map((lActionItem) => 
                {
                    if (lActionItem.action_type_ === '1') 
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else 
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );

            lSearchActions =
                <CSCOBaseActionBar
                    align="left"
                    pType='SEARCH_ACTION_BAR'
                    parent={this}
                    actionList={lSearchActionList}
                />;
        }

        const lLayout = 
            <div style={{width:CSUtil.mApplicationWidth}}>
                <CSCarousel 
                    pData           = { this.state.mDataCollection }
                    pFeatureRefId   = { this.state.mFeatureRefId }
                    pColumns        = { this.state.mFormGrid }
                    rowIndex        = { this.state.mRowIndex }
                    pageSize        = { 15 }
                    onClick         = { this.cfOnDataSelection }
                    pSearchActionBar= { lSearchActions }
                    pEnableFilter   = { this.state.mEnableFilter } 
                    pWidgetList     = { this.state.mWidgetList }
                />

                    <CSCODashboardReport
                        pFeatureRefId   = { this.state.mFeatureRefId }
                        pLayoutCode     = { this.state.mLayoutCode }
                        pReportList     = { this.state.mDashboardReportList }
                        pLayoutType     = { this.state.mLayoutType } 
                    />
            </div>
            
        return lLayout;
    }
}

export default CSCODashboardLayout1;
