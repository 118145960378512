var a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
var b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];

function fnConvertNumberToWords(lNumber)
{
    var lNum = null;
    if(typeof(lNumber) === 'number')
    {
        lNum = lNumber.toString();
    }
    else
    {
        lNum = lNumber;
    }

    if (!lNum || (lNum.length > 9))
    {
        return 'overflow';
    }

    var n = ('000000000' + lNum).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n)
    {
        return; 
    }
    
    var lStr = '';
    
    lStr += (n[1] !== 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    if(lStr.trim() === 'Crore')
    {
        lStr = '';
    }

    lStr += (n[2] !== 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    if(lStr.trim() === 'Lakh')
    {
        lStr = '';
    }

    lStr += (n[3] !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    if(lStr.trim() === 'Thousand')
    {
        lStr = '';
    }

    lStr += (Number(n[4]) !== 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    if(lStr.trim() === 'Hundred')
    {
        lStr = '';
    }

    lStr += (Number(n[5]) !== 0) ? ((lStr !== '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    lStr += 'only '; 

    return lStr;    
}

function fnNumberIndiaFormat(lNum, lDecimals)
{
    var lRetVal = null;
    if(lNum)
    {
        var lNumber = Number(lNum);
        var lNumDecimals = (lDecimals) ? lDecimals : 0;
        lRetVal = lNumber.toLocaleString('en-IN', 
            {
                maximumFractionDigits: lNumDecimals,
                style: 'currency',
                currency: 'INR'
            }
        );
    }

    return lRetVal;
}

function fnArraySplit(lDataCollection, lNumChunks, lBalanced) 
{    
    if (lNumChunks < 2)
    {
        return [lDataCollection];
    }

    var lCollectionLength = (lDataCollection) ? lDataCollection.length : 0;
    var lRetData          = [];
    var lIndex            = 0;
    var lDataSize         = 0;

    if (lCollectionLength % lNumChunks === 0) 
    {
        lDataSize = Math.floor(lCollectionLength / lNumChunks);
        while (lIndex < lCollectionLength) 
        {
            lRetData.push(lDataCollection.slice(lIndex, lIndex += lDataSize));
        }
    }
    else if (lBalanced) 
    {
        while (lIndex < lCollectionLength) 
        {
            lDataSize = Math.ceil((lCollectionLength - lIndex) / lNumChunks--);
            lRetData.push(lDataCollection.slice(lIndex, lIndex += lDataSize));
        }
    }
    else 
    {
        lNumChunks--;
        lDataSize = Math.floor(lCollectionLength / lNumChunks);
        if (lCollectionLength % lDataSize === 0)
        {
            lDataSize--;
        }

        while (lIndex < lDataSize * lNumChunks) 
        {
            lRetData.push(lDataCollection.slice(lIndex, lIndex += lDataSize));
        }
        
        lRetData.push(lDataCollection.slice(lDataSize * lNumChunks));
    }

    return lRetData;
}

function fnCreateOTP(lLength, lNumberFlag = false)
{
    var lResult     = '';
    var lCharLength = 0;
    if(lNumberFlag)
    {
        const lNumbers       = "0123456789";
        lCharLength = lNumbers.length;
        for ( var lIndex = 0; lIndex < lLength; lIndex++ ) 
        {
            lResult += lNumbers.charAt(Math.floor(Math.random() * lNumbers.length));
        }
    }
    else
    {
        const lCharacters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        lCharLength = lCharacters.length;
        for ( var lIndex0 = 0; lIndex0 < lLength; lIndex0++ ) 
        {
            lResult += lCharacters.charAt(Math.floor(Math.random() * lCharLength));
        }
    }

    return lResult;
}

export { fnConvertNumberToWords, fnNumberIndiaFormat, fnArraySplit, fnCreateOTP };