import React, { Component } from "react";

import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { TreeTable } from 'primereact/treetable';
import { InputText } from 'primereact/inputtext';
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from 'primereact/dropdown';

import {fnGetDateToString, fnGetTimeString} from "../util/CSDateTimeUtil";
import CSUtil from "../util/CSUtil"
import CSServiceUtil from "../util/CSServiceUtil";
import { cfGetConstantValue } from "../util/CSConstantUtil";

class CSCOTreeGrid extends Component 
{
    mPaginatorHeight        = 60;
    mDataTableHeaderHeight  = 60;
    mScrollableHeaderHeight = 60;
    mFooterHeight           = 60;
    mCheckboxRenderer       = '';

    constructor(props) 
    {
        super(props);
        this.mServiceUtil        = CSServiceUtil.cfGetInstance();
        this.mDataFieldColumnMap = {};
        this.mMaxIndex           = 0;
        this.state =    
            {
                mData                   : null,
                mKeyObjectMap           : this.props.pKeyObjectMap,
                mColumns                : this.props.pColumns,
                mFeatureRefId           : this.props.pFeatureRefId,
                mRowIndex               : this.props.rowIndex,
                mPageSize               : 20,
                mMinPageSize            : this.props.minPageSize,
                mSearchActionBar        : this.props.pSearchActionBar,
                mFooter                 : this.props.pFooter,
                mGridHeading            : this.props.pHeading,
                mEnableFilter           : this.props.pEnableFilter,
                mSelectedData           : null,
                mSelectionMode          : 'single',
                mColumnList             : [],
                mGroupingFields         : this.props.pGroupFields,
                mMinHeight              : (this.props.pMinHeight) ? (this.props.pMinHeight) : '200px',
                mColumnWidth            : (this.props.pColumnWidth) ? (this.props.pColumnWidth) : '75px',
                mAlignHeading           : (this.props.pAlignHeading) ? (this.props.pAlignHeading) : 'center',
                mScrollHeight           : (this.props.pScrollHeight) ? (this.props.pScrollHeight) : '400px',
                mEnableScroll           : (this.props.pEnableScroll) ? this.props.pEnableScroll : false,

                cfOnClick               : this.props.onClick,
                cfOnItemChange          : this.props.OnItemChange
            }

        this.cfOnSelectionChange        = this.cfOnSelectionChange.bind(this);
        this.cfOnSelect                 = this.cfOnSelect.bind(this);
        this.cfInitializeGrid           = this.cfInitializeGrid.bind(this);
        this.cfInitializeSummary        = this.cfInitializeSummary.bind(this);

        this.cfGetRenderer              = this.cfGetRenderer.bind(this);
        this.cfDropdownRendererTemplate = this.cfDropdownRendererTemplate.bind(this);
        this.cfCheckboxRendererTemplate = this.cfCheckboxRendererTemplate.bind(this);

        this.cfGetEditor                          = this.cfGetEditor.bind(this);
        this.cfMultiselectDropdownEditorTemplate  = this.cfMultiselectDropdownEditorTemplate.bind(this);
        this.cfOnMultiselectColumnSelection       = this.cfOnMultiselectColumnSelection.bind(this);
        this.cfInputTextEditorTemplate            = this.cfInputTextEditorTemplate.bind(this);
        this.cfDatefieldEditorTemplate            = this.cfDatefieldEditorTemplate.bind(this);
        this.cfTimefieldEditorTemplate            = this.cfTimefieldEditorTemplate.bind(this);
        this.cfOnCheckboxSelection                = this.cfOnCheckboxSelection.bind(this);
        this.cfHeaderColumnGroup                  = this.cfHeaderColumnGroup.bind(this);
        this.cfFooterColumnGroup                  = this.cfFooterColumnGroup.bind(this);

        if(this.props.pData instanceof Array)
        {
            this.state.mData = this.props.pData;
        }

        if(this.props.pScrollHeight)
        {
            let lScrollHeight = this.props.pScrollHeight;
            lScrollHeight = lScrollHeight - this.mScrollableHeaderHeight - this.mDataTableHeaderHeight - this.mPaginatorHeight;  
            
            if(this.props.pFooter)
            {
                this.state.mScrollHeight = this.state.mScrollHeight - this.mFooterHeight;
            }

            this.state.mScrollHeight = lScrollHeight.toFixed(0) + 'px';
        }
    }

    componentDidMount() 
    {
    }

    componentDidUpdate(lPrevProps, lPrevState) 
    {
        if ((lPrevProps.pFeatureRefId !== this.props.pFeatureRefId) ||
            (lPrevProps.pColumns !== this.props.pColumns) ||
            (lPrevProps.pData !== this.props.pData) ||
            (lPrevProps.rowIndex !== this.props.rowIndex) ||
            (lPrevProps.pSearchActionBar !== this.props.pSearchActionBar) ||
            (lPrevProps.minPageSize !== this.props.minPageSize) ||
            (lPrevProps.pEnableFilter !== this.props.pEnableFilter))  
        {
            let lScrollHeight = null;

            if(this.props.pScrollHeight)
            {
                lScrollHeight = this.props.pScrollHeight;
                lScrollHeight = lScrollHeight - this.mScrollableHeaderHeight - this.mDataTableHeaderHeight - this.mPaginatorHeight;
            
                if(this.props.pFooter)
                {
                    lScrollHeight = lScrollHeight - this.mFooterHeight;
                }

                lScrollHeight = lScrollHeight.toFixed(0) + 'px'
            }
           
            this.setState(
                {
                    mColumns            : this.props.pColumns,
                    mFeatureRefId       : this.props.pFeatureRefId,
                    mRowIndex           : this.props.rowIndex,
                    mPageSize           : this.props.pageSize,
                    mSearchActionBar    : this.props.pSearchActionBar,
                    mGridHeading        : this.props.pHeading,
                    mFooter             : this.props.pFooter,
                    mMinPageSize        : this.props.minPageSize,
                    mData               : (this.props.pData instanceof Array) ? this.props.pData : null,
                    mKeyObjectMap       : this.props.pKeyObjectMap,
                    mScrollHeight       : lScrollHeight,
                    mEnableScroll       : this.props.pEnableScroll,
                }
            );
        }
    }

    cfInitializeGrid() 
    {
        if (this.state.mColumns) 
        {
            let lRowSelectionMode   = this.state.mSelectionMode;
            let lEditableGrid       = false;
            let lVisibleColumns = [];
            for(let lInd=0; lInd<this.state.mColumns.length; lInd++)
            {
                let lColumn = this.state.mColumns[lInd];
                if(lColumn.visible_ === '1')
                {
                    lVisibleColumns.push(lColumn);
                }
            }
      
            const lColumnList = lVisibleColumns.map((lColumn, index) => 
                {
                    let lCol = null;
                    var lEditor = null;
                    let lRenderer = null;

                    if(lColumn.item_editor_)
                    {
                        lEditor = this.cfGetEditor(lColumn.item_editor_);
                    }
                    else if(lColumn.editable_ === '1')
                    {
                        lEditableGrid = true;
                        lEditor = this.cfGetEditor('DEFAULT');
                    }

                    this.mDataFieldColumnMap[lColumn.data_field_] = lColumn;

                    if(lColumn.item_renderer_)
                    {
                        if(lColumn.item_renderer_)
                        {
                            if(lColumn.item_renderer_ === 'MULTI_SELECT_RENDERER_1')
                            {
                                lRowSelectionMode = 'checkbox';
                            }
                            else if(lColumn.item_renderer_ === 'SINGLE_SELECT_RENDERER_1')
                            {
                                lRowSelectionMode = 'single'
                            }
                            else
                            {
                                lRowSelectionMode = 'single'
                                lRenderer = this.cfGetRenderer(lColumn.item_renderer_);
                            }
                        }
                    }

                    const lExpanderFlag = (this.props.pGroupFields && this.props.pGroupFields.includes(lColumn.data_field_));
                    lCol = 
                        <Column 
                            field           = { (lExpanderFlag) ? '__NAME__': lColumn.data_field_ } 
                            header          = { lColumn.column_name_ } 
                            editor          = { lEditor }
                            body            = { lRenderer }
                            expander        = { lExpanderFlag }
                            key             = { ++this.mMaxIndex }
                    />;

                    return lCol;
                }
            );

            this.cfInitializeSummary(lColumnList);

            return ([ lColumnList, lRowSelectionMode, lEditableGrid, null, this.cfHeaderColumnGroup() ])
        }
        else
        {
            return null;
        }
    }

    cfHeaderColumnGroup()
    {
        var lSummaryColGroup = null;
        if(this.props.pHeaderColumns)
        {
            CSUtil.cfSort(this.props.pHeaderColumns, 'sequence_');
            var lHeaderColumnSpanMap = {};
            for(var lInd=0; lInd<this.props.pHeaderColumns.length; lInd++)
            {
                var lhColumn = this.props.pHeaderColumns[lInd];
                if(lhColumn)
                {
                    var lhColumnList = lHeaderColumnSpanMap[lhColumn.property_];
                    if(!lhColumnList)
                    {
                        lhColumnList = [];
                        lHeaderColumnSpanMap[lhColumn.property_] = lhColumnList;
                    }

                    lhColumnList.push(lhColumn);
                }
            }

            var lungroupedColumns = [];
            var lgroupedColumns = []
            for(var lcInd=0; lcInd<this.state.mColumns.length; lcInd++)
            {
                var lColumn = this.state.mColumns[lcInd];
                if(lColumn.visible_ === '1')
                {     
                    if(lColumn.hasOwnProperty('property_') && lColumn.hasOwnProperty('colSpan'))
                    {
                        var lExists = false;
                        for(var lugcInd=0; lugcInd<lungroupedColumns.length; lugcInd++)
                        {
                            var lElement = lungroupedColumns[lugcInd];
                            if(lElement.props.header === lColumn.property_)
                            {
                                lExists = true;
                                break;
                            }
                        }

                        if(!lExists)
                        {
                            var lClm1 = 
                                <Column
                                    headerStyle = 
                                    {
                                        {
                                            fontSize            : '13px',
                                            fontWeight          : 'normal',
                                            color               : '#ffffff',
                                            backgroundColor     : '--primary-color',
                                            sortableColumnIcon  :
                                            {
                                                backgroundColor : '#ffffff',
                                                backgroundImage : 'none',
                                                color           : '#ffffff' 
                                            }
                                        }
                                    }
                                    header={lColumn.property_} 
                                    colSpan={(lColumn.colSpan) ? Number(lColumn.colSpan) : 1} />

                            lungroupedColumns.push(lClm1);

                            var lgColumns = lHeaderColumnSpanMap[lColumn.property_];
                            if(lgColumns)
                            {
                                for(var lgcInd=0; lgcInd<lgColumns.length; lgcInd++)
                                {
                                    var lClm2 = 
                                        <Column
                                            headerStyle = 
                                            {
                                                {
                                                    fontSize            : '13px',
                                                    fontWeight          : 'normal',
                                                    color               : '#ffffff',
                                                    backgroundColor     : '--primary-color',
                                                    sortableColumnIcon  :
                                                    {
                                                        backgroundColor : '#ffffff',
                                                        backgroundImage : 'none',
                                                        color           : '#ffffff' 
                                                    }
                                                }
                                            }
                                            header={(lgColumns[lgcInd].name_) ? lgColumns[lgcInd].name_ : lgColumns[lgcInd].column_name_} />

                                    lgroupedColumns.push(lClm2);        
                                }
                            }
                        }
                    }
                    else
                    {
                        var lClm0 = 
                            <Column
                                headerStyle = 
                                {
                                    {
                                        fontSize            : '13px',
                                        fontWeight          : 'normal',
                                        color               : '#ffffff',
                                        backgroundColor     : '--primary-color',
                                        sortableColumnIcon  :
                                        {
                                            backgroundColor : '#ffffff',
                                            backgroundImage : 'none',
                                            color           : '#ffffff' 
                                        }
                                    }
                                }
                                header={lColumn.column_name_} 
                                rowSpan={2} />

                        lungroupedColumns.push(lClm0);
                    }
                }
            }

            if(lungroupedColumns.length > 0)
            {
                lSummaryColGroup = 
                    <ColumnGroup>
                        <Row>
                            {lungroupedColumns}
                        </Row>

                        <Row>
                            {lgroupedColumns}
                        </Row>
                    </ColumnGroup>;
            }
            else
            {
                lSummaryColGroup = 
                    <ColumnGroup>
                        <Row>
                            {lgroupedColumns}
                        </Row>
                    </ColumnGroup>;
            }
        }

        return lSummaryColGroup;
    }

    cfFooterColumnGroup()
    {
        var lSummaryColGroup = null;
        if(this.state.mSummaryColumns)
        {
            CSUtil.cfSort(this.state.mSummaryColumns, 'sequence_');
            var lSequenceColumnsMap = {};
            for(var lInd=0; lInd<this.state.mSummaryColumns.length; lInd++)
            {
                var lColumn = this.state.mSummaryColumns[lInd];
                if(lColumn)
                {
                    var lColumnList = lSequenceColumnsMap[lColumn.sequence_];
                    if(!lColumnList)
                    {
                        lColumnList = [];
                        lSequenceColumnsMap[lColumn.sequence_] = lColumnList;
                    }

                    lColumnList.push(lColumn);
                }
            }

            var lColumnGroup = null;
            var lKeys = Object.keys(lSequenceColumnsMap);
            if(lKeys && (lKeys.length > 0))
            {
                if(!lColumnGroup)
                {
                    lColumnGroup = []
                }

                for(var lInd0=0; lInd0<lKeys.length; lInd0++)
                {
                    var lValues = lSequenceColumnsMap[lKeys[lInd0]];
                    if(lValues && (lValues.length > 0))
                    {
                        var lColumnData = null
                        for(var lInd1=0; lInd1<lValues.length; lInd1++)
                        {
                            if(lValues[lInd1].type_ === 'summary_column_footer')
                            {
                                if(!lColumnData)
                                {
                                    lColumnData = {}
                                }

                                lColumnData[lValues[lInd1].property_] = lValues[lInd1].value_;
                            }
                        }
 
                        if(lColumnData)
                        {
                            if(lColumnData.hasOwnProperty('footer'))
                            {
                                var lFooter = lColumnData.footer;
                                var lHeaderColumn = 
                                    <Column  
                                        style   = { {fontSize : '13px'} }
                                        footer  = { lFooter } 
                                        colSpan = { (lColumnData.hasOwnProperty('colSpan')) ? Number(lColumnData.colSpan) : 1} />
                                        
                                lColumnGroup.push(lHeaderColumn);
                                
                                var lDataValue = 0;
                                if((lColumnData.hasOwnProperty('data_field') && lColumnData.operation && this.state.mData))
                                {
                                    if(lColumnData.operation === 'SUM')
                                    {
                                        for(var lInd2=0; lInd2<this.state.mData.length; lInd2++)
                                        {
                                            var lData = this.state.mData[lInd2];
                                            if(lData.hasOwnProperty(lColumnData.data_field))
                                            {
                                                lDataValue += Number(lData[lColumnData.data_field]);
                                            }
                                        }

                                        if(lColumnData.hasOwnProperty('summary_field'))
                                        {
                                            for(var lInd3=0; lInd3<this.state.mData.length; lInd3++)
                                            {
                                                var lData3 = this.state.mData[lInd3];
                                                if(!lData3.hasOwnProperty(lColumnData.summary_field))
                                                {
                                                    lData3[lColumnData.summary_field] = lDataValue;
                                                }
                                            }    
                                        }
                                    }
                                }

                                var lDataColumn = 
                                    <Column footer={lDataValue.toFixed(0)} colSpan={1} style={{fontSize : '13px'}}/>
                                        
                                lColumnGroup.push(lDataColumn);
                            }
                        }
                    }
                }

                if(lColumnGroup.length > 0)
                {
                    lSummaryColGroup = 
                        <ColumnGroup>
                            <Row>
                                {lColumnGroup}
                            </Row>
                        </ColumnGroup>;
                }
            }
        }

        return lSummaryColGroup;
    }

    cfInitializeSummary()
    {
        if(this.state.mColumns && this.state.mData)
        {
            const lSummaryFieldList = [];
            const lSummaryFieldOperationMap = {};
            for(let lInd=0; lInd<this.state.mColumns.length; lInd++)
            {
                const lColumn = this.state.mColumns[lInd];
                if(lColumn.summary_field_ === '1')
                {
                    lSummaryFieldList.push(lColumn.data_field_);
                    lSummaryFieldOperationMap[lColumn.data_field_] = lColumn.operation_;
                }
            }

            if(lSummaryFieldList.length > 0)
            {
                for(let lInd0=0; lInd0<lSummaryFieldList.length; lInd0++)
                {
                    const lSummaryField = lSummaryFieldList[lInd0];
                    for(let lInd1=0; lInd1<this.state.mData.length; lInd1++)
                    {
                        const lData = this.state.mData[lInd1];
                        if(lData.hasOwnProperty('children') && lData.data)
                        {
                            lData.data[lSummaryField] = this.cfGetSummaryValue(lData.children, lSummaryField, lSummaryFieldOperationMap[lSummaryField]);
                        }
                    }
                }
            }
        }
    }

    cfGetSummaryValue(lList, lSummaryField, lOperation)
    {
        let lRetValue = -1;
        if(lList)
        {
            if(lOperation === 'SUM')
            {
                lRetValue = 0;

                for(let lInd1=0; lInd1<lList.length; lInd1++)
                {
                    const lData = lList[lInd1];
                    if(lData.children && lData.data)
                    {
                        lData.data[lSummaryField] = this.cfGetSummaryValue(lData.children, lSummaryField, lOperation);
                        lRetValue = lRetValue + Number(Number(lData.data[lSummaryField]).toFixed(2));
                    }
                    else
                    {
                        if(lData.data[lSummaryField])
                        {
                            lRetValue = lRetValue + Number(Number(lData.data[lSummaryField]).toFixed(2));
                        }
                    }
                }
            }
            else if(lOperation === 'CONCAT')
            {
                lRetValue = '';

                for(let lInd2=0; lInd2<lList.length; lInd2++)
                {
                    const lData1 = lList[lInd2];
                    if(lData1.children && lData1.data)
                    {
                        lData1.data[lSummaryField] = this.cfGetSummaryValue(lData1.children, lSummaryField, lOperation);
                        if(!lRetValue)
                        {
                            lRetValue = lData1.data[lSummaryField];
                        }
                        else
                        {
                            lRetValue = lRetValue + ', ' + lData1.data[lSummaryField];
                        }
                    }
                    else
                    {
                        if(lData1.data[lSummaryField])
                        {
                            if(!lRetValue)
                            {
                                lRetValue = lData1.data[lSummaryField];
                            }
                            else
                            {
                                lRetValue = lRetValue + ', ' + lData1.data[lSummaryField];
                            }
                        }
                    }
                }
            }
            else
            {
                lRetValue = '';   
                for(let lInd3=0; lInd3<lList.length; lInd3++)
                {
                    const lData2 = lList[lInd3];
                    if(lData2.children && lData2.data)
                    {
                        lData2.data[lSummaryField] = this.cfGetSummaryValue(lData2.children, lSummaryField, lOperation);
                        if(!lRetValue)
                        {
                            lRetValue = lData2.data[lSummaryField];
                            break;
                        }
                    }
                    else
                    {
                        if(lData2.data[lSummaryField])
                        {
                            if(!lRetValue)
                            {
                                lRetValue = lData2.data[lSummaryField];
                                break;
                            }
                        }
                    }
                }
            }
        }

        return lRetValue;
    }

    cfGetRenderer(lItemRenderer)
    {
        let lRetValue = null;
        if(lItemRenderer)
        {
            switch(lItemRenderer)
            {
                case 'DROP_DOWN_RENDERER_1':
//                    lRetValue = this.cfDropdownRendererTemplate;
                break;
                
                case 'CHK_BOX_RENDERER_1':
                    lRetValue = this.cfCheckboxRendererTemplate;
                    this.mCheckboxRenderer = true;
                break;
                
                case 'TEXT_INPUT_RENDERER_1':
                break;
                
                case 'QR_CODE_RENDERER_1':
//                    lRetValue = this.cfQRCodeTemplate;
                break;
                
                case 'DATE_RENDERER_1':
                break;

                default: 
                break;
            }
        }

        return lRetValue;
    }
    
    cfDropdownRendererTemplate(lRowData, lCol)
    {
        return (
            <Dropdown 
                style       = {{width:'100%'}} 
                onChange    = {(e) => this.cfOnColumnSelection(e.target.value)}>
            </Dropdown>
        );
    }
    
    cfCheckboxRendererTemplate(lRowData, lCol)
    {
        var lDataObj = (lRowData && lRowData.hasOwnProperty('data')) ? lRowData.data : lRowData;
        return (
            <div style={{alignItems : 'center', justifyContent : 'center', justifyItems : 'center', width : '100%', textAlign:'center'}}>
                <Checkbox 
                    checked     = {(lDataObj && lCol && (lDataObj[lCol.field] === '1')) ? true : false}
                    onChange    = {(e) => this.cfOnCheckboxSelection(e.checked, lDataObj, lCol)}>
                </Checkbox>
            </div>
        );
    }

    cfOnMultiselectColumnSelection(lProps, lValue)
    {
        var lRowData = lProps.node.data;
        var lColumn = this.mDataFieldColumnMap[lProps.field];
        if(lValue instanceof Object)
        {
            if(lColumn && lColumn.data_provider_id_)
            {
                var lSelection = null;
                if(lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
                {
                    for(var lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
                    {
                        const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                        if((lfgipObj.param_ === "data_provider_source_") && (lValue instanceof Array))
                        {
                            for(var lSelInd=0; lSelInd<lValue.length; lSelInd++)
                            {
                                var lSelObj = lValue[lSelInd];
                                if(lSelInd === 0)
                                {
                                    lSelection = lSelObj[lfgipObj.selection_property_] 
                                }
                                else
                                {
                                    lSelection = lSelection + "," + lSelObj[lfgipObj.selection_property_];
                                }
                            }

                            lRowData[lProps.field] = lSelection;
                        }
                    }
                }

                if(this.state.cfOnItemChange)
                {
                    this.state.cfOnItemChange(this.state.mData, lProps.rowData, lProps.rowIndex, lValue, lColumn);
                }
            }
        }
    }
    
    cfOnCheckboxSelection(lValue, lRowData, lCol)
    {
        if(lRowData && lCol)
        {
            if(lValue)
            {
                lRowData[lCol.field] = "1";
            }
            else
            {
                lRowData[lCol.field] = "0";
            }
        }
    }

    cfGetEditor(lItemEditor)
    {
        var lRetValue;
        if(lItemEditor)
        {
            switch(lItemEditor)
            {
                case 'COMBO_BOX_EDITOR_1':
                    lRetValue = this.cfDropdownEditorTemplate;
                break;
                
                case 'MULTI_SELECT_COMBO_BOX_EDITOR_1':
                    lRetValue = this.cfMultiselectDropdownEditorTemplate;
                break;
                
                case 'DATE_FIELD_EDITOR_1':
                    lRetValue = this.cfDatefieldEditorTemplate;
                break;
                
                case 'TIME_FIELD_EDITOR_1':
                    lRetValue = this.cfTimefieldEditorTemplate;
                break;
                
                case 'TEXT_FIELD_EDITOR_1':
                    lRetValue = this.cfInputTextEditorTemplate;
                break;

                default: 
                    lRetValue = this.cfInputTextEditorTemplate;
                break;
            }
        }

        return lRetValue;
    }

    cfDropdownEditorTemplate(lProps)
    {
        var lRowData = lProps.rowData;
        var lColumn = this.mDataFieldColumnMap[lProps.field];
        if(lColumn && lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
        {
            for(var lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
            {
                const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                if(lfgipObj.property_ === 'item_editor_properties')
                {
                    if(lfgipObj.type_ === 'data_field')
                    {
                        if((lfgipObj.param_ === 'data_provider_source_') && lfgipObj.value_)
                        {
                            const lTmpVal = lfgipObj.value_;
                            this.mServiceUtil.cfGetDataFromDataProvider (lTmpVal, 
                                (lDataProviderSource, lCollection)=>
                                {
                                    if(lDataProviderSource === lTmpVal)
                                    {
                                        var lDataProvider = null; 
                                        if(lfgipObj.selection_property_ && lCollection && lRowData)
                                        {
                                            for(var lInd=0; lInd<lCollection.length; lInd++)
                                            {
                                                if(lCollection[lInd][lfgipObj.selection_property_] === lRowData[lfgipObj.selection_property_])
                                                {
                                                    if(!lDataProvider)
                                                    {
                                                        lDataProvider = [];
                                                    }

                                                    lDataProvider.push(lCollection[lInd]);
                                                }
                                            }
                                        }
                                        else
                                        {
                                            lDataProvider = lCollection;
                                        }

                                        lColumn.data_provider_ = lDataProvider;
                                    }
                                }
                            );
                        }
                        else if((lfgipObj.param_ === 'label_field_') && lfgipObj.value_)
                        {
                            lColumn.data_provider_display_field_ = lfgipObj.value_;
                        }
                        else if(lfgipObj.param_ === 'data_provider_id_')
                        {
                            lColumn.data_provider_id_ = lfgipObj.value_;;
                        }
                    }
                }
            }
        }

        return (
            <Dropdown
                key         = { lProps.rowIndex}
                appendTo    = { document.body }
                value       = { lProps.value[lProps.rowIndex][lProps.field] } 
                options     = { (lColumn && lColumn.hasOwnProperty('data_provider_')) ? lColumn.data_provider_ : null }
                optionLabel = { (lColumn && lColumn.hasOwnProperty('data_provider_display_field_')) ? lColumn.data_provider_display_field_ : null }
                onChange    = { (e) => this.cfOnColumnSelection(lProps, e.target.value) }
            />
        );
    }
 
    cfMultiselectDropdownEditorTemplate(lProps)
    {
        let lRowData = lProps.node.data;
        let lColumn = this.mDataFieldColumnMap[lProps.field];
        let lDpSource = null;
        let lSelection = [];
        let lSelectionProperty = null;
        let lDependentFields = [];
        let lDependentFieldObjectMap = {};
        let lDependentMap = {}
        if(lColumn.hasOwnProperty('cs_form_grid_item_properties_') && lColumn.cs_form_grid_item_properties_)
        {
            for(let lInd=0; lInd< lColumn.cs_form_grid_item_properties_.length; lInd++)
            {
                const lfgipObj = lColumn.cs_form_grid_item_properties_[lInd];
                if(lfgipObj.property_ === 'item_editor_properties')
                {
                    if(lfgipObj.type_ === 'data_field')
                    {
                        if((lfgipObj.param_ === 'data_provider_source_') && lfgipObj.value_)
                        {
                            lDpSource = lfgipObj.value_;
                            if(lfgipObj.selection_property_)
                            {
                                lSelectionProperty = lfgipObj.selection_property_;
                                lDependentFields.push(lfgipObj.selection_property_);
                            }
                        }
                        else if((lfgipObj.param_ === 'dependent_field_') && lfgipObj.selection_property_)
                        {
                            lDependentFieldObjectMap[lfgipObj.selection_property_] = lfgipObj;
                            lDependentFields.push(lfgipObj.selection_property_);
                        }
                        else if((lfgipObj.param_ === 'dependent_constant_') && lfgipObj.selection_property_)
                        {
                            lDependentFieldObjectMap[lfgipObj.selection_property_] = lfgipObj;
                            lDependentFields.push(lfgipObj.selection_property_);
                            lDependentMap[lfgipObj.selection_property_] = cfGetConstantValue(lfgipObj.value_);
                        }
                        else if((lfgipObj.param_ === 'label_field_') && lfgipObj.value_)
                        {
                            lColumn.data_provider_display_field_ = lfgipObj.value_;
                        }
                        else if(lfgipObj.param_ === 'data_provider_id_')
                        {
                            lColumn.data_provider_id_ = lfgipObj.value_;;
                        }
                    }
                }
            }
        }
        else if(lColumn.hasOwnProperty('data_provider_source_'))
        {
            lDpSource = lColumn.data_provider_source_;
        }

        if(lDpSource)
        {                
            this.mServiceUtil.cfGetDataFromDataProvider (lDpSource, 
                (lDataProviderSource, lCollection)=>
                {
                    if(lDataProviderSource === lDpSource)
                    {
                        if(lCollection && lRowData)
                        {
                            if((lDependentFields.length > 0) && lCollection && lRowData)
                            {
                                let lDpField = lColumn.group_;
                                for(let lInd=0; lInd<lCollection.length; lInd++)
                                {
                                    let lcObj = lCollection[lInd];
                                    let lIncFlag = false;
                                    if(lDependentFields.length > 0)
                                    {
                                        for(let ldInd=0; ldInd<lDependentFields.length; ldInd++)
                                        {
                                            if(lRowData.hasOwnProperty(lDependentFields[ldInd]))
                                            {
                                                let lDepObj = lDependentFieldObjectMap[lDependentFields[ldInd]];
                                                if(lDepObj && (lDepObj.param_ === 'dependent_field_'))
                                                {
                                                    if(!lDepObj.value_)
                                                    {
                                                        if(lcObj[lDependentFields[ldInd]] === lRowData[lDependentFields[ldInd]])
                                                        {
                                                            lIncFlag = true;
                                                        }
                                                        else
                                                        {
                                                            lIncFlag = false;
                                                            break;
                                                        }
                                                    }
                                                    else
                                                    {
                                                        if(lcObj[lDependentFields[ldInd]] === lRowData[lDepObj.value_])
                                                        {
                                                            lIncFlag = true;
                                                        }
                                                        else
                                                        {
                                                            lIncFlag = false;
                                                            break;
                                                        }
                                                    }
                                                }
                                                else if(lDpField)
                                                {
                                                    if(lRowData[lDependentFields[ldInd]] === lcObj[lDpField])
                                                    {
                                                        lIncFlag = true;
                                                    }
                                                    else
                                                    {
                                                        lIncFlag = false;
                                                        break;
                                                    }    
                                                }
                                            }
                                            else
                                            {
                                                lIncFlag = false;
                                            }
                                        }
                                    }
                                    else if(lDpField)
                                    {
                                        if(lcObj[lDpField] === lRowData[lDpField])
                                        {
                                            lIncFlag = true;
                                        }
                                    }
    
                                    if(lIncFlag)
                                    {
                                        if(!lDataProvider)
                                        {
                                            lDataProvider = [];
                                        }
    
                                        lDataProvider.push(lcObj);
                                    }
                                }
                            }

                            let lColumnData = lRowData[lColumn.data_field_];
                            if(lColumnData && lDataProvider)
                            {
                                if((lColumnData instanceof Array) || (lColumnData instanceof Object))
                                {
                                }
                                else if(lSelectionProperty)
                                {
                                    const lKeys = lColumnData.split(",");
                                    if(lKeys)
                                    {
                                        for(let lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                                        {
                                            for(let lInd=0; lInd<lDataProvider.length; lInd++)
                                            {
                                                let lDataObj = lDataProvider[lInd];
                                                if(lDataObj.hasOwnProperty(lSelectionProperty) && (lDataObj[lSelectionProperty] === lKeys[lKeyInd]))
                                                {
                                                    lSelection.push(lDataObj);
                                                }
                                            }
                                        }
                                    }
                                }  
                            }
                        }
                        else
                        {
                            lDataProvider = lCollection;
                        }

                        lColumn.data_provider_ = lDataProvider;
                    }
                }
            );
        }

        var lLabel =  (lColumn && lColumn.hasOwnProperty('data_provider_display_field_')) ? lColumn.data_provider_display_field_ : null;
        var lDataProvider = (lColumn && lColumn.hasOwnProperty('data_provider_')) ? lColumn.data_provider_ : null;

        if(!lDataProvider)
        {
            lDataProvider = []
        }

        return (
            <MultiSelect
//                key         = { lProps.rowIndex}
                appendTo    = { document.body }
                value       = { lSelection } 
                options     = { lDataProvider }
                optionLabel = { lLabel }
                onChange    = { (e) => this.cfOnMultiselectColumnSelection(lProps, e.target.value) }
                display     = "chip"
                filter      = {true}
            />
        );
    }

    cfDatefieldEditorTemplate(lProps)
    {
        var lRowData = lProps.rowData;
        return (
            <Calendar
                style       = {{height : '28px', width : '100%'}}
                appendTo    = { document.body }
                inputStyle  = {{fontSize : '12px', height : '28px', width : '100%'}}
                onChange    = {(e) => lRowData[lProps.field] = fnGetDateToString(e.value)}
                dateFormat  = "dd-mm-yy"
                />
        );
    }

    cfTimefieldEditorTemplate(lProps)
    {
        var lRowData = lProps.rowData;
        return (
            <Calendar
                inputClassName = "cs-time-field"
                hourFormat  = "24"
                timeOnly    = {true}
                showTime    = {true} 
                style       = {{height : '28px', width : '100%'}}
                appendTo    = { document.body }
                inputStyle  = {{fontSize : '12px', height : '28px', width : '100%'}}
                onChange    = {(e) => lRowData[lProps.field] = fnGetTimeString(e.value)}
                showIcon    = {false}
                />
        );
    }

    cfInputTextEditorTemplate(lProps) 
    {
        var lField = lProps.field;
        return (
            <InputText 
                type        = "text" 
                value       = {(lField) ? lProps.rowData[lField] : null} 
                onChange={(e) => this.cfOnColumnSelection(lProps, e.target.value)} />
        );
    }

    cfOnSelectionChange(lEvent) 
    {
        if(lEvent) 
        {
            this.setState({mSelectedData : lEvent.value})
        }
    }

    cfOnSelect(lEvent) 
    {
        if(lEvent && this.state.hasOwnProperty('cfOnClick') && this.state.cfOnClick) 
        {
            if(lEvent.hasOwnProperty('node'))
            {
                var lSummaryNode = (lEvent.node.hasOwnProperty('children')) ? '1' : '0';
                var lData = null;
                if(lEvent.node.hasOwnProperty('data'))
                {
                    lData = lEvent.node.data;
                }
                else
                {
                    lData = {}
                }

                lData['__SUMMARY_NODE__'] = lSummaryNode;
                this.state.cfOnClick(lData);
            }
        }
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        const lTableData = this.cfInitializeGrid();
        let lTable = null;

        if(lTableData[0])
        {
            let lFilter = null;
            if(this.state.mEnableFilter) 
            {
                lFilter = 
                    <div className="p-inputgroup">
                        <span className     = "p-inputgroup-addon" >
                            <i className    = {CSUtil.mNameIconMap['FA_FILTER']}> </i>
                        </span>
                        
                        <InputText 
                            style       = {{ fontSize: '12px' }}
                            type        = "search" 
                            onInput     = {(e) => this.setState({mGlobalFilter: e.target.value})} 
                            placeholder = "" 
                            size="30"/>
                    </div>
            }

            let lHeading = null;
            if(this.state.mGridHeading)
            {
                lHeading = 
                    <div style={{display: "inline-block", float: this.state.mAlignHeading}}>
                        <h3>{this.state.mGridHeading}</h3>
                    </div>
            }
            
            let lSearchActions = null;
            if(this.state.mSearchActionBar)
            {
                lSearchActions = 
                    <div  style={{float: 'right'}}>
                        {this.state.mSearchActionBar}
                    </div>
            }

            let lHeader = null;
            if(lTableData[0] && ((lTableData[0]).length > 0) && (lFilter || lHeading || lSearchActions))
            {
                lHeader = 
                    <div className="fluid formgrid grid">
                        <div align    = "center"
                            key       = {this.state.mId}
                            className = "col-12 md:col-6 lg:col-3">
                            { lFilter }
                        </div>
                        
                        <div align    = "center"
                            key       = {this.state.mId}
                            className = "col-12 md:col-6 lg:col-5">
                            { lHeading }
                        </div>
                        
                        <div align    = "right"
                            key       = {this.state.mId}
                            className = "col-12 md:col-6 lg:col-3">
                            { lSearchActions }
                        </div>

                       
                    </div>
            }

            const lGridData = (this.state.mData) ? this.state.mData : []; 
            lTable = null;
            if(lTableData[0].length > 0) {
                lTable = <TreeTable
                    style = 
                    { 
                        {
                            fontSize    : '11.9px',
                            minheight   : this.state.mMinHeight,
                            width       : '100%'
                        }
                    }

                    scrollable              = {this.state.mEnableScroll}
                    scrollHeight            = {this.state.mScrollHeight}
                    paginator               = {true}
                    paginatorPosition       = "top"
                    rows                    = { this.state.mPageSize }
                    columnResizeMode        = "fit"
                    resizableColumns        = { true}
                    selectionMode           = { lTableData[1] }
                    selectionKeys           = { this.state.mSelectedData }
                    onSelectionChange       = { this.cfOnSelectionChange }
                    onSelect                = { this.cfOnSelect }
                    header                  = {lHeader}
                    footer                  = {this.state.mFooter}
                    footerColumnGroup       = { lTableData[3] }
                    headerColumnGroup       = { lTableData[4] }
                    globalFilter            = {this.state.mGlobalFilter} 
                    value                   = { lGridData }
                    rowGroupMode            = { (this.state.mGroupByField) ? 'subheader' : null }>

                    { lTableData[0] }

                </TreeTable>
            }
        }

        return lTable;
    }
}

export default CSCOTreeGrid;

