import React, { useState, useRef, useEffect } from 'react';
import QRCode from "qrcode.react"
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import CSUtil from "../util/CSUtil";
import CSConfigUtil from "../util/CSConfigUtil";
import { fnConvertNumberToWords, fnNumberIndiaFormat } from '../util/CSMathUtil';
import { fnGetDateToString, fnGetStringToDate } from '../util/CSDateTimeUtil';

var CryptoJS = require("crypto-js");

const CSReceiptForm2 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const mParentCopyHeading = 'PARENT COPY';
//    const mInstituteCopyHeading = 'OFFICE COPY';
    
    const [ mInstituteHeading, setInstituteHeading ] = useState('')
    const [ mStudentDetails, setStudentDetails ] = useState(
        {
            mStudentName          : '',
            mParentName           : '',
            mClass                : '',
            mSection              : '',
            mSectionLabel         : '',
            mRegistrationNo       : '',
            mRegistrationNoLabel  : ''
        }, []
    );

    const [ mFormTitle, setFormTitle ] = useState(CSConfigUtil.mReceiptHeading[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle1, setFormSubTitle1 ] = useState(CSConfigUtil.mReceiptSubHeading1[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle2, setFormSubTitle2 ] = useState(CSConfigUtil.mReceiptSubHeading2[CSConfigUtil.mBranchCode]);

    const [ mReceiptDetails, setReceiptDetails ] = useState(
        {
            mTransactionId     : '', 
            mReceiptNo         : '', 
            mReceiptDate       : '', 
            mBranchName        : '', 
            mAdmissionNo       : '',
            mInquiryNo         : '',
            mQrCode            : '',
            mEncryptedQrCode   : '',
            mTimestamp         : '',
            mPaymentInWords    : '',
            mIdName            : ''      
        }, [lProps]
    );

    const [mChequeNoTitle, setChequeNoTitle] = useState(null);
    const [mChequeDateTitle, setChequeDateTitle] = useState(null);
    const [mDraweeBankTitle, setDraweeBankTitle] = useState(null);

    const [ mPaymentDetails, setPaymentDetails ] = useState([]);
    const [ mPaymentSummary, setPaymentSummary ] = useState([]);
    const [ mInstallmentSummary, setInstallmentSummary ] = useState([]);
    const [ mPaymentInfo, setPaymentInfo ] = useState(
        {
            mPaymentMode : '',
            mChequeNo    : '',
            mChequeDate  : '',
            mBank        : '',
            mAccountant  : '   '
        }
    );

    const [ mFooter, setFooter ] = useState("");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pFormProperties)
                {
                    var lReceiptDetails = null;
                    var lReceiptDetailsName = null;
                    var lDefaultDataField = null;
                    var lPaymentDetailsName = null;
                    var lFeeNameDataField = 'fee_name_'
                    var lInstallmentCodeDataField = 'installment_code_'
                    var lTotalPmtField = 'total_payment_amount_';
                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        if(lPropObj.type_ === 'initialize')
                        {
                            const lValue = lPropObj.value_;
                            if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_1_')
                            {
                                setFormSubTitle1(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_2_')
                            {
                                setFormSubTitle2(lValue);
                            }
                            else if(lPropObj.property_ === 'receipt_details_')
                            {
                                lReceiptDetailsName = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'payment_details_')
                            {
                                lPaymentDetailsName = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'default_data_field_')
                            {
                                lDefaultDataField = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lValue);
                            }
                            else if(lPropObj.property_ === 'total_payment_field_')
                            {
                                lTotalPmtField = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'fee_name_data_field_')
                            {
                                lFeeNameDataField = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'installment_code_data_field_')
                            {
                                lInstallmentCodeDataField = lPropObj.value_;
                            }
                        }
                    }
                        
                    var lParticulars = '';
                    if(lPaymentDetailsName)
                    {
                        var lPmtCollection = [];
                        var lInstallmentCollection = [];
                        if(lProps.hasOwnProperty(lPaymentDetailsName))
                        {
                            var lPmtSummaryObj = lProps[lPaymentDetailsName];
                            var lFeeTypeMap = {}
                            var lInstallmentBreakupMap = {}
                            for(var lpInd=0; lpInd<lPmtSummaryObj.length; lpInd++)
                            {
                                var lData = lPmtSummaryObj[lpInd];
                                if(Number(lData.payment_amount_) > 0)
                                {
                                    if(lParticulars !== '')
                                    {
                                        lParticulars += '; ';
                                    }

                                    if(lData.fee_type_ === 'ADM_FEE')
                                    {
                                        lParticulars = lParticulars + lData[lFeeNameDataField] + " Fee"
                                    }
                                    else
                                    {
                                        lParticulars = lParticulars + lData[lFeeNameDataField] + " Fee - " + lData.installment_name_;
                                    }

                                    var lInstObj = lInstallmentBreakupMap[lData[lInstallmentCodeDataField]];
                                    if(!lInstObj)
                                    {
                                        lInstObj = {};

                                        var lFeeType = (lData.fee_type_ === 'OLD_DUES') ? lData.fee_type_name_ : lData.fee_type_name_  + " Fee";

                                        lInstObj.fee_type_name_     = lFeeType;
                                        lInstObj.installment_name_  = lData.installment_name_;
                                        lInstObj.payment_amount_    = lData.payment_amount_;

                                        lInstallmentBreakupMap[lData[lInstallmentCodeDataField]] = lInstObj;
                                    }
                                }

                                var lFeeTypeObj = lFeeTypeMap[lData[lFeeNameDataField]];
                                if(!lFeeTypeObj)
                                {
                                    lFeeTypeObj = {};

                                    lFeeTypeObj.fee_type_name_     = lData[lFeeNameDataField];
                                    lFeeTypeObj.fee_amount_        = Number(lData.fee_amount_);
                                    lFeeTypeObj.concession_amount_ = Number(lData.concession_amount_);
                                    lFeeTypeObj.payment_amount_    = Number(lData.paid_installment_amount_);
                                    lFeeTypeObj.payment_amount_    += Number(lData.payment_amount_);
                                    lFeeTypeObj.due_amount_        = Number(lData.fee_amount_) - Number(lData.concession_amount_) - Number(lFeeTypeObj.payment_amount_);

                                    lFeeTypeMap[lData[lFeeNameDataField]] = lFeeTypeObj;
                                }
                                else
                                {
                                    lFeeTypeObj.payment_amount_    = Number(lFeeTypeObj.payment_amount_) + Number(lData.payment_amount_);
                                    lFeeTypeObj.due_amount_        = Number(lFeeTypeObj.fee_amount_) - Number(lFeeTypeObj.concession_amount_) - Number(lFeeTypeObj.payment_amount_);
                                }
                            }

                            var lInstKeys = Object.keys(lInstallmentBreakupMap);
                            for(var lIKeyInd=0; lIKeyInd<lInstKeys.length; lIKeyInd++)
                            {
                                var lInsObj = lInstallmentBreakupMap[lInstKeys[lIKeyInd]];
                                var lInsPmtData = [];
                                lInsPmtData.push( <td key={lIKeyInd + 21}> {lInsObj[lData[lFeeNameDataField]]} </td> );
                                lInsPmtData.push( <td key={lIKeyInd + 22}> {lInsObj.installment_name_}         </td> );
                                lInsPmtData.push( <td key={lIKeyInd + 23}> {lInsObj.payment_amount_}           </td> );
                                
                                lInstallmentCollection.push(<tr key={lIKeyInd + 21}>{lInsPmtData}</tr>);
                            }

                            setInstallmentSummary(lInstallmentCollection);

                            var lKeys = Object.keys(lFeeTypeMap);
                            for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                            {
                                var lObj = lFeeTypeMap[lKeys[lKeyInd]];
                                var lPmtData = [];
                                lPmtData.push( <td key={lKeyInd + 1}> {lObj.fee_type_name_}     </td> );
                                lPmtData.push( <td key={lKeyInd + 2}> {lObj.fee_amount_}        </td> );
                                lPmtData.push( <td key={lKeyInd + 3}> {lObj.concession_amount_} </td> );
                                lPmtData.push( <td key={lKeyInd + 4}> {lObj.payment_amount_}    </td> );
                                lPmtData.push( <td key={lKeyInd + 5}> {lObj.due_amount_}        </td> );
                                
                                lPmtCollection.push(<tr key={lKeyInd + 1}>{lPmtData}</tr>);
                            }

                            setPaymentSummary(lPmtCollection);
                        }
                    }
                    
                    if(lReceiptDetailsName)
                    {
                        if(lProps.hasOwnProperty(lReceiptDetailsName))
                        {
                            var lRcptData = lProps[lReceiptDetailsName];
                            if(lDefaultDataField && lRcptData.hasOwnProperty(lDefaultDataField))
                            {
                                lReceiptDetails = lRcptData[lDefaultDataField];
                            }
                            else
                            {
                                lReceiptDetails = lRcptData;
                            }

                            if(lReceiptDetails.hasOwnProperty('fee_type_') && lReceiptDetails.fee_type_)
                            {
                                if(lReceiptDetails.fee_type_ === 'REG_FEE')
                                {
                                    lParticulars = 'Registration Fee';
                                }
                                else if(lReceiptDetails.fee_type_ === 'TUI_FEE')
                                {
                                    lParticulars = 'Tuition Fee';
                                }
                                else if(lReceiptDetails.fee_type_ === 'TRN_FEE')
                                {
                                    lParticulars = 'Transport Fee';
                                }
                                else if(lReceiptDetails.fee_type_ === 'OTH_FEE')
                                {
                                    lParticulars = 'Other Fee';
                                }
                                else if(lReceiptDetails.fee_type_ === 'OLD_DUES')
                                {
                                    lParticulars = 'Old Dues';
                                }
                            }
                            else if(lReceiptDetails.hasOwnProperty('fee_type_name_') && lReceiptDetails.fee_type_name_)
                            {
                                lParticulars = (lReceiptDetails.fee_type_name_) ? lReceiptDetails.fee_type_name_ : lReceiptDetails.fee_type_;
                            }
/*
                            var lRctData = [];
                            lRctData.push(<td key={1}>{ lParticulars }</td>);
                            lRctData.push(<td key={2}>{ fnNumberIndiaFormat(lReceiptDetails[lTotalPmtField]) }</td>);
                            
                            var lPmtDetails = [];
                            lPmtDetails.push(<tr key={1}>{lRctData}</tr>);
                            setPaymentDetails(lPmtDetails);
*/
                            var lRctData1 = [];
                            lRctData1.push(<td key={1}>Total Payment</td>);
                            lRctData1.push(<td key={2}>Rupees { fnNumberIndiaFormat(lReceiptDetails[lTotalPmtField]) }</td>);
                            
                            var lPmtDetails = [];
                            lPmtDetails.push(<tr key={1}>{lRctData1}</tr>);
                            setPaymentDetails(lPmtDetails);
                        }
                    }
                }

                if(lReceiptDetails)
                {
                    var lRecData = {}
                    var lStdData = {}
                    var lPmtInfo = {}
                    for(var lInd1=0; lInd1<lProps.pFormProperties.length;lInd1++)
                    {
                        var lPropObj0 = lProps.pFormProperties[lInd1];
                        if(lPropObj0.type_ === 'initialize')
                        {
                            const lValue = lPropObj0.value_;
                            if(lPropObj0.property_ === 'receipt_no_')
                            {
                                lRecData.mReceiptNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'institute_heading_')
                            {
                                setInstituteHeading(lValue);
                            }
                            else if(lPropObj0.property_ === 'admission_no_')
                            {
                                lRecData.mAdmissionNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'inquiry_no_')
                            {
                                lRecData.mInquiryNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'id_name_')
                            {
                                lRecData.mIdName = lValue;
                            }
                            else if(lPropObj0.property_ === 'branch_name_')
                            {
                                lRecData.mBranchName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'date_')
                            {
                                var lDate1 = fnGetStringToDate(lReceiptDetails[lValue]);
                                if(lDate1)
                                {
                                    var lDate1Str = fnGetDateToString(lDate1, 'display' )
                                    lRecData.mReceiptDate = lDate1Str;
                                }
                            }
                            else if(lPropObj0.property_ === 'time_')
                            {
                                lRecData.mReceiptTime = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'total_payment_')
                            {
                                if(lReceiptDetails[lValue])
                                {
                                    lRecData.mPaymentInWords = fnConvertNumberToWords(lReceiptDetails[lValue]);
                                }

                                lRecData.mTotalPayment = fnNumberIndiaFormat(lReceiptDetails[lValue]);
                            }
                            else if(lPropObj0.property_ === 'student_name_')
                            {
                                lStdData.mStudentName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'parent_name_')
                            {
                                lStdData.mParentName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'class_')
                            {
                                lStdData.mClass = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'section_')
                            {
                                lStdData.mSection = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'section_label_')
                            {
                                lStdData.mSectionLabel = lValue;
                            }
                            else if(lPropObj0.property_ === 'registration_no_')
                            {
                                lStdData.mRegistrationNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'registration_no_label_')
                            {
                                lStdData.mRegistrationNoLabel = lValue;
                            }
                            else if(lPropObj0.property_ === 'payment_mode_')
                            {
                                lPmtInfo.mPaymentMode = lReceiptDetails[lValue];
                                var lChequeNoTitle = '';
                                var lChequeDateTitle  = '';
                                var lDraweeBankTitle  = '';

                                if(lPmtInfo.mPaymentMode === 'CASH')
                                {
                                    lChequeNoTitle = '';
                                    lChequeDateTitle  = '';
                                    lDraweeBankTitle  = '';
                                }
                                else if(lPmtInfo.mPaymentMode === 'POS')
                                {
                                    lChequeNoTitle = 'Batch No/Appr Code';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.batch_no_ + "/" + lReceiptDetails.approval_code_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'BANK_TXFR')
                                {
                                    lChequeNoTitle = 'UTR No';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.utr_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'UPI')
                                {
                                    lChequeNoTitle = 'UPI No';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.upi_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'BBPS')
                                {
                                    lChequeNoTitle = 'UPI No';
                                    lChequeDateTitle  = '';
                                    lDraweeBankTitle  = '';

                                    lPmtInfo.mChequeNo = lReceiptDetails.upi_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'CHEQUE')
                                {
                                    lChequeNoTitle = 'Cheque No';
                                    lChequeDateTitle  = 'Cheque Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.cheque_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }

                                setChequeDateTitle(lChequeDateTitle);
                                setChequeNoTitle(lChequeNoTitle);
                                setDraweeBankTitle(lDraweeBankTitle);
                            }
                        }
                    }

                    if(lRecData.mReceiptDate && lRecData.mReceiptTime)
                    {
                        lRecData.mTimestamp = lRecData.mReceiptDate + lRecData.mReceiptTime; 
                        lRecData.mQrCode  =    "Receipt Date : "    + lRecData.mReceiptDate;
                        lRecData.mQrCode += "\n Receipt Time : "    + lRecData.mReceiptTime;
                        lRecData.mQrCode += "\n Student Name : "    + lStdData.mStudentName;
                        lRecData.mQrCode += "\n Parent Name : "     + lStdData.mParentName;
                        lRecData.mQrCode += "\n Class : "           + lStdData.mClass;
                        lRecData.mQrCode += "\n Section : "         + lStdData.mSection;
                        lRecData.mQrCode += "\n Admission No : "    + lRecData.mAdmissionNo;
                        lRecData.mQrCode += "\n Inquiry No : "      + lRecData.mInquiryNo;
                        lRecData.mQrCode += "\n Registration No : " + lRecData.mRegistrationNo;
                        lRecData.mQrCode += "\n Payment : "         + lRecData.mTotalPayment;

                        var lTmpdata = CSUtil.cfGetObjectCopy(lRecData.mQrCode);
                        lTmpdata = "Transaction Id : " + lRecData.id_ + "\n " + lTmpdata;
                        lRecData.mEncryptedQrCode = CryptoJS.AES.encrypt(JSON.stringify(lTmpdata), 'cs_app').toString();
                    }

                    setReceiptDetails(lRecData);
                    setStudentDetails(lStdData);
                    setPaymentInfo(lPmtInfo);
                }
            }

            fnInitializeForm();
        }, [lProps, setStudentDetails, setReceiptDetails, setPaymentInfo]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => lProps.fnProcessClose(false, true) }
        />
    );

    var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    var lSection = (mStudentDetails && mStudentDetails.mSectionLabel) 
        ?
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ mStudentDetails.mSectionLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{mStudentDetails.mSection}</div>
            </div>
        : null;

    var lRegistrationNo = (mStudentDetails && mStudentDetails.mRegistrationNoLabel) 
        ?
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ mStudentDetails.mRegistrationNoLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{mStudentDetails.mRegistrationNo}</div>
            </div>
        : null;

    const lForm = 
        <div id="rf" ref={mComponentRef}>
            <div className="rf overflow-auto">
                <div style={{minWidth: '750px'}}>
                    <main>
                        <div className="title">
                            <h1>{ mFormTitle }</h1>

                            <div className="top">
                                <div className="ins-name">
                                    { mInstituteHeading }
                                </div>

                                <div className="heading-copy">
                                    { mParentCopyHeading }
                                </div>
                            </div>

                            <h2>{ mFormSubTitle1 }</h2>

                            <h2>{ mFormSubTitle2 }</h2>
                        </div>

                        <div className="summary">
                            <div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Student Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mStudentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Parent Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mParentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Class</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mClass}</div>
                                </div>

                                { lSection }

                                { lRegistrationNo }
                            </div>
                    
                            <div className="receipt-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Receipt No</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mReceiptNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Branch</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mBranchName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>{ mReceiptDetails.mIdName }</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{ (mReceiptDetails.mIdName === 'Admission No') ? (mReceiptDetails.mAdmissionNo) : (mReceiptDetails.mInquiryNo) }</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mReceiptDate}</div>
                                </div>
                            </div>

                            <div className="qrcode">
                                <QRCode
                                    style   = {{ width: '72px', height: '72px' }}
                                    level   = "L"
                                    value   = {(mReceiptDetails.mEncryptedQrCode) ? mReceiptDetails.mEncryptedQrCode : ''}
                                />
                               
{/*                               <div className='timestamp'>{mReceiptDetails.mTimestamp}</div> */}
                            </div>
                        </div>
                         
                        <div className="payment-details">
                            <h2>Payment Summary</h2>
                            <table className="items">
{/*                                
                                <thead>
                                    <tr>
                                        <th>Fee Type</th>
                                        <th>Fee</th>
                                        <th>Concession</th>
                                        <th>Payment</th>
                                        <th>Due</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mPaymentSummary}
                                </tbody>
*/}
                                <thead>
                                    <tr>
                                        <th>Fee Type</th>
                                        <th>Installment</th>
                                        <th>Payment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mInstallmentSummary}
                                </tbody>
                            </table>
                        </div>

                        <div className="payment-details">
                        </div>

{/*                        
                        <div className="payment-details">
                            <h2>Payment Details</h2>
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>Particulars</th>
                                        <th>Payment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mPaymentDetails}
                                </tbody>
                            </table>
                        </div>
*/}
                        <div className="pmt-amt"> 
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment Amount</div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>{mReceiptDetails.mTotalPayment}</div>
                            </div>
                                
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment in Words </div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>Rupees {mReceiptDetails.mPaymentInWords}</div>
                            </div>
                        </div>
                        
                        <div className="payment-info">
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Payment Mode" }
                                </div>

                                { mPaymentInfo.mPaymentMode }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeNoTitle }
                                </div>

                                { mPaymentInfo.mChequeNo }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeDateTitle }
                                </div>

                                { mPaymentInfo.mChequeDate }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mDraweeBankTitle }
                                </div>

                                { mPaymentInfo.mBank }
                            </div>
                            <div className="pi-item-accountant">
                                { mPaymentInfo.mAccountant }

                                <div className="pi-item-heading">
                                    { "Principal/Accountant" }
                                </div>
                            </div>
                        </div>
                        <div className="cheque-realization"> 
                            { mFooter }
                        </div>
                    </main>
{/*                         
                    <div className="page-break"/>

                    <main>
                        <div className="title">
                            <h1>{ mFormTitle }</h1>

                            <div className="top">
                                <div className="ins-name">
                                    { mInstituteHeading }
                                </div>

                                <div className="heading-copy">
                                    { mInstituteCopyHeading }
                                </div>
                            </div>

                            <h2>{ mFormSubTitle1 }</h2>

                            <h2>{ mFormSubTitle2 }</h2>
                        </div>

                        <div className="summary">
                            <div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Student Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mStudentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Parent Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mParentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Class</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mClass}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Section</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mSection}</div>
                                </div>                             
                            </div>
                    
                            <div className="receipt-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Receipt No</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mReceiptNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Branch</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mBranchName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Admission No</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mAdmissionNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mReceiptDate}</div>
                                </div>
                            </div>
                    
                            <div className="qrcode">
                                <QRCode
                                    style   = {{ width: '72px', height: '72px' }}
                                    bgColor = "#FFFFFF"
                                    level   = "L"
                                    value   = {(mReceiptDetails.mEncryptedQrCode) ? mReceiptDetails.mEncryptedQrCode : ''}
                                />
                               
                               <div className='timestamp'>{mReceiptDetails.mTimestamp}</div>
                            </div>
                        </div>

                        <div className="payment-details">
                            <h2>Payment Summary</h2>
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>Fee Type</th>
                                        <th>Fee</th>
                                        <th>Concession</th>
                                        <th>Payment</th>
                                        <th>Due</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mPaymentSummary}
                                </tbody>
                            </table>
                        </div>
                                               
                        <div className="payment-details">
                            <h2>Payment Details</h2>
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>Particulars</th>
                                        <th>Payment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mPaymentDetails}
                                </tbody>
                            </table>
                        </div>

                        <div className="pmt-amt"> 
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment Amount</div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>{mReceiptDetails.mTotalPayment}</div>
                            </div>
                                
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment in Words (In Rupees) </div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>{mReceiptDetails.mPaymentInWords}</div>
                            </div>
                        </div>

                        <div className="payment-info">
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Payment Mode" }
                                </div>

                                { mPaymentInfo.mPaymentMode }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Cheque No" }
                                </div>

                                { mPaymentInfo.mChequeNo }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Cheque Date" }
                                </div>

                                { mPaymentInfo.mChequeDate }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Drawee Bank" }
                                </div>

                                { mPaymentInfo.mBank }
                            </div>
                            <div className="pi-item-accountant">
                                { mPaymentInfo.mAccountant }

                                <div className="pi-item-heading">
                                    { "Principal/Accountant" }
                                </div>
                            </div>
                        </div>
                        <div className="cheque-realization"> 
                            { mFooter }
                        </div>
                    </main>
 */} 
                </div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            appendTo  = { document.body }
            header    = {lHeader}
            onHide    = { () => lProps.fnProcessClose(false, true) }>
                { lForm }
        </Dialog>
    );
}

export default CSReceiptForm2;