import React, { Component } from "react"
import {read, utils} from 'xlsx';

import CSUtil from "../util/CSUtil";
import CSFormUtil from "../util/CSFormUtil"
import CSServiceUtil from "../util/CSServiceUtil";
import { fnGetForm } from "../util/CSLayoutUtil"
import { cfGetConstantValue} from "../util/CSConstantUtil";

import CSCOGrid from "../components/CSCOGrid";
import CSCODetailsHeader1 from "../components/CSCODetailsHeader1";
import CSCOHorizontalActionBar from "../components/CSCOHorizontalActionBar"

export default class CSImportTemplate extends Component
{
    mMaxSections        = 4;
    mMinSectionItems 	= 6;

    mFormUtil = CSFormUtil.cfGetInstance();
    mCSServiceUtil = CSServiceUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mDisplay        	: this.props.pShow,
            mAppId          	: this.props.pAppId,
            mFormHeading    	: (this.props.pFormHeading) ? this.props.pFormHeading : "",
            mFormCode       	: this.props.pFormCode,
            mFormData       	: null,
            mDataCollection     : [],
            mFormOrigData   	: null,
            mFormActions    	: this.props.pFormActions,
            mFormProperties 	: this.props.pFormProperties,
            mFormItemProperties	: this.props.pFormItemProperties,
            mFormItems         	: this.props.pFormItems,
            mSelectedActionItem : this.props.pSelectedActionItem,
            mSelectedData       : this.props.pSelectedData,
            mSelectedRecord     : null,
            mDetailsMetaData    : null,
            mWidth             	: this.props.pWidth,
            mHeight            	: this.props.pHeight,
            mHeaderFlag         : false,
            mSectionFlag        : false,
            mPopoutFlag         : false,
            mImportType         : null,
            mAddFlag            : false,
            mDetailsFlag        : false,

            fnProcessChange     : this.props.fnProcessChange,
            fnProcessSubmit     : this.props.fnProcessSubmit,
            fnProcessClose      : this.props.fnProcessClose
        }

        this.process_add        = this.process_add.bind(this);
        this.process_modify     = this.process_modify.bind(this);
        this.process_save       = this.process_save.bind(this);
        this.process_close      = this.process_close.bind(this);
        this.process_cancel     = this.process_cancel.bind(this);

        this.cfGetFormHeading   = this.cfGetFormHeading.bind(this);
        this.cfGetFormItems     = this.cfGetFormItems.bind(this);
        this.cfSetGridColumns   = this.cfSetGridColumns.bind(this);
        this.cfProcessChange    = this.cfProcessChange.bind(this);
        this.cfCloseForm        = this.cfCloseForm.bind(this);

        this.cfBrowseFile       = this.cfBrowseFile.bind(this);
        this.cfAddToCollection  = this.cfAddToCollection.bind(this);
        this.cfUpdateCollection = this.cfUpdateCollection.bind(this);

        this.cfClosePopout      = this.cfClosePopout.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);
        this.cfEnableSave       = this.cfEnableSave.bind(this);
        this.cfResetForm        = this.cfResetForm.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            this.state.mFormData['selected_data_'] = this.props.pSelectedData;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'layout')
                {
                    if (lFormProperty.property_ === 'max_sections_')
                    {
                        this.mMaxSections = lFormProperty.value_;
                    }

                    if(lFormProperty.property_ === 'min_section_items_')
                    {
                        this.mMinSectionItems = lFormProperty.value_;
                    }
                }
                else if((lFormProperty.type_ === 'initialize') && ((lFormProperty.property_ === 'data_') || (lFormProperty.property_ === 'collection_data_')))
                {
                    if(this.props.pFormData)
                    {
                        var lDataObj = null;
                        var lData = null;
                        if(lFormProperty.property_ === 'data_')
                        {
                            if(!this.state.mFormData)
                            {
                                this.state.mFormData = {};
                            }
        
                            if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.props.pFormData[lFormProperty.value_];
                                if(lData)
                                {
                                    if(lData instanceof Array && (lData.length > 0))
                                    {
                                        lDataObj = lData[0];
                                    }
                                    else
                                    {
                                        lDataObj = lData;
                                    }

                                    if(lDataObj)
                                    {
                                        this.state.mFormData[lFormProperty.value_] = lDataObj;
                                    }
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = {};
                            }
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            if(this.props.pFormData.hasOwnProperty(lFormProperty.value_))
                            {
                                lData = this.props.pFormData[lFormProperty.value_];
                                if(lData instanceof Array)
                                {
                                    this.state.mFormData[lFormProperty.value_] = lData;
                                }
                                else
                                {
                                    this.state.mFormData[lFormProperty.value_] = [];
                                    this.state.mFormData[lFormProperty.value_].push(lData)    
                                }
                            }
                            else
                            {
                                this.state.mFormData[lFormProperty.value_] = [];
                            }
                        }
                    }
                    else
                    {
                        if(lFormProperty.property_ === 'data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = {};
                        }
                        else if(lFormProperty.property_ === 'collection_data_')
                        {
                            this.state.mFormData[lFormProperty.value_] = [];
                        }
                    }
                }
                else if((lFormProperty.type_ === 'initialize') && (this.state.mFormCode === lFormProperty.cs_form_code_))
                {
                    if (lFormProperty.property_ === 'default_data_field_')
                    {
                        this.state.mDefaultDataField = lFormProperty.value_;
                    }
                }
            }
        }
        else
        {
            this.state.mFormData = this.props.pFormData;
        }
    }

    componentDidMount()
    {
        this.cfSetGridColumns();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
        }
    }

    cfGetFormItems(lActionId)
    {
        var lFormItems = [];
        if(lActionId)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.details_action_ref_id_ === lActionId)
                {
                    lFormItems.push(lFormItem);
                }
            }
        }
        
        return lFormItems;
    }

    process_add(event, lActionItem)
    {
        if(event && event.currentTarget && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pResponseFn             : this.cfAddToCollection
            };

            this.setState(
                {
                    mAddFlag             : true,
                    mDetailsMetaData     : lDetailsMetaData,
                    mDetailsFlag         : true
                }
            );
        }
    }
    
    cfAddToCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            var lCollection = this.state.mDataCollection;
            if(!lCollection)
            {
                lCollection = [];
            }

            lCollection.push(lRecord);

            this.setState(
                {
                    mDataCollection : lCollection
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }

    process_modify(event, lActionItem)
    {
        if(event && event.currentTarget && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }
            
            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pResponseFn             : this.cfUpdateCollection
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = this.state.mSelectedRecord;
                this.setState(
                    {
                        mAddFlag             : false,
                        mDetailsMetaData     : lDetailsMetaData,
                        mDetailsFlag         : true,
                        mSelectedData        : lDataObj
                    }
                );
            }
        }
    }
    
    cfUpdateCollection(lRecord, lCloseFlag)
    {
        if(lRecord)
        {
            this.setState(
                {
                    mSelectedRecord : lRecord
                }
            );
        }

        if(lCloseFlag)
        {
            this.cfCloseForm();
        }
    }

    cfSetGridColumns()
    {
        var lColumnList = [];
        var lColumn0 = {};
        lColumn0.column_name_   = 'Required ?';
        lColumn0.data_field_    = 'required_';
        lColumn0.visible_       = '1';
        lColumn0.width_         = "120px";
        lColumnList.push(lColumn0);

        var lColumn1 = {};
        lColumn1.column_name_   = 'Grid Column';
        lColumn1.data_field_    = 'grid_column_';
        lColumn1.visible_       = '1';
        lColumn1.width_         = "200px";
        lColumnList.push(lColumn1);

        var lColumn2 = {};
        lColumn2.column_name_   = 'Excel Column';
        lColumn2.data_field_    = 'excel_column_';
        lColumn2.visible_       = '1';
        lColumn2.width_         = "200px";
        lColumn2.editable_      = "1";
        lColumn2.item_editor_   = 'COMBO_BOX_EDITOR_1';
        lColumn2.data_provider_ = this.state.mExcelColumns;
        lColumnList.push(lColumn2);

        return lColumnList;
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormActions.length; lInd++)
            {
                var lFormAction = this.state.mFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(lCloseFlag, lRefreshFlag)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(lCloseFlag === false, lRefreshFlag);
        }
    }

    cfClosePopout(lCloseFlag)
    {
        this.setState({mPopoutFlag : lCloseFlag});
    }

    cfBrowseFile(event)
    {
        const lFiles = event.target.files;
        if(lFiles && (lFiles.length > 0))
        {
            const lSelectedFile = lFiles[0];
            const lReader = new FileReader();
            if(lSelectedFile)
            {
                lReader.onload = function(evt)
                {
                    var lData = evt.target.result;
                    if(lData)
                    {
                        var lWorkbook = read(lData, {type: 'binary'});
                        const lWorksheetName = lWorkbook.SheetNames[0];
                        const lWorksheet = lWorkbook.Sheets[lWorksheetName];
                        const lSheetData = utils.sheet_to_json(lWorksheet, {header:1});

                        if(lSheetData && (lSheetData.length > 0))
                        {
                            var lExcelData = [];
                            var lHeader = [];
                            var lHeaderRow = null;
                            for(var lSInd=0; lSInd<lSheetData.length; lSInd++)
                            {
                                var lRow = lSheetData[lSInd];
                                var lObj    = {}
                                var lIsBlankRow = true;
                                for(var lPInd=0; lPInd<lRow.length; lPInd++)
                                {
                                    if(lSInd === 0)
                                    {
                                        if(lRow)
                                        {
                                            lHeaderRow = lRow;
                                            var lHeaderObj = {};
                                            lHeaderObj.key    = lPInd;
                                            lHeaderObj.label  = lRow[lPInd];
                                            lHeaderObj.value  = lRow[lPInd];
                                            lHeader.push(lHeaderObj);
                                        }
                                        else
                                        {
                                            break;
                                        }
                                    }
                                    else
                                    {
                                        if(lHeaderRow && lHeaderRow[lPInd])
                                        {
                                            lObj[ lHeaderRow[lPInd] ] = lRow[lPInd];
                                            lObj.key    = lPInd;
                                            if(lRow[lPInd])
                                            {
                                                lIsBlankRow = false;
                                            }
                                        }
                                    }
                                }

                                if(lSInd > 0)
                                {
                                    if(!lIsBlankRow)
                                    {
                                        lExcelData.push(lObj);
                                    }
                                }
                            }

                            this.setState(
                                {
                                    mExcelColumns       : lHeader, 
                                    mHeaderRow          : lHeaderRow, 
                                    mOrigExcelColumns   : lHeader, 
                                    mExcelData          : lExcelData
                                }
                            );
                        }
                    }
                }.bind(this);

                lReader.readAsBinaryString(lSelectedFile);
            }
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            if(!lFormData)
			{
                lFormData = {};
			}

            var lDataObject = null;
            if(lData.hasOwnProperty('data_object_'))
            {
                if(!lFormData.hasOwnProperty(lData.data_object_))
                {
                    lDataObject = {};
                    lFormData[lData.data_object_] = lDataObject;
                }

                lDataObject = lFormData[lData.data_object_];
            }

            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                if(lFormItem.control_type_ === 'constant')
                {
                    if(lDataObject)
                    {
                        lDataObject[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                    else
                    {
                        lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                }
            }

            if(lData.hasOwnProperty('data_field_') && lData.data_field_)
            {
                if(lDataObject)
                {
                    lDataObject[lData.data_field_] = lData.value_;
                }
                else
                {
                    lFormData[lData.data_field_] = lData.value_;
                }
            }

            this.setState ({mFormData : lFormData});
        }
    }

    cfResetForm()
    {
    }
    
    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mFormData;
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm()
    {
        this.setState({ mDetailsFlag : false });
    }

    cfEnableSave(lValue, lHeaderFlag)
    {
        if(lHeaderFlag === 1)
        {
            this.setState({mHeaderFlag : lValue});
        }
        else
        {
            this.setState({mSectionFlag : lValue});
        }
    }

    process_save(lEvent, lParent, lActionItem)
    {
        this.setState({mSelectedActionItem : lActionItem});
        if(lActionItem)
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult)
                    {
                        if(lResult.value)
                        {
                            var lRequestData = null;
                            if(this.state.mFormItems)
                            {
                                for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
                                {
                                    var lFormItem = this.state.mFormItems[lInd];
                                    if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                                    {
                                        var lRequestObj = this.mFormUtil.fnGetRequestObject(lFormItem, this.state.mDataCollection, this.state.mFormData, lRequestData);
                                        if(lRequestObj)
                                        {
                                            if(!lRequestData)
                                            {
                                                lRequestData = {}
                                            }
                    
                                            lRequestData[lFormItem.data_field_] = lRequestObj;
                                        }
                                    }
                                }
                            }
                    
                            if(!lRequestData)
                            {
                                lRequestData = this.state.mFormData;
                            }
                    
                            this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                                (lResponse) =>
                                {
                                    var lCode = null;
                                    var lMsg = null;
                                    var lCloseFlag = (lActionItem.new_state_ === "list")
    
                                    if(lResponse && lResponse.code)
                                    {
                                        if(lResponse.code instanceof Array)
                                        {
                                            lCode = lResponse.code[0];
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message[0];
                                            }
                                        }
                                        else
                                        {
                                            lCode = lResponse.code;
                                            if(lResponse.message)
                                            {
                                                lMsg = lResponse.message;
                                            }
                                        }
                                    }
                                    else
                                    {
                                        lCode = -1;
                                    }
    
                                    this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                    this.process_close(lCloseFlag, true);
                                }
                            );
                        }
                    }
                }
            );
        }
    }

    render() 
    {
        this.cfSetControlValue();
     
        var lFormDetailsItemActionsList = null;
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if((lFormItem.visible_ === '1') && !lFormItem.details_action_ref_id_)
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lSection = null;
        if(lFormItemList.length > 0)
        {
            var lSaveActionItem = null;
            if(this.state.mFormActions && (this.state.mFormActions.length > 0) && this.state.mFormData)
            {
                var lFormItemsSearchActions = null;
                for(var lAInd=0; lAInd<this.state.mFormActions.length; lAInd++)
                {
                    var lActionItem = this.state.mFormActions[lAInd];
                    if(lActionItem.component_type_ === 'DETAILS_ACTION')
                    {
                        if((lActionItem.parent_ref_id_ === '') || (lActionItem.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                        {
                            if(lActionItem.action_type_ === '1')
                            {
                                if (lActionItem.action_ === 'process_save')
                                {
                                    lSaveActionItem = lActionItem;
                                }

                                lActionItem.mEnabled = this.state.mHeaderFlag && this.state.mSectionFlag;
                            }
                            else
                            {
                                lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                            }

                            if(!lFormDetailsItemActionsList)
                            {
                                lFormDetailsItemActionsList = [];
                            }
    
                            lFormDetailsItemActionsList.push(lActionItem);
                        }
                    }
                    else if(lActionItem.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                    {
                        if(!lActionItem.parent_ref_id_ || (lActionItem.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                        {
                            if(!lFormItemsSearchActions)
                            {
                                lFormItemsSearchActions = [];
                            }

                            lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                            lFormItemsSearchActions.push(lActionItem);
                        }
                    }
                }
            }

            var lChooseFileInput = this.state.mFileName;
            if(!lChooseFileInput)
            {
                lChooseFileInput = 
                    <div style={{height : "40px",  marginTop : '5px', width : "100%", backgroundColor : '#F8F8F8', paddingTop : '5px'}}>
                        <div className="p-toolbar-group-left">
                            <input 
                                id      = "ID_FILE_INPUT" 
                                type    = "file"
                                accept  = ".csv, .xls, .xlsx"
                                onChange= {this.cfBrowseFile}  
                                style   =
                                {
                                    {
                                        padding     : '5px', 
                                        fontSize    : '14px',
                                        fontFamily  : "Open Sans"
                                    }
                                }/>
                        </div>

                        <div className="p-toolbar-group-right">
                            {
                            (lFormItemsSearchActions) 
                                ? lFormItemsSearchActions.map((lFiAi) => CSFormUtil.cfGetButton(lFiAi, 'SEARCH_ACTION_BAR', 
                                        (event) => 
                                            {
                                                if(this.hasOwnProperty(lFiAi.action_))
                                                {
                                                    this[lFiAi.action_](event, lFiAi);
                                                }
                                            }
                                        )
                                    )
                                : null
                            }
                        </div>
                    </div>
            }

            var lDetailsForm = null;
            if(this.state.mDetailsFlag)
            {
                var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
                if(this.state.mDetailsMetaData && this.state.mDetailsMetaData.hasOwnProperty('pFormType'))
                {
                    lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.cfCloseForm);
                }
            }

            var lGrid = 
                <CSCOGrid
                    pData           = { this.state.mDataCollection }
                    pColumns        = { this.cfSetGridColumns() }
                    pageSize        = { 15 }
                    pScrollHeight   = { '300px' }
                    pEnableScroll   = { true}
                    OnItemChange    = { this.cfOnItemChange }
                />

            lSection = 
                <>
                    <CSCODetailsHeader1 pFormItems          = {lFormItemList} 
                                        pFormData           = {this.state.mFormData} 
                                        pFormProperties     = {this.state.mFormProperties} 
                                        pEnableSaveFn       = {this.cfEnableSave}
                                        pSaveActionItem     = {lSaveActionItem}
                                        pFormItemProperties = {this.state.mFormItemProperties}
                                        pSelectedData       = {this.state.mSelectedData}
                                        pOnPopout           = {this.onPopout}
                                        />

                    { lChooseFileInput }

                    { lGrid }

                    <CSCOHorizontalActionBar 
                        align       = "left"
                        pType       = 'DETAILS_ACTION_BAR'
                        parent      = { this }
                        actionList  = { lFormDetailsItemActionsList }
                    />

                    { lDetailsForm }
                </>
        }

        return lSection;
    }
}
