import React, { Component } from "react"
import {BreadCrumb} from 'primereact/breadcrumb';

import CSUtil from "../../util/CSUtil";
import CSConfigUtil from "../../util/CSConfigUtil";
import CSServiceUtil from "../../util/CSServiceUtil";
import { fnGetFormMetaData } from "../../util/CSMiscUtil"
import { fnGetFormHeader, fnGetForm, fnGetLayout } from "../../util/CSLayoutUtil"

class CSCSOBreadCrumbForm1 extends Component 
{
    mTempInd = 0;
    mParInd = 10000;
    mIndex = 0;
	mNumSections 		= 3;
    mMinSectionItems 	= 7;
    mCSServiceUtil      = null;
    mDetailsIdTabMap    = {};
    
    constructor(props)
    {
        super(props);
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();
        this.state = 
        {
            mDisplay            : props.pShow,
            mAppId              : props.pAppId,
            mFormHeading        : (props.pFormHeading) ? props.pFormHeading : "",
            mOrigFormHeading    : (props.pFormHeading) ? props.pFormHeading : "",
            mFormItems          : props.pFormItems,
            mFormDetails        : props.pFormDetails,
            mFormData           : (props.pFormData) ? props.pFormData : {},
            mFormOrigData       : CSUtil.cfGetObjectCopy(props.pFormData),
            mFormActions        : props.pFormActions,
            mFormProperties     : props.pFormProperties,
            mControlType        : props.pControlType,
            mEditable           : props.pEditable,
            mWidth              : props.pWidth,
            mHeight             : props.pHeight,
            mValue              : props.pValue,
            mSelectedForm       : null,
            mParentFlag         : props.pParentFlag,
            mParentTabList      : null,
            mChildTabList       : null,
            mChildFormDetails   : null,
            mParentActiveTab    : null,
            mChildActiveTab     : null,
            fnProcessClose      : props.fnProcessClose
        }

        this.cfGetTabList               = this.cfGetTabList.bind(this);
        this.cfGetTabMenu               = this.cfGetTabMenu.bind(this);
        this.cfCloseForm                = this.cfCloseForm.bind(this);
        this.cfProcessMenuClick         = this.cfProcessMenuClick.bind(this);
        this.cfProcessLayoutMetaData    = this.cfProcessLayoutMetaData.bind(this);
        this.cfProcessDetailFormMetaData= this.cfProcessDetailFormMetaData.bind(this);
        this.cfProcessTabChange         = this.cfProcessTabChange.bind(this);

        this.process_cancel = this.process_cancel.bind(this);
        this.process_close  = this.process_close.bind(this);
    }

    componentDidMount()
    {
        var lActiveParentTab;
        var lParentTabList  = null;
        var lParentTabCmp   = null;
        lParentTabList = this.cfGetTabList(true, this.props.pFormDetails);
        if(lParentTabList)
        {
            lActiveParentTab = lParentTabList[0];
            lParentTabCmp = this.cfGetTabMenu(lParentTabList, true, lActiveParentTab);
            this.setState(
                {
                    mParentTabList      : lParentTabCmp,
                    mParentActiveTab    : lActiveParentTab,
                    mParentActiveItem   : lActiveParentTab.details,
                }
            );

            var lEvent = {};
            lEvent.item = lActiveParentTab;
            if(lActiveParentTab.hasOwnProperty('details'))
            {
                lEvent.item.details = lActiveParentTab.details;
                lEvent.item.parentFlag = true;
            }

            this.cfProcessMenuClick(lEvent);
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
        }
    }

    cfCloseForm()
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose();
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    cfProcessMenuClick(lEvent)
    {
        if(lEvent.hasOwnProperty('item') && lEvent.item)
        {
            var lSelectedItem;
            if(lEvent.item.hasOwnProperty('details'))
            {
                lSelectedItem = lEvent.item.details;
            }

            /**
             * Change title with respect to selection 
             */
            var lFormHeading = this.state.mOrigFormHeading;
            if(lFormHeading)
            {
                var lAppName = lSelectedItem.child_app_name_;
                if(!lAppName)
                {
                    lAppName = lSelectedItem.tab_label_;
                }

                if(lAppName)
                {
                    lFormHeading = lFormHeading + " / " + lAppName;
                }
            }

            if(lEvent.item.parentFlag)
            {
                /**
                 * Highlight the parent tab, if selected 
                 */
                var lActiveParentTab;
                var lParentTabList  = null;
                var lParentTabCmp   = null;
                lParentTabList = this.cfGetTabList(true, this.props.pFormDetails);
                if(lParentTabList)
                {
                    for(var lParInd=0; lParInd<lParentTabList.length; lParInd++)
                    {
                        if(lParentTabList[lParInd].details.id_ === lSelectedItem.id_)
                        {
                            lActiveParentTab = lParentTabList[lParInd];
                            break;
                        }
                    }

                    lParentTabCmp = this.cfGetTabMenu(lParentTabList, true, lActiveParentTab);
                }

                this.setState(
                    {
                        mFormHeading        : lFormHeading,
                        mParentTabList      : lParentTabCmp,
                        mParentActiveTab    : lActiveParentTab,
                        mParentActiveItem   : lActiveParentTab.details,
                        mChildTabList       : null,
                        mChildActiveItem    : null,
                        mSelectedForm       : null
                    }
                );
            }
            else
            {
                /**
                 * Highlight the child tab, if selected 
                 */
                var lActiveChildTab;
                var lChildTabList  = null;
                var lChildTabCmp   = null;
                lChildTabList = this.cfGetTabList(false, this.state.mChildFormDetails);
                if(lChildTabList)
                {
                    for(var lChiInd=0; lChiInd<lChildTabList.length; lChiInd++)
                    {
                        if(lChildTabList[lChiInd].details.id_ === lSelectedItem.id_)
                        {
                            lActiveChildTab = lChildTabList[lChiInd];
                            break;
                        }
                    }

                    lChildTabCmp = this.cfGetTabMenu(lChildTabList, false, lActiveChildTab);
                }

                this.setState(
                    {
                        mFormHeading        : lFormHeading,
                        mChildTabList       : lChildTabCmp,
                        mChildActiveTab     : lActiveChildTab,
                        mSelectedForm       : null
                    }
                );
            }

            /**
             * Change title with respect to selection 
             */
            var lAppObj = {};
            lAppObj.app_id_               = lSelectedItem.child_app_id_;
            lAppObj.app_code_             = CSConfigUtil.mAppCode;
            lAppObj.selected_item_        = lSelectedItem;
            lAppObj.parentFlag            = lEvent.item.parentFlag;
            lAppObj.user_id_              = CSConfigUtil.mUserId;

            if((lSelectedItem.layout_type_ === 'BASIC') || (lSelectedItem.layout_type_ === 'STANDARD') || (lSelectedItem.layout_type_ === 'FORM'))
            {
                fnGetFormMetaData(lAppObj, this.cfProcessLayoutMetaData)
            }
            else if((lSelectedItem.layout_type_ === 'DETAILS') || (lSelectedItem.layout_type_ === 'DETAILS_GROUP'))
            {
                fnGetFormMetaData(lAppObj, this.cfProcessDetailFormMetaData)
            }
        }
    }

    cfGetTabList(lParentFlag, lFormDetails)
    {
        var lTabList = null;
        if(lFormDetails)
        {
            if(lFormDetails && (lFormDetails.length > 0))
            {
                lTabList = [];
                for(var lInd=0; lInd<lFormDetails.length; lInd++)
                {
                    var lFormDetailsObj = lFormDetails[lInd];
                    var lTabObject = {};
                    lTabObject.label = lFormDetailsObj.tab_label_;
                    lTabObject.icon = lFormDetailsObj.picture_name_;
                    lTabObject.details = lFormDetailsObj;
                    lTabObject.parentFlag = lParentFlag;
                    lTabObject.command = this.cfProcessMenuClick;
                    lTabList.push(lTabObject);
                    this.mDetailsIdTabMap[lFormDetailsObj.id_] = lTabObject;
                }
            }
        }

        return lTabList;
    }

    cfGetTabMenu(lTabList, lParentFlag, lActiveTab)
    {
        var lClassName = (lParentFlag) ? 'parentMenu' : 'childMenu';
        var lTabs = null;
        var lHome = null;
        if(lTabList)
        {
            for(var lTabInd=0; lTabInd<lTabList.length; lTabInd++)
            {
                if(lTabInd === 0)
                {
                    lHome = lTabList[0];
                    lTabs = [];
                }
                else
                {
                    lTabs.push(lTabList[lTabInd]);
                }
            }
        }

        return ( 
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <BreadCrumb  
                    model       = { lTabs }
                    className   = { lClassName } 
                    home        = { lHome }
                    activeItem  = { lActiveTab }/>
            </div>
        );
    }

    cfProcessTabChange(lEvent)
    {
    }

    cfProcessLayoutMetaData(lResponse, lCriteria)
    {
        if(lResponse && lCriteria)
        {
            lResponse.pFormType = lCriteria.selected_item_.cs_layout_code_;
            var lLayout = fnGetLayout(lResponse.pFormType, lResponse);
            this.setState({ mSelectedForm : lLayout });
        }
    }

    cfProcessDetailFormMetaData(lResponse, lCriteria)
    {
        if(lResponse && lCriteria && lCriteria.hasOwnProperty('selected_item_') && lCriteria.selected_item_)
        {
            var lSelectedItem = lCriteria.selected_item_;
            lResponse.pFormType = lSelectedItem.cs_layout_code_;
            if((lSelectedItem.cs_layout_code_ === 'CS_BREAD_CRUMB_LAYOUT_1') || (lSelectedItem.cs_layout_code_ === 'CS_DETAILS_FORM_TAB_LAYOUT_1'))
            {
                var lActiveChildTab;
                var lChildTabList  = null;
                var lChildTabCmp   = null;
                lChildTabList = this.cfGetTabList(false, lResponse.pFormDetails);
                if(lChildTabList && (lChildTabList.length > 0) && lSelectedItem)
                {
                    for(var lChiInd=0; lChiInd<lChildTabList.length; lChiInd++)
                    {
                        if(lChildTabList[lChiInd].details.id_ === lSelectedItem.id_)
                        {
                            lActiveChildTab = lChildTabList[lChiInd];
                            break;
                        }
                    }
    
                    if(!lActiveChildTab)
                    {
                        lActiveChildTab = lChildTabList[0];
                    }
    
                    lChildTabCmp = this.cfGetTabMenu(lChildTabList, false, lActiveChildTab);
                }
    
                this.setState(
                    {
                        mChildTabList       : lChildTabCmp, 
                        mChildFormDetails   : lResponse.pFormDetails, 
                        mChildActiveTab     : lActiveChildTab
                    }
                );

                var lEvent = {};
                lEvent.item = lActiveChildTab;
                if(lActiveChildTab.hasOwnProperty('details'))
                {
                    lEvent.item.details = lActiveChildTab.details;
                    lEvent.item.parentFlag = false;
                }

                this.cfProcessMenuClick(lEvent);
            }
            else
            {
                var lDataObj = null;
                if(lResponse.hasOwnProperty('pFormActions'))
                {
                    lResponse.pSelectedCriteria = lCriteria;
                    var lSelectedActionItem = null;
                    if(lResponse.pFormActions instanceof Array)
                    {
                        for(var lActionInd=0; lActionInd<lResponse.pFormActions.length; lActionInd++)
                        {
                            if(lResponse.pFormActions[lActionInd].id_ === lSelectedItem.action_ref_id_)
                            {
                                lSelectedActionItem = lResponse.pFormActions[lActionInd];
                                break;
                            }
                        }
                    }

                    if(this.state.mParentActiveTab && this.state.mParentActiveTab.details)
                    {
                        lResponse.pSelectedTabActionItem = this.state.mParentActiveTab.details;
                    }

                    if(lSelectedActionItem)
                    {
                        lResponse.pSelectedActionItem       = lSelectedActionItem;

                        lDataObj    = this.state.mFormData;
                        var lLayout = fnGetForm(lResponse, lDataObj, this.cfCloseForm);
                        this.setState({ mSelectedForm : lLayout });
                    }
                    else
                    {
                        lDataObj = this.state.mFormData;
                        var lLayout1 = fnGetForm(lResponse, lDataObj, this.cfCloseForm);
                        this.setState({ mSelectedForm : lLayout1 });
                    }
                }
            }
        }
    }

    render() 
    {
        return (
            <div style={{height: "100%"}}>
                { fnGetFormHeader(this.state.mFormHeading) }
                
                { this.state.mParentTabList }
                
                { this.state.mChildTabList }

                <div style={{height: "100%", minheight: "350px", marginTop: "0px", marginBottom: "2px"}}>
                    {this.state.mSelectedForm}
                </div>
            </div>
        );
    }
}

export default CSCSOBreadCrumbForm1;
