import React, { useEffect, useState } from 'react'

function CSCOScroller(lProps) {

    const [mPFDueDate, setPFDueDate] = useState('');
    const [mGSTDueDate, setGSTDueDate] = useState('');
    const [mTDSDueDate, setTDSDueDate] = useState('');
    const [mITDueDate, setITDueDate] = useState('');

    useEffect(() => {
            if(lProps && lProps.hasOwnProperty('mComplianceCodeDueDateMap')) {
                let lObj = lProps.mComplianceCodeDueDateMap;
                setPFDueDate(lObj['PF']);
                setGSTDueDate(lObj['GST']);
                setTDSDueDate(lObj['TDS']);
                setITDueDate(lObj['IT']);
            }
        }, [lProps]
    );

    return (
        <div className='w-full'>
            <div id="scroll-container" className='bg-clr-text  mb-1' style={{width:'120%'}}>
                <div id="scroll-text" className='text-white text-center p-2 '>PF due on {mPFDueDate}     |         GST due on {mGSTDueDate}     |         TDS due on {mTDSDueDate}     |         IT due on {mITDueDate}</div>
            </div>
        </div>
    )
}

export default CSCOScroller