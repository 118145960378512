import React from "react"
import {Dropdown} from 'primereact/dropdown';
import CSServiceUtil from '../util/CSServiceUtil'
import CSBaseControl from "./CSBaseControl";

class CSDropDown extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            mDataProvider : [],
            mValue        : (this.props.pValue) ? this.props.pValue : null,
            mPlaceHolder  : this.props.pPlaceHolder
        }

        var lProperties = null;
        if(this.props.hasOwnProperty('cs_form_criteria_properties_'))
        {
            lProperties = this.props.cs_form_criteria_properties_;
        }
        else if(this.props.hasOwnProperty('cs_form_item_properties_'))
        {
            lProperties = this.props.cs_form_item_properties_;
        }

        if(lProperties)
        {            
            for(var lInd=0; lInd<lProperties.length; lInd++)
            {
                var lProperty = lProperties[lInd];
                if((lProperty.type_ === 'other') && (lProperty.property_ === 'place_holder'))
                {
                    this.state.mPlaceHolder = lProperty.value_;
                    break;
                }
            }
        }

        this.mServiceUtil       = CSServiceUtil.cfGetInstance();
        this.cfGetControlLabel  = this.cfGetControlLabel.bind(this);
        this.cfHandleChange     = this.cfHandleChange.bind(this);
        this.cfHandleResponse   = this.cfHandleResponse.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);
    }

    componentDidMount()
    {        
        if(this.props.data_provider_source_)
        {
            this.mServiceUtil.cfGetDataFromDataProvider (this.props.data_provider_source_, this.cfHandleResponse);
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(lPrevProps.pShow !== this.props.pShow)
                {
                this.setState(
                    {
                        mValue : (this.props.pValue) ? this.props.pValue : null,
                    }
                );
            }

            if (this.props.data_provider_source_)
            {
                this.mServiceUtil.cfGetDataFromDataProvider (this.props.data_provider_source_, (lDataProviderId, lCollection) =>
                    {
                        this.cfHandleResponse(lDataProviderId, lCollection);
                    }
                );
            }
            else
            {
                this.setState({mValue : this.props.mValue});    
            }
        }
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    cfHandleResponse(lDataProviderId, lCollection)
    {
        if(this.props && (lDataProviderId === this.props.data_provider_source_))
        {
            var lValue = this.props.mValue;
            var lDataProvider = null;
            var lDependentValObj = null;
            var lDependencyFlag = false;
            if(this.props.hasOwnProperty('cs_form_criteria_properties_'))
            {
                var lProperties = this.props.cs_form_criteria_properties_;
                for(var lfcpInd=0; lfcpInd<lProperties.length; lfcpInd++)
                {
                    var lProperty = lProperties[lfcpInd];
                    if(lProperty.type_ === 'dependency')
                    {
                        if(lProperty.value_ && lProperty.selection_property_ && lProperty.mDependentValue)
                        {
                            if(!lDependentValObj)
                            {
                                lDependentValObj = {}
                            }

                            lDependentValObj[lProperty.selection_property_] = lProperty.mDependentValue;
                        }

                        lDependencyFlag = true;
                    }
                }
            }

            if(this.props.dependent_field_)
            {
                if(lCollection && this.props.dependent_value_)
                {
                    for(var ldpInd=0; ldpInd<lCollection.length; ldpInd++)
                    {
                        var ldpObj = lCollection[ldpInd];
                        var lDependentField = (this.props.reference_field_) ? this.props.reference_field_ : this.props.dependent_field_;
                        if(lDependentField)
                        {
                            if(ldpObj[lDependentField] && (ldpObj[lDependentField] === this.props.dependent_value_))
                            {
                                if(!lDataProvider)
                                {
                                    lDataProvider = [];
                                }

                                lDataProvider.push(ldpObj);
                            }
                        }
                    }
                }
            }
            else if(lCollection && lDependentValObj)
            {
                for(var ldpInd1=0; ldpInd1<lCollection.length; ldpInd1++)
                {
                    var ldpObj1 = lCollection[ldpInd1];
                    var lKeys = Object.keys(lDependentValObj);
                    var lIncludeFlag = true;
                    for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                    {
                        var lKey = lKeys[lKeyInd];
                        if(ldpObj1.hasOwnProperty(lKey))
                        {
                            if(ldpObj1[lKey] !== lDependentValObj[lKey])
                            {
                                lIncludeFlag = false;
                                break;
                            }
                        }
                    }
                    
                    if(lIncludeFlag)
                    {
                        if(!lDataProvider)
                        {
                            lDataProvider = [];
                        }

                        lDataProvider.push(ldpObj1);
                    }
                }                
            }
            else
            {
                if(!lDependencyFlag)
                {
                    lDataProvider = lCollection;
                    if(this.props.hasOwnProperty('pFormItem') && this.props.pFormItem && this.props.pFormItem.constant_ && !this.props.pValue)
                    {
                        lValue = this.props.pFormItem.constant_;
                    }
                }
            }

            this.setState(
                {
                    mDataProvider : lDataProvider,
                    mValue : this.cfSetControlValue(lDataProvider, this.props.pDataProviderId, lValue)
                }
            );
        }
    }

    cfSetControlValue(lCollection, lDataProviderId, lValue)
    {
        var lRetValue = null;
        if(lCollection && lValue)
        {
            if(lDataProviderId)
            {
                for(var ind=0; ind<lCollection.length; ind++)
                {
                    var lData = lCollection[ind]
                    if(lData[lDataProviderId] === lValue)
                    {
                        lRetValue = lData;
                        break;
                    }
                }
            }
            else
            {
                lRetValue = lValue;
            }
        }

        return lRetValue;
    }

    cfHandleChange(event)
    {
        var lSelectedData = event.value;
        if(this.props.pProcessChange)
        {
            var lData = {};
            lData.control_type_ = 'combo_box';
            lData.data_field_   = this.props.pDataField;
            lData.id_           = this.props.pId;
            if(lSelectedData)
            {
                if(lData.data_field_ && lSelectedData.hasOwnProperty(this.props.pDataProviderId))
                {
                    lData.selected_data_ = lSelectedData;
                    lData.value_ = lSelectedData[this.props.pDataProviderId];
                }
                else
                {
                    lData.value_ = lSelectedData;
                }
            }

            this.props.pProcessChange(lData);
        }
    }

    cfGetControlLabel()
    {
        var lCtlLabel = null;
        if(this.props.pDisplayField)
        {
            var lLabel = null;
            if(this.props.pRequired)
            {
                lLabel = 
                <>
                    {this.props.pDisplayField} <span style={{color: 'red'}}>&nbsp;* </span> 
                </>
            }
            else
            {
                lLabel = 
                <>
                    {this.props.pDisplayField}
                </>
            }

            lCtlLabel = 
                <div style={{padding: '0.25em'}}>
                    <label style = 
                        {
                            {
                                display     : 'block', 
                                fontSize    : '12.5px', 
                                fontWeight  : this.props.pType === 'section_total' ? 500 : 500, 
                                width       : (!this.props.pLabelWidth) ? '100px' : this.props.pLabelWidth,
                                textAlign   : this.props.pType === 'section_total' ? 'right' : 'left'
                            }
                        }>
                        {lLabel}
                    </label>
                </div>
        }

        return lCtlLabel;
    }

    render()
    {
        var ctl = null;
        if(this.props.pControlType && (this.props.pControlType === 'combo_box'))
        {
            ctl = (
                <div className="cs-control field grid">
                    { super.cfGetControlLabel() }
                    
                    <Dropdown
                        className   = "cs-drop-down"
                        id          = {this.props.pId}
                        filter      = {true}
                        inputId     = {this.props.pId}
                        editable    = {this.props.pEditable}
                        showClear   = {!this.props.pRequired}
                        appendTo    = {document.body}
                        placeholder = {this.state.mPlaceHolder}
                        name        = {this.props.pDataField}
                        onChange    = {this.cfHandleChange}
                        options     = {this.state.mDataProvider}
                        value       = {this.state.mValue}
                        optionLabel = {this.props.data_provider_display_field_}/>
                </div>
            )
        }

        return ctl;
    }
}

export default CSDropDown;
