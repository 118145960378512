import React from "react"
import {InputTextarea} from 'primereact/inputtextarea';
import CSServiceUtil from '../util/CSServiceUtil'
import CSBaseControl from './CSBaseControl'

class CSTextArea extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.mServiceUtil       = CSServiceUtil.cfGetInstance();
        this.cfGetControl       = this.cfGetControl.bind(this);
        this.cfHandleChange     = this.cfHandleChange.bind(this);
        this.cfHandleResponse   = this.cfHandleResponse.bind(this);
    }

    cfHandleResponse(lDataProviderId, lData)
    {
    }

    cfHandleChange(event)
    {
        if(this.state.mHandleChange)
        {
            var lData = {};
            lData.control_type_ = 'text_area';
            lData.data_field_   = this.state.mDataField;
            lData.value_        = event.target.value;
            lData.id_           = this.state.mId;

            this.state.mHandleChange(lData);
            this.setState({mValue : event.target.value});
        }
    }

    cfGetControl()
    {
        var ctl = null;
        if(this.state.mControlType && (this.state.mControlType === 'text_area'))
        {
            ctl = (
                <div className="cs-control field grid">
                    { super.cfGetControlLabel() }

                    <InputTextarea 
                        style = 
                        {
                            {
//                                width           : `calc(100% > ${this.state.mMaxWidth})` ? this.state.mMaxWidth : `calc(100%)`,
                                height          : this.state.mHeight,
                                backgroundColor : (this.state.mEditable) ? '#FFFFFF' : 'var(--primary-color)'
//                                fontFamily : "'AnupamaMedium', serif",
//                                fontSize : '20px'
                            }
                        }
                        className   = "cs-text-area"
                        id          = {this.state.mId} 
                        name        = {this.state.mDataField}
                        value       = {this.state.mValue || ''}
                        onChange    = {this.cfHandleChange}
                        readOnly    = { !this.state.mEditable }
                        />
                </div>
            )
        }

        return ctl;
    }

    render()
    {
        return this.cfGetControl();
    }
}

export default CSTextArea;
