import React from "react";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

function CSCOFullCalendar() {
  return (
    <div>
        <Fullcalendar
            plugins       = {[dayGridPlugin, interactionPlugin]}
            initialView   = {"dayGridMonth"}
            headerToolbar = {{ start: "today prev,next", center: "title", end: "dayGridMonth, timeGridWeek, timeGridDay" }}
        />
    </div>
  );
}

export default CSCOFullCalendar;
