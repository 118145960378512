import React from "react"
import {Calendar} from 'primereact/calendar';
import CSServiceUtil from '../util/CSServiceUtil'
import CSBaseControl from './CSBaseControl'

class CSTimeField extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.mServiceUtil = CSServiceUtil.cfGetInstance();
        this.cfGetControl = this.cfGetControl.bind(this);
        this.cfHandleChange = this.cfHandleChange.bind(this);
        this.cfHandleResponse = this.cfHandleResponse.bind(this);

        if(this.props.pValue && (this.props.pValue !== '0000-00-00') && (this.props.pValue !== ''))
        {
            var lDate = new Date(this.props.pValue);
            this.state.mValue = lDate; 
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            var lDate = new Date();
            var lHourString = '00';
            var lMinutesString = '00';

            if(this.props.pValue && (this.props.pValue !== '0000-00-00') && (this.props.pValue !== ''))
            {
                var lTimeArray = (this.props.pValue).split(':');
                lHourString = lTimeArray[0];
                if(!lHourString)
                {
                    lHourString = '00';
                }
                else if(lHourString.length < 2)
                {
                    lHourString = '0' + lHourString;
                }

                lMinutesString = lTimeArray[1];
                if(!lMinutesString)
                {
                    lMinutesString = '00';
                }
                else if(lMinutesString.length < 2)
                {
                    lMinutesString = '0' + lMinutesString;
                }
            }

            lDate.setHours(lHourString);
            lDate.setMinutes(lMinutesString);
            this.setState({mValue : lDate});
        }
    }

    componentWillUnmount()
    {
        this.props = null;
    }
    
    cfHandleResponse(lDataProviderId, lData)
    {
        if(lDataProviderId === this.props.data_provider_source_)
        {
            this.setState({mDataProvider : lData});
        }
    }

    cfHandleChange(event)
    {
        event.preventDefault();
        if(this.state.mHandleChange)
        {
            var lDate = event.value;
            if(lDate && (lDate instanceof Date))
            {
                var lHourString = lDate.getHours();
                if(!lHourString)
                {
                    lHourString = '00';
                }
                else if(lHourString.length < 2)
                {
                    lHourString = '0' + lHourString;
                }

                var lMinutesString = lDate.getMinutes();
                if(!lMinutesString)
                {
                    lMinutesString = '00';
                }
                else if(lMinutesString.length < 2)
                {
                    lMinutesString = '0' + lMinutesString;
                }

                var lData           = {};
                lData.control_type_ = 'time_field';
                lData.data_field_   = this.state.mDataField;
                lData.id_           = this.state.mId;
                lData.value_        = lHourString + ':' + lMinutesString;
                this.state.mHandleChange(lData);
                console.log(lData.value_);
            }
        }
    }

    cfGetControl()
    {
        var ctl = null;
        if(this.state.mControlType && (this.state.mControlType === 'time_field'))
        {
            ctl = (
                <div className="cs-control field grid">
                    { super.cfGetControlLabel() }
                    
                    <Calendar
                        className       = "cs-time-field"
                        hourFormat      = "24"
                        appendTo        = { document.body }
                        showTime        = {true}
                        timeOnly        = {true}
                        value           = {(this.state.mValue)}
                        id              = { this.state.mId } 
                        onChange        = { this.cfHandleChange }
                        showIcon        = { false }
                        />
                </div>
            )
        }

        return ctl;
    }

    render()
    {        
        return this.cfGetControl();
    }
}

export default CSTimeField;
