import React, { useState, useRef, useEffect } from 'react';
import QRCode from "qrcode.react"
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import CSUtil from "../util/CSUtil";
import { cfGetConstantValue } from '../util/CSConstantUtil';
import CSConfigUtil from "../util/CSConfigUtil";
import { fnConvertNumberToWords } from '../util/CSMathUtil';

const CSReceiptForm5 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const [ mHeader, setHeader ]                 = useState({}, [])
    const [ mTopSection1, setTopSection1 ]       = useState({}, []);
    const [ mTopSection2, setTopSection2 ]       = useState({}, []);
    const [ mPaymentTable, setPaymentTable ]     = useState({}, []);
    const [ mPaymentData, setPaymentData ]       = useState({}, []);
    const [ mPaymentDetails, setPaymentDetails ] = useState({}, []);
    const [ mQrCode, setQrCode ]                 = useState("");
    const [ mFooter, setFooter ]                 = useState("");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pFormProperties)
                {
                    var lFormData       = null;
                    var lHeader         = null;
                    var lTopSection1    = null;
                    var lTopSection2    = null;
                    var lPaymentTable   = null;
                    var lPaymentData    = null;
                    var lPaymentDetails = null;

                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        if(lPropObj.type_ === 'initialize')
                        {
                            if(lProps.pSelectedData)
                            {
                                if(lProps.pSelectedData.hasOwnProperty(lPropObj.value_))
                                {
                                    lFormData = lProps.pSelectedData[lPropObj.value_];
                                }
                                else
                                {
                                    lFormData = lProps.pSelectedData;
                                } 
                            }
                        }
                        else
                        {
                            var lDataValue = fnGetDataValue(lFormData, lPropObj.value_, lPropObj.property_, lPropObj.type_);
                            if(lDataValue)
                            {
                                if(lPropObj.type_ === 'header')
                                {
                                    if(!lHeader)
                                    {
                                        lHeader = {}
                                    }

                                    lHeader[lPropObj.property_] = lDataValue;
                                }
                                else if(lPropObj.type_ === 'top_section_1')
                                {
                                    if(!lTopSection1)
                                    {
                                        lTopSection1 = {}
                                    }

                                    lTopSection1[lPropObj.property_] = lDataValue;
                                }
                                else if(lPropObj.type_ === 'top_section_2')
                                {
                                    if(!lTopSection2)
                                    {
                                        lTopSection2 = {}
                                    }

                                    lTopSection2[lPropObj.property_] = lDataValue;
                                }
                                else if(lPropObj.type_ === 'payment_table')
                                {
                                    if(!lPaymentTable)
                                    {
                                        lPaymentTable = {}
                                    }

                                    lPaymentTable[lPropObj.property_] = lDataValue;
                                }
                                else if(lPropObj.type_ === 'payment_data')
                                {
                                    if(!lPaymentData)
                                    {
                                        lPaymentData = {}
                                    }

                                    lPaymentData[lPropObj.property_] = lDataValue;
                                }
                                else if(lPropObj.type_ === 'payment_details')
                                {
                                    if(!lPaymentDetails)
                                    {
                                        lPaymentDetails = {}
                                    }

                                    lPaymentDetails[lPropObj.property_] = lDataValue;
                                }
                            }
                        }
                    }

                    setHeader(lHeader);
                    setTopSection1(lTopSection1);
                    setTopSection2(lTopSection2);
                    setPaymentTable(lPaymentTable);
                    setPaymentData(lPaymentData);
                    setPaymentDetails(lPaymentDetails);
                }
            }

            fnInitializeForm();
        }, [lProps, setHeader, setTopSection1, setTopSection2, setPaymentTable, setPaymentData, setPaymentDetails, setFooter]
    );

    const fnGetDataValue = (lDataObj, lDataField, lProperty, lType) =>
    {
        var lRetValue = null;

        /**
         * Check is data field is constant
         */
        if(lDataField.startsWith('#') && lDataField.endsWith('#'))
        {
            if(lDataField === '#RECEIPT_HEADING#')
            {
                lRetValue = CSConfigUtil.mReceiptHeading[CSConfigUtil.mBranchCode];
            }
            else if(lDataField === '#RECEIPT_SUB_HEADING_1#')
            {
                lRetValue = CSConfigUtil.mReceiptSubHeading1[CSConfigUtil.mBranchCode];
            }
            else if(lDataField === '#RECEIPT_SUB_HEADING_2#')
            {
                lRetValue = CSConfigUtil.mReceiptSubHeading2[CSConfigUtil.mBranchCode];
            }
            else
            {
                lRetValue = cfGetConstantValue(lDataField);
            }
        }
        else if(lDataObj && lDataObj.hasOwnProperty(lDataField))
        {
            if(lProperty && (lProperty === 'payment_in_words_'))
            {
                var lValue = (Math.round(lDataObj[lDataField])).toFixed(0);
                lRetValue = fnConvertNumberToWords(lValue);
            }
            else
            {
                lRetValue = lDataObj[lDataField];
            }
        }
        else if(!lProperty.includes('data_field_'))
        {
            lRetValue = lDataField;
        }

        return lRetValue;
    }

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
            onAfterPrint= { () => lProps.fnProcessClose(false, true) }
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => lProps.fnProcessClose(false, true) }
        />
    );

    var lTitleBar = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    /**
     * Header
     */
    let lTitle = null;
    if(mHeader && mHeader.hasOwnProperty('title_') && mHeader.title_)
    {
        lTitle = <h1>{ mHeader.title_ }</h1>
    }

    let lHeading1 = null;
    if(mHeader && mHeader.hasOwnProperty('heading_1_') && mHeader.heading_1_)
    {
        lHeading1 = 
            <div className="ins-name">
                { mHeader.heading_1_ }
            </div>
    }

    let lHeading2 = null;
    if(mHeader && mHeader.hasOwnProperty('heading_2_') && mHeader.heading_2_)
    {
        lHeading2 = 
            <div className="heading-copy">
                { mHeader.heading_2_ }
            </div>
    }

    let lSubTitle1 = null;
    if(mHeader && mHeader.hasOwnProperty('sub_title_1_') && mHeader.sub_title_1_)
    {
        lSubTitle1 = <h2>{ mHeader.sub_title_1_ }</h2>
    }

    let lSubTitle2 = null;
    if(mHeader && mHeader.hasOwnProperty('sub_title_2_') && mHeader.sub_title_2_)
    {
        lSubTitle2 = <h2>{ mHeader.sub_title_2 }</h2>
    }

    let lHeading = null;
    if(lHeading1 || lHeading2)
    {
        lHeading = 
            <div className="top">
                { lHeading1 }
                { lHeading2 }
            </div>
    }

    let lHeader = null;
    if(lTitle || lHeading || lSubTitle1 || lSubTitle2)
    {
        lHeader = 
            <div className="title">
                { lHeading1 }

                <div className='sub-title'>
                    { lSubTitle1 }
                    { lSubTitle2 }
                </div>
            </div>
    }

    /**
     * Top Section 1
     */
    let lTopSection1Column1 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_1_label_'))
    {
        let lTopSection1Column1Label     = mTopSection1.column_1_label_;
        let lTopSection1Column1Data      = mTopSection1.column_1_data_field_;
        lTopSection1Column1 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column1Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column1Data }</div>
            </div>
    }

    let lTopSection1Column2 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_2_label_'))
    {
        let lTopSection1Column2Label     = mTopSection1.column_2_label_;
        let lTopSection1Column2Data      = mTopSection1.column_2_data_field_;
        lTopSection1Column2 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column2Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column2Data }</div>
            </div>
    }

    let lTopSection1Column3 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_3_label_'))
    {
        let lTopSection1Column3Label     = mTopSection1.column_3_label_;
        let lTopSection1Column3Data      = mTopSection1.column_3_data_field_;
        lTopSection1Column3 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column3Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column3Data }</div>
            </div>
    }

    let lTopSection1Column4 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_4_label_'))
    {
        let lTopSection1Column4Label     = mTopSection1.column_4_label_;
        let lTopSection1Column4Data      = mTopSection1.column_4_data_field_;
        lTopSection1Column4 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column4Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column4Data }</div>
            </div>
    }

    let lTopSection1Column5 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_5_label_'))
    {
        let lTopSection1Column5Label     = mTopSection1.column_5_label_;
        let lTopSection1Column5Data      = mTopSection1.column_5_data_field_;
        lTopSection1Column5 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column5Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column5Data }</div>
            </div>
    }

    let lTopSection1Column6 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_6_label_'))
    {
        let lTopSection1Column6Label     = mTopSection1.column_6_label_;
        let lTopSection1Column6Data      = mTopSection1.column_6_data_field_;
        lTopSection1Column6 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column6Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column6Data }</div>
            </div>
    }

    let lTopSection1Column7 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_7_label_'))
    {
        let lTopSection1Column7Label     = mTopSection1.column_7_label_;
        let lTopSection1Column7Data      = mTopSection1.column_7_data_field_;
        lTopSection1Column7 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column7Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column7Data }</div>
            </div>
    }

    let lTopSection1Column8 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_8_label_'))
    {
        let lTopSection1Column8Label     = mTopSection1.column_8_label_;
        let lTopSection1Column8Data      = mTopSection1.column_8_data_field_;
        lTopSection1Column8 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column8Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column8Data }</div>
            </div>
    }

    /**
     * Top Section 2
     */
    let lTopSection2Column1 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_1_label_'))
    {
        let lTopSection2Column1Label     = mTopSection2.column_1_label_;
        let lTopSection2Column1Data      = mTopSection2.column_1_data_field_;
        lTopSection2Column1 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column1Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column1Data }</div>
            </div>
    }

    let lTopSection2Column2 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_2_label_'))
    {
        let lTopSection2Column2Label     = mTopSection2.column_2_label_;
        let lTopSection2Column2Data      = mTopSection2.column_2_data_field_;
        lTopSection2Column2 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column2Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column2Data }</div>
            </div>
    }

    let lTopSection2Column3 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_3_label_'))
    {
        let lTopSection2Column3Label     = mTopSection2.column_3_label_;
        let lTopSection2Column3Data      = mTopSection2.column_3_data_field_;
        lTopSection2Column3 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column3Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column3Data }</div>
            </div>
    }

    let lTopSection2Column4 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_4_label_'))
    {
        let lTopSection2Column4Label     = mTopSection2.column_4_label_;
        let lTopSection2Column4Data      = mTopSection2.column_4_data_field_;
        lTopSection2Column4 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column4Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column4Data }</div>
            </div>
    }

    let lTopSection2Column5 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_5_label_'))
    {
        let lTopSection2Column5Label     = mTopSection2.column_5_label_;
        let lTopSection2Column5Data      = mTopSection2.column_5_data_field_;
        lTopSection2Column5 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column5Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column5Data }</div>
            </div>
    }

    let lTopSection2Column6 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_6_label_'))
    {
        let lTopSection2Column6Label     = mTopSection2.column_6_label_;
        let lTopSection2Column6Data      = mTopSection2.column_6_data_field_;
        lTopSection2Column6 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column6Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column6Data }</div>
            </div>
    }

    let lTopSection2Column7 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_7_label_'))
    {
        let lTopSection2Column7Label     = mTopSection2.column_7_label_;
        let lTopSection2Column7Data      = mTopSection2.column_7_data_field_;
        lTopSection2Column7 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column7Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column7Data }</div>
            </div>
    }

    let lTopSection2Column8 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_8_label_'))
    {
        let lTopSection2Column8Label     = mTopSection2.column_8_label_;
        let lTopSection2Column8Data      = mTopSection2.column_8_data_field_;
        lTopSection2Column8 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column8Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column8Data }</div>
            </div>
    }

    const lTopSection1 = 
        <div className="summary">
            <div className="summary-1">
                { lTopSection1Column1 }
                { lTopSection1Column3 }
                { lTopSection1Column5 }
                { lTopSection1Column7 }
            </div>

            <div className="summary-2">
                { lTopSection1Column2 }
                { lTopSection1Column4 }
                { lTopSection1Column6 }
                { lTopSection1Column8 }
            </div>

            <div className="qrcode">
                <QRCode
                    style   = {{ width: '72px', height: '72px' }}
                    level   = "L"
                    value   = { mQrCode }
                />
            </div>
        </div>

    let lTopSection2 = null;
    if(lTopSection2Column1 || lTopSection2Column2)
    {
        lTopSection2 = 
            <div className="summary">
                <div className="summary-1">
                    { lTopSection2Column1 }
                    { lTopSection2Column3 }
                    { lTopSection2Column5 }
                    { lTopSection2Column7 }
                </div>

                <div className="summary-2">
                    { lTopSection2Column2 }
                    { lTopSection2Column4 }
                    { lTopSection2Column6 }
                    { lTopSection2Column8 }
                </div>
            </div>
    }

    /**
     * Table 1
     */
    let lTable1Heading = null;
    let lTable1Data = null;
    let lTable1Column1Heading = null;
    var lKey = 0;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_1_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column1Heading = <th key={lKey++}>{ mPaymentTable.column_1_heading_ }</th>
        lTable1Heading.push(lTable1Column1Heading);
        
        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field1Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_1_data_field_'))
        {
            lTable1Field1Data = <td key={lKey++}> {mPaymentTable.column_1_data_field_} </td> 
        }
        else
        {
            lTable1Field1Data = <td key={lKey++}/>
        }

        lTable1Data.push(lTable1Field1Data);
    }

    let lTable1Column2Heading = null;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_2_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column2Heading = <th key={lKey++}>{ mPaymentTable.column_2_heading_ }</th>
        lTable1Heading.push(lTable1Column2Heading);

        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field2Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_2_data_field_'))
        {
            lTable1Field2Data = <td key={lKey++}> {mPaymentTable.column_2_data_field_} </td> 
        }
        else
        {
            lTable1Field2Data = <td  key={lKey++}/>
        }

        lTable1Data.push(lTable1Field2Data);
    }

    let lTable1Column3Heading = null;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_3_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column3Heading = <th key={lKey++}>{ mPaymentTable.column_3_heading_ }</th>
        lTable1Heading.push(lTable1Column3Heading);

        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field3Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_3_data_field_'))
        {
            lTable1Field3Data = <td key={lKey++}> {mPaymentTable.column_3_data_field_} </td> 
        }
        else
        {
            lTable1Field3Data = <td key={lKey++}/>
        }

        lTable1Data.push(lTable1Field3Data);
    }

    let lTable1Column4Heading = null;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_4_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column4Heading = <th key={lKey++}>{ mPaymentTable.column_4_heading_ }</th>
        lTable1Heading.push(lTable1Column4Heading);

        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field4Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_4_data_field_'))
        {
            lTable1Field4Data = <td key={lKey++}> {mPaymentTable.column_4_data_field_} </td> 
        }
        else
        {
            lTable1Field4Data = <td key={lKey++}/>
        }

        lTable1Data.push(lTable1Field4Data);
    }

    let lTable1Column5Heading = null;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_5_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column5Heading = <th key={lKey++}>{ mPaymentTable.column_5_heading_ }</th>
        lTable1Heading.push(lTable1Column5Heading);

        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field5Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_5_data_field_'))
        {
            lTable1Field5Data = <td key={lKey++}> {mPaymentTable.column_5_data_field_} </td> 
        }
        else
        {
            lTable1Field5Data = <td key={lKey++}/>
        }

        lTable1Data.push(lTable1Field5Data);
    }

    let lTable1Column6Heading = null;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_6_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column6Heading = <th key={lKey++}>{ mPaymentTable.column_6_heading_ }</th>
        lTable1Heading.push(lTable1Column6Heading);

        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field6Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_6_data_field_'))
        {
            lTable1Field6Data = <td key={lKey++}> {mPaymentTable.column_6_data_field_} </td> 
        }
        else
        {
            lTable1Field6Data = <td key={lKey++}/>
        }

        lTable1Data.push(lTable1Field6Data);
    }

    let lTable1Column7Heading = null;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_7_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column7Heading = <th key={lKey++}>{ mPaymentTable.column_7_heading_ }</th>
        lTable1Heading.push(lTable1Column7Heading);

        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field7Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_7_data_field_'))
        {
            lTable1Field7Data = <td key={lKey++}> {mPaymentTable.column_7_data_field_} </td> 
        }
        else
        {
            lTable1Field7Data = <td key={lKey++}/>
        }

        lTable1Data.push(lTable1Field7Data);
    }

    let lTable1Column8Heading = null;
    if(mPaymentTable && mPaymentTable.hasOwnProperty('column_8_heading_'))
    {
        if(!lTable1Heading)
        {
            lTable1Heading = []
        }

        lTable1Column8Heading = <th key={lKey++}>{ mPaymentTable.column_8_heading_ }</th>
        lTable1Heading.push(lTable1Column8Heading);

        if(!lTable1Data)
        {
            lTable1Data = []
        }

        let lTable1Field8Data = null;
        if(mPaymentTable && mPaymentTable.hasOwnProperty('column_8_data_field_'))
        {
            lTable1Field8Data = <td key={lKey++}> {mPaymentTable.column_8_data_field_} </td> 
        }
        else
        {
            lTable1Field8Data = <td key={lKey++}/>
        }

        lTable1Data.push(lTable1Field8Data);
    }

    let lTable1 = null;
    if(lTable1Heading)
    {
        lTable1 = 
            <div className="payment-details">
                <h2>{ (mPaymentTable.title_) ? mPaymentTable.title_ : 'Payment Summary' }</h2>
                <table className="items">
                    <thead>
                        <tr key={lKey++}>{ lTable1Heading }</tr>
                    </thead>

                    <tbody>{ lTable1Data }</tbody>
                </table>
            </div>
    }

    const lPaymentData = 
        <div className="pmt-amt"> 
            <div className="pmt-words"> 
                <div style={{fontWeight: 'bold'}}>{mPaymentData.payment_label_}</div>
                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                <div>{mPaymentData.payment_data_field_}</div>
            </div>
                
            <div className="pmt-words"> 
                <div style={{fontWeight: 'bold'}}>Payment in Words </div>
                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                <div>Rupees {mPaymentData.payment_in_words_}</div>
            </div>
        </div>

    const lPaymentDetails = 
        <>
            <div className="payment-info">
                <div className="pi-item">
                    <div className="pi-item-heading">
                        { "Payment Mode" }
                    </div>

                    { mPaymentDetails.payment_mode_ }
                </div>
                
                <div className="pi-item">
                    <div className="pi-item-heading">
                        { mPaymentDetails.cheque_no_label_ }
                    </div>

                    { mPaymentDetails.cheque_no_data_field_ }
                </div>

                <div className="pi-item">
                    <div className="pi-item-heading">
                        { mPaymentDetails.cheque_date_label_ }
                    </div>

                    { mPaymentDetails.cheque_date_data_field_ }
                </div>

                <div className="pi-item">
                    <div className="pi-item-heading">
                        { mPaymentDetails.bank_label_ }
                    </div>

                    { mPaymentDetails.bank_data_field_ }
                </div>

                <div className="pi-item-accountant">
                    { mPaymentDetails.mAccountant }

                    <div className="pi-item-heading">
                        { (mPaymentDetails.authorized_signatory_label_) ? (mPaymentDetails.authorized_signatory_label_) : "Principal/Accountant" }
                    </div>
                </div>
            </div>

            <div className="cheque-realization"> 
                { mFooter }
            </div>
        </>

    const lForm = 
        <div id="rf_5" ref={mComponentRef} style={{paddingTop : '10px', paddingBottom : '10px'}}>
            <div className="rf5 overflow-auto">
                <div style={{minWidth: '750px'}}>
                    <main>
                        { lHeader }

                        <div className='form-title'>
                            <h2>{ mHeader.heading_ }</h2>
                        </div>

                        { lTopSection1 }
                        { lTopSection2 }
                        { lTable1 }
                        { lPaymentData }
                        { lPaymentDetails }
                    </main>
                </div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            appendTo  = { document.body }
            header    = {lTitleBar}
            onHide    = { () => lProps.fnProcessClose(false, true) }>
                { lForm }
        </Dialog>
    );
}

export default CSReceiptForm5;