import React, { useState, useRef, useEffect } from 'react';
import QRCode from "qrcode.react"
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import CSUtil from "../util/CSUtil";
import CSConfigUtil from "../util/CSConfigUtil";
import { cfGetConstantValue } from '../util/CSConstantUtil';
import { fnConvertNumberToWords } from '../util/CSMathUtil';

const CSTransferNote = (lProps) =>
{
    const mComponentRef = useRef(null);
    const [ mInstituteHeading, setInstituteHeading ] = useState('')
    const [ mParentCopyHeading, setParentCopyHeading ] = useState('')

    const [ mFormTitle,           setFormTitle ] = useState(CSConfigUtil.mReceiptHeading[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle1,   setFormSubTitle1 ] = useState(CSConfigUtil.mReceiptSubHeading1[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle2,   setFormSubTitle2 ] = useState(CSConfigUtil.mReceiptSubHeading2[CSConfigUtil.mBranchCode]);
    const [ mStudentDetails, setStudentDetails ] = useState(
        {
            mAcademicYear     : '',
            mAdmissionNo      : '',
            mLegacyNo         : '',
            mStudentName      : '',
            mParentName       : '',
            mClass            : '',
            mSection          : ''
        }, []
    );

    const [ mTransferSummary, setTransferSummary ] = useState(
        {
            mTransferNoteNo       : '',
            mInitiatedDate        : '',
            mInitiatedBy          : '',
            mSubmittedDate        : '',
            mSubmittedBy          : '',
            mTransferStatus       : '',
            mTransferReason       : '',
            mFromBranchName       : '',
            mToBranchName         : '',
            mFromBranchSection    : '',
            mToBranchSection      : 'Section A'
        },[]
    );

    const [ mFeeSummary, setFeeSummary ] = useState(
        {
            mFromBranchErpFee           : '',
            mFromBranchConcession       : '',
            mFromBranchNetFee           : '',
            mFromBranchProportionateFee : '',
            mToBranchErpFee             : '',
            mToBranchConcession         : '',
            mToBranchNetFee             : '',
            mToBranchProportionateFee   : '',
            mBranchAdjustmentFee        : ''
        },[]
    );

    const fnGetDataValue = (lDataObj, lDataField, lProperty) =>
    {
        var lRetValue = null;

        /**
         * Check is data field is constant
         */
        if(lDataField.startsWith('#') && lDataField.endsWith('#'))
        {
            if(lDataField === '#RECEIPT_HEADING#')
            {
                lRetValue = CSConfigUtil.mReceiptHeading[CSConfigUtil.mBranchCode];
            }
            else if(lDataField === '#RECEIPT_SUB_HEADING_1#')
            {
                lRetValue = CSConfigUtil.mReceiptSubHeading1[CSConfigUtil.mBranchCode];
            }
            else if(lDataField === '#RECEIPT_SUB_HEADING_2#')
            {
                lRetValue = CSConfigUtil.mReceiptSubHeading2[CSConfigUtil.mBranchCode];
            }
            else
            {
                lRetValue = cfGetConstantValue(lDataField);
            }
        }
        else if(lDataObj && lDataObj.hasOwnProperty(lDataField))
        {
            if(lProperty && (lProperty === 'payment_in_words_'))
            {
                var lValue = (Math.round(lDataObj[lDataField])).toFixed(0);
                lRetValue = fnConvertNumberToWords(lValue);
            }
            else
            {
                lRetValue = lDataObj[lDataField];
            }
        }
        else
        {
            lRetValue = lDataField;
        }

        return lRetValue;
    }

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                let lFormCode = null;
                let lFormData = null;
                if(lProps.hasOwnProperty('pSelectedActionItem'))
                {
                    lFormCode = lProps.pSelectedActionItem.navigate_to_form_;
                }

                if(lProps.pSelectedData && lProps.pSelectedData.hasOwnProperty('selected_data_') && lProps.pSelectedData.selected_data_)
                {
                    lFormData = lProps.pSelectedData.selected_data_;    
                }

                if(lProps && lProps.pFormProperties && lFormCode && lFormData)
                {
                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        if((lPropObj.cs_form_code_ === lFormCode) && (lPropObj.type_ === 'initialize'))
                        {
                            let lValue = fnGetDataValue(lFormData, lPropObj.value_, lPropObj.property_, lPropObj.type_);
                            if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_1_')
                            {
                                setFormSubTitle1(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_2_')
                            {
                                setFormSubTitle2(lValue);
                            }
                            else if (lPropObj.property_ === 'footer_')
                            {

                            }
                            else if(lPropObj.property_ === 'mAcademicYear')
                            {
								setStudentDetails(lPrevState => { return { ...lPrevState, mAcademicYear : lValue} });
                            }
                            else if(lPropObj.property_ === 'mAdmissionNo')
                            {
								setStudentDetails(lPrevState => { return { ...lPrevState, mAdmissionNo : lValue} });
                            }
                            else if(lPropObj.property_ === 'mLegacyNo')
                            {
								setStudentDetails(lPrevState => { return { ...lPrevState, mLegacyNo : lValue} });
                            }
                            else if(lPropObj.property_ === 'mStudentName')
                            {
								setStudentDetails(lPrevState => { return { ...lPrevState, mStudentName : lValue} });
                            }
                            else if(lPropObj.property_ === 'mParentName')
                            {
								setStudentDetails(lPrevState => { return { ...lPrevState, mParentName : lValue} });
                            }
                            else if(lPropObj.property_ === 'mClass')
                            {
								setStudentDetails(lPrevState => { return { ...lPrevState, mClass : lValue} });
                            }
                            else if(lPropObj.property_ === 'mSection')
                            {
								setStudentDetails(lPrevState => { return { ...lPrevState, mSection : lValue} });
                            }
							else if(lPropObj.property_ === 'mTransferNoteNo')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mTransferNoteNo : lValue} });                               
                            }
							else if(lPropObj.property_ === 'mInitiatedDate')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mInitiatedDate        : lValue} });
                            }
							else if(lPropObj.property_ === 'mInitiatedBy')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mInitiatedBy          : lValue} });
                            }
							else if(lPropObj.property_ === 'mSubmittedDate')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mSubmittedDate        : lValue} });
                            }
							else if(lPropObj.property_ === 'mTransferDate')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mTransferDate         : lValue} });
                            }
							else if(lPropObj.property_ === 'mSubmittedBy')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mSubmittedBy          : lValue} });
                            }
							else if(lPropObj.property_ === 'mTransferStatus')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mTransferStatus       : lValue} });
                            }
							else if(lPropObj.property_ === 'mTransferReason')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mTransferReason       : lValue} });
                            }
							else if(lPropObj.property_ === 'mFromBranchName')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mFromBranchName       : lValue} });
                            }
							else if(lPropObj.property_ === 'mToBranchName')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mToBranchName         : lValue} });
                            }
							else if(lPropObj.property_ === 'mFromBranchSection')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mFromBranchSection    : lValue} });
                            }
							else if(lPropObj.property_ === 'mToBranchSection')
                            {
								setTransferSummary(lPrevState => { return { ...lPrevState, mToBranchSection      : 'Section A'} });
                            }
							else if(lPropObj.property_ === 'mFromBranchErpFee')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mFromBranchErpFee           : lValue} });
                            }
                            else if(lPropObj.property_ === 'mFromBranchConcession')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mFromBranchConcession       : lValue} });
                            }
                            else if(lPropObj.property_ === 'mFromBranchNetFee')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mFromBranchNetFee           : lValue} });
                            }
                            else if(lPropObj.property_ === 'mFromBranchProportionateFee')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mFromBranchProportionateFee : lValue} });
                            }
                            else if(lPropObj.property_ === 'mToBranchErpFee')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mToBranchErpFee             : lValue} });
                            }
                            else if(lPropObj.property_ === 'mToBranchConcession')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mToBranchConcession         : lValue} });
                            }
                            else if(lPropObj.property_ === 'mToBranchNetFee')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mToBranchNetFee             : lValue} });
                            }
                            else if(lPropObj.property_ === 'mToBranchProportionateFee')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mToBranchProportionateFee   : lValue} });
                            }
                            else if(lPropObj.property_ === 'mBranchAdjustmentFee')
                            {
                                setFeeSummary(lPrevState => { return { ...lPrevState, mBranchAdjustmentFee        : lValue} });   
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, [lProps]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => lProps.fnProcessClose(false, true) }
        />
    );

    var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    const lForm = 
        <div id="trn_note" ref={mComponentRef}>
            <div className="trn_note overflow-auto">
                <div style={{minWidth: '750px'}}>
                    <main>
                        <div className="title">
                            <h1>{ mFormTitle }</h1>

                            <div className="top">
                                <div className="ins-name">
                                    { mInstituteHeading }
                                </div>

                                <div className="heading-copy">
                                    { mParentCopyHeading }
                                </div>
                            </div>

                            <h2>{ mFormSubTitle1 }</h2>

                            <h2>{ mFormSubTitle2 }</h2>
                        </div>

                        <div className="summary">
                            <div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mTransferDate}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Transfer Note No</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mTransferNoteNo}</div>
                                </div>

                                <div className="form-item-row">								
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Transfer Reason</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mTransferReason}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>From Branch</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mFromBranchName}</div>
                                </div>
								
                                <div className="form-item-row">								
                                    <div style={{width: '150px', fontWeight: 'bold'}}>To Branch</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mToBranchName}</div>
                                </div>
                            </div>
        
							<div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Student Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mStudentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Parent Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mParentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Class</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mClass}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>From Section</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mFromBranchSection}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>To Section</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mToBranchSection}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="summary">
							<div className="receipt-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>From Branch Erp Fee</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mFromBranchErpFee}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>From Branch Concession</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mFromBranchConcession}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>From Branch Net Fee</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mFromBranchNetFee}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>From Branch Proportionate Fee</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mFromBranchProportionateFee}</div>
                                </div>
                            </div>
        
							<div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>To Branch Erp Fee</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mToBranchErpFee}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>To Branch Concession</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mToBranchConcession}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>To Branch Net Fee</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mToBranchNetFee}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>To Branch Proportionate Fee</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mToBranchProportionateFee}</div>
                                </div>
                            </div>
							
                            <div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Initiated Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mInitiatedDate}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Initiated By</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mInitiatedBy}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Submitted Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mSubmittedDate}</div>
                                </div>
								
                                <div className="form-item-row">								
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Submitted By</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mTransferSummary.mSubmittedBy}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Adjustment Fee</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mFeeSummary.mBranchAdjustmentFee}</div>
                                </div>								
                            </div>							
                        </div>
                    </main>
                </div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            appendTo  = { document.body }
            header    = {lHeader}
            onHide    = { () => lProps.fnProcessClose(false, true) }>
                { lForm }
        </Dialog>
    );
}

export default CSTransferNote;
