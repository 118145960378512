import React from "react"
import { Pie } from 'react-chartjs-2';
import CSUtil from "../../util/CSUtil";

class CSCOPieChart extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mChartData              : {},
            mReportData             : this.props.pReportData,
            mReportChart            : (this.props.pReporChart) ? this.props.pReporChart[0] : null,
            mReportChartProperties  : this.props.pReportChartProperties,
            mDataCollection         : this.props.pDataCollection,
            mPropertyObjectMap      : null,
            mPropertyChildrenMap    : null,
        }

        this.cfGetChartData         = this.cfGetChartData.bind(this);
        this.cfSetChart             = this.cfSetChart.bind(this);

        if(this.props.pReportChartProperties)
        {
            var lIdChartDataSetObj = {}
            var llabels = [];

            this.state.mChartData.datasets = [];
            this.state.mChartData.labels = llabels;
            var lDataset1 = {}
            this.state.mChartData.datasets.push(lDataset1);

            CSUtil.cfSort(this.props.pReportChartProperties, 'parent_ref_id_', 'sequence_');
            for(var lInd=0; lInd<this.props.pReportChartProperties.length; lInd++)
            {
                var lProperty = this.props.pReportChartProperties[lInd];
                if(!this.state.mPropertyObjectMap)
                {
                    this.state.mPropertyObjectMap = {}
                }

                this.state.mPropertyObjectMap[lProperty.id_] = lProperty;

                if(lProperty.parent_ref_id_)
                {
                    if(!this.state.mPropertyChildrenMap)
                    {
                        this.state.mPropertyChildrenMap = {};
                    }

                    var lChildren = this.state.mPropertyChildrenMap[lProperty.parent_ref_id_];
                    if(!lChildren)
                    {
                        lChildren = [];
                        this.state.mPropertyChildrenMap[lProperty.parent_ref_id_] = lChildren;
                    }
                    
                    if(lProperty.property_name_ === 'sequence_')
                    {
                        lProperty.sequence_ = lProperty.value_;
                    }

                    lChildren.push(lProperty);
                    var lDataSetObj = lIdChartDataSetObj[lProperty.parent_ref_id_]

                    if(lDataSetObj)
                    {
                        if(lProperty.property_name_ === 'data_field_' ) 
                        {
                            if(!lDataset1.dataFieldList)
                            {
                                lDataset1.dataFieldList = [];   
                            }

                            lDataset1.dataFieldList.push(lProperty.value_);
                        }
                        else if(lProperty.property_name_ === 'background_color_' )
                        {
                            
                            if(!lDataset1.backgroundColor)
                            {
                                lDataset1.backgroundColor = [];
                            }

                            lDataset1.backgroundColor.push(lProperty.value_);
                        }
                        else if(lProperty.property_name_ === 'hover_background_color_')
                        {
                            if(!lDataset1.hoverBackgroundColor)
                            {
                                lDataset1.hoverBackgroundColor = [];
                            }

                            lDataset1.hoverBackgroundColor.push(lProperty.value_);
                        }
                        else if(lProperty.property_name_ === 'label_')
                        {
                            llabels.push(lProperty.value_);
                        }
                    }
                }
                else
                {
                    if(lProperty.property_name_ === 'pie_')
                    {
                        lIdChartDataSetObj[lProperty.id_] = {};
                    }
                }
            }
        }
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            var lPropertyObjectMap = null;
            var lPropertyChildrenMap = null;
            var lChartData = {}
            if(this.props.pReportChartProperties)
            {
                var lIdChartDataSetObj = {}
                var llabels = [];

                lChartData.datasets = [];
                lChartData.labels = llabels;
                var lDataset1 = {}
                lChartData.datasets.push(lDataset1);

                CSUtil.cfSort(this.props.pReportChartProperties, 'parent_ref_id_', 'sequence_');
                for(var lInd=0; lInd<this.props.pReportChartProperties.length; lInd++)
                {
                    var lProperty = this.props.pReportChartProperties[lInd];
                    if(!lPropertyObjectMap)
                    {
                        lPropertyObjectMap = {}
                    }

                    lPropertyObjectMap[lProperty.id_] = lProperty;

                    if(lProperty.parent_ref_id_)
                    {
                        if(!lPropertyChildrenMap)
                        {
                            lPropertyChildrenMap = {};
                        }

                        var lChildren = lPropertyChildrenMap[lProperty.parent_ref_id_];
                        if(!lChildren)
                        {
                            lChildren = [];
                            lPropertyChildrenMap[lProperty.parent_ref_id_] = lChildren;
                        }
                        
                        if(lProperty.property_name_ === 'sequence_')
                        {
                            lProperty.sequence_ = lProperty.value_;
                        }

                        lChildren.push(lProperty);
                        var lDataSetObj = lIdChartDataSetObj[lProperty.parent_ref_id_]

                        if(lDataSetObj)
                        {
                            if(lProperty.property_name_ === 'data_field_' ) 
                            {
                                if(!lDataset1.dataFieldList)
                                {
                                    lDataset1.dataFieldList = [];   
                                }

                                lDataset1.dataFieldList.push(lProperty.value_);
                            }
                            else if(lProperty.property_name_ === 'background_color_' )
                            {
                                
                                if(!lDataset1.backgroundColor)
                                {
                                    lDataset1.backgroundColor = [];
                                }

                                lDataset1.backgroundColor.push(lProperty.value_);
                            }
                            else if(lProperty.property_name_ === 'hover_background_color_')
                            {
                                if(!lDataset1.hoverBackgroundColor)
                                {
                                    lDataset1.hoverBackgroundColor = [];
                                }

                                lDataset1.hoverBackgroundColor.push(lProperty.value_);
                            }
                            else if(lProperty.property_name_ === 'label_')
                            {
                                llabels.push(lProperty.value_);
                            }
                        }
                    }
                    else
                    {
                        if(lProperty.property_name_ === 'pie_')
                        {
                            lIdChartDataSetObj[lProperty.id_] = {};
                        }
                    }
                }
            }

            if(lPropertyObjectMap && lPropertyChildrenMap)
            {
                this.setState(
                    {
                        mPropertyChildrenMap: lPropertyChildrenMap,
                        mPropertyObjectMap  : lPropertyObjectMap,
                        mChartData          : lChartData,
                        mDataCollection     : this.props.pDataCollection
                    }
                );
            }
            else
            {
                this.setState(
                    {
                        mDataCollection  : this.props.pDataCollection
                    }
                );
            }
        }
    }

    cfGetChartData()
    {
        var lChartData = this.state.mChartData;
        if(this.state.mDataCollection)
        {
            if(lChartData)
            {
                var lDatasets = lChartData.datasets;
                if(lDatasets)
                {
                    for(var lInd=0; lInd<this.state.mDataCollection.length; lInd++)
                    {
                        var lData = this.state.mDataCollection[lInd];
                        if(lDatasets.length >= (lInd + 1))
                        {
                            var lDatasetObj = lDatasets[lInd];
                            if(lDatasetObj.dataFieldList)
                            {
                                var lDataFieldList = lDatasetObj.dataFieldList;
                                for(var ldInd=0; ldInd<lDataFieldList.length; ldInd++)
                                {
                                    if(ldInd === 0)
                                    {
                                        lDatasetObj.data = [];
                                    }

                                    var lDataField = lDataFieldList[ldInd];
                                    if(lDataField)
                                    {
                                        var lDataValue = lData[lDataField];            
                                        lDatasetObj.data.push(parseInt(lDataValue, 10));
                                    }    
                                }
                            }
                        }
                    }
                }
            }
        }        

        return lChartData;
    }

    cfSetChart()
    {
        var lHeight     = (this.props.pIsDashboardReport) ? 82 : 115;
        var lChartData  = this.cfGetChartData();
        var lChart = 
            <Pie 
                data    = {lChartData}
                height  = {lHeight}
            />

        return (
            <div style=
                {
                    {
                        backgroundColor : "#ffffff", 
                        marginTop       : "5px", 
                        marginLeft      : "2px", 
                        marginRight     : "2px",
                        overflowY       : "auto",
                        padding         : (this.props.pIsDashboardReport) ? 10 : 20
                    }
                }>
                {lChart}
            </div>
        )
    }

    render()
    {
        return (this.cfSetChart());
    }
}

export default CSCOPieChart;