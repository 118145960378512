import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';

import CSCOGrid from "./CSCOGrid";
import CSUtil from "../util/CSUtil";
import CSFormUtil from "../util/CSFormUtil";
import { Button } from "primereact/button";

class CSCOColumnChooser extends Component
{
    mFormUtil = CSFormUtil.cfGetInstance();
    constructor(props) 
    {
        super(props);
        this.state =
            {
                mDisplay        : this.props.pShow,
                mFormHeading    : (this.props.pFormHeading) ? this.props.pFormHeading : "Choose Columns to Display",
                mGridColumns    : this.props.pFormGrid,
                mColumns        : null,
                mData           : null,
                fnProcessClose  : this.props.fnProcessClose
            };

        this.cfSave          = this.cfSave.bind(this);
        this.cfCloseForm     = this.cfCloseForm.bind(this);
        this.cfProcessClick  = this.cfProcessClick.bind(this);
    }

    componentDidMount() 
    {
        this.cfInitializeForm();
    }

    componentDidUpdate(lPrevProps, lPrevState) 
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeForm();
        }
    }

    cfInitializeForm()
    {
        var lColumnList = [];
        var lColumn0 = {};
        lColumn0.column_name_   = 'Select ?';
        lColumn0.data_field_    = 'select_';
        lColumn0.visible_       = '1';
        lColumn0.width_         = "30px";
        lColumn0.item_renderer_ = "CHK_BOX_RENDERER_1";
        lColumnList.push(lColumn0);

        var lColumn1 = {};
        lColumn1.column_name_   = 'Column';
        lColumn1.data_field_    = 'column_name_';
        lColumn1.visible_       = '1';
        lColumn1.width_         = "80px";
        lColumnList.push(lColumn1);

        var lData = []
        if(this.state.mGridColumns)
        {
            for(var lInd=0; lInd<this.state.mGridColumns.length; lInd++)
            {
                var lColumn = this.state.mGridColumns[lInd];
                if(lColumn.column_name_)
                {
                    var lDataObj = {};
                    lDataObj.select_      = lColumn.visible_;
                    lDataObj.column_name_ = lColumn.column_name_

                    lData.push(lDataObj);
                }
            }
        }

        this.setState(
            {
                mColumns : lColumnList,
                mData    : lData
            }
        );
    }

    cfCloseForm()
    {
        this.state.fnProcessClose();
    }

    cfProcessClick(lEvent){}

    cfSave(lEvent)
    {
        if(lEvent)
        {
            for(var lInd=0; lInd<this.state.mData.length; lInd++)
            {
                var lData = this.state.mData[lInd];
                for(var lInd0=0; lInd0<this.state.mGridColumns.length; lInd0++)
                {
                    var lgridColumn = this.state.mGridColumns[lInd0];
                    if(lgridColumn.column_name_ === lData.column_name_)
                    {
                        lgridColumn.visible_ = lData.select_;
                        break;
                    }
                }
            }

            this.props.fnProcessSave(this.state.mGridColumns);
            this.state.fnProcessClose();
        }
    }

    render() 
    {
        var lFormActionList = [];
        var lButton = 
            <Button
                style=
                    {
                        {
                            fontSize        : '13.2px', 
                            marginRight     : '.3em',
                            verticalAlign   : 'middle'
                        }
                    } 
                    key     = {1}
                    label   = "Save"
                    onClick = {this.cfSave}
                    icon    = {CSUtil.mNameIconMap['PI_CHECK']} 
                />
                
        lFormActionList.push(lButton);

        var lForm = 
            <Dialog visible={this.state.mDisplay}
                modal       = {true}
              
                maximizable = { false }
                appendTo    = { document.body }
                padding     = '5px'
                header      = { this.state.mFormHeading || '.' }
                onHide      = { () => this.cfCloseForm(true) }
                footer      = { <div style={{ height : '36px', margin : '3px', padding : '3px' }}> { lFormActionList } </div> }>

                <div style={{ height : '100%', width : '600px'}}>
                    <CSCOGrid
                        pData           = { this.state.mData }
                        pColumns        = { this.state.mColumns }
                        pColumnWidth    = "100px"
                        pageSize        = { 25 }
                        pScrollHeight   = { '100%' }
                        onClick         = { this.cfProcessClick }
                        pEnableScroll   = { true}
                        pWidth          = "600px"
                    />          
                </div>
            </Dialog>

        return lForm;
    }
}

export default CSCOColumnChooser;
