
import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { ProductService } from '../ProductService';

export default function CSCarousel() {
    const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };

    useEffect(() => {
        ProductService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
    }, []);

    const productTemplate = (product) => {
        return (
            <div className=" row border-1 surface-border border-round m-2  ">
                <div className=" flex">
                    <img src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`} alt={product.name} className="Carouselimg " />
                </div>
                <div className='p-2'>
                    <h4 className='m-auto p-1 '>{product.name}</h4>
                    <h4 className='m-auto p-1'>{product.description}</h4>
                    <h4 className='m-auto p-1'>{product.category}</h4>
                    <h4 className='m-auto p-1   '>{product.inventoryStatus}</h4>


                    
                  
                </div>
            </div>
        );
    };

    return (
        <div className="card">
            <Carousel value={products} numVisible={4} numScroll={4} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
        </div>
    )
}
        