import React, { Component } from "react"
import {Tree} from 'primereact/tree';
import {ScrollPanel} from 'primereact/scrollpanel';

import CSCOCriteria from "./CSCOCriteria";
import CSCOHorizontalActionBar from "./CSCOHorizontalActionBar"

import CSUtil from "../util/CSUtil"
import CSFormUtil from "../util/CSFormUtil"
import CSRequestUtil from "../util/CSRequestUtil"
import CSServiceUtil from "../util/CSServiceUtil";
import { cfGetConstantValue} from "../util/CSConstantUtil";
import { fnGetForm } from "../util/CSLayoutUtil"

import {Button} from 'primereact/button';


const fnTraverse = (lNode) => 
{
    var lArray = [];
    if(lNode)
    {
        for(var lInd=0; lInd<lNode.length; lInd++)
        {
            lArray.push(lNode[lInd]);
            if(lNode[lInd].hasOwnProperty('children'))
            {
                lArray = lArray.concat(fnTraverse(lNode[lInd].children));
            }
        }
    }

    return lArray;
}

class CSCOTreeSelection1 extends Component
{
    mFormUtil = CSFormUtil.cfGetInstance();
    mCSServiceUtil = CSServiceUtil.cfGetInstance();
    mKey = 1;

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mWidth                  : this.props.pWidth,
            mHeight                 : this.props.pHeight,

            mFormCode               : null,
            mFormData               : {},
            mFormProperties         : this.props.pFormProperties,
            mFormItems              : this.props.pFormItems,
            mFormIdItemMap          : null,
            mFormItemProperties     : this.props.pFormItemProperties,
            mFormActions            : this.props.pFormActions,
            mFormActionProperties   : this.props.pFormActionProperties,
            mFormCriteria           : this.props.pFormCriteria,
            mFormCriteriaProperties : this.props.pFormCriteriaProperties,

            mSourceHeading          : null,
            mSourceFormName         : null,
            mSourceCriteria         : null,
            mSourceCriteriaActions  : null,
            mSourceSearchActions    : null,
            mSourceCollectionItem   : null,
            mSourceData             : null,
            mSourceDisplayLabel     : null,
            mSelectedSourceNodes    : null,
            mIdItemObjectMap        : {},
            mFeatureCodeObjectMap      : {},

            mTargetHeading          : null,
            mTargetFormName         : null,
            mTargetCriteria         : null,
            mTargetCriteriaActions  : null,
            mTargetSearchActions    : null,
            mTargetCollectionItem   : null,
            mTargetData             : (this.props.pTargetData) ? this.props.pTargetData : [],
            mTargetDisplayLabel     : null,
            mSelectedTargetNodes    : null,
            mTargetIdItemObjectMap  : {},
            fnProcessChange         : this.props.fnProcessChange
        }

        this.process_search         = this.process_search.bind(this);
        this.process_add            = this.process_add.bind(this);
        this.process_delete         = this.process_delete.bind(this);

        this.cfInitializeData       = this.cfInitializeData.bind(this);
        this.cfProcessChange        = this.cfProcessChange.bind(this);
        this.cfSetControlValue      = this.cfSetControlValue.bind(this);
        this.cfSetSourceTreeData    = this.cfSetSourceTreeData.bind(this);
        this.cfAddFeature           = this.cfAddFeature.bind(this);
    }

    componentDidMount()
    {
        this.cfInitializeData();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeData();
        }
    }

    cfInitializeData()
    {
        var lSourceHeading;
        var lSourceCollectionItem;
        var lSourceDisplayLabel;
        var lTargetHeading;
        var lTargetCollectionItem;
        var lTargetDisplayLabel;

        /*
         * Initialize Source & Target
         */
        if(this.state.mFormItems)
        {
            for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
            {
                var lFObj = this.state.mFormItems[lInd];
                if(lFObj.hasOwnProperty('cs_form_item_properties_') && lFObj.cs_form_item_properties_)
                {
                    for(var lFipInd=0; lFipInd<lFObj.cs_form_item_properties_.length; lFipInd++)
                    {
                        var lFipObj = lFObj.cs_form_item_properties_[lFipInd];
                        if(lFipObj.value_ === 'true')
                        {
                            switch(lFipObj.property_)
                            {
                                case 'source_collection':
                                    lSourceCollectionItem = lFObj.data_field_;
                                    lSourceDisplayLabel = lFipObj.selection_value_;
                                break;

                                case 'source_heading':
                                    lSourceHeading = lFObj.display_label_;
                                break;

                                case 'target_collection':
                                    lTargetCollectionItem = lFObj.data_field_;
                                    lTargetDisplayLabel = lFipObj.selection_value_;
                                break;

                                case 'target_heading':
                                    lTargetHeading = lFObj.display_label_;
                                break;

                                default:
                                break;
                            }
                        }
                    }
                }
            }
        }

        var lSourceCriteria;
        var lTargetCriteria;
        if(this.state.mFormCriteria)
        {
            for(var lFcInd=0; lFcInd<this.state.mFormCriteria.length; lFcInd++)
            {
                var lFcObj = this.state.mFormCriteria[lFcInd];
                if(lFcObj && lFcObj.hasOwnProperty('cs_form_criteria_properties_'))
                {
                    for(var lFcpInd=0; lFcpInd<lFcObj.cs_form_criteria_properties_.length; lFcpInd++)
                    {
                        var lFcrpObj = lFcObj.cs_form_criteria_properties_[lFcpInd];
                        if((lFcrpObj.property_ === 'source_criteria') && (lFcrpObj.value_ === 'true'))
                        {
                            if(!lSourceCriteria)
                            {
                                lSourceCriteria = [];
                            }

                            lSourceCriteria.push(lFcObj);
                        }
                        else if((lFcrpObj.property_ === 'target_criteria') && (lFcrpObj.value_ === 'true'))
                        {
                            if(!lTargetCriteria)
                            {
                                lTargetCriteria = [];
                            }

                            lTargetCriteria.push(lFcObj);
                        }
                    }
                }
            }
        }

        var lSourceCriteriaActions;
        var lTargetCriteriaActions;
        var lSourceSearchActions;
        var lTargetSearchActions;
        if(this.state.mFormActions)
        {
            for(var lFaInd=0; lFaInd<this.state.mFormActions.length; lFaInd++)
            {
                var lFaObj = this.state.mFormActions[lFaInd];
                lFaObj.mEnabled = false;
                if(lFaObj.enabled_ === '1')
                {
                    lFaObj.mEnabled = true;
                }

                if(lFaObj && lFaObj.hasOwnProperty('cs_form_action_properties_'))
                {
                    for(var lFapInd=0; lFapInd<lFaObj.cs_form_action_properties_.length; lFapInd++)
                    {
                        var lFapObj = lFaObj.cs_form_action_properties_[lFapInd];
                        if((lFapObj.property_ === 'source_criteria_action') && (lFapObj.value_ === 'true'))
                        {
                            if(!lSourceCriteriaActions)
                            {
                                lSourceCriteriaActions = [];
                            }

                            lSourceCriteriaActions.push(lFaObj);
                        }
                        else if((lFapObj.property_ === 'target_criteria_action') && (lFapObj.value_ === 'true'))
                        {
                            if(!lTargetCriteriaActions)
                            {
                                lTargetCriteriaActions = [];
                            }

                            lTargetCriteriaActions.push(lFaObj);
                        }
                        else if((lFapObj.property_ === 'source_search_action') && (lFapObj.value_ === 'true'))
                        {
                            if(!lSourceSearchActions)
                            {
                                lSourceSearchActions = [];
                            }

                            lSourceSearchActions.push(lFaObj);
                        }
                        else if((lFapObj.property_ === 'target_search_action') && (lFapObj.value_ === 'true'))
                        {
                            if(!lTargetSearchActions)
                            {
                                lTargetSearchActions = [];
                            }

                            lFaObj.enabled_ = '1';
                            lFaObj.enabled = true;
                            lTargetSearchActions.push(lFaObj);
                        }
                    }
                }
            }
        }

        var lTargetTreeData = this.cfGetTargetTreeData(this.props.pTargetData, this.state.mIdItemObjectMap);

        this.setState(
            {
                mSourceCollectionItem   : lSourceCollectionItem,
                mSourceHeading          : lSourceHeading,
                mTargetCollectionItem   : lTargetCollectionItem,
                mTargetHeading          : lTargetHeading,
                mSourceCriteria         : lSourceCriteria,
                mTargetCriteria         : lTargetCriteria,
                mSourceCriteriaActions  : lSourceCriteriaActions,
                mTargetCriteriaActions  : lTargetCriteriaActions,
                mTargetData             : lTargetTreeData,
                mSourceSearchActions    : lSourceSearchActions,
                mTargetSearchActions    : lTargetSearchActions,
                mSourceDisplayLabel     : lSourceDisplayLabel,
                mTargetDisplayLabel     : lTargetDisplayLabel
            }
        );
    }

    process_search(lCriteria, lActionItem)
    {
        this.setState({mCriteria : lCriteria, mActionItem : lActionItem});
        if(lActionItem && lActionItem.on_click_service_code_)
        {
            this.cfSearch(lActionItem.on_click_service_code_, lCriteria);
        }
    }

    cfSearch(lServiceCode, lCriteria)
    {
        if(lServiceCode)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }

                if(this.state.mSelectedCriteria)
                {
                    var lSelCritKeys = Object.keys(this.state.mSelectedCriteria);
                    var lSelCritValues = Object.values(this.state.mSelectedCriteria);
                    if(lSelCritKeys && (lSelCritKeys.length > 0))
                    {
                        for(var lKeyInd=0; lKeyInd<lSelCritKeys.length; lKeyInd++)
                        {
                            lCriteriaObj[lSelCritKeys[lKeyInd]] = lSelCritValues[lKeyInd];
                        }
                    }
                }

                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                    {
                        if(lResponse)
                        {
                            var lCollection = [];
                            if((lResponse) && lResponse.hasOwnProperty("collection_"))
                            {
                                lCollection = lResponse.collection_;
                            }
                    
                            this.cfSetSourceTreeData(lCollection);
                        }
                    }
                );
            }
        }
    }

    cfProcessChange(lData)
    {
        if(this.state.fnProcessChange)
        {
            var lDataObj = {};
            lDataObj.target_data_ = lData;

            this.state.fnProcessChange(lDataObj);
        }
    }

    cfSetControlValue()
    {
    }

    cfGetTargetTreeData(lTgtData, lIdItemObjectMap)
    {
        var lTargetTreeData = [];
        if(lTgtData)
        {
            var lTargetData = fnTraverse(lTgtData);
            if(lTargetData)
            {
                if(!lIdItemObjectMap)
                {
                    lIdItemObjectMap = this.state.mIdItemObjectMap;
                }

                var lFeatureCodeObjectMap = this.state.mFeatureCodeObjectMap;
                
                if(!lIdItemObjectMap)
                {
                    lIdItemObjectMap = {}
                }

                CSUtil.cfSort(lTargetData, 'parent_feature_');
                for(var lCInd=0; lCInd<lTargetData.length; lCInd++)
                {
                    var lItem = lTargetData[lCInd];
                    lItem.key = this.mKey;
                    this.mKey++;
                    lItem.label = lItem[this.state.mTargetDisplayLabel];
                    lIdItemObjectMap[this.mKey] = lItem;
                    if(!lItem.parent_feature_)
                    {
                        lTargetTreeData.push(lItem);
                        var lPitem = lFeatureCodeObjectMap[lItem.feature_code_];
                        if(lPitem && lPitem.hasOwnProperty('children'))
                        {
                            for(var lPInd=0; lPInd<lPitem.children.length; lPInd++)
                            {
                                var lPObj = lPitem.children[lPInd];
                                if(lPInd === 0)
                                {
                                    lItem.children = [];
                                }

                                if(lTargetData.indexOf(lPObj) !== -1)
                                {
                                    lItem.children.push(lPObj);
                                }
                            }
                        }

                        lFeatureCodeObjectMap[lItem.feature_code_] = lItem;
                    }
                    else
                    {
                        if(lItem.children)
                        {
                            for(var lCInd1=0; lCInd1<lItem.children.length; lCInd1++)
                            {
                                var lCObj = lItem.children[lCInd1];
                                if(lCInd1 === 0)
                                {
                                    lItem.children = [];
                                }

                                if(lTargetData.indexOf(lCObj) !== -1)
                                {
                                    lItem.children.push(lCObj);
                                }
                            }
                        }
                        
                        lFeatureCodeObjectMap[lItem.feature_code_] = lItem;
                        var lParentItem = lFeatureCodeObjectMap[lItem.parent_feature_];
                        if(lParentItem)
                        {
                            if(!lParentItem.hasOwnProperty('children'))
                            {
                                lParentItem.children = [];
                            }
                        }
                        else
                        {
                            lParentItem = {}
                            lParentItem.children = [];
                            lFeatureCodeObjectMap[lItem.parent_feature_] = lParentItem;
                        }

                        if(lParentItem.children.indexOf(lItem) === -1)
                        {
                            lParentItem.children.push(lItem);
                        }
                    }
                }
            }
        }

        return lTargetTreeData;
    }

    cfSetSourceTreeData(lCollection)
    {
        var lIdItemObjectMap = {};
        if(lCollection && this.state.mSourceDisplayLabel)
        {
            var lSourceTreeData = [];
            for(var lCInd=0; lCInd<lCollection.length; lCInd++)
            {
                var lItem = lCollection[lCInd];
                lIdItemObjectMap[this.mKey] = lItem;
                lItem.key = this.mKey;
                lItem.label = lItem[this.state.mSourceDisplayLabel];
                if(!this.state.mTargetData || (this.state.mTargetData && !this.state.mTargetIdItemObjectMap[this.mKey]))
                {
                    lSourceTreeData.push(lItem);
                }

                this.mKey++;
            }

            this.setState(
                {
                    mSourceData         : lSourceTreeData,
                    mIdItemObjectMap    : lIdItemObjectMap
                }
            );
        }
    }

    cfAddFeature(event)
    {
        var lTargetIdObjMap = this.state.mTargetIdItemObjectMap;
        var lTargetItems    = this.state.mTargetData;
        
        if(this.state.mSelectedSourceNodes && this.state.mSourceData)
        {
            for(var lInd0=0; lInd0<this.state.mSourceData.length; lInd0++)
            {
                var lDataObj = this.state.mSourceData[lInd0];
                if(lDataObj.hasOwnProperty('key') && lDataObj.key)
                {
                    var lSelObj = this.state.mSelectedSourceNodes[lDataObj.key];
                    if(lSelObj)
                    {
                        if(!lTargetItems)
                        {
                            lTargetItems = [];
                        }

                        if(!lTargetIdObjMap)
                        {
                            lTargetIdObjMap = {}
                        }

                        lDataObj.key = lTargetItems.length + 1;
                        lTargetIdObjMap[lDataObj.key] = lDataObj;
                        lTargetItems.push(lDataObj);
                    }
                }
            }
        }

        this.setState(
            {
                mSelectedSourceNodes    : null,
                mSelectedTargetNodes    : null,
                mTargetData             : CSUtil.cfGetObjectCopy(lTargetItems),
                mTargetIdItemObjectMap  : lTargetIdObjMap
            }
        );

        this.cfProcessChange(lTargetItems);
    }

    process_add(event)
    {
        console.log(event);
    }

    process_delete(event)
    {
        if(this.state.mSelectedTargetNodes && this.props.pTargetData)
        {
            this.cfRemoveFeature(this.props.pTargetData);
        }

        this.setState(
            {
                mSelectedTargetNodes: null,
                mTargetData         : this.props.pTargetData
            }
        );

        this.cfProcessChange(this.props.pTargetData);
    }

    cfRemoveFeature(lTargetData)
    {
        if(lTargetData)
        {
            for(let lInd0=0; lInd0<lTargetData.length; lInd0++)
            {
                let lDataObj = lTargetData[lInd0];
                var lSelObj = this.state.mSelectedTargetNodes[lTargetData[lInd0].key];
                if(lSelObj && lSelObj.checked)
                {
                    const lIndex = lTargetData.findIndex((lObj) => lObj.key === lDataObj.key);
                    if(lIndex !== -1)
                    {
                        lTargetData.splice(lIndex, 1);
                        lInd0 = 0;
                    }
                }
                else if(lSelObj && lSelObj.partialChecked)
                {
                    this.cfRemoveFeature(lDataObj.children);
                }
            }
        }
    }

    render() 
    {
        var lSourceCriteria = null;

        if(this.state.mSourceCriteria && (this.state.mSourceCriteria.length > 0))
        {
            lSourceCriteria = 
                <div style={{paddingBottom: '2px', paddingTop: '2px', width : '100%'}}>
                    <CSCOCriteria
                        pCriteria       = {this.state.mSourceCriteria}
                        pFormActions    = {this.state.mSourceCriteriaActions}
                        pFormProperties = {this.state.mFormProperties}
                        pParent         = {this}
                    />
                </div>
        }

        var lSourceHeader = null;
        if(this.state.mSourceHeading)
        {
            lSourceHeader = 
                <div className="card card-w-heading">
                    <h3>
                        { this.state.mSourceHeading }
                    </h3>
                </div>
        }

        var lTargetHeader = null;
        if(this.state.mTargetHeading)
        {
            lTargetHeader = 
                <div className="card card-w-heading">
                    <h3>
                    { this.state.mTargetHeading }
                    </h3>
                </div>
        }

        var lTargetCriteria = null;
        if(this.state.mTargetCriteria && (this.state.mTargetCriteria.length > 0))
        {
            lTargetCriteria = 
                <CSCOCriteria
                    pCriteria       = {this.state.mTargetCriteria}
                    pFormActions    = {this.state.mTargetCriteriaActions}
                    pFormProperties = {this.state.mFormProperties}
                    pParent         = {this}
                />
        }

        var lTargetSearchActions = null;
        if(this.state.mTargetSearchActions)
        {
            lTargetSearchActions = 
                <div style={{paddingBottom: '10px', paddingTop: '10px', height : '50px'}}>
                    <CSCOHorizontalActionBar
                        align       = "left"
                        pType       = 'SEARCH_ACTION_BAR'
                        parent      = { this }
                        actionList  = { this.state.mTargetSearchActions }
                    />
                </div>
        }

        this.cfSetControlValue();
        var lDetailsForm = null;
        if(this.state.mDetailsFlag)
        {
            if(this.state.mAddFlag)
            {
                if(this.state.mDetailsMetaData && this.state.mDetailsMetaData.hasOwnProperty('pFormType'))
                {
                    lDetailsForm = fnGetForm(this.state.mDetailsMetaData, null, this.cfCloseForm);
                }
            }
        }

        return(
            <>
                <div
                    align="center" 
                    style=
                    {
                        {
                            background      : '#FEFEFE', 
                            marginTop       : '8px', 
                            marginLeft      : '1px', 
                            marginRight     : '1px', 
                            marginBottom    : '3px'
                        }
                    }>
                    
                    <div className="p-grid" style={{alignItems: 'center'}}>
                        <div className="p-col-5" align="center" style={{paddingLeft : '10px', paddingRight : '10px'}}>
                            { lSourceHeader }

                            { lSourceCriteria }                        

                            <ScrollPanel className='tree-selection-scroll-panel'>
                                <Tree
                                    value               = {this.state.mSourceData}
                                    dragdropScope       = "demo"
                                    selectionMode       = "checkbox"
                                    selectionKeys       = {this.state.mSelectedSourceNodes}
                                    onSelectionChange   = {event => this.setState({mSelectedSourceNodes : event.value})}
                                    onDragDrop          = {event => this.setState({mSourceData: event.value})} />
                            </ScrollPanel>
                        </div>
                    
                        <div className="p-col-2" align="center" style={{paddingLeft : '20px', height: '100%', paddingRight : '20px', verticalAlign: 'middle'}}>
                            <Button
                                icon={CSUtil.mNameIconMap['PI_CARET_RIGHT']} 
                                style=
                                {
                                    {
                                        fontSize        : '13.2px', 
                                        marginRight     : '.25em', 
                                        verticalAlign   : 'middle',
                                        width           : '80px',
                                        height          : '36px'
                                    }
                                }
                                key         = '1'
                                id          = '1'
                                onClick     = 
                                {
                                    (event) => 
                                    {
                                        this.cfAddFeature(event);
                                    }
                                }
                            />
                        </div>

                        <div className="p-col-5" align="center" style={{paddingLeft : '20px', paddingRight : '20px'}}>
                            { lTargetHeader }

                            { lTargetCriteria }

                            { lTargetSearchActions }

                            <ScrollPanel className='tree-selection-scroll-panel'>
                                <Tree
                                    value               = {this.state.mTargetData}
                                    dragdropScope       = "demo"
                                    selectionMode       = "checkbox"
                                    selectionKeys       = {this.state.mSelectedTargetNodes}
                                    onSelectionChange   = {event => this.setState({mSelectedTargetNodes : event.value})}
                                    onDragDrop          = {event => this.setState({mTargetData: event.value})} />
                            </ScrollPanel>
                        </div>
                    </div>
                </div>

                { lDetailsForm }
            </>
        );
    }
}

export default CSCOTreeSelection1;
