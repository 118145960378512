import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import CSConfigUtil from '../util/CSConfigUtil'; 
import CSServiceUtil from '../util/CSServiceUtil'; 
import CSRequestUtil from '../util/CSRequestUtil'; 
import CSUtil from "../util/CSUtil";

const mServiceUtil = CSServiceUtil.cfGetInstance();

const CSMarkSheetForm1 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const [ mFormTitle, setFormTitle ] = useState('Marks Sheet');
    const [ mHeaderTitle, setHeaderTitle ] = useState('Gowtham Model Schools');

    const [ mStudentDetails, setStudentDetails ] = useState(
        {
            mTitle          : 'Student Details',
            mCompanyLogo    : CSConfigUtil.mCompanyLogo,
            mCustomerName   : '', 
            mCustomerAddress: '', 
            mDeliveryAddress: '', 
            mContactNumber  : '', 
            mPlaceOfSupply  : '', 
            mGstNo          : '',
            mPhoneNumber    : '',
            mEmail          : '',
        }, []
    );

    const [ mMarksDetails, setMarksDetails ] = useState(
        {
            mTitle  : 'Marks Details',
            mColumn1: 'SNo', 
            mColumn2: 'Subject', 
            mColumn3: 'Max Marks', 
            mColumn4: 'Marks Secured', 
            mColumn5: 'Grade'
        }, [lProps]
    );

    const [ mTop1Section1, setTop1Section1 ] = useState({}, []);
    const [ mTop1Section2, setTop1Section2 ] = useState({}, []);
    const [ mMarksHeader, setMarksHeader ] = useState(null);
    const [ mMarksContents, setMarksContents ] = useState(null);

    const [ mServiceCode, setServiceCode] = useState(null);
    const [ mRequestId, setRequestId] = useState(null);

    const [ mFooter, setFooter ] = useState("Marks Sheet was created on a computer and is valid without the signature and seal.");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pFormProperties)
                {
                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        const lValue = lPropObj.value_;
                        if(lPropObj.type_ === 'initialize')
                        {
                            if(lPropObj.property_ === 'header_title_')
                            {
                                setHeaderTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'student_details_title_')
                            {
                                setStudentDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'marks_details_title_')
                            {
                                setMarksDetails(lPrevState => { return { ...lPrevState, mTitle : lValue } });
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lValue);
                            }
                            else if(lPropObj.property_ === 'logo_')
                            {
                                setStudentDetails(lPrevState => { return { ...lPrevState, mCompanyLogo : lValue } });
                            }
                        }

                        if(lPropObj.type_ === 'header')
                        {
                            if(lPropObj.property_ === 'section_1_label_column_1_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_1_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_1_label_column_2_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_2_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_1_label_column_3_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_3_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_1_label_column_4_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_4_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_1_data_field_column_1_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_1_data_field_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_1_data_field_column_2_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_2_data_field_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_1_data_field_column_3_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_3_data_field_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_1_data_field_column_4_')
                            {
                                setTop1Section1(lPrevState => { return { ...lPrevState, column_4_data_field_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_label_column_1_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_1_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_label_column_2_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_2_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_label_column_3_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_3_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_label_column_4_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_4_label_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_data_field_column_1_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_1_data_field_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_data_field_column_2_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_2_data_field_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_data_field_column_3_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_3_data_field_ : lValue } });
                            }
                            else if(lPropObj.property_ === 'section_2_data_field_column_4_')
                            {
                                setTop1Section2(lPrevState => { return { ...lPrevState, column_4_data_field_ : lValue } });
                            }
                        }
                    }

                    if (lProps.pCriteria && lProps.pSelectedActionItem)
                    {
                        setRequestId(lProps.pSelectedActionItem.request_id_);
                        setServiceCode(lProps.pSelectedActionItem.on_click_service_code_);
                    }
                }
            }

            fnInitializeForm();
        }, [lProps, setMarksDetails, setStudentDetails]
    );

    useEffect(() =>
        {
            const fnInitializeData = () =>
            {
                if(mServiceCode && lProps.pCriteria)
                {
                    var lServiceObject = mServiceUtil.cfGetServiceObject(mServiceCode);
                    if(lServiceObject)
                    {
                        var lCriteria = lProps.pCriteria;
                        lCriteria.office_code_ = CSConfigUtil.mOfficeCode;
                        var lRequestUtil = new CSRequestUtil('GET', mServiceCode, lServiceObject.type_, mRequestId, lServiceObject);
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse) =>
                            {
                                if(lResponse)
                                {
                                    if(lResponse.hasOwnProperty('student_marks_header_') && (lResponse.student_marks_header_.length > 0))
                                    {
                                        var lStudentMarksHeader = lResponse.student_marks_header_[0];
                                        setMarksHeader(lStudentMarksHeader);
                                    }

                                    if(lResponse.hasOwnProperty('student_marks_') && (lResponse.student_marks_.length > 0))
                                    {
                                        var lStudentMarksList = [];
                                        var lpcInd = 0;
                                        for(var lpInd=0; lpInd<lResponse.student_marks_.length; lpInd++)
                                        {
                                            var lMarksObj = lResponse.student_marks_[lpInd];
                                            var lMarksColumn = [];

                                            lMarksColumn.push(<td key={lpcInd++}>{lMarksObj.sno_}</td>);
                                            lMarksColumn.push(<td key={lpcInd++}>{lMarksObj.subject_name_}</td>);
                                            lMarksColumn.push(<td key={lpcInd++}>{lMarksObj.max_marks_}</td>);
                                            lMarksColumn.push(<td key={lpcInd++}>{lMarksObj.marks_}</td>);
                                            lMarksColumn.push(<td key={lpcInd++}>{lMarksObj.grade_}</td>);
                    
                                            lStudentMarksList.push(<tr key={lpInd + 1}>{lMarksColumn}</tr>);
                                        }
                    
                                        setMarksContents(lStudentMarksList);
                                    }
                                }
                            }
                        );
                    }
                }
            }

            fnInitializeData();
        }, [mServiceCode, mRequestId, lProps.pCriteria]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => (lProps.fnClose) ? lProps.fnClose(false) : null }
        />
    );

    let lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    /**
     * Top Section 1
     * Column 1
     */
    let lTop1Section1Column1 = null;
    if(mTop1Section1 && mTop1Section1.hasOwnProperty('column_1_label_'))
    {
        let lLabel = mTop1Section1.column_1_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section1.hasOwnProperty('column_1_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section1.column_1_data_field_))
        {
            lData = mMarksHeader[mTop1Section1.column_1_data_field_];
        }

        lTop1Section1Column1 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    /**
     * Top Section 1
     * Column 2
     */
    let lTop1Section1Column2 = null;
    if(mTop1Section1 && mTop1Section1.hasOwnProperty('column_2_label_'))
    {
        let lLabel = mTop1Section1.column_2_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section1.hasOwnProperty('column_2_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section1.column_2_data_field_))
        {
            lData = mMarksHeader[mTop1Section1.column_2_data_field_];
        }

        lTop1Section1Column2 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    /**
     * Top Section 1
     * Column 3
     */
    let lTop1Section1Column3 = null;
    if(mTop1Section1 && mTop1Section1.hasOwnProperty('column_3_label_'))
    {
        let lLabel = mTop1Section1.column_3_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section1.hasOwnProperty('column_3_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section1.column_3_data_field_))
        {
            lData = mMarksHeader[mTop1Section1.column_3_data_field_];
        }
        
        lTop1Section1Column3 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    /**
     * Top Section 1
     * Column 4
     */
    let lTop1Section1Column4 = null;
    if(mTop1Section1 && mTop1Section1.hasOwnProperty('column_4_label_'))
    {
        let lLabel = mTop1Section1.column_4_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section1.hasOwnProperty('column_4_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section1.column_4_data_field_))
        {
            lData = mMarksHeader[mTop1Section1.column_4_data_field_];
        }

        lTop1Section1Column4 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    /**
     * Top Section 2
     * Column 1
     */
    let lTop1Section2Column1 = null;
    if(mTop1Section2 && mTop1Section2.hasOwnProperty('column_1_label_'))
    {
        let lLabel = mTop1Section2.column_1_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section2.hasOwnProperty('column_1_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section2.column_1_data_field_))
        {
            lData = mMarksHeader[mTop1Section2.column_1_data_field_];
        }

        lTop1Section2Column1 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    /**
     * Top Section 2
     * Column 2
     */
    let lTop1Section2Column2 = null;
    if(mTop1Section2 && mTop1Section2.hasOwnProperty('column_2_label_'))
    {
        let lLabel = mTop1Section2.column_2_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section2.hasOwnProperty('column_2_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section2.column_2_data_field_))
        {
            lData = mMarksHeader[mTop1Section2.column_2_data_field_];
        }

        lTop1Section2Column2 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    /**
     * Top Section 2
     * Column 3
     */
    let lTop1Section2Column3 = null;
    if(mTop1Section2 && mTop1Section2.hasOwnProperty('column_3_label_'))
    {
        let lLabel = mTop1Section2.column_3_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section2.hasOwnProperty('column_3_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section2.column_3_data_field_))
        {
            lData = mMarksHeader[mTop1Section2.column_3_data_field_];
        }

        lTop1Section2Column3 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    /**
     * Top Section 2
     * Column 4
     */
    let lTop1Section2Column4 = null;
    if(mTop1Section2 && mTop1Section2.hasOwnProperty('column_4_label_'))
    {
        let lLabel = mTop1Section2.column_4_label_;
        let lData  = '';
        if(mMarksHeader && mTop1Section2.hasOwnProperty('column_4_data_field_') && mMarksHeader.hasOwnProperty(mTop1Section2.column_4_data_field_))
        {
            lData = mMarksHeader[mTop1Section2.column_4_data_field_];
        }

        lTop1Section2Column4 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lLabel }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lData }</div>
            </div>
    }

    const lTop1 = 
        <div className="summary">
            <div className="summary-1">
                { lTop1Section1Column1 }
                { lTop1Section1Column2 }
                { lTop1Section1Column3 }
                { lTop1Section1Column4 }
            </div>

            <div className="summary-2">
                { lTop1Section2Column1 }
                { lTop1Section2Column2 }
                { lTop1Section2Column3 }
                { lTop1Section2Column4 }
            </div>
        </div>

    const lForm = 
        <div id="dc" ref={mComponentRef}>
            <div className="ms overflow-auto">
                <div style={{minWidth: '600px'}}>
                    <header>
                        <div>
                            <div className="logo">
                                <img
                                    src={ mStudentDetails.mCompanyLogo } 
                                    width="100%"
                                    alt=""/>
                            </div>
                            
                            <div className="header-heading">
                                <h2>{ mHeaderTitle }</h2>
                            </div>
                        </div>
                    </header>

                    <main>
                        <div className="marks-details">                   
                            { lTop1 }
                        </div>

                        <>
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th colSpan="5">{ mMarksDetails.mTitle }</th>
                                    </tr>
                                    <tr>
                                        <th>{ mMarksDetails.mColumn1 }</th>
                                        <th>{ mMarksDetails.mColumn2 }</th>
                                        <th>{ mMarksDetails.mColumn3 }</th>
                                        <th>{ mMarksDetails.mColumn4 }</th>
                                        <th>{ mMarksDetails.mColumn5 }</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {mMarksContents}
                                </tbody>
                            </table>
                        </>
                    </main>

                    <footer>
                        { mFooter }
                    </footer>
                </div>

                <div></div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            header    = {lHeader}
            appendTo  = { document.body }
            onHide    = { () => (lProps.fnClose) ? lProps.fnClose() : null }>
                { lForm }
        </Dialog>
    );
}

export default CSMarkSheetForm1;