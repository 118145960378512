import React from 'react';

import CSControl from '../controls/CSControl'
import CSCOVerticalSection from '../components/CSCOVerticalSection'
import {Button} from 'primereact/button';
import CSUtil from '../util/CSUtil';
import CSServiceUtil from '../util/CSServiceUtil';
import CSFormUtil from '../util/CSFormUtil';
import { cfGetConstantValue } from "../util/CSConstantUtil";

class CSCOCriteria extends React.Component
{
    mServiceUtil = CSServiceUtil.cfGetInstance();
    constructor(props)
    {
        super(props);

        var lFormCode = null;
        var lNumSections = 3;
        if(this.props.pCriteria)
        {
            if(this.props.pCriteria.length > 0)
            {
                var lCriteriaObj = this.props.pCriteria[0];
                if(lCriteriaObj !== null)
                {
                    lFormCode = lCriteriaObj.cs_form_code_;
                }

                for(var lcInd=0; lcInd<this.props.pCriteria.length; lcInd++)
                {
                    var lCriteria = this.props.pCriteria[lcInd];
                    if(lCriteria.hasOwnProperty('cs_form_criteria_properties_'))
                    {
                        var lfcProperties = lCriteria.cs_form_criteria_properties_;
                        for(var lfcpInd=0; lfcpInd<lfcProperties.length; lfcpInd++)
                        {
                            var lfcProperty = lfcProperties[lfcpInd];
                            if((lfcProperty.type_ === 'other') && (lfcProperty.property_ === 'include'))
                            {
                                var lSelectionProperty = cfGetConstantValue(lfcProperty.selection_property_);
                                if(lSelectionProperty && lfcProperty.selection_value_)
                                {
                                    if(lSelectionProperty === lfcProperty.selection_value_)
                                    {
                                        if(lfcProperty.value_ === 'true')
                                        {
                                            lCriteria.include_ = '1';
                                            lCriteria.width_ = '-1';
                                        }
                                        else if(lfcProperty.value_ === 'false')
                                        {
                                            lCriteria.include_ = '0';
                                            lCriteria.width_ = '0';
                                            lCriteria.sequence_ = '0';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if(this.props.pFormProperties)
        {
            for(var lfpInd=0; lfpInd<this.props.pFormProperties.length; lfpInd++)
            {
                var lProperty =  this.props.pFormProperties[lfpInd];
                if(lProperty.cs_form_code_ === lFormCode)
                {
                    if((lProperty.type_ === 'layout') && (lProperty.property_ === 'max_sections_'))
                    {
                        lNumSections = Number(lProperty.value_);
                    }
                }
            }
        }
    
        this.state =
        {
            mCriteriaList   : this.props.pCriteria,
            mFormActions    : this.props.pFormActions,
            mNumSections    : lNumSections,
            mFormCode       : lFormCode,
            mParent         : this.props.pParent,
            mFormData       : {}            
        }

        this.cfGetDefaultCriteriaData      = this.cfGetDefaultCriteriaData.bind(this);
        this.cfGetCriteriaObject    = this.cfGetCriteriaObject.bind(this);
        this.cfGetCriteriaSections  = this.cfGetCriteriaSections.bind(this);
        this.cfGetCriteriaActions   = this.cfGetCriteriaActions.bind(this);
        this.cfProcessSearchAction  = this.cfProcessSearchAction.bind(this);
        this.cfProcessSelection     = this.cfProcessSelection.bind(this);
        this.cfSetControlValue      = this.cfSetControlValue.bind(this);
    }

    componentDidMount()
    {
        var lCriteriaSection = document.getElementById('ID_CRITERIA_SECTION');
        if(lCriteriaSection)
        {
            var lHeight = lCriteriaSection.offsetHeight;
            if(this.props.fnLayoutParams)
            {
                this.props.fnLayoutParams('CRITERIA', {height : lHeight})
            }
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            var lFormCode = null;
            var lCriteria = null;
            var lPrevCriteria = null;
            if(this.props.pCriteria && (this.props.pCriteria.length > 0))
            {
                lCriteria = this.props.pCriteria[0];
                if(lCriteria)
                {
                    lFormCode = lCriteria.cs_form_code_;
                }
            }

            if(lPrevProps.pCriteria && (lPrevProps.pCriteria.length > 0))
            {
                lPrevCriteria = lPrevProps.pCriteria[0];
            }

            if(!lPrevCriteria)
            {
                this.setState({mFormCode : lFormCode, mFormData : null});
            }
            else 
            {
                if(lCriteria.hasOwnProperty('app_id_') && lCriteria.app_id_ && lPrevCriteria.hasOwnProperty('app_id_') && lPrevCriteria.app_id_)
                {
                    if(lCriteria.app_id_ !== lPrevCriteria.app_id_)
                    {
                        this.setState({mFormCode : lFormCode, mFormData : null});
                    }
                }
            }

//            if(lPrevState.mCriteriaList !== this.state.mCriteriaList)
//            {
                this.setState({mCriteriaList : this.props.pCriteria});
//            }

            if(this.props.pFormProperties)
            {
                var lNumSections = 3;
                for(var lfpInd=0; lfpInd<this.props.pFormProperties.length; lfpInd++)
                {
                    var lProperty =  this.props.pFormProperties[lfpInd];
                    if(lProperty.cs_form_code_ === lFormCode)
                    {
                        if((lProperty.type_ === 'layout') && (lProperty.property_ === 'max_sections_'))
                        {
                            lNumSections = Number(lProperty.value_);
                            this.setState({mNumSections : lNumSections});
                        }
                    }
                }
            }
        }
    }

    cfGetCriteriaObject(lFormCriteria, lNumSections)
    {
        var ctl = null;
        if(lFormCriteria)
        {
            var lRequired = false;
            var lEditable = '1';
            lFormCriteria.required_ = '0';
            lFormCriteria.display_field_ = lFormCriteria.display_label_;
            lFormCriteria.data_field_ = lFormCriteria.criteria_field_;
            if(lFormCriteria.control_type_ === 'combo_box')
            {
                lEditable = '0';
            }

            if(lFormCriteria.hasOwnProperty('cs_form_criteria_properties_') &&  lFormCriteria.cs_form_criteria_properties_)
            {
                for(var lInd=0; lInd<lFormCriteria.cs_form_criteria_properties_.length; lInd++)
                {
                    var lProperty = lFormCriteria.cs_form_criteria_properties_[lInd];
                    if(lProperty.cs_form_criteria_ref_id_ === lFormCriteria.id_)
                    {
                        if((lProperty.type_ === 'other') && (lProperty.property_ === 'required'))
                        {
                            lRequired = (lProperty.value_ === '1') ? true : false;
                            lFormCriteria.required_ = lProperty.value_;
                            break;
                        }
                    }
                }
            }

            ctl = 
            (
                
                <CSControl 
                    key                 = { lFormCriteria.id_ }
                    process_change_     = { this.cfProcessSelection }
                    pFormLabelClassName = "form_criteria_label"
                    pLabelType          = "floating"
                    editable_           = { lEditable }
                    pLabelWidth         = "100px"  
                    pMaxWidth           = "340px"
                    pRequired           = { lRequired }
                    {...lFormCriteria}/>
            );
        }

        return ctl;
    }

    cfGetCriteriaSections(lCriteriaList)
    {
        var lCriteriaLength = lCriteriaList.length;
        for(var lCInd=0; lCInd<lCriteriaList.length; lCInd++)
        {
            if(lCriteriaList[lCInd].sequence_ === '0')
            {
                lCriteriaLength--;
            }
        }

        var lHeight = 0;
        var lSections = [];
        var lNumSections = this.state.mNumSections;
        if(lCriteriaLength < this.state.mNumSections)
        {
            lNumSections = lCriteriaLength;
        }

        if(lNumSections > 0)
        {   
            var lNumCriteriaSectionRows = Math.ceil(lCriteriaLength/lNumSections);
            if(lNumCriteriaSectionRows > 0)
            {
                lHeight = 60 + (lNumCriteriaSectionRows - 1) * 45;
                
                var lSectionNumControlMap = {};
                CSUtil.cfSort(lCriteriaList, 'sequence_')
                for(var ind=0; ind<lCriteriaList.length; ind++)
                {
                    var lFormCriteria = lCriteriaList[ind];
                    var lSequence = parseInt(lFormCriteria.sequence_, 10);
                    if( lSequence > 0)
                    {
                        var lCriteriaField = this.cfGetCriteriaObject(lFormCriteria, lNumSections);
                        var lIndex = Math.ceil(lSequence/lNumCriteriaSectionRows) - 1;
                        var lSectionControlList = null;
                        lSectionControlList = lSectionNumControlMap[lIndex];
                        if(lSectionControlList == null)
                        {
                            lSectionControlList = [];
                            lSectionNumControlMap[lIndex] = lSectionControlList;
                        }

                        lSectionControlList.push(lCriteriaField)
                    }
                }

                var lCriteriaActionsList = [];
                var lFormActions = this.props.pFormActions;
                var lLabelFlag = false;
                if(lFormActions)
                {
                    for(var lInd_0=0; lInd_0<lFormActions.length; lInd_0++)
                    {
                        var lActionItem = lFormActions[lInd_0];
                        if(lActionItem.component_type_ === 'CRITERIA_ACTION')
                        {
                            if(lActionItem.label_)
                            {
                                lLabelFlag = true;
                            }

                            if(lActionItem.action_type_ === '1')
                            {
                                lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mFormData, lCriteriaList);
                            }
                            else
                            {
                                lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                            }

                            lCriteriaActionsList.push(lActionItem);
                        }
                    }
                }

                for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
                {
                    var lSection = null;
                    var lBkgColor = "";
                    if((lSectionNum % 2) === 0)
                    {
                        lBkgColor = "#FAFAFA";
                    }
                    else
                    {
                        lBkgColor = "#FFFFFF";
                    }

                    lSection = <CSCOVerticalSection 
                        pBackgroundColor    = {lBkgColor} 
                        pId                 = {lSectionNum} 
                        key                 = {lSectionNum}
                        pNumSections        = {lNumSections + 1}
                        pHeight             = {lHeight} 
                        pHasCriteria        = {true}
                        pIsCriteriaSection  = {false}
                        pIsCriteriaLabel    = {lLabelFlag}
                        pChildren           = {lSectionNumControlMap[lSectionNum]}/>

                    lSections.push(lSection);
                }
        
                var lActionSectionBackgroundColor = "#FFFFFF";
                if((lNumSections % 2) === 0)
                {
                    lActionSectionBackgroundColor = "#FAFAFA";
                }

                var lActionSection = <CSCOVerticalSection  
                                        pBackgroundColor    = {lActionSectionBackgroundColor} 
                                        pChildren           = {this.cfGetCriteriaActions(lCriteriaActionsList)}
                                        pId                 = {lSectionNum} 
                                        key                 = {lSectionNum} 
                                        pNumSections        = {lNumSections + 1}
                                        pHeight             = {lHeight} 
                                        pHasCriteria        = {true}
                                        pIsCriteriaSection  = {true}
                                        pIsCriteriaLabel    = {lLabelFlag}
                                        pIsActionSection    = {true}
                                        pAlign              = "center"
                                        pWidth              = {(lLabelFlag) ? "150px" : "60px"}/>
                lSections.push(lActionSection);
            }
        }

        return lSections;
    }

    cfGetCriteriaActions(lCriteriaActionsList)
    {
        var lActions = null;
        lActions = 
            (lCriteriaActionsList) ?
                lCriteriaActionsList.map((lActionItem) => (
                    <div key = {lActionItem.id_} className="criteria-action">
                        <Button
                            icon        = {CSUtil.mNameIconMap[lActionItem.icon_name_]} 
                            style       = {{fontSize : '13.2px', height : '30px'}}
                            key         = {lActionItem.id_}
                            id          = {lActionItem.id_}
                            label       = {lActionItem.label_}
                            disabled    = { !lActionItem.mEnabled }
                            onClick     = {(event) => this.cfProcessSearchAction(event, lActionItem)}
                            />
                    </div>
                )
            )
        : null

       return lActions;
    } 

    cfSetControlValue(lCriteriaList)
    {
        if(lCriteriaList)
        {
            var lFormData = this.state.mFormData;
            for(var ind=0; ind<lCriteriaList.length; ind++)
            {
                var lFormCriteriaItem = lCriteriaList[ind];
                if(lFormCriteriaItem.sequence_ === '0')
                {
                    lFormCriteriaItem.mValue = cfGetConstantValue(lFormCriteriaItem.value_);
                }
                else if(lFormData)
                {   
                    lFormCriteriaItem.mValue = lFormData[lFormCriteriaItem.criteria_field_];
                }
                else
                {
                    lFormCriteriaItem.mValue = '';
                }
    
                if(!lFormCriteriaItem.mValue && lFormCriteriaItem.value_)
                {
                    if(!lFormData)
                    {
                        lFormData = {}
                    }
                    
                    lFormCriteriaItem.mValue = cfGetConstantValue(lFormCriteriaItem.value_);
                    lFormData[lFormCriteriaItem.criteria_field_] = lFormCriteriaItem.mValue;
                }

//                this.setState ({mFormData : lFormData});
            }
        }
    }

    cfProcessSelection(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            if(!lFormData)
            {
                lFormData = {};
            }

            lFormData[lData.data_field_] = lData.value_;

            for(var lInd=0; lInd<this.state.mCriteriaList.length; lInd++)
            {
                var lCriteria = this.state.mCriteriaList[lInd];
                if(lCriteria.hasOwnProperty('cs_form_criteria_properties_'))
                {
                    var lfcProperties = lCriteria.cs_form_criteria_properties_;
                    for(var lfcPInd=0; lfcPInd<lfcProperties.length; lfcPInd++)
                    {
                        var lfcProperty = lfcProperties[lfcPInd];
                        if(lfcProperty.type_ === 'dependency')
                        {
                            if(lfcProperty.dependent_criteria_ref_id_ === lData.id_)
                            {
                                if(lData.hasOwnProperty('selected_data_'))
                                {
                                    if(lCriteria.control_type_ === 'combo_box')
                                    {
                                        lfcProperty.mDependentValue = lData.selected_data_[lfcProperty.value_];
                                    }
                                    else if(lCriteria.control_type_ === 'text_input')
                                    {
                                        lCriteria.value_ = lData.selected_data_[lfcProperty.value_];
                                        lFormData[lCriteria.criteria_field_] = lData.selected_data_[lfcProperty.value_];
                                    } 
                                }
                                else
                                {
                                    lfcProperty.mDependentValue = lData.value_;
                                }
                            }
                        }
                    }
                }
            }

            this.setState ({mFormData : lFormData});
        }
    }

    cfGetDefaultCriteriaData()
    {
        var lCriteria = null;
        if(this.state.mCriteriaList)
        {
            if(!lCriteria)
            {
                lCriteria = {}
            }

            for(var lInd=0; lInd<this.state.mCriteriaList.length; lInd++)
            {
                var lCriteriaObject = this.state.mCriteriaList[lInd];
                if(lCriteriaObject.default_criteria_field_)
                {
                    lCriteria[lCriteriaObject.default_criteria_field_] = cfGetConstantValue(lCriteriaObject.value_)
                }

                if(lCriteriaObject.hasOwnProperty('cs_form_criteria_properties_'))
                {
                    for(var lFcpInd=0; lFcpInd<lCriteriaObject.cs_form_criteria_properties_.length; lFcpInd++)
                    {
                        var lFcpObj = lCriteriaObject.cs_form_criteria_properties_[lFcpInd];
                        if(lFcpObj.type_ === 'data_provider')
                        {
                            if(lFcpObj.property_ === 'selection')
                            {
                                if(lFcpObj.value_ === 'all')
                                {
                                    if(lCriteriaObject.data_provider_source_)
                                    {
                                        var lDataProviderList = this.mServiceUtil.mDataProviderIdListMap[lCriteriaObject.data_provider_source_];
                                        if(lDataProviderList)
                                        {
                                            var tmp_val = '';
                                            for(var lCtrlDpInd=0; lCtrlDpInd<lDataProviderList.length; lCtrlDpInd++)
                                            {
                                                var lCtrlDpObj = lDataProviderList[lCtrlDpInd];
                                                if(lCtrlDpObj[lFcpObj.selection_property_])
                                                {
                                                    if(tmp_val)
                                                    {
                                                        tmp_val += ', ';
                                                    }
                                                    
                                                    tmp_val += "'" + lCtrlDpObj[lFcpObj.selection_property_] + "'";
                                                }
                                            }

                                            lCriteria[lCriteriaObject.criteria_field_] = tmp_val;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return lCriteria;
    }

    cfProcessSearchAction(event, lActionItem)
    {
        if(event && lActionItem)
        {
            var lDefaultCriteria = this.cfGetDefaultCriteriaData();
            if(!lDefaultCriteria)
            {
                lDefaultCriteria = {}
            }

            var lCriteria = {}
            if(lDefaultCriteria)
            {
                var ldCriteriaNames = Object.keys(lDefaultCriteria);
                var ldCriteriaValues = Object.values(lDefaultCriteria);
                for(var lInd_1=0; lInd_1<ldCriteriaNames.length; lInd_1++)
                {
                    lCriteria[ldCriteriaNames[lInd_1]] = ldCriteriaValues[lInd_1]
                }
            }

            if(this.state.mFormData)
            {
                var lCriteriaNames = Object.keys(this.state.mFormData);
                var lCriteriaValues = Object.values(this.state.mFormData);
                for(var lInd_0=0; lInd_0<lCriteriaNames.length; lInd_0++)
                {
                    lCriteria[lCriteriaNames[lInd_0]] = lCriteriaValues[lInd_0]
                }
            }

            if(lActionItem.hasOwnProperty('fnProcessClick'))
            {
                lActionItem.fnProcessClick(lCriteria, lActionItem);
            }
            else if(this.state.mParent && this.state.mParent.hasOwnProperty(lActionItem.action_))
            {
                this.state.mParent[lActionItem.action_](lCriteria, lActionItem);
            }
        }
    }

    render()
    {
        var lCriteria = null;
        
        if(this.state.mCriteriaList)
        {
            var lCriteriaList = [];

            /**
             * Get Number of Sections From Properties. Default is 3
             */
            if(this.state.mCriteriaList.length > 0)
            {
                for(var ind=0; ind<this.state.mCriteriaList.length; ind++)
                {
                    var lCrObject = this.state.mCriteriaList[ind];
                    if(lCrObject.include_ === "Y")
                    {
                        lCriteriaList.push(lCrObject);
                    }
                }

                this.cfSetControlValue(this.state.mCriteriaList);
            }

            if(lCriteriaList.length > 0)
            {
                var lCriteriaSections = this.cfGetCriteriaSections(lCriteriaList);
                if(lCriteriaSections && (lCriteriaSections.length > 0))
                {
                    lCriteria = 
                        <div className="fluid formgrid flex" id="ID_CRITERIA_SECTION">
                            { lCriteriaSections }
                        </div>
                }
            }
        }
        
        return lCriteria;
    }
}

export default CSCOCriteria;
