import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';

const CSCLIScript = () =>
{
    const dt = useRef(null);
    const [m_script_, setScript] = useState("");

    return (
        <div className="cs-cli-script-group">
            <InputTextarea id="ID_SCRIPT" className="cs-cli-script" value={m_script_} onChange={(e)=>{setScript(e.value)}}/>
            <div className="cs-cli-criteria">
                <DataTable ref={dt} style={{width : '100%'}} header="Criteria Details">
                    <Column field="criteria_"          style={{fontSize:'13px', width:'5em'}}   header="Criteria" sortable/>
                    <Column field="alias_"             style={{fontSize:'13px', width:'5em'}}   header="Alias"/>
                    <Column field="type_"              style={{fontSize:'13px', width:'3em'}}   header="Type"/>
                    <Column field="default_value_"     style={{fontSize:'13px', width:'3em'}}   header="Default"/>
                </DataTable>
            </div>
        </div>
    );
}

export default CSCLIScript;
