import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import CSUtil from "../util/CSUtil";

function CSBonafideCertificate1(lProps) 
{
    const mComponentRef = useRef(null);

    const [ mStudentDetails, setStudentDetails ] = useState(
        {
            mAdmissionNo            : '',
            mStudentName            : '',
            mParentName             : '',
            mClass                  : '',
            mSection                : '',
            mBonafideCertificateNo  : '',
            mIssueDate              : '',
            mDateOfBirth            : '',
        }
    );

    useEffect(() =>
        {
            const fnInitializeForm = () =>
            {
                if(lProps && lProps.pSelectedData)
                {
                    var lStudentDetails = 
                    {
                        mAdmissionNo           : lProps.pSelectedData.selected_data_.admission_no_,
                        mStudentName           : lProps.pSelectedData.selected_data_.student_name_,
                        mClass                 : lProps.pSelectedData.selected_data_.class_range_,
                        mSection               : lProps.pSelectedData.selected_data_section_code_,
                        mBonafideCertificateNo : lProps.pSelectedData.selected_data_.bonafide_certificate_no_,
                        mIssueDate             : lProps.pSelectedData.selected_data_.issue_date_,
                        mDateOfBirth           : lProps.pSelectedData.selected_data_.date_of_birth_,
                        mParentName            : lProps.pSelectedData.selected_data_.father_name_,
                        mAcademicYears         : lProps.pSelectedData.selected_data_.ay_range_
                    }

                    setStudentDetails(lStudentDetails);
                }
            }
            fnInitializeForm();

        }, [lProps]);

    
        var lHeaderActions = [];
        lHeaderActions.push(
            <ReactToPrint
                key = "1"
                trigger={() => 
                    <Button
                        style=
                            {
                                {
                                    fontSize        : '13.2px', 
                                    marginRight     : '.35em', 
                                    width           : '24px', 
                                    height          : '24px',
                                    verticalAlign   : 'middle'
                                }
                            } 
                            type="Button" 
                            icon={CSUtil.mNameIconMap['PI_PRINT']} 
                        />
                    }
                content={() => mComponentRef.current}
            />
        );
                
        lHeaderActions.push(
            <Button
                key = "2"
                style=
                    {
                        {
                            fontSize        : '13.2px', 
                            marginRight     : '.35em', 
                            width           : '24px', 
                            height          : '24px',
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => lProps.fnProcessClose(true, true) }
            />
        );

    var lHeader = 
    <>
        <div className="p-toolbar-group-left">
            <h2>{lProps.pFormHeading}</h2>
        </div>

        <div className="p-toolbar-group-right">
            { lHeaderActions }
        </div>
    </>

    const lForm =
        <div className='bc1' ref={mComponentRef} style={{backgroundImage: `url(./images/BC-GMS.png)`, backgroundSize: 'cover'}}>
            {
            /* <div className='std-photo'>
                    <img alt='' style = {{ width: 100, height: 100 }}
                        src="https://www.freepnglogos.com/uploads/student-png/student-png-international-pioneers-school-11.png"
                    />
                </div> 
            */}

            <p className='p6'> {mStudentDetails.mBonafideCertificateNo}</p>
            <p className='p0'> {mStudentDetails.mIssueDate}     </p>
            <p className='p1'> {mStudentDetails.mStudentName} ( {mStudentDetails.mAdmissionNo} ). </p>
            <p className='p2'> {mStudentDetails.mParentName}    </p>
            <p className='p3'> {mStudentDetails.mClass}         </p>
            <p className='p4'> {mStudentDetails.mAcademicYears} </p>
            <p className='p5'> {mStudentDetails.mDateOfBirth}   </p>

        </div>

  return (
    <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            appendTo  = { document.body }
            header    = {lHeader}
            onHide    = { () => lProps.fnProcessClose(false, true) }>
                { lForm }
        </Dialog>
  )
}

export default CSBonafideCertificate1;