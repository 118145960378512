import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function CSCODoughnutChart(lProps) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        let lChartData = {};
        if(lProps.pData && lProps.pChartData && lProps.pChartData.chart_data_field_)
        {
            lChartData.labels = [];
            lChartData.datasets = [];
            for(let lDataInd=0; lDataInd<lProps.pChartData.chart_data_field_.length; lDataInd++)
            {
                let lDataObj = {};
                lDataObj.data = [];
                let lChartDataField = lProps.pChartData.chart_data_field_[lDataInd];
                for(let lInd=0; lInd<lProps.pData.length; lInd++)
                {
                    let lObj = lProps.pData[lInd];
                    lDataObj.data.push(lObj[lChartDataField]);
                    lChartData.labels.push(lObj[lProps.pChartData.chart_data_label_[lDataInd]]);
                }

                lChartData.datasets.push(lDataObj);
            }
        }
/*        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [300, 50, 100],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--clr-heading'), 
                        documentStyle.getPropertyValue('--clr-text'), 
                        documentStyle.getPropertyValue('--clr-heading')
                    ]
                }
            ]
        };
*/        const lOptions = {
            cutout: '50%'
        };

        setChartData(lChartData);
        setChartOptions(lOptions);
    }, [lProps.pData, lProps.pChartData]);

    return (
        <div className="flex justify-content-center">
            <Chart 
                type       = "doughnut" 
                data       = {chartData} 
                options    = {chartOptions} 
            />
        </div>
    )
}