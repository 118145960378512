import React, { Component } from 'react';
import CSConfigUtil from '../util/CSConfigUtil'; 
import CSUtil from '../util/CSUtil';

export default class CSProfile extends Component 
{
    constructor(lProps) 
    {
        super(lProps);

        this.mCSUtil = CSUtil.cfGetInstance();

        this.state = 
        {
            expanded: false
        };
        
        this.onClick   = this.onClick.bind(this);
        this.cfProfile = this.cfProfile.bind(this);
        this.cfChange  = this.cfChange.bind(this);
    }

    onClick(event) 
    {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    cfChange(event)
    {
        if(CSConfigUtil.mCompanyCode)
        {
            this.props.cfCompanyChange(event);
        }
        else
        {
            this.props.cfBranchChange(event);
        }

        event.preventDefault();
    }

    cfProfile()
    {
        var lFeatureRefId = null;
        if(this.mCSUtil.mFloatingMenuMap)
        {
            if(CSConfigUtil.mUserType === 'EMPLOYEE')
            {
                lFeatureRefId = this.mCSUtil.mFloatingMenuMap['EMPLOYEE_PROFILE'];
            }
            else if(CSConfigUtil.mUserType === 'STUDENT')
            {
                lFeatureRefId = this.mCSUtil.mFloatingMenuMap['STUDENT_PROFILE'];
            }

            this.props.fnClick(lFeatureRefId);
        }
    }

    render() 
    {
        let lPicture = CSConfigUtil.mEmployeePhoto;
        // var lPhoto = CSConfigUtil.mCompanyLogo;
        let lPhoto = null;
        if(!lPicture)
        {
            lPhoto = <i className="pi pi-user "></i>
        }
        else
        {
            lPhoto = <img src={lPicture} alt="Profile" />
        }

        return  (
            <>
                <div className="layout-profile">
                    { lPhoto }
                </div>
                
                <div className="profile-user">
                    <button className="p-link" onClick={this.cfProfile}>
                        { CSConfigUtil.mEmployeeName }
                    </button>
                </div>
                <div className="profile-branch">
                    <button className="p-link" onClick={this.cfChange}>
                        { (CSConfigUtil.mCompanyCode) ? CSConfigUtil.mCompanyName : CSConfigUtil.mBranchName }
                    </button>
                </div>
            </>
        );
    }
}