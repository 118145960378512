import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';

import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"
import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";

import CSCODetailsHeader1 from "../../components/CSCODetailsHeader1";
import {Button} from 'primereact/button';
import CSCOAccordion from "../../components/CSCOAccordion";

class CSCODetailsForm16 extends Component
{
    mComponentCode      = 'CS_DETAILS_FORM_LAYOUT_16';
    mComponentList      = ['CS_DETAILS_FORM_LAYOUT_16', 'CS_DETAILS_HEADING_SECTION_1', 'CS_DETAILS_FORM_SECTION_1'];
    mMaxSections        = 4;
    mMinSectionItems 	= 6;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mDisplay        	: this.props.pShow,
            mAppId          	: this.props.pAppId,
            mFormHeading    	: (this.props.pFormHeading) ? this.props.pFormHeading : "",
            mFormCode       	: this.props.pFormCode,
            mFormData       	: (this.props.pFormData) ? this.props.pFormData : {},
            mFormOrigData   	: CSUtil.cfGetObjectCopy(this.props.pFormData),
            mFormActions    	: this.props.pFormActions,
            mFormProperties 	: this.props.pFormProperties,
            mFormItemProperties	: this.props.pFormItemProperties,
            mFormItems         	: this.props.pFormItems,
            mSelectedActionItem : this.props.pSelectedActionItem,
            mWidth             	: this.props.pWidth,
            mHeight            	: this.props.pHeight,
            fnProcessClose     	: props.fnProcessClose,
            mHeaderFlag         : false,
            mSectionFlag        : false
        }

        this.cfProcessChange    = this.cfProcessChange.bind(this);
        this.cfEnableSave      = this.cfEnableSave.bind(this);
        this.process_cancel     = this.process_cancel.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.setState({
                mDisplay            : this.props.pShow,
                mAppId              : this.props.pAppId,
                mFormHeading        : (this.props.pFormHeading) ? this.props.pFormHeading : "",
                mFormCode           : this.props.pFormCode,
                mFormItems          : this.props.pFormItems,
                mFormData           : this.props.pFormData,
                mControlType        : this.props.pControlType,
                mEditable           : this.props.pEditable,
                mFormActions    	: this.props.pFormActions,
                mFormProperties 	: this.props.pFormProperties,
                mFormItemProperties	: this.props.pFormItemProperties,
                mSelectedActionItem : this.props.pSelectedActionItem,
                mWidth              : this.props.pWidth,
                mHeight             : this.props.pHeight,
                mValue              : this.props.pValue,
            });
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            var lFormData = this.state.mFormData;
            if(!lFormData)
			{
                lFormData = {};
                for(var ind=0; ind<this.state.mFormItems.length; ind++)
                {
                    var lFormItem = this.state.mFormItems[ind];
                    lFormData[lFormItem.data_field_] = '';
                    if(lFormItem.control_type_ === 'constant')
                    {
                        lFormData[lFormItem.data_field_] = cfGetConstantValue(lFormItem.constant_);
                    }
                }
			}

            if(lData.hasOwnProperty('data_field_') && lData.data_field_)
            {
                lFormData[lData.data_field_] = lData.value_;
            }

            this.setState ({mFormData : lFormData});
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mFormData;
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm(lCloseFlag)
    {
        this.state.fnProcessClose(lCloseFlag === false);
    }

    cfEnableSave(lValue, lHeaderFlag)
    {
        if(lHeaderFlag === 1)
        {
            this.setState({mHeaderFlag : lValue});
        }
        else
        {
            this.setState({mSectionFlag : lValue});
        }
    }

    render() 
    {
        
        this.cfSetControlValue();
     
        var lFormActions = null;
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lSection = null;
        if(lFormItemList.length > 0)
        {
            var lSaveActionItem = null;
            if(this.state.mFormActions && (this.state.mFormActions.length > 0) && this.state.mFormData)
            {
                var lFormActionList = null;
                for(var lAInd=0; lAInd<this.state.mFormActions.length; lAInd++)
                {
                    var lActionItem = this.state.mFormActions[lAInd];
                    if(lActionItem.component_type_ === 'DETAILS_ACTION')
                    {
                        if((lActionItem.parent_ref_id_ === '') || (lActionItem.parent_ref_id_ === this.state.mSelectedActionItem.id_))
                        {
                            if(lActionItem.action_type_ === '1')
                            {
                                if (lActionItem.action_ === 'process_save')
                                {
                                    lSaveActionItem = lActionItem;
                                }

                                lActionItem.mEnabled = this.state.mHeaderFlag && this.state.mSectionFlag;
                            }
                            else
                            {
                                lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                            }

                            if(!lFormActionList)
                            {
                                lFormActionList = [];
                            }
    
                            lFormActionList.push(lActionItem);
                        }
                    }
                }
     
                lFormActions = 
                    <CSCOHorizontalActionBar 
                        align       = "left"
                        pType       = 'SAVE_ACTION_BAR'
                        parent      = {this}
                        actionList  = {lFormActionList}
                    />;
            }
     
            let lHeaderActions = 
            <div style={{verticalAlign: 'middle'}}>            


                <Button
                    key = "21"
                    style=
                        {
                            {
                                verticalAlign   : 'middle'
                            }
                        } 
                    type="Button" 
                    icon={CSUtil.mNameIconMap['PI_TIMES']}
                    onClick= { () => this.process_close(false, true) }
                />
            </div>

        let lHeader = 
            <div className='flex justify-content-between'>
                <div className="p-toolbar-group-left">
                    <h2>{ this.state.mFormHeading || '.' }</h2>
                </div>

                <div className="p-toolbar-group-right">
                    { lHeaderActions }
                </div>
            </div>

            lSection = 
                <Dialog visible={this.state.mDisplay}
                        modal={true}
                        style=
                        {
                            {
                                minWidth : 'auto',
                                maxWidth : '100%',
                                minheight : 'auto', 
                                paddingBottom: -40,
                                overflow : 'auto'
                            }
                        }
                        minheight   = 'auto'
                        appendTo    = { document.body }
                        header      = {lHeader}
                        closable    = { false }
                        padding     = '2px'
                        onHide      = {() => this.cfCloseForm()}
                        footer      = { lFormActions }
                        >

                    <div className="background">
                        <CSCODetailsHeader1 pFormItems          = {lFormItemList} 
                                            pFormData           = {this.state.mFormData} 
                                            pFormProperties     = {this.state.mFormProperties} 
                                            pEnableSaveFn       = {this.cfEnableSave}
                                            pSaveActionItem     = {lSaveActionItem}
                                            pFormItemProperties = {this.state.mFormItemProperties}
                                            />
                        <CSCOAccordion      pFormItems         = {lFormItemList} 
                                            pFormData           = {this.state.mFormData} 
                                            pFormProperties     = {this.state.mFormProperties} 
                                            pEnableSaveFn       = {this.cfEnableSave}
                                            pSaveActionItem     = {lSaveActionItem}
                                            pFormItemProperties = {this.state.mFormItemProperties}/>
                    </div>
                </Dialog>
        }

        return lSection;
    }
}

export default CSCODetailsForm16;
