import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import CSConfigUtil from '../util/CSConfigUtil'; 
import CSServiceUtil from '../util/CSServiceUtil'; 
import CSRequestUtil from '../util/CSRequestUtil'; 
import CSUtil from "../util/CSUtil";
import { cfGetConstantValue } from '../util/CSConstantUtil';
import { fnConvertNumberToWords } from '../util/CSMathUtil';

const mServiceUtil = CSServiceUtil.cfGetInstance();

const CSSalesOrder1 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const [ mCompanyDetails, setCompanyDetails ] = useState(
        {
            mCompanyName: '',
            mAddress1   : '',
            mAddress2   : '',
            mPhone1     : '',
            mPhone2     : '',
            mEmail      : '',
            mWebsite    : '',
            mLogo       : ''
        }, []
    );

    const [ mDisplay, setDisplay ] = useState( true );

    const [ mFormTitle, setFormTitle ] = useState('Sales Order');
    const [ mTopSection1, setTopSection1 ]       = useState({}, []);
    const [ mTopSection2, setTopSection2 ]       = useState({}, []);

    const [ mNameObjectMap, setNameObjectMap ] = useState ({});

    const [ mPriceDetails, setPriceDetails ] = useState(null);

    const [ mPaymentTermsTitle, setPaymentTermsTitle ] = useState('Payment Terms');
    const [ mPaymentTerms, setPaymentTerms ] = useState(null);

    const [ mDeliveryTermsTitle, setDeliveryTermsTitle ] = useState('Delivery Terms');
    const [ mDeliveryTerms, setDeliveryTerms ] = useState(null);

    const [ mCancellationTermsTitle, setCancellationTermsTitle ] = useState('Cancellation Terms');   
    const [ mCancellationTerms, setCancellationTerms ] = useState(null);

    const [ mOtherTermsTitle, setOtherTermsTitle ] = useState('Other Terms');
    const [ mOtherTerms, setOtherTerms ] = useState(null);

    const [ mLegalTermsTitle, setLegalTermsTitle ] = useState('Legal Terms');
    const [ mLegalTerms, setLegalTerms ] = useState(null);

    const [ mServiceCode, setServiceCode] = useState(null);
    const [ mRequestId, setRequestId] = useState(null);

    const [ mFooter, setFooter ] = useState("Sales Order was created on a computer and is valid without the signature and seal.");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                setDisplay(true);
                if(lProps && lProps.pFormProperties)
                {
                    var lFormData       = null;
                    var lTopSection1    = null;
                    var lTopSection2    = null;
                    var lPaymentTable   = null;

                    for(var lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        var lPropObj = lProps.pFormProperties[lInd];
                        var lDataValue = fnGetDataValue(lFormData, lPropObj.value_, lPropObj.property_, lPropObj.type_);
                        if(lDataValue)
                        {
                            if(lPropObj.type_ === 'top_section_1')
                            {
                                if(!lTopSection1)
                                {
                                    lTopSection1 = {}
                                }

                                lTopSection1[lPropObj.property_] = lDataValue;
                            }
                            else if(lPropObj.type_ === 'top_section_2')
                            {
                                if(!lTopSection2)
                                {
                                    lTopSection2 = {}
                                }

                                lTopSection2[lPropObj.property_] = lDataValue;
                            }
                            else if(lPropObj.type_ === 'payment_table')
                            {
                                if(!lPaymentTable)
                                {
                                    lPaymentTable = {}
                                }

                                lPaymentTable[lPropObj.property_] = lDataValue;
                            }
                            else if(lPropObj.property_ === 'request_id_')
                            {
                                setRequestId(lDataValue);
                            }
                            else if(lPropObj.property_ === 'service_code_')
                            {
                                setServiceCode(lDataValue);
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lDataValue);
                            }                                
                        }
                    }

                    setTopSection1(lTopSection1);
                    setTopSection2(lTopSection2);
                }
            }

            fnInitializeForm();
        }, [lProps, setTopSection1, setTopSection2, setFooter]
    );

    const fnGetDataValue = (lDataObj, lDataField, lProperty) =>
    {
        var lRetValue = null;

        /**
         * Check is data field is constant
         */
        if(lDataField.startsWith('#') && lDataField.endsWith('#'))
        {
            if(lDataField === '#RECEIPT_HEADING#')
            {
                lRetValue = CSConfigUtil.mReceiptHeading[CSConfigUtil.mBranchCode];
            }
            else if(lDataField === '#RECEIPT_SUB_HEADING_1#')
            {
                lRetValue = CSConfigUtil.mReceiptSubHeading1[CSConfigUtil.mBranchCode];
            }
            else if(lDataField === '#RECEIPT_SUB_HEADING_2#')
            {
                lRetValue = CSConfigUtil.mReceiptSubHeading2[CSConfigUtil.mBranchCode];
            }
            else
            {
                lRetValue = cfGetConstantValue(lDataField);
            }
        }
        else if(lDataObj && lDataObj.hasOwnProperty(lDataField))
        {
            if(lProperty && (lProperty === 'payment_in_words_'))
            {
                var lValue = (Math.round(lDataObj[lDataField])).toFixed(0);
                lRetValue = fnConvertNumberToWords(lValue);
            }
            else
            {
                lRetValue = lDataObj[lDataField];
            }
        }
        else
        {
            lRetValue = lDataField;
        }

        return lRetValue;
    }

    useEffect(() =>
        {
            const fnInitializeData = () =>
            {
                if(mServiceCode && lProps.pCriteria)
                {
                    var lServiceObject = mServiceUtil.cfGetServiceObject(mServiceCode);
                    if(lServiceObject)
                    {
                        var lCriteria = lProps.pCriteria;
                        lCriteria.office_code_ = CSConfigUtil.mOfficeCode;
                        var lRequestUtil = new CSRequestUtil('GET', mServiceCode, lServiceObject.type_, mRequestId, lServiceObject);
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse) =>
                            {
                                if(lResponse)
                                {
                                    let lResponseObjectMap = {}
                                    if(lResponse.hasOwnProperty('sales_order_details_') && (lResponse.sales_order_details_.length > 0))
                                    {
                                        lResponseObjectMap['sales_order_details_'] = lResponse.sales_order_details_[0];
                                    }

                                    if(lResponse.hasOwnProperty('company_details_') && (lResponse.company_details_.length > 0))
                                    {
                                        lResponseObjectMap['company_details_'] = lResponse.company_details_[0];

                                        let lCompanyInfo = lResponse.company_details_[0];
                                        let lCompanyDetails = {};

                                        lCompanyDetails.mCompanyName = lCompanyInfo.office_name_;
                                        lCompanyDetails.mAddress1    = lCompanyInfo.address_1_;
                                        lCompanyDetails.mAddress2    = lCompanyInfo.address_2_;
                                        lCompanyDetails.mPhone1      = lCompanyInfo.office_phone_1_;
                                        lCompanyDetails.mPhone2      = lCompanyInfo.office_phone_2_;
                                        lCompanyDetails.mEmail       = lCompanyInfo.email_;
                                        lCompanyDetails.mWebsite     = lCompanyInfo.website_;
                                        lCompanyDetails.mLogo        = lCompanyInfo.logo_;

                                        setCompanyDetails(lCompanyDetails);
                                    }

                                    if(lResponse.hasOwnProperty('customer_details_') && (lResponse.customer_details_.length > 0))
                                    {
                                        lResponseObjectMap['customer_details_'] = lResponse.customer_details_[0];
                                    }

                                    /**
                                     * Price Details
                                     */
                                    if(lResponse.hasOwnProperty('price_details_') && (lResponse.price_details_.length > 0))
                                    {
                                        lResponseObjectMap['price_details_'] = lResponse.price_details_;
                                        let lPriceDataCollection = [];
                                        let lpdInd = 0;
                                        let lSeq = '1';
                                        for(let ldInd=0; ldInd<lResponse.price_details_.length; ldInd++)
                                        {
                                            let lPriceDataObj = lResponse.price_details_[ldInd];
                                            let lPriceDataList = [];
                                            if(ldInd > 0)
                                            {
                                                lSeq = '';
                                            }

                                            if(ldInd < 3)
                                            {
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lSeq}                          </td>);
                                                lPriceDataList.push(<td key={lpdInd++}>{lPriceDataObj.unit_code_}                    </td>);
                                                lPriceDataList.push(<td key={lpdInd++}>{lPriceDataObj.carpet_area_}                  </td>);
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lPriceDataObj.base_price_}     </td>);
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lPriceDataObj.premium_}        </td>);
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lPriceDataObj.gross_amount_}   </td>);
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lPriceDataObj.discount_}       </td>);
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lPriceDataObj.net_amount_}     </td>);
                                            }
                                            else if(ldInd === 3)
                                            {
                                                lPriceDataList.push(<td align='right' colSpan='7' key={lpdInd++}>GST                 </td>);
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lPriceDataObj.net_amount_}     </td>);
                                            }
                                            else if(ldInd === 4)
                                            {
                                                lPriceDataList.push(<td align='right' colSpan='7' key={lpdInd++}>Total               </td>);
                                                lPriceDataList.push(<td align='right' key={lpdInd++}>{lPriceDataObj.net_amount_}     </td>);
                                            }

                                            lPriceDataCollection.push(<tr key={ldInd + 1}>{lPriceDataList}</tr>);
                                        }
                    
                                        setPriceDetails(lPriceDataCollection);
                                    }

                                    if(lResponse.hasOwnProperty('payment_terms_') && (lResponse.payment_terms_.length > 0))
                                    {
                                        lResponseObjectMap['payment_terms_'] = lResponse.payment_terms_;
                                        var lPaymentTermsCollection = [];
                                        var lpdInd = 0;
                                        for(let ldtInd=0; ldtInd<lResponse.payment_terms_.length; ldtInd++)
                                        {
                                            var lPaymentTermsObj = lResponse.payment_terms_[ldtInd];
                                            var lPaymentTermsList = [];

                                            lPaymentTermsList.push(<td key={lpdInd++} className='terms-seq'>{ldtInd + 1}               </td>);
                                            lPaymentTermsList.push(<td key={lpdInd++} className='terms-det'>{lPaymentTermsObj.details_}</td>);
                    
                                            lPaymentTermsCollection.push(<tr key={ldtInd + 1}>{lPaymentTermsList}</tr>);
                                        }
                    
                                        setPaymentTerms(lPaymentTermsCollection);
                                    }

                                    if(lResponse.hasOwnProperty('delivery_terms_') && (lResponse.delivery_terms_.length > 0))
                                    {
                                        lResponseObjectMap['delivery_terms_'] = lResponse.delivery_terms_;
                                        var lDeliveryTermsCollection = [];
                                        var lpdInd = 0;
                                        for(let ldtInd=0; ldtInd<lResponse.delivery_terms_.length; ldtInd++)
                                        {
                                            var lDeliveryTermsObj = lResponse.delivery_terms_[ldtInd];
                                            var lDeliveryTermsList = [];

                                            lDeliveryTermsList.push(<td key={lpdInd++} className='terms-seq'>{ldtInd + 1}</td>);
                                            lDeliveryTermsList.push(<td key={lpdInd++} className='terms-det'>{lDeliveryTermsObj.details_}</td>);
                    
                                            lDeliveryTermsCollection.push(<tr key={ldtInd + 1}>{lDeliveryTermsList}</tr>);
                                        }
                    
                                        setDeliveryTerms(lDeliveryTermsCollection);
                                    }

                                    if(lResponse.hasOwnProperty('cancellation_terms_') && (lResponse.cancellation_terms_.length > 0))
                                    {
                                        lResponseObjectMap['cancellation_terms_'] = lResponse.cancellation_terms_;
                                        var lCancellationTermsCollection = [];
                                        var lpdInd = 0;
                                        for(let ldtInd=0; ldtInd<lResponse.cancellation_terms_.length; ldtInd++)
                                        {
                                            var lCancellationTermsObj = lResponse.cancellation_terms_[ldtInd];
                                            var lCancellationTermsList = [];

                                            lCancellationTermsList.push(<td key={lpdInd++} className='terms-seq'>{ldtInd + 1}</td>);
                                            lCancellationTermsList.push(<td key={lpdInd++} className='terms-det'>{lCancellationTermsObj.details_}</td>);
                    
                                            lCancellationTermsCollection.push(<tr key={ldtInd + 1}>{lCancellationTermsList}</tr>);
                                        }
                    
                                        setCancellationTerms(lCancellationTermsCollection);
                                    }

                                    if(lResponse.hasOwnProperty('legal_terms_') && (lResponse.legal_terms_.length > 0))
                                    {
                                        lResponseObjectMap['legal_terms_'] = lResponse.legal_terms_;
                                        var lLegalTermsCollection = [];
                                        var lpdInd = 0;
                                        for(let ldtInd=0; ldtInd<lResponse.legal_terms_.length; ldtInd++)
                                        {
                                            var lLegalTermsObj = lResponse.legal_terms_[ldtInd];
                                            var lLegalTermsList = [];

                                            lLegalTermsList.push(<td key={lpdInd++} className='terms-seq'>{ldtInd + 1}</td>);
                                            lLegalTermsList.push(<td key={lpdInd++} className='terms-det'>{lLegalTermsObj.details_}</td>);
                    
                                            lLegalTermsCollection.push(<tr key={ldtInd + 1}>{lLegalTermsList}</tr>);
                                        }
                    
                                        setLegalTerms(lLegalTermsCollection);
                                    }

                                    if(lResponse.hasOwnProperty('other_terms_') && (lResponse.other_terms_.length > 0))
                                    {
                                        lResponseObjectMap['other_terms_'] = lResponse.other_terms_;
                                        var lOtherTermsCollection = [];
                                        var lpdInd = 0;
                                        for(let ldtInd=0; ldtInd<lResponse.other_terms_.length; ldtInd++)
                                        {
                                            var lOtherTermsObj = lResponse.other_terms_[ldtInd];
                                            var lOtherTermsList = [];

                                            lOtherTermsList.push(<td key={lpdInd++} className='terms-seq'>{ldtInd + 1}</td>);
                                            lOtherTermsList.push(<td key={lpdInd++} className='terms-det'>{lOtherTermsObj.details_}</td>);
                    
                                            lOtherTermsCollection.push(<tr key={ldtInd + 1}>{lOtherTermsList}</tr>);
                                        }
                    
                                        setOtherTerms(lOtherTermsCollection);
                                    }

                                    setNameObjectMap(lResponseObjectMap);
                                }
                            }
                        );
                    }
                }
            }

            fnInitializeData();
        }, [mServiceCode, mRequestId, lProps.pCriteria]
    );

    var lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px',
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => setDisplay(false) }
        />
    );

    var lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    /**
     * Top Section 1
     */        
    let lTopSection1Column1 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_1_label_'))
    {
        let lTopSection1Column1Label     = mTopSection1.column_1_label_;
        let lTopSection1Column1Data      = null;
        if(mTopSection1.column_1_data_field_ && mTopSection1.column_1_data_obj_ && mNameObjectMap[mTopSection1.column_1_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_1_data_obj_];
            lTopSection1Column1Data = lObj[mTopSection1.column_1_data_field_];
        }

        lTopSection1Column1 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column1Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column1Data }</div>
            </div>
    }

    let lTopSection1Column2 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_2_label_'))
    {
        let lTopSection1Column2Label     = mTopSection1.column_2_label_;
        let lTopSection1Column2Data      = null;
        if(mTopSection1.column_2_data_field_ && mTopSection1.column_2_data_obj_ && mNameObjectMap[mTopSection1.column_2_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_2_data_obj_];
            lTopSection1Column2Data = lObj[mTopSection1.column_2_data_field_];
        }

        lTopSection1Column2 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column2Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column2Data }</div>
            </div>
    }

    let lTopSection1Column3 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_3_label_'))
    {
        let lTopSection1Column3Label     = mTopSection1.column_3_label_;
        let lTopSection1Column3Data      = null;
        
        if(mTopSection1.column_3_data_field_ && mTopSection1.column_3_data_obj_ && mNameObjectMap[mTopSection1.column_3_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_3_data_obj_];
            lTopSection1Column3Data = lObj[mTopSection1.column_3_data_field_];
        }

        lTopSection1Column3 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column3Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column3Data }</div>
            </div>
    }

    let lTopSection1Column4 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_4_label_'))
    {

        let lTopSection1Column4Label     = mTopSection1.column_4_label_;
        let lTopSection1Column4Data      = null;
        
        if(mTopSection1.column_4_data_field_ && mTopSection1.column_4_data_obj_ && mNameObjectMap[mTopSection1.column_4_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_4_data_obj_];
            lTopSection1Column4Data = lObj[mTopSection1.column_4_data_field_];
        }

        lTopSection1Column4 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column4Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column4Data }</div>
            </div>
    }

    let lTopSection1Column5 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_5_label_'))
    {
        let lTopSection1Column5Label     = mTopSection1.column_5_label_;
        let lTopSection1Column5Data      = null;
        
        if(mTopSection1.column_5_data_field_ && mTopSection1.column_5_data_obj_ && mNameObjectMap[mTopSection1.column_5_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_5_data_obj_];
            lTopSection1Column5Data = lObj[mTopSection1.column_5_data_field_];
        }
		
        lTopSection1Column5 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column5Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column5Data }</div>
            </div>
    }

    let lTopSection1Column6 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_6_label_'))
    {
        let lTopSection1Column6Label     = mTopSection1.column_6_label_;
        let lTopSection1Column6Data      = null;
        
        if(mTopSection1.column_6_data_field_ && mTopSection1.column_6_data_obj_ && mNameObjectMap[mTopSection1.column_6_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_6_data_obj_];
            lTopSection1Column6Data = lObj[mTopSection1.column_6_data_field_];
        }
		
        lTopSection1Column6 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column6Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column6Data }</div>
            </div>
    }

    let lTopSection1Column7 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_7_label_'))
    {
        let lTopSection1Column7Label     = mTopSection1.column_7_label_;
        let lTopSection1Column7Data      = null;
        
        if(mTopSection1.column_7_data_field_ && mTopSection1.column_7_data_obj_ && mNameObjectMap[mTopSection1.column_7_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_7_data_obj_];
            lTopSection1Column7Data = lObj[mTopSection1.column_7_data_field_];
        }
		
        lTopSection1Column7 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection1Column7Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection1Column7Data }</div>
            </div>
    }

    let lTopSection1Column8 = null;
    if(mTopSection1 && mTopSection1.hasOwnProperty('column_8_label_'))
    {
        let lTopSection1Column8Label     = mTopSection1.column_8_label_;
        let lTopSection1Column8Data      = null;
        
        if(mTopSection1.column_8_data_field_ && mTopSection1.column_8_data_obj_ && mNameObjectMap[mTopSection1.column_8_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection1.column_8_data_obj_];
            lTopSection1Column8Data = lObj[mTopSection1.column_8_data_field_];
        }
		
        lTopSection1Column8 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection1Column8Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection1Column8Data }</div>
            </div>
    }

    /**
     * Top Section 2
     */
    let lTopSection2Column1 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_1_label_'))
    {
        let lTopSection2Column1Label     = mTopSection2.column_1_label_;
        let lTopSection2Column1Data      = null;
        if(mTopSection2.column_1_data_field_ && mTopSection2.column_1_data_obj_ && mNameObjectMap[mTopSection2.column_1_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_1_data_obj_];
            lTopSection2Column1Data = lObj[mTopSection2.column_1_data_field_];
        }

        lTopSection2Column1 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column1Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column1Data }</div>
            </div>
    }

    let lTopSection2Column2 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_2_label_'))
    {
        let lTopSection2Column2Label     = mTopSection2.column_2_label_;
        let lTopSection2Column2Data      = null;
        if(mTopSection2.column_2_data_field_ && mTopSection2.column_2_data_obj_ && mNameObjectMap[mTopSection2.column_2_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_2_data_obj_];
            lTopSection2Column2Data = lObj[mTopSection2.column_2_data_field_];
        }

        lTopSection2Column2 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column2Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column2Data }</div>
            </div>
    }

    let lTopSection2Column3 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_3_label_'))
    {
        let lTopSection2Column3Label     = mTopSection2.column_3_label_;
        let lTopSection2Column3Data      = null;
        
        if(mTopSection2.column_3_data_field_ && mTopSection2.column_3_data_obj_ && mNameObjectMap[mTopSection2.column_3_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_3_data_obj_];
            lTopSection2Column3Data = lObj[mTopSection2.column_3_data_field_];
        }

        lTopSection2Column3 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column3Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column3Data }</div>
            </div>
    }

    let lTopSection2Column4 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_4_label_'))
    {

        let lTopSection2Column4Label     = mTopSection2.column_4_label_;
        let lTopSection2Column4Data      = null;
        
        if(mTopSection2.column_4_data_field_ && mTopSection2.column_4_data_obj_ && mNameObjectMap[mTopSection2.column_4_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_4_data_obj_];
            lTopSection2Column4Data = lObj[mTopSection2.column_4_data_field_];
        }

        lTopSection2Column4 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection2Column4Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection2Column4Data }</div>
            </div>
    }

    let lTopSection2Column5 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_5_label_'))
    {
        let lTopSection2Column5Label     = mTopSection2.column_5_label_;
        let lTopSection2Column5Data      = null;
        
        if(mTopSection2.column_5_data_field_ && mTopSection2.column_5_data_obj_ && mNameObjectMap[mTopSection2.column_5_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_5_data_obj_];
            lTopSection2Column5Data = lObj[mTopSection2.column_5_data_field_];
        }
		
        lTopSection2Column5 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column5Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column5Data }</div>
            </div>
    }

    let lTopSection2Column6 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_6_label_'))
    {
        let lTopSection2Column6Label     = mTopSection2.column_6_label_;
        let lTopSection2Column6Data      = null;
        
        if(mTopSection2.column_6_data_field_ && mTopSection2.column_6_data_obj_ && mNameObjectMap[mTopSection2.column_6_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_6_data_obj_];
            lTopSection2Column6Data = lObj[mTopSection2.column_6_data_field_];
        }
		
        lTopSection2Column6 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection2Column6Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection2Column6Data }</div>
            </div>
    }

    let lTopSection2Column7 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_7_label_'))
    {
        let lTopSection2Column7Label     = mTopSection2.column_7_label_;
        let lTopSection2Column7Data      = null;
        
        if(mTopSection2.column_7_data_field_ && mTopSection2.column_7_data_obj_ && mNameObjectMap[mTopSection2.column_7_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_7_data_obj_];
            lTopSection2Column7Data = lObj[mTopSection2.column_7_data_field_];
        }
		
        lTopSection2Column7 = 
            <div className="form-item-row"> 
                <div style={{width: '150px', fontWeight: 'bold'}}>{ lTopSection2Column7Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div style={{width: '80%'}}>{ lTopSection2Column7Data }</div>
            </div>
    }

    let lTopSection2Column8 = null;
    if(mTopSection2 && mTopSection2.hasOwnProperty('column_8_label_'))
    {
        let lTopSection2Column8Label     = mTopSection2.column_8_label_;
        let lTopSection2Column8Data      = null;
        
        if(mTopSection2.column_8_data_field_ && mTopSection2.column_8_data_obj_ && mNameObjectMap[mTopSection2.column_8_data_obj_])
        {
            let lObj = mNameObjectMap[mTopSection2.column_8_data_obj_];
            lTopSection2Column8Data = lObj[mTopSection2.column_8_data_field_];
        }
		
        lTopSection2Column8 = 
            <div className="form-item-row"> 
                <div style={{width: '110px', fontWeight: 'bold'}}>{ lTopSection2Column8Label }</div>
                <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                <div>{ lTopSection2Column8Data }</div>
            </div>
    }

    const lTopSection1 = 
        <div className="sum-1">
            <div className="summary-1">
                { lTopSection1Column1 }
                { lTopSection1Column3 }
                { lTopSection1Column5 }
                { lTopSection1Column7 }
            </div>

            <div className="summary-2">
                { lTopSection1Column2 }
                { lTopSection1Column4 }
                { lTopSection1Column6 }
                { lTopSection1Column8 }
            </div>
        </div>

    let lTopSection2 = null;
    if(lTopSection2Column1 || lTopSection2Column2)
    {
        lTopSection2 = 
            <div className="sum-2">
                <div className="summary-1">
                    { lTopSection2Column1 }
                    { lTopSection2Column3 }
                    { lTopSection2Column5 }
                    { lTopSection2Column7 }
                </div>

                <div className="summary-2">
                    { lTopSection2Column2 }
                    { lTopSection2Column4 }
                    { lTopSection2Column6 }
                    { lTopSection2Column8 }
                </div>
            </div>
    }

    let lPaymentTerms = null;
    if(mPaymentTerms)
    {
        lPaymentTerms = 
            <div className="terms">
                <h3 className="terms-heading">
                    { mPaymentTermsTitle }
                </h3>

                { mPaymentTerms }
            </div>
    }

    let lDeliveryTerms = null;
    if(mDeliveryTerms)
    {
        lDeliveryTerms = 
            <div className="terms">
                <h3 className="terms-heading">
                    { mDeliveryTermsTitle }
                </h3>

                { mDeliveryTerms }
            </div>
    }

    let lCancellationTerms = null;
    if(mCancellationTerms)
    {
        lCancellationTerms = 
            <div className="terms">
                <h3 className="terms-heading">
                    { mCancellationTermsTitle }
                </h3>

                { mCancellationTerms }
            </div>
    }

    let lOtherTerms = null;
    if(mOtherTerms)
    {
        lOtherTerms = 
            <div className="terms">
                <h3 className="terms-heading">
                    { mOtherTermsTitle }
                </h3>

                { mOtherTerms }
            </div>
    }

    let lLegalTerms = null;
    if(mLegalTerms)
    {
        lLegalTerms = 
            <div className="terms">
                <h3 className="terms-heading">
                    { mLegalTermsTitle }
                </h3>

                { mLegalTerms }
            </div>
    }

    const lForm = 
        <div id="so1" ref={mComponentRef} className="so1">
            <div style={{minWidth: '600px'}}>
                <header>
                    <div className="row">
                        <div className="company-details">
                            <h2 className="name">
                                { mCompanyDetails.mCompanyName }
                            </h2>

                            <div>
                                { mCompanyDetails.mAddress1 }
                            </div>

                            <div>
                                { mCompanyDetails.mAddress2 }
                            </div>
                        </div>
                
                        <div className="company-contact">
                            <div>Tel No. : { mCompanyDetails.mPhone1 }</div>
                            <div>Tel No. : { mCompanyDetails.mPhone2 }</div>
                            <div>Website : { mCompanyDetails.mWebsite }</div>
                            <div>EMail   : { mCompanyDetails.mEmail }</div>
                        </div>
                
                        <div className="logo">
                            <img
                                src={ mCompanyDetails.mLogo } 
                                width="100%"
                                alt=""/>
                        </div>
                    </div>
                </header>

                <main>
                    <div className="title">
                        <h1>{ mFormTitle }</h1>
                    </div>

                    { lTopSection1 }
                    { lTopSection2 }

                    <div className="order-details">
                        <table className="items">
                            <thead>
                                <tr>
                                    <th>S.No          </th>
                                    <th>Property Id   </th>
                                    <th>Area (Cents)  </th>
                                    <th>Base Price    </th>
                                    <th>Premium (%)   </th>
                                    <th>Gross Value   </th>
                                    <th>Discount      </th>
                                    <th>Net Value     </th>
                                </tr>
                            </thead>
                            <tbody>
                                { mPriceDetails }
                            </tbody>
                        </table>
                    </div>

                    { lPaymentTerms }

                    { lDeliveryTerms }

                    { lCancellationTerms }

                    { lOtherTerms }

                    { lLegalTerms }
                </main>

                <footer>
                    { mFooter }
                </footer>
            </div>
        </div>

    return (
        <Dialog
            visible   = {mDisplay} 
            minheight = 'auto'
            closable  = {false}
            header    = {lHeader}
            appendTo  = { document.body }
            onHide    = { () => setDisplay(false) }>
                { lForm }
        </Dialog>
    );
}

export default CSSalesOrder1;