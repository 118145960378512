import React from "react"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil";
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";
import CSConfigUtil from "../../util/CSConfigUtil";
import { isArray } from "lodash";

class CSCOBaseFormLayout extends React.Component
{
    constructor(props)
    {
       super(props);
   
        this.mCSFormUtil = CSFormUtil.cfGetInstance();
        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mFeatureRefId                   : this.props.pFeatureRefId,
            mAppId                          : this.props.pAppId,
            mHeaderHeight                   : null,
            mCriteriaHeight                 : null,
            mActionBarHeight                : null,
            mSearchFormHeight               : null,
            mShowBackdrop                   : false,
            mSelectedMenuItem               : null,
            mFormLayoutHeading              : null,
            mDataCollection                 : null,
            mGroupCollection                : [],
            mSaveFlag                       : true,
            mParent                         : this.props.pParent,
            mSearchActionList               : null,
            mFormActions                    : this.props.pFormActions,
            mFormGrid                       : (this.props.pFormGrid) ? CSUtil.cfSort(this.props.pFormGrid, 'sequence_') : [],
            mFormItems                      : this.props.pFormItems,
            mFormCriteria                   : this.props.pFormCriteria,
            mFormProperties                 : this.props.pFormProperties,
            mFormValidation                 : this.props.pFormValidation,
            mFormSelection                  : this.props.pFormSelection,
            mFormDetails                    : this.props.pFormDetails,
            mFormActionProperties           : this.props.pFormActionProperties,
            mFormCriteriaProperties         : this.props.pFormCriteriaProperties,
            mFormGridProperties             : this.props.pFormGridProperties,
            mFormGridItemProperties         : this.props.pFormGridItemProperties,
            mFormItemProperties             : this.props.pFormItemProperties,
            mFormItemComputation            : this.props.pFormItemComputation,
            mHeaderColumns                  : null,
            mEnablePagination               : true,
            mFormData                       : (this.props.pFormData !== null) ? this.props.pFormData : {},
            mSelectedCriteria               : null,
            mSelectedRecord                 : null,
            mCriteria                       : null,
            mCriteriaSection                : null,
            mActionItem                     : null,
            mEnableFilter                   : true,
            mClearSelection                 : false,

            mAddFlag                        : false,
            mDetailsFlag                    : false,
            mImportFlag                     : false,
            mFormFlag                       : 0,
            mSelectedData                   : null,
            mSelectedRow                    : null,
            mDetailsMetaData                : null,
            mSelectedActionItem             : null,
            mDetailsForm1                   : null,
            mKeyObjectMap                   : {},
            mKeyField                       : null,
            mFormGridItemObjectMap          : {},
            mDataFieldGridObjectMap         : {},
            mGroupFieldGridItemObjectMap    : {},
            mGroupFields                    : [],
            mFormHeadingHeight              : 0,
            mEnablePrint                    : false,
            mEnablePreview                  : false,
            mPreviewCriteria                : null,
            mPrintForm                      : null,
            mDistributeData                 : false,
            mMaxGrids                       : 1,
        }

        if(this.props.pFormData)
        {
            this.state.mSelectedCriteria    = this.props.pFormData.selected_criteria_;
            this.state.mSelectedRecord      = this.props.pFormData.selected_data_;
        }

        if(this.props.pSelectedData)
        {
            if(!this.state.mFormData)
            {
                this.state.mFormData = {}
            }

            this.state.mFormData.selected_data_ = this.props.pSelectedData;
        }

        this.process_search     = this.process_search.bind(this);
        this.process_add        = this.process_add.bind(this);
        this.process_modify     = this.process_modify.bind(this);
        this.process_delete     = this.process_delete.bind(this);
        this.process_details    = this.process_details.bind(this);
        this.process_import     = this.process_import.bind(this);
        this.process_close      = this.process_close.bind(this);
        this.process_set        = this.process_set.bind(this);

        this.cfCloseForm        = this.cfCloseForm.bind(this);
        this.cfGetFormItems     = this.cfGetFormItems.bind(this);
        this.cfGetFormHeading   = this.cfGetFormHeading.bind(this);
        this.cfProcessData      = this.cfProcessData.bind(this);
        this.cfProcessResponse  = this.cfProcessResponse.bind(this);
        this.cfSetFormData      = this.cfSetFormData.bind(this);
        this.cfInitializeLayout = this.cfInitializeLayout.bind(this);
        this.cfSearch           = this.cfSearch.bind(this);
        this.cfOnDataSelection  = this.cfOnDataSelection.bind(this);
        this.cfInitializeGroups = this.cfInitializeGroups.bind(this);
        this.cfGetDimensions    = this.cfGetDimensions.bind(this);
        this.cfAddToCollection  = this.cfAddToCollection.bind(this);
        this.cfUpdateCollection = this.cfUpdateCollection.bind(this);
        this.cfEnablePrint      = this.cfEnablePrint.bind(this);
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.pFeatureRefId !== this.state.mFeatureRefId)
            {
                this.process_close();
            }

            if(!this.props.pSelectionFlag && (lPrevProps.pAppId !== this.props.pAppId))
            {
                this.cfInitializeLayout();
            }
        }
    }

    cfInitializeLayout()
    {
        var lDefaultSearch    = false;
        var lEnablePrint      = false;
        var lPreviewCriteria = null;
        var lPrintForm        = null;
        var lDistributeData   = false;
        var lMaxGrids         = 1;
        if(this.props.pFormProperties)
        {
            var lFormProperties = null;
            if(this.props.pFormProperties instanceof Array)
            {
                lFormProperties = this.props.pFormProperties;
            }
            else
            {
                lFormProperties = [];
                lFormProperties.push(this.props.pFormProperties);
            }

            for(var ind=0; ind<lFormProperties.length; ind++)
            {
                var lProperty = lFormProperties[ind];
                if(lProperty.type_ === 'initialize')
                {
                    if (lProperty.property_ === 'service_code_')
                    {
                        var lCriteria = {}
                        var lFormCode = null;
                        
                        if(this.props.pFormCriteria && (this.props.pFormCriteria.length > 0))
                        {
                            for(var lInd=0; lInd<this.props.pFormCriteria.length; lInd++)
                            {
                                var lCriteriaObject = this.props.pFormCriteria[lInd];
                                if(lCriteriaObject.default_criteria_field_)
                                {
                                    lFormCode = lCriteriaObject.cs_form_code_;
                                    lCriteria[lCriteriaObject.default_criteria_field_] = cfGetConstantValue(lCriteriaObject.value_)
                                }
                
                                if(lCriteriaObject.hasOwnProperty('cs_form_criteria_properties_'))
                                {
                                    for(var lFcpInd=0; lFcpInd<lCriteriaObject.cs_form_criteria_properties_.length; lFcpInd++)
                                    {
                                        var lFcpObj = lCriteriaObject.cs_form_criteria_properties_[lFcpInd];
                                        if(lFcpObj.type_ === 'data_provider')
                                        {
                                            if(lFcpObj.property_ === 'selection')
                                            {
                                                if(lFcpObj.value_ === 'all')
                                                {
                                                    if(lCriteriaObject.data_provider_source_)
                                                    {
                                                        var lDataProviderList = this.mServiceUtil.mDataProviderIdListMap[lCriteriaObject.data_provider_source_];
                                                        if(lDataProviderList)
                                                        {
                                                            var tmp_val = '';
                                                            for(var lCtrlDpInd=0; lCtrlDpInd<lDataProviderList.length; lCtrlDpInd++)
                                                            {
                                                                var lCtrlDpObj = lDataProviderList[lCtrlDpInd];
                                                                if(lCtrlDpObj[lFcpObj.selection_property_])
                                                                {
                                                                    if(tmp_val)
                                                                    {
                                                                        tmp_val += ', ';
                                                                    }
                                                                    
                                                                    tmp_val += "'" + lCtrlDpObj[lFcpObj.selection_property_] + "'";
                                                                }
                                                            }
                
                                                            lCriteria[lCriteriaObject.criteria_field_] = tmp_val;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if(lProperty.cs_form_code_ === lFormCode)
                        {
                            this.cfSearch(lProperty.value_, lCriteria);
                            lDefaultSearch = true;
                        }
                    }
                    else if (lProperty.property_ === 'enable_print')
                    {
                        lEnablePrint = true;
                    }
                    else if (lProperty.property_ === 'print_form')
                    {
                        lPrintForm = lProperty.value_;
                    }
                    else if (lProperty.property_ === 'distribute_data')
                    {
                        lDistributeData = (lProperty.value_ === 'true');
                    }
                    else if (lProperty.property_ === 'max_grids')
                    {
                        lMaxGrids = Number(lProperty.value_);
                        if(lMaxGrids > 3)
                        {
                            lMaxGrids = 3;
                        }
                    }
                }
                else if(lProperty.type_ === 'preview_criteria')
                {
                    if(!lPreviewCriteria)
                    {
                        lPreviewCriteria = {}
                    }

                    lPreviewCriteria[lProperty.property_] = lProperty.value_;
                }
            }
        }

        var lFormGridObjectMap = {};
        var lDataFieldGridObjectMap = {};
        var lGroupFieldGridItemObjectMap = {};
        if(this.props.pFormGrid && (this.props.pFormGrid.length > 0))
        {
            var lGroupFields = [];
            var lHeaderColumns = null;
            var lnameColumnMap = {}

            for(var lInd0=0; lInd0<this.props.pFormGrid.length; lInd0++)
            {
                var lGridObject = this.props.pFormGrid[lInd0];
                lFormGridObjectMap[lGridObject.id_] = lGridObject;
                lDataFieldGridObjectMap[lGridObject.data_field_] = lGridObject;

                if(lGridObject.grouping_field_ === '1')
                {
                    lGroupFields.push(lGridObject.data_field_);
                    lGroupFieldGridItemObjectMap[lGridObject.data_field_] = lGridObject;
                }

                if(lGridObject.hasOwnProperty('cs_form_grid_item_properties_'))
                {
                    for(var lpInd=0; lpInd< lGridObject.cs_form_grid_item_properties_.length; lpInd++)
                    {
                        var lProperty1 = lGridObject.cs_form_grid_item_properties_[lpInd];
                        if(lProperty1.property_ === 'summary_column')
                        {
                            if(!lHeaderColumns)
                            {
                                lHeaderColumns = []
                            }

                            lGridObject.property_type_ = lProperty1.type_;
                            lHeaderColumns.push(lGridObject);

                            if((lProperty1.property_ === 'summary_column') && (lProperty1.type_ === 'header'))
                            {
                                if(lProperty1.param_ === 'name')
                                {
                                    lGridObject.property_ = lProperty1.value_;
                                    var lnColumnList = lnameColumnMap[lProperty1.value_]
                                    if(!lnColumnList)
                                    {
                                        lnColumnList = []
                                        lnameColumnMap[lProperty1.value_] = lnColumnList;
                                    }

                                    lnColumnList.push(lGridObject);
                                }
                            }
                        }
                    }
                }  
            }
    
            var lKeys = Object.keys(lnameColumnMap);
            for(var lkInd=0; lkInd<lKeys.length; lkInd++)
            {
                var lKey = lKeys[lkInd];
                var lCList = lnameColumnMap[lKey];
                for(var lspInd=0; lspInd<lCList.length; lspInd++)
                {
                    var lColumn0 = lCList[lspInd];
                    lColumn0.colSpan = lCList.length;
                }
            }            

            var lEnablePagination = true;
            var lKeyField = null;
            if(this.props.pFormGridProperties)
            {
                for(var lInd_0=0; lInd_0<this.props.pFormGridProperties.length; lInd_0++)
                {
                    var lGridProperties = this.props.pFormGridProperties[lInd_0];
                    if((lGridProperties.type_ === 'GROUP') && (lGridProperties.name_ === 'key'))
                    {
                        lKeyField = lGridProperties.value_;
                    }
                    else if ((lGridProperties.type_ === 'INITIALIZE') && (lGridProperties.name_ === 'disable_pagination') && (lGridProperties.value_ === '1'))
                    {
                        lEnablePagination = false;
                    }
                }
            }

            this.setState(
                {
                    mFormGrid                       : this.props.pFormGrid, 
                    mFormGridProperties             : this.props.pFormGridProperties,
                    mFormGridItemProperties         : this.props.pFormGridItemProperties,
                    mFormGridItemObjectMap          : lFormGridObjectMap,
                    mDataFieldGridObjectMap         : lDataFieldGridObjectMap,
                    mEnablePagination               : lEnablePagination,
                    mGroupFieldGridItemObjectMap    : lGroupFieldGridItemObjectMap,
                    mGroupFields                    : lGroupFields,
                    mKeyField                       : lKeyField,
                    mDataCollection                 : null,
                    mGroupCollection                : null,
                    mCriteriaHeight                 : 0,
                    mFormHeadingHeight              : 0,
                    mEnablePreview                  : lEnablePrint,
                    mPreviewCriteria                : lPreviewCriteria,
                    mPrintForm                      : lPrintForm,
                    mHeaderColumns                  : lHeaderColumns,
                    mMaxGrids                       : lMaxGrids,
                    mDistributeData                 : lDistributeData
                }
            );
        }

        if(!lDefaultSearch)
        {
            this.cfSetFormData(null);
        }
    }

    cfAddToCollection(lRecord, lCloseFlag){}
    cfUpdateCollection(lRecord, lCloseFlag){}
    process_set(){}

    cfGetFormItems(lActionId)
    {
        var lFormItems = [];
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.action_ref_id_ === lActionId)
                {
                    lFormItems.push(lFormItem);
                }
            }
        }
        
        return lFormItems;
    }
    
    cfEnablePrint(lFlag, lDetailsFlag)
    {
        let lDetailsMetaData = this.state.mDetailsMetaData;
        if(lDetailsMetaData)
        {
            lDetailsMetaData.pEnablePrint = lFlag;
        }

        if(!(lDetailsFlag === undefined) && !lDetailsFlag)
        {
            lDetailsFlag = null;
        }
        
        if(lFlag)
        {
            lDetailsFlag = true;
        }

        this.setState({mEnablePrint : lFlag, mDetailsFlag : lDetailsFlag, mDetailsMetaData : lDetailsMetaData});
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormActions.length; lInd++)
            {
                var lFormAction = this.state.mFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    cfSetFormData(lCollection, lDataFlag = true)
    {
        if(this.props)
        {
            var lConstList = null;
            if(this.props.hasOwnProperty('pFormGrid') && this.props.pFormGrid)
            {
                for(var lgInd=0; lgInd<this.props.pFormGrid.length; lgInd++)
                {
                    var lgObj = this.props.pFormGrid[lgInd];
                    if(lgObj.constant_ && (lgObj.type_ !== 'CONFIG'))
                    {
                        if(!lConstList)
                        {
                            lConstList = []
                        }

                        lConstList.push (lgObj);
                    }
                }
            }

            if(lConstList && lCollection)
            {
                for(var ldInd=0; ldInd<lCollection.length; ldInd++)
                {
                    var ldObj = lCollection[ldInd];
                    for(var lcInd=0; lcInd<lConstList.length; lcInd++)
                    {
                        ldObj[lConstList[lcInd].data_field_] = cfGetConstantValue(lConstList[lcInd].constant_);
                    }
                }
            }

            var lFormTitle = null;
            var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
            if(lMenuItem != null)
            {
                if(lMenuItem.title_ != null)
                {
                    lFormTitle = lMenuItem.title_;
                }
                else
                {
                    lFormTitle = lMenuItem.layout_item_name_;
                }
            }

            var lSearchActionsList = [];
            var lFormActions = this.props.pFormActions;
            if((lFormActions !== undefined) && (lFormActions !== null))
            {
                for(var ind=0; ind<lFormActions.length; ind++)
                {
                    if(lFormActions[ind].component_type_ === 'SEARCH_ACTION')
                    {
                        lSearchActionsList.push(lFormActions[ind]);
                    }
                }
            }

            var lFormGridProperties = this.props.pFormGridProperties;
            var lEnableFilter = true;
            var lSelectedDataCollection = null;
            if(lFormGridProperties)
            {
                var lName;
                var lValue;
    
                for(var lGpInd=0; lGpInd<lFormGridProperties.length; lGpInd++)
                {
                    if(lFormGridProperties[lGpInd].property_ === 'filter')
                    {
                        lEnableFilter = (lFormGridProperties[lGpInd].value_ === '0') ? false : true;
                    }
                    else if(lFormGridProperties[lGpInd].property_ === 'display_selection')
                    {
                        lName = lFormGridProperties[lGpInd].name_;
                        lValue = lFormGridProperties[lGpInd].value_;
                    }
                }

                if(lCollection)
                {
                    for(var lInd=0; lInd<lCollection.length; lInd++)
                    {
                        var lDataObj = lCollection[lInd];
                        if(lDataObj[lName] === lValue)
                        {
                            if(!lSelectedDataCollection)
                            {
                                lSelectedDataCollection = [];
                            }
    
                            lSelectedDataCollection.push(lDataObj);
                        }
                    }
                }
            }

            this.setState(
                {
                    mFeatureRefId           : this.props.pFeatureRefId,
                    mSelectedMenuItem       : lMenuItem,
                    mFormLayoutHeading      : lFormTitle,
                    mParent                 : this.props.pParent,
                    mFormCriteria           : this.props.pFormCriteria,                                    
                    mFormActions            : this.props.pFormActions,
                    mFormItems              : this.props.pFormItems,
                    mFormGrid               : (this.props.pFormGrid) ? CSUtil.cfSort(this.props.pFormGrid, 'sequence_') : [],
                    mFormProperties         : this.props.pFormProperties,
                    mFormValidation         : this.props.pFormValidation,
                    mFormSelection          : this.props.pFormSelection,
                    mFormDetails            : this.props.pFormDetails,
                    mFormActionProperties   : this.props.pFormActionProperties,
                    mFormCriteriaProperties : this.props.pFormCriteriaProperties,
                    mFormGridProperties     : this.props.pFormGridProperties,
                    mFormGridItemProperties : this.props.pFormGridItemProperties,
                    mFormItemProperties     : this.props.pFormItemProperties,
                    mFormItemComputation    : this.props.pFormItemComputation,
                    mSearchActionList       : (lSearchActionsList) ? CSUtil.cfSort(lSearchActionsList, 'sequence_') : null,
                    mDataCollection         : lCollection,
                    mSelectedData           : null,
                    mSelectedRecord         : lSelectedDataCollection,
                    mEnableFilter           : lEnableFilter
                }
            );

            this.cfInitializeGroups(lCollection);
        }
    }

    process_search(lCriteria, lActionItem)
    {
        if(lActionItem && lActionItem.on_click_service_code_)
        {
            this.setState({mCriteria : lCriteria, mActionItem : lActionItem, mShowBackdrop : true});
            this.cfSearch(lActionItem.on_click_service_code_, lCriteria);
        }
        else
        {
            this.setState({mCriteria : lCriteria, mActionItem : lActionItem});
        }
    }

    cfInitializeGroups(lCollection){ }

    cfSearch(lServiceCode, lCriteria)
    {
        if(lServiceCode)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }

                if(lCriteria)
                {
                    var lSelCritKeys = Object.keys(lCriteria);
                    var lSelCritValues = Object.values(lCriteria);
                    if(lSelCritKeys && (lSelCritKeys.length > 0))
                    {
                        for(var lKeyInd=0; lKeyInd<lSelCritKeys.length; lKeyInd++)
                        {
                            lCriteriaObj[lSelCritKeys[lKeyInd]] = lSelCritValues[lKeyInd];
                            if(CSConfigUtil.mAppCode === 'DESIGNER_STUDIO')
                            {
                                if(lSelCritKeys[lKeyInd] === 'selected_app_code_')
                                {
                                    lRequestUtil.cfSetAppCode(lSelCritValues[lKeyInd]);
                                }
                            }
                        }
                    }
                }

                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest(this.cfProcessResponse);
            }
        }
    }

    process_add(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            var lActionIdChildrenMap = {};
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if(lAction.parent_ref_id_)
                {
                    var lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if(!lChildren)
                    {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if (((lAction.component_type_ === 'DETAILS_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION')
                    ) && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_)))
                {
                    lActions.push(lAction);
                }
            }

            if(lActionIdChildrenMap && lActions)
            {
                var lChildList = null;
                for(var lPInd=0; lPInd<lActions.length; lPInd++)
                {
                    if(lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_])
                    {
                        var lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                        }

                        lChildList = [...lChildList, ...lCList];
                    }
                }

                if(lChildList)
                {
                    lActions = [...lActions, ...lChildList];
                }
            }

            let lFormFlag = 1;
            if(lActionItem.navigate_to_form_component_code_ === "CS_DETAILS_FORM_1")
            {
                lFormFlag = 2;
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pParent                 : this,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormDetails            : this.state.mFormDetails,
                pFormValidation         : this.state.mFormValidation,
                pSelectedActionItem     : lActionItem,
                pResponseFn             : this.cfAddToCollection,
                pFormMetaData           : this.props.pFormMetaData,
//                pEnablePreview          : this.state.mEnablePreview,
//                pEnablePrint            : this.state.mEnablePrint,
//                pPrintForm              : this.state.mPrintForm,
                fnEnablePrint           : this.cfEnablePrint
            };

            /*
             * This is required to pass the selected tab data to details form
             */
            var lSelectedData = null;
            if(this.props.pFormData && this.props.pFormData.hasOwnProperty('selected_data_'))
            {
                lSelectedData = this.props.pFormData.selected_data_;
            }

            var lSelectionData = null;
            if(this.props.pFormProperties && this.state.mSelectedRecord)
            {
                lSelectionData = {}
                for(var lfpInd =0; lfpInd<this.props.pFormProperties.length; lfpInd++)
                {
                    var lFormPropertyObj = this.props.pFormProperties[lfpInd];
                    if(lFormPropertyObj.type_ === 'initialize' && lFormPropertyObj.property_ === 'selection_data_field_')
                    {
                        lSelectionData[lFormPropertyObj.value_] = this.state.mSelectedRecord[lFormPropertyObj.value_];
                    }
                }
            }

            if(lSelectionData)
            {
                lDetailsMetaData.pSelectedData = lSelectionData;
            }

            if((lActionItem.on_click_request_ref_id_ && lActionItem.on_click_service_code_) || lActionItem.on_click_service_code_)
            {
                var lDataObj = {};
                var lServiceCode = lActionItem.on_click_service_code_;
                var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
                if(lServiceCode)
                {
                    var lCriteria = {};
                    if(this.state.mFormGrid)
                    {
                        for(var lGInd=0; lGInd<this.state.mFormGrid.length; lGInd++)
                        {
                            var lGridObject = this.state.mFormGrid[lGInd];
                            if(lGridObject.criteria_field_ && lGridObject.constant_)
                            {
                                lCriteria[lGridObject.criteria_field_] = cfGetConstantValue(lGridObject.constant_);
                            }
                        }
                    }
    
                    var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        var lRequestId = null;
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                        {
                            lRequestId = lRequestObject.request_id_;
                        }
            
                        var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                        if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                            {
                                lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                            }
                        }
            
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                            {
                                lDataObj.selected_criteria_ = lCriteria;
//                                lDataObj.selected_data_ = this.state.mSelectedRecord;
                                if(lResponse)
                                {
                                    var lKeys = Object.keys(lResponse);
                                    if(lKeys)
                                    {
                                        for(var lKInd=0; lKInd<lKeys.length; lKInd++)
                                        {
                                            lDataObj[lKeys[lKInd]] = lResponse[lKeys[lKInd]];
                                        }
                                    }
                                }
            
                                lDetailsMetaData.pFormData = lDataObj;

                                this.setState(
                                    {
                                        mAddFlag            : false,
                                        mDetailsFlag        : true,
                                        mFormFlag           : lFormFlag,
                                        mDetailsMetaData    : lDetailsMetaData,
                                        mSelectedActionItem : lActionItem,
                                        mSelectedData       : lDataObj
                                    }
                                );
                            }
                        );
                    }
                }
                else
                {
                    this.setState(
                        {
                            mAddFlag            : true,
                            mDetailsFlag        : true,
                            mFormFlag           : lFormFlag,
                            mDetailsMetaData    : lDetailsMetaData,
                            mSelectedActionItem : lActionItem,
                            mSelectedData       : lSelectedData
                        }
                    );
                }
            }
            else
            {
                this.setState(
                    {
                        mAddFlag            : true,
                        mDetailsFlag        : true,
                        mFormFlag           : lFormFlag,
                        mDetailsMetaData    : lDetailsMetaData,
                        mSelectedData       : lSelectedData,
                        mSelectedActionItem : lActionItem
                    }
                );
            }
        }
    }

    process_modify(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            var lActionIdChildrenMap = {};
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if(lAction.parent_ref_id_)
                {
                    var lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if(!lChildren)
                    {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if (((lAction.component_type_ === 'DETAILS_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION') 
                        || (lAction.component_type_ === 'DIALOG_HEADER_ACTION')
                        || (lAction.component_type_ === 'DETAILS_CRITERIA_ACTION')
                        || (lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION')
                    ) && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_)))
                {
                    lActions.push(lAction);
                }
            }

            if(lActionIdChildrenMap && lActions)
            {
                var lChildList = null;
                for(var lPInd=0; lPInd<lActions.length; lPInd++)
                {
                    if(lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_])
                    {
                        var lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                        }

                        lChildList = [...lChildList, ...lCList];
                    }
                }

                if(lChildList)
                {
                    lActions = [...lActions, ...lChildList];
                }
            }

            let lFormFlag = 1;
            if(lActionItem.navigate_to_form_component_code_ === "CS_DETAILS_FORM_1")
            {
                lFormFlag = 2;
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pParent                 : this,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pAllFormItems           : this.state.mFormItems,
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pSelectedMenuItem       : this.state.mSelectedMenuItem,
                pSelectedActionItem     : lActionItem,
                pResponseFn             : this.cfUpdateCollection,
                pFormMetaData           : this.props.pFormMetaData,
                pEnablePreview          : this.state.mEnablePreview,
                pEnablePrint            : this.state.mEnablePrint,
                pPrintForm              : this.state.mPrintForm,
                fnEnablePrint           : this.cfEnablePrint
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                var lServiceCode = lActionItem.on_click_service_code_;
                var lRequestObject = this.mCSServiceUtil.cfGetRequestObject(lActionItem.on_click_request_ref_id_);
                var lCriteria;
                for(var lGInd=0; lGInd<this.state.mFormGrid.length; lGInd++)
                {
                    var lGridObject = this.state.mFormGrid[lGInd];
                    if(lGridObject.criteria_field_)
                    {
                        var lCriteriaValue;
                        if(lGridObject.constant_)
                        {
                            lCriteriaValue = cfGetConstantValue(lGridObject.constant_);
                        }
                        else
                        {
                            var lSelectedRecord = null;
                            if(isArray(this.state.mSelectedRecord))
                            {
                                lSelectedRecord = this.state.mSelectedRecord[0]
                            }
                            else
                            {
                                lSelectedRecord = this.state.mSelectedRecord;
                            }

                            lCriteriaValue = lSelectedRecord[ lGridObject.data_field_ ];
                        }

                        if(lCriteriaValue)
                        {
                            if(!lCriteria)
                            {
                                lCriteria = {};
                            }

                            lCriteria[lGridObject.criteria_field_] = lCriteriaValue;
                        }
                    }
                }

                if(lCriteria && (lServiceCode || lRequestObject))
                {
                    var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        var lRequestId = null;
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                        {
                            lRequestId = lRequestObject.request_id_;
                        }

                        var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                        if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                            {
                                lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                            }
                        }

                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                            {
                                lDataObj.selected_criteria_ = lCriteria;
                                lDataObj.selected_data_ = this.state.mSelectedRecord;
                                if(lResponse)
                                {
                                    if(lRequestObject)
                                    {
                                        var lKeys = Object.keys(lResponse);
                                        if(lKeys)
                                        {
                                            for(var lKInd=0; lKInd<lKeys.length; lKInd++)
                                            {
                                                lDataObj[lKeys[lKInd]] = lResponse[lKeys[lKInd]];
                                            }
                                        }
                                    }
                                    else
                                    {
                                        if(lResponse.hasOwnProperty('collection_') && (lResponse.collection_.length > 0))
                                        {
                                            var lRespData = lResponse.collection_[0];
                                            lDataObj.collection_ = lResponse.collection_;
                                            var lRespKeys = Object.keys(lRespData);
                                            if(lRespKeys)
                                            {
                                                for(var lRKInd=0; lRKInd<lRespKeys.length; lRKInd++)
                                                {
                                                    lDataObj[lRespKeys[lRKInd]] = lRespData[lRespKeys[lRKInd]];
                                                }
                                            }
                                        }
                                    }
                                }

                                lDetailsMetaData.pFormData = lDataObj;

                                this.setState(
                                    {
                                        mAddFlag                : false,
                                        mDetailsFlag            : true,
                                        mFormFlag               : lFormFlag,
                                        mDetailsMetaData        : lDetailsMetaData,
                                        mSelectedActionItem     : lActionItem,
                                        mSelectedData           : lDataObj
                                    }
                                );
                            }
                        );
                    }
                }
                else
                {
                    lDataObj.selected_data_ = this.state.mSelectedRecord;
                    this.setState(
                        {
                            mAddFlag            : false,
                            mDetailsFlag        : true,
                            mFormFlag           : lFormFlag,
                            mDetailsMetaData    : lDetailsMetaData,
                            mSelectedActionItem : lActionItem,
                            mSelectedData       : lDataObj
                        }
                    );
                }
            }
        }
    }

    process_details(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            var lActionIdChildrenMap = {};
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if(lAction.parent_ref_id_)
                {
                    var lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if(!lChildren)
                    {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if (((lAction.component_type_ === 'DETAILS_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION') 
                        || (lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION')
                    ) && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_)))
                {
                    lActions.push(lAction);
                }
            }

            if(lActionIdChildrenMap && lActions)
            {
                var lChildList = null;
                for(var lPInd=0; lPInd<lActions.length; lPInd++)
                {
                    if(lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_])
                    {
                        var lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                        if(!lChildList)
                        {
                            lChildList = [];
                        }

                        lChildList = [...lChildList, ...lCList];
                    }
                }

                if(lChildList)
                {
                    lActions = [...lActions, ...lChildList];
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pParent                 : this,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pSelectedMenuItem       : this.state.mSelectedMenuItem,
                pSelectedActionItem     : lActionItem
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = this.state.mSelectedRecord;
                this.setState(
                    {
                        mAddFlag            : false,
                        mDetailsFlag        : true,
                        mFormFlag           : 1,
                        mDetailsMetaData    : lDetailsMetaData,
                        mSelectedActionItem : lActionItem,
                        mSelectedData       : lDataObj
                    }
                );
            }
        }
    }

    process_delete(event, lParent)
    {
        if(this.state.mParent && this.state.mParent.hasOwnProperty('process_delete'))
        {
            this.state.mParent.process_delete(event, lParent);
        }
    }

    process_import(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if ((lAction.component_type_ === 'IMPORT_ACTION')  && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_)))
                {
                    lActions.push(lAction);
                }
            }

            var lImportMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pParent                 : this,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormProperties         : this.state.mFormProperties,
                pFormGrid               : this.state.mFormGrid,
                pFormActions            : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pSelectedMenuItem       : this.state.mSelectedMenuItem,
                pSelectedActionItem     : lActionItem,
                fnProcessData           : this.cfProcessData       
            };
        
            if(lActionItem.on_click_service_code_)
            {	
                var lServiceCode = lActionItem.on_click_service_code_;
                if(lServiceCode)
                {
                    var lCriteria = {};
                    if(lActionItem.cs_form_action_properties_)
                    {
                        for(var lGInd=0; lGInd<lActionItem.cs_form_action_properties_.length; lGInd++)
                        {
                            var lActionProperiesObject = lActionItem.cs_form_action_properties_[lGInd];
                            if((lActionProperiesObject.type_ === 'item_details') && (lActionProperiesObject.property_ === 'criteria'))
                            {
                                lCriteria[lActionProperiesObject.selection_property_] = lActionProperiesObject.selection_value_;
                            }
                        }
                    }

                    var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                        if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            for(var lInd_0=0; lInd_0<lServiceProperties.length; lInd_0++)
                            {
                                lCriteria[lServiceProperties[lInd_0].property_] = cfGetConstantValue(lServiceProperties[lInd_0].value_);
                            }
                        }
            
                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest((lResponse, lCriteria) => 
                            {
                                var lDataObj = {}
                                lDataObj.selected_criteria_ = lCriteria;
                                lDataObj.selected_data_ = this.state.mSelectedRecord;
                                if(lResponse)
                                {
                                    var lKeys = Object.keys(lResponse);
                                    if(lKeys)
                                    {
                                        for(var lKInd=0; lKInd<lKeys.length; lKInd++)
                                        {
                                            lDataObj[lKeys[lKInd]] = lResponse[lKeys[lKInd]];
                                        }
                                    }

                                    this.setState(
                                        {
                                            mAddFlag            : true,
                                            mDetailsFlag        : true,
                                            mImportFlag         : true,
                                            mFormFlag           : 1,
                                            mDetailsMetaData    : lImportMetaData,
                                            mSelectedActionItem : lActionItem,
                                            mSelectedData       : lDataObj
                                        }
                                    );                    
                                }
                            }
                        );
                    }
                }
            }
            else
            {            
                this.setState(
                    {
                        mAddFlag            : true,
                        mDetailsFlag        : true,
                        mImportFlag         : true,
                        mFormFlag           : 1,
                        mDetailsMetaData    : lImportMetaData,
                        mSelectedActionItem : lActionItem,
                        mSelectedData       : null
                    }
                );
            }
        }
    }

    cfProcessResponse(response)
    {
        var lCollection = [];
        if((response) && response.hasOwnProperty("collection_"))
        {
            lCollection = response.collection_;
        }

        this.cfSetFormData(lCollection);
        this.setState({mShowBackdrop : false});
    }

    cfProcessData(lData)
    {
        return this.state.mDataCollection;
    }

    process_close(lDetailsFlag, lRefreshFlag)
    {
        if(lDetailsFlag instanceof Object)
        {
            lDetailsFlag = false;
        }
        
        if(lRefreshFlag instanceof Object)
        {
            lRefreshFlag = true;
        }

        this.cfCloseForm(lDetailsFlag, lRefreshFlag);
    }

    cfCloseForm(lDetailsFlag, lRefreshFlag, lClearSelection)
    {
        if(lDetailsFlag)
        {
            lDetailsFlag = false;
        }
        
        var lDataCollection = (lClearSelection) ? null : this.state.mDataCollection;
        this.setState(
            {
                mDetailsFlag    : lDetailsFlag, 
                mFormFlag       : 0,
                mClearSelection : lRefreshFlag,
                mSelectedData   : null,
                mSelectedRecord : null,
                mDataCollection : lDataCollection,
                mDetailsMetaData: null
            }
        );

        if(lRefreshFlag)
        {
            if(this.state.mActionItem)
            {
                this.process_search(this.state.mCriteria, this.state.mActionItem);
            }
            else
            {
                this.cfInitializeLayout();
            }
        }
    }

    cfGetDimensions(lId)
    {
        var lHeight;
        if(lId)
        {
            var lElement = document.getElementById(lId);
            if(lElement)
            {
                var lRect = lElement.getBoundingClientRect();
                if(lRect)
                {
                    lHeight = lRect.height
                }
            }
        }

        return lHeight;
    }

    cfOnDataSelection(sel_data)
    {
        if(sel_data)
        {
            this.setState({mSelectedRecord : sel_data, mClearSelection : false});
        }
    }
}

export default CSCOBaseFormLayout;
