import React, { Component } from "react"
import {TabMenu} from 'primereact/tabmenu';
import {BreadCrumb} from 'primereact/breadcrumb';

import CSUtil from "../../util/CSUtil";
import CSConfigUtil from "../../util/CSConfigUtil";
import CSServiceUtil from "../../util/CSServiceUtil";
import { fnGetFormMetaData } from "../../util/CSMiscUtil"
import { fnGetFormHeader, fnGetForm, fnGetLayout } from "../../util/CSLayoutUtil"

class CSCSODetailsTabForm1 extends Component 
{
    mTempInd = 0;
    mParInd = 10000;
    mIndex = 0;
	mNumSections 		= 3;
    mMinSectionItems 	= 7;
    mCSServiceUtil      = null;
    mDetailsIdTabMap    = {};
    
    constructor(props)
    {
        super(props);
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();
        this.mCSUtil = CSUtil.cfGetInstance();
        this.state = 
        {
            mDisplay                        : props.pShow,
            mAppId                          : props.pAppId,
            mFormHeading                    : (props.pFormHeading) ? props.pFormHeading : "",
            mOrigFormHeading                : (props.pFormHeading) ? props.pFormHeading : "",
            mFormItems                      : props.pFormItems,
            mFormDetails                    : props.pFormDetails,
            mFormData                       : (props.pFormData) ? props.pFormData : {},
            mSelectedData                   : (props.pSelectedData) ? props.pSelectedData : {},
            mFormOrigData                   : CSUtil.cfGetObjectCopy(props.pFormData),
            mFormActions                    : props.pFormActions,
            mFormProperties                 : props.pFormProperties,
            mControlType                    : props.pControlType,
            mEditable                       : props.pEditable,
            mWidth                          : props.pWidth,
            mHeight                         : props.pHeight,
            mValue                          : props.pValue,
            mType                           : null,
            mSelectedForm                   : null,
            mParentFlag                     : props.pParentFlag,
            mSelectedActionItem             : this.props.pSelectedActionItem,
            mParentTabList                  : null,
            mChildTabList                   : null,
            mChildFormDetails               : null,
            mParentActiveTab                : null,
            mChildActiveTab                 : null,
            mDetailsMetaData                : null,
            mSelectedItem                   : null,
            fnProcessClose                  : props.fnProcessClose
        }

        this.cfGetTabList                   = this.cfGetTabList.bind(this);
        this.cfGetTabMenu                   = this.cfGetTabMenu.bind(this);
        this.cfCloseForm                    = this.cfCloseForm.bind(this);
        this.cfProcessMenuClick             = this.cfProcessMenuClick.bind(this);
        this.cfProcessLayoutMetaData        = this.cfProcessLayoutMetaData.bind(this);
        this.cfProcessDetailFormMetaData    = this.cfProcessDetailFormMetaData.bind(this);
        this.cfProcessTabChange             = this.cfProcessTabChange.bind(this);

        this.process_cancel                 = this.process_cancel.bind(this);
        this.process_close                  = this.process_close.bind(this);
        
        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'initialize')
                {
                    if (lFormProperty.property_ === 'type_')
                    {
                        this.state.mType = lFormProperty.value_;
                    }
                }
            }
        }
    }

    componentDidMount()
    {
        const lParentTabList   = this.cfGetTabList(true, this.props.pFormDetails);

        if(lParentTabList)
        {
            const lActiveParentTab = lParentTabList[0];
            const lParentTabCmp = this.cfGetTabMenu(lParentTabList, true, lActiveParentTab);
            this.setState(
                {
                    mParentTabList      : lParentTabCmp,
                    mParentActiveTab    : lActiveParentTab,
                    mParentActiveItem   : lActiveParentTab.details,
                }
            );

            const lEvent = {};
            lEvent.item = lActiveParentTab;
            if(lActiveParentTab.hasOwnProperty('details'))
            {
                lEvent.item.details = lActiveParentTab.details;
                lEvent.item.parentFlag = true;
            }

            this.cfProcessMenuClick(lEvent);
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps.pApplicationWidth !== this.props.pApplicationWidth)
        {
            if(this.state.mSelectedItem && this.state.mSelectedForm)
            {
                var lDetailsFormProps = this.state.mDetailsMetaData;
                lDetailsFormProps.pApplicationWidth = this.props.pApplicationWidth;

                var lSelectedItem = this.state.mSelectedItem;
                var lLayout = null;
                if((lSelectedItem.layout_type_ === 'DETAILS') || (lSelectedItem.layout_type_ === 'DETAILS_GROUP'))
                {
                    lLayout = fnGetForm( lDetailsFormProps, this.state.mSelectedData, this.cfCloseForm);
                }
                else
                {
                    lLayout = fnGetLayout(lDetailsFormProps.pFormType, lDetailsFormProps);
                }

                this.setState({ mSelectedForm : lLayout, mDetailsMetaData : lDetailsFormProps });
            }
        }
    }

    cfCloseForm(lCloseFlag)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(true);
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    cfProcessMenuClick(lEvent)
    {
        if(lEvent.hasOwnProperty('item') && lEvent.item)
        {
            let lSelectedItem = null;
            if(lEvent.item.hasOwnProperty('details'))
            {
                lSelectedItem = lEvent.item.details;
                lSelectedItem.layout_type_ = this.mCSUtil.cfGetLayoutTypeFromCode(lSelectedItem.cs_layout_code_);
            }

            /**
             * Change title with respect to selection 
             */
            let lFormHeading = this.state.mOrigFormHeading;
            if(lFormHeading)
            {
                let lAppName = lSelectedItem.child_app_name_;
                if(!lAppName)
                {
                    lAppName = lSelectedItem.tab_label_;
                }

                if(lAppName)
                {
                    lFormHeading = lFormHeading + " / " + lAppName;
                }
            }

            if(lEvent.item.parentFlag)
            {
                /**
                 * Highlight the parent tab, if selected 
                 */
                let lActiveParentTab = null;
                let lParentTabList   = null;
                let lParentTabCmp    = null;
                lParentTabList = this.cfGetTabList(true, this.props.pFormDetails);
                if(lParentTabList)
                {
                    for(let lParInd=0; lParInd<lParentTabList.length; lParInd++)
                    {
                        if(lParentTabList[lParInd].details.id_ === lSelectedItem.id_)
                        {
                            lActiveParentTab = lParentTabList[lParInd];
                            break;
                        }
                    }

                    lParentTabCmp = this.cfGetTabMenu(lParentTabList, true, lActiveParentTab);
                }

                this.setState(
                    {
                        mFormHeading        : lFormHeading,
                        mParentTabList      : lParentTabCmp,
                        mParentActiveTab    : lActiveParentTab,
                        mParentActiveItem   : lActiveParentTab.details,
                        mChildTabList       : null,
                        mChildActiveItem    : null,
                        mSelectedForm       : null,
                        mSelectedItem       : lSelectedItem
                    }
                );
            }
            else
            {
                /**
                 * Highlight the child tab, if selected 
                 */
                let lActiveChildTab = null;
                let lChildTabList   = null;
                let lChildTabCmp    = null;
                lChildTabList = this.cfGetTabList(false, this.state.mChildFormDetails);
                if(lChildTabList)
                {
                    for(let lChiInd=0; lChiInd<lChildTabList.length; lChiInd++)
                    {
                        if(lChildTabList[lChiInd].details.id_ === lSelectedItem.id_)
                        {
                            lActiveChildTab = lChildTabList[lChiInd];
                            break;
                        }
                    }

                    lChildTabCmp = this.cfGetTabMenu(lChildTabList, false, lActiveChildTab);
                }

                this.setState(
                    {
                        mFormHeading        : lFormHeading,
                        mChildTabList       : lChildTabCmp,
                        mChildActiveTab     : lActiveChildTab,
                        mSelectedForm       : null,
                        mSelectedItem       : lSelectedItem
                    }
                );
            }

            /**
             * Change title with respect to selection 
             */
            const lAppObj = {};
            lAppObj.app_id_               = lSelectedItem.child_app_id_;
            lAppObj.app_code_             = CSConfigUtil.mAppCode;
            lAppObj.selected_item_        = lSelectedItem;
            lAppObj.parentFlag            = lEvent.item.parentFlag;
            lAppObj.user_id_              = CSConfigUtil.mUserId;

            if((lSelectedItem.layout_type_ === 'BASIC') || (lSelectedItem.layout_type_ === 'STANDARD') || (lSelectedItem.layout_type_ === 'FORM'))
            {
                fnGetFormMetaData(lAppObj, this.cfProcessLayoutMetaData)
            }
            else if((lSelectedItem.layout_type_ === 'DETAILS') || (lSelectedItem.layout_type_ === 'DETAILS_GROUP'))
            {
                fnGetFormMetaData(lAppObj, this.cfProcessDetailFormMetaData)
            }
        }
    }

    cfGetTabList(lParentFlag, lFormDetails)
    {
        let lTabList = null;
		const lSelectedActionItem = this.props.pSelectedActionItem;
        if(lFormDetails)
        {
            if(lFormDetails && (lFormDetails.length > 0))
            {
                lTabList = [];
                CSUtil.cfSort(lFormDetails, 'sequence_')
                for(let lInd=0; lInd<lFormDetails.length; lInd++)
                {
                    const lFormDetailsObj = lFormDetails[lInd];
                    let lSelFlag = false;
                    if(this.state.mParentActiveTab && this.state.mParentActiveTab.details && (this.state.mParentActiveTab.details.cs_child_form_code_ === lFormDetailsObj.cs_group_form_code_))
                    {
                        lSelFlag = true;
                    }
                    else if(lSelectedActionItem && (lSelectedActionItem.navigate_to_form_ === lFormDetailsObj.cs_group_form_code_))
					{
                        lSelFlag = true;
                    }

                    if(lSelFlag)
                    {
						const lTabObject = {};
						lTabObject.label = lFormDetailsObj.tab_label_;
						lTabObject.icon = lFormDetailsObj.picture_name_;
						lTabObject.details = lFormDetailsObj;
						lTabObject.parentFlag = lParentFlag;
						lTabObject.command = this.cfProcessMenuClick;
						lTabList.push(lTabObject);
						this.mDetailsIdTabMap[lFormDetailsObj.id_] = lTabObject;
					}
                }
            }
        }

        return lTabList;
    }

    cfGetTabMenu(lTabList, lParentFlag, lActiveTab)
    {
        const lClassName = (lParentFlag) ? 'parentMenu' : 'childMenu';
        let lTabIndex = 0;
        var lHome = null;
        var lTabs = null;
        if(lTabList)
        {
            for(var lTabInd=0; lTabInd<lTabList.length; lTabInd++)
            {
                if(lTabInd === 0)
                {
                    lHome = lTabList[0];
                    lTabs = [];
                }
                else
                {
                    lTabs.push(lTabList[lTabInd]);
                }
            }
            for(let lChiInd=0; lChiInd<lTabList.length; lChiInd++)
            {
                if(lTabList[lChiInd] === lActiveTab)
                {
                    lTabIndex = lChiInd;
                    break;
                }
            }
        }

        var lStandardTabList =
            <TabMenu 
                model       = { lTabList } 
                className   = { lClassName } 
                activeIndex = { lTabIndex }/>

        var lBreadCrumbTabList =
            <BreadCrumb  
                    model       = { lTabs }
                    className   = { lClassName } 
                    home        = { lHome }/>

        var lTabComponentList = (this.state.mType === 'bread_crumb') ? lBreadCrumbTabList : lStandardTabList;

        return ( 
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                { lTabComponentList }
            </div>            
        );
    } 

    cfProcessTabChange(lEvent)
    {
    }

    cfProcessLayoutMetaData(lResponse, lCriteria)
    {
        if(lResponse && lCriteria)
        {
            
            lResponse.pFormType           = lCriteria.selected_item_.cs_layout_code_;
            lResponse.pApplicationWidth   = this.props.pApplicationWidth;
            lResponse.pSelectedActionItem = this.state.mSelectedActionItem;
            lResponse.pSelectedData       = this.props.pSelectedData;
            lResponse.pSelectedCriteria   = this.props.pSelectedCriteria;
            const lLayout = fnGetLayout(lResponse.pFormType, lResponse);
            this.setState({ mSelectedForm : lLayout, mDetailsMetaData : lResponse });
        }
    }

    cfProcessDetailFormMetaData(lResponse, lCriteria)
    {
        if(lResponse && lCriteria && lCriteria.hasOwnProperty('selected_item_') && lCriteria.selected_item_)
        {
            const lSelectedItem = lCriteria.selected_item_;
            lResponse.pFormType = lSelectedItem.cs_layout_code_;
            if(lSelectedItem.cs_layout_code_ === 'CS_DETAILS_FORM_TAB_LAYOUT_1')
            {
                let lActiveChildTab = null;
                let lChildTabList   = null;
                let lChildTabCmp    = null;
                lChildTabList = this.cfGetTabList(false, lResponse.pFormDetails);
                if(lChildTabList && (lChildTabList.length > 0) && lSelectedItem)
                {
                    for(let lChiInd=0; lChiInd<lChildTabList.length; lChiInd++)
                    {
                        if(lChildTabList[lChiInd].details.id_ === lSelectedItem.id_)
                        {
                            lActiveChildTab = lChildTabList[lChiInd];
                            break;
                        }
                    }
    
                    if(!lActiveChildTab)
                    {
                        lActiveChildTab = lChildTabList[0];
                    }
    
                    lChildTabCmp = this.cfGetTabMenu(lChildTabList, false, lActiveChildTab);
                }
    
                this.setState(
                    {
                        mChildTabList       : lChildTabCmp, 
                        mChildFormDetails   : lResponse.pFormDetails, 
                        mChildActiveTab     : lActiveChildTab
                    }
                );

                const lEvent = {};
                lEvent.item = lActiveChildTab;
                if(lActiveChildTab && lActiveChildTab.hasOwnProperty('details'))
                {
                    lEvent.item.details = lActiveChildTab.details;
                    lEvent.item.parentFlag = false;
                }

                this.cfProcessMenuClick(lEvent);
            }
            else
            {
                let lDataObj = null;
                if(lResponse.hasOwnProperty('pFormActions'))
                {
                    const lFormActions = [];
                    let lTabActionItem = null;
                    if(lResponse.pFormActions instanceof Array)
                    {
                        for(let lActionInd=0; lActionInd<lResponse.pFormActions.length; lActionInd++)
                        {
                            if(lResponse.pFormActions[lActionInd].id_ === lSelectedItem.action_ref_id_)
                            {
                                lTabActionItem = lResponse.pFormActions[lActionInd];
                            }

                            lFormActions.push(lResponse.pFormActions[lActionInd]);
                        }
                    }

                    lResponse.pFormActions = lFormActions;
                    lResponse.pSelectedTabActionItem = lTabActionItem;
                    lResponse.pSelectedActionItem = this.state.mSelectedActionItem;
                    lResponse.pFormCode = lTabActionItem.cs_form_code_;
                    lResponse.pApplicationWidth = this.props.pApplicationWidth;
                    lResponse.pAppId = lSelectedItem.child_app_id_;
                    lResponse.pFeatureRefId = lSelectedItem.child_layout_item_ref_id_;

                    if(lTabActionItem)
                    {
                        lDataObj = this.state.mSelectedData;
                        lResponse.pSelectedCriteria = this.props.pSelectedCriteria;
                        const lLayout = fnGetForm( lResponse, lDataObj, this.cfCloseForm);
                        this.setState({ mSelectedForm : lLayout, mDetailsMetaData : lResponse });
                    }
                }
            }
        }
    }

    render() 
    {
        return (
            <div style={{backgroundColor: "#FAFAFA",  width: '100%'}}>
                { fnGetFormHeader(this.state.mFormHeading) }
                
                {this.state.mParentTabList}

                {this.state.mChildTabList}

                <div style={{height: "100%", minheight: "350px", marginTop: "0px", marginBottom: "2px"}}>
                    {this.state.mSelectedForm}
                </div>
            </div>
        );
    }
}

export default CSCSODetailsTabForm1;
