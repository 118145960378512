import React from "react"
import {Chart} from 'primereact/chart';
import CSCOPieChart from './CSCOPieChart.js'
import CSCOBarChart from './CSCOBarChart.js'

class CSCOReportChart extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mReportData             : this.props.pReportData,
            mChartType              : null,
            mChartHeading           : null,
            mReportName             : null,
            mReportCriteria         : this.props.pReportCriteria,
            mReportChart            : this.props.pReportChart,
            mReportChartProperties  : this.props.pReportChartProperties,
            mDataCollection         : this.props.pDataCollection,
            mReport                 : null,
            mIsDashboardReport      : this.props.pIsDashboardReport,
            mHeight                 : this.props.pHeight
        }

        this.cfInitializeReport = this.cfInitializeReport.bind(this);
    }

    componentDidMount()
    {
        this.cfInitializeReport();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if((lPrevProps.pReportData !== this.props.pReportData) 
            || (lPrevProps.pReportCriteria !== this.props.pReportCriteria)
                || (lPrevProps.pDataCollection !== this.props.pDataCollection))
            {
                this.cfInitializeReport();
            }
        }
    }

    cfInitializeReport()
    {
        var lReportData = this.props.pReportData;
        var lReport = null;
        if(lReportData)
        {
            var lProps = 
            {
                pReportData             : lReportData,
                pReportChart            : this.props.pReportChart,
                pReportChartProperties  : this.props.pReportChartProperties,
                pIsDashboardReport      : this.props.pIsDashboardReport,
                pDataCollection         : this.props.pDataCollection,
                pIsReportCriteria       : (this.props.pParent && this.props.pParent.state.mReportCriteriaData) ? true : false,
                pChartType              : lReportData.chart_type_
            }
        
            switch(lReportData.chart_type_)
            {
                case 'PIE':
                    lReport = <CSCOPieChart {...lProps} />
                break;
    
                case 'BAR':
                case 'COLUMN': 
                case 'MULTI_AXIS': 
                case 'STACKED': 
                    lReport = <CSCOBarChart {...lProps} />
                break;
    
                case 'AREA':
                    lReport = <Chart type="polarArea" data={lReportData} />
                break;

                case 'LINE':
                    lReport = <Chart type="line" data={lReportData} />
                break;

                case 'DOUGHNUT':
                    lReport = <Chart type="doughnut" data={lReportData} />
                break;

                case 'RADAR':
                    lReport = 
                        <div className="content-section implementation">
                            <Chart type="radar" data={lReportData} />
                        </div>
                break;

                case 'COMBO':
                    lReport = 
                        <div className="content-section implementation">
                            <Chart type="bar" data={lReportData} />
                        </div>
                break;

                default:
                break;
            }
    
            this.setState(
                {
                    mReport             : lReport, 
                    mReportData         : this.props.pReportData,
                    mColumns            : this.props.pColumns,
                    mPrintFlag          : this.props.pPrintFlag,
                    mDataCollection     : this.props.pDataCollection,
                    mChartHeading       : lReportData.heading_, 
                    mChartType          : lReportData.chart_type_, 
                    mReportName         : lReportData.report_name_,
                    mIsDashboardReport  : this.props.pIsDashboardReport
                }
            );
        }
    }

    render()
    {
        var lChart = 
            <div style={{height : this.state.mHeight, width : '100%'}}>
                {this.state.mReport}
            </div>

        return lChart;
    }
}

export default CSCOReportChart;