import React from "react"

class CSCOVerticalSection extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mInclude                : true,
            mId                     : this.props.pId,
            mAlign                  : (this.props.pAlign) ? (this.props.pAlign) : 'left',
            mBackgroundColor        : this.props.pBackgroundColor,
            mParent                 : this.props.parent,
            mChildren               : this.props.pChildren,
            mMaxWidth               : this.props.mMaxWidth,
            mMaxSectionItems        : (this.props.pMaxSectionItems) ? this.props.pMaxSectionItems + 0.29 : null ,
            mHeight                 : this.props.pHeight,
            mFormItemHeight         : (this.props.pFormItemHeight) ? this.props.pFormItemHeight : '43px',
            mWidth                  : this.props.pWidth,
            mPadding                : (this.props.pPadding) ? this.props.pPadding : '2px',
            mNumSections            : this.props.pNumSections,
            mCriteriaSectionWidth   : (this.props.pIsCriteriaSection) ? '150px' : '0px',
            mImageSectionWidth      : (this.props.pImageSectionWidth) ? this.props.pImageSectionWidth : '0px'
        };

        if((this.props.pIsCriteriaLabel !== undefined) && (!this.props.pIsCriteriaLabel))
        {
            this.state.mCriteriaSectionWidth = '60px';
        }
    }

    componentDidMount()
    {    
        this.setState({mChildren : this.props.pChildren});
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
/*        if(lPrevProps.pInclude !== this.props.pInclude)
        {
            this.setState({mInclude : this.props.pInclude});
        }

        if(lPrevProps.pId !== this.props.pId)
        {
            this.setState({mId : this.props.pId});
        }

        if(lPrevProps.pAlign !== this.props.pAlign)
        {
            this.setState({mAlign : this.props.pAlign});
        }

        if(lPrevProps.pBackgroundColor !== this.props.pBackgroundColor)
        {
            this.setState({mBackgroundColor : this.props.pBackgroundColor});
        }

        if(lPrevProps.parent !== this.props.parent)
        {
            this.setState({mParent : this.props.parent});
        }

        if(lPrevProps.pChildren !== this.props.pChildren)
        {
            this.setState({mChildren : this.props.pChildren});
        }

        if(lPrevProps.pHeight !== this.props.pHeight)
        {
            this.setState({mHeight : this.props.pHeight});
        }
            
        if(lPrevProps.pWidth !== this.props.pWidth)
        {
            this.setState({mWidth : this.props.pWidth});
        }
            
        if(lPrevProps.pNumSections !== this.props.pNumSections)
        {
            this.setState({mNumSections : this.props.pNumSections});
        }
        */
       
        if(lPrevProps.pChildren !== this.props.pChildren)
        {
            this.setState({mChildren : this.props.pChildren});
        }
    }

    cfGetMaxSectionItems(lLength)
    {
        var lRetVal = lLength;
        if(lLength <= 6)
        {
            lRetVal = lLength + 0.35;
        }
        else if((lLength > 6) && (lLength <= 14))
        {
            lRetVal = lLength - 0.15;
        }
        else if((lLength > 14) && (lLength <= 21))
        {
            lRetVal = lLength + 0.05;
        }
        else if((lLength > 21) && (lLength <= 28))
        {
            lRetVal = lLength;
        }

        return lRetVal;
    }

    render() 
    {
        var lSectionClassName="form-vsection field";
        if(this.props.pIsCriteriaSection)
        {
            lSectionClassName="form-hsection field";
        }

        return (
            <div align    = "center"
                key       = { this.state.mId }
                className = { lSectionClassName }>
                <div className="form-align">
                    {this.state.mChildren}
                </div>
            </div>
        );
    }
}

export default CSCOVerticalSection;
