import React from "react"

import { fnGetForm } from "../../util/CSLayoutUtil"
import { fnGetFormHeader } from "../../util/CSLayoutUtil"
import CSCOCriteria from "../../components/CSCOCriteria"
import CSCOTreeGrid from "../../components/CSCOTreeGrid"
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar"
import CSCOBaseFormLayout from "./CSCOBaseFormLayout"
import CSFormUtil from "../../util/CSFormUtil";
import CSUtil from "../../util/CSUtil";
import CSCOBackdrop from "../../components/CSCOBackdrop"
import { cfGetConstantValue } from "../../util/CSConstantUtil"

class CSCOAdvancedFormLayout3 extends CSCOBaseFormLayout
{
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);

        this.cfInitializeGroups   = this.cfInitializeGroups.bind(this);
        this.cfGetRequestObject   = this.cfGetRequestObject.bind(this);
        this.cfGetRequestCriteria = this.cfGetRequestCriteria.bind(this);

        this.process_save         = this.process_save.bind(this);
    }

    componentDidMount()
    {
        super.cfInitializeLayout();
    }

    cfInitializeGroups(lCollection)
    {
        var lGroupObj = null;
        if(this.state.mKeyField)
        {
            lGroupObj = this.mCSFormUtil.cfGetTreeCollection(lCollection, this.state.mGroupFields, this.state.mKeyField);
        }
        else
        {
            lGroupObj = this.mCSFormUtil.cfGetGroupedCollection(lCollection, this.state.mGroupFields, this.state.mGroupFieldGridItemObjectMap);
        }
    
        if(lGroupObj)
        {
            this.setState({mGroupCollection : lGroupObj.collection, mKeyObjectMap : lGroupObj.keyObjectMap})
        }
    }

    cfGetRequestCriteria()
    {
        var lCriteria = this.state.mCriteria;
        var lFormGridProperties = this.state.mFormGridProperties;
        if(lFormGridProperties)
        {
            for(var lfipInd=0; lfipInd<lFormGridProperties.length; lfipInd++)
            {
                var lfipObj = lFormGridProperties[lfipInd];
                if(lfipObj.property_ === 'save_criteria')
                {
                    if(lfipObj.type_ && (lfipObj.type_ !== '#CONSTANT#'))
                    {
                        if(this.state.mFormData.hasOwnProperty(lfipObj.type_))
                        {
                            var lDataObj = this.state.mFormData[lfipObj.type_];

                            if(!lCriteria)
                            {
                                lCriteria = {};
                            }
        
                            lCriteria[lfipObj.name_] = lDataObj[lfipObj.value_];
                        }                        
                    }
                    else
                    {
                        if(!lCriteria)
                        {
                            lCriteria = {}; 
                        }
    
                        lCriteria[lfipObj.name_] = cfGetConstantValue(lfipObj.value_);
                    }
                }
            }
         }

         return lCriteria;
    }

    cfGetRequestObject()
    {
        let lRetObj;
        let lCollection = this.state.mDataCollection;
        if(!lCollection)
        {
            lCollection = this.state.mSelectedRecord;
        }
        
        if(lCollection)
        {
            let lFormGridProperties = this.state.mFormGridProperties;
            if(lFormGridProperties)
            {
                for(let lfipInd=0; lfipInd<lFormGridProperties.length; lfipInd++)
                {
                    let lfipObj = lFormGridProperties[lfipInd];
                    if(lfipObj.property_ === 'save_data')
                    {
                        if(lfipObj.type_=== 'collection')
                        {
                            if((lfipObj.name_ === 'data_object_') && lfipObj.value_)
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = {}
                                }

                                lRetObj[lfipObj.value_] = lCollection;
                            }
                        }
                    }
                }
            }

            let lRequiredFields = []
            for(let lInd0=0; lInd0<this.state.mFormGrid.length; lInd0++)
            {
                let lGridObject = this.state.mFormGrid[lInd0];
                if(lGridObject.required_ === '1')
                {
                    lRequiredFields.push(lGridObject.data_field_);
                }

                if(lGridObject.hasOwnProperty('cs_form_grid_item_properties_'))
                {
                    var lFgiSequencePropMap = null;
                    var lSaveFlag = false;
                    var lFgiProperties = CSUtil.cfSortInteger(lGridObject.cs_form_grid_item_properties_, 'sequence_');
                    for(var lFgiInd=0; lFgiInd<lFgiProperties.length; lFgiInd++)
                    {
                        var lFgiProp = lFgiProperties[lFgiInd];
                        if(lFgiProp.property_ === 'collection_data')
                        {
                            if(!lFgiSequencePropMap)
                            {
                                lFgiSequencePropMap = {}
                            }

                            if(!lFgiSequencePropMap[lFgiProp.sequence_])
                            {
                                lFgiSequencePropMap[lFgiProp.sequence_] = [];
                            }

                            lFgiSequencePropMap[lFgiProp.sequence_].push(lFgiProp);
                        }
                        else if((lFgiProp.property_ === 'collection') || (lFgiProp.property_ === 'data'))
                        {
                            lSaveFlag = true;
                        }
                    }

                    if(lSaveFlag)
                    {
                        for(var ldInd0=0; ldInd0<lCollection.length; ldInd0++)
                        {
                            var ldObj0 = lCollection[ldInd0];
                            var lDataObject0 = null;
                            for(var lFgiInd0=0; lFgiInd0<lFgiProperties.length; lFgiInd0++)
                            {
                                var lFgiProp0 = lFgiProperties[lFgiInd0];
                                if(lFgiProp0.property_ === 'collection')
                                {
                                    if(!lRetObj)
                                    {
                                        lRetObj = {}
                                    }

                                    if(!lRetObj.hasOwnProperty(lGridObject.data_field_))
                                    {
                                        lRetObj[lGridObject.data_field_] = []
                                    }

                                    if(lFgiProp0.type_ === 'data_selection')
                                    {
                                        if(ldObj0[lFgiProp0.param_] && lFgiProp.value_ === '#NOT_NULL#')
                                        {
                                            if(!lDataObject0)
                                            {
                                                lDataObject0 = {}
                                            }

                                            lDataObject0[lFgiProp0.selection_property_] = ldObj0[lFgiProp0.selection_property_];
                                        }
                                        else if(ldObj0[lFgiProp0.param_] === lFgiProp.value_)
                                        {
                                            if(!lDataObject0)
                                            {
                                                lDataObject0 = {}
                                            }

                                            lDataObject0[lFgiProp0.selection_property_] = ldObj0[lFgiProp0.selection_property_];
                                        }
                                    }
                                }
                                else if(lFgiProp.property_ === 'data')
                                {
                                    if(!lRetObj)
                                    {
                                        lRetObj = {}
                                    }
    
                                    if(!lRetObj.hasOwnProperty(lGridObject.data_field_))
                                    {
                                        lRetObj[lGridObject.data_field_] = {}
                                    }

                                    lRetObj[lGridObject.data_field_][lFgiProp0.selection_property_] = ldObj0[lFgiProp0.selection_property_];
                                }
                            }

                            if(lRetObj && lRetObj[lGridObject.data_field_] && lDataObject0)
                            {
                                lRetObj[lGridObject.data_field_].push(lDataObject0);
                            }
                        }
                    }

                    for(var ldInd=0; lFgiSequencePropMap && ldInd<lCollection.length; ldInd++)
                    {
                        var ldObj = lCollection[ldInd];
                        const lKeys = Object.keys(lFgiSequencePropMap);
                        for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                        {
                            var lDataObj = {}
                            var lFgiPropertyList = lFgiSequencePropMap[lKeys[lKeyInd]];
                            for(var lTInd=0; lTInd<lFgiPropertyList.length; lTInd++)
                            {
                                var lFgiProperty = lFgiPropertyList[lTInd];
                                var lDependentObj = this.state.mFormGridItemObjectMap[lFgiProperty.dependent_item_ref_id_];
                                if(lDependentObj)
                                {
                                    if(lFgiProperty.type_ === 'data_field')
                                    {
                                        if(lFgiProperty.param_ && (lFgiProperty.value_ === 'cell_value_') && ldObj[lDependentObj.data_field_])
                                        {
                                            if(lDependentObj.item_editor_ && (lDependentObj.item_editor_ === 'MULTI_SELECT_COMBO_BOX_EDITOR_1'))
                                            {
                                                /*
                                                 * String Tokenize and add "'"
                                                 */
                                                var lString = ldObj[lDependentObj.data_field_];
                                                if(lString)
                                                {
                                                    lString = "'" + lString.split( "," ).join( "','" ) + "'";
                                                    lDataObj[lFgiProperty.param_] = lString;
                                                }
                                            }
                                            else
                                            {
                                                lDataObj[lFgiProperty.param_] = ldObj[lDependentObj.data_field_];
                                            }
                                        }
                                        else if(lDependentObj.required_ === '1')
                                        {
                                            break;
                                        }
                                        else
                                        {
                                            lDataObj[lFgiProperty.param_] = '';
                                        }
                                    }
                                    else if(lFgiProperty.type_ === 'constant')
                                    {
                                        var lConstValue = cfGetConstantValue(lFgiProperty.value_);
                                        if(lConstValue)
                                        {
                                            lDataObj[lFgiProperty.param_] = lConstValue;
                                        }
                                        else if(lDependentObj.required_ === '1')
                                        {
                                            break;
                                        }
                                        else
                                        {
                                            lDataObj[lFgiProperty.param_] = '';
                                        }
                                    }

                                    if(lTInd === lFgiPropertyList.length - 1)
                                    {
                                        if(!lRetObj)
                                        {
                                            lRetObj = {}
                                        }

                                        if(!lRetObj.hasOwnProperty(lFgiProperty.data_field_))
                                        {
                                            lRetObj[lFgiProperty.data_field_] = []
                                        }

                                        lRetObj[lFgiProperty.data_field_].push(lDataObj);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if(!lRetObj)
            {
                if(lRequiredFields.length === 0)
                {
                    lRetObj = lCollection;
                }
                else
                {
                    for(var lcInd=0; lcInd<lCollection.length; lcInd++)
                    {
                        var lCObj = lCollection[lcInd];
                        var lreqFlag = true;
                        for(var lInd1=0; lInd1<lRequiredFields.length; lInd1++)
                        {
                            if(!lCObj[lRequiredFields[lInd1]])
                            {
                                lreqFlag = false;
                                break;
                            }
                        }

                        if(lreqFlag)
                        {
                            if(!lRetObj)
                            {
                                lRetObj = [];
                            }

                            lRetObj.push(lCObj);
                        }
                    }
                }
            }
        }

        return lRetObj;
    }

    process_save(lEvent, lParent, lActionItem)
    {
        this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
            {
                if(lResult && lResult.value)
                {
                    var lRequestData = this.cfGetRequestObject();
                    var lCriteria = this.cfGetRequestCriteria();
                    this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                        (lResponse) =>
                        {
                            var lCode = null;
                            var lMsg = null;
                            var lCloseFlag = (lActionItem.new_state_ === "list")

                            if(lResponse && lResponse.code)
                            {
                                if(lResponse.code instanceof Array)
                                {
                                    lCode = lResponse.code[0];
                                    if(lResponse.message)
                                    {
                                        lMsg = lResponse.message[0];
                                    }
                                }
                                else
                                {
                                    lCode = lResponse.code;
                                    if(lResponse.message)
                                    {
                                        lMsg = lResponse.message;
                                    }
                                }
                            }
                            else
                            {
                                lCode = -1;
                            }

                            this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                            if((lCode === 1) || (lCode === '1'))
                            {
                                this.cfCloseForm(!lCloseFlag, false);
                            }
                        }, lCriteria
                    );
                }            
            }
        );
    }

    render()
    {
        var lCriteria       = null;
        var lSearchActions  = null;
        var lDetailsForm    = null;
        var lTreeGrid       = null;
        if(this.state)
        {
            if((this.state.mFormCriteria !== null) && (this.state.mFormCriteria.length > 0))
            {
                lCriteria = 
                    <div id="ID_CRITERIA" ref={this.cfGetDimensions}>
                        <CSCOCriteria 
                            id              = "ID_CRITERIA"
                            pCriteria       = {this.state.mFormCriteria}
                            pFormActions    = {this.state.mFormActions}
                            pFormProperties = {this.state.mFormProperties}
                            pParent         = {this}
                            />
                    </div>
            }

            if(this.state.mSearchActionList && (this.state.mSearchActionList.length > 0))
            {
                var lSearchActionList = this.state.mSearchActionList.map((lActionItem) => 
                    {
                        if(lActionItem.action_type_ === '1')
                        {
                            lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                            lActionItem.enabled_ = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                        }
                        else
                        {
                            lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                        }
    
                        return lActionItem;
                    }
                );
    
                lSearchActions = 
                    <CSCOBaseActionBar 
                        align       = "left"
                        pType       = 'SEARCH_ACTION_BAR'
                        parent      = {this}
                        actionList  = {lSearchActionList}
                    />;
            }

            var lSaveMultipleActions = null; 
            if(this.state.mFormActions && (this.state.mFormActions.length > 0))
            {
                var lFormActionList = [];
                for(var lFInd=0; lFInd<this.state.mFormActions.length; lFInd++)
                {
                    var lActionItem = this.state.mFormActions[lFInd];
                    if (lActionItem.component_type_ === 'SAVE_MULTIPLE_ACTION')
                    {
                        if(lActionItem.action_type_ === '1')
                        {
                            if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                            {
                                lActionItem.mEnabled = true;
                            }
                        }
                        else
                        {
                            lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                        }
    
                        lFormActionList.push(lActionItem);
                    }
                }
    
                if(lFormActionList.length > 0)
                {
                    lSaveMultipleActions = 
                        <CSCOBaseActionBar 
                            align       = "left"
                            pType       = 'SAVE_ACTION_BAR'
                            parent      = {this}
                            actionList  = {lFormActionList}
                        />;
                }
            }
    
            if(this.state.mDetailsFlag)
            {
                var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
                if(this.state.mAddFlag && this.state.mDetailsMetaData.hasOwnProperty('pSelectedData'))
                {
                    lDetailsData = this.state.mDetailsMetaData.pSelectedData;
                }
                
                lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.process_close);
            }

            var lScrollHeight = ((CSUtil.cfGetInstance()).mApplicationHeight - this.state.mCriteriaHeight - 150);
            if(this.state.mCriteriaHeight === 0)
            {
                lScrollHeight += 4;
            }
        
            lTreeGrid = 
                <CSCOTreeGrid 
                    pData               = { this.state.mGroupCollection }
                    pKeyObjectMap       = { this.state.mKeyObjectMap }
                    pFeatureRefId       = { this.state.mFeatureRefId }
                    pColumns            = { this.state.mFormGrid }
                    pHeaderColumns      = { this.state.mHeaderColumns }
                    pGroupFields        = { this.state.mGroupFields }
                    rowIndex            = { this.state.mRowIndex } 
                    pSearchActionBar    = { lSearchActions }
                    pFooter             = { lSaveMultipleActions }
                    pageSize            = { 15 }
                    onClick             = { this.cfOnDataSelection }
                    pEnableFilter       = { this.state.mEnableFilter }
                    pScrollHeight       = { lScrollHeight }
                    pEnableScroll       = { true}
                />
        }

        var lRetValue = 
            <>
                <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>

                { fnGetFormHeader(this.state.mFormLayoutHeading) }

                { lCriteria }
                
                { lTreeGrid }

                { lDetailsForm }
            </>

        return(lRetValue);
    } 
}

export default CSCOAdvancedFormLayout3;