import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import CSUtil from '../util/CSUtil';
import CSConfigUtil from "../util/CSConfigUtil"
import CSServiceUtil from '../util/CSServiceUtil'
import { Dropdown } from 'primereact/dropdown';

var gServiceUtil = CSServiceUtil.cfGetInstance();

const CompanyChange = (lProps) =>
{
    const [mCompany, setCompany]          = useState(CSUtil.mSelectedCompanyObj);
    const [mCompanyList, setCompanyList]  = useState(null);
 
    useEffect(() =>
        {
            gServiceUtil.cfGetDataFromDataProvider ('USER_COMPANY_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'USER_COMPANY_LIST')
                    {
                        setCompanyList(lCollection);
                    }
                }
            );
        }, []
    );

    const cfSetCompany = () =>
    {
        if(mCompany)
        {
            CSConfigUtil.mCompanyCode = mCompany.company_code_;
            CSConfigUtil.mCompanyName = mCompany.company_name_;
            CSConfigUtil.mCompanyObj  = mCompany;
            gServiceUtil.cfResetDataproviderList();
        }

        lProps.fnProcessClose(true);
    }

    var lFooter =
        <div className="cs-dialog-footer">
            <Button label="Save" icon={CSUtil.mNameIconMap['PI_CHECK']} disabled = {!mCompany} onClick = { () => cfSetCompany() }/>
            <Button label="Back" icon={CSUtil.mNameIconMap['PI_CARET_LEFT']} onClick = { () => lProps.fnProcessClose() }/>
        </div>

    var lForm =
        <Dialog 
            className = 'cs-dialog-1'
            minheight = '150px'
            visible   = {lProps.pShow} 
            header    = "Company Change" 
            footer    = {lFooter} 
            modal     = {true} 
            onHide    = { () => lProps.fnProcessClose() }>
            <div className = "cs-details-section-1" align="center">
                <div className  = "p-field">
                    <label htmlFor="ID_COMPANY" className="cs-details-section-label" >Company</label>
                    <Dropdown 
                        id          = "ID_COMPANY" 
                        className   = "cs-details-section-control-1" 
                        type        = "text" 
                        onChange    = {(lItem) => setCompany(lItem.value)}
                        appendTo    = {document.body}                            
                        optionLabel = 'company_name_'
                        options     = {mCompanyList}
                        value       = {mCompany}/>
                </div>
            </div>
        </Dialog>
    return lForm;
}

export default CompanyChange;
