import React from "react"
import {Toolbar} from 'primereact/toolbar';

import CSFormUtil from "../util/CSFormUtil";

class CSCOHorizontalActionBar extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mInclude            : true,
            mAlign              : this.props.align,
            mParent             : this.props.parent,
            mType               : this.props.pType,
            mChildren           : this.props.actionList,
            mHeading            : (this.props.pHeading) ? <React.Fragment>{this.props.pHeading}</React.Fragment> : null,
            mCardHeading        : (this.props.pCardHeading) ? <React.Fragment>{this.props.pCardHeading}</React.Fragment> : null,
            mPadding            : (this.props.pPadding) ? this.props.pPadding : '5px',
            mMargin             : (this.props.pMargin) ? this.props.pMargin : '2px',
            mMarginBottom       : (this.props.pMarginBottom) ? this.props.pMarginBottom : '2px',
            mBackgroundColor    : '#edf0f5 !important'
        };

        if(this.state.mBackgroundAlpha === undefined)
        {
            this.state.mBackgroundAlpha = 1;
        }

        if(this.props.pBackgroundColor)
        {
            this.state.mBackgroundColor = this.props.pBackgroundColor;
        }
    }

    componentDidMount()
    {    
        this.setState({mChildren : this.props.actionList});
    }

    
    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.setState(
                {
                    mChildren   : this.props.actionList,
                    mHeading    : this.props.pHeading
                }
            );
        }
    }

    render() 
    {
        var lLeftActionList = null;
        var lRightActionList = null;
        var lMiddleActionList = null;
        if(this.state.mChildren)
        {
            for(var lInd=0; lInd<this.state.mChildren.length; lInd++)
            {
                var lActionItem = this.state.mChildren[lInd];
                if(lActionItem.position_ === 'left')
                {
                    if(!lLeftActionList)
                    {
                        lLeftActionList = [];
                    }

                    lLeftActionList.push(CSFormUtil.cfGetButton(lActionItem, this.state.mType, 
                            (event, lAItem) =>  
                            {
                                if(lAItem.hasOwnProperty('fnProcessClick'))
                                {
                                    lAItem.fnProcessClick(event, lAItem);
                                }
                                else if(this.state.mParent && this.state.mParent.hasOwnProperty(lAItem.action_))
                                {
                                    this.state.mParent[lAItem.action_](event, this.state.mParent, lAItem);
                                }
                            }
                        )
                    );
                }
                else if(lActionItem.position_ === 'right')
                {
                    if(!lRightActionList)
                    {
                        lRightActionList = [];
                    }
                    
                    
                    lRightActionList.push(CSFormUtil.cfGetButton(lActionItem, this.state.mType, 
                            (event, lAItem) =>  
                            {
                                if(lAItem.hasOwnProperty('fnProcessClick'))
                                {
                                    lAItem.fnProcessClick(event, lAItem);
                                }
                                else if(this.state.mParent && this.state.mParent.hasOwnProperty(lAItem.action_))
                                {
                                    this.state.mParent[lAItem.action_](event, this.state.mParent, lAItem);
                                }
                            }
                        )
                    );
                }
                else if(lActionItem.position_ === 'middle')
                {
                    if(!lMiddleActionList)
                    {
                        lMiddleActionList = [];
                    }
                    
                    lMiddleActionList.push(CSFormUtil.cfGetButton(lActionItem, this.state.mType, 
                            (event, lAItem) =>  
                            {
                                if(lAItem.hasOwnProperty('fnProcessClick'))
                                {
                                    lAItem.fnProcessClick(event, lAItem);
                                }
                                else if(this.state.mParent && this.state.mParent.hasOwnProperty(lAItem.action_))
                                {
                                    this.state.mParent[lAItem.action_](event, this.state.mParent, lAItem);
                                }
                            }
                        )
                    );
                }
            }
        }

        var lToolbar = null;
        if(lLeftActionList || lRightActionList || lMiddleActionList)
        {
            let lCenter = null;
            if(lMiddleActionList)
            {
                lCenter = <React.Fragment>{lMiddleActionList}</React.Fragment>
            } 
            else
            {
                lCenter = (this.state.mHeading) ? (<React.Fragment>{this.state.mHeading}</React.Fragment>) : (<React.Fragment><h2>{this.state.mCardHeading}</h2></React.Fragment>);
            }
            lToolbar = 
                <Toolbar 
                    start = <React.Fragment>{lLeftActionList}</React.Fragment>
                    center = {lCenter}
                    end   = <React.Fragment>{lRightActionList}</React.Fragment>
                    />
        }
        
        return lToolbar;
    }
}

export default CSCOHorizontalActionBar;
