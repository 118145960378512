import React from 'react';

import CSFormUtil from "../util/CSFormUtil";

const mFormUtil         = CSFormUtil.cfGetInstance();
const mMaxSections      = 3;
const mMinSectionItems 	= 7;

const CSCODetailsSection = (lProps) =>
{
    const fnPopout = (lEvent, lFormItem) =>
    {
        if(lEvent && lFormItem && lProps.pOnPopout)
        {
            lProps.fnPopout(lEvent, lFormItem);
        }
    }

    let lForm = null;
    let lMaxSections = (lProps.pNumSections) ? lProps.pNumSections : mMaxSections;
    if(lProps.pFormItems && (lProps.pFormItems.length > 0))
    {
        if(lProps.pFormProperties)
        {
            for(let pInd=0; pInd<lProps.pFormProperties.length; pInd++)
            {
                let lProperty = lProps.pFormProperties[pInd];
                if(lProperty && (lProperty.property_ === 'max_sections_'))
                {
                    lMaxSections = Number(lProperty.value_);
                }
            }
        }

        lForm = 
            <div className="fluid flex">
                { mFormUtil.cfGetSections(lProps.pFormItems, mMinSectionItems, lMaxSections, lProps.pProcessChange, null, null, null, fnPopout) }
            </div>
    }

    return lForm;
}

export default CSCODetailsSection;
