import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import SalesMetrics from '../../business/BMS/SalesMetrics'
import MarginMetrics from '../../business/BMS/MarginMetrics'
import Compliance from '../../business/BMS/Compliance'
import BudgetPerformance from '../../business/BMS/BudgetPerformance'
import RevenueMetrics from '../../business/BMS/ReveueMetrics'
import ComplianceIcon from '../../assets/images/complianceicon.svg'
import MarginMetricsIcon from '../../assets/images/marginicon.svg'
import RevenueMetricsIcon from '../../assets/images/RevenueIcon.svg'
import BudgetPerformanceIcon from '../../assets/images/BudgetPerformanceIcon.svg'
import SalesMetricsIcon from '../../assets/images/SalesIcon.svg'
import CSUtil from '../../util/CSUtil'
import CSConfigUtil from '../../util/CSConfigUtil'
import CSRequestUtil from '../../util/CSRequestUtil'

function CSCODashboardLayout2() {
    const [mSelectedReport, setSelectedReport] = useState();
    const [mDashboardReportList, setDashboardReportList] = useState();
    const [mDashboardReportSequenceMap, setDashboardReportSequenceMap] = useState({});
    const [mWidgetList, setWidgetList] = useState();
    const [mHeading, setHeading] = useState('Sales Metrics');

    useEffect(() => {
            const lDashboardReportList = [];
            const lDashboardReportSequenceMap = {}
            const lWidgetList = [];
            for(let ldbrInd=0; ldbrInd<CSUtil.mDashboard.length; ldbrInd++)
            {
                const lDashboardReport = CSUtil.mDashboard[ldbrInd];
                if(lDashboardReport.hasOwnProperty('report_name_'))
                {
                    lDashboardReportSequenceMap[lDashboardReport.sequence_] = lDashboardReport; 
                    if((lDashboardReport.type_ === 'Table') || lDashboardReport.type_ === 'Chart')
                    {
                        lDashboardReportList.push(lDashboardReport);
                    }
                    else if(lDashboardReport.type_ === 'Widget')
                    {
                        lWidgetList.push(lDashboardReport);
                    }
                }
            }

            setDashboardReportList(lDashboardReportList);
            setDashboardReportSequenceMap(lDashboardReportSequenceMap, setDashBoardComponent(null, lDashboardReportSequenceMap));
            setWidgetList(lWidgetList);
        }, []
    );

    function setDashBoardComponent(lEvent, lDashboardReportSequenceMap) {
        let lId = null;
        if(!lEvent)
        {
            lId = '1';
        }
        else 
        {
            lDashboardReportSequenceMap = mDashboardReportSequenceMap;
            lId = lEvent.currentTarget.id;
        }

        let lReportData = lDashboardReportSequenceMap[lId];
        if(lReportData)
        {
            setHeading(lReportData.report_heading_);
            let lRequestUtil = null;
            let lCriteria = {};
            lCriteria.app_code_ = CSConfigUtil.mAppCode;
            lCriteria.report_name_ = lReportData.report_name_;
            lCriteria.selected_report_ = lReportData;

            if(lReportData.type_ === 'Table')
            {
                lRequestUtil = new CSRequestUtil(
                        'GET',
                        'REQ_GET_REPORT_DATA', 
                        'cs_report',
                        'REQ_GET_REPORT_DATA');

                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest(fnProcessReportTable);
            }
            else if(lReportData.type_ === 'Chart')
            {
                lRequestUtil = new CSRequestUtil(
                    'GET',
                    'REQ_GET_CHART_DATA', 
                    'cs_report',
                    'REQ_GET_CHART_DATA');

                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest(fnProcessReportChart);
            }            
        }
    }

    function fnProcessReportTable(lResponse, lRequestCriteria)
    {
/*         let lSelectedReport = null;
        if(lRequestCriteria)
        {
            lSelectedReport = lRequestCriteria.selected_report_;
        }

        if(lResponse)
        {
            let lReportCriteriaData = null;
            let lEnableColumnChooser = false;
            let lReportColumns = null;
    
            if(lResponse.hasOwnProperty('report_criteria_'))
            {
                lReportCriteriaData = lResponse.report_criteria_;
            }

            if(lResponse.hasOwnProperty('report_table_column_'))
            {
                lReportColumns = lResponse.report_table_column_;
                lEnableColumnChooser = true;
            }
        } */
    }

    function fnProcessReportChart(lResponse, lCriteria)
    {
        if(lCriteria && lResponse)
        {
            let lComponent             = null;
            let lReportCriteriaData    = null;
            let lReportChart           = null;
            let lReportChartProperties = null;
    
            if(lResponse.hasOwnProperty('report_criteria_'))
            {
                lReportCriteriaData = lResponse.report_criteria_;
            }

            if(lResponse.hasOwnProperty('report_chart_'))
            {
                lReportChart = lResponse.report_chart_;
            }

            if(lResponse.hasOwnProperty('report_chart_property_'))
            {
                lReportChartProperties = lResponse.report_chart_property_;
            }

            if(lCriteria.report_name_ === 'SALES_METRICS')
            {
                lComponent = 
                    <SalesMetrics 
                        pReport                 = {lCriteria.selected_report_}
                        pReportCriteria         = {lReportCriteriaData} 
                        pReportChart            = {lReportChart} 
                        pReportChartProperties  = {lReportChartProperties}
                    />
            }
            else if(lCriteria.report_name_ === 'REVENUE_METRICS')
            {
                lComponent = 
                    <RevenueMetrics 
                        pReport                 = {lCriteria.selected_report_}
                        pReportCriteria         = {lReportCriteriaData} 
                        pReportChart            = {lReportChart} 
                        pReportChartProperties  = {lReportChartProperties}
                    />
            } 
            else if(lCriteria.report_name_ === 'MARGIN_METRICS')
            {
                lComponent = 
                    <MarginMetrics 
                        pReport                 = {lCriteria.selected_report_}
                        pReportCriteria         = {lReportCriteriaData} 
                        pReportChart            = {lReportChart} 
                        pReportChartProperties  = {lReportChartProperties}
                    />
            }
            else if(lCriteria.report_name_ === 'COMPLIANCE_METRICS')
            {
                lComponent = 
                    <Compliance 
                        pReport                 = {lCriteria.selected_report_}
                        pReportCriteria         = {lReportCriteriaData} 
                        pReportChart            = {lReportChart} 
                        pReportChartProperties  = {lReportChartProperties}
                    />
            } 
            else if(lCriteria.report_name_ === 'BUDGET_PERFORMANCE')
            {
                lComponent = 
                    <BudgetPerformance 
                        pReport                 = {lCriteria.selected_report_}
                        pReportCriteria         = {lReportCriteriaData} 
                        pReportChart            = {lReportChart} 
                        pReportChartProperties  = {lReportChartProperties}
                    />
            }

            setSelectedReport(lComponent);
        }
    }

    let lSidebar =
        <div className='flex flex-column bg-primary border-round-sm justify-content-center h-23rem dashboard border-0 right-0 top-50 ' >
            <Button id="1" onClick={setDashBoardComponent} tooltip='Sales Metrics' tooltipOptions={{ position: 'left' }} className='sidebarbutton'>
                <i> <img src={SalesMetricsIcon} alt='Sales Metrics' height={32} /></i>
            </Button>

            <Button id="2" onClick={setDashBoardComponent} tooltip='Revenue Metrics' tooltipOptions={{ position: 'left' }} className='sidebarbutton' >
                <i> <img src={RevenueMetricsIcon} alt='Revenue Metrics' height={32} /></i>
            </Button>

            <Button id="3" onClick={setDashBoardComponent} tooltip='Margin Metrics' tooltipOptions={{ position: 'left' }} className='sidebarbutton' >
                <i> <img src={MarginMetricsIcon} alt='Margin Metrics' height={32} /> </i>
            </Button>
            
            <Button id="4" onClick={setDashBoardComponent} tooltip='Compliance' tooltipOptions={{ position: 'left' }} className='sidebarbutton' >
                <i> <img src={ComplianceIcon} alt='Compliance' height={32} /> </i>
            </Button>

            <Button id="5" onClick={setDashBoardComponent} tooltip='Budget Performance' tooltipOptions={{ position: 'left' }} className='sidebarbutton' >
                <i> <img src={BudgetPerformanceIcon} alt='Budget Performance' height={32} /> </i>
            </Button>
        </div>

    return (
        <div className='grid overflow-hidden'>            
            <h2 className='col-12 lg:col-12 bg-primary-light border-bottom-1 border-primary ml-2' style={{height:'10%'}}>{mHeading}</h2>
            
            <div className='col-11 lg:col-11 flex flex-wrap justify-content-center h-full '>
                {mSelectedReport}
            </div>

            <div className='col-1 lg:col-1 flex flex-wrap justify-content-end' style={{marginTop:'6rem'}}   >
                {lSidebar}
            </div>
        </div>
    )
}

export default CSCODashboardLayout2;