import CSRequestUtil from "./CSRequestUtil"
import { cfGetConstantValue, cfGetYearList} from "../util/CSConstantUtil";

class CSServiceUtil
{
    mServiceUtil                = null;
    mServiceList                = null;
    mRequestDefinitionList      = null;
    mRequestIdObjectMap         = null;
    mServiceCodeObjectMap       = {};
    mDataProviderList           = {};
    mDataProviderPropertiesList = {};
    mDataProviderObjectMap      = {};
    mCriteriaDefinitionList     = {};
    mCriteriaNameObjectMap      = {};
    mDataProviderIdCallBackMap  = {};
    mDataProviderIdListMap      = {};

    static cfGetInstance()
    {
        if(this.mServiceUtil === undefined)
        {
            this.mServiceUtil = new CSServiceUtil();
        }

        return this.mServiceUtil;
    }

    cfInitializeService(lServiceList)
    {
        if(lServiceList != null)
        {
            this.mServiceList = lServiceList;
            for(let lInd=0; lInd<lServiceList.length; lInd++)
            {
                this.mServiceCodeObjectMap[lServiceList[lInd].code_] = lServiceList[lInd];
            }
        }

        return this.mServiceCodeObjectMap;
    }

    cfGetServiceObject(lServiceCode)
    {
        let lServiceObject = null;

        if(lServiceCode != null)
        {
            lServiceObject = this.mServiceCodeObjectMap[lServiceCode];
        }

        return lServiceObject;
    }

    cfInitializeRequestDefinition(lRequestList)
    {
        if(lRequestList != null)
        {
            this.mRequestIdObjectMap = {};
            this.mRequestDefinitionList = lRequestList;
            for(let lInd=0; lInd<lRequestList.length; lInd++)
            {
                this.mRequestIdObjectMap[lRequestList[lInd].id_] = lRequestList[lInd];
            }
        }

        return this.mRequestIdObjectMap;
    }

    cfGetRequestObject(lRequestRefId)
    {
        let lRequestObject = null;

        if(lRequestRefId && this.mRequestIdObjectMap)
        {
            lRequestObject = this.mRequestIdObjectMap[lRequestRefId];
        }

        return lRequestObject;
    }

    cfInitializeDataProvider(lDataProviderList)
    {
        if(lDataProviderList != null)
        {
            this.mDataProviderList = lDataProviderList;
            for(let lInd=0; lInd<lDataProviderList.length; lInd++)
            {
                this.mDataProviderObjectMap[lDataProviderList[lInd].data_provider_id_] = lDataProviderList[lInd];
            }
        }
    }

    cfInitializeDataProviderProperties(lPropertiesList)
    {
        if(lPropertiesList && (lPropertiesList.length > 0))
        {
            this.mDataProviderPropertiesList = lPropertiesList;
            for(var lInd=0; lInd<lPropertiesList.length; lInd++)
            {
                var lProperty = lPropertiesList[lInd];
                var lDataProviderObject = this.mDataProviderObjectMap[lProperty.data_provider_id_];
                if(lDataProviderObject)
                {
                    if(!lDataProviderObject.cs_data_provider_properties_)
                    {
                        lDataProviderObject.cs_data_provider_properties_ = [];
                    }

                    lDataProviderObject.cs_data_provider_properties_.push(lProperty);
                }
            }
        }
    }

    cfGetDataProviderObject(lDataProviderId)
    {
        let lRetValue = null;
        if(lDataProviderId)
        {
            lRetValue = this.mDataProviderObjectMap[lDataProviderId];
        }

        return lRetValue;
    }
    
    cfResetDataproviderList()
    {
        this.cfInitializeDataProvider(this.mDataProviderList);
        this.cfInitializeDataProviderProperties(this.mDataProviderPropertiesList);
        if(this.mDataProviderIdListMap)
        {
            const lKeys = Object.keys(this.mDataProviderIdListMap);
            if(lKeys && (lKeys.length > 0))
            {
                for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                {
                    var lKey = lKeys[lKeyInd];
                    if(this.mDataProviderIdListMap[lKey])
                    {
                        delete this.mDataProviderIdListMap[lKey];
                    }
                }
            }

            this.mDataProviderIdListMap = {};
        }

        if(this.mDataProviderIdCallBackMap)
        {
            const lKeys0 = Object.keys(this.mDataProviderIdCallBackMap);
            if(lKeys0 && (lKeys0.length > 0))
            {
                for(var lKeyInd0=0; lKeyInd0<lKeys0.length; lKeyInd0++)
                {
                    var lKey0 = lKeys0[lKeyInd0];
                    if(this.mDataProviderIdCallBackMap[lKey0])
                    {
                        delete this.mDataProviderIdCallBackMap[lKey0];
                    }
                }
            }

            this.mDataProviderIdCallBackMap = {};
        }
    }

    cfGetDataFromDataProvider(lDataProviderId, fHandleResponse, lReload = false)
    {
        const lServiceUtil = CSServiceUtil.cfGetInstance();
        let lDataProvider = null;
        if(lDataProviderId && (fHandleResponse || lReload))
        {
            let lDataProviderList = lServiceUtil.mDataProviderIdListMap[lDataProviderId];
            if(!lDataProviderList || lReload)
            {
                let lInitFlag = true;
                if(fHandleResponse)
                {
                    var lResponseFnList = lServiceUtil.mDataProviderIdCallBackMap[lDataProviderId];
                    if(!lResponseFnList)
                    {
                        lResponseFnList = [];
                        lServiceUtil.mDataProviderIdCallBackMap[lDataProviderId] = lResponseFnList;
                        lInitFlag = false;
                    }

                    if(!lResponseFnList.includes(fHandleResponse))
                    {
                        lResponseFnList.push(fHandleResponse);
                    }
                }

                if(!lInitFlag || lReload)
                {
                    if(lDataProviderId === 'YEAR_LIST')
                    {
                        var lCallbackFnList0 = lServiceUtil.mDataProviderIdCallBackMap[lDataProviderId];
                        if(lCallbackFnList0)
                        {
                            for(var lcbInd0=0; lcbInd0<lCallbackFnList0.length; lcbInd0++)
                            {
                                const lCallBackFunction0 = lCallbackFnList0[lcbInd0];
                                lServiceUtil.mDataProviderIdListMap[lDataProviderId] = cfGetYearList();
                                lCallBackFunction0(lDataProviderId, cfGetYearList());
                            }
                        }                        
                    }
                    else
                    {
                        const lDataProviderObject = lServiceUtil.mDataProviderObjectMap[lDataProviderId];
                        if (lDataProviderObject && (lDataProviderObject.service_code_ !== null))                        
                        {
                            const lServiceObject = lServiceUtil.cfGetServiceObject(lDataProviderObject.service_code_);
                            if(lServiceObject)
                            {
                                const lRequestUtil = new CSRequestUtil('GET', lDataProviderObject.service_code_, lServiceObject.type_, null, lServiceObject);
                                if((lDataProviderObject.param_name_) || lServiceObject.hasOwnProperty('cs_service_properties_') || lDataProviderObject.hasOwnProperty('cs_data_provider_properties_'))
                                {
                                    const criteria_obj = {};
                                    if(lDataProviderObject.param_name_)
                                    {
                                        criteria_obj[lDataProviderObject.param_name_] = cfGetConstantValue(lDataProviderObject.param_value_);
                                    }
                                    else if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                                    {
                                        const lServiceProperties = lServiceObject.cs_service_properties_;
                                        for(let lInd=0; lInd<lServiceProperties.length; lInd++)
                                        {
                                            if(lServiceProperties[lInd].type_ === 'criteria')
                                            {
                                                criteria_obj[lServiceProperties[lInd].property_] = lServiceProperties[lInd].value_;
                                            }
                                        }
                                    }
/*
                                    let lUserId = criteria_obj['user_id_'];
                                    if(!lUserId)
                                    {
                                        criteria_obj['user_id_'] = CSConfigUtil.mUserId;
                                    }
*/                                    
                                    if(lDataProviderObject.hasOwnProperty('cs_data_provider_properties_'))
                                    {
                                        for(var ldpInd=0; ldpInd<lDataProviderObject.cs_data_provider_properties_.length; ldpInd++)
                                        {
                                            var lProperty = lDataProviderObject.cs_data_provider_properties_[ldpInd];
                                            criteria_obj[lProperty.param_name_] = cfGetConstantValue(lProperty.param_value_);
                                        }    
                                    }

                                    lRequestUtil.cfSetRequestCriteria(criteria_obj);
                                }

                                const lRequestKeys = {};
                                lRequestKeys.data_provider_id_ = lDataProviderId;
                                lRequestUtil.cfProcessRequest((lResponse, lCriteria)=>
                                    {
                                        if(fHandleResponse)
                                        {
                                            const lServiceUtil = CSServiceUtil.cfGetInstance();
                                            if(lResponse && lServiceUtil.mDataProviderIdCallBackMap && lDataProviderId)
                                            {
                                                var lCallbackFnList = lServiceUtil.mDataProviderIdCallBackMap[lDataProviderId];
                                                if(lCallbackFnList)
                                                {
                                                    for(var lcbInd=0; lcbInd<lCallbackFnList.length; lcbInd++)
                                                    {
                                                        const lCallBackFunction = lCallbackFnList[lcbInd];
                                                        if(lCallBackFunction && lResponse.hasOwnProperty('collection_'))
                                                        {
                                                            for(var lInd=0; lInd<lResponse.collection_.length; lInd++)
                                                            {
                                                                var ldpObj = lResponse.collection_[lInd];
                                                                ldpObj.key = lInd + 1;
                                                            }

                                                            lServiceUtil.mDataProviderIdListMap[lDataProviderId] = lResponse.collection_;
                                                            lCallBackFunction(lDataProviderId, lResponse.collection_);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        else
                                        {
                                            lServiceUtil.mDataProviderIdListMap[lDataProviderId] = lResponse.collection_;
                                        }
                                    }
                                );
                            }
                        }
                    }
                }
            }
            else
            {
                if(fHandleResponse)
                {
                    fHandleResponse(lDataProviderId, lDataProviderList);
                }
            }
        }

        return lDataProvider;
    }

    cfInitializeCriteriaDefinition(lCriteriaList)
    {
        if(lCriteriaList != null)
        {
            this.mCriteriaDefinitionList = lCriteriaList;
            for(let lInd=0; lInd<lCriteriaList.length; lInd++)
            {
                this.mCriteriaNameObjectMap[lCriteriaList[lInd].name_] = lCriteriaList[lInd];
            }     
        }
    }

    cfGetCriteriaDefinitionObject(lCriteriaName)
    {
        let lRetValue = null;
        if(lCriteriaName)
        {
            lRetValue = this.mCriteriaNameObjectMap[lCriteriaName];
        }

        return lRetValue;
    }
}

export default CSServiceUtil;
