import React from 'react';
import ReactDOM from 'react-dom/client';
import "primeflex/primeflex.css"

import CSCOLogin from "./components/CSCOLogin"
import CSRequestUtil from './util/CSRequestUtil';
import CSConfigUtil from './util/CSConfigUtil';

var lRootElement = document.getElementById('root');
if(lRootElement)
{
    const urlParams = new URLSearchParams(window.location.search);
    const lAppCode = urlParams.get('APP_CODE');
    if(lAppCode)
    {
        var lServerLocation = window.location.origin;
        if(lServerLocation)
        {
            CSRequestUtil.mServerUrl = lServerLocation;
        }

        CSConfigUtil.mAppCode = lAppCode;
        CSRequestUtil.cfSetUrls();
    }
}

ReactDOM.createRoot(document.getElementById('root')).render(<CSCOLogin />)
