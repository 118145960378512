import React from 'react'
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

function CSCOSidebar(lProps) {
    const fnHideSidebar = () => {
        lProps.fnProcessClose();
    }
    
    const fnSetFeatureId = (lEvent) => {
        lProps.fnDisplayFeature(lEvent.currentTarget.id);
    }

    return (
        <>
            <Sidebar position="right" closeIcon='pi pi-angle-double-right' visible={true} onHide={fnHideSidebar}>

                <div className="sidebar-icons vertical-align-center" >
                    <Button id='0900903' onClick={fnSetFeatureId}>
                        <i className="pi pi-calendar p-3" style={{ fontSize: '18px' }}></i>
                    </Button>

                    <Button id='0900904' onClick={fnSetFeatureId}>
                        <i className="pi pi-bell p-3" style={{ fontSize: '18px' }}></i>
                    </Button>

                    <Button id='0900905' onClick={fnSetFeatureId}>
                        <i className="pi pi-home p-3" style={{ fontSize: '18px' }}></i>
                    </Button>

                    <Button id='0900906' onClick={fnSetFeatureId}>
                        <i className="pi pi-cog p-3" style={{ fontSize: '18px' }}></i>
                    </Button>

                    <Button id='0900907' onClick={fnSetFeatureId}>
                        <i className="pi pi-heart-fill p-3" style={{ fontSize: '18px' }}></i>
                    </Button>
                </div>

            </Sidebar>
        </>
    )
}

export default CSCOSidebar;