import React from "react"

import { fnGetForm } from "../../util/CSLayoutUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import CSRequestUtil from "../../util/CSRequestUtil"

import CSCOTreeGrid from "../../components/CSCOTreeGrid"
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";

class CSCOAdvancedDetailsForm3 extends React.Component
{
    mMinSectionItems 	= 1;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
       super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mFeatureRefId               : props.pFeatureRefId,
            mAppId                      : props.pAppId,
            mNumSections                : 3,

            mSelectedMenuItem           : null,
            mFormLayoutHeading          : null,
            mParent                     : this.props.pParent,
            mFormData                   : this.props.pFormData,
            mDataCollection             : null,
            mSectionData                : null,
            mSelectedData               : null,
            mSelectedActionItem         : null,
            mFormGrid                   : null,
            mFormActions                : this.props.pFormActions,
            mFormItems                  : this.props.pFormItems,
            mFormProperties             : this.props.pFormProperties,
            mFormValidation             : this.props.pFormValidation,
            mFormSelection              : this.props.pFormSelection,
            mFormActionProperties       : this.props.pFormActionProperties,
            mFormItemProperties         : this.props.pFormItemProperties,
            mFormItemComputation        : this.props.pFormItemComputation,
            mFormDetailsActions         : null,
            mFormItemsActions           : null,
            mFormItemsSearchActions     : null,
            mFormItemsDetailsActions    : null,
            mGridHeading                : null,
            mAddFlag                    : false,
            mDetailsFlag                : false,
            mDetailsMetaData            : null,
            mSelectedRecord             : null,

            fnProcessChange             : this.props.fnProcessChange,
            fnProcessSubmit             : this.props.fnProcessSubmit,
            fnProcessClose              : this.props.fnProcessClose
        }

        this.process_search             = this.process_search.bind(this);
        this.process_add                = this.process_add.bind(this);
        this.process_modify             = this.process_modify.bind(this);
        this.process_delete             = this.process_delete.bind(this);
        this.process_details            = this.process_details.bind(this);
        this.process_close              = this.process_close.bind(this);

        this.cfGetFormHeading           = this.cfGetFormHeading.bind(this);
        this.cfGetFormItems             = this.cfGetFormItems.bind(this);
        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfCloseForm                = this.cfCloseForm.bind(this);

        this.cfProcessResponse          = this.cfProcessResponse.bind(this);
        this.cfSetFormData              = this.cfSetFormData.bind(this);
        this.cfInitializeLayout         = this.cfInitializeLayout.bind(this);
        this.cfSearch                   = this.cfSearch.bind(this);
        this.cfOnDataSelection          = this.cfOnDataSelection.bind(this);
        
        if(this.props.pFormProperties && this.props.pFormData)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'initialize')
                {
                    if (lFormProperty.property_ === 'data_')
                    {
                        this.state.mSectionData = this.props.pFormData[lFormProperty.value_];
                    }
                    else if(lFormProperty.property_ === 'collection_data_')
                    {
                        this.state.mDataCollection = this.props.pFormData[lFormProperty.value_];
                    }
                }
            }
        }
    }

    componentDidMount()
    {
        this.cfInitializeLayout();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.cfInitializeLayout();
        }
    }

    cfInitializeLayout()
    {
        var lFormTitle = null;
        var lFormGrid = [];
        var lFormDetailsActions = [];
        var lFormItemsSearchActions = [];
        var lFormItemsDetailsActions = [];
        var lGridHeading = null;

        var lSelectedActionItem = this.props.pSelectedActionItem;
        if(lSelectedActionItem)
        {
            lFormTitle = lSelectedActionItem.title_;
        }

        var lFormItemCollection = this.props.pFormItems;
        if(lFormItemCollection)
        {
            for (var lItemInd=0; lItemInd<lFormItemCollection.length; lItemInd++)
            {
                var lFormItem = lFormItemCollection[lItemInd];
                if(lFormItem.grid_display_ === '1')
                {
                    lFormItem.column_name_ = lFormItem.display_label_;
                    lFormGrid.push(lFormItem);
                }
                else if(this.state.mSectionData && this.state.mSectionData.hasOwnProperty(lFormItem.data_field_))
                {
                    lFormItem.mValue = this.state.mSectionData[lFormItem.data_field_]
                }

                if(lFormItem.control_type_ === 'collection')
                {
                    if(lFormItem.display_label_)
                    {
                        lGridHeading = lFormItem.display_label_;
                    }
                }
            }
        }

        var lFormActions = this.props.pFormActions;
        if(lFormActions)
        {
            for (var lActionInd=0; lActionInd<lFormActions.length; lActionInd++)
            {
                var lFormAction = lFormActions[lActionInd];
                if(lFormAction.component_type_ === 'DETAILS_ACTION')
                {
                    if(lFormAction.parent_ref_id_ && (this.props.pSelectedActionItem) && (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormDetailsActions.push(lFormAction);
                    }
                }
                else if(lFormAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                {
                    if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormItemsSearchActions.push(lFormAction);
                    }
                }
                else if(lFormAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION')
                {
                    if(!lFormAction.parent_ref_id_ || (lFormAction.parent_ref_id_ === this.props.pSelectedActionItem.id_))
                    {
                        lFormItemsDetailsActions.push(lFormAction);
                    }
                }
            }
        }

        if(lFormGrid)
        {
            CSUtil.cfSort(lFormGrid, 'sequence_')            
        }

        if(lFormItemsSearchActions)
        {
            CSUtil.cfSort(lFormItemsSearchActions, 'sequence_')            
        }

        if(lFormDetailsActions)
        {
            CSUtil.cfSort(lFormDetailsActions, 'sequence_')            
        }

        this.setState(
            {
                mFormLayoutHeading          : lFormTitle,
                mFormGrid                   : lFormGrid,
                mFormDetailsActions         : lFormDetailsActions,
                mFormItemsSearchActions     : lFormItemsSearchActions,
                mFormItemsDetailsActions    : lFormItemsDetailsActions,
                mGridHeading                : lGridHeading
            }
        );
    }

    cfGetFormHeading(lActionId)
    {
        var lFormTitle;
        if(lActionId !== null)
        {
            for(var lInd=0; lInd < this.state.mFormActions.length; lInd++)
            {
                var lFormAction = this.state.mFormActions[lInd];
                if(lFormAction.id_ === lActionId)
                {
                    lFormTitle = lFormAction.title_;
                    break;
                }
            }
        }

        return lFormTitle;
    }

    cfSetFormData(lCollection)
    {
        if(this.props !== null)
        {
            var lMenuItem = this.mCSUtil.cfGetMenuItem(this.props.pFeatureRefId);
            if(lMenuItem != null)
            {
                var lSearchActionsList = [];
                var lFormActions = this.props.pFormActions;
                if((lFormActions !== undefined) && (lFormActions !== null))
                {
                    for(var ind=0; ind<lFormActions.length; ind++)
                    {
                        if(lFormActions[ind].component_type_ === 'SEARCH_ACTION')
                        {
                            lSearchActionsList.push(lFormActions[ind]);
                        }
                    }
                }

                this.setState(
                    {
                        mFeatureRefId           : this.props.pFeatureRefId,
                        mSelectedMenuItem       : lMenuItem,
                        mSelectedActionItem     : null,
                        mParent                 : this.props.pParent,
                        mFormActions            : this.props.pFormActions,
                        mFormItems              : this.props.pFormItems,
                        mFormGrid               : this.props.pFormGrid,
                        mFormProperties         : this.props.pFormProperties,
                        mFormValidation         : this.props.pFormValidation,
                        mFormSelection          : this.props.pFormSelection,
                        mFormActionProperties   : this.props.pFormActionProperties,
                        mFormGridProperties     : this.props.pFormGridProperties,
                        mFormGridItemProperties : this.props.pFormGridItemProperties,
                        mFormItemProperties     : this.props.pFormItemProperties,
                        mFormItemComputation    : this.props.pFormItemComputation,
                        mSearchActionList       : lSearchActionsList,
                        mDataCollection         : lCollection
                    }
                );
            }
        }
    }

    process_search(lCriteria, lActionItem)
    {
        this.setState({mCriteria : lCriteria, mActionItem : lActionItem});
        if((lActionItem !== undefined) && (lActionItem !== null) && (lActionItem.on_click_service_code_ !== null))
        {
            this.cfSearch(lActionItem.on_click_service_code_, lCriteria);
        }
    }

    cfSearch(lServiceCode, lCriteria)
    {
        if(lServiceCode !== null)
        {
            var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
            if(lServiceObject !== null)
            {
                var lCriteriaObj = (lCriteria) ? lCriteria : {};
                var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                {
                    var lServiceProperties = lServiceObject.cs_service_properties_;
                    if(lServiceProperties !== null)
                    {
                        for(var lInd=0; lInd<lServiceProperties.length; lInd++)
                        {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }
                
                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest(this.cfProcessResponse);
            }
        }
    }

    process_add(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation,
                pResponseFn             : this.cfAddToCollection
            };

            this.setState(
                {
                    mAddFlag             : true,
                    mDetailsMetaData     : lDetailsMetaData,
                    mDetailsFlag         : true,
                    mSelectedActionItem  : lActionItem
                }
            );
        }
    }

    process_modify(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }
            
            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = this.state.mSelectedRecord;
                this.setState(
                    {
                        mAddFlag             : false,
                        mDetailsMetaData     : lDetailsMetaData,
                        mDetailsFlag         : true,
                        mSelectedData        : lDataObj,
                        mSelectedActionItem  : lActionItem
                    }
                );
            }
        }
    }

    process_details(event, lParent, lActionItem)
    {
        if(event && event.currentTarget && lParent && lActionItem)
        {
            event.preventDefault();
            var lActions = [];
            for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
            {
                var lAction = this.state.mFormActions[lInd];
                if((lAction.component_type_ === 'DETAILS_ITEM_ACTION') && (lAction.parent_ref_id_ === event.currentTarget.id))
                {
                    lActions.push(lAction);
                }
            }

            var lDetailsMetaData = 
            {
                pAppId                  : this.state.mAppId,
                pFormType               : lActionItem.navigate_to_form_component_code_,
                pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                pFormActions            : CSUtil.cfSort(lActions, 'sequence_'),
                pFormProperties         : this.state.mFormProperties,
                pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                pFormItemProperties     : this.state.mFormItemProperties,
                pFormItemComputation    : this.state.mFormItemComputation,
                pFormSelection          : this.state.mFormSelection,
                pFormValidation         : this.state.mFormValidation
            };

            if(this.state.mSelectedRecord)
            {
                var lDataObj = {};
                lDataObj.selected_data_ = this.state.mSelectedRecord;
                this.setState(
                    {
                        mAddFlag            : false,
                        mDetailsMetaData    : lDetailsMetaData,
                        mDetailsFlag        : true,
                        mSelectedData       : lDataObj,
                        mSelectedActionItem : lActionItem
                    }
                );
            }
        }
    }

    process_delete(event, lParent)
    {
        if(this.state.mParent && this.state.mParent.hasOwnProperty('process_delete'))
        {
            this.state.mParent.process_delete(event, lParent);
        }
    }

    cfProcessResponse(response)
    {
        var lCollection = [];
        if((response != null) && response.hasOwnProperty("result") && response.result.hasOwnProperty("collection_") )
        {
            if( (response.result.collection_ !== undefined) || (response.result.collection_ !== null))
            {
                if(response.result.collection_ instanceof Array)
                {
                    lCollection = response.result.collection_;
                }
                else
                {
                    lCollection.push(response.result.collection_);
                }
            }
        }

        this.cfSetFormData(lCollection);
    }

    cfCloseForm(lDetailsFlag)
    {
        this.setState({ mDetailsFlag : lDetailsFlag });
    }

    process_close(lEvent, lParent, lActionItem)
    {
        if(this.state.fnProcessClose)
        {
            this.state.fnProcessClose(lEvent, lParent, lActionItem);
        }
    }

    cfOnDataSelection(sel_data)
    {
        if(sel_data)
        {
            this.setState({mSelectedRecord : sel_data});
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            if(this.state.mFormData !== null)
            {
                var lFormData = this.state.mFormData;
                lFormData[lData.data_field_] = lData.value_;
                this.setState ({mFormData : lFormData});
            }
        }
    }

    cfGetFormItems(lActionId)
    {
        var lFormItems = [];
        if(lActionId)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.parent_id_)
                {
                    lFormItems.push(lFormItem);
                }
            }
        }
        
        return lFormItems;
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render()
    {
        var lFormItemSection                = null;
        var lFormHeading                    = null;
        var lFormDetailsItemSearchActions   = null;
        var lGroupFields                    = [];
//        var lKeyField                       = null;
        var lTreeGrid                       = null;
        var lDetailsActions                 = null;
        var lDetailsForm                    = null;
        var lGroupFieldGridItemObjectMap    = {};

        if(this.state.mFormItems && (this.state.mFormItems.length > 0))
        {
            var lFormItems = [];
            CSUtil.cfSort(this.state.mFormItems, 'sequence_');
            for(var lInd=0; lInd<this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if((!lFormItem.parent_id_) && (lFormItem.visible_ === '1') && (lFormItem.control_type_ !== 'collection'))
                {
                    lFormItems.push(lFormItem);
                }
                else if((lFormItem.control_type_ === 'collection'))
                {
                    lFormHeading = lFormItem.display_label_;
                }

                if(lFormItem.hasOwnProperty('cs_form_item_properties_'))
                {
                    for(var lfipInd=0; lfipInd<lFormItem.cs_form_item_properties_.length; lfipInd++)
                    {
                        var lfipObj = lFormItem.cs_form_item_properties_[lfipInd];
                        if(lfipObj.property_ === 'grouping_field')
                        {
                            lGroupFields.push(lFormItem.data_field_);
                            lGroupFieldGridItemObjectMap[lFormItem.data_field_] = lFormItem;
                        }
                    }
                }
            }

            if(lFormItems.length > 0)
            {
                CSUtil.cfSort(lFormItems, 'sequence_');
                var lFISectionFields = this.mFormUtil.cfGetDetailsSections(lFormItems, this.mMinSectionItems, this.cfProcessChange, "form_criteria_label");
                if(lFISectionFields)
                {
                    lFormItemSection = 
                        <div className="form-criteria">
                            {lFISectionFields}
                        </div>
                }
            }
        }

        if(this.state.mFormGrid)
        {
            for(var lGInd=0; lGInd<this.state.mFormGrid.length; lGInd++)
            {
                var lGridItem = this.state.mFormGrid[lGInd];
                if(lGridItem.type_ === 'grouping_field')
                {
                    lGroupFields.push(lGridItem.data_field_);
                }
            }

            if((lGroupFields.length > 0) && (this.state.pFormGridProperties))
            {
                for(var lInd_0=0; lInd_0<this.state.pFormGridProperties.length; lInd_0++)
                {
                    var lGridProperties = this.state.pFormGridProperties[lInd_0];
                    if((lGridProperties.type_ === 'GROUP') && (lGridProperties.name_ === 'key'))
                    {
//                        lKeyField = lGridProperties.value_;
                    }
                }
            }

            if(this.state.mDataCollection)
            {
                for(var lkInd=0; lkInd<this.state.mDataCollection.length; lkInd++)
                {
                    var ldObj = this.state.mDataCollection[lkInd];
                    ldObj['_key_'] = lkInd + 1;
                    ldObj.key = lkInd + 1;
                }
            }

            var lGroupObj = this.mFormUtil.cfGetGroupedCollection(this.state.mDataCollection, lGroupFields, lGroupFieldGridItemObjectMap);
//            var lGroupObj = this.mFormUtil.cfGetGroupCollection(this.state.mDataCollection, lGroupFields, '_key_');
            var lCollection = null;
            var lKeyObjectMap = null;
            if(lGroupObj)
            {
                lCollection = lGroupObj.collection;
                lKeyObjectMap = lGroupObj.keyObjectMap;
            }
            
            if(!lCollection)
            {
                lCollection = this.state.mDataCollection;
            }
    
            if(!lCollection)
            {
                lCollection = [];
            }
    
            lTreeGrid = 
                <CSCOTreeGrid 
                    pData           = {lCollection}
                    pKeyObjectMap   = {lKeyObjectMap}
                    pColumns        = {this.state.mFormGrid}
                    pGroupFields    = {lGroupFields}
                    pageSize        = {15}
                    onClick         = {this.cfOnDataSelection}
                />
        }

        if(this.state.mFormItemsSearchActions && (this.state.mFormItemsSearchActions.length > 0))
        {
            var lFormDetailsItemSearchActionList = this.state.mFormItemsSearchActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormDetailsItemSearchActions = 
                <CSCOHorizontalActionBar
                    align       = "right"
                    pType       = 'SEARCH_ACTION_BAR'
                    pHeading    = {lFormHeading}
                    parent      = {this}
                    actionList  = {lFormDetailsItemSearchActionList}
                />;
        }

        if(this.state.mFormDetailsActions && (this.state.mFormDetailsActions.length > 0))
        {
            var lFormDetailsItemActionsList = this.state.mFormDetailsActions.map((lDetailsActionItem) => 
                {
                    if(lDetailsActionItem.action_type_ === '1')
                    {
                        lDetailsActionItem.mEnabled = CSFormUtil.cfEnableAction(lDetailsActionItem, this.state.mSectionData, this.state.mSectionFormItems);
                        if(lDetailsActionItem.mEnabled)
                        {
                            if(this.state.mCollectionFormItem && (this.state.mCollectionFormItem.required_ === '1'))
                            {
                                lDetailsActionItem.mEnabled = (this.state.mDataCollection && (this.state.mDataCollection.length > 0));
                            }
                        }
                    }
                    else
                    {
                        lDetailsActionItem.mEnabled = (lDetailsActionItem.enabled_ === '1') ? true : false;
                    }

                    return lDetailsActionItem;
                }
            );

            lDetailsActions = 
                <CSCOHorizontalActionBar 
                    align       = "right"
                    pType       = 'DETAILS_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormDetailsItemActionsList}
                />
        }      

        if(this.state.mDetailsFlag)
        {
            var lDetailsData = (this.state.mAddFlag) ? null : this.state.mSelectedData;
            if(this.state.mDetailsMetaData && this.state.mDetailsMetaData.hasOwnProperty('pFormType'))
            {
                lDetailsForm = fnGetForm(this.state.mDetailsMetaData, lDetailsData, this.cfCloseForm);
            }
        }

        return(
            <>
                { lFormItemSection }

				{ lFormDetailsItemSearchActions }

                { lTreeGrid }

                { lDetailsActions }
                
                { lDetailsForm }
            </>
        );
    }
}

export default CSCOAdvancedDetailsForm3;