import React from "react"

import CSFormUtil from "../util/CSFormUtil";

class CSCOBaseActionBar extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            mInclude          : true,
            mAlign            : this.props.align,
            mParent           : this.props.parent,
            mType             : this.props.pType,
            mChildren         : this.props.actionList,
            mHeading          : this.props.pHeading,
            mBackgroundColor  : this.props.backgroundColor,
            mBorderColor      : this.props.borderColor,
            mPadding          : this.props.pPadding
        };
    }

    componentDidMount()
    {    
        this.setState({mChildren : this.props.actionList});
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(lPrevState.mChildren !== this.state.mChildren)
            {
                this.setState(
                    {
                        mChildren   : this.props.actionList
                    }
                );
            }
            
            if(lPrevState.mHeading !== this.state.mHeading)
            {
                this.setState(
                    {
                        mHeading    : this.props.pHeading
                    }
                );
            }
        }
    }

    render()
    {
        return (                
            <div className="p-toolbar-group-right" style={{justifyContent:this.state.mAlign, backgroundColor:this.state.mBackgroundColor, border:this.state.mBorderColor, padding:this.state.mPadding}}>
                {
                    (this.props.actionList) 
                        ? this.props.actionList.map((lActionItem) => CSFormUtil.cfGetButton(lActionItem, this.state.mType, 
                                (event) => 
                                    {
                                        if(lActionItem.hasOwnProperty('fnProcessClick'))
                                        {
                                            lActionItem.fnProcessClick(event, lActionItem);
                                        }
                                        else if(this.state.mParent && this.state.mParent.hasOwnProperty(lActionItem.action_))
                                        {
                                            this.state.mParent[lActionItem.action_](event, this.state.mParent, lActionItem);
                                        }
                                    }
                                )
                            ) 
                        : null
                }
            </div>
        );
    }
}

export default CSCOBaseActionBar;
