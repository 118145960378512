import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"
import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";

class CSCODetailsForm9 extends Component
{
    mComponentCode      = 'CS_DETAILS_FORM_LAYOUT_9';
    mMaxSections        = 4;
    mMinSectionItems 	= 6;
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
        super(props);
        this.state = 
        {
            mDisplay        	: this.props.pShow,
            mAppId          	: this.props.pAppId,
            mFormHeading    	: (this.props.pFormHeading) ? this.props.pFormHeading : "",
            mFormCode       	: this.props.pFormCode,
            mFormData       	: (this.props.pFormData) ? this.props.pFormData : {},
            mFormOrigData   	: CSUtil.cfGetObjectCopy(this.props.pFormData),
            mFormActions    	: this.props.pFormActions,
            mFormProperties 	: this.props.pFormProperties,
            mFormItemProperties	: this.props.pFormItemProperties,
            mFormItems         	: null,
            mWidth             	: this.props.pWidth,
            mHeight            	: this.props.pHeight,

        }

        this.cfProcessChange    = this.cfProcessChange.bind(this);
        this.process_cancel     = this.process_cancel.bind(this);
        this.cfSetControlValue  = this.cfSetControlValue.bind(this);

        if(this.props.pFormItems)
        {
            var lFormItemList = [];
            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                if(lFormItem.cs_form_component_code_ === this.mComponentCode)
                {
                    lFormItemList.push(lFormItem);
                }
            }

            this.state.mFormItems = lFormItemList;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if(lFormProperty.type_ === 'layout')
                {
                    if (lFormProperty.property_ === 'max_sections_')
                    {
                        this.mMaxSections = lFormProperty.value_;
                    }

                    if(lFormProperty.property_ === 'min_section_items_')
                    {
                        this.mMinSectionItems = lFormProperty.value_;
                    }
                }
            }
        }
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.pFormItems)
            {
                var lFormItemList = [];
                for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
                {
                    var lFormItem = this.props.pFormItems[lInd];
                    if(lFormItem.cs_form_code_ === this.mFormCode)
                    {
                        lFormItemList.push(lFormItem);
                    }
                }

                this.setState({mFormItems : lFormItemList});
            }

            if(this.props.pFormProperties)
            {
                for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
                {
                    var lFormProperty = this.props.pFormProperties[lInd_0];
                    if(lFormProperty.type_ === 'layout')
                    {
                        if (lFormProperty.property_ === 'max_sections_')
                        {
                            this.mMaxSections = lFormProperty.value_;
                        }
    
                        if(lFormProperty.property_ === 'min_section_items_')
                        {
                            this.mMinSectionItems = lFormProperty.value_;
                        }
                    }
                }
            }
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            if(this.state.mFormData !== null)
            {
                var lFormData = this.state.mFormData;
                lFormData[lData.data_field_] = lData.value_;
                this.setState ({mFormData : lFormData});
            }
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mFormData;
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm()
    {
        this.setState({mDisplay : false});
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormActions = null;
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            var lFormActionList = this.state.mFormActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mFormData, this.state.mFormItems);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormActions = 
                <CSCOHorizontalActionBar 
                    align       = "left"
                    pType       = 'SAVE_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormActionList}
                />;
        }

        let lHeaderActions = 
        <div style={{verticalAlign: 'middle'}}>            
            <Button
                key = "21"
                style=
                    {
                        {
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => this.process_close(false, true) }
            />
        </div>

    let lHeader = 
        <div className='flex justify-content-between'>
            <div className="p-toolbar-group-left">
                <h2>{ this.state.mFormHeading || '.' }</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </div>

        var lSection = null;
        if(lFormItemList.length > 0)
        {
            lSection = 
                <Dialog visible={this.state.mDisplay}
                        modal={true}
                        style=
                        {
                            {
                                minWidth : 'auto',
                                minheight : 'auto', 
                                paddingBottom: -40,
                                backgroundColor: "#D8D8D8"
                            }
                        }
                        minheight   = 'auto'
                        appendTo    = { document.body }
                        header      = {lHeader}
                        closable    = { false }
                        padding     = '10px'
                        onHide      = {() => this.cfCloseForm()}
                        footer      = { lFormActions }
                        >
                    <div className="form-item-1">
                        { this.mFormUtil.cfGetSections(lFormItemList, this.mMinSectionItems, this.mMaxSections, this.cfProcessChange, '300px') }
                    </div>
                </Dialog>
        }

        return lSection;
    }
}

export default CSCODetailsForm9;
