import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { TabMenu } from 'primereact/tabmenu';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import CSDao from './CSDao';
import CSUtil from '../util/CSUtil';
import CSQuery from './CSQuery';
import CSCLIScript from './CSCLIScript';
import CSServiceUtil from '../util/CSServiceUtil'

var gServiceUtil = CSServiceUtil.cfGetInstance();

const CSSolutionServices = (lProps) =>
{
    const [mServiceGroupList, setServiceGroupList] = useState(null);
    const [mServiceHandlerList, setServiceHandlerList] = useState(null);
    const [mServiceTypeList, setServiceTypeList] = useState(null);
    const [mServiceMethodList, setServiceMethodList] = useState(null);

    const [mSelectedData, setSelectedData] = useState(null);
    const [mServiceCode, setServiceCode] = useState('');
    const [mServiceName, setServiceName] = useState('');
    const [mSelectedServiceGroup, setSelectedServiceGroup] = useState(null);
    const [mSelectedServiceHandler, setSelectedServiceHandler] = useState(null);
    const [mSelectedServiceType, setSelectedServiceType] = useState(null);
    const [mSelectedServiceMethod, setSelectedServiceMethod] = useState(null);
    const [mEnableDao, enableDao] = useState(false);
    const [mEnableQuery, enableQuery] = useState(false);
    const [mEnableScript, enableScript] = useState(false);

    const mServiceMethodObjMap = {};
    const mServiceHandlerObjectMap = {};
    const mServiceGroupObjectMap = {};

    useEffect(() =>
        {
            gServiceUtil.cfGetDataFromDataProvider ('SERVICE_METHOD_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'SERVICE_METHOD_LIST')
                    {
                        for(var lInd=0; lInd<lCollection.length; lInd++)
                        {
                            mServiceMethodObjMap[lCollection[lInd].code_] = lCollection[lInd];
                        }

                        if(mSelectedData)
                        {
                            var lObj = mServiceMethodObjMap[mSelectedData.method_];
                            setSelectedServiceMethod(lObj);
                        }
                        
                        setServiceMethodList(lCollection);
                    }
                }
            );

            gServiceUtil.cfGetDataFromDataProvider ('SERVICE_TYPE_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'SERVICE_TYPE_LIST')
                    {                        
                        setServiceTypeList(lCollection);
                    }
                }
            );

            gServiceUtil.cfGetDataFromDataProvider ('SERVICE_HANDLER_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'SERVICE_HANDLER_LIST')
                    {
                        for(var lInd=0; lInd<lCollection.length; lInd++)
                        {
                            mServiceHandlerObjectMap[lCollection[lInd].code_] = lCollection[lInd];
                        }

                        if(mSelectedData)
                        {
                            var lObj = mServiceHandlerObjectMap[mSelectedData.service_handler_];
                            setSelectedServiceHandler(lObj);
                        }
                        
                        setServiceHandlerList(lCollection);
                    }
                }
            );

            gServiceUtil.cfGetDataFromDataProvider ('SERVICE_GROUP_LIST', 
                (lDataProviderId, lCollection) => 
                {
                    if(lDataProviderId === 'SERVICE_GROUP_LIST')
                    {
                        for(var lInd=0; lInd<lCollection.length; lInd++)
                        {
                            mServiceGroupObjectMap[lCollection[lInd].code_] = lCollection[lInd];
                        }

                        if(mSelectedData)
                        {
                            var lObj = mServiceGroupObjectMap[mSelectedData.service_group_];
                            setSelectedServiceGroup(lObj);
                        }
                        
                        setServiceGroupList(lCollection);
                    }
                }
            );
        }, []
    );

    useEffect(() =>
        {
            const fnInitializeForm = () => 
            {
                if(lProps.pSelectedData)
                {
                    var lSelectedData = (lProps.pSelectedData.hasOwnProperty('selected_data_')) ? lProps.pSelectedData.selected_data_ : lProps.pSelectedData;
                    if(lSelectedData)
                    {
                        setSelectedData(lSelectedData);
                        if(lSelectedData.hasOwnProperty('code_'))
                        {
                            setServiceCode(lSelectedData.code_);
                        }

                        if(lSelectedData.hasOwnProperty('name_'))
                        {
                            setServiceName(lSelectedData.name_);
                        }

                        if(lSelectedData.hasOwnProperty('type_'))
                        {
                            setSelectedServiceType(lSelectedData.type_);
                        }

                        if(lSelectedData.hasOwnProperty('service_handler_'))
                        {
                            var lServiceHandlerObj = mServiceHandlerObjectMap[lSelectedData.service_handler_];
                            if(lServiceHandlerObj)
                            {
                                setSelectedServiceHandler(lServiceHandlerObj);
                            }
                        }

                        if(lSelectedData.hasOwnProperty('method_'))
                        {
                            var lMethodObj = mServiceMethodObjMap[lSelectedData.method_];
                            if(lMethodObj)
                            {
                                setSelectedServiceMethod(lMethodObj);
                            }
                        }

                        if(lSelectedData.hasOwnProperty('service_group_'))
                        {
                            var lServiceGroupObj = mServiceGroupObjectMap[lSelectedData.service_group_];
                            if(lServiceGroupObj)
                            {
                                setSelectedServiceGroup(lServiceGroupObj);
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, []
    );

    const fnProcessDao = () =>
    {

    }

    const fnProcessQuery = () =>
    {

    }

    const fnProcessScript = () =>
    {

    }
    
    const mTabs = [
        {key: '1', label: 'Dao',        icon: 'fa fa-database',         command: fnProcessDao,      disabled: !mEnableDao,      className:(mEnableDao) ? 'cs-tab-menu-item' : 'cs-tab-menu-item-disabled'},
        {key: '2', label: 'Query',      icon: 'fa fa-file-code-o',      command: fnProcessQuery,    disabled: !mEnableQuery,    className:(mEnableQuery) ? 'cs-tab-menu-item' : 'cs-tab-menu-item-disabled'},
        {key: '3', label: 'CLI Script', icon: 'fa fa-pencil-square-o',  command: fnProcessScript,   disabled: !mEnableScript,   className:(mEnableScript) ? 'cs-tab-menu-item' : 'cs-tab-menu-item-disabled'},
    ];
 
    const [mActiveTab, setActiveTab] = useState(null);
    
    const mDao = 
        <div key="1" style={{width: '100%', height: '330px', overflowY: 'auto'}}>
            <CSDao />
        </div>

    const mQuery = 
        <div key="2" style={{width: '100%', height: '330px', overflowY: 'auto'}}>
            <CSQuery />
        </div>

    const mScript = 
        <div key="3" style={{width: '100%', height: '330px', overflowY: 'auto'}}>
            <CSCLIScript />
        </div>

    const [mSelectedTab, setSelectedTab] = useState(mDao);
    const fnProcessTabChange = (lEvent) =>
    {
        if(lEvent && lEvent.hasOwnProperty('value'))
        {
            var lSelectedTab = null;
            if(lEvent.value.label === 'Dao')
            {
                lSelectedTab = mDao;
            }
            else if(lEvent.value.label === 'Query')
            {
                lSelectedTab = mQuery;
            }
            else if(lEvent.value.label === 'CLI Script')
            {
                lSelectedTab = mScript;
            }

            setActiveTab(lEvent.value);
            setSelectedTab(lSelectedTab);
        }
    }

    const fnEnableTab = (lSelectedMethod) =>
    {
        if(lSelectedMethod)
        {
            setSelectedServiceMethod(lSelectedMethod);

            switch (lSelectedMethod.code_)
            {
                case 'add'   :
                case 'modify':
                case 'delete':
                case 'get'   :
                case 'save'  :
                    enableDao(true);
                    enableQuery(false);
                    enableScript(false);
                    setSelectedTab(mDao);
                    setActiveTab(mTabs[0]);
                break;
                
                case 'search'  :
                case 'ins_query'  :
                    enableDao(false);
                    enableQuery(true);
                    enableScript(false);
                    setSelectedTab(mQuery);
                    setActiveTab(mTabs[1]);
                break;
                
                case 'execute'  :
                    enableDao(false);
                    enableQuery(false);
                    enableScript(true);
                    setSelectedTab(mScript);
                    setActiveTab(mTabs[2]);
                break;

                default :
                break;
            }
        }    
    }

    const fnAddServiceGroup = () =>
    {
    }

    var lFooter =
        <div className="cs-dialog-footer">
            <Button label="Save" icon={CSUtil.mNameIconMap['PI_CHECK']}/>
            <Button label="Back" icon={CSUtil.mNameIconMap['PI_CARET_LEFT']} onClick = { () => lProps.fnProcessClose() }/>
        </div>

    var lForm =
        <>
            <Dialog className = "cs-dialog" visible = {lProps.pShow} header = "Solution Service" footer = {lFooter} modal = {true} onHide = { () => lProps.fnProcessClose() }>
                <div className="cs-dialog-form-item">
                    <div className="cs-details-first-section" align="center">
                        <div className="p-field">
                            <label htmlFor="ID_SERVICE_CODE" className="cs-details-section-label" >Service Code</label>
                            <InputText 
                                id          = "ID_SERVICE_CODE" 
                                type        = "text" 
                                onChange    = {(lItem) => setServiceCode(lItem.value)}
                                className   = "cs-details-section-control" 
                                value       = {mServiceCode}/>
                        </div>

                        <div className  =   "p-field">
                            <label htmlFor="ID_SERVICE_NAME" className="cs-details-section-label" >Service Name</label>
                            <InputText 
                                id          = "ID_SERVICE_NAME" 
                                type        = "text" 
                                onChange    = {(lItem) => setServiceName(lItem.value)}
                                className   = "cs-details-section-control" 
                                value       = {mServiceName}/>
                        </div>
                    </div>

                    <div className  = "cs-details-even-section" align="center">
                        <div className = "p-field">
                            <label htmlFor  = "ID_SERVICE_TYPE" className="cs-details-section-label" >Service Type</label>
                            <Dropdown
                                id          = "ID_SERVICE_TYPE" 
                                className   = "cs-details-section-control" 
                                editable
                                options     = {mServiceTypeList} 
                                optionLabel = 'service_type_'
                                value       = {mSelectedServiceType}
                                onChange    = {(lItem) => setSelectedServiceType(lItem.value)}/>
                        </div>

                        <div className  = "p-field" align="center">
                            <label htmlFor  = "ID_SERVICE_HANDLER" className="cs-details-section-label" >Service Handler</label>
                            <Dropdown 
                                id          = "ID_SERVICE_HANDLER" 
                                className   = "cs-details-section-control" 
                                options     = {mServiceHandlerList} 
                                optionLabel = 'name_'
                                value       = {mSelectedServiceHandler}
                                onChange    = {(lItem) => setSelectedServiceHandler(lItem.value)}/>
                        </div>
                    </div>

                    <div className="cs-details-odd-section" align="center">
                        <div className="p-field">
                            <label htmlFor="ID_SERVICE_METHOD" className="cs-details-section-label" >Service Method</label>
                            <Dropdown 
                                id          = "ID_SERVICE_METHOD" 
                                className   = "cs-details-section-control" 
                                options     = {mServiceMethodList} 
                                optionLabel = 'name_'
                                value       = {mSelectedServiceMethod}
                                onChange    = {(lItem) => fnEnableTab(lItem.value)}/>
                        </div>

                        <div className="p-field">
                            <label htmlFor="ID_SERVICE_GROUP" className="cs-details-section-label" >Service Group</label>
                            <div className="p-inputgroup" 
                                style={{width : '100%', alignItems : 'center', justifyContent : 'center', paddingLeft : '5px', paddingRight : '5px'}}>
                                <Dropdown 
                                    id          = "ID_SERVICE_GROUP" 
                                    className   = "cs-details-section-control" 
                                    options     = {mServiceGroupList} 
                                    optionLabel = 'name_'
                                    value       = {mSelectedServiceGroup}
                                    onChange    = {(lItem) => setSelectedServiceGroup(lItem.value)}/>
                                <Button icon="pi pi-plus" style={{height : '35px'}} onClick={fnAddServiceGroup()}/>
                            </div>
                        </div>
                    </div>

                    <div className="cs-details-last-section" align="center">
                        <div className="p-field">
                            <label htmlFor="ID_SERVICE_REMARKS" className="cs-details-section-label" >Remarks</label>
                            <InputTextarea id="ID_SERVICE_REMARKS"  className="cs-details-section-text-area" />
                        </div>
                    </div>
                </div>

                <TabMenu model={ mTabs } className="cs-tab-menu" activeItem={mActiveTab} onTabChange={fnProcessTabChange}/>

                <Panel className="cs-panel">
                    <div className="cs-panel-section">
                        { mSelectedTab }
                    </div>
                </Panel>
            </Dialog>
        </>
    return lForm;
}

export default CSSolutionServices;
