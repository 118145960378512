import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function CSCOBarChart(lProps){
    const [mChartData,    setChartData]    = useState({});
    const [mChartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const lDocumentStyle      = getComputedStyle(document.documentElement);
        const lTextColor          = lDocumentStyle.getPropertyValue('--text-color');
        const lSurfaceBorder      = lDocumentStyle.getPropertyValue('--surface-border');
        let lChartData            = {};

        if(lProps.pData && lProps.pChartData && lProps.pChartData.chart_data_field_)
        {
            if(lProps.pChartData.chart_var_field_)
            {
                lChartData.labels = [];
                lChartData.datasets = [];
                if(lProps.pChartData.hasOwnProperty('chart_data_type_') && (lProps.pChartData.chart_data_type_ === 'VARIABLE'))
                {
                    let lChartDataField = lProps.pChartData.chart_data_field_[0];
                    let lChartLabelDataMap = {};
                    for(let lInd=0; lInd<lProps.pData.length; lInd++)
                    {
                        let lObj = lProps.pData[lInd];
                        let lDataLabel = lObj[lProps.pChartData.chart_data_label_];
                        let lData = lChartLabelDataMap[lDataLabel];
                        if(!lData)
                        {
                            lData = {};
                            lChartLabelDataMap[lDataLabel] = lData;
                            lData.data = [];
                        }

                        if(!lChartData.labels.includes(lObj[lProps.pChartData.chart_var_field_]))
                        {
                            lChartData.labels.push(lObj[lProps.pChartData.chart_var_field_]);
                        }

                        lData.data.push(lObj[lChartDataField]);
                    }

                    let lKeys = Object.keys(lChartLabelDataMap);
                    for(let lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                    {
                        let lDataObj = {}
                        lDataObj.label = lKeys[lKeyInd];
                        lDataObj.data = (lChartLabelDataMap[lKeys[lKeyInd]]).data;
                        lChartData.datasets.push(lDataObj);
                    }
                }
                else
                {
                    for(let lDataInd=0; lDataInd<lProps.pChartData.chart_data_field_.length; lDataInd++)
                    {
                        let lChartDataField = lProps.pChartData.chart_data_field_[lDataInd];
                        let lChartDataLabel = lProps.pChartData.chart_data_label_[lDataInd];
                        let lDataObj = {}
                        lDataObj.label = lChartDataLabel;
                        lDataObj.data = [];
                        for(let lInd=0; lInd<lProps.pData.length; lInd++)
                        {
                            let lObj = lProps.pData[lInd];
                            lDataObj.data.push(lObj[lChartDataField]);

                            if(lDataInd === 0)
                            {
                                lChartData.labels.push(lObj[lProps.pChartData.chart_var_field_]);
                            }
                        }

                        lChartData.datasets.push(lDataObj);
                    }
                }
            }
        }

        const lOptions = 
        {
            maintainAspectRatio : false,
            aspectRatio         : 0.8,
            plugins             : 
            {
                legend: 
                {
                    labels: 
                    {
                        fontColor : lTextColor
                    }
                }
            },
            scales: 
            {
                x: 
                {
                    ticks: 
                    {
                        font: 
                        {
                            weight : 600
                        }
                    },
                    grid: 
                    {
                        display    : true,
                        drawBorder : true
                    }
                },
                y: 
                {
                    grid: 
                    {
                        color      : lSurfaceBorder,
                        drawBorder : false
                    }
                }
            }
        }

        setChartData(lChartData);
        setChartOptions(lOptions);
    }, [lProps.pData, lProps.pChartData]);

    return (
        <div className="card">
            <Chart 
                type     = "bar" 
                data     = { mChartData } 
                options  = { mChartOptions } className='p-2' />
        </div>
    )
}
