import React from "react"

class CSBaseControl extends React.Component
{
    constructor(props)
    {
       super(props);
        this.state =  
        {
            mId                 		 : this.props.pId,
            mDisplayField       		 : (this.props.pDisplayField) ? this.props.pDisplayField : this.props.pDisplayLabel,
            mDataField          		 : this.props.pDataField,
            mControlType        		 : this.props.pControlType,
            mType               		 : this.props.pType,
            mEditable           		 : this.props.pEditable,
            mRequired                    : this.props.pRequired,
            mMaxWidth           		 : (this.props.pMaxWidth) ? this.props.pMaxWidth : 0,
            mWidth              		 : this.props.pWidth,
            mHeight             		 : this.props.pHeight,
            mValue              		 : (this.props.pValue) ? this.props.pValue : '',
            mHandleChange       		 : this.props.pProcessChange,
            mFormLabelClassName 		 : this.props.pFormLabelClassName,
            mLabelWidth         		 : (!this.props.pLabelWidth) ? '100px' : this.props.pLabelWidth,
            mDataProviderId     		 : this.props.pDataProviderId,
            mDataProviderIdObjectMap     : {},
            mDataProvider       		 : [],
            mMinDate                     : null,
            mMaxDate                     : new Date(),
            mDataProviderDisplayField    : this.props.data_provider_display_field_,
            mControlClassName            : (this.props.pRequired && !this.props.pValue) ? 'p-error' : '',
            mPopoutButton                : this.props.pPopoutButton,
            mBase64Flag                  : (this.props.pBase64Flag) ? this.props.pBase64Flag : false,

            mErrorBorder                 : "0.005ex solid red",
            mStandardBorder              : "0.005ex solid #aaa",
            mBorder                      : "0.005ex solid #aaa",

            mLength                      : 150
        }

        if(!this.state.mDisplayField)
        {
            this.state.mDisplayField = this.props.display_label_;
        }

        if(this.props.pControlType === 'multi_select_combo_box')
        {
                this.state.mValue = false;
        }
        else if(this.props.pControlType === 'input_number')
        {
                this.state.mValue = this.props.pValue;
        }

        this.cfGetControlLabel = this.cfGetControlLabel.bind(this);

        if(this.props.popout_button_ === 'Y')
        {
            this.state.mMaxWidth = this.props.pMaxWidthWithPopout;
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.setState(
                {
                    mId                 		: this.props.pId,
                    mDisplayField       		: (this.props.pDisplayField) ? this.props.pDisplayField : this.props.pDisplayLabel,
                    mDataField          		: this.props.pDataField,
                    mControlType        		: this.props.pControlType,
                    mType               		: this.props.pType,
                    mEditable           		: this.props.pEditable,
                    mRequired                   : this.props.pRequired,
                    mMaxWidth           		: (this.props.pMaxWidth) ? this.props.pMaxWidth : 0,
                    mWidth              		: this.props.pWidth,
                    mHeight             		: this.props.pHeight,
                    mValue              		: (this.props.pValue) ? this.props.pValue : null,
                    mHandleChange       		: this.props.pProcessChange,
                    mFormLabelClassName 		: this.props.pFormLabelClassName,
                    mLabelWidth         		: (!this.props.pLabelWidth) ? '100px' : this.props.pLabelWidth,
                    mDataProviderId     		: this.props.pDataProviderId,
                    mDataProvider       		: [],
                    mDataProviderDisplayField   : this.props.data_provider_display_field_,
                    mControlClassName           : (this.props.pRequired && !this.props.pValue) ? 'p-error' : '',
                    mBase64Flag                 : (this.props.pBase64Flag) ? this.props.pBase64Flag : false
                }
            );
        }
    }

    cfGetControlLabel()
    {
        var lCtlLabel = null;
        var lDisplayField = this.props.display_field_;
        if(!lDisplayField)
        {
            lDisplayField = this.props.display_label_;
        }

        if(lDisplayField)
        {
            var lLabel = null;
            if(this.props.pRequired)
            {
                lLabel = 
                <>
                    <span style={{color: 'red'}}>*&nbsp;</span> { lDisplayField }
                </>
            }
            else
            {
                lLabel = 
                <>
                    { lDisplayField }
                </>
            }

            lCtlLabel = 
                    <label htmlFor = { this.props.pId }  className="cs-control-label">
                        {lLabel}
                    </label>
        }

        return lCtlLabel;
    }
}

export default CSBaseControl;
