import { DataView } from 'primereact/dataview';
import CSDropDown from '../../controls/CSDropDown';
import { useEffect, useState } from 'react';
import CSRequestUtil from '../../util/CSRequestUtil';
import CSServiceUtil from '../../util/CSServiceUtil';
import CSCOColumnChart from '../../layout/analysis/CSCOColumnChart';
import CSConfigUtil from '../../util/CSConfigUtil';
import CSCOBackdrop from "../../components/CSCOBackdrop";

const SalesMetrics = (lProps) => {
    const [mSelectedPipelineFYCode, setselectedPipelineFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mselectedPipelineFYName, setselectedPipelineFYName] = useState(CSConfigUtil.mCurrentFYName);
    const [mselectedPipelineFYObj, setselectedPieplineFYObj] = useState(CSConfigUtil.mActiveFinancialYearObj);
    const [mPipelineData, setPipelineData] = useState(null);
    const [mSummaryData, setSummaryData] = useState(null);
    const [mShowBackdrop, setShowBackdrop] = useState(false);

    const [mSelectedSalesFYCode, setselectedSalesFYCode] = useState(CSConfigUtil.mCurrentFYCode);
    const [mselectedSalesFYName, setselectedSalesFYName] = useState(CSConfigUtil.mCurrentFYName);
    const [mselectedSalesFYObj, setselectedSalesFYObj] = useState(CSConfigUtil.mActiveFinancialYearObj);

    const [mSelectedCompanyCode, setselectedCompanyCode] = useState(CSConfigUtil.mCompanyCode);
    const [mselectedCompanyObj, setselectedCompanyObj] = useState(CSConfigUtil.mCompanyObj);

    const [mRequestId, setRequestId] = useState(null);
    const [mChartIdObjectMap, setChartIdObjectMap] = useState(null);
    const mSalesMetricsData = [
        {
            name1:  'Customer Acquisition Cost',
            name2:  'CAC Payback Period',
            name3:  'Pipeline conversion rate',
            name4:  'Lifetime Value of customers',
            value1: '-',
            value2: '-',
            value3: '-',
            value4: '-'
        }
    ];

    useEffect(() => {
            let lChartIdObjMap = {};
            let lChartRefIdObjMap = {};

            if(lProps.pReportChartProperties)
            {
                for(let lInd=0; lInd<lProps.pReportChartProperties.length; lInd++)
                {
                    let propertyObj = lProps.pReportChartProperties[lInd];
                    if(propertyObj.property_name_ === 'request_id_')
                    {
                        setRequestId(propertyObj.value_);
                    }
                    else if(propertyObj.property_name_ === 'chart_id_')
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.id_];
                        if(!lcpObj)
                        {
                            lcpObj = {};
                            lChartRefIdObjMap[propertyObj.id_] = lcpObj;
                        }

                        lcpObj[propertyObj.property_name_] = propertyObj.value_;
                        lChartIdObjMap[propertyObj.value_] = lcpObj;
                    }
                    else if(propertyObj.parent_ref_id_)
                    {
                        let lcpObj = lChartRefIdObjMap[propertyObj.parent_ref_id_];
                        if(lcpObj)
                        {
                            if((propertyObj.property_name_ === 'chart_data_field_') || (propertyObj.property_name_ === 'chart_data_label_'))
                            {
                                if(propertyObj.property_name_ === 'chart_data_field_')
                                {
                                    let lChartDataFieldList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataFieldList)
                                    {
                                        lChartDataFieldList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataFieldList;
                                    }

                                    lChartDataFieldList.push(propertyObj.value_);
                                }
                                else
                                {
                                    let lChartDataLabelList = lcpObj[propertyObj.property_name_];
                                    if(!lChartDataLabelList)
                                    {
                                        lChartDataLabelList = [];
                                        lcpObj[propertyObj.property_name_] = lChartDataLabelList;
                                    }

                                    lChartDataLabelList.push(propertyObj.value_);
                                }
                            }
                            else
                            {
                                lcpObj[propertyObj.property_name_] = propertyObj.value_; 
                            }
                        }
                    }
                }

                setChartIdObjectMap(lChartIdObjMap);
            }
        }, []
    );

    useEffect(() => {
            fnGetPipelineReportData(CSConfigUtil.mCurrentFYCode);
            fnGetSalesReportData(CSConfigUtil.mCurrentFYCode, CSConfigUtil.mCompanyCode);
        }, [mChartIdObjectMap]
    );

    function fnPipelineDataProcessChange(lData)
    {
        if(lData && lData.hasOwnProperty('selected_data_'))
        {
            let lFyCode = mSelectedPipelineFYCode;
            if(lData.id_ === 'ID_FY_CODE')
            {
                setselectedPieplineFYObj(lData.selected_data_);
                setselectedPipelineFYCode(lData.value_);
                lFyCode = lData.value_;
                setselectedPipelineFYName(lData.selected_data_.name_);
            }

            fnGetPipelineReportData(lFyCode);
        }
    }

    function fnGetPipelineReportData(lFyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && lFyCode)
            {
                setShowBackdrop(true);
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lpipelineObj = mChartIdObjectMap['ID_PIPELINE'];
                            if(lpipelineObj && lResponse.hasOwnProperty(lpipelineObj.data_field_))
                            {
                                let lpipelineDataObj = lResponse[lpipelineObj.data_field_];
                                setPipelineData(lpipelineDataObj);
                            }
                            else
                            {
                                setPipelineData(null);
                            }

                            setShowBackdrop(false);
                        }
                    }
                );
            }
        }
    }

    function fnSalesDataProcessChange(lData)
    {
        if(lData)
        {
            let lFyCode = mSelectedSalesFYCode;
            let lCompanyCode = mSelectedCompanyCode;

            if(lData.id_ === 'ID_FY_CODE')
            {
                setselectedSalesFYObj(lData);
                setselectedSalesFYCode(lData.value_);
                lFyCode = lData.value_;
                if(lData.hasOwnProperty('selected_data_'))
                {
                    setselectedSalesFYName(lData.selected_data_.name_);
                }
            }
            else if(lData.id_ === 'ID_COMPANY')
            {
                setselectedCompanyObj(lData);
                setselectedCompanyCode(lData.value_);
                lCompanyCode =lData.value_;
            }

            fnGetSalesReportData(lFyCode, lCompanyCode);
        }
    }

    function fnGetSalesReportData(lFyCode, lCompanyCode) {
        if(lProps.pReport)
        {
            let lServiceObject = (CSServiceUtil.cfGetInstance()).cfGetServiceObject(lProps.pReport.service_code_);
            if(lServiceObject && (lFyCode || lCompanyCode))
            {
                setShowBackdrop(true);
                let lCriteria = {}
                lCriteria.fy_code_ = lFyCode;
                lCriteria.company_code_ = lCompanyCode;

                let lRequestUtil = new CSRequestUtil('GET', lProps.pReport.service_code_, lServiceObject.type_, mRequestId);
                lRequestUtil.cfSetRequestCriteria(lCriteria);
                lRequestUtil.cfProcessRequest((lResponse) => {
                        if(lResponse)
                        {
                            let lSummaryObj = mChartIdObjectMap['ID_SUMMARY'];
                            if(lSummaryObj && lResponse.hasOwnProperty(lSummaryObj.data_field_))
                            {
                                let lSummaryDataObj = lResponse[lSummaryObj.data_field_];
                                setSummaryData(lSummaryDataObj[0]);
                            }
                            else
                            {
                                setSummaryData(null);
                            }

                            setShowBackdrop(false);
                        }
                    }
                );
            }
        }
    }

    function fnSalesMetricsData() {
        let lCustomerAcquisitionCost = (mSummaryData && mSummaryData.hasOwnProperty('customer_acquisition_cost_') && mSummaryData.customer_acquisition_cost_) ? mSummaryData.customer_acquisition_cost_: '--';
        let lCacPaybackPeriod        = (mSummaryData && mSummaryData.hasOwnProperty('payback_period_') && mSummaryData.payback_period_)                       ? mSummaryData.payback_period_           : '--';
        let lPipelineConversionRate  = (mSummaryData && mSummaryData.hasOwnProperty('pipeline_conversion_rate_') && mSummaryData.pipeline_conversion_rate_)   ? mSummaryData.pipeline_conversion_rate_ : '--';
        let lLifeTimeValue           = (mSummaryData && mSummaryData.hasOwnProperty('lifetime_value_') && mSummaryData.lifetime_value_)                       ? mSummaryData.lifetime_value_           : '--';

        const itemTemplate = (lData) => {
            return (
                <div className="col-12 w-full h-full shadow-2 px-4 py-4">
                    <div className="inline-flex h-5rem align-items-center">
                        <div className="flex justify-content-center py-2 mb-6 h-5rem ">
                            <CSDropDown
                                display_field_                = 'Financial Year'
                                data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                                pDataProviderId               = 'code_'
                                pDataField                    = 'code_'
                                pPlaceHolder                  = 'Select a Financial Year'
                                pRequired                     = { true }
                                pId                           = 'ID_FY_CODE'
                                data_provider_display_field_  = 'name_'
                                pControlType                  = "combo_box"
                                pValue                        = { mselectedSalesFYObj }
                                mValue                        = { mSelectedSalesFYCode }
                                pProcessChange                = { fnSalesDataProcessChange } 
                            />
                        </div>

                        <div className="flex justify-content-center py-2 mb-6 h-5rem ">
                            <CSDropDown
                                    display_field_                = 'Company'
                                    data_provider_source_         = 'USER_COMPANY_LIST'
                                    pDataProviderId               = 'company_code_'
                                    pDataField                    = 'company_code_'
                                    pPlaceHolder                  = 'Select a Company'
                                    pRequired                     = { true }
                                    pId                           = 'ID_COMPANY'
                                    data_provider_display_field_  = 'company_name_'
                                    pControlType                  = "combo_box"
                                    pValue                        = { mselectedCompanyObj }
                                    mValue                        = { mSelectedCompanyCode }
                                    pProcessChange                = { fnSalesDataProcessChange } 
                                />
                        </div>
                    </div>

                    <div className="flex align-items-center justify-content-center ">
                        <span className="mt-4 text-xl text-center pb-4 font-medium text-700">Sales Data</span>
                    </div>

                    <div className='py-5'>
                        <ul className="list-none p-0 m-0  ">
                            <li className="flex align-items-center py-4">
                                <span className="border-round bg-indigo-300 mr-3 flex-shrink-0" style={{ width: '1rem', height: '1rem', marginLeft: '8%' }}/>
                                <span className="text-base font-medium text-60">{lData.name1}</span><span className="text-900 text-base font-semibold ml-auto">{lCustomerAcquisitionCost}</span>
                            </li>
                            
                            <li className="flex align-items-center py-4">
                                <span className="border-round bg-blue-300 mr-3 flex-shrink-0" style={{ width: '1rem', height: '1rem', marginLeft: '8%' }}/>
                                <span className="text-base font-medium text-60">{lData.name2}</span><span className="text-900 text-base font-semibold ml-auto">{lCacPaybackPeriod}</span>
                            </li>

                            <li className="flex align-items-center py-4">
                                <span className="border-round bg-orange-300 mr-3 flex-shrink-0" style={{ width: '1rem', height: '1rem', marginLeft: '8%' }}/>
                                <span className="text-base font-medium text-60">{lData.name3}</span><span className="text-900 text-base font-semibold ml-auto">{lPipelineConversionRate}</span>
                            </li>
                        
                            <li className="flex align-items-center pt-4">
                                <span className="border-round bg-purple-300 mr-3 flex-shrink-0" style={{ width: '1rem', height: '1rem', marginLeft: '8%' }}/>
                                <span className="text-base font-medium text-60">{lData.name4}</span><span className="text-900 text-base font-semibold ml-auto">{lLifeTimeValue}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        };

        return (
            <DataView 
                id           = "ID_SUMMARY" 
                value        = { mSalesMetricsData } 
                itemTemplate = { itemTemplate } 
            />
        )
    }

    const lForm =
    <>
        <CSCOBackdrop pShow = {mShowBackdrop}/>
            <div className="grid flex flex-wrap justify-content-center pl-5 pt-4">
                <div className="xl:col-7 md:col-7 inline-block shadow-2">
                    <div className='flex align-items-center justify-content-center'>
                        <div className="flex justify-content-center py-2">
                            <CSDropDown
                                display_field_                = 'Financial Year'
                                data_provider_source_         = 'FINANCIAL_YEAR_LIST'
                                pDataProviderId               = 'code_'
                                pDataField                    = 'code_'
                                pPlaceHolder                  = 'Select a Financial Year'
                                pRequired                     = { true }
                                pId                           = 'ID_FY_CODE'
                                data_provider_display_field_  = 'name_'
                                pControlType                  = "combo_box"
                                pValue                        = { mselectedPipelineFYObj }
                                mValue                        = { mSelectedPipelineFYCode }
                                pProcessChange                = { fnPipelineDataProcessChange } 
                            />
                        </div>
                    </div>

                    <div className='text-lg text-center font-bold mb-1 p-2 text-700'>Sales Pipeline vs Converted - { mselectedPipelineFYName }</div>
                    
                    <div className="col-10">
                        <CSCOColumnChart 
                            id         = "ID_PIPELINE" 
                            pData      = {mPipelineData}
                            pChartData = {(mChartIdObjectMap) ? mChartIdObjectMap["ID_PIPELINE"] : null}/>
                    </div>
                </div>

                <div className="col-12 lg:col-5 px-4">
                    { fnSalesMetricsData() } 
                </div>
            </div>
        </>
    return lForm;
}

export default SalesMetrics;
