import React, { useEffect, useState } from 'react'

function VGROUP(mProps) {
    const [mGroupStyle, setGroupStype] = useState({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow:'auto',
        gap: 10,
        backgroundColor: '#fafafa',
        padding         : '2px',
        paddingLeft     : '2px',
        paddingRight    : '2px',
        paddingTop      : '2px',
        paddingBottom   : '2px',
        justifyContent: 'center'
    }
    );

    const [mChildren, setChildren] = useState(null);

    useEffect(() => {
        if (mProps) {
            /**
             * Initialize Component Styles
             */
            if (mProps.hasOwnProperty('width')) {
                setGroupStype((lStyle) => ({ ...lStyle, width: mProps.width }));
            }

            if (mProps.hasOwnProperty('height')) {
                setGroupStype((lStyle) => ({ ...lStyle, height: mProps.height }));
            }

            if (mProps.hasOwnProperty('gap')) {
                setGroupStype((lStyle) => ({ ...lStyle, gap: mProps.gap }));
            }

            if (mProps.hasOwnProperty('backgroundColor')) {
                setGroupStype((lStyle) => ({ ...lStyle, backgroundColor: mProps.backgroundColor }));
            }

            if (mProps.hasOwnProperty('justifyContent')) {
                setGroupStype((lStyle) => ({ ...lStyle, justifyContent: mProps.justifyContent }));
            }
            
            if (mProps.hasOwnProperty('paddingLeft')) {
                setGroupStype((lStyle) => ({ ...lStyle, paddingLeft: mProps.paddingLeft }));
            }
            
            if (mProps.hasOwnProperty('paddingRight')) {
                setGroupStype((lStyle) => ({ ...lStyle, paddingRight: mProps.paddingRight }));
            }
            
            if (mProps.hasOwnProperty('paddingTop')) {
                setGroupStype((lStyle) => ({ ...lStyle, paddingTop: mProps.paddingTop }));
            }
            
            if (mProps.hasOwnProperty('paddingBottom')) {
                setGroupStype((lStyle) => ({ ...lStyle, paddingBottom: mProps.paddingBottom }));
            }
            
            if (mProps.hasOwnProperty('padding')) {
                setGroupStype((lStyle) => ({ ...lStyle, padding: mProps.padding }));
            }


            /**
             * Add Children
             */
            if (mProps.hasOwnProperty('children')) {
                setChildren(mProps.children);
            }
        }
    }, [mProps]);

    return (
        <div style={mGroupStyle}>
            {mChildren}
        </div>
    )
}

export default VGROUP;