import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import CSCONavBar from "./navigation/CSCONavBar";
import CSCOLayoutManager from "./layout/CSCOLayoutManager";
import CSCOMenu from "./layout/CSCOMenu";
import CSProfile from "./business/CSProfile";
import CSCOLogin from "./components/CSCOLogin";
import CSUtil from './util/CSUtil';
import CSConfigUtil from './util/CSConfigUtil';
import CSServiceUtil from './util/CSServiceUtil';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/styles/CStheme.css';
import './assets/styles/responsive.css';
import 'primereact/resources/primereact.css';

import AyChange from './business/AyChange';
import BranchChange from './business/BranchChange';

import FyChange from './business/FyChange';
import CompanyChange from './business/CompanyChange';

import './assets/fonts/ANUPM___.TTF'
import CSTheme from './components/CSTheme';
import CSCOFullCalendar from './components/CSCOFullCalendar';
import CSCOSidebar from './components/CSCOSidebar';
import CSCOOrganizationChart from './components/CSCOOrganizationChart';
import CSCONotificaton from './components/CSCONotification';


class App extends Component 
{
    mWindowWidth    = window.innerWidth;
    mWindowHeight   = window.innerHeight;
    mRefreshLayout  = false;
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            mlayoutMode              : 'static',
            mLayoutColorMode         : 'light',
            mStaticMenuInactive      : false,
            mOverlayMenuActive       : false,
            mMobileMenuActive        : false,
            mSelectedFeatureRefId    : "",
            mRedirectFrom            : this.props.redirectFrom,

            mAyChangeFlag            : false,
            mBranchChangeFlag        : false,
            mNotificationsFlag       : false,

            mFyChangeFlag            : false,
            mCompanyChangeFlag       : false,

            mUpdateDimensions        : false,
            mPrimaryColor            : null,
            mShowThemeForm           : false,
            mVisibleRight            : false,
            mOrganizationchart       : false
        };
        
        this.cfOnPopState                 = this.cfOnPopState.bind(this);
        this.cfUpdateDimensions           = this.cfUpdateDimensions.bind(this);
        this.cfSetSelectedAppId           = this.cfSetSelectedAppId.bind(this);
        this.cfOnWrapperClick             =  this.cfOnWrapperClick.bind(this);
        this.cfOnToggleMenu               = this.cfOnToggleMenu.bind(this);
        this.cfOnSidebarClick             = this.cfOnSidebarClick.bind(this);
        this.cfOnMenuItemClick            = this.cfOnMenuItemClick.bind(this);
        this.cfAddClass                   = this.cfAddClass.bind(this);
        this.cfRemoveClass                = this.cfRemoveClass.bind(this);
        this.cfGetDimensions              = this.cfGetDimensions.bind(this);
  
        this.cfShowNotifications          = this.cfShowNotifications.bind(this);
        this.cfAyChange                   = this.cfAyChange.bind(this);
        this.cfBranchChange               = this.cfBranchChange.bind(this);
        this.cfCloseAyBranchChangeForm    = this.cfCloseAyBranchChangeForm.bind(this);
  
        this.cfFyChange                   = this.cfFyChange.bind(this);
        this.cfCompanyChange              = this.cfCompanyChange.bind(this);
        this.cfCloseFyCompanyChangeForm   = this.cfCloseFyCompanyChangeForm.bind(this);
  
        this.cfOnThemeChange              = this.cfOnThemeChange.bind(this);
        this.cfDisplayFeature             = this.cfDisplayFeature.bind(this);
        this.cfCloseThemeChangeForm       = this.cfCloseThemeChangeForm.bind(this);
        this.cfThemeChange                = this.cfThemeChange.bind(this);
        this.cfSetSidebar                 = this.cfSetSidebar.bind(this);
        this.cfHideSidebar                = this.cfHideSidebar.bind(this);
        this.cfOrganizationChart          = this.cfOrganizationChart.bind(this);
        this.cfCloseOrganizationchart     = this.cfCloseOrganizationchart.bind(this);
       
        const root = document.getElementById('root');
        this.state.mPrimaryColor = root.style.getPropertyValue=('--primary-color');
    }

    cfSetSidebar() {
        this.setState
        (
            {
              mVisibleRight: true,
            }
        )
    }
    
    cfHideSidebar() {
        this.setState
        (
            {
              mVisibleRight: false,
            }
        )
    }
      
      
    cfThemeSelector()
    {
        const colorThemes = document.querySelectorAll('[name="theme"]');
        const storeTheme = function (theme) {
            localStorage.setItem("theme", theme);
        };

        const setTheme = function () {
            const activeTheme = localStorage.getItem("theme");
            colorThemes.forEach((themeOption) => {
                if (themeOption.id === activeTheme) {
                    themeOption.checked = true;
                }
            });

            document.documentElement.className = activeTheme;
        };

        colorThemes.forEach((themeOption) => {
            themeOption.addEventListener("click", () => {
                storeTheme(themeOption.id);
                document.documentElement.className = themeOption.id;
            });
        });

        document.onload = setTheme();
    }

    cfSetSelectedAppId(lId) 
    {
        this.setState({mSelectedFeatureRefId : lId});
        ReactDOM.createRoot(document.getElementById('root')).render(<CSCOLogin />)
    
        ReactDOM.render(<App />, document.getElementById('root'));
    }
    
    cfOnWrapperClick(event) 
    {
        if (!this.menuClick) 
        {
            this.setState(
                {
                    mOverlayMenuActive  : false,
                    mMobileMenuActive   : false
                }
            );
        }

        this.menuClick = false;
    }

    cfOnToggleMenu(event) 
    {
        this.menuClick = true;

        if (this.isDesktop()) 
        {
            if (this.state.mlayoutMode === 'overlay') 
            {
                this.setState(
                    {
                        mOverlayMenuActive: !this.state.mOverlayMenuActive
                    }
                );
            }
            else if (this.state.mlayoutMode === 'static') 
            {
                this.setState(
                    {
                        mStaticMenuInactive: !this.state.mStaticMenuInactive
                    }
                );
            }
        }
        else 
        {
            const mobileMenuActive = this.state.mMobileMenuActive;
            this.setState(
                {
                    mMobileMenuActive: !mobileMenuActive
                }
            );
        }

        event.preventDefault();
    }

    cfLogout(lEvent)
    {
        if(lEvent)
        {
            var lUtil = CSUtil.cfGetInstance();
            lUtil.cfReset();

            var lServiceUtil = CSServiceUtil.cfGetInstance();
            lServiceUtil.cfResetDataproviderList();

            CSConfigUtil.mUserId       = '';
            CSConfigUtil.mSessionId    = '';

            ReactDOM.render(<CSCOLogin pLogout={true}/>, document.getElementById('root'));
        }
    }

    cfFullCalendar(lEvent)
    {
        if(lEvent)
        {
            this.setState({mFullCalendarFlag : true});
        }
    }

    cfOrganizationChart(lEvent)
    {
        if(lEvent)
        {
            this.setState({mOrganizationchart : true});
        }
    }

    cfShowNotifications(lEvent)
    {
        if(lEvent)
        {
            this.setState({mNotificationsFlag : true});
        }
    }

    cfAyChange(lEvent)
    {
        if(lEvent)
        {
            this.setState({mAyChangeFlag : true});
        }
    }

    cfBranchChange(lEvent)
    {
        if(lEvent)
        {
            this.setState({mBranchChangeFlag : true});
        }
    }
    
    cfFyChange(lEvent)
    {
        if(lEvent)
        {
            this.setState({mFyChangeFlag : true});
        }
    }
    
    cfCompanyChange(lEvent)
    {
        if(lEvent)
        {
            this.setState({mCompanyChangeFlag : true});
        }
    }

    cfOnThemeChange(lEvent)
    {
        if(lEvent && lEvent.value)
        {
            const lThemeObj = lEvent.value;
            const root = document.getElementById('root');
            root.style.setProperty('--primary-color', lThemeObj.color);
        }
    }
    
    cfThemeChange(lEvent)
    {
        if(lEvent)
        {
            this.setState({mShowThemeForm : true});
        }
    }

    cfOnSidebarClick(event) 
    {
        this.menuClick = true;
    }

    cfOnMenuItemClick(event) 
    {
        if(event.item)
        {
            this.setState({mSelectedFeatureRefId : event.item.layout_map_ref_id_})
        }

        if (!event.item.items) 
        {
            this.setState(
                {
                    mOverlayMenuActive  : false,
                    mMobileMenuActive   : false
                }
            )
        }
    }

    cfDisplayFeature(lFeatureRefId)
    {
        if(lFeatureRefId)
        {
            this.setState({ mSelectedFeatureRefId : lFeatureRefId, mVisibleRight : false});
        }
    }

    cfOnPopState()
    {
        window.history.pushState(null, document.title,  window.location.href);
    }

    cfUpdateDimensions()
    {
        var lElement = document.getElementById('ID_LAYOUT_AREA');
        if(lElement)
        {
            var lUpdate = this.state.mUpdateDimensions;

            var lRect = lElement.getBoundingClientRect();
            (CSUtil.cfGetInstance()).mApplicationHeight = lRect.height;
            this.setState({mUpdateDimensions : !lUpdate});
        }        
    }

    componentDidMount() 
    {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', this.cfOnPopState);
        window.addEventListener('resize', this.cfUpdateDimensions);
    }

    componentWillUnmount() 
    {
        window.removeEventListener('popstate', this.cfOnPopState);
        window.removeEventListener('resize',   this.cfUpdateDimensions);
    }

    componentDidUpdate() 
    {
        if (this.state.mMobileMenuActive)
        {
            this.cfAddClass(document.body, 'body-overflow-hidden');
        }
        else
        {
            this.cfRemoveClass(document.body, 'body-overflow-hidden');
        }
    }

    cfAddClass(element, className) 
    {
        if (element.classList)
        {
            element.classList.add(className);
        }
        else
        {
            element.className += ' ' + className;
        }
    }

    cfRemoveClass(element, className) 
    {
        if (element.classList)
        {
            element.classList.remove(className);
        }
        else
        {
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
    
    isDesktop() 
    {
        return window.innerWidth > 1024;
    }

    cfGetDimensions(lElement)
    {
        if(lElement)
        {
            var lRect = lElement.getBoundingClientRect();
            (CSUtil.cfGetInstance()).mApplicationHeight = lRect.height;
        }
    }

    cfCloseAyBranchChangeForm(lRefresh = false)
    {
        if(lRefresh)
        {
            this.mRefreshLayout = true;
        }

        this.setState({mAyChangeFlag : false, mBranchChangeFlag : false});
    }

    cfCloseFyCompanyChangeForm(lRefresh = false)
    {
        if(lRefresh)
        {
            this.mRefreshLayout = true;
        }

        this.setState({mFyChangeFlag : false, mCompanyChangeFlag : false});
    }

    cfCloseThemeChangeForm(lRefresh = false)
    {
        if(lRefresh)
        {
            this.mRefreshLayout = true;
        }

        this.setState({mShowThemeForm : false});
    }

    cfCloseFullCalendarForm(lRefresh = false)
    {
        if(lRefresh)
        {
            this.mRefreshLayout = true;
        }

        this.setState({mFullCalendarFlag : false});
    }    

    cfCloseOrganizationchart(lRefresh = false) {
        if (lRefresh) {
            this.mRefreshLayout = true;
        }

        this.setState({ mOrganizationchart : false });
    }
    
    render() {
        (CSUtil.cfGetInstance()).mApplicationWidth = (this.state.mStaticMenuInactive && (this.state.mlayoutMode === 'static')) ? this.mWindowWidth : this.mWindowWidth - 250;
        const wrapperClass = classNames('layout-wrapper',
            {
                'layout-overlay': this.state.mlayoutMode === 'overlay',
                'layout-static': this.state.mlayoutMode === 'static',
                'layout-static-sidebar-inactive': this.state.mStaticMenuInactive && this.state.mlayoutMode === 'static',
                'layout-overlay-sidebar-active': this.state.mOverlayMenuActive && this.state.mlayoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.state.mMobileMenuActive
            }
        );

        const sidebarClassName = classNames("layout-sidebar",
            {
                'layout-sidebar-dark': this.state.mLayoutColorMode === 'dark',
                'layout-sidebar-light': this.state.mLayoutColorMode === 'light'
            }
        );

        let lAyChangeForm      = <AyChange     fnProcessClose={this.cfCloseAyBranchChangeForm} pShow={this.state.mAyChangeFlag}/>
        let lBranchChangeForm  = <BranchChange fnProcessClose={this.cfCloseAyBranchChangeForm} pShow={this.state.mBranchChangeFlag}/>

        let lFyChangeForm      = <FyChange     fnProcessClose={this.cfCloseFyCompanyChangeForm} pShow={this.state.mFyChangeFlag}/>
        let lCompanyChangeForm = <CompanyChange fnProcessClose={this.cfCloseFyCompanyChangeForm} pShow={this.state.mCompanyChangeFlag}/>

        let lThemeChangeForm   = null;
        if(this.state.mShowThemeForm)
        {
            lThemeChangeForm = <CSTheme fnProcessClose={this.cfCloseThemeChangeForm} pShow={this.state.mShowThemeForm} fnOnChange={this.cfOnThemeChange}/>
        }

        var lSidebar = null;
        if (this.state.mVisibleRight === true)
        {
            lSidebar = <CSCOSidebar fnProcessClose={this.cfHideSidebar} fnshow={this.cfSetSidebar} fnDisplayFeature={this.cfDisplayFeature}/>
        }
        
        var lRefresh = (this.mRefreshLayout === true)
        this.mRefreshLayout = false;

        let lFeature = null;
        if (this.state.mSelectedFeatureRefId === '0900901')
        {
            lFeature = <CSCOFullCalendar fnProcessClose={this.cfCloseFullCalendarForm} pShow={ this.state.mFullCalendarFlag }/>
        }
        else if (this.state.mSelectedFeatureRefId === '0900902')
        {
            lFeature = <CSCOOrganizationChart fnProcessClose={this.cfCloseOrganizationchart} pshow={ this.state.mOrganizationchart } />   
        }
        else if (this.state.mSelectedFeatureRefId === '0900904')
        {
            lFeature = <CSCONotificaton pshow={ this.state.mNotificationsFlag } />   
        }
        else (
            lFeature = <CSCOLayoutManager pFeatureRefId = {this.state.mSelectedFeatureRefId} pRefresh = { lRefresh }/>      
        )
        
        return (
            <div className={wrapperClass} onClick={this.cfOnWrapperClick}>
                <CSCONavBar
                    cfOnToggleMenu      = {this.cfOnToggleMenu}
                    cfAyChange          = {this.cfAyChange}
                    cfBranchChange      = {this.cfBranchChange}
                    cfFyChange          = {this.cfFyChange}
                    cfCompanyChange     = {this.cfCompanyChange}
                    cfThemeChange       = {this.cfThemeChange}
                    cfSetSidebar        = {this.cfSetSidebar}
                    fnDisplayFeature    = {this.cfDisplayFeature}
                    cfLogout            = {this.cfLogout}

                    id="ID_NAV_BAR" />

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.cfOnSidebarClick}>
                    <CSProfile 
                        fnClick         = {this.cfDisplayFeature} 
                        cfBranchChange  = {this.cfBranchChange}  
                        cfCompanyChange = {this.cfCompanyChange} />

                    <CSCOMenu 
                        cfOnMenuItemClick = {this.cfOnMenuItemClick} 
                        redirectFrom      = {this.state.mRedirectFrom} />
                </div>

                <div className="layout-main" id="ID_LAYOUT_AREA" ref={this.cfGetDimensions}>
                    { lFeature }
                </div>

                {lAyChangeForm}

                {lBranchChangeForm}

                {lFyChangeForm}

                {lCompanyChangeForm}
                
                {lThemeChangeForm}
                
                {lSidebar}
            </div>
        );
    }
}

export default App;
