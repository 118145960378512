import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from "react-to-print";
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import CSUtil from "../util/CSUtil";
import CSConfigUtil from "../util/CSConfigUtil";
import { fnConvertNumberToWords, fnNumberIndiaFormat } from '../util/CSMathUtil';
import { fnGetDateToString, fnGetStringToDate } from '../util/CSDateTimeUtil';
import CSRequestUtil from '../util/CSRequestUtil';
import { cfGetConstantValue } from '../util/CSConstantUtil';
import CSServiceUtil from '../util/CSServiceUtil';

let CryptoJS = require("crypto-js");

const CSReceiptForm7 = (lProps) =>
{
    const mComponentRef = useRef(null);

    const mParentCopyHeading = 'PARENT COPY';
    
    const [ mInstituteHeading, setInstituteHeading ] = useState('')
    const [ mStudentDetails, setStudentDetails ] = useState(
        {
            mStudentName          : '',
            mParentName           : '',
            mClass                : '',
            mSection              : '',
            mSectionLabel         : '',
            mRegistrationNo       : '',
            mRegistrationNoLabel  : ''
        }, []
    );

    const [ mFormTitle, setFormTitle ] = useState(CSConfigUtil.mReceiptHeading[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle1, setFormSubTitle1 ] = useState(CSConfigUtil.mReceiptSubHeading1[CSConfigUtil.mBranchCode]);
    const [ mFormSubTitle2, setFormSubTitle2 ] = useState(CSConfigUtil.mReceiptSubHeading2[CSConfigUtil.mBranchCode]);

    const [ mReceiptDetails, setReceiptDetails ] = useState(
        {
            mTransactionId     : '', 
            mReceiptNo         : '', 
            mReceiptDate       : '', 
            mBranchName        : '', 
            mAdmissionNo       : '',
            mInquiryNo         : '',
            mQrCode            : '',
            mEncryptedQrCode   : '',
            mTimestamp         : '',
            mPaymentInWords    : '',
            mIdName            : ''      
        }, [lProps]
    );

    const [mChequeNoTitle, setChequeNoTitle] = useState(null);
    const [mChequeDateTitle, setChequeDateTitle] = useState(null);
    const [mDraweeBankTitle, setDraweeBankTitle] = useState(null);

    const [ mTaxInvoiceTotalPayment, setTaxInvoiceTotalPayment ] = useState(0);
    const [ mTaxInvoicePaymentInWords, setTaxInvoicePaymentInWords ] = useState('');

    const [ mTaxInvoiceReceiptNo, setTaxInvoiceReceiptNo ] = useState([]);
    const [ mItemDetails, setItemDetails ] = useState([]);
    const [ mPaymentInfo, setPaymentInfo ] = useState(
        {
            mPaymentMode : '',
            mChequeNo    : '',
            mChequeDate  : '',
            mBank        : '',
            mAccountant  : '   '
        }
    );

    const [ mFooter, setFooter ] = useState("");

    useEffect(() =>
        { 
            const fnInitializeForm = () =>
            {
                let lDetailsServiceCode = null;
                let lReceiptServiceCode = null;
                let lReceiptDetails = null;
                let lReceiptDetailsName = null;
                let lDefaultDataField = 'selected_data_';
                let lCriteriaField = '';
                
                let lTotalPmtField = 'total_payment_amount_';

                if(lProps && lProps.pFormProperties)
                {
                    for(let lInd=0; lInd<lProps.pFormProperties.length;lInd++)
                    {
                        let lPropObj = lProps.pFormProperties[lInd];
                        if(lPropObj.type_ === 'initialize')
                        {
                            const lValue = lPropObj.value_;
                            if(lPropObj.property_ === 'details_service_code_')
                            {
                                lDetailsServiceCode = lValue;
                            }
                            if(lPropObj.property_ === 'receipt_service_code_')
                            {
                                lReceiptServiceCode = lValue;
                            }
                            else if(lPropObj.property_ === 'form_title_')
                            {
                                setFormTitle(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_1_')
                            {
                                setFormSubTitle1(lValue);
                            }
                            else if(lPropObj.property_ === 'form_sub_title_2_')
                            {
                                setFormSubTitle2(lValue);
                            }
                            else if(lPropObj.property_ === 'receipt_details_')
                            {
                                lReceiptDetailsName = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'default_data_field_')
                            {
                                lDefaultDataField = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'criteria_field_')
                            {
                                lCriteriaField = lPropObj.value_;
                            }
                            else if(lPropObj.property_ === 'footer_')
                            {
                                setFooter(lValue);
                            }
                            else if(lPropObj.property_ === 'total_payment_field_')
                            {
                                lTotalPmtField = lPropObj.value_;
                            }
                        }
                    }
                    
                    if(lReceiptDetailsName)
                    {
                        if(lProps.hasOwnProperty(lReceiptDetailsName))
                        {
                            let lRcptData = lProps[lReceiptDetailsName];
                            if(lDefaultDataField && lRcptData.hasOwnProperty(lDefaultDataField))
                            {
                                lReceiptDetails = lRcptData[lDefaultDataField];
                            }
                            else
                            {
                                lReceiptDetails = lRcptData;
                            }

                            let lRctData1 = [];
                            lRctData1.push(<td key={1}>Total Payment</td>);
                            lRctData1.push(<td key={2}>Rupees { fnNumberIndiaFormat(lReceiptDetails[lTotalPmtField]) }</td>);
                        }
                    }
                }

                if(lReceiptDetails)
                {
                    let lRecData = {}
                    let lStdData = {}
                    let lPmtInfo = {}
                    for(let lInd1=0; lInd1<lProps.pFormProperties.length;lInd1++)
                    {
                        let lPropObj0 = lProps.pFormProperties[lInd1];
                        if(lPropObj0.type_ === 'initialize')
                        {
                            const lValue = lPropObj0.value_;
                            if(lPropObj0.property_ === 'receipt_no_')
                            {
                                lRecData.mReceiptNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'institute_heading_')
                            {
                                setInstituteHeading(lValue);
                            }
                            else if(lPropObj0.property_ === 'admission_no_')
                            {
                                lRecData.mAdmissionNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'inquiry_no_')
                            {
                                lRecData.mInquiryNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'id_name_')
                            {
                                lRecData.mIdName = lValue;
                            }
                            else if(lPropObj0.property_ === 'branch_name_')
                            {
                                lRecData.mBranchName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'date_')
                            {
                                let lDate1 = fnGetStringToDate(lReceiptDetails[lValue]);
                                if(lDate1)
                                {
                                    let lDate1Str = fnGetDateToString(lDate1, 'display' )
                                    lRecData.mReceiptDate = lDate1Str;
                                }
                            }
                            else if(lPropObj0.property_ === 'time_')
                            {
                                lRecData.mReceiptTime = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'total_payment_')
                            {
                                if(lReceiptDetails[lValue])
                                {
                                    let lRcptVal = (Math.round(lReceiptDetails[lValue])).toFixed(0);
                                    lRecData.mPaymentInWords = fnConvertNumberToWords(lRcptVal);
                                }

                                lRecData.mTotalPayment = fnNumberIndiaFormat(lReceiptDetails[lValue]);
                            }
                            else if(lPropObj0.property_ === 'student_name_')
                            {
                                lStdData.mStudentName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'parent_name_')
                            {
                                lStdData.mParentName = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'class_')
                            {
                                lStdData.mClass = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'section_')
                            {
                                lStdData.mSection = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'section_label_')
                            {
                                lStdData.mSectionLabel = lValue;
                            }
                            else if(lPropObj0.property_ === 'registration_no_')
                            {
                                lStdData.mRegistrationNo = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'registration_no_label_')
                            {
                                lStdData.mRegistrationNoLabel = lValue;
                            }
                            else if(lPropObj0.property_ === 'purchased_for_class_')
                            {
                                lStdData.mPurchasedFor = lReceiptDetails[lValue];
                            }
                            else if(lPropObj0.property_ === 'payment_mode_')
                            {
                                lPmtInfo.mPaymentMode = lReceiptDetails[lValue];
                                let lChequeNoTitle = '';
                                let lChequeDateTitle  = '';
                                let lDraweeBankTitle  = '';

                                if(lPmtInfo.mPaymentMode === 'CASH')
                                {
                                    lChequeNoTitle = '';
                                    lChequeDateTitle  = '';
                                    lDraweeBankTitle  = '';
                                }
                                else if(lPmtInfo.mPaymentMode === 'POS')
                                {
                                    lChequeNoTitle = 'Batch No/Appr Code';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.batch_no_ + "/" + lReceiptDetails.approval_code_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'BANK_TXFR')
                                {
                                    lChequeNoTitle = 'UTR No';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.utr_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'UPI')
                                {
                                    lChequeNoTitle = 'UPI No';
                                    lChequeDateTitle  = 'Remittance Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.upi_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'BBPS')
                                {
                                    lChequeNoTitle = 'UPI No';
                                    lChequeDateTitle  = '';
                                    lDraweeBankTitle  = '';

                                    lPmtInfo.mChequeNo = lReceiptDetails.upi_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                }
                                else if(lPmtInfo.mPaymentMode === 'CHEQUE')
                                {
                                    lChequeNoTitle = 'Cheque No';
                                    lChequeDateTitle  = 'Cheque Date';
                                    lDraweeBankTitle  = 'Bank';

                                    lPmtInfo.mChequeNo = lReceiptDetails.cheque_no_;
                                    lPmtInfo.mChequeDate = lReceiptDetails.date_;
                                    lPmtInfo.mBank = lReceiptDetails.bank_;
                                }

                                setChequeDateTitle(lChequeDateTitle);
                                setChequeNoTitle(lChequeNoTitle);
                                setDraweeBankTitle(lDraweeBankTitle);
                            }
                        }
                    }

                    if(lRecData.mReceiptDate && lRecData.mReceiptTime)
                    {
                        lRecData.mTimestamp = lRecData.mReceiptDate + lRecData.mReceiptTime; 
                        lRecData.mQrCode  =    "Receipt Date : "    + lRecData.mReceiptDate;
                        lRecData.mQrCode += "\n Receipt Time : "    + lRecData.mReceiptTime;
                        lRecData.mQrCode += "\n Student Name : "    + lStdData.mStudentName;
                        lRecData.mQrCode += "\n Parent Name : "     + lStdData.mParentName;
                        lRecData.mQrCode += "\n Class : "           + lStdData.mClass;
                        lRecData.mQrCode += "\n Section : "         + lStdData.mSection;
                        lRecData.mQrCode += "\n Admission No : "    + lRecData.mAdmissionNo;
                        lRecData.mQrCode += "\n Inquiry No : "      + lRecData.mInquiryNo;
                        lRecData.mQrCode += "\n Registration No : " + lRecData.mRegistrationNo;
                        lRecData.mQrCode += "\n Payment : "         + lRecData.mTotalPayment;

                        let lTmpdata = CSUtil.cfGetObjectCopy(lRecData.mQrCode);
                        lTmpdata = "Transaction Id : " + lRecData.id_ + "\n " + lTmpdata;
                        lRecData.mEncryptedQrCode = CryptoJS.AES.encrypt(JSON.stringify(lTmpdata), 'cs_app').toString();
                    }

                    setReceiptDetails(lRecData);
                    setStudentDetails(lStdData);
                    setPaymentInfo(lPmtInfo);
                    
                    if(lReceiptDetails.hasOwnProperty(lCriteriaField))
                    {
                        let lCriteriaObj = {};
                        lCriteriaObj.receipt_no_ = lReceiptDetails.receipt_no_;
                        let lServiceUtil = CSServiceUtil.cfGetInstance();
                        if(lReceiptServiceCode)  
                        {
                            let lReceiptServiceObject = lServiceUtil.cfGetServiceObject(lReceiptServiceCode);
                            if(lReceiptServiceObject)
                            {
                                let lRequestUtil0 = new CSRequestUtil('GET', lReceiptServiceCode, lReceiptServiceObject.type_, null, lReceiptServiceObject);

                                lRequestUtil0.cfSetRequestCriteria(lCriteriaObj);
                                lRequestUtil0.cfProcessRequest((lResponse0) =>
                                    {
                                        if((lResponse0) && lResponse0.hasOwnProperty("collection_"))
                                        {
                                            if(lResponse0.collection_ && (lResponse0.collection_.length > 0))
                                            {
                                                let lRcptObj = lResponse0.collection_[0];
                                                setTaxInvoiceReceiptNo(lRcptObj.tax_invoice_receipt_no_);
                                            }
                                        }
                                    }
                                );
                            }
                        }

                        if(lDetailsServiceCode)
                        {
                            let lServiceObject = lServiceUtil.cfGetServiceObject(lDetailsServiceCode);
                            if(lServiceObject)
                            {
                                let lRequestUtil = new CSRequestUtil('GET', lDetailsServiceCode, lServiceObject.type_, null, lServiceObject);
                                if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                                {
                                    let lServiceProperties = lServiceObject.cs_service_properties_;
                                    if(lServiceProperties !== null)
                                    {
                                        for(let lspInd=0; lspInd<lServiceProperties.length; lspInd++)
                                        {
                                            lCriteriaObj[lServiceProperties[lspInd].property_] = cfGetConstantValue(lServiceProperties[lspInd].value_);
                                        }
                                    }
                                }

                                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                                lRequestUtil.cfProcessRequest((lResponse) =>
                                    {
                                        let lCollection = [];
                                        if((lResponse) && lResponse.hasOwnProperty("collection_"))
                                        {
                                            let lItemCollection = [];
                                            lCollection = lResponse.collection_;
                                            let lcgstValue = 0;
                                            let lsgstValue = 0;
                                            let lgstValue = 0;
                                            let lKeyInd = 1000
                                            let lTaxInvoiceTotalPayment = 0;
                                            let lTaxInvoiceNetPayment = 0;

                                            for(let lpInd=0; lpInd<lCollection.length; lpInd++)
                                            {
                                                let lData = lCollection[lpInd];
                                                let lItemData = [];

                                                if(Number(lData.gst_percentage_) > 0)
                                                {
                                                    lItemData.push( <td key={lKeyInd + 101} > {lItemCollection.length + 1} </td> );
                                                    lItemData.push( <td key={lKeyInd + 102} > {lData.item_name_}        </td> );
                                                    lItemData.push( <td key={lKeyInd + 103} > {lData.hsn_code_}         </td> );
                                                    lItemData.push( <td key={lKeyInd + 104} > {lData.gst_percentage_}   </td> );
                                                    lItemData.push( <td key={lKeyInd + 105} > {lData.quantity_}         </td> );
                                                    lItemData.push( <td key={lKeyInd + 107} > {lData.sale_price_}       </td> );
                                                    lItemCollection.push(<tr key={lKeyInd + 108}>{lItemData}</tr>);
                                                
                                                    lcgstValue = Number(lcgstValue) + Number(lData.cgst_value_);
                                                    lsgstValue = Number(lsgstValue) + Number(lData.sgst_value_);
                                                    lgstValue  = Number(lcgstValue) + Number(lsgstValue);

                                                    lTaxInvoiceTotalPayment += Number(lData.item_amount_);
                                                    lTaxInvoiceNetPayment += Number(lData.sale_price_);
                                                }

                                                if(lpInd === lCollection.length - 1)
                                                {
                                                    let lnettData = [];
                                                    lnettData.push( <td key={lKeyInd + 109}/> );
                                                    lnettData.push( <td key={lKeyInd + 110}/> );
                                                    lnettData.push( <td key={lKeyInd + 111}/> );
                                                    lnettData.push( <td key={lKeyInd + 112}/> );
                                                    lnettData.push( <td key={lKeyInd + 113}> Net Amt. </td> );
                                                    lnettData.push( <td key={lKeyInd + 114}> {Math.round(lTaxInvoiceNetPayment).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 115}>{lnettData}</tr>);

                                                    let lcgstData = [];
                                                    lcgstData.push( <td key={lKeyInd + 116}/> );
                                                    lcgstData.push( <td key={lKeyInd + 117}/> );
                                                    lcgstData.push( <td key={lKeyInd + 118}/> );
                                                    lcgstData.push( <td key={lKeyInd + 119}/> );
                                                    lcgstData.push( <td key={lKeyInd + 120}> CGST Amt. </td> );
                                                    lcgstData.push( <td key={lKeyInd + 121}> {Math.round(lcgstValue).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 122}>{lcgstData}</tr>);

                                                    let lsgstData = [];
                                                    lsgstData.push( <td key={lKeyInd + 123}/> );
                                                    lsgstData.push( <td key={lKeyInd + 124}/> );
                                                    lsgstData.push( <td key={lKeyInd + 125}/> );
                                                    lsgstData.push( <td key={lKeyInd + 126}/> );
                                                    lsgstData.push( <td key={lKeyInd + 127}> SGST Amt. </td> );
                                                    lsgstData.push( <td key={lKeyInd + 128}> {Math.round(lsgstValue).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 129}>{lsgstData}</tr>);

                                                    let lgstData = [];
                                                    lgstData.push( <td key={lKeyInd + 130}/> );
                                                    lgstData.push( <td key={lKeyInd + 131}/> );
                                                    lgstData.push( <td key={lKeyInd + 132}/> );
                                                    lgstData.push( <td key={lKeyInd + 133}/> );
                                                    lgstData.push( <td key={lKeyInd + 134}> Total GST. </td> );
                                                    lgstData.push( <td key={lKeyInd + 135}> {Math.round(lgstValue).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 136}>{lgstData}</tr>);

                                                    let ltotData = [];
                                                    ltotData.push( <td key={lKeyInd + 137}/> );
                                                    ltotData.push( <td key={lKeyInd + 138}/> );
                                                    ltotData.push( <td key={lKeyInd + 139}/> );
                                                    ltotData.push( <td key={lKeyInd + 140}/> );
                                                    ltotData.push( <td key={lKeyInd + 141}> Total Amt. </td> );
                                                    ltotData.push( <td key={lKeyInd + 142}> {Math.round(lTaxInvoiceTotalPayment).toFixed(2)} </td> );

                                                    lItemCollection.push(<tr key={lKeyInd + 143}>{ltotData}</tr>);
                                                }

                                                lKeyInd += 16;
                                            }

                                            let lRcptVal1 = (Math.round(lTaxInvoiceTotalPayment)).toFixed(2);
                                            setTaxInvoiceTotalPayment(fnNumberIndiaFormat(lRcptVal1));
                                            setTaxInvoicePaymentInWords(fnConvertNumberToWords(Math.round(lTaxInvoiceTotalPayment)));

                                            setItemDetails(lItemCollection);
                                        }                                
                                    }
                                );
                            }
                        }
                    }
                }
            }

            fnInitializeForm();
        }, []
    );

    let lHeaderActions = [];
    lHeaderActions.push(
        <ReactToPrint
            key = "1"
            trigger={() => 
                <Button
                    style=
                        {
                            {
                                fontSize        : '13.2px', 
                                marginRight     : '.35em', 
                                width           : '24px', 
                                height          : '24px',
                                verticalAlign   : 'middle'
                            }
                        } 
                        type="Button" 
                        icon={CSUtil.mNameIconMap['PI_PRINT']} 
                    />
                }
            content={() => mComponentRef.current}
        />
    );
        
    lHeaderActions.push(
        <Button
            key = "2"
            style=
                {
                    {
                        fontSize        : '13.2px', 
                        marginRight     : '.35em', 
                        width           : '24px', 
                        height          : '24px',
                        verticalAlign   : 'middle'
                    }
                } 
            type="Button" 
            icon={CSUtil.mNameIconMap['PI_TIMES']}
            onClick= { () => lProps.fnProcessClose(true, true) }
        />
    );

    let lHeader = 
        <>
            <div className="p-toolbar-group-left">
                <h2>{lProps.pFormHeading}</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </>

    const lForm = 
        <div id="rf_1" ref={mComponentRef}>
            <div className="rf1 overflow-auto">
                <div style={{minWidth: '750px'}}>
                    <main>
                        <div className="top">
                            <div className="heading-copy">
                                { mParentCopyHeading }
                            </div>
                        </div>

                        <div className="title-summary">
                            <div className='tax-info'>
                                    <div className="form-item-row"> 
                                        <div style={{width: '80px', fontWeight: 'bold'}}>GSTIN</div>
                                        <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                        <div style={{width: '80%'}}>36AARFG5671Q1ZE</div>
                                    </div>
                                    <div className="form-item-row"> 
                                        <div style={{width: '80px', fontWeight: 'bold'}}>Place</div>
                                        <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                        <div style={{width: '80%'}}>Hyderabad - 36</div>
                                    </div>
                            </div>

                            <div className="title">
                                <h1>{ mInstituteHeading }</h1>

                                <div className='sub-title'>
                                    <h2>{ mFormSubTitle1 }</h2>
                                    <h2>{ mFormSubTitle2 }</h2>
                                </div>
                            </div>
                        </div>

                        <div className='form-title'>
                            <h2>TAX INVOICE</h2>
                        </div>

                        <div className="summary">
                            <div className="student-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Customer Name</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mStudentName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Class</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mClass}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '150px', fontWeight: 'bold'}}>Section</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div style={{width: '80%'}}>{mStudentDetails.mSection}</div>
                                </div>
                            </div>
                    
                            <div className="receipt-details">
                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Receipt No</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mTaxInvoiceReceiptNo}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Location</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mBranchName}</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>{ mReceiptDetails.mIdName }</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{ (mReceiptDetails.mIdName === 'Admission No') ? (mReceiptDetails.mAdmissionNo) : (mReceiptDetails.mInquiryNo) }</div>
                                </div>

                                <div className="form-item-row"> 
                                    <div style={{width: '110px', fontWeight: 'bold'}}>Date</div>
                                    <div style={{width: '25px', fontWeight: 'bold'}}>:&nbsp;&nbsp;</div>
                                    <div>{mReceiptDetails.mReceiptDate}</div>
                                </div>
                            </div>
                        </div>
                         
                        <div className="payment-details">
                            <table className="items">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Product/Item Name</th>
                                        <th>HSN Code</th>
                                        <th>GST(%)</th>
                                        <th>Qty</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mItemDetails}
                                </tbody>
                            </table>
                        </div>

                        <div className="pmt-amt"> 
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment Amount</div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>{mTaxInvoiceTotalPayment}</div>
                            </div>
                                
                            <div className="pmt-words"> 
                                <div style={{fontWeight: 'bold'}}>Payment in Words </div>
                                <div style={{width: '15px', fontWeight: 'bold'}}>&nbsp;&nbsp;:</div>
                                <div>Rupees {mTaxInvoicePaymentInWords}</div>
                            </div>
                        </div>
                        
                        <div className="payment-info">
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { "Payment Mode" }
                                </div>

                                { mPaymentInfo.mPaymentMode }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeNoTitle }
                                </div>

                                { mPaymentInfo.mChequeNo }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mChequeDateTitle }
                                </div>

                                { mPaymentInfo.mChequeDate }
                            </div>
                            <div className="pi-item">
                                <div className="pi-item-heading">
                                    { mDraweeBankTitle }
                                </div>

                                { mPaymentInfo.mBank }
                            </div>
                            <div className="pi-item-accountant">
                                { mPaymentInfo.mAccountant }

                                <div className="pi-item-heading">
                                    { "Principal/Accountant" }
                                </div>
                            </div>
                        </div>
                        <div className="cheque-realization"> 
                            { mFooter }
                        </div>
                    </main>
                </div>
            </div>
        </div>

    return (
        <Dialog
            visible   = {true} 
            minheight = 'auto'
            closable  = {false}
            appendTo  = { document.body }
            header    = {lHeader}
            onHide    = { () => lProps.fnProcessClose(true, true) }>
                { lForm }
        </Dialog>
    );
}

export default CSReceiptForm7;