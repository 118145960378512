import React from "react";

import CSCOFormLayoutManager from "../layout/CSCOFormLayoutManager"
import CSCOAnalysisLayoutManager from "./analysis/CSCOAnalysisLayoutManager"
import CSUtil from "../util/CSUtil";

class CSCOLayoutManager extends React.Component
{
    mCSUtil = null;
    constructor(props)
    {
        super(props);
        this.mCSUtil = CSUtil.cfGetInstance();
        this.state = 
        {
            mFeatureRefId: props.pFeatureRefId,
            mLayoutType: this.mCSUtil.cfGetLayoutType(this.props.pFeatureRefId),
            mLayoutCode: this.mCSUtil.cfGetLayoutCode(this.props.pFeatureRefId),
            mLayoutData: {}
        };

        this.cfProcessResponse = this.cfProcessResponse.bind(this);
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps.pFeatureRefId !== this.props.pFeatureRefId)
        {
            var lLayoutType = this.mCSUtil.cfGetLayoutType(this.props.pFeatureRefId);
            var lLayoutCode = this.mCSUtil.cfGetLayoutCode(this.props.pFeatureRefId);
            this.setState(
                {
                    mFeatureRefId   : this.props.pFeatureRefId,
                    mLayoutType     : lLayoutType,
                    mLayoutCode     : lLayoutCode
                }
            )
        }
    }
  
    cfProcessResponse(lRspCol)
    {
        if(lRspCol)
        {
        }
    }

    render()
    {    
        /**
         * Return respective layout
         */
        var lLayout = null;
        switch(this.state.mLayoutType)
        {
            case 'ANALYSIS':
                lLayout = <CSCOAnalysisLayoutManager 
                    pFeatureRefId   = {this.state.mFeatureRefId} 
                    pLayoutCode     = {this.state.mLayoutCode}
                    pLayoutType     = {this.state.mLayoutType}/>
            break;

            case 'APPLICATION':
            case 'HOME':
            case 'BASIC':
            case 'STANDARD':
            case 'FORM':
            case 'DASHBOARD':
            case 'DETAILS':
                lLayout = <CSCOFormLayoutManager 
                    pFeatureRefId   = {this.state.mFeatureRefId}
                    pLayoutCode     = {this.state.mLayoutCode}
                    pLayoutType     = {this.state.mLayoutType}
                    pRefresh        = {this.props.pRefresh}/>
            break;

            default:
            break;
        }

        return lLayout;
    }
}

export default CSCOLayoutManager;
