import React, { useState, useEffect, useCallback, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { ListBox } from 'primereact/listbox';

import { fnGetForm } from "../../util/CSLayoutUtil"
import { fnGetFormHeader } from "../../util/CSLayoutUtil"
import { cfGetConstantValue } from "../../util/CSConstantUtil";

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil";
import CSConfigUtil from "../../util/CSConfigUtil";
import CSRequestUtil from "../../util/CSRequestUtil";

import CSCOGrid from "../../components/CSCOGrid"
import CSCOCard from "../../components/CSCOCard"
import CSServiceUtil from '../../util/CSServiceUtil';
import CSCOBackdrop from "../../components/CSCOBackdrop";
import CSCOCriteria from "../../components/CSCOCriteria"
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar"
import CSCOHorizontalActionBar from '../../components/CSCOHorizontalActionBar';
import HGROUP from '../../components/CSCOHGroup'
import VGROUP from '../../components/CSCOVGroup';
const mFormUtil = CSFormUtil.cfGetInstance();
const mCSServiceUtil = CSServiceUtil.cfGetInstance();

const CSCOFormLayout = (lProps) => {
    let mSelectedRecord = useRef(null);
    let mCriteriaObject = useRef(null);
    let mCriteriaServiceCode = useRef(null);
    let mDoubleClickActionItem = useRef(null);
    let mSelectedDataCollection = useRef(null);

    const mCSUtil = CSUtil.cfGetInstance();
    const [mFormHeading, setFormHeading]               = useState('');
    const [mSearchActionList, setSearchActionList]     = useState(null);
    const [mSearchActions, setSearchActions]           = useState(null);
    const [mEnableDetailsTabs, setEnableDetailsTabs]   = useState(false);
    const [mDetailsFlag, setDetailsFlag]               = useState(false);
    const [mDetailsForm, setDetailsForm]               = useState(null);

    const [mPreviewFlag, setPreviewFlag]               = useState(false);
    const [mPreviewForm, setPreviewForm]               = useState(null);
    const [mShowBackdrop, setBackdrop]                 = useState(false);
    const [mLayoutType, setLayoutType]                 = useState('grid');

    const [mSelectedItem, setSelectedItem]             = useState(null);
    const [mSelectedListItem, setSelectedListItem]     = useState(null);
    const [mSelectedActionItem, setSelectedActionItem] = useState(null);

    const [mEnableFilter, setEnableFilter]             = useState(true);
    const [mApplicationWidth, setApplicationWidth]     = useState(CSUtil.mApplicationWidth);

    const [mFormCriteria, setFormCriteria]             = useState(null);
    const [mDataCollection, setDataCollection]         = useState(null);
    const [mListCollection, setListCollection]         = useState(null);
    const [mResetActions, setResetActions]             = useState(false);
    const [mListLabel, setListLabel]                   = useState(null);
    const [mFeatureRefId, setFeatureRefId]             = useState(lProps.pFeatureRefId);
    const [mRefIdActionMap, setRefIdActionMap]         = useState(null);

    useEffect(() => {
        if (mDetailsFlag) {
            setApplicationWidth(mCSUtil.mApplicationWidth);
        }
    }, [mCSUtil.mApplicationWidth, mDetailsFlag]
    );

    const cfGetDimensions = (lId) => {
        let lHeight;
        if (lId) {
            let lElement = document.getElementById(lId);
            if (lElement) {
                let lRect = lElement.getBoundingClientRect();
                if (lRect) {
                    lHeight = lRect.height;
                }
            }
        }

        return lHeight;
    }

    const fnEnableActions = (lActionItem, lSelectedData) => {
        if (lActionItem) {
            if (lActionItem.action_type_ === '1') {
                lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, lSelectedData);
                lActionItem.enabled_ = CSFormUtil.cfEnableAction(lActionItem, lSelectedData);
            }
            else {
                lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
            }

            return lActionItem;
        }
    }

    const fnOnDataSelection = (lSelectedData) => {
        if (mSearchActionList) {
            let lSearchActionList = mSearchActionList.map((lActionItem) => {
                    return fnEnableActions(lActionItem, lSelectedData);
                }
            );

            setSearchActionList(lSearchActionList);
            setSearchActions(fnGetSearchActions(lSearchActionList))
        }

        mSelectedRecord.current = lSelectedData;
    };

    const fnOnDoubleClick = (lSelectedData) => {
        if (mDoubleClickActionItem.current) {
            fnProcessModify(mDoubleClickActionItem.current, lSelectedData);
        }
    };

    const fnProcessSearch = useCallback((lServiceCode, lCriteria, lBackdropFlag) => {
        if (lServiceCode) {
            if (!lBackdropFlag) {
                setBackdrop(true);
            }

            let lServiceObject = mCSServiceUtil.cfGetServiceObject(lServiceCode);
            let lLayoutType = 'grid';
            if (lServiceObject) {
                let lCriteriaObj = (lCriteria) ? lCriteria : {};
                let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                if (lServiceObject.hasOwnProperty('cs_service_properties_')) {
                    let lServiceProperties = lServiceObject.cs_service_properties_;
                    if (lServiceProperties !== null) {
                        for (let lInd = 0; lInd < lServiceProperties.length; lInd++) {
                            lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                        }
                    }
                }

                if (lProps.pFormProperties) {
                    let lSelectedData = (lProps && lProps.hasOwnProperty('pFormData') && lProps.pFormData.hasOwnProperty('selected_data_')) ? lProps.pFormData.selected_data_ : null;
                    for (let lfpInd1 = 0; lfpInd1 < lProps.pFormProperties.length; lfpInd1++) {
                        let lProperty = lProps.pFormProperties[lfpInd1];
                        if (lSelectedData) {
                            if ((lProperty.type_ === 'initialize') && (lProperty.property_ === 'criteria_field_') && lSelectedData[lProperty.value_]) {
                                lCriteriaObj[lProperty.value_] = lSelectedData[lProperty.value_];
                            }
                        }
                        
                        if ((lProperty.type_ === 'layout') && (lProperty.property_ === 'layout_type_') && lProperty.value_) {
                            lLayoutType = lProperty.value_;
                        }
                    }
                }

                lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                lRequestUtil.cfProcessRequest((lResponse) => {
                    if (lResponse && lResponse.hasOwnProperty("collection_")) {
                        let lCollection = lResponse.collection_;
                        let lSelectedDataCollection = null;
                        if (lCollection) {
                            let lName;
                            let lValue;
                            if (lProps.pFormGridProperties) {
                                for (let lGpInd = 0; lGpInd < lProps.pFormGridProperties.length; lGpInd++) {
                                    if (lProps.pFormGridProperties[lGpInd].property_ === 'display_selection') {
                                        lName = lProps.pFormGridProperties[lGpInd].name_;
                                        lValue = lProps.pFormGridProperties[lGpInd].value_;
                                        break;
                                    }
                                }
                            }

                            if (lName && lValue) {
                                for (let lInd = 0; lInd < lCollection.length; lInd++) {
                                    let lDataObj = lCollection[lInd];
                                    if (lDataObj[lName] === lValue) {
                                        if (!lSelectedDataCollection) {
                                            lSelectedDataCollection = [];
                                        }

                                        lSelectedDataCollection.push(lDataObj);
                                    }
                                }
                            }
                        }

                        if((lLayoutType === 'tree-grid') || (lLayoutType === 'tree-form')) {
                            setListCollection(lCollection);
                        }
                        else {
                            setDataCollection(lCollection);
                        }

                        if (!lBackdropFlag) {
                            setBackdrop(false);
                        }
                    }
                    else {
                        if (!lBackdropFlag) {
                            setBackdrop(false);
                        }
                    }
                }
                );
            }
            else {
                if (!lBackdropFlag) {
                    setBackdrop(false);
                }
            }
        }
    }, [lProps.pFormGridProperties, lProps.pFormProperties]
    );

    const fnSearch = useCallback((lCriteria, lActionItem) => {
        if (lActionItem) {
            let lServiceCode = lActionItem.on_click_service_code_;
            if (lActionItem.action_ === 'process_search') {
                if (lServiceCode) {
                    mCriteriaObject.current = lCriteria;
                    mCriteriaServiceCode.current = lServiceCode;
                    fnProcessSearch(lServiceCode, lCriteria);
                }
            }
        }
    }, [fnProcessSearch]
    );

    const fnGetSearchActions = (lSearchActionList, lAlign, lBackgroundColor, lBorderColor, lPadding) => {
        let lSearchActions = null;

        if (lSearchActionList) {
            lSearchActions =
                <CSCOBaseActionBar
                    pPadding = {lPadding}
                    borderColor={lBorderColor}
                    backgroundColor={lBackgroundColor}
                    align={ (lAlign) ? lAlign : "left" }
                    pType='SEARCH_ACTION_BAR'
                    actionList={lSearchActionList}
                />
        }

        return lSearchActions;
    }

    const fnProcessClose = useCallback((lRefreshFlag, lCloseFlag, lParent) => {
        setDetailsForm(null);
        setDetailsFlag(false);
        setEnableDetailsTabs(false);
        let lLayoutType = null;
        let lSelectedListItem = null;
        if(lParent && lParent.hasOwnProperty(mLayoutType)) {
            lLayoutType = lParent.mLayoutType;
            lSelectedListItem = lParent.mSelectedListItem;
        }

        if((lLayoutType === 'tree-form') && lSelectedListItem) {
            fnOnListItemChange(null, lSelectedListItem);
        }
        else {
            if (lRefreshFlag) {
                let lDefaultCriteria = false;
                if (lProps.pFormProperties) {
                    for (let lfpInd = 0; lfpInd < lProps.pFormProperties.length; lfpInd++) {
                        let lProperty = lProps.pFormProperties[lfpInd];
                        if ((lProperty.type_ === 'initialize') && (lProperty.property_ === 'service_code_')) {
                            fnProcessSearch(lProperty.value_, mCriteriaObject.current);
                            lDefaultCriteria = true;
                            break;
                        }
                    }
                }

                if (!lDefaultCriteria && mCriteriaServiceCode.current) {
                    fnProcessSearch(mCriteriaServiceCode.current, mCriteriaObject.current);
                }
            }
        }

        setResetActions(true);
        mSelectedRecord.current = null;
    }, [lProps.pFormProperties, fnProcessSearch]);

    const fnProcessImport = (lEvent, lActionItem) => {
    }

    const fnProcessPreview = (lEvent, lActionItem) => {
        let lPreviewFormCode = lActionItem.navigate_to_form_;
        if (lProps.pFormProperties && lPreviewFormCode && mSelectedRecord) {
            let lFormProperties = null;
            let lCriteriaKeys = null;
            for (let lGInd = 0; lGInd < lProps.pFormGrid.length; lGInd++) {
                let lGridObject = lProps.pFormGrid[lGInd];
                if (lGridObject.criteria_field_) {
                    if (!lCriteriaKeys) {
                        lCriteriaKeys = [];
                    }

                    lCriteriaKeys.push(lGridObject.criteria_field_);
                }
            }

            let lCriteria = null;
            if (lCriteriaKeys) {
                lCriteria = {};
                for (let lCInd = 0; lCInd < lCriteriaKeys.length; lCInd++) {
                    lCriteria[lCriteriaKeys[lCInd]] = mSelectedRecord.current[lCriteriaKeys[lCInd]];
                }
            }

            let lMetaData = {}

            for (let lfpInd = 0; lfpInd < lProps.pFormProperties.length; lfpInd++) {
                let lfpObj = lProps.pFormProperties[lfpInd];
                if (lfpObj.cs_form_code_ === lPreviewFormCode) {
                    if (!lFormProperties) {
                        lFormProperties = [];
                    }

                    lFormProperties.push(lfpObj);
                }
            }

            lMetaData.pCriteria = lCriteria;
            lMetaData.pFormProperties = lFormProperties;
            lMetaData.pFormType = lActionItem.navigate_to_form_component_code_;
            lMetaData.pSelectedActionItem = lActionItem;
            lMetaData.fnClose = () => {
                setDetailsFlag(false);
                setPreviewFlag(false);
            }

            setDetailsFlag(false);
            setPreviewFlag(true);
            setPreviewForm(fnGetForm(lMetaData));
        }
    }

    const fnGetFormItems = (lId) => {
        if (lId) {
            let lFormItems = [];
            for (let lInd = 0; lInd < lProps.pFormItems.length; lInd++) {
                let lFormItem = lProps.pFormItems[lInd];
                if (lFormItem.action_ref_id_ === lId) {
                    lFormItems.push(lFormItem);
                }
            }

            return lFormItems;
        }
    }

    const fnAddToCollection = () => {
    }

    const fnUpdateCollection = () => {
    }
    /*
        const fnRemoveFromCollection = () => {
        }
    */
    const fnProcessAdd = (lActionItem) => {
        if (lActionItem) {
            let lActions = [];
            let lActionIdChildrenMap = {};
            for (let lInd = 0; lInd < lProps.pFormActions.length; lInd++) {
                let lAction = lProps.pFormActions[lInd];
                if (lAction.parent_ref_id_) {
                    let lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if (!lChildren) {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if (((lAction.component_type_ === 'DETAILS_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION'))
                    && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_))) {
                    lActions.push(lAction);
                }

                if (lActionIdChildrenMap && lActions) {
                    let lChildList = null;
                    for (let lPInd = 0; lPInd < lActions.length; lPInd++) {
                        if (lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_]) {
                            let lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                            if (!lChildList) {
                                lChildList = [];
                            }

                            lChildList = [...lChildList, ...lCList];
                        }
                    }

                    if (lChildList) {
                        lActions = [...lActions, ...lChildList];
                    }
                }

                let lCriteria = null;
                if (lProps.hasOwnProperty('pFormData') && lProps.pFormData) {
                    if (lProps.pFormData.hasOwnProperty('selected_criteria_')) {
                        lCriteria = lProps.pFormData.selected_criteria_;
                    }
                }

                let lSelectionData = null;
                let lSelectedData = (lProps && lProps.hasOwnProperty('pFormData') && lProps.pFormData.hasOwnProperty('selected_data_')) ? lProps.pFormData.selected_data_ : null;
                if (!lSelectedData) {
                    lSelectedData = mSelectedRecord.current;
                }

                for (let lGInd = 0; lGInd < lProps.pFormGrid.length; lGInd++) {
                    let lGridObject = lProps.pFormGrid[lGInd];
                    if (lGridObject.criteria_field_) {
                        if (!lCriteria) {
                            lCriteria = {};
                        }

                        if (lSelectedData && lSelectedData[lGridObject.criteria_field_]) {
                            lCriteria[lGridObject.criteria_field_] = lSelectedData[lGridObject.criteria_field_];
                        }
                        else if (lGridObject.constant_) {
                            lCriteria[lGridObject.criteria_field_] = cfGetConstantValue(lGridObject.constant_);
                        }
                    }
                }

                let lDetailsMetaData = {
                    pAppId                : lProps.pAppId,
                    pFormType             : lActionItem.navigate_to_form_component_code_,
                    pFormHeading          : lActionItem.title_,
                    pFormActions          : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                    pFormProperties       : lProps.pFormProperties,
                    pFormItems            : fnGetFormItems(lActionItem.id_),
                    pFormItemProperties   : lProps.pFormItemProperties,
                    pFormItemComputation  : lProps.pFormItemComputation,
                    pFormSelection        : lProps.pFormSelection,
                    pFormDetails          : lProps.pFormDetails,
                    pFormValidation       : lProps.pFormValidation,
                    pSelectedActionItem   : lActionItem,
                    pResponseFn           : fnAddToCollection,
                    pFormMetaData         : lProps.pFormMetaData,
                    pApplicationWidth     : mApplicationWidth,
                    pSelectedCriteria     : lCriteria,
                    pSelectedData         : lProps.pSelectedData
                };

                lDetailsMetaData.pFormData = lSelectedData;

                if (lProps.pFormProperties && lSelectedData) {
                    lSelectionData = {}
                    for (let lfpInd = 0; lfpInd < lProps.pFormProperties.length; lfpInd++) {
                        let lFormPropertyObj = lProps.pFormProperties[lfpInd];
                        if (lFormPropertyObj.type_ === 'initialize' && lFormPropertyObj.property_ === 'selection_data_field_') {
                            if (lFormPropertyObj.value_ === '#ALL#') {
                                lSelectionData = lSelectedData;
                                break;
                            }
                            else {
                                lSelectionData[lFormPropertyObj.value_] = lSelectedData[lFormPropertyObj.value_];
                            }
                        }
                    }
                }

                let lDetailsForm = fnGetForm(lDetailsMetaData, lSelectionData, fnProcessClose);
                setDetailsFlag(true);
                setPreviewFlag(false);
                if (lActionItem.navigate_to_form_component_code_ === 'CS_DETAILS_FORM_TAB_LAYOUT_1') {
                    setEnableDetailsTabs(true);
                }
                else {
                    setEnableDetailsTabs(false);
                }

                setDetailsForm(lDetailsForm);
            }
        }
    }

    const fnProcessModify = (lActionItem, lSelectedData, lGridData) => {
        lGridData = mSelectedDataCollection.current;
        if (!lSelectedData) {
            lSelectedData = mSelectedRecord.current;
            if (!lSelectedData) {
                if (lProps.hasOwnProperty('pFormData') && lProps.pFormData && lProps.pFormData.hasOwnProperty('selected_data_')) {
                    lSelectedData = lProps.pFormData.selected_data_;
                }
            }
        }

        if (lSelectedData && lActionItem && lProps.pFormActions) {
            let lActions = [];
            let lActionIdChildrenMap = {};
            for (let lInd = 0; lInd < lProps.pFormActions.length; lInd++) {
                let lAction = lProps.pFormActions[lInd];
                if (lAction.parent_ref_id_) {
                    let lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if (!lChildren) {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if (((lAction.component_type_ === 'DETAILS_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION'))
                    && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_))) {
                    lActions.push(lAction);
                }
            }

            if (lActionIdChildrenMap && lActions) {
                let lChildList = null;
                for (let lPInd = 0; lPInd < lActions.length; lPInd++) {
                    if (lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_]) {
                        let lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                        if (!lChildList) {
                            lChildList = [];
                        }

                        lChildList = [...lChildList, ...lCList];
                    }
                }

                if (lChildList) {
                    lActions = [...lActions, ...lChildList];
                }
            }

            let lCriteria = null;
            if (lProps.hasOwnProperty('pFormData') && lProps.pFormData && lProps.pFormData.hasOwnProperty('selected_criteria_')) {
                lCriteria = lProps.pFormData.selected_criteria_;
            }

            for (let lGInd = 0; lGInd < lProps.pFormGrid.length; lGInd++) {
                let lGridObject = lProps.pFormGrid[lGInd];
                if (lGridObject.hasOwnProperty('summary_field_') && lGridObject.summary_field_) {
                    if (!lSelectedData) {
                        lSelectedData = {}
                    }

                    lSelectedData[lGridObject.summary_field_] = lGridObject.summary_data_;
                }

                if (lSelectedData && lGridObject.criteria_field_) {
                    if (!lCriteria) {
                        lCriteria = {};
                    }

                    if (lGridObject.constant_) {
                        lCriteria[lGridObject.criteria_field_] = cfGetConstantValue(lGridObject.criteria_field_);
                    }
                    else {
                        lCriteria[lGridObject.criteria_field_] = lSelectedData[lGridObject.data_field_];
                    }
                }
            }

            let lCollectionData = null;
            if (lProps.hasOwnProperty('pFormGridProperties') && lProps.pFormGridProperties && (lProps.pFormGridProperties.length > 0)) {
                for (let lgInd = 0; lgInd < lProps.pFormGridProperties.length; lgInd++) {
                    let lgObj = lProps.pFormGridProperties[lgInd];
                    if ((lgObj.property_ === 'other') && (lgObj.type_ === 'grid_data')) {
                        if (!lgObj.name_ || !lgObj.value_) {
                            lCollectionData = lGridData;
                        }
                        else if (lGridData) {
                            for (let lgdInd = 0; lgdInd < lGridData.length; lgdInd++) {
                                let lDataObj = lGridData[lgdInd];
                                if (lgObj.value_ && lDataObj.hasOwnProperty(lgObj.name_) && lDataObj[lgObj.name_]) {
                                    if (lgObj.value_ === '#NOT_NULL#') {
                                        if (!lCollectionData) {
                                            lCollectionData = [];
                                        }

                                        lCollectionData.push(lDataObj);
                                    }
                                    else if (lDataObj[lgObj.name_] === lgObj.value_) {
                                        if (!lCollectionData) {
                                            lCollectionData = [];
                                        }

                                        lCollectionData.push(lDataObj);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let lDetailsMetaData = {
                pAppId               : lProps.pAppId,
                pFormType            : lActionItem.navigate_to_form_component_code_,
                pFormHeading         : lActionItem.title_,
                pFormActions         : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties      : lProps.pFormProperties,
                pFormItems           : fnGetFormItems(lActionItem.id_),
                pFormItemProperties  : lProps.pFormItemProperties,
                pFormItemComputation : lProps.pFormItemComputation,
                pFormSelection       : lProps.pFormSelection,
                pFormDetails         : lProps.pFormDetails,
                pFormValidation      : lProps.pFormValidation,
                pSelectedCriteria    : lCriteria,
                pCollectionData      : lCollectionData,
                pSelectedActionItem  : lActionItem,
                pResponseFn          : fnUpdateCollection,
                pFormMetaData        : lProps.pFormMetaData,
                pApplicationWidth    : mApplicationWidth
            };

            let lDetailsForm = fnGetForm(lDetailsMetaData, cloneDeep(lSelectedData), fnProcessClose);
            setDetailsFlag(true);
            setPreviewFlag(false);
            if (lActionItem.navigate_to_form_component_code_ === 'CS_DETAILS_FORM_TAB_LAYOUT_1') {
                setEnableDetailsTabs(true);
            }
            else {
                setEnableDetailsTabs(false);
            }

            setDetailsForm(lDetailsForm);
        }
    }

    const fnProcessDelete = (lActionItem) => {
        if (lActionItem) {
            mFormUtil.cfPromptDelete(lActionItem, (lResult) => {
                    if (lResult) {
                        if (lResult.value) {
                            let lCriteria = null;
                            for (let lGInd = 0; lGInd < lProps.pFormGrid.length; lGInd++) {
                                let lGridObject = lProps.pFormGrid[lGInd];
                                if (lGridObject.criteria_field_) {
                                    if (!lCriteria) {
                                        lCriteria = {};
                                    }

                                    lCriteria[lGridObject.criteria_field_] = mSelectedRecord.current[lGridObject.criteria_field_];
                                }
                            }

                            if (lCriteria) {
                                mFormUtil.cfProcessDelete(lActionItem, lCriteria, (lResponse) => {
                                        let lCode = null;
                                        let lMsg = null;
                                        if (lResponse && lResponse.code) {
                                            if (lResponse.code instanceof Array) {
                                                lCode = lResponse.code[0];
                                            }
                                            else {
                                                lCode = lResponse.code;
                                            }

                                            fnProcessClose(true);
                                        }
                                        else {
                                            lCode = -1;
                                        }

                                        mFormUtil.cfProcessDeleteResponse(lCode, lMsg, lActionItem, lResponse);
                                    }
                                )
                            }
                        }
                    }
                }
            );
        }
    }

    const fnProcessDetails = (lActionItem, lSelectedData) => {
        if (lActionItem && lProps.pFormActions) {
            let lActions = [];
            let lActionIdChildrenMap = {};
            for (let lInd = 0; lInd < lProps.pFormActions.length; lInd++) {
                let lAction = lProps.pFormActions[lInd];
                if (lAction.parent_ref_id_) {
                    let lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if (!lChildren) {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if (!lAction.parent_ref_id_) {
                    if (((lAction.component_type_ === 'DETAILS_ACTION')
                        || (lAction.component_type_ === 'DETAILS_ITEM_ACTION')
                        || (lAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                        || (lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION'))
                        && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_))) {
                        lActions.push(lAction);
                    }
                }
            }

            if (lActionIdChildrenMap && lActions) {
                let lChildList = null;
                for (let lPInd = 0; lPInd < lActions.length; lPInd++) {
                    if (lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_]) {
                        let lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                        if (!lChildList) {
                            lChildList = [];
                        }

                        lChildList = [...lChildList, ...lCList];
                    }
                }

                if (lChildList) {
                    lActions = [...lActions, ...lChildList];
                }
            }

            let lDetailsMetaData = {
                pAppId               : lProps.pAppId,
                pFormType            : lActionItem.navigate_to_form_component_code_,
                pFormHeading         : lActionItem.title_,
                pFormActions         : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties      : lProps.pFormProperties,
                pFormItems           : fnGetFormItems(lActionItem.id_),
                pFormItemProperties  : lProps.pFormItemProperties,
                pFormItemComputation : lProps.pFormItemComputation,
                pFormSelection       : lProps.pFormSelection,
                pFormDetails         : lProps.pFormDetails,
                pFormValidation      : lProps.pFormValidation,
//                pSelectedCriteria  : lCriteria,
//                pCollectionData    : lCollectionData,
                pSelectedActionItem  : lActionItem,
                pResponseFn          : fnUpdateCollection,
                pFormMetaData        : lProps.pFormMetaData,
                pApplicationWidth    : mApplicationWidth
            };

            setDetailsForm(fnGetForm(lDetailsMetaData, cloneDeep(lSelectedData), fnProcessClose));
        }
    }

    const fnProcessSave = (lActionItem) => {
        if (lActionItem) {
            mFormUtil.cfPromptDelete(lActionItem, (lResult) => {
                    if (lResult) {
                        if (lResult.value) {
                            let lCriteria = null;
                            for (let lGInd = 0; lGInd < lProps.pFormGrid.length; lGInd++) {
                                let lGridObject = lProps.pFormGrid[lGInd];
                                if (lGridObject.criteria_field_) {
                                    if (!lCriteria) {
                                        lCriteria = {};
                                    }

                                    lCriteria[lGridObject.criteria_field_] = mSelectedRecord.current[lGridObject.criteria_field_];
                                }
                            }

                            if (lCriteria) {
                                mFormUtil.cfProcessSave(lActionItem, mSelectedRecord.current, (lResponse) => {
                                        let lCode = null;
                                        let lMsg = null;
                                        if (lResponse && lResponse.code) {
                                            if (lResponse.code instanceof Array) {
                                                lCode = lResponse.code[0];
                                            }
                                            else {
                                                lCode = lResponse.code;
                                            }

                                            fnProcessClose(true);
                                        }
                                        else {
                                            lCode = -1;
                                        }

                                        mFormUtil.cfProcessDeleteResponse(lCode, lMsg, lActionItem, lResponse);
                                    }
                                )
                            }
                        }
                    }
                }
            );
        }
    }

    const fnProcessVideoConf = (lActionItem) => {
        if (mSelectedRecord.current && lActionItem) {
            let lActions = [];
            let lActionIdChildrenMap = {};
            for (let lInd = 0; lInd < lProps.pFormActions.length; lInd++) {
                let lAction = lProps.pFormActions[lInd];
                if (lAction.parent_ref_id_) {
                    let lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if (!lChildren) {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if ((lAction.component_type_ === 'DETAILS_ACTION')
                    && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === lActionItem.id_))) {
                    lActions.push(lAction);
                }
            }

            if (lActionIdChildrenMap && lActions) {
                let lChildList = null;
                for (let lPInd = 0; lPInd < lActions.length; lPInd++) {
                    if (lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_]) {
                        let lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                        if (!lChildList) {
                            lChildList = [];
                        }

                        lChildList = [...lChildList, ...lCList];
                    }
                }

                if (lChildList) {
                    lActions = [...lActions, ...lChildList];
                }
            }

            let lDetailsMetaData =
            {
                pAppId               : lProps.pAppId,
                pFormType            : lActionItem.navigate_to_form_component_code_,
                pFormHeading         : lActionItem.title_,
                pFormActions         : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                pFormProperties      : lProps.pFormProperties,
                pFormValidation      : lProps.pFormValidation,
                pFeatureRefId        : lProps.pFeatureRefId,
                pSelectedActionItem  : lActionItem,
                pResponseFn          : fnUpdateCollection,
                pFormMetaData        : lProps.pFormMetaData,
                pApplicationWidth    : mApplicationWidth,
                pMaximizable         : true
            };

            let lDetailsForm = fnGetForm(lDetailsMetaData, mSelectedRecord.current, fnProcessClose);
            setDetailsFlag(true);
            setPreviewFlag(false);
            setDetailsForm(lDetailsForm);
        }
    }

    const fnProcessClick = (lEvent, lActionItem) => {
        if (lActionItem) {
            if (lActionItem.action_ === 'process_import') {
                fnProcessImport(lEvent, lActionItem);
            }
            else if (lActionItem.action_ === 'process_add') {
                fnProcessAdd(lActionItem);
            }
            else if (lActionItem.action_ === 'process_modify') {
                fnProcessModify(lActionItem, null, mDataCollection);
            }
            else if (lActionItem.action_ === 'process_delete') {
                fnProcessDelete(lActionItem);
            }
            else if (lActionItem.action_ === 'process_details') {
                fnProcessDetails(lActionItem);
            }
            else if (lActionItem.action_ === 'process_save') {
                fnProcessSave(lActionItem);
            }
            else if (lActionItem.action_ === 'process_close') {
                fnProcessClose();
            }
            else if (lActionItem.action_ === 'process_preview') {
                fnProcessPreview(lEvent, lActionItem);
            }
            else if (lActionItem.action_ === 'process_video_conf') {
                fnProcessVideoConf(lActionItem);
            }
        }
    };

    /*
     * Form Heading
     */
    useEffect(() => {
        const fnInitializeFormHeading = () => {
            var lFormTitle = null;
            var lMenuItem = mCSUtil.cfGetMenuItem(lProps.pFeatureRefId);
            if (lMenuItem) {
                if (lMenuItem.title_) {
                    lFormTitle = lMenuItem.title_;
                }
                else {
                    lFormTitle = lMenuItem.layout_item_name_;
                }
            }

            var lFormHeading = fnGetFormHeader(lFormTitle);
            setFormHeading(lFormHeading);
            setDataCollection(null);
        }

        fnInitializeFormHeading();
    }, [lProps.pFeatureRefId, mCSUtil]
    );

    /*
     * Form Actions
     */
    useEffect(() => {
        /*
         * Search Actions
         */
        const fnInitializeActions = () => {
            let lSearchActionsList = [];
            let lCriteriaActionsList = [];
            let lRefIdActionMap = {}
            let lFormActions = CSUtil.cfSort(lProps.pFormActions, 'sequence_');
            setResetActions(false);
            if ((lFormActions !== undefined) && (lFormActions !== null)) {
                for (let ind = 0; ind < lFormActions.length; ind++) {
                    let lActionItem = lFormActions[ind];
                    lRefIdActionMap[lActionItem.id_] = lActionItem;
                    if (lActionItem.component_type_ === 'SEARCH_ACTION') {
                        if (lActionItem.action_type_ === '0') {
                            lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                        }
                        else {
                            fnEnableActions(lActionItem);
                        }

                        if (lActionItem.action_) {
                            lActionItem.fnProcessClick = fnProcessClick;
                        }

                        if (CSFormUtil.cfIsDoubleClickEnabled(lActionItem)) {
                            mDoubleClickActionItem.current = lActionItem;
                        }

                        lSearchActionsList.push(lActionItem);
                    }
                    else if (lActionItem.component_type_ === 'CRITERIA_ACTION') {
                        lActionItem.fnProcessClick = fnSearch;
                        lCriteriaActionsList.push(lActionItem);
                    }
                }

                setSearchActionList(lSearchActionsList);
                setRefIdActionMap(lRefIdActionMap);
            }

            if (lSearchActionsList.length > 0) {
                setSearchActions(fnGetSearchActions(lSearchActionsList));
            }
            else {
                setSearchActions(null);
            }

            /*
            * Criteria
            */
            let lCriteria = null;
            if (lProps.pFormCriteria && (lProps.pFormCriteria.length > 0)) {
                lCriteria =
                    <div id="ID_CRITERIA" ref={cfGetDimensions}>
                        <CSCOCriteria
                            id="ID_CRITERIA"
                            pCriteria={lProps.pFormCriteria}
                            pFormActions={lCriteriaActionsList}
                            pFormProperties={lProps.pFormProperties}
                        />
                    </div>
            }

            setFormCriteria(lCriteria);
        }

        fnInitializeActions();
    }, [lProps.pFormActions, lProps.pFormCriteria, lProps.pFormProperties, fnSearch, mResetActions, CSConfigUtil.mAYCode, CSConfigUtil.mBranchCode]
    );

    useEffect(() => {
            const fnInitializeForm = () => {
                /*
                 * Enable/Disable Filter
                 */
                if (lProps.pFeatureRefId !== mFeatureRefId) {
                    fnProcessClose();
                }

                setDetailsForm(null);
                setDetailsFlag(false);
                setEnableDetailsTabs(false);
                setEnableFilter(true);

                if (lProps.pFormGridProperties) {
                    for (let lGpInd = 0; lGpInd < lProps.pFormGridProperties.length; lGpInd++) {
                        if (lProps.pFormGridProperties[lGpInd].property_ === 'filter') {
                            let lEnableFilter = (lProps.pFormGridProperties[lGpInd].value_ === '1') ? false : true;
                            setEnableFilter(lEnableFilter);
                            break;
                        }
                    }
                }

                /*
                * Default Search
                */
                setDataCollection(null);
                let lLayoutType = 'grid';
                if (lProps.pFormProperties) {
                    let lCriteria = null;
                    let lServiceCode = null;
                    let lRefreshFlag = false;
                    let lRefreshInterval = 1000;
                    for (let lfpInd = 0; lfpInd < lProps.pFormProperties.length; lfpInd++) {
                        let lProperty = lProps.pFormProperties[lfpInd];
                        if (lProperty.type_ === 'initialize') {
                            if (lProperty.property_ === 'service_code_') {
                                if (lProps.hasOwnProperty('pFormData') && lProps.pFormData && lProps.pFormData.hasOwnProperty('selected_criteria_')) {
                                    lCriteria = lProps.pFormData.selected_criteria_;
                                }

                                if (lProps.pFormCriteria) {
                                    for (let lcInd = 0; lcInd < lProps.pFormCriteria.length; lcInd++) {
                                        let lCriteriaItem = lProps.pFormCriteria[lcInd];
                                        if (lCriteriaItem.default_criteria_field_ && lCriteriaItem.value_) {
                                            if (!lCriteria) {
                                                lCriteria = {}
                                            }

                                            lCriteria[lCriteriaItem.default_criteria_field_] = cfGetConstantValue(lCriteriaItem.value_);
                                        }
                                    }
                                }

                                mCriteriaObject.current = lCriteria;
                                lServiceCode = lProperty.value_;
                            }
                            else if (lProperty.property_ === 'refresh_') {
                                lRefreshFlag = true;
                                lRefreshInterval = parseInt(lProperty.value_);
                            }
                        }
                        else if (lProperty.type_ === 'layout') {
                            if (lProperty.property_ === 'layout_type_') {
                                lLayoutType = lProperty.value_;
                            }
                            else if (lProperty.property_ === 'list_label_'){
                                if(lProperty.value_){
                                    setListLabel(lProperty.value_);
                                }
                            }
                        }
                    }

                    if (lServiceCode) {
                        fnProcessSearch(lServiceCode, lCriteria);

                        if (lRefreshFlag) {
                            const interval = setInterval(() => {
                                fnProcessSearch(lServiceCode, lCriteria, true);
                            }, lRefreshInterval);

                            return () => clearInterval(interval);
                        }
                    }
                    
                    for (let lfaInd = 0; lfaInd < lProps.pFormActions.length; lfaInd++) {
                        let lAction = lProps.pFormActions[lfaInd];
                        if (lAction.component_type_ === 'SELECTION_ACTION'){
                            fnProcessClick(null, lAction);
                        }
                    }
                }

                setLayoutType(lLayoutType);
            }

            fnInitializeForm();
        }, [fnProcessSearch, lProps.pFormGridProperties, lProps.pFormProperties]
    );

    const fnOnItemChange = (lData, lRowData, lRowIndex, lValue, lColumn) => {
        CSFormUtil.cfGetGridComputeFieldsData(lRowData, lProps.pFormGrid, lProps.pFormGridItemProperties);
        if (lData) {
            mSelectedDataCollection.current = lData;
        }
    }

    const fnOnListItemChange = (lEvent, lListItem) => {
        let lSelectedItem = null;
        if(lEvent && lEvent.value) {
            setSelectedListItem(lEvent.value);
            lSelectedItem = lEvent.value;
        }
        else {
            lSelectedItem = lListItem;
        }

        if (lProps.pFormProperties) {
            let lCriteria = null;
            let lServiceCode = null;
            let lListFormCode = null;
            let lSelectedActionItem = null;

            for(let lInd=0; lInd<lProps.pFormProperties.length; lInd++) {
                let lProperty = lProps.pFormProperties[lInd];
                if(lProperty.component_code_ === 'CS_LIST_FORM_1') {
                    lListFormCode = lProperty.cs_form_code_;
                }
            }

            for(let lInd=0; lInd<lProps.pFormProperties.length; lInd++) {
                let lProperty = lProps.pFormProperties[lInd];
                if(lProperty.cs_form_code_ === lListFormCode) {
                    if(lProperty.type_ === 'criteria') {
                        if(lSelectedItem) {
                            if(!lCriteria) {
                                lCriteria = {}
                            }
    
                            lCriteria[lProperty.property_] = lSelectedItem[lProperty.value_];
                        }
                    }
                    else if(lProperty.type_ === 'initialize') {
                        if(lProperty.property_ === 'service_code_') {
                            lServiceCode = lProperty.value_;
                        }
                    }
                    else if(lProperty.type_ === 'layout') {
                        if((lProperty.property_ === 'list_action_ref_id_') && mRefIdActionMap && lProperty.value_) {
                            lSelectedActionItem = mRefIdActionMap[lProperty.value_];
                        }
                    }
                }
            }

            if(lServiceCode && lCriteria) {
                setBackdrop(true);
    
                let lServiceObject = mCSServiceUtil.cfGetServiceObject(lServiceCode);
                if (lServiceObject) {
                    let lCriteriaObj = (lCriteria) ? lCriteria : {};
                    let lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
                    if (lServiceObject.hasOwnProperty('cs_service_properties_')) {
                        let lServiceProperties = lServiceObject.cs_service_properties_;
                        if (lServiceProperties !== null) {
                            for (let lInd = 0; lInd < lServiceProperties.length; lInd++) {
                                lCriteriaObj[lServiceProperties[lInd].property_] = cfGetConstantValue(lServiceProperties[lInd].value_);
                            }
                        }
                    }    

                    lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
                    lRequestUtil.cfProcessRequest((lResponse) => {
                            if (lResponse && lResponse.hasOwnProperty("collection_")) {
                                let lCollection = lResponse.collection_;
                                if (lCollection) {
                                    setDataCollection(lCollection);
                                    setBackdrop(false);
                                }
                            }
                        }
                    );
                }
            }

            setSelectedActionItem(lSelectedActionItem);
        }

        setSelectedItem(lSelectedItem);
    }

    let lHeadingHeight = cfGetDimensions("ID_HEADING");
    let lCriteriaHeight = cfGetDimensions("ID_CRITERIA");
    let lScrollHeight = (CSUtil.cfGetInstance()).mApplicationHeight - 40;

    if (lHeadingHeight) {
        lScrollHeight = lScrollHeight - lHeadingHeight;
    }

    if (lCriteriaHeight) {
        lScrollHeight = lScrollHeight - lCriteriaHeight;
    }

    let lGrid = null;
    let lCard = null;
    if (mLayoutType === 'grid') {
        lGrid =
            <>
                <div id="ID_HEADING">
                    {mFormHeading}
                </div>

                {mFormCriteria}

                <CSCOGrid
                    pData               = { mDataCollection }
                    pFeatureRefId       = { lProps.pFeatureRefId }
                    pColumns            = { (lProps.pFormGrid) ? CSUtil.cfSort(lProps.pFormGrid, 'sequence_') : lProps.pFormGrid }
                    pFormGridProperties = { lProps.pFormGridProperties }
                    pageSize            = { CSConfigUtil.gPageSize }
                    onClick             = { fnOnDataSelection }
                    onDoubleClick       = { fnOnDoubleClick }
                    OnItemChange        = { fnOnItemChange }
                    pSearchActionBar    = { mSearchActions }
                    pEnableFilter       = { mEnableFilter }
                    pEnableScroll       = { true }
                    pScrollHeight       = { lScrollHeight }
                />
            </>
    }
    else if (mLayoutType === 'tree-form') {
        let lDetailsForm = null;
        let lDetailsMetaData = null;
        if(mSelectedActionItem) {
            let lActions = [];
            let lActionIdChildrenMap = {};
            for (let lInd = 0; lInd < lProps.pFormActions.length; lInd++) {
                let lAction = lProps.pFormActions[lInd];
                if (lAction.parent_ref_id_) {
                    let lChildren = lActionIdChildrenMap[lAction.parent_ref_id_];
                    if (!lChildren) {
                        lChildren = [];
                        lActionIdChildrenMap[lAction.parent_ref_id_] = lChildren;
                    }

                    lChildren.push(lAction);
                }

                if (((lAction.component_type_ === 'DETAILS_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_SEARCH_ACTION')
                    || (lAction.component_type_ === 'DETAILS_ITEM_DETAILS_ACTION'))
                    && (!lAction.parent_ref_id_ || (lAction.parent_ref_id_ === mSelectedActionItem.id_))) {
                    lActions.push(lAction);
                }

                if (lActionIdChildrenMap && lActions) {
                    let lChildList = null;
                    for (let lPInd = 0; lPInd < lActions.length; lPInd++) {
                        if (lActions[lPInd].id_ && lActionIdChildrenMap[lActions[lPInd].id_]) {
                            let lCList = lActionIdChildrenMap[lActions[lPInd].id_];
                            if (!lChildList) {
                                lChildList = [];
                            }

                            lChildList = [...lChildList, ...lCList];
                        }
                    }

                    if (lChildList) {
                        lActions = [...lActions, ...lChildList];
                    }
                }
            }

            lDetailsMetaData = {
                pAppId                : lProps.pAppId,
                pFormType             : mSelectedActionItem.navigate_to_form_component_code_,
                pFormHeading          : mSelectedActionItem.title_,
                pFormActions          : lActions,
                pFormProperties       : lProps.pFormProperties,
                pFormItems            : fnGetFormItems(mSelectedActionItem.id_),
                pFormItemProperties   : lProps.pFormItemProperties,
                pFormItemComputation  : lProps.pFormItemComputation,
                pFormSelection        : lProps.pFormSelection,
                pFormDetails          : lProps.pFormDetails,
                pFormValidation       : lProps.pFormValidation,
                pParentLayoutType     : mLayoutType,
                pParent               : this,
                pDataCollection       : mDataCollection,
                pSelectedActionItem   : mSelectedActionItem,
                pResponseFn           : fnAddToCollection,
                pFormMetaData         : lProps.pFormMetaData,
                pApplicationWidth     : mApplicationWidth,
                pSelectedCriteria     : mFormCriteria,
                pSelectedData         : mSelectedItem
            };

            lDetailsForm = fnGetForm(lDetailsMetaData, mSelectedItem, fnProcessClose);
        };

        lGrid =
            <>
                <div id="ID_HEADING">
                    {mFormHeading}
                </div>

                { mFormCriteria }

                <HGROUP width='100%' height="85%">
                    <HGROUP width='20%' height="100%" paddingTop="5px" paddingLeft="5px" paddingRight="2px" gap="0px" >
                        <VGROUP height="100%" justifyContent="start">
                            { fnGetSearchActions(mSearchActionList, 'center', 'var(--primary-light)', '1px solid  var(--primary-color)', '5px') }

                            <VGROUP height="100%" justifyContent="start">
                                <ListBox 
                                    filter 
                                    value       = { mSelectedItem }
                                    onChange    = { fnOnListItemChange }
                                    options     = { mListCollection } 
                                    optionLabel = { mListLabel } 
                                    listStyle   = {{ width: '100%' }} 
                                />
                            </VGROUP>
                        </VGROUP>
                    </HGROUP>

                    <VGROUP gap="0px" padding="-20px" justifyContent="start">
                        { lDetailsForm }
                    </VGROUP>
                </HGROUP>
            </>
    }
    else if (mLayoutType === 'tree-grid') {
        lGrid =
            <>
                <div id="ID_HEADING">
                    {mFormHeading}
                </div>

                { mFormCriteria }

                <HGROUP width='100%' height="80%">
                    <HGROUP width='20%' height="100%" paddingTop="5px" paddingLeft="5px" paddingRight="2px" gap="0px" >
                        <VGROUP height="100%" justifyContent="start">
                            { fnGetSearchActions(mSearchActionList, 'center', 'var(--primary-light)', '1px solid  var(--primary-color)', '5px') }

                            <VGROUP height="100%" justifyContent="start">
                                <ListBox 
                                    filter 
                                    value       = { mSelectedItem }
                                    onChange    = { fnOnListItemChange }
                                    options     = { mListCollection } 
                                    optionLabel = { mListLabel } 
                                    listStyle   = {{ width: '100%' }} 
                                />
                            </VGROUP>
                        </VGROUP>
                    </HGROUP>

                    <HGROUP gap="0px" padding="0px">
                        <CSCOGrid
                            pData               = { mDataCollection }
                            pFeatureRefId       = { lProps.pFeatureRefId }
                            pColumns            = { (lProps.pFormGrid) ? CSUtil.cfSort(lProps.pFormGrid, 'sequence_') : lProps.pFormGrid }
                            pFormGridProperties = { lProps.pFormGridProperties }
                            onClick             = { fnOnDataSelection }
                            onDoubleClick       = { fnOnDoubleClick }
                            OnItemChange        = { fnOnItemChange }
                            pEnableFilter       = { mEnableFilter }
                            pEnableScroll       = { true }
                            pScrollHeight       = { lScrollHeight }
                        />
                    </HGROUP>
                </HGROUP>
            </>
    }
    else if (mLayoutType === 'card') {
        lCard =
            <>
                <div id="ID_HEADING">
                    {mFormHeading}
                </div>

                {mFormCriteria}

                <CSCOHorizontalActionBar
                    align="left"
                    pType='SEARCH_ACTION_BAR'
                    parent={this}
                    actionList={mSearchActionList}
                />

                <CSCOCard
                    pData          = { mDataCollection }
                    pFeatureRefId  = { lProps.pFeatureRefId }
                    pColumns       = { (lProps.pFormGrid) ? CSUtil.cfSort(lProps.pFormGrid, 'sequence_') : lProps.pFormGrid }
                    pageSize       = { CSConfigUtil.gPageSize }
                    onClick        = { fnOnDataSelection }
                    pEnableFilter  = { mEnableFilter }
                    pScrollHeight  = { lScrollHeight }
                    pEnableScroll  = { true }
                />
            </>
    }

    const lForm = (!mEnableDetailsTabs) ?
        <>
            <CSCOBackdrop pShow={mShowBackdrop} />

            {lGrid}

            {lCard}

            {(mDetailsFlag) ? mDetailsForm : (mPreviewFlag) ? mPreviewForm : null}
        </>
        :
        <>
            {mDetailsForm}
        </>

    return lForm;
}

export default CSCOFormLayout;
