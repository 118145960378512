import React from "react"
import {Calendar} from 'primereact/calendar';
import CSServiceUtil from '../util/CSServiceUtil'
import {fnDateAdd, fnGetDateToString, fnGetStringToDate} from "../util/CSDateTimeUtil";
import CSBaseControl from './CSBaseControl'
import { cfGetConstantValue } from "../util/CSConstantUtil";

class CSDateField extends CSBaseControl
{
    constructor(props)
    {
        super(props);

        this.mServiceUtil = CSServiceUtil.cfGetInstance();
        this.cfGetControl = this.cfGetControl.bind(this);
        this.cfHandleChange = this.cfHandleChange.bind(this);
        this.cfHandleResponse = this.cfHandleResponse.bind(this);

        if(this.props.pValue && (this.props.pValue !== '0000-00-00') && (this.props.pValue !== ''))
        {
            var lDate = new Date(this.props.pValue);
            this.state.mValue = lDate; 
        }
        else if (this.props.pValue === '0000-00-00')
        {
            this.state.mValue = null;
        }
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.pValue && (this.props.pValue !== '0000-00-00'))
            {
                const lDate = new Date(this.props.pValue);
                this.setState({mValue : lDate});
            }
        }
    }

    componentWillUnmount()
    {
        this.props = null;
    }
    
    cfHandleResponse(lDataProviderId, lData)
    {
        if(lDataProviderId === this.props.data_provider_source_)
        {
            this.setState({mDataProvider : lData});
        }
    }

    cfHandleChange(event)
    {
        event.preventDefault();
        if(event.value instanceof Date)
        {
            var lDate = event.value;
            var lNewDate = null;
            var lToday = new Date();
            var lProperties = this.props.cs_form_item_properties_;
            if(lProperties && (lProperties.length > 0))
            {
                for(var lpInd = 0; lpInd < lProperties.length; lpInd++)
                {
                    var lProperty = lProperties[lpInd];
                    if(lProperty.type_ === 'validate')
                    {
                        if(lProperty.property_ === 'min_date')
                        {
                        }
                        else if(lProperty.property_ === 'max_date')
                        {
                        }
                        else if(lProperty.property_ === 'min_days')
                        {
                        }
                        else if(lProperty.property_ === 'max_days')
                        {
                        }
                        else if(lProperty.property_ === 'min_years')
                        {
                            if(lDate && lToday)
                            {
                            }
                        }
                        else if(lProperty.property_ === 'max_years')
                        {
                        }
                    }
                }
            }

            var lDateString  = null;
            if(lNewDate)
            {
                lDateString = fnGetDateToString(lNewDate);
            }
            else
            {
                lDateString = fnGetDateToString(lDate)
            }

            if(this.state.mHandleChange)
            {
                var lData           = {};
                lData.control_type_ = 'date_field';
                lData.data_field_   = this.state.mDataField;
                lData.id_           = this.state.mId;
                lData.value_        = lDateString;

                this.state.mHandleChange(lData);
            }
        }
    }

    cfGetControl()
    {
        var lMaxDate = this.state.mMaxDate; 
        var lMinDate = this.state.mMinDate; 
        var lProperties = this.props.cs_form_item_properties_;
        if(!lProperties)
        {
            lProperties = this.props.cs_form_criteria_properties_;
        }

        if(lProperties && (lProperties.length > 0))
        {
            for(var lpInd = 0; lpInd < lProperties.length; lpInd++)
            {
                var lProperty = lProperties[lpInd];
                if(lProperty.type_ === 'validate')
                {            
                    var lValue1 = Number(lProperty.value_);
                    if(lProperty.property_ === 'min_date')
                    {
                        lMinDate = new Date(lValue1);
                    }
                    else if(lProperty.property_ === 'max_date')
                    {
                        lMaxDate = new Date(lValue1);
                    }
                    else if(lProperty.property_ === 'min_days')
                    {
                        lMaxDate = fnDateAdd(new Date(), 'day', lValue1);
                    }
                    else if(lProperty.property_ === 'max_days')
                    {
                        lMinDate = fnDateAdd(new Date(), 'day', lValue1);
                    }
                    else if(lProperty.property_ === 'min_years')
                    {
                        lMaxDate = fnDateAdd(new Date(), 'day', -1 * lValue1 * 365);
                    }
                    else if(lProperty.property_ === 'max_years')
                    {
                        lMinDate = fnDateAdd(new Date(), 'day', lValue1 * 365);
                    }
                }
            }
        }

        var lValue = this.state.mValue;
        if(this.props.constant_)
        {
            var lConstant = cfGetConstantValue(this.props.constant_);
            if(lConstant)
            {
                const lCnstDate = fnGetStringToDate(lConstant);
                if(lCnstDate instanceof Date)
                {
                    lValue = lCnstDate;
                }
            }
        }
        else
        {
            if(this.props.pValue)
            {
                const lDate = new Date(this.props.pValue);
                lValue = lDate;
            }
        }
        
        var ctl = null;
        if((this.state.mControlType !== null) && (this.state.mControlType === 'date_field'))
        {
            ctl = (
                <div className="cs-control field grid">
                    { super.cfGetControlLabel() }
                    
                    <Calendar
                        className       = "cs-date-field"
                        inputClassName  = "cs-df-input"
                        dateFormat      = "dd-mm-yy"
                        appendTo        = { document.body }
                        yearNavigator   = { true }
                        yearRange       = '1900:2050'
                        maxDate         = { lMaxDate }
                        minDate         = { lMinDate }
                        value           = { lValue }
                        id              = { this.state.mId } 
                        onChange        = { this.cfHandleChange }
                        showIcon        = { true }
                        />
                </div>
            )
        }

        return ctl;
    }

    render()
    {        
        return this.cfGetControl();
    }
}

export default CSDateField;
