import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from "primereact/radiobutton";

const CSTheme = (lProps) => {
  const [mSelectedTheme, setSelectedTheme] = useState('blue');

  const setTheme = (lTheme) => {
    setSelectedTheme(lTheme.currentTarget.id);
    document.documentElement.className = lTheme.currentTarget.id;
  }

  return (
    <Dialog
      minheight='150px'
      visible={lProps.pShow}
      header="Theme Change"
      // footer = {lFooter} 
      onHide={() => lProps.fnProcessClose()}>
      <div className="cs-details-section-1" align="center">
        <form className="color-picker" action="">
          <fieldset>
            <legend className="visually-hidden">Pick a color scheme</legend>

            <label htmlFor="blue" className="visually-hidden">Blue theme</label>
            <input type="radio" id="blue" name="theme" defaultChecked={mSelectedTheme === 'blue'} onClick={setTheme} />

            <label htmlFor="light" className="visually-hidden">Light</label>
            <input type="radio" id="light" name="theme" defaultChecked={mSelectedTheme === 'light'} onClick={setTheme} />

            <label htmlFor="pink" className="visually-hidden">Pink theme</label>
            <input type="radio" id="teal" name="theme" defaultChecked={mSelectedTheme === 'pink'} onClick={setTheme} />

            <label htmlFor="green" className="visually-hidden">Green theme</label>
            <input type="radio" id="green" name="theme" defaultChecked={mSelectedTheme === 'green'} onClick={setTheme} />

            <label htmlFor="dark" className="visually-hidden">Dark theme</label>
            <input type="radio" id="dark" name="theme" defaultChecked={mSelectedTheme === 'dark'} onClick={setTheme} />
          </fieldset>
        </form>
      </div>

    </Dialog>
  );
};

export default CSTheme;
