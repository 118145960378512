import React from "react"
import {InputNumber } from 'primereact/inputnumber';
import CSServiceUtil from '../util/CSServiceUtil'
import CSBaseControl from './CSBaseControl'
import CSUtil from "../util/CSUtil";

class CSInputNumber extends CSBaseControl
{
    constructor(props)
    {
        super(props);
        this.mProperties = 
        {
            mMode                   : 'decimal',
            mShowButtons            : false,
            mButtonLayout           : 'horizontal',
            mMinValue               : 0,
            mMaxValue               : null,
            mMinDecimals            : 0,
            mIncrementButtonClass   : 'p-button-success',
            mIncrementButtonIcon    : CSUtil.mNameIconMap['PI_PLUS'],
            mDecrementButtonClass   : 'p-button-danger',
            mDecrementButtonIcon    : CSUtil.mNameIconMap['PI_MINUS'],
            mWidth                  : '100px',
            mStep                   : 1
        }

        if(this.props.pFormItem && this.props.pFormItem.hasOwnProperty('cs_form_item_properties_'))
        {
            var lFormItemProperties = this.props.pFormItem.cs_form_item_properties_;
            for(var lInd=0; lInd<lFormItemProperties.length; lInd++)
            {
                var lFip = lFormItemProperties[lInd];
                if(lFip.type_ === 'property')
                {
                    if(lFip.property_ === 'mode')
                    {
                        this.mProperties.mMode = lFip.value_;
                    }
                    else if(lFip.property_ === 'show_buttons')
                    {
                        this.mProperties.mShowButtons = (lFip.value_ === 'true');
                    }
                    else if(lFip.property_ === 'button_layout')
                    {
                        this.mProperties.mButtonLayout = lFip.value_;
                    }
                    else if(lFip.property_ === 'min_value')
                    {
                        this.mProperties.mMinValue = Number(lFip.value_);
                    }
                    else if(lFip.property_ === 'max_value')
                    {
                        this.mProperties.mMaxValue = Number(lFip.value_);
                    }
                    else if(lFip.property_ === 'min_decimals')
                    {
                        this.mProperties.mMinDecimals = Number(lFip.value_);
                    }
                    else if(lFip.property_ === 'step')
                    {
                        this.mProperties.mStep = Number(lFip.value_);
                    }
                    else if(lFip.property_ === 'increment_button_class')
                    {
                        this.mProperties.mIncrementButtonClass = lFip.value_;
                    }
                    else if(lFip.property_ === 'decrement_button_class')
                    {
                        this.mProperties.mDecrementButtonClass = lFip.value_;
                    }
                    else if(lFip.property_ === 'increment_button_icon')
                    {
                        if(lFip.value_ !== 'PI_PLUS')
                        {
                            this.mProperties.mIncrementButtonIcon = CSUtil.mNameIconMap(lFip.value_);
                        }
                    }
                    else if(lFip.property_ === 'decrement_button_icon')
                    {
                        if(lFip.value_ !== 'PI_MINUS')
                        {
                            this.mProperties.mDecrementButtonIcon = CSUtil.mNameIconMap(lFip.value_);
                        }
                    }
                }
            }
        }
        
        this.mServiceUtil = CSServiceUtil.cfGetInstance();
        this.cfGetControl = this.cfGetControl.bind(this);
        this.cfHandleChange = this.cfHandleChange.bind(this);
    }
 
    cfHandleChange(event)
    {
        if(this.state.mRequired)
        {
            if(!event.value)
            {
                this.setState({mControlClassName : 'p-error'})
            }
            else
            {
                this.setState({mControlClassName : ''})
            }
        }

        if(this.state.mHandleChange)
        {
            var lData           = {};
            lData.control_type_ = 'input_number';
            lData.data_field_   = this.state.mDataField;
            lData.value_        = event.value;
            lData.id_           = this.state.mId;

            this.state.mHandleChange(lData);
        }
    }

    cfGetControl()
    {
        var ctl = null;
        if ((this.state.mControlType) && ((this.state.mControlType === 'input_number')))
        {
            ctl = (
                <div className="form-item-row"> 
                    {super.cfGetControlLabel()}
                    
                    <InputNumber
                        style = 
                        {
                            {
                                maxWidth    : `calc(100% > ${this.props.pMaxWidth})` ? this.props.pMaxWidth : `calc(100%)`, 
                                width       : '100%',
//                                width       : `calc(100% > ${this.state.mMaxWidth})` ? this.state.mMaxWidth : `calc(100%)`, 
                                height      : '34px', 
                            }
                        }

                        showButtons                 = {this.mProperties.mShowButtons}
                        buttonLayout                = {this.mProperties.mButtonLayout} 
                        min                         = {this.mProperties.mMinValue} 
                        max                         = {this.mProperties.mMaxValue}
                        step                        = {this.mProperties.mStep}
                        mode                        = {this.mProperties.mMode}
                        minFractionDigits           = {this.mProperties.mMinDecimals}
                        incrementButtonClassName    = {this.mProperties.mIncrementButtonClass}
                        incrementButtonIcon         = {this.mProperties.mIncrementButtonIcon}
                        decrementButtonClassName    = {this.mProperties.mDecrementButtonClass} 
                        decrementButtonIcon         = {this.mProperties.mDecrementButtonIcon}

                        id                          = {this.state.mId} 
                        readOnly                    = {!(this.state.mEditable)}
                        name                        = {this.state.mDataField}
                        value                       = {Number(this.state.mValue)}
                        onValueChange               = {this.cfHandleChange}
                        onChange                    = {this.cfHandleChange}
                        required                    = {(this.state.mRequired === '1')}
                        />
                </div>
            )
        }

        return ctl;
    }

    render()
    {
        return this.cfGetControl();
    }
}

export default CSInputNumber;
