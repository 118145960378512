import React, { Component } from "react"

import CSCODetailsHeader1 from "../../components/CSCODetailsHeader1";
import CSCOFormLayout21 from "../form/CSCOFormLayout21";

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import CSRequestUtil from "../../util/CSRequestUtil"
import CSServiceUtil from "../../util/CSServiceUtil";
import { cfGetConstantValue} from "../../util/CSConstantUtil";

class CSCODetailsForm13 extends Component 
{
	mNumSections 		= 3;
    mMinSectionItems 	= 7;
    
    constructor(props)
    {
        super(props);

        this.mFormUtil = CSFormUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state = 
        {
            mDisplay                : this.props.pShow,
            mAppId                  : this.props.pAppId,
            mFormHeading            : (this.props.pFormHeading !== null) ? this.props.pFormHeading : "",
            mFormCriteria           : this.props.pFormCriteria,
            mFormGrid               : (this.props.pFormGrid) ? CSUtil.cfSort(this.props.pFormGrid, 'sequence_') : [],
            mFormItems              : this.props.pFormItems,
            mFormActions            : this.props.pFormActions,
            mFormProperties         : this.props.pFormProperties,
            mFormValidation         : this.props.pFormValidation,
            mFormSelection          : this.props.pFormSelection,
            mSelectedActionItem     : this.props.pSelectedActionItem,
            mSelectedTabActionItem  : this.props.pSelectedTabActionItem,
            mFormDetails            : this.props.pFormDetails,
            mFormActionProperties   : this.props.pFormActionProperties,
            mFormCriteriaProperties : this.props.pFormCriteriaProperties,
            mFormGridProperties     : this.props.pFormGridProperties,
            mFormGridItemProperties : this.props.pFormGridItemProperties,
            mFormItemProperties     : this.props.pFormItemProperties,
            mFormItemComputation    : this.props.pFormItemComputation,
            mFormData               : null,
            mSelectedData           : {},
            mDataCollection         : null,
            mFormOrigData           : CSUtil.cfGetObjectCopy(this.props.pFormData),
            mControlType            : this.props.pControlType,
            mEditable               : this.props.pEditable,
            mWidth                  : this.props.pWidth,
            mHeight                 : this.props.pHeight,
            mValue                  : this.props.pValue,
            mCriteriaFields         : null,
            mIdFieldObjectMap       : {},
            mDataFieldObjectMap     : {},

            fnProcessChange : this.props.fnProcessChange,
            fnProcessSubmit : this.props.fnProcessSubmit,
            fnProcessClose  : this.props.fnProcessClose
        }

        this.cfProcessChange            = this.cfProcessChange.bind(this);
        this.cfSetControlValue          = this.cfSetControlValue.bind(this);
        this.cfInitializeLayout         = this.cfInitializeLayout.bind(this);
        this.cfSetDependentFieldData    = this.cfSetDependentFieldData.bind(this);

        this.cfCloseForm                = this.cfCloseForm.bind(this);        
        this.process_save               = this.process_save.bind(this);
        this.process_cancel             = this.process_cancel.bind(this);
        this.process_close              = this.process_close.bind(this);
        this.process_reset              = this.process_reset.bind(this);

        if(!this.props.pFormData)
        {
            this.state.mFormData = {};
        }
        else
        {
            this.state.mFormData = CSUtil.cfGetObjectCopy(this.props.pFormData);
        }

        if(this.props.pSelectedData && (this.props.pSelectedData !== this.props.pFormData))
        {
            this.state.mSelectedData['selected_data_'] = this.props.pSelectedData;
        }

        if(this.props.pSelectedCriteria)
        {
            this.state.mSelectedData['selected_criteria_'] = this.props.pSelectedCriteria;
        }

        if(this.props.pFormProperties)
        {
            for(var lInd_0=0; lInd_0<this.props.pFormProperties.length; lInd_0++)
            {
                var lFormProperty = this.props.pFormProperties[lInd_0];
                if((lFormProperty.type_ === 'layout') && (lFormProperty.property_ === 'min_section_items_'))
                {
                    this.mMinSectionItems = lFormProperty.value_;
                }

                if((lFormProperty.type_ === 'initialize') && (lFormProperty.property_ === 'criteria_field_'))
                {
                    if(!this.state.mCriteriaFields)
                    {
                        this.state.mCriteriaFields = [];
                    }

                    this.state.mCriteriaFields.push(lFormProperty.value_);
                }
            }
        }

        if(this.props.pFormItems)
        {
            CSUtil.cfSort(this.props.pFormItems, 'parent_id_', 'sequence_')
            for(var lInd=0; lInd<this.props.pFormItems.length; lInd++)
            {
                var lFormItem = this.props.pFormItems[lInd];
                this.state.mIdFieldObjectMap[lFormItem.id_] = lFormItem;
                this.state.mDataFieldObjectMap[lFormItem.data_field_] = lFormItem;
            }
        }
    }

    componentDidMount()
    {
        this.cfInitializeLayout();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(lPrevProps.pAppId !== this.props.pAppId)
            {
                this.cfInitializeLayout();
            }
        }
    }

    cfInitializeLayout()
    {
        var lFormGridObjectMap = {};
        if(this.props.pFormGrid && (this.props.pFormGrid.length > 0))
        {
            for(var lInd0=0; lInd0<this.props.pFormGrid.length; lInd0++)
            {
                var lGridObject = this.props.pFormGrid[lInd0];
                lFormGridObjectMap[lGridObject.id_] = lGridObject;
            }
        }

        if(this.props.pFormGridItemProperties && (this.props.pFormGridItemProperties.length > 0))
        {
            for(var lInd=0; lInd<this.props.pFormGridItemProperties.length; lInd++)
            {
                var lGridItemPropObject = this.props.pFormGridItemProperties[lInd];
                var lGridObject0 = lFormGridObjectMap[lGridItemPropObject.cs_form_grid_ref_id_];
                if(lGridObject0)
                {
                    if(!lGridObject0.hasOwnProperty('cs_form_grid_item_properties_'))
                    {
                        lGridObject0.cs_form_grid_item_properties_ = [];
                    }

                    lGridObject0.cs_form_grid_item_properties_.push(lGridItemPropObject);
                }
            }

            this.setState({mFormGrid : this.props.pFormGrid, mFormGridItemProperties : this.props.pFormGridItemProperties});
        }

        if(this.props.pSelectedTabActionItem)
        {
            var lCriteria;
            var lServiceCode;
            var lRequestObject;

            if(this.state.mSelectedData)
            {
                var lSelectedCriteria;
                if(this.state.mSelectedData.hasOwnProperty('selected_criteria_'))
                {
                    lSelectedCriteria = this.state.mSelectedData.selected_criteria_;
                }

                if(lSelectedCriteria && this.state.mCriteriaFields && (this.state.mCriteriaFields.length > 0))
                {
                    for(var lcInd=0; lcInd<this.state.mCriteriaFields.length; lcInd++)
                    {
                        var lCriteriaField = this.state.mCriteriaFields[lcInd]
                        if(lSelectedCriteria.hasOwnProperty(lCriteriaField))
                        {
                            if(!lCriteria)
                            {
                                lCriteria = {}
                            }

                            lCriteria[lCriteriaField] = lSelectedCriteria[lCriteriaField];
                        }
                    }
                }
            }
    
            if(lCriteria)
            {
                lRequestObject = this.mCSServiceUtil.cfGetRequestObject(this.props.pSelectedTabActionItem.on_click_request_ref_id_);
                lServiceCode = this.props.pSelectedTabActionItem.on_click_service_code_;
                if(lServiceCode || lRequestObject)
                {
                    var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
                    if(lServiceObject)
                    {
                        var lRequestId = null;
                        if(lRequestObject && lRequestObject.hasOwnProperty('request_id_'))
                        {
                            lRequestId = lRequestObject.request_id_;
                        }
        
                        var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, lRequestId, lServiceObject);
                        if(lServiceObject.hasOwnProperty('cs_service_properties_'))
                        {
                            var lServiceProperties = lServiceObject.cs_service_properties_;
                            if(lServiceProperties !== null)
                            {
                                for(var lTInd=0; lTInd<lServiceProperties.length; lTInd++)
                                {
                                    if(!lCriteria)
                                    {
                                        lCriteria = {}
                                    }

                                    lCriteria[lServiceProperties[lTInd].property_] = cfGetConstantValue(lServiceProperties[lTInd].value_);
                                }
                            }
                        }

                        lRequestUtil.cfSetRequestCriteria(lCriteria);
                        lRequestUtil.cfProcessRequest(
                            (lResponse)=>
                            {
                                if(lResponse && lResponse.hasOwnProperty('collection_'))
                                {
                                    this.setState({mDataCollection : lResponse.collection_});
                                }
                            }
                        );
                    }
                }
            }
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            if(this.state.mDataCollection)
            {
                var lFormData = this.state.mDataCollection;
                lFormData[lData.data_field_] = lData.value_;
                this.setState ({mDataCollection : lFormData});
            }
        }
    }
    
    cfSetDependentFieldData(lFormItem)
    {
        if(lFormItem && lFormItem.dependent_id_)
        {
            var lDependentItem = this.state.mIdFieldObjectMap[lFormItem.dependent_id_];
            if(lDependentItem)
            {
                var lDependentData = lDependentItem.mValue;
                if(lFormItem.dependent_field_)
                {
                    if(lDependentData)
                    {
                       lFormItem.mValue = lDependentData[lFormItem.dependent_field_];
                    }
                }
                else
                {
                    lFormItem.mValue = lDependentData;
                }
            }
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var lFormItem = this.state.mFormItems[ind];
                var lFormData = this.state.mFormData;
                if(lFormItem.control_type_ === 'constant')
                {
                    lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                    if(lFormData)
                    {
                        lFormData[lFormItem.data_field_] = lFormItem.mValue;
                    }
                }
                else if(lFormItem.dependent_id_)
                {
                    this.cfSetDependentFieldData(lFormItem);
                }
                else
                {
                    if(lFormItem.control_type_ === 'check_box')
                    {
                        if(lFormItem.constant_ && lFormData && ((lFormItem.constant_ === lFormData[lFormItem.data_field_])))
                        {
                            lFormItem.mValue = '1';
                        }
                        else
                        {
                            lFormItem.mValue = '0';
                        }
                    }
                    else if(lFormData)
                    {
                        if(lFormItem.control_type_ === 'text_input')
                        {
                            if(lFormData[lFormItem.data_field_])
                            {
                                lFormItem.mValue = lFormData[lFormItem.data_field_];        
                            }
                            else if(lFormItem.constant_)
                            {
                                lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                            }
                        }
                        else
                        {
                            lFormItem.mValue = lFormData[lFormItem.data_field_];
                        }
                    }
                    else
                    {
                        if(lFormItem.control_type_ === 'text_input')
                        {
                            lFormItem.mValue = cfGetConstantValue(lFormItem.constant_);
                        }
                        else
                        {
                            lFormItem.mValue = '';
                        }
                    }
                }
            }
        }
    }

    cfCloseForm()
    {
        this.setState({mDisplay : false});
    }

    process_save(event, lParent, lActionItem)
    {
        if(lActionItem && (lActionItem.on_click_service_code_ || lActionItem.on_click_request_ref_id_))
        {
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if((lFormItem.visible_ === '1') && this.state.mSelectedActionItem && (lFormItem.action_ref_id_ === this.state.mSelectedActionItem.id_))
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        var lForm =
            <>
                <CSCODetailsHeader1 pFormItems          = {lFormItemList} 
                                    pFormProperties     = {this.state.mFormProperties} 
                                    pFormData           = {this.state.mFormData}
                                    pSelectedData       = {this.props.pSelectedData}
                                    /> 
                <CSCOFormLayout21   pFormItems          = {this.state.mFormItems} 
                                    pFormProperties     = {this.state.mFormProperties} 
                                    pDataCollection     = {this.state.mDataCollection} 
                                    pFormData           = {this.state.mFormData}
                                    pFormGrid           = {this.state.mFormGrid}
                                    pFormGridProperties = {this.state.mFormGridProperties}
                                    pFormCriteria       = {this.state.mFormCriteria}
                                    pSelectedData       = {this.props.pSelectedData}
                                    pFormActions        = {this.state.mFormActions}/>
            </>
        return lForm;
    }
}

export default CSCODetailsForm13;
