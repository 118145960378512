import React from "react"
import CSConfigUtil from "../util/CSConfigUtil";
import CSBaseControl from "./CSBaseControl";
class CSHyperlink extends CSBaseControl
{
    render() 
    {
        var lLink = null;
        if(this.state.mValue)
        {
            var lFormItem = this.props.pFormItem;
            if(lFormItem.constant_)
            {
                var lDataFolder = CSConfigUtil.mDataFolderMap[lFormItem.constant_];
                lLink = lDataFolder + "/" + this.state.mValue;
            }
        }

        var lctl =
            <a href={ lLink } target="_blank">
                { this.state.mDisplayField }
            </a>

        return lctl;
    }
}

export default CSHyperlink;
