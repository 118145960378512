import React from "react"
import {Button} from 'primereact/button';

import CSCOGrid from "../../components/CSCOGrid"
import CSCOBaseFormLayout from "./CSCOBaseFormLayout";
import CSCOCriteria from "../../components/CSCOCriteria"
import CSCOBackdrop from "../../components/CSCOBackdrop";
import CSCOBaseActionBar from "../../components/CSCOBaseActionBar";
import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar";

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil";
import { cfGetConstantValue } from "../../util/CSConstantUtil";
import { fnGetForm, fnGetFormHeader } from "../../util/CSLayoutUtil"
import { fnArraySplit } from "../../util/CSMathUtil";

class CSCOFormLayout11 extends CSCOBaseFormLayout
{
    mFormUtil = CSFormUtil.cfGetInstance();

    constructor(props)
    {
       super(props);
        
       this.process_save         = this.process_save.bind(this);        
       this.process_details_1    = this.process_details_1.bind(this);
       this.cfGetRequestObject   = this.cfGetRequestObject.bind(this);
       this.cfGetRequestCriteria = this.cfGetRequestCriteria.bind(this);
       this.cfOnItemChange       = this.cfOnItemChange.bind(this);
  }

    componentDidMount()
    {
        super.cfInitializeLayout();
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            if(this.props.pFeatureRefId !== this.state.mFeatureRefId)
            {
                this.process_close();
            }

            if(lPrevProps.pAppId !== this.props.pAppId)
            {
                this.cfInitializeLayout();
            }
        }
    }

    process_details_1(event, lParent, lActionItem)
    {
        if(event && event.currentTarget)
        {
            if(event && event.currentTarget && lParent && lActionItem)
            {
                event.preventDefault();
                var lActions = [];
                for(var lInd=0; lInd<this.state.mFormActions.length; lInd++)
                {
                    var lAction = this.state.mFormActions[lInd];
                    if((lAction.component_type_ === 'DETAILS_ACTION') || (lAction.component_type_ === 'DETAILS_ITEM_ACTION'))
                    {
                        if(lAction.parent_ref_id_ === lActionItem.id_)
                        {
                            lActions.push(lAction);
                        }
                    }
                }
    
                var lDetailsMetaData = 
                {
                    pAppId                  : this.state.mAppId,
                    pFormType               : lActionItem.navigate_to_form_component_code_,
                    pFormHeading            : this.cfGetFormHeading(event.currentTarget.id),
                    pFormActions            : (lActions) ? CSUtil.cfSort(lActions, 'sequence_') : null,
                    pFormProperties         : this.state.mFormProperties,
                    pFormItems              : this.cfGetFormItems(event.currentTarget.id),
                    pFormItemProperties     : this.state.mFormItemProperties,
                    pFormItemComputation    : this.state.mFormItemComputation,
                    pFormSelection          : this.state.mFormSelection,
                    pFormValidation         : this.state.mFormValidation,
                    pSelectedActionItem     : lActionItem,
                    pFormMetaData           : this.props.pFormMetaData
                };
    
                if(this.state.mSelectedRecord)
                {    
                    var lDataObj = {};
                    lDataObj.selected_data_ = this.state.mSelectedRecord;
                    this.setState(
                        {
                            mAddFlag            : false,
                            mDetailsMetaData    : lDetailsMetaData,
                            mDetailsFlag        : true,
                            mSelectedActionItem : lActionItem,
                            mSelectedData       : lDataObj,
                            mFormFlag           : 2
                        }
                    );
                }
            }
        }
    }

    cfGetRequestCriteria()
    {
        var lCriteria = this.state.mCriteria;
        var lFormGridProperties = this.state.mFormGridProperties;
        if(lFormGridProperties)
        {
            for(var lfipInd=0; lfipInd<lFormGridProperties.length; lfipInd++)
            {
                var lfipObj = lFormGridProperties[lfipInd];
                if(lfipObj.property_ === 'save_criteria')
                {
                    if(lfipObj.type_ && (lfipObj.type_ !== '#CONSTANT#'))
                    {
                        if(this.state.mFormData.hasOwnProperty(lfipObj.type_))
                        {
                            var lDataObj = this.state.mFormData[lfipObj.type_];

                            if(!lCriteria)
                            {
                                lCriteria = {};
                            }
        
                            lCriteria[lfipObj.name_] = lDataObj[lfipObj.value_];
                        }                        
                    }
                    else
                    {
                        if(!lCriteria)
                        {
                            lCriteria = {}; 
                        }
    
                        lCriteria[lfipObj.name_] = cfGetConstantValue(lfipObj.value_);
                    }
                }
            }
         }

         return lCriteria;
    }

    cfGetRequestObject()
    {
        var lRetObj;
        var lCollection = this.state.mDataCollection;
        if(!lCollection)
        {
            lCollection = this.state.mSelectedRecord;
        }
        
        if(lCollection)
        {
            var lFormGridProperties = this.state.mFormGridProperties;
            if(lFormGridProperties)
            {
                for(var lfipInd=0; lfipInd<lFormGridProperties.length; lfipInd++)
                {
                    var lfipObj = lFormGridProperties[lfipInd];
                    if(lfipObj.property_ === 'save_data')
                    {
                        if(lfipObj.type_=== 'collection')
                        {
                            if((lfipObj.name_ === 'data_object_') && lfipObj.value_)
                            {
                                if(!lRetObj)
                                {
                                    lRetObj = {}
                                }

                                lRetObj[lfipObj.value_] = lCollection;
                            }
                        }
                    }
                }
            }

            var lRequiredFields = []
            for(var lInd0=0; lInd0<this.state.mFormGrid.length; lInd0++)
            {
                var lGridObject = this.state.mFormGrid[lInd0];
                if(lGridObject.required_ === '1')
                {
                    lRequiredFields.push(lGridObject.data_field_);
                }

                if(lGridObject.hasOwnProperty('cs_form_grid_item_properties_'))
                {
                    var lFgiSequencePropMap = null;
                    var lFgiProperties = CSUtil.cfSortInteger(lGridObject.cs_form_grid_item_properties_, 'sequence_');
                    for(var lFgiInd=0; lFgiInd<lFgiProperties.length; lFgiInd++)
                    {
                        var lFgiProp = lFgiProperties[lFgiInd];
                        if(lFgiProp.property_ === 'collection_data')
                        {
                            if(!lFgiSequencePropMap)
                            {
                                lFgiSequencePropMap = {}
                            }

                            if(!lFgiSequencePropMap[lFgiProp.sequence_])
                            {
                                lFgiSequencePropMap[lFgiProp.sequence_] = [];
                            }

                            lFgiSequencePropMap[lFgiProp.sequence_].push(lFgiProp);
                        }
                    }

                    for(var ldInd=0; lFgiSequencePropMap && ldInd<lCollection.length; ldInd++)
                    {
                        var ldObj = lCollection[ldInd];
                        const lKeys = Object.keys(lFgiSequencePropMap);
                        for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                        {
                            var lDataObj = {}
                            var lFgiPropertyList = lFgiSequencePropMap[lKeys[lKeyInd]];
                            for(var lTInd=0; lTInd<lFgiPropertyList.length; lTInd++)
                            {
                                var lFgiProperty = lFgiPropertyList[lTInd];
                                var lDependentObj = this.state.mFormGridItemObjectMap[lFgiProperty.dependent_item_ref_id_];
                                if(lDependentObj)
                                {
                                    if(lFgiProperty.type_ === 'data_field')
                                    {
                                        if(lFgiProperty.param_ && (lFgiProperty.value_ === 'cell_value_') && ldObj[lDependentObj.data_field_])
                                        {
                                            if(lDependentObj.item_editor_ && (lDependentObj.item_editor_ === 'MULTI_SELECT_COMBO_BOX_EDITOR_1'))
                                            {
                                                /*
                                                 * String Tokenize and add "'"
                                                 */
                                                var lString = ldObj[lDependentObj.data_field_];
                                                if(lString)
                                                {
                                                    lString = "'" + lString.split( "," ).join( "','" ) + "'";
                                                    lDataObj[lFgiProperty.param_] = lString;
                                                }
                                            }
                                            else
                                            {
                                                lDataObj[lFgiProperty.param_] = ldObj[lDependentObj.data_field_];
                                            }
                                        }
                                        else if(lDependentObj.required_ === '1')
                                        {
                                            break;
                                        }
                                        else
                                        {
                                            lDataObj[lFgiProperty.param_] = '';
                                        }
                                    }
                                    else if(lFgiProperty.type_ === 'constant')
                                    {
                                        var lConstValue = cfGetConstantValue(lFgiProperty.value_);
                                        if(lConstValue)
                                        {
                                            lDataObj[lFgiProperty.param_] = lConstValue;
                                        }
                                        else if(lDependentObj.required_ === '1')
                                        {
                                            break;
                                        }
                                        else
                                        {
                                            lDataObj[lFgiProperty.param_] = '';
                                        }
                                    }

                                    if(lTInd === lFgiPropertyList.length - 1)
                                    {
                                        if(!lRetObj)
                                        {
                                            lRetObj = {}
                                        }

                                        if(!lRetObj.hasOwnProperty(lFgiProperty.data_field_))
                                        {
                                            lRetObj[lFgiProperty.data_field_] = []
                                        }

                                        lRetObj[lFgiProperty.data_field_].push(lDataObj);
                                    }
                                }
                            }
                        }
                    }
                }
            }            

            if(!lRetObj)
            {
                if(lRequiredFields.length === 0)
                {
                    lRetObj = lCollection;
                }
                else
                {
                    for(var lcInd=0; lcInd<lCollection.length; lcInd++)
                    {
                        var lCObj = lCollection[lcInd];
                        var lreqFlag = true;
                        for(var lInd1=0; lInd1<lRequiredFields.length; lInd1++)
                        {
                            if(!lCObj[lRequiredFields[lInd1]])
                            {
                                lreqFlag = false;
                                break;
                            }
                        }

                        if(lreqFlag)
                        {
                            if(!lRetObj)
                            {
                                lRetObj = [];
                            }

                            lRetObj.push(lCObj);
                        }
                    }
                }
            }
        }

        return lRetObj;
    }

    cfGetActionItemState(lActionItem)
    {
        var lEnable = true;
        if(lActionItem.hasOwnProperty('cs_form_action_properties_') && lActionItem.cs_form_action_properties_)
        {
            var lfaProperties = lActionItem.cs_form_action_properties_;
            for(var lInd=0; lInd<lfaProperties.length; lInd++)
            {
                var lformProp = lfaProperties[lInd];
                if(lformProp.type_ === 'form_initialize')
                {
                    if(lformProp.property_ === 'enabled')
                    {
                        if(lformProp.selection_value_ === '#NOT_NULL#')   
                        {
                            if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                            {
                                var lDataObj = this.state.mDataCollection[0];
                                if(lDataObj.hasOwnProperty(lformProp.selection_property_))
                                {
                                    if(lformProp.value_ === 'false')
                                    {
                                        lEnable = false;
                                    }
                                    else
                                    {
                                        lEnable = true;
                                    }
                                    }
                            }
                        }
                        else if(lformProp.selection_value_ === '#NULL#')   
                        {
                            if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                            {
                                var lDataObj1 = this.state.mDataCollection[0];
                                if(!lDataObj1.hasOwnProperty(lformProp.selection_property_))
                                {
                                    if(lformProp.value_ === 'false')
                                    {
                                        lEnable = false;
                                    }
                                    else
                                    {
                                        lEnable = true;
                                    }
                                }
                            }
                            else
                            {
                                if(lformProp.value_ === 'false')
                                {
                                    lEnable = false;
                                }
                                else
                                {
                                    lEnable = true;
                                }
                            }
                        }
                    }
                }

                if(!lEnable)
                {
                    break;
                }
            }
        }

        return lEnable;
    }

    process_set(lCriteria, lActionItem)
    {
        if(lCriteria && lActionItem)
        {
            var lSetDataField = null;
            if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
            {
                for(var lfapInd=0; lfapInd<lActionItem.cs_form_action_properties_.length; lfapInd++)
                {
                    var lProperty = lActionItem.cs_form_action_properties_[lfapInd];
                    if((lProperty.type_ === 'form_initialize') && (lProperty.property_ === 'set_criteria'))
                    {
                        lSetDataField = lProperty.value_;
                        break;
                    }
                }
            }

            if(lSetDataField && lCriteria.hasOwnProperty(lSetDataField))
            {
                var lDataField = null;
                var lFilterParam = null;
                var lFilterValue = null;
                var lFieldValue = lCriteria[lSetDataField];
                var lSetItems = []
                for(var lgInd=0; lgInd<this.state.mFormGrid.length; lgInd++)
                {
                    var lGridItem = this.state.mFormGrid[lgInd];
                    if(lGridItem.hasOwnProperty('cs_form_grid_item_properties_'))
                    {
                        var lProperties = lGridItem.cs_form_grid_item_properties_;
                        for(var lpInd=0; lpInd<lProperties.length; lpInd++)
                        {
                            var lfgiProperty = lProperties[lpInd];
                            if((lfgiProperty.property_ === 'grid_data') 
                                && (lfgiProperty.type_ === 'data_selection')
                                 && (lfgiProperty.param_ === 'enable_set'))
                            {
                                lSetItems.push(lGridItem.data_field_);
                            }
                            else if(lfgiProperty.property_ === 'criteria_data') 
                            {
                                if(!lFilterParam)
                                {
                                    lFilterParam = []
                                }
                                
                                if(!lFilterValue)
                                {
                                    lFilterValue = []
                                }
                                
                                if(!lDataField)
                                {
                                    lDataField = []
                                }

                                lFilterParam.push(lfgiProperty.param_);
                                lDataField.push(lGridItem.data_field_);
                                lFilterValue.push(lfgiProperty.value_);
                            }
                        }
                    }
                }

                if(this.state.mDataCollection)
                {
                    var lCollection = this.state.mDataCollection.map((lDataObj, lIndex) =>
                        {
                            var lIsDefaultCriteria = true;
                            for(var lsInd=0; lsInd<lSetItems.length; lsInd++)
                            {
                                if(lFilterValue && lFilterParam && lDataField)
                                {
                                    for(var lfInd=0; lfInd<lFilterValue.length; lfInd++)
                                    {
                                        if(!lCriteria.hasOwnProperty(lFilterParam[lfInd]) || !lCriteria[lFilterParam[lfInd]])
                                        {
                                            break;
                                        }

                                        lIsDefaultCriteria = false;
 
                                        if(lCriteria[lFilterParam[lfInd]] === lFilterValue[lfInd])
                                        {
                                            if(lSetItems[lsInd] === lDataField[lfInd])
                                            {
                                                lDataObj[lDataField[lfInd]] = lFieldValue;
                                            }
                                        }
                                    }
                                }

                                if(lIsDefaultCriteria)
                                {
                                    lDataObj[lSetItems[lsInd]] = lFieldValue;
                                }
                            }

                            return lDataObj;
                        }
                    );

                    this.setState({mDataCollection : lCollection});
                }
            }
        }
    }

    process_save(lEvent, lParent, lActionItem)
    {
        var lSaveFlag = 1;
        this.setState({mShowBackdrop : true});

        if(lActionItem.hasOwnProperty('cs_form_action_properties_') && lActionItem.cs_form_action_properties_)
        {
            var lCodeMessageMap = null;
            var lfaProperties = lActionItem.cs_form_action_properties_;
            for(var lInd=0; lInd<lfaProperties.length; lInd++)
            {
                var lformProp = lfaProperties[lInd];
                if((lformProp.type_ === 'item_selection') && (lformProp.property_ === 'on_save_validation'))
                {
                    if(lformProp.value_ && lformProp.selection_value_)
                    {
                        if(!lCodeMessageMap)
                        {
                            lCodeMessageMap = {}
                        }

                        lCodeMessageMap[lformProp.value_] = lformProp.selection_value_;
                    }
                }
            }

            if(lCodeMessageMap)
            {
                for(var lgInd=0; lgInd<this.state.mFormGrid.length; lgInd++)
                {
                    var lColumn = this.state.mFormGrid[lgInd];
                    if(lColumn.required_ === "1")
                    {
                        if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                        {
                            for(var ldInd=0; ldInd<this.state.mDataCollection.length; ldInd++)
                            {
                                var ldObj = this.state.mDataCollection[ldInd];
                                if(!ldObj[lColumn.data_field_])
                                {
                                    lSaveFlag = -1;
                                    break;
                                }
                            }
                        }
                        else
                        {
                            lSaveFlag = -2;
                            break;
                        }
                    }
                }

                this.mFormUtil.cfSaveValidation(lActionItem, lCodeMessageMap[lSaveFlag]);
            }
        }

        if(lSaveFlag === 1)
        {
            this.mFormUtil.cfPromptSave(lActionItem, (lResult) =>
                {
                    if(lResult && lResult.value)
                    {
                        var lRequestData = this.cfGetRequestObject();
                        var lCriteria = this.cfGetRequestCriteria();
                        this.mFormUtil.cfProcessSave(lActionItem, lRequestData, 
                            (lResponse) =>
                            {
                                var lCode = null;
                                var lMsg = null;
                                var lCloseFlag = (lActionItem.new_state_ === "list")

                                if(lResponse && lResponse.code)
                                {
                                    if(lResponse.code instanceof Array)
                                    {
                                        lCode = lResponse.code[0];
                                        if(lResponse.message)
                                        {
                                            lMsg = lResponse.message[0];
                                        }
                                    }
                                    else
                                    {
                                        lCode = lResponse.code;
                                        if(lResponse.message)
                                        {
                                            lMsg = lResponse.message;
                                        }
                                    }
                                }
                                else
                                {
                                    lCode = -1;
                                }

                                this.mFormUtil.cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
                                if((lCode === 1) || (lCode === '1'))
                                {
                                    this.cfCloseForm(!lCloseFlag, true);
                                }

                                this.setState({mShowBackdrop : false});
                            }, lCriteria
                        );
                    }
                }
            );
        }
        else
        {
            this.setState({mShowBackdrop : false});
        }
    }
    
    cfOnItemChange(lData, lRowData, lRowIndex, lValue, lColumn)
    {
        var lComputedData = CSFormUtil.cfGetGridComputeFieldsData(lRowData, this.props.pFormGrid, this.props.pFormGridItemProperties);
        this.setState( { mSelectedRow : lComputedData } );
/*
        if(this.props.pFormGridItemProperties)
        {
            var lComputeList = null;
            for(var lInd=0; lInd<this.props.pFormGridItemProperties.length; lInd++)
            {
                var lProperty = this.props.pFormGridItemProperties[lInd];
                if(lProperty.property_ === 'compute')
                {
                    if(!lComputeList)
                    {
                        lComputeList = {}
                    }

                    var lGridRefIdList = lComputeList[lProperty.cs_form_grid_ref_id_];
                    if(!lGridRefIdList)
                    {
                        lGridRefIdList = []
                        lComputeList[lProperty.cs_form_grid_ref_id_] = lGridRefIdList;
                    }

                    lGridRefIdList.push(lProperty);
                }
            }

            if(lComputeList)
            {
                var lGridItemMap = {}
                for(var lgItemIndex=0; lgItemIndex<this.props.pFormGrid.length; lgItemIndex++)
                {
                    lGridItemMap[this.props.pFormGrid[lgItemIndex].id_] = this.props.pFormGrid[lgItemIndex];
                }

                var lKeys = Object.keys(lComputeList);
                for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                {
                    var lKey = lKeys[lKeyInd];
                    var lCompList = lComputeList[lKey];
                    if(lCompList)
                    {
                        var lColumnValue = null;
                        var lDataField = null;
                        CSUtil.cfSort(lCompList, 'sequence_');
                        for(var lcInd=0; lcInd<lCompList.length; lcInd++)
                        {
                            var lcProperty = lCompList[lcInd];
                            if(lcInd === 0)
                            {
                                if(lcProperty.type_ === 'data_field')
                                {
                                    lColumnValue = lRowData[lcProperty.param_]
                                }
                                else if(lcProperty.type_ === 'constant')
                                {
                                    lColumnValue = lcProperty.value_;
                                }

                                lDataField = lGridItemMap[lcProperty.cs_form_grid_ref_id_]['data_field_'];
                            }
                            else
                            {
                                var lVal2 = null;
                                if(lcProperty.type_ === 'data_field')
                                {
                                    lVal2 = lRowData[lcProperty.param_]
                                }
                                else if(lcProperty.type_ === 'constant')
                                {
                                    lVal2 = lcProperty.value_;
                                }

                                if(lcProperty.selection_property_ === 'multiply')
                                {
                                    lColumnValue = Number(lColumnValue) * Number(lVal2);
                                }
                                else if(lcProperty.selection_property_ === 'divide')
                                {
                                    lColumnValue = Number(lColumnValue) / Number(lVal2);
                                }
                                else if(lcProperty.selection_property_ === 'add')
                                {
                                    lColumnValue = Number(lColumnValue) + Number(lVal2);
                                }
                                else if(lcProperty.selection_property_ === 'difference')
                                {
                                    lColumnValue = Number(lColumnValue) - Number(lVal2);
                                }
                            }
                        }

                        if(lDataField)
                        {
                            lRowData[lDataField] = lColumnValue;
                        }
                    }
                }
            }
        }

   */
    }

    render()
    {
        var lRetValue = null;
        var lDetailsForm = null;
        var lCriteria = null;
        var lSearchActions = null;
        var lSaveMultipleActions = null; 

        if(this.state.mFormCriteria && (this.state.mFormCriteria.length > 0))
        {
            lCriteria = 
                <div id="ID_CRITERIA" ref={this.cfGetDimensions}>
                    <CSCOCriteria 
                        pCriteria       = {this.state.mFormCriteria}
                        pFormActions    = {this.state.mFormActions}
                        pFormProperties = {this.state.mFormProperties}
                        pParent         = {this}
                    />
                </div>
        }

        var lCriteriaHeight = this.state.mCriteriaHeight;
        if((lCriteriaHeight === 0) && this.state.mFormCriteria)
        {
            lCriteriaHeight = 80;
        }

        var lFormHeading =  fnGetFormHeader(this.state.mFormLayoutHeading);
        var lScrollHeight = ((CSUtil.cfGetInstance()).mApplicationHeight - lCriteriaHeight - 150);

        if(this.mCriteriaHeight === 0)
        {
            lScrollHeight += 20;
        }

        if(this.state.mSearchActionList && (this.state.mSearchActionList.length > 0))
        {
            var lSearchActionList = this.state.mSearchActionList.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mSelectedRecord);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );

            lSearchActions = 
                <CSCOBaseActionBar 
                    align       = "left"
                    pType       = 'SEARCH_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lSearchActionList}
                />;
        }

        var lCollectionArray = null;
        var lDataCollection0 = null;
        var lDataCollection1 = null;
        var lDataCollection2 = null;
        var lGrid1 = null;
        var lGrid2 = null;
        var lGrid3 = null;

        if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
        {
            lCollectionArray = fnArraySplit(this.state.mDataCollection, this.state.mMaxGrids, true)
        }

        if(this.state.mMaxGrids >= 3)
        {
            lDataCollection2 = (lCollectionArray && (lCollectionArray.length > 2)) ? lCollectionArray[2] : null;
            lGrid3 = 
                <div className="col">
                    <CSCOGrid
                        pData               = { lDataCollection2 }
                        pFeatureRefId       = { this.state.mFeatureRefId }
                        pColumns            = { this.state.mFormGrid }
                        pHeaderColumns      = { this.state.mHeaderColumns }
                        rowIndex            = { this.state.mRowIndex } 
                        pFormGridProperties = { this.props.pFormGridProperties }
                        pageSize            = { 500 }
                        onClick             = { this.cfOnDataSelection }
                        OnItemChange        = { this.cfOnItemChange }
                        pResetFlag          = { (this.state.mFormFlag === 2) ? true : false }
                        pSearchActionBar    = { lSearchActions }
                        pEnableFilter       = { this.state.mEnableFilter }
                        pSelectedData       = { this.state.mSelectedRecord }
                        pScrollHeight       = { lScrollHeight }
                        pEnableScroll       = { true }
                        pClearSelection     = { this.state.mClearSelection }
                    />
                </div>
        }

        if(this.state.mMaxGrids >= 2)
        {
            lDataCollection1 = (lCollectionArray && (lCollectionArray.length > 1)) ? lCollectionArray[1] : null;
            lGrid2 = 
                <div className="col">
                    <CSCOGrid
                        pData               = { lDataCollection1 }
                        pFeatureRefId       = { this.state.mFeatureRefId }
                        pColumns            = { this.state.mFormGrid }
                        pHeaderColumns      = { this.state.mHeaderColumns }
                        rowIndex            = { this.state.mRowIndex } 
                        pFormGridProperties = { this.props.pFormGridProperties }
                        pageSize            = { 50 }
                        onClick             = { this.cfOnDataSelection }
                        OnItemChange        = { this.cfOnItemChange }
                        pResetFlag          = { (this.state.mFormFlag === 2) ? true : false }
                        pSearchActionBar    = { lSearchActions }
                        pEnableFilter       = { this.state.mEnableFilter }
                        pSelectedData       = { this.state.mSelectedRecord }
                        pScrollHeight       = { lScrollHeight }
                        pEnableScroll       = { true }
                        pClearSelection     = { this.state.mClearSelection }
                    />
                </div>
        }

        if(this.state.mMaxGrids >= 1)
        {
            lDataCollection0 = (lCollectionArray && (lCollectionArray.length > 0)) ? lCollectionArray[0] : null;
            lGrid1 = 
                <div className="col">
                    <CSCOGrid
                        pData               = { lDataCollection0 }
                        pFeatureRefId       = { this.state.mFeatureRefId }
                        pColumns            = { this.state.mFormGrid }
                        pHeaderColumns      = { this.state.mHeaderColumns }
                        rowIndex            = { this.state.mRowIndex } 
                        pFormGridProperties = { this.props.pFormGridProperties }
                        pageSize            = { 50 }
                        onClick             = { this.cfOnDataSelection }
                        OnItemChange        = { this.cfOnItemChange }
                        pResetFlag          = { (this.state.mFormFlag === 2) ? true : false }
                        pSearchActionBar    = { lSearchActions }
                        pEnableFilter       = { this.state.mEnableFilter }
                        pSelectedData       = { this.state.mSelectedRecord }
                        pScrollHeight       = { lScrollHeight }
                        pEnableScroll       = { true }
                        pClearSelection     = { this.state.mClearSelection }
                    />
                </div>
        }

        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            var lFormActionList = [];
            for(var lFInd=0; lFInd<this.state.mFormActions.length; lFInd++)
            {
                var lActionItem = this.state.mFormActions[lFInd];
                if (lActionItem.component_type_ === 'SAVE_MULTIPLE_ACTION')
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        let lRequiredAll = true;
                        if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
                        {
                            var lProperties = lActionItem.cs_form_action_properties_;
                            for(var lpInd=0; lpInd<lProperties.length; lpInd++)
                            {
                                var lProperty = lProperties[lpInd];
                                if(lProperty.selection_property_ === '#REQUIRED#')
                                {
                                    if(lProperty.selection_value_ === 'ANY')
                                    {
                                        lRequiredAll = false;
                                        break;
                                    }
                                }
                            }
                        }

                        lActionItem.mEnabled = true;
                        lActionItem.enabled_ = "1";
                        for(var lgInd=0; lgInd<this.state.mFormGrid.length; lgInd++)
                        {
                            var lColumn = this.state.mFormGrid[lgInd];
                            if(lColumn.required_ === "1")
                            {
                                if(this.state.mDataCollection && (this.state.mDataCollection.length > 0))
                                {
                                    for(var ldInd=0; ldInd<this.state.mDataCollection.length; ldInd++)
                                    {
                                        var ldObj = this.state.mDataCollection[ldInd];
                                        if(!ldObj[lColumn.data_field_])
                                        {
                                            lActionItem.mEnabled = false;
                                            lActionItem.enabled_ = "0";

                                            if(lRequiredAll)
                                            {
                                                break;
                                            }
                                        }
                                        else
                                        {
                                            if(!lRequiredAll)
                                            {
                                                lActionItem.mEnabled = true;
                                                lActionItem.enabled_ = "1";
                                                break;
                                            }
                                        }
                                    }
                                }
                                else
                                {
                                    lActionItem.mEnabled = false;
                                    lActionItem.enabled_ = "0";
                                    break;
                                }
                            }
                        }
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    lFormActionList.push(lActionItem);
                }
            }

            if(lFormActionList.length > 0)
            {
                lSaveMultipleActions = 
                    <CSCOHorizontalActionBar 
                        align         = "left"
                        pType         = 'SAVE_ACTION_BAR'
                        pMarginBottom = '0em'
                        pMargin       = '0em'
                        pPadding      = '0em'
                        parent        = {this}
                        actionList    = {lFormActionList}
                    />;
            }
                    
            if(lSaveMultipleActions)
            {
                lScrollHeight = lScrollHeight - 80;
            }
        }

        let lSelectedData = this.state.mSelectedData;
        if(!lSelectedData)
        {
            lSelectedData = this.state.mSelectedRecord;
        }

        if(this.state.mFormFlag === 1)
        {
            var lFormHeading1    = null;
            if(this.state.mSelectedActionItem)
            {
                var lButtons = null;
                if(this.state.mPrintForm && this.state.mEnablePreview && (this.state.mSelectedActionItem.action_ !== 'process_add'))
                {
                    let lShowPreview = true;
                    if(this.state.mPreviewCriteria && this.state.mSelectedRecord)
                    {
                        let lKeys = Object.keys(this.state.mPreviewCriteria);
                        for(let lkInd=0; lkInd<lKeys.length; lkInd++)
                        {
                            let lKey = lKeys[lkInd];
                            if(this.state.mSelectedRecord[lKey] !== this.state.mPreviewCriteria[lKey])
                            {
                                lShowPreview = false;
                                break;
                            }
                        }
                    }

                    if(lShowPreview)
                    {
                        lButtons = 
                            <Button
                                onClick = { (event) => this.cfEnablePrint(true) } 
                                icon    = { CSUtil.mNameIconMap['PI_BARS'] } 
                                style   = {{ fontSize : '13.2px', top: '4px', width: '24px', height: '24px', position:'absolute', right:'20px' }}
                            />
                    }
                }

                lFormHeading1 = fnGetFormHeader(this.state.mSelectedActionItem.title_, lButtons);
            }

            if(this.state.mDetailsMetaData && this.state.mDetailsFlag)
            {
                lRetValue = 
                    <>
                        { lFormHeading1 }

                        { fnGetForm(this.state.mDetailsMetaData, lSelectedData, this.process_close) }
                    </>
            }
        }
        else
        {
            lRetValue = 
                <>
                    <CSCOBackdrop pShow = {this.state.mShowBackdrop}/>
                    
                    <div id="ID_HEADING" ref={this.cfGetDimensions}>
                        { lFormHeading }                
                    </div>
                
                    { lCriteria }

                    <div className="fluid formgrid grid">
                        { lGrid1 }

                        { lGrid2 }
                        
                        { lGrid3 }
                    </div>

                    { lSaveMultipleActions }

                    { (this.state.mFormFlag === 2) ? fnGetForm(this.state.mDetailsMetaData, lSelectedData, this.process_close) : null }

                    { lDetailsForm }
                </>
        }
            
        return(lRetValue)
    }
}

export default CSCOFormLayout11;
