import React, { Component } from "react"
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import CSCOHorizontalActionBar from "../../components/CSCOHorizontalActionBar"

import CSUtil from "../../util/CSUtil";
import CSFormUtil from "../../util/CSFormUtil"
import { cfGetConstantValue} from "../../util/CSConstantUtil";

class CSCODetailsForm2 extends Component 
{
	mNumSections 		= 3;
    mMinSectionItems 	= 7;
    mFormUtil = CSFormUtil.cfGetInstance();
    
    constructor(props)
    {
        super(props);

        this.state = 
        {
            mDisplay        : props.pShow,
            mAppId          : props.pAppId,
            mFormHeading    : (props.pFormHeading) ? props.pFormHeading : "",
            mFormItems      : props.pFormItems,
            mFormData       : (props.pFormData) ? props.pFormData : {},
            mFormOrigData   : CSUtil.cfGetObjectCopy(props.pFormData),
            mFormActions    : props.pFormActions,
            mFormProperties : props.pFormProperties,
            mControlType    : props.pControlType,
            mEditable       : props.pEditable,
            mWidth          : props.pWidth,
            mHeight         : props.pHeight,
            mValue          : props.pValue,

            fnProcessChange : props.fnProcessChange,
            fnProcessSubmit : props.fnProcessSubmit,
            fnProcessClose  : props.fnProcessClose
        }

        this.cfProcessChange        = this.cfProcessChange.bind(this);
        this.cfSetControlValue      = this.cfSetControlValue.bind(this);

        this.cfCloseForm    = this.cfCloseForm.bind(this);        
        this.process_save   = this.process_save.bind(this);
        this.process_cancel = this.process_cancel.bind(this);
        this.process_close  = this.process_close.bind(this);
        this.process_reset  = this.process_reset.bind(this);
    }

    componentDidMount()
    {
    }

    componentDidUpdate(lPrevProps, lPrevState)
    {
        if(lPrevProps !== this.props)
        {
            this.setState({
                mDisplay        : this.props.pShow,
                mAppId          : this.props.pAppId,
                mFormHeading    : (this.props.pFormHeading) ? this.props.pFormHeading : "",
                mFormItems      : this.props.pFormItems,
                mFormData       : this.props.pFormData,
                mControlType    : this.props.pControlType,
                mEditable       : this.props.pEditable,
                mWidth          : this.props.pWidth,
                mHeight         : this.props.pHeight,
                mValue          : this.props.pValue,

                fnProcessChange : this.props.fnProcessChange,
                fnProcessSubmit : this.props.fnProcessSubmit,
                fnProcessClose  : this.props.fnProcessClose
            });

            this.mInitFlag = true;
        }
    }

    cfProcessChange(lData)
    {
        if(lData)
        {
            if(this.state.mFormData !== null)
            {
                var lFormData = this.state.mFormData;
                lFormData[lData.data_field_] = lData.value_;
                this.setState ({mFormData : lFormData});
            }
        }
    }

    cfSetControlValue()
    {
        if(this.state && this.state.mFormItems)
        {
            for(var ind=0; ind<this.state.mFormItems.length; ind++)
            {
                var form_item = this.state.mFormItems[ind];
                var form_data = this.state.mFormData;
                if(form_item.control_type_ === 'constant')
                {
                    form_item.mValue = cfGetConstantValue(form_item.constant_);
                    if(form_data)
                    {
                        form_data[form_item.data_field_] = form_item.mValue;
                    }
                }
                else if(form_item.control_type_ === 'check_box')
                {
                    if(form_item.constant_ && form_data && ((form_item.constant_ === form_data[form_item.data_field_])))
                    {
                        form_item.mValue = '1';
                    }
                    else
                    {
                        form_item.mValue = '0';
                    }
                }
                else if(form_data)
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        if(form_data[form_item.data_field_])
                        {
                            form_item.mValue = form_data[form_item.data_field_];        
                        }
                        else if(form_item.constant_)
                        {
                            form_item.mValue = cfGetConstantValue(form_item.constant_);
                        }
                    }
                    else
                    {
                        form_item.mValue = form_data[form_item.data_field_];
                    }
                }
                else
                {
                    if(form_item.control_type_ === 'text_input')
                    {
                        form_item.mValue = cfGetConstantValue(form_item.constant_);
                    }
                    else
                    {
                        form_item.mValue = '';
                    }
                }
            }
        }
    }

    cfCloseForm()
    {
        this.setState({mDisplay : false});
    }

    process_save(event, lParent, lActionItem)
    {
        if(lActionItem && (lActionItem.on_click_service_code_ || lActionItem.on_click_request_ref_id_))
        {
        }
    }

    process_cancel(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_close(event, lParent, lActionItem)
    {
        this.cfCloseForm();
    }

    process_reset()
    {
        this.setState ({mFormData : CSUtil.cfGetObjectCopy(this.state.mFormOrigData)});
    }

    componentWillUnmount()
    {
        this.props = null;
    }

    render() 
    {
        this.cfSetControlValue();
        var lFormActions = null;
        var lFormItemList = [];
        if(this.state.mFormItems && this.state.mFormItems.length > 0)
        {
            for(var lInd=0; lInd < this.state.mFormItems.length; lInd++)
            {
                var lFormItem = this.state.mFormItems[lInd];
                if(lFormItem.visible_ === '1')
                {
                    lFormItemList.push(lFormItem);
                }
            }
        }

        if(this.state.mFormActions && (this.state.mFormActions.length > 0))
        {
            var lFormActionList = this.state.mFormActions.map((lActionItem) => 
                {
                    if(lActionItem.action_type_ === '1')
                    {
                        lActionItem.mEnabled = CSFormUtil.cfEnableAction(lActionItem, this.state.mFormData, this.state.mFormItems);
                    }
                    else
                    {
                        lActionItem.mEnabled = (lActionItem.enabled_ === '1') ? true : false;
                    }

                    return lActionItem;
                }
            );
 
            lFormActions = 
                <CSCOHorizontalActionBar 
                    align       = "left"
                    pType       = 'SAVE_ACTION_BAR'
                    parent      = {this}
                    actionList  = {lFormActionList}
                />;
        }
        let lHeaderActions = 
        <div style={{verticalAlign: 'middle'}}>            


            <Button
                key = "21"
                style=
                    {
                        {
                            verticalAlign   : 'middle'
                        }
                    } 
                type="Button" 
                icon={CSUtil.mNameIconMap['PI_TIMES']}
                onClick= { () => this.process_close(false, true) }
            />
        </div>

    let lHeader = 
        <div className='flex justify-content-between'>
            <div className="p-toolbar-group-left">
                <h2>{ this.state.mFormLayoutHeading || '.' }</h2>
            </div>

            <div className="p-toolbar-group-right">
                { lHeaderActions }
            </div>
        </div>

        var lForm =
            <Dialog visible={this.state.mDisplay}
                    modal={true}
                    style=
                    {
                        {
                            minWidth : '350px', 
                            minheight : 'auto', 
                            paddingBottom: -40,
                        }
                    }
                    minheight   = 'auto'
                    appendTo    = { document.body }
                    header      = {lHeader}
                    closable    = { false }
                    padding     = '10px'
                    onHide      = {() => this.cfCloseForm()}
                    footer      = { lFormActions }
                >
                <div className="form-item">
                { this.mFormUtil.cfGetDetailsSections(lFormItemList, this.mMinSectionItems, this.cfProcessChange) }
                </div>
            </Dialog>
          
        return lForm;
    }
}

export default CSCODetailsForm2;
