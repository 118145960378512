import React from "react";

import CSServiceUtil from "../util/CSServiceUtil";
import CSRequestUtil from "../util/CSRequestUtil";
import CSConfigUtil from "../util/CSConfigUtil";
import { CSConstantUtil, cfGetConstantValue } from "../util/CSConstantUtil";
import CSUtil from "../util/CSUtil";
import App from '../app'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import CSCOBackdrop from "./CSCOBackdrop";
import { fnCreateOTP } from "../util/CSMathUtil";
import { classNames } from "primereact/utils";
import { Password } from 'primereact/password';
import { Divider } from "@mui/material";

class CSCOLogin extends React.Component {
    mCSUtil = null;
    mCSServiceUtil = null;

    constructor(props) {
        super(props);

        this.mCSUtil = CSUtil.cfGetInstance();
        this.mCSServiceUtil = CSServiceUtil.cfGetInstance();

        this.state =
        {
            mLoginStatus: false,
            mLoginAttempt: 1,
            mSessionId: -1,
            mUserId: '',
            mPassword: '',
            mLoginMessage: null,
            mShowBackdrop: false,
            mSelectedAcademicYear: null,
            mSelectedInstitute: null,
            mAcademicYearList: null,
            mInstituteList: null,
            mEnableSignIn: false,
            mInsFlag: false,
            mHomeFlag: false,
            mNewPassword: '',
            mConfirmPassword: '',
            mPasswordChange: 0,
            mNewEnteredOT: '',
            mEnteredOTP: '',
            mGeneratedOTP: '',
            mResetPasswdMsg: '',
            mValidatePasswdMsg: ''
        }

        this.cfAuthenticate = this.cfAuthenticate.bind(this);
        this.cfSetProperties = this.cfSetProperties.bind(this);
        this.cfReset = this.cfReset.bind(this);
        this.cfResetPassword = this.cfResetPassword.bind(this);
        this.cfValidateOTP = this.cfValidateOTP.bind(this);
        this.cfValidatePassword = this.cfValidatePassword.bind(this);
        this.cfGetLoginForm = this.cfGetLoginForm.bind(this);
        this.cfProcessChange = this.cfProcessChange.bind(this);
        this.cfProcessAuthenticateResponse = this.cfProcessAuthenticateResponse.bind(this);
        this.cfInitialize = this.cfInitialize.bind(this);
        this.cfHome = this.cfHome.bind(this);
    }

    componentDidMount() {
        var lRequestUtil = new CSRequestUtil();
        lRequestUtil.cfGetPropertiesRequest(this.cfSetProperties);
    }

    componentDidUpdate(lPrevProps, lPrevState) {
        if (lPrevProps !== this.props) {
            if (this.props.hasOwnProperty('pLogout') && (this.props.pLogout === true)) {
                this.setState(
                    {
                        mLoginStatus: false,
                        mLoginAttempt: 1,
                        mSessionId: -1,
                        mUserId: '',
                        mPassword: '',
                        mLoginMessage: null,
                        mShowBackdrop: false,
                        mInsFlag: false,
                        mHomeFlag: false
                    }
                );
            }
        }
        else if ((CSConfigUtil.mLoginType === '2') || CSConfigUtil.mLoginType === '3') {
            if ((lPrevState.mSelectedAcademicYear !== this.state.mSelectedAcademicYear) || (lPrevState.mSelectedInstitute !== this.state.mSelectedInstitute)) {

                this.setState({ mInsFlag: true })
            }
        }
    }

    cfInitialize() {
        var lServiceCode = 'GET_BRANCH';
        var lRequestId = 'CS_REQ_INIT_SMS';
        var lServiceObject = this.mCSServiceUtil.cfGetServiceObject(lServiceCode);
        if (lServiceObject) {
            var lCriteriaObj = {};
            lCriteriaObj.user_id_ = CSConfigUtil.mUserId;
            lCriteriaObj.app_code_ = CSConfigUtil.mAppCode;
            var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
            lRequestUtil.mRequestId = lRequestId;
            lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
            lRequestUtil.cfProcessRequest((lResponse) => {
                    if (lResponse && lResponse.hasOwnProperty("academic_year_list_")) {
                        CSUtil.mAcademicYearList = lResponse.academic_year_list_;
                        if (lResponse.academic_year_list_.length > 0) {
                            for (var lAyInd = 0; lAyInd < CSUtil.mAcademicYearList.length; lAyInd++) {
                                var lAyObj = CSUtil.mAcademicYearList[lAyInd];
                                if (lAyObj.is_default_ === 'Y') {
                                    CSConfigUtil.mActiveAcademicYearObj = lAyObj;
                                    CSConfigUtil.mAYCode = lAyObj;
                                    CSConfigUtil.mCurrentAYCode = lAyObj.academic_year_code_;
                                    CSConfigUtil.mCurrentAYName = lAyObj.academic_year_name_;
                                    break;
                                }
                            }
                        }
                    }

                    if (lResponse && lResponse.hasOwnProperty("institute_list_")) {
                        CSUtil.mInstituteList = lResponse.institute_list_;
                        if (CSConfigUtil.mBranchCode && (lResponse.institute_list_.length > 0)) {
                            for (let lbInd = 0; lbInd < CSUtil.mInstituteList.length; lbInd++) {
                                let lInstObj = CSUtil.mInstituteList[lbInd];
                                if (lInstObj.branch_code_ === CSConfigUtil.mBranchCode) {
                                    CSConfigUtil.mBranchObj = lInstObj;
                                    if (lInstObj.type_name_) {
                                        CSConfigUtil.mBranchType = lInstObj.type_name_;
                                        CSConfigUtil.mBranchTypeCode = lInstObj.type_code_;
                                    }

                                    CSConfigUtil.mBoardCode = lInstObj.board_code_;
                                    CSConfigUtil.mBoardName = lInstObj.board_name_;
                                    CSConfigUtil.mBranchEmail = lInstObj.email_;
                                    break;
                                }
                            }
                        }
                    }

                    if (lResponse && lResponse.hasOwnProperty("receipt_config_")) {
                        if (lResponse.receipt_config_.length > 0) {
                            var lReceiptConfigList = lResponse.receipt_config_;
                            for (var lrcInd = 0; lrcInd < lReceiptConfigList.length; lrcInd++) {
                                var lRcObj = lReceiptConfigList[lrcInd];
                                if ((lRcObj.type_ === 'RECEIPT') || (lRcObj.type_ === 'RECEIPT1') || (lRcObj.type_ === 'RECEIPT2')) {
                                    if (lRcObj.name_ === 'HEADING') {
                                        if (!CSConfigUtil.mReceiptHeadingList) {
                                            CSConfigUtil.mReceiptHeadingList = []
                                        }

                                        CSConfigUtil.mReceiptHeadingList.push(lRcObj);
                                    }
                                    else if (lRcObj.name_ === 'SUB_HEADING_1') {
                                        if (!CSConfigUtil.mReceiptSubHeading1List) {
                                            CSConfigUtil.mReceiptSubHeading1List = []
                                        }

                                        CSConfigUtil.mReceiptSubHeading1List.push(lRcObj);
                                    }
                                    else if (lRcObj.name_ === 'SUB_HEADING_2') {
                                        if (!CSConfigUtil.mReceiptSubHeading2List) {
                                            CSConfigUtil.mReceiptSubHeading2List = []
                                        }

                                        CSConfigUtil.mReceiptSubHeading2List.push(lRcObj);
                                    }
                                }
                                else if (lRcObj.type_ === 'REPORT') {
                                    if (lRcObj.name_ === 'HEADING') {
                                        if (!CSConfigUtil.mReportHeadingList) {
                                            CSConfigUtil.mReportHeadingList = []
                                        }

                                        CSConfigUtil.mReportHeadingList.push(lRcObj);
                                    }
                                    else if (lRcObj.name_ === 'SUB_HEADING_1') {
                                        if (!CSConfigUtil.mReportSubHeading1List) {
                                            CSConfigUtil.mReportSubHeading1List = []
                                        }

                                        CSConfigUtil.mReportSubHeading1List.push(lRcObj);
                                    }
                                    else if (lRcObj.name_ === 'SUB_HEADING_2') {
                                        if (!CSConfigUtil.mReportSubHeading2List) {
                                            CSConfigUtil.mReportSubHeading2List = []
                                        }

                                        CSConfigUtil.mReportSubHeading2List.push(lRcObj);
                                    }
                                }
                            }

                            this.mCSUtil.cfInitializeReceiptConfig();
                            this.mCSUtil.cfInitializeReportConfig();
                        }
                    }

                    if (lResponse && lResponse.hasOwnProperty("system_config_")) {
                        var lFloatingMenuMap = {}
                        if (lResponse.system_config_.length > 0) {
                            let lSystemConfigList = lResponse.system_config_;
                            for (let lscInd = 0; lscInd < lSystemConfigList.length; lscInd++) {
                                let lSysConfigObj = lSystemConfigList[lscInd];
                                if ((lSysConfigObj.config_group_ === 'FLOATING_MENU_ITEM') && (lSysConfigObj.config_name_ === 'FEATURE_REF_ID')) {
                                    lFloatingMenuMap[lSysConfigObj.config_code_] = lSysConfigObj.config_default_value_;
                                }
                                else if ((lSysConfigObj.config_group_ === 'GENERAL') && (lSysConfigObj.config_name_ === 'DATA_FOLDER')) {
                                    CSConfigUtil.mDataFolder = lSysConfigObj.config_code_;
                                }
                                else if (lSysConfigObj.config_group_ === 'DATA_FOLDER') {
                                    CSConfigUtil.mDataFolderMap[lSysConfigObj.config_code_] = lSysConfigObj.config_name_;
                                }
                            }
                        }

                        this.mCSUtil.mFloatingMenuMap = lFloatingMenuMap;
                    }

                    this.setState(
                        {
                            mInsFlag: true,
                            mSelectedAcademicYear: CSConfigUtil.mActiveAcademicYearObj,
                            mSelectedInstitute: CSConfigUtil.mBranchObj
                        }
                    );
                }
            )
        }
    }

    cfSetProperties() {
        this.setState({ mInsFlag: false });
    }

    cfAuthenticate() {
        this.setState({ mShowBackdrop: true });
        if (this.state.mUserId && this.state.mPassword) {
            new CSRequestUtil().cfAuthenticate(this.state.mUserId, this.state.mPassword, this.state.mLoginAttempt, this.cfProcessAuthenticateResponse);
            this.setState({ mLoginAttempt: this.state.mLoginAttempt + 1 });
        }
    }

    cfReset() {
        this.setState({ mUserId: '', mPassword: '' });
        this.setState({ mInsFlag: false });
    }

    cfResetPassword() {
        let lOtp = fnCreateOTP(4, true);
        if (lOtp) {
            var lServiceCode = 'GET_EMPLOYEE_CONTACT_NO';
            var lServiceObject =
            {
                type_: 'employee',
                domain_id_: 'CS_DAO',
            };

            var lLoginObj =
            {
                user_id: this.state.mUserId,
                session_id: '1'
            }

            var lCriteriaObj = { user_id_: this.state.mUserId, app_code_: CSConfigUtil.mAppCode };
            var lRequestUtil = new CSRequestUtil('GET', lServiceCode, lServiceObject.type_, null, lServiceObject);
            lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
            lRequestUtil.cfProcessGetRequest((lResponse) => {
                var lContactNo = null;
                if (lResponse && lResponse.hasOwnProperty('collection_')) {
                    lContactNo = lResponse.collection_[0].mobile_no_;
                    if (lContactNo) {
                        new CSRequestUtil().cfSendOtp(lOtp, lContactNo, (lResult) => {
                                this.setState({ mLoginMessage: '', mResetPasswdMsg: '', mGeneratedOTP: lOtp, mPasswordChange: 1 });
                            }
                        )
                    }
                    else {
                        this.setState({ mLoginMessage: 'No Contact Number associated with this User Id' });
                    }
                }
            }, lLoginObj
            );
        }
    }

    cfValidateOTP() {
        if (this.state.mEnteredOTP === this.state.mGeneratedOTP) {
            this.setState({ mPasswordChange: 2 });
        }
        else {
            this.setState({ mResetPasswdMsg: CSConstantUtil.gResetPasswordMsg });
        }
    }

    cfValidatePassword() {
        if (this.state.mNewPassword === this.state.mConfirmPassword) {
            var lServiceCode = 'SYS_CHANGE_PASSWORD';
            var lServiceObject =
            {
                type_: 'cs_user',
                domain_id_: 'CS_DAO',
            };

            if (lServiceObject) {
                var lRequestUtil = new CSRequestUtil('SAVE', lServiceCode, lServiceObject.type_, null, lServiceObject);;
                var lData = { user_id_: this.state.mUserId, password_: this.state.mNewPassword, app_code_: CSConfigUtil.mAppCode };
                lRequestUtil.cfSetRequestData(lData);
                lRequestUtil.cfProcessRequest((lResponse) => {
                    if (lResponse.hasOwnProperty('code') && (lResponse.code[0] === 1)) {
                        this.setState({ mValidatePasswdMsg: 'Password Change Successful' });
                        this.setState({ mShowBackdrop: true });
                        if (this.state.mUserId) {
                            new CSRequestUtil().cfAuthenticate(this.state.mUserId, this.state.mNewPassword, this.state.mLoginAttempt, this.cfProcessAuthenticateResponse);
                            this.setState({ mLoginAttempt: this.state.mLoginAttempt + 1 });
                        }
                    }
                }
                );
            }
        }
        else {
            this.setState({ mValidatePasswdMsg: CSConstantUtil.gValidatePasswordMsg });
        }
    }

    cfProcessAuthenticateResponse(lStatus, lData) {
        var lResponse = null;
        if (lStatus) {
            if (lStatus.code === 1) {
                if (lData.collection_ instanceof Array) {
                    lResponse = lData.collection_[0];
                }
                else {
                    lResponse = lData.collection_;
                }

                if (lResponse.hasOwnProperty('session_id_')) {
                    CSConfigUtil.mSessionId = lResponse.session_id_;
                }

                if (lResponse.hasOwnProperty('user_id_')) {
                    CSConfigUtil.mUserId = lResponse.user_id_;
                }

                if (lResponse.hasOwnProperty('user_type_')) {
                    CSConfigUtil.mUserType = lResponse.user_type_;
                }

                if (lData.hasOwnProperty('authorization')) {
                    var authorization = lData.authorization;
                    if (authorization instanceof Array) {
                        var lAccessPrivList;
                        var lActionPrivList;
                        for (var lAuthInd = 0; lAuthInd < authorization.length; lAuthInd++) {
                            var lAuthObj = authorization[lAuthInd];
                            if (lAuthObj.hasOwnProperty('access_priv_list')) {
                                if (!lAccessPrivList) {
                                    lAccessPrivList = [];
                                }

                                lAccessPrivList.push(lAuthObj.access_priv_list);
                            }
                            else if (lAuthObj.hasOwnProperty('action_priv_list')) {
                                if (!lActionPrivList) {
                                    lActionPrivList = [];
                                }

                                lActionPrivList.push(lAuthObj.action_priv_list);
                            }
                        }

                        this.mCSUtil.cfSetAccessPrivilegeList(lAccessPrivList);
                        this.mCSUtil.cfSetActionPrivilegeList(lActionPrivList);
                    }
                    else {
                        if (authorization.hasOwnProperty('access_priv_list')) {
                            this.mCSUtil.cfSetAccessPrivilegeList(authorization.access_priv_list);
                        }

                        if (authorization.hasOwnProperty('action_priv_list')) {
                            this.mCSUtil.cfSetActionPrivilegeList(authorization.action_priv_list);
                        }
                    }
                }

                if (lData.hasOwnProperty('app_menu_')) {
                    this.mCSUtil.mAppMenuList = lData.app_menu_;
                }

                if (lData.hasOwnProperty('cs_layout_')) {
                    this.mCSUtil.mLayoutList = lData.cs_layout_;
                    this.mCSUtil.cfSetLayoutMap();
                }

                if (lData.hasOwnProperty('cs_component_')) {
                    this.mCSUtil.mComponentList = lData.cs_component_;
                    this.mCSUtil.cfSetComponentMap();
                }

                if (lData.hasOwnProperty('cs_icons_')) {
                    if (lData.cs_icons_ instanceof Array) {
                        this.mCSUtil.mAppIcons = lData.cs_icons_;
                        this.mCSUtil.cfSetAppIcons(lData.cs_icons_);
                    }
                    else {
                        if (Object.keys(lData.cs_icons_) && Object.keys(lData.cs_icons_)[0]) {
                            this.mCSUtil.mAppIcons = Object.values(lData.cs_icons_);
                            this.mCSUtil.cfSetAppIcons(Object.values(lData.cs_icons_));
                        }
                    }
                }

                if (lData.hasOwnProperty('app_layout_')) {
                    this.mCSUtil.mAppLayoutList = lData.app_layout_;
                    this.mCSUtil.cfSetMenuItems(lData.app_layout_);
                }

                if (lData.hasOwnProperty('cs_data_provider_')) {
                    if (lData.cs_data_provider_ instanceof Array) {
                        this.mCSServiceUtil.cfInitializeDataProvider(lData.cs_data_provider_);
                    }
                    else {
                        if (Object.keys(lData.cs_data_provider_) && Object.keys(lData.cs_data_provider_)[0]) {
                            this.mCSServiceUtil.cfInitializeDataProvider(Object.values(lData.cs_data_provider_));
                        }
                    }
                }

                if (lData.hasOwnProperty('cs_data_provider_properties_')) {
                    if (lData.cs_data_provider_ instanceof Array) {
                        this.mCSServiceUtil.cfInitializeDataProviderProperties(lData.cs_data_provider_properties_);
                    }
                }

                if (lData.hasOwnProperty('cs_criteria_definition_')) {
                    if (lData.cs_criteria_definition_ instanceof Array) {
                        this.mCSServiceUtil.cfInitializeCriteriaDefinition(lData.cs_criteria_definition_);
                    }
                    else {
                        if (Object.keys(lData.cs_criteria_definition_) && Object.keys(lData.cs_criteria_definition_)[0]) {
                            this.mCSServiceUtil.cfInitializeCriteriaDefinition(Object.values(lData.cs_criteria_definition_));
                        }
                    }
                }

                if (lData.hasOwnProperty('cs_service_')) {
                    var lServiceList = null;
                    if (lData.cs_service_ instanceof Array) {
                        lServiceList = lData.cs_service_;
                    }
                    else {
                        if (Object.keys(lData.cs_service_) && Object.keys(lData.cs_service_)[0]) {
                            lServiceList = Object.values(lData.cs_service_);
                        }
                    }

                    var lServiceCodeObjectMap = this.mCSServiceUtil.cfInitializeService(lServiceList);
                    if (lServiceCodeObjectMap) {
                        if (lData.hasOwnProperty('cs_service_properties_')) {
                            var lServicePropertiesList = null;
                            if (lData.cs_service_properties_ instanceof Array) {
                                lServicePropertiesList = lData.cs_service_properties_;
                            }
                            else {
                                if (Object.keys(lData.cs_service_properties_) && Object.keys(lData.cs_service_properties_)[0]) {
                                    lServicePropertiesList = Object.values(lData.cs_service_properties_);
                                }
                            }

                            for (var lInd = 0; lInd < lServicePropertiesList.length; lInd++) {
                                var lServicePropertyObject = lServicePropertiesList[lInd];
                                var lServiceObject = lServiceCodeObjectMap[lServicePropertyObject.cs_service_code_]
                                if (lServiceObject) {
                                    if (!lServiceObject.hasOwnProperty('cs_service_properties_')) {
                                        lServiceObject.cs_service_properties_ = [];
                                    }

                                    lServiceObject.cs_service_properties_.push(lServicePropertyObject);
                                }
                            }
                        }
                    }
                }

                if (lData.hasOwnProperty('cs_request_definition_')) {
                    if (lData.cs_request_definition_ instanceof Array) {
                        this.mCSServiceUtil.cfInitializeRequestDefinition(lData.cs_request_definition_);
                    }
                    else {
                        if (Object.keys(lData.cs_request_definition_) && Object.keys(lData.cs_request_definition_)[0]) {
                            this.mCSServiceUtil.cfInitializeRequestDefinition(Object.values(lData.cs_request_definition_));
                        }
                    }
                }

                var lDReportPropertyMap = null;
                if (lData.hasOwnProperty('cs_dashboard_report_properties_')) {
                    for (var ldrpInd = 0; ldrpInd < lData.cs_dashboard_report_properties_.length; ldrpInd++) {
                        var lDashboardReportProperty = lData.cs_dashboard_report_properties_[ldrpInd];
                        if (lDashboardReportProperty) {
                            if (!lDReportPropertyMap) {
                                lDReportPropertyMap = {}
                            }

                            if (!lDReportPropertyMap[lDashboardReportProperty.report_name_]) {
                                lDReportPropertyMap[lDashboardReportProperty.report_name_] = [];
                            }

                            lDReportPropertyMap[lDashboardReportProperty.report_name_].push(lDashboardReportProperty);
                        }
                    }
                }

                if (lData.hasOwnProperty('cs_dashboard_')) {
                    for (var ldInd = 0; ldInd < lData.cs_dashboard_.length; ldInd++) {
                        var lDashboardReport = lData.cs_dashboard_[ldInd];
                        lDashboardReport.key = 1000 + ldInd;

                        if (lDReportPropertyMap) {
                            var lPropList = lDReportPropertyMap[lDashboardReport.report_name_];
                            if (lPropList) {
                                lDashboardReport.report_properties_ = lPropList;
                            }
                        }
                    }

                    CSUtil.mDashboard = lData.cs_dashboard_;
                }

                /**
                 * Initialize Menu, Services and Employee Data
                 */
                this.mCSUtil.cfInitializeApplication();

                if ((CSConfigUtil.mLoginType === '1') || (CSConfigUtil.mUserType === 'STUDENT')) {
                    this.setState(
                        {
                            mLoginStatus: true,
                            mSessionId: lResponse.session_id_,
                            mLoginAttempt: 0,
                            mShowBackdrop: false,
                            mHomeFlag: true
                        }
                    );
                }
                else {
                    if ((CSConfigUtil.mLoginType === '2') || (CSConfigUtil.mLoginType === '3')) {
                        this.cfInitialize();
                    }

                    this.setState(
                        {
                            mLoginStatus: true,
                            mSessionId: lResponse.session_id_,
                            mLoginAttempt: 0,
                            mShowBackdrop: false,
                            //                            mInsFlag        : true
                            mHomeFlag: true
                        }
                    );
                }
            }
            else {
                this.setState(
                    {
                        mLoginMessage: lStatus.message,
                        mShowBackdrop: false,
                        mInsFlag: false
                    }
                );
            }
        }
    }

    cfProcessChange(event) {
        if (event.target) {
            var lEnableSignIn = false;
            if (event.target.id === 'ID_USER_ID') {
                if (this.state.mPassword && event.target.value) {
                    lEnableSignIn = true;
                }

                this.setState({ mUserId: event.target.value, mEnableSignIn: lEnableSignIn });
            }
            else if (event.target.id === 'ID_PASSWD') {
                if (this.state.mUserId && event.target.value) {
                    lEnableSignIn = true;
                }

                this.setState({ mPassword: event.target.value, mEnableSignIn: lEnableSignIn });
            }
        }
    }

    cfHome() {
        CSUtil.mSelectedAYObj = this.state.mSelectedAcademicYear;
        if (this.state.mSelectedAcademicYear) {
            CSConfigUtil.mAYCode = this.state.mSelectedAcademicYear.academic_year_code_;
        }

        //       CSConfigUtil.mActiveAcademicYearObj = CSUtil.mSelectedAYObj;
        CSConfigUtil.mCurrentAYCode = CSUtil.mSelectedAYObj.academic_year_code_;
        CSConfigUtil.mCurrentAYName = CSUtil.mSelectedAYObj.academic_year_name_;

        CSUtil.mSelectedInstituteObj = this.state.mSelectedInstitute;
        if (this.state.mSelectedInstitute) {
            CSConfigUtil.mBranchCode = this.state.mSelectedInstitute.branch_code_;
            CSConfigUtil.mBranchName = this.state.mSelectedInstitute.branch_name_;
            CSConfigUtil.mBranchType = this.state.mSelectedInstitute.type_name_;
            CSConfigUtil.mBoardCode = this.state.mSelectedInstitute.board_;
        }

        this.setState({ mHomeFlag: true })
    }

    cfGetLoginForm(lInsFlag = false) {
        var lLoginActions = (lInsFlag) ? null :
            <>
                <div className="flex align-items-center justify-content-center">
                    <Button
                        icon="pi pi-sign-in"
                        className="w-6 text-xl mt-2"
                        key="20001"
                        id="20001"
                        label="Sign In"
                        disabled={!this.state.mEnableSignIn}
                        onClick={this.cfAuthenticate}
                    />
                </div>
                <div className="flex align-items-center justify-content-center mt-2">
                    <Button
                        disabled={!this.state.mUserId}
                        key="20002"
                        id="20002"
                        onClick={this.cfResetPassword}
                        className="  font-medium no-underline text-center cursor-pointer"
                        style={{ backgroundColor: 'white', color: 'var(--clr-heading)' }}>
                        Forgot password?
                    </Button>
                </div>
                {/* <Button
                            icon    = "pi pi-key" 
                            className   = 'w-full p-3 text-xl mt-3'
                            key     = "20002"
                            id      = "20002"
                            label   = "Reset Password"
                            disabled= {!this.state.mUserId}
                            onClick = {this.cfResetPassword}
                        /> */}


                <h3> {this.state.mLoginMessage} </h3>
            </>
        var lInstituteInfo = null;
        if (lInsFlag) {
            var lEnableGo = (this.state.mSelectedAcademicYear);
            let lAcademicYear = null;
            if ((CSConfigUtil.mLoginType === '2') || (CSConfigUtil.mLoginType === '3')) {
                lAcademicYear =
                    <div className="login-form-item">
                        <div style={{ padding: '0.25em' }}>
                            <label style={{ display: 'block', fontSize: '14px', fontWeight: 'bold', width: "120px" }}>
                                {"Academic Year"}
                            </label>
                        </div>
                        <Dropdown
                            style={{ fontSize: '12px', width: '300px', height: '36px', margin: '0.15em' }}
                            id="ID_AY"
                            options={CSUtil.mAcademicYearList}
                            optionLabel='academic_year_name_'
                            value={this.state.mSelectedAcademicYear}
                            onChange={(lItem) => this.setState({ mSelectedAcademicYear: lItem.value })} />
                    </div>
            }

            let lInstitute = null;
            if (CSConfigUtil.mLoginType === '3') {
                lEnableGo = lEnableGo && (this.state.mSelectedInstitute);
                lInstitute =
                    <div className="login-form-item">
                        <div style={{ padding: '0.25em' }}>
                            <label style={{ display: 'block', fontSize: '14px', fontWeight: 'bold', width: "120px" }}>
                                {"Branch"}
                            </label>
                        </div>
                        <Dropdown
                            style={{ fontSize: '12px', width: '300px', height: '36px', margin: '0.15em' }}
                            id="ID_INSTITUTE"
                            options={CSUtil.mInstituteList}
                            optionLabel='branch_name_'
                            value={this.state.mSelectedInstitute}
                            onChange={(lItem) => this.setState({ mSelectedInstitute: lItem.value })} />
                    </div>
            }

            lInstituteInfo =
                <div className='login-ins-form'>
                    <>
                        {lAcademicYear}

                        {lInstitute}

                        <div align="center" style={{ paddingTop: '15px' }}>
                            <Button
                                icon="pi pi-angle-double-right"
                                style={{ fontSize: '13.2px', marginRight: '.25em', verticalAlign: 'middle' }}
                                key="20001"
                                id="20001"
                                disabled={!lEnableGo}
                                label="Go"
                                onClick={this.cfHome}
                            />
                        </div>
                    </>
                </div>
        }

        let lResetPassword = null;
        if (this.state.mPasswordChange === 1) {
            lResetPassword =
                <div className='login-ins-form'>
                    <>
                        <div className="login-form-item">
                            <div>
                                <label style={{ display: 'block', fontSize: '14px', fontWeight: 'bold', }}>
                                    {"Enter OTP"}
                                </label>

                            </div>
                            <InputText
                                style={{ fontSize: '12px', width: '150px', height: '36px', margin: '0.15em', paddingRight: '.15em' }}
                                id="ID_OTP"
                                width='300px'
                                type="password"
                                value={this.state.mEnteredOTP}
                                onChange={(lEvent) => { this.setState({ mEnteredOTP: lEvent.target.value }) }}
                            />
                            <Button
                                icon="pi pi-check"
                                style={{ width: '100px', }}
                                key="20001"
                                id="20001"
                                label="Validate"
                                onClick={this.cfValidateOTP}
                            />
                        </div>

                        <div style={{ justifyContent: 'space-between' }}>

                        </div>

                        <h3> {this.state.mResetPasswdMsg} </h3>
                    </>
                </div>
        }

        let lPasswordChange = null;
        if (this.state.mPasswordChange === 2) {
            lPasswordChange =
                <>
                    <div className="login-form-item">
                        <div style={{ padding: '0.25em' }}>
                            <label style={{ display: 'block', fontSize: '14px', fontWeight: 'bold', width: "170px" }}>
                                {"Enter Password"}
                            </label>
                        </div>
                        <InputText
                            style={{ fontSize: '12px', width: '250px', height: '36px', margin: '0.15em' }}
                            id="ID_PASSWORD"
                            width='300px'
                            type="password"
                            value={this.state.mNewPassword}
                            onChange={(lEvent) => { this.setState({ mNewPassword: lEvent.target.value }) }}
                        />
                    </div>

                    <div className="login-form-item">
                        <div style={{ padding: '0.25em' }}>
                            <label style={{ display: 'block', fontSize: '14px', fontWeight: 'bold', width: "170px" }}>
                                {"Re-Enter Password"}
                            </label>
                        </div>
                        <InputText
                            style={{ fontSize: '12px', width: '250px', height: '36px', margin: '0.15em' }}
                            id="ID_CONFIRM_PASSWORD"
                            width='300px'
                            type="password"
                            value={this.state.mConfirmPassword}
                            onChange={(lEvent) => { this.setState({ mConfirmPassword: lEvent.target.value }) }}
                        />
                    </div>

                    <div align="center" style={{ paddingTop: '15px' }}>
                        <Button
                            icon="pi pi-check"
                            style={{ fontSize: '13.2px', marginRight: '.25em', verticalAlign: 'middle' }}
                            key="20001"
                            id="20001"
                            label="Validate Password"
                            onClick={this.cfValidatePassword}
                        />
                    </div>

                    <h3> {this.state.mValidatePasswdMsg} </h3>
                </>
        }
        const clContainerClassName = classNames(' flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden');

        return (
            <div className={clContainerClassName} >
                <div className="flex flex-column align-items-center justify-content-center">
                    <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) , var(--primary-light) 30%)' }}>
                        <div className="w-full  py-3 px-3 sm:px-5 box-shadow" style={{ borderRadius: '53px' }}>
                            <div className="text-center mb-4">
                                <img src="./cs_logo_latest.png" alt="logo" height="50" className="mb-3" />
                                <div className="text-900 text-2xl font-bold mb-3">Chinmmai Systemss</div>
                                <Divider />
                                <div className="font-semibold text-xl text-primary mt-3 mb-3">{CSConfigUtil.mProductCode + " " + CSConfigUtil.mProductCaption}</div>
                            </div>
                            <>
                                <label htmlFor="ID_USER_ID" className="block text-900 font-semibold text-base mb-2">
                                    User Id
                                </label>

                                <InputText
                                    id="ID_USER_ID"
                                    inputid="ID_USER_ID"
                                    type="text"
                                    name='user_id_'
                                    value={this.state.mUserId}
                                    placeholder="Enter User Id"
                                    className="w-full md:w-30rem mb-5"
                                    style={{ padding: '1rem' }}
                                    onChange={this.cfProcessChange}>
                                </InputText>

                                <label htmlFor="ID_PASSWD" className="block text-900 font-semibold text-base mb-2 ">
                                    Password
                                </label>
                                <Password
                                    className      = 'w-full mb-3'
                                    id             = "ID_PASSWD"
                                    inputId        = "ID_PASSWD"
                                    value          = {this.state.mPassword}
                                    placeholder    = "Enter Password"
                                    autoComplete   = "password"
                                    inputClassName = "w-full p-3"
                                    onChange       = {this.cfProcessChange}
                                    name           = 'password_'
                                    feedback       = {false}
                                    onKeyDown      = {(event) => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.cfAuthenticate();
                                            }
                                        }
                                    }
                                />

                                {lLoginActions}
                                <p className="text-danger"></p>
                            </>

                            {lResetPassword}

                            {lPasswordChange}

                            {lInstituteInfo}
                            <div className="flex justify-content-center mt-1">
                                <a href="https://Chinmmaisystems.com/" className="align-items-center text-primary justify-content-center">
                                    © {cfGetConstantValue('#YEAR#')} Chinmmai Systemss &nbsp;
                                    {/* <img className='copyright' src='./cs_logo_latest.png' alt="Chinmmai Systemss" style={{ width: '20px', height: '20px'}}/> */}
                                </a>
                            </div >
                        </div>

                    </div>
                </div>
            </div>

        );
    }

    render() {
        var lLoginForm = null;
        if (this.state.mHomeFlag) {
            lLoginForm = <App redirectFrom="Login" />
        }
        else {
            lLoginForm = this.cfGetLoginForm(this.state.mInsFlag);
        }

        var lForm =
            <>
                <CSCOBackdrop pShow={this.state.mShowBackdrop} />
                {lLoginForm}
            </>

        return lForm;
    }
}

export default CSCOLogin;
